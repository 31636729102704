import * as React from 'react';
import { pluralizedTense } from 'utils/string';
import ExpandRowButton from 'components/ExpandRowButton';

const getLabel = (count: number, label: string) =>
  `${count} ${pluralizedTense(count, label)}`;

interface Props extends React.HTMLAttributes<HTMLElement> {
  expanded: boolean;
  numberOfPendingVerifications: number;
  numberOfUnaddressedResponses: number;
  numberOfVerificationImages: number;
  verificationDeadlineId: number;
}

const ExpandVerificationsButton: React.FC<Props> = ({
  expanded,
  numberOfPendingVerifications,
  numberOfUnaddressedResponses,
  numberOfVerificationImages,
  onClick,
  verificationDeadlineId,
}: Props) => {
  let label;
  let color;
  if (numberOfPendingVerifications) {
    label = `${numberOfPendingVerifications} pending`;
    color = 'warning';
  } else if (numberOfVerificationImages) {
    label = getLabel(numberOfVerificationImages, 'verification');
  } else if (numberOfUnaddressedResponses) {
    label = getLabel(numberOfUnaddressedResponses, 'response');
    color = 'warning';
  }

  return numberOfVerificationImages || numberOfUnaddressedResponses ? (
    <ExpandRowButton
      buttonColor={color}
      expanded={expanded}
      idLabel={`verifications-${verificationDeadlineId}`}
      label={label}
      onClick={onClick}
    />
  ) : null;
};

export default ExpandVerificationsButton;
