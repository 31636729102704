import { divIcon } from 'leaflet';

export function buildSponsorablePropertyTooltip({ name }) {
  return `<span>${name}</span>`;
}

export const buildClusterIcon = ({ population }) =>
  divIcon({
    html: population,
    className: 'map__cluster-icon',
    iconSize: [30, 30],
  });

function iconSym({ modifiers }) {
  if (modifiers.includes('removed')) {
    return 'cross';
  }
  if (modifiers.includes('added')) {
    return 'check';
  }
  if (modifiers.includes('focus')) {
    return 'star';
  }

  return 'man';
}

export function buildSponsorablePropertyIcon({ modifiers }) {
  const className = 'map__sponsorable_property-icon'.concat(
    ...modifiers.map(
      (modifier) => ` map__sponsorable_property-icon--${modifier}`,
    ),
  );
  const innerHtml = `<span class="icon icon-${iconSym({ modifiers })}" />`;

  return divIcon({
    html: `<div class="${className}">${innerHtml}</div>`,
    iconAnchor: [12, 36],
  });
}

export default {
  buildClusterIcon,
  buildSponsorablePropertyIcon,
  buildSponsorablePropertyTooltip,
};
