import * as React from 'react';
import { CampaignStatus } from 'types/campaign';
import { capitalizeEachWord } from 'utils/string';
import Badge, { BadgeProps } from 'components/Badge';

interface Props {
  campaignStatus: CampaignStatus;
}

const CampaignStatusBadge: React.FunctionComponent<Props> = ({
  campaignStatus,
}: Props) => {
  const label = capitalizeEachWord(campaignStatus);

  let badgeType: BadgeProps['badgeType'];
  switch (campaignStatus) {
    case 'active':
      badgeType = 'primary';
      break;
    case 'complete':
      badgeType = 'success';
      break;
    case 'proposed':
      badgeType = 'warning';
      break;
    default:
      badgeType = 'secondary';
      break;
  }

  return (
    <Badge className="ml-1" badgeType={badgeType}>
      {label}
    </Badge>
  );
};

export default CampaignStatusBadge;
