import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import {
  any,
  either,
  flatten,
  isEmpty,
  isNil,
  length,
  map,
  partition,
  path,
  pathEq,
  pipe,
  prop,
  propEq,
} from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import {
  sortBySponsorablePropertyName,
  sponsorablePropertyName,
} from 'utils/offer';
import Icon from './Icon';
import SponsorshipEmailModal from './SponsorshipEmailModal';

const SEND_OFFER_EMAILS_MUTATION = gql`
  mutation SendOfferEmails($input: SendOfferEmailsInput!) {
    sendOfferEmails(input: $input) {
      offers {
        id
      }
    }
  }
`;

const EMAIL_QUERY = gql`
  query OfferEmail($offerId: Int!) {
    offerEmail(offerId: $offerId) {
      htmlBody
      to
    }
  }
`;

const offerEmail = path(['data', 'offerEmail']);

const renderOffer = (offer) => (
  <li key={offer.id}>{sponsorablePropertyName(offer)}</li>
);

const renderOffers = pipe(sortBySponsorablePropertyName, map(renderOffer));

const hasNoAssets = pathEq(['assets', 'length'], 0);
const hasNoValue = propEq('value', null);
const hasNoAssetsOrValue = either(hasNoAssets, hasNoValue);
const hasDefaultVerificationDeadlines = pipe(
  prop('assets'),
  map(path(['campaignCapability', 'defaultVerificationDeadlines'])),
  flatten,
  length,
);
const missingDescription = pipe(
  prop('assets'),
  map(path(['campaignCapability', 'executionExpectations'])),
  any(either(isNil, isEmpty)),
);

const SendOfferEmailsModal = ({
  id,
  offers,
  onSend,
  toggleClassName,
  disabled,
}) => {
  const [offersWoAssetsOrValue, offersWAssetsAndValue] = partition(
    hasNoAssetsOrValue,
    offers,
  );
  const [offersWDeadlines, offersWoDeadlines] = partition(
    hasDefaultVerificationDeadlines,
    offersWAssetsAndValue,
  );
  const [offersWoDescription, sendableOffers] = partition(
    missingDescription,
    offersWDeadlines,
  );

  return (
    <ApolloConsumer>
      {(client) => (
        <SponsorshipEmailModal
          id={id}
          fetchEmail={(offerId) =>
            client
              .query({
                fetchPolicy: 'network-only',
                query: EMAIL_QUERY,
                variables: { offerId },
              })
              .then(offerEmail)
          }
          lastSentAtPath={['offerSentAt']}
          offers={sendableOffers}
          sendEmails={(offerIds) =>
            client
              .mutate({
                mutation: SEND_OFFER_EMAILS_MUTATION,
                variables: { input: { offerIds } },
              })
              .then(onSend)
          }
          title="Send Offer Email?"
          toggleProps={{
            children: (
              <>
                <Icon className="mr-2" value="mail" />
                Send Offer
              </>
            ),
            className: toggleClassName,
            disabled,
          }}
          submitProps={sendableOffers.length ? undefined : { disabled: true }}
        >
          {!isEmpty(offersWoAssetsOrValue) && (
            <div
              className="alert alert-warning"
              data-test="assets-or-values-alert"
            >
              The offers associated with the following sponsorable properties
              don&#39;t&nbsp; have values and/or any assets; offers cannot be
              sent to them.
              <ul>{renderOffers(offersWoAssetsOrValue)}</ul>
            </div>
          )}
          {!isEmpty(offersWoDeadlines) && (
            <div className="alert alert-warning" data-test="deadlines-alert">
              The offers associated with the following sponsorable properties
              don&#39;t&nbsp; have any default verification deadlines associated
              with their assets; offers cannot be sent to them.
              <ul>{renderOffers(offersWoDeadlines)}</ul>
            </div>
          )}
          {!isEmpty(offersWoDescription) && (
            <div
              className="alert alert-warning"
              data-test="campaign-capability-description-alert"
            >
              The offers associated with the following sponsorable properties
              are missing asset descriptions;&nbsp; offers cannot be sent to
              them.
              <ul>{renderOffers(offersWoDescription)}</ul>
            </div>
          )}
        </SponsorshipEmailModal>
      )}
    </ApolloConsumer>
  );
};

SendOfferEmailsModal.propTypes = {
  id: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  onSend: PropTypes.func,
  toggleClassName: PropTypes.string,
  disabled: PropTypes.bool,
};

SendOfferEmailsModal.defaultProps = {
  onSend: () => {},
  toggleClassName: 'btn btn-primary',
  disabled: false,
};

export default SendOfferEmailsModal;
