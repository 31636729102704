import * as React from 'react';
import classNames from 'classnames';
import s from './styles.module.css';

export interface BadgeProps extends React.HTMLAttributes<HTMLElement> {
  badgeType?:
    | 'zero'
    | 'prospecting'
    | 'qualified'
    | 'proposed'
    | 'confirmed'
    | 'packages'
    | 'average-sponsorship-value'
    | 'confirmed-sponsorships'
    | 'on-time-verifications'
    // TODO: check if these are still needed after delete the toolkit.css file
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
  title?: string;
  size?: 'sm' | 'md' | 'lg';
}

const Badge = (props: BadgeProps) => {
  const {
    children,
    className,
    badgeType = 'secondary',
    size = 'md',
    ...rest
  } = props;
  return (
    <span
      className={classNames(
        s.badge,
        s[`badge-${badgeType}`],
        s[`badge-${size}`],
        className,
      )}
      {...rest}
    >
      {children}
    </span>
  );
};

Badge.defaultProps = {
  badgeType: 'secondary',
};

export default Badge;
