import { createReducer } from 'redux-create-reducer';
import { pathEq, prop, evolve, always, T, F } from 'ramda';
import { push } from 'react-router-redux';
import { receiveAuthenticationToken } from 'modules/authentication';

import api from '../utils/leagueside-api';
import { received } from './caughtErrors';

export const INVITATION_REQUESTED = 'invitation/invitation_requested';
export const RECEIVE_INVITATION = 'invitation/receive_invitation';

export const INVITATION_ACCEPTANCE_STARTED =
  'invitation/invitation_acceptance_started';
export const INVITATION_ACCEPTED = 'invitation/invitation_accepted';
export const INVITATION_ACCEPTANCE_FAILED =
  'invitation/invitation_acceptance_failed';

const initialState = {
  isWorking: false,
  invitation: null,
  loaded: false,
  message: null,
};

export default createReducer(initialState, {
  [INVITATION_REQUESTED]: evolve({ isWorking: T }),
  [RECEIVE_INVITATION]: (state, action) =>
    evolve(
      {
        isWorking: F,
        invitation: always(action.invitation),
        loaded: T,
      },
      state,
    ),
  [INVITATION_ACCEPTANCE_STARTED]: evolve({ isWorking: T }),
  [INVITATION_ACCEPTED]: evolve({ isWorking: F }),
  [INVITATION_ACCEPTANCE_FAILED]: (state, action) =>
    evolve(
      {
        isWorking: F,
        message: always(action.message),
      },
      state,
    ),
});

const isNotFoundError = pathEq(['response', 'status'], 404);

export function loadInvitation(token) {
  return (dispatch) => {
    dispatch({
      type: INVITATION_REQUESTED,
    });

    return api.getInvitation(token).then(
      (response) => {
        dispatch({
          type: RECEIVE_INVITATION,
          invitation: response,
        });
      },
      (error) => {
        if (isNotFoundError(error)) dispatch(push('/login'));
        else dispatch(received('Invitation request failed'));
      },
    );
  };
}

export function acceptInvitation(
  token,
  password,
  passwordConfirmation,
  name,
  title,
  cellPhone,
) {
  return (dispatch) => {
    dispatch({
      type: INVITATION_ACCEPTANCE_STARTED,
    });

    return api
      .acceptInvitation(
        token,
        password,
        passwordConfirmation,
        name,
        title,
        cellPhone,
      )
      .then(
        (response) => {
          if (response.message) {
            dispatch({
              type: INVITATION_ACCEPTANCE_FAILED,
              message: response.message,
            });

            return;
          }

          dispatch({
            type: INVITATION_ACCEPTED,
            invitation: response,
          });

          dispatch(receiveAuthenticationToken(response.jwt));

          dispatch(push('/'));
        },
        () => {
          dispatch({
            type: INVITATION_ACCEPTANCE_FAILED,
            message: 'An unknown error has occurred. Please contact support.',
          });
        },
      );
  };
}

export const getInvitation = prop('invitation');
export const getIsWorking = prop('isWorking');
export const getLoaded = prop('loaded');
export const getMessage = prop('message');
