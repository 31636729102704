import React from 'react';
import { useQuery } from 'react-apollo';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import Loader from 'components/Loader';
import CapabilitiesTableController from './CapabilitiesTableController';

const QUERY = gql`
  query OrganizationCapabilities($id: Int!) {
    organization(id: $id) {
      id
      name
      organizationCapabilities {
        id
        description
        capability {
          id
          name
        }
        capabilityDetail {
          id
          maxForSale
          price
          __typename
          capabilityDetailable {
            ... on GameSignage {
              id
              canHang24Hours
              bannerPlacements
              bannerSizes
              organizationPlayLocations {
                id
                playLocation {
                  id
                  address
                }
              }
            }
            ... on DedicatedEmail {
              id
              emailListSize
            }
            ... on SocialMediaPost {
              id
              socialMediaFollowingSize
              socialMediaChannel {
                id
                name
              }
            }
            ... on TeamGameJersey {
              id
              minTeamsRequired
              canUseLogo
              otherBrands
              logoPlacements
            }
          }
        }
      }
    }
  }
`;

const OrganizationCapabilitiesTab = ({ organizationId }) => {
  const { data, loading } = useQuery(QUERY, {
    variables: { id: organizationId },
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loader />;
  const organization = data && data.organization;
  const organizationCapabilities =
    data && organization.organizationCapabilities;

  return (
    <div className="card-body">
      {organizationCapabilities ? (
        <>
          <div className="d-flex justify-content-end mr-3">
            <Link to={`/organizations/${organization.id}/capability-form`}>
              <button type="button" className="btn btn-primary">
                + Add Capability
              </button>
            </Link>
          </div>
          <CapabilitiesTableController
            organizationCapabilities={organizationCapabilities}
          />
        </>
      ) : (
        <h1
          className="d-flex justify-content-center"
          data-test="no-results-message"
        >
          {' '}
          Sorry! Capabilities have not been created yet!{' '}
        </h1>
      )}
    </div>
  );
};

OrganizationCapabilitiesTab.propTypes = {
  organizationId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(OrganizationCapabilitiesTab);
