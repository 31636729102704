import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useWizardContext } from 'pages/qualify/provider';
import WizardInput from '../wizard-input';
import WizardStep from '../wizard-step';

export default function PrimaryContactStep() {
  const { tokenData } = useWizardContext();
  const [performMutation] = useMutation(MUTATION);

  return (
    <WizardStep
      performMutation={performMutation}
      mutationStaticIds={{
        organizationId: tokenData.id,
        season: tokenData.season,
        year: tokenData.year,
        offerId: tokenData.offer_id,
      }}
    >
      <WizardStep.Content title="Input details for the primary contact person handling sponsorships for your organization.">
        <h2 className="sui-text-desktop-5 d:sui-text-desktop-6 sui-font-bold sui-mb-3">
          Primary contact
        </h2>

        <div
          className="sui-flex sui-flex-col t:sui-grid sui-mb-1"
          style={{ gap: 16, gridTemplateColumns: '1fr 1fr' }}
        >
          <WizardInput
            type="text"
            name="primaryContactFirstName"
            label="First Name"
            rules={{ required: 'First name is required' }}
          />
          <WizardInput
            type="text"
            name="primaryContactLastName"
            label="Last Name"
            rules={{ required: 'Last name is required' }}
          />
        </div>
        <div
          className="sui-flex sui-flex-col t:sui-grid"
          style={{ gap: 16, gridTemplateColumns: '1fr 1fr' }}
        >
          <WizardInput
            type="phone"
            name="primaryContactPhone"
            label="Phone Number"
            rules={{
              required: 'Phone number is required',
              pattern: {
                value: /\d{3}-\d{3}-\d{4}/,
                message: 'Enter a valid phone number',
              },
            }}
          />
          <WizardInput
            type="email"
            name="primaryContactEmail"
            label="Email"
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid email',
              },
            }}
          />
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation UpsertPrimaryContact($input: UpsertPrimaryContactInput!) {
    upsertPrimaryContact(input: $input) {
      id
    }
  }
`;
