import * as React from 'react';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import {
  descend,
  filter,
  isEmpty,
  map,
  path,
  pipe,
  reject,
  sortWith,
} from 'ramda';
import gql from 'graphql-tag';
import { isAllShownOrHidden } from 'utils/misc';
import {
  campaignSeasonOrdinal,
  hasActiveCampaign,
  isConfirmed,
  isRemoved,
} from 'utils/offer';
import { CampaignStatus } from 'types/campaign';
import { OfferStatus } from 'types/offer';
import { Season } from 'types/season';
import HoverTable from 'components/HoverTable';
import CampaignsTableRow from './campaigns/CampaignsTableRow';

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      offers {
        id
        campaign {
          id
          name
          season
          sponsor {
            id
            name
          }
          status
          year
        }
        sponsorableProperty {
          id
          name
        }
        status
      }
    }
  }
`;

const filterActiveOffers = pipe(filter(hasActiveCampaign), reject(isRemoved));

const yearSeasonConfirmedSort = sortWith([
  descend(path(['campaign', 'year'])),
  descend(campaignSeasonOrdinal),
  descend(isConfirmed),
]);

const emptyMessage = (
  <p className="text-center m-4">
    No campaigns include sponsorable properties of this organization yet!
  </p>
);

interface Sponsor {
  id: number;
  name: string;
}

interface SponsorableProperty {
  id: number;
  name: string;
}

interface Campaign {
  id: number;
  name: string;
  season: Season;
  sponsor: Sponsor;
  status: CampaignStatus;
  year: string;
}

interface Offer {
  id: number;
  campaign: Campaign;
  sponsorableProperty: SponsorableProperty;
  status: OfferStatus;
}

interface Organization {
  id: number;
  offers: Offer[];
}

interface OrganizationData {
  organization: Organization;
}

interface Props {
  organizationId: number;
}

const Campaigns: React.FunctionComponent<Props> = ({
  organizationId,
}: Props) => {
  const [showAllOffers, setShowAllOffers] = useState(false);

  const { data, loading } = useQuery<OrganizationData>(ORGANIZATION_QUERY, {
    variables: { id: organizationId },
  });

  const organization = data && data.organization;
  const offers = organization ? organization.offers : [];

  const sortedOffers = yearSeasonConfirmedSort(offers) as Offer[];
  const activeOffers = filterActiveOffers(sortedOffers) as Offer[];
  const offersHaveSameStatus = isAllShownOrHidden(activeOffers, offers);

  return !loading && organization ? (
    <div className="card-body">
      {isEmpty(sortedOffers) ? (
        emptyMessage
      ) : (
        <>
          <div className="d-inline-flex align-self-center my-1">
            <strong>
              {showAllOffers || offersHaveSameStatus
                ? 'All Offers'
                : 'Active Offers'}
            </strong>
          </div>
          {!offersHaveSameStatus && (
            <button
              className="btn btn-sm btn-secondary ml-2"
              id="showAllToggle"
              onClick={() => setShowAllOffers(!showAllOffers)}
              type="button"
            >
              {showAllOffers ? 'Show Active' : 'Show All'}
            </button>
          )}
          <HoverTable>
            <thead>
              <tr>
                <th scope="col">Sponsorable Property</th>
                <th scope="col">Campaign</th>
                <th scope="col">Sponsor</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {map(
                (offer) => (
                  <CampaignsTableRow key={offer.id} offer={offer} />
                ),
                showAllOffers || offersHaveSameStatus
                  ? sortedOffers
                  : activeOffers,
              )}
            </tbody>
          </HoverTable>
        </>
      )}
    </div>
  ) : null;
};

export default Campaigns;
