import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { camelToKebab } from 'utils/string';
import { create } from 'modules/campaign/searchFilters';
import CreateLocationFilterModal from './CreateLocationFilterModal';

const LOCATION_FILTERS = [
  { filterName: 'State', iconName: 'address', label: 'State' },
];

const ATTRIBUTES_FILTERS = [
  {
    filterName: 'NumberOfPlayers',
    iconName: 'man',
    label: 'Number of Players',
  },
  { filterName: 'Capability', iconName: 'flag', label: 'Capabilities' },
  { filterName: 'Sport', iconName: 'trophy', label: 'Sports' },
  { filterName: 'Season', iconName: 'calendar', label: 'Seasons' },
];

const DEMOGRAPHIC_FILTERS = [
  { filterName: 'Income', iconName: 'credit', label: 'Household Income' },
  { filterName: 'Ethnicity', iconName: 'pie-chart', label: 'Ethnicity' },
];

export const AddFilterButtonUnwrapped = ({ addFilter }) => {
  const renderDropdownButton = ({ filterName, iconName, label }) => (
    <button
      key={filterName}
      id={`add-${camelToKebab(filterName)}-filter`}
      className="dropdown-item"
      onClick={() => addFilter(`${filterName}Filter`)}
      type="button"
    >
      <span className={`icon icon-${iconName} text-muted mr-2`} />
      {label}
    </button>
  );

  return (
    <div className="dropdown">
      <button
        className="btn btn-large btn-secondary dropdown-toggle"
        data-test="add-filter-button"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
      >
        <span className="icon icon-plus" />
        Add Filter
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <div className="mb-2">
          <span className="m-3">
            <small>LOCATION FILTERS</small>
          </span>
          <CreateLocationFilterModal />
          {LOCATION_FILTERS.map(renderDropdownButton)}
        </div>
        <div className="mb-2">
          <span className="m-3">
            <small>ATTRIBUTE FILTERS</small>
          </span>
          {ATTRIBUTES_FILTERS.map(renderDropdownButton)}
        </div>
        <div className="mb-2">
          <span className="m-3">
            <small>DEMOGRAPHIC FILTERS</small>
          </span>
          {DEMOGRAPHIC_FILTERS.map(renderDropdownButton)}
        </div>
      </div>
    </div>
  );
};

AddFilterButtonUnwrapped.propTypes = {
  addFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch, { campaignId }) => ({
  addFilter: (type) => dispatch(create(campaignId, type)),
});

const wrapWithReduxStore = connect(undefined, mapDispatchToProps);
const AddFilterButton = wrapWithReduxStore(AddFilterButtonUnwrapped);

AddFilterButton.propTypes = {
  campaignId: PropTypes.number.isRequired,
};

export default AddFilterButton;
