import { map } from 'ramda';
import type { Offer } from 'types/offer';
import { sortWithDeadlineAndName } from 'utils/ts/sort';
import VerificationDeadlineRow from './verification-deadline-row';

type VerificationDeadlinesTableProps = {
  offer: Offer;
};

function VerificationDeadlinesTable(props: VerificationDeadlinesTableProps) {
  const { offer } = props;
  const { verificationDeadlines } = offer;
  const sortedVerificationDeadlines = sortWithDeadlineAndName(
    verificationDeadlines,
  );
  const headers = [
    'Deadline',
    'Name',
    'Capability',
    'Creatives',
    'Verifications',
    'Actions',
  ];

  return (
    <table className="table mb-0">
      <thead>
        <tr>
          {map(
            (header) => (
              <th scope="col" key={header}>
                {header}
              </th>
            ),
            headers,
          )}
        </tr>
      </thead>
      <tbody>
        {map(
          (verificationDeadline) => (
            <VerificationDeadlineRow
              key={verificationDeadline.id}
              offer={offer}
              tableColCount={headers.length}
              verificationDeadline={verificationDeadline}
            />
          ),
          sortedVerificationDeadlines,
        )}
      </tbody>
    </table>
  );
}

export default VerificationDeadlinesTable;
