import { ascend, prop, reverse, sortWith } from 'ramda';
import { sortByCreatedAt } from 'utils/sort';

export function sortWithDeadlineAndName<
  T extends Record<'deadline' | 'name', string>,
>(xs: T[]): T[] {
  return sortWith<T>([ascend(prop('deadline')), ascend(prop('name'))], xs);
}

export function sortByReversedCreatedAt<
  T extends Record<'createdAt', Date | string>,
>(xs: T[]): T[] {
  return reverse(sortByCreatedAt(xs));
}
