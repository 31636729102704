/* eslint-disable jsx-a11y/anchor-is-valid, react/no-array-index-key */

import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAuthentication } from 'modules';
import { getToken, logout } from 'modules/authentication';
import { CAMPAIGN_PLANNER_PATH, ORGANIZATIONS_PATH } from 'utils/paths';
import RestrictTo from 'components/RestrictTo';
import BrandBar from './BrandBar';
import Icon from './Icon';

class NavUnwrapped extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { props } = this;
    if (!props.token) {
      props.logout();
    }
  }

  render() {
    const { additionalDropdownItems, additionalNavItems } = this.props;

    return (
      <BrandBar>
        <button
          className="navbar-toggler btn btn-link text-uppercase"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mr-auto">
            <RestrictTo roles={['admin']}>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="nav-link active"
                  to={ORGANIZATIONS_PATH}
                >
                  Organizations
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="nav-link active"
                  to={CAMPAIGN_PLANNER_PATH}
                >
                  Campaign Planner
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  activeClassName="nav-link active"
                  to="/verifications"
                >
                  Verifications
                </NavLink>
              </li>
            </RestrictTo>
            {additionalNavItems.map((node, index) => (
              <li key={`additionaNavItem-${index}`} className="nav-item">
                {node}
              </li>
            ))}
          </ul>
          <ul className="navbar-nav">
            <RestrictTo roles={['superadmin']}>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  role="button"
                  id="adminMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Admin
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="adminMenuLink"
                >
                  <Link className="dropdown-item" to="/checks">
                    Checks
                  </Link>
                  <Link className="dropdown-item" to="/league-import">
                    Import CSV
                  </Link>
                </div>
              </li>
            </RestrictTo>
            {additionalDropdownItems.map((node, index) => (
              <li
                className="nav-item dropdown"
                key={`additionalDropdownItem-${index}`}
              >
                {node}
              </li>
            ))}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                role="button"
                id="userMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <Icon value="cog" />
              </a>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="userMenuLink"
              >
                <Link className="dropdown-item" to="/change-password">
                  Change Password
                </Link>
                <Link className="dropdown-item" to="/logout">
                  Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </BrandBar>
    );
  }
}

NavUnwrapped.propTypes = {
  additionalDropdownItems: PropTypes.arrayOf(PropTypes.node),
  additionalNavItems: PropTypes.arrayOf(PropTypes.node),
  logout: PropTypes.func.isRequired,
  token: PropTypes.string,
};

NavUnwrapped.defaultProps = {
  additionalDropdownItems: [],
  additionalNavItems: [],
  token: null,
};

const mapStateToProps = (state) => ({
  token: getToken(getAuthentication(state)),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);

const Nav = connect(mapStateToProps, mapDispatchToProps)(NavUnwrapped);

export default Nav;

/* eslint-enable jsx-a11y/anchor-is-valid, react/no-array-index-key */
