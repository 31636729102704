import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import ModalForm from './ModalForm';

const MUTATION = gql`
  mutation AddPreviousPartnersToCampaign(
    $input: AddPreviousPartnersToCampaignInput!
  ) {
    addPreviousPartnersToCampaign(input: $input)
  }
`;

const AddPreviousPartnersModal = ({ input, onAdd }) => {
  const [mutate] = useMutation(MUTATION);
  const addAll = () => mutate({ variables: { input } }).then(onAdd);

  const { loading, handleSubmit } = useSubmit(addAll);
  return (
    <ModalForm
      id="add-previous-partners-modal"
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{ children: loadingTense(loading, 'Add') }}
      toggleProps={{
        className: 'btn btn-link',
        children: 'Add Previous Partners',
      }}
      title="Are you sure?"
    >
      <p>
        Are you sure you want to add ALL previous partners to this campaign?
      </p>
    </ModalForm>
  );
};

AddPreviousPartnersModal.propTypes = {
  input: PropTypes.shape({
    campaignId: PropTypes.number,
    searchFilterId: PropTypes.number,
  }).isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddPreviousPartnersModal;
