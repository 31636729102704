import { useState } from 'react';
import moment from 'moment';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import formatDate from 'utils/formatDate';
import { useSubmit } from 'utils/hooks';
import {
  invalidMustacheTagError,
  isInvalidInstructions,
} from 'utils/mustacheTagErrors';
import type { VerificationDeadline } from 'types/offer';
import { campaignCapabilityCapabilityVerificationDeadlines } from 'utils/asset';
import SPONSORSHIP_EVENT_FRAGMENT from 'fragments/SponsorshipEventFragment';
import ModalFormWithMustacheTagHandling from 'components/ModalFormWithMustacheTagHandling';
import DeadlineNameInput from 'components/formInputs/DeadlineNameInput';
import DeadlineDateInput from 'components/formInputs/DeadlineDateInput';
import TextAreaInput from 'components/formInputs/TextAreaInput';
import VerificationReminderInstructionsInput from 'components/formInputs/VerificationReminderInstructionsInput';
import RequiresCreativesInput from 'components/formInputs/RequiresCreativesInput';

type UpdateVerificationDeadlineModalProps = {
  sponsorablePropertyName: string;
  verificationDeadline: VerificationDeadline;
};

function UpdateVerificationDeadlineModal(
  props: UpdateVerificationDeadlineModalProps,
) {
  const { sponsorablePropertyName, verificationDeadline } = props;
  const {
    asset,
    id: verificationDeadlineId,
    deadline: initialDeadline,
    name: initialName,
    verificationReminderInstructions: initialInstructions,
    requiresCreatives: initialRequiresCreatives,
  } = verificationDeadline;
  const [changeReason, setChangeReason] = useState('');
  const [deadline, setDeadline] = useState(moment(initialDeadline));
  const [name, setName] = useState(initialName);
  const [instructions, setInstructions] = useState(initialInstructions);
  const [requiresCreatives, setRequiresCreatives] = useState(
    initialRequiresCreatives,
  );
  const [mutate] = useMutation(MUTATION);

  const update = (input: any) => mutate({ variables: { input } });

  const requiredDataPresent = !!(
    changeReason &&
    deadline &&
    instructions &&
    name
  );

  async function asyncSubmit() {
    if (isInvalidInstructions(instructions)) {
      throw new Error(invalidMustacheTagError);
    }

    const updatedVerificationDeadlineInputs = {
      id: verificationDeadlineId,
      changeReason,
      deadline: initialDeadline,
      name: initialName,
      verificationReminderInstructions: initialInstructions,
      requiresCreatives: initialRequiresCreatives,
    };

    const formattedDeadline = deadline.format('YYYY-MM-DD');
    if (formattedDeadline !== initialDeadline) {
      updatedVerificationDeadlineInputs.deadline = formattedDeadline;
    }

    if (name !== initialName) {
      updatedVerificationDeadlineInputs.name = name;
    }

    if (instructions !== initialInstructions) {
      updatedVerificationDeadlineInputs.verificationReminderInstructions =
        instructions;
    }

    if (requiresCreatives !== initialRequiresCreatives) {
      updatedVerificationDeadlineInputs.requiresCreatives = requiresCreatives;
    }

    await update(updatedVerificationDeadlineInputs);

    setChangeReason('');
  }

  const capabilityVerificationDeadlinesByAsset = () =>
    campaignCapabilityCapabilityVerificationDeadlines(asset);

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const prefix = `update-verification-deadline-${verificationDeadlineId}`;

  return (
    <ModalFormWithMustacheTagHandling
      id={`${prefix}-modal`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      requiredDataPresent={requiredDataPresent}
      submitLabel="Update"
      title="Update Deadline"
      toggleProps={{
        className: 'btn btn-link py-1',
        children: 'Edit deadline, name, instructions',
      }}
    >
      <p>
        {'Are you sure you want to update the '}
        <strong>{`${formatDate(initialDeadline)} ${initialName}`}</strong>
        {' deadline for '}
        <strong>{sponsorablePropertyName}</strong>?
      </p>
      <DeadlineDateInput
        deadline={deadline}
        isRequired
        onChange={setDeadline}
        label={null}
      />
      <DeadlineNameInput
        capabilityVerificationDeadlines={capabilityVerificationDeadlinesByAsset()}
        isRequired
        loading={loading}
        name={name}
        onChange={setName}
        prefix={prefix}
      />
      <VerificationReminderInstructionsInput
        instructions={instructions}
        loading={loading}
        prefix={prefix}
        setInstructions={setInstructions}
      />
      <RequiresCreativesInput
        disabled={loading}
        onChange={(ev) =>
          setRequiresCreatives((ev.target as HTMLInputElement).checked)
        }
        prefix={prefix}
        requiresCreatives={requiresCreatives}
      />
      <TextAreaInput
        disabled={loading}
        formGroupClassName="mt-3"
        label="Reason for Change"
        onChange={(ev) =>
          setChangeReason((ev.target as HTMLInputElement).value)
        }
        prefix={prefix}
        required
        value={changeReason}
      />
    </ModalFormWithMustacheTagHandling>
  );
}

export default UpdateVerificationDeadlineModal;

const MUTATION = gql`
  mutation UpdateVerificationDeadline(
    $input: UpdateVerificationDeadlineInput!
  ) {
    updateVerificationDeadline(input: $input) {
      offer {
        id
        sponsorshipEvents {
          ...SponsorshipEventFragment
        }
        verificationDeadlines {
          id
          deadline
          missingCreatives
          name
          overdue
          requiresCreatives
          verificationReminderInstructions
        }
      }
    }
  }
  ${SPONSORSHIP_EVENT_FRAGMENT}
`;
