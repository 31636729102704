import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Input = styled.input`
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  width: 4.5rem;
`;

const AgeRange = (props) => {
  const { maxAge, minAge } = props;

  return (
    <>
      <p className="text-nowrap">
        <Input
          className="form-control mr-2"
          id="minAge"
          placeholder="Min"
          type="number"
          value={Number.isNaN(minAge) ? '' : minAge}
          disabled
        />
        -
        <Input
          className="form-control ml-2"
          id="maxAge"
          placeholder="Max"
          type="number"
          value={maxAge || ''}
          disabled
        />
      </p>
    </>
  );
};

AgeRange.propTypes = {
  minAge: PropTypes.number,
  maxAge: PropTypes.number,
};

AgeRange.defaultProps = {
  minAge: null,
  maxAge: null,
};

export default AgeRange;
