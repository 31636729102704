import * as React from 'react';
import formatDate from 'utils/formatDate';
import ApplicationCompletionStatus from 'components/ApplicationCompletionStatus';

interface SponsorableProperty {
  organization: {
    id: number;
  };
}

interface Campaign {
  id: number;
  season: string;
  year: number;
}
interface Offer {
  id: number;
  sponsorableProperty: SponsorableProperty;
  sponsorshipApplicationSentAt?: Date;
  campaign: Campaign;
}

interface Props {
  offer: Offer;
}

const SurveyCol: React.FunctionComponent<Props> = ({ offer }: Props) => {
  const { sponsorableProperty, campaign } = offer;

  const determineSurveyStatus = () => {
    return (
      <>
        <small data-test={`survey-col-text-${offer.id}`}>
          {offer.sponsorshipApplicationSentAt === null
            ? 'Not Sent'
            : `Sent ${formatDate(
                offer.sponsorshipApplicationSentAt,
                'M/D/YY',
              )}`}
        </small>
        <ApplicationCompletionStatus
          organizationId={sponsorableProperty.organization.id}
          season={campaign.season}
          year={campaign.year}
          campaignId={campaign.id}
          condensed
        />
      </>
    );
  };

  return (
    <td className="align-middle" data-test="survey">
      {determineSurveyStatus()}
    </td>
  );
};

export default SurveyCol;
