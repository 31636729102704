import React from 'react';
import { Link } from 'react-router-dom';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Card from 'components/Card';

function TargetingCard({ campaign }) {
  const { demographicTargetingSummary, geographicTargetingSummary } = campaign;

  return (
    <Card id="targeting-card">
      <h3 className="card-title">
        <Link to={`/campaign-targeting/${campaign.id}`}>Targeting</Link>
      </h3>
      {!geographicTargetingSummary && !demographicTargetingSummary ? (
        'No targeting filters have been added yet'
      ) : (
        <ul className="px-3">
          {!!geographicTargetingSummary && (
            <li>{geographicTargetingSummary}</li>
          )}
          {!!demographicTargetingSummary && (
            <li>{demographicTargetingSummary}</li>
          )}
        </ul>
      )}
    </Card>
  );
}

TargetingCard.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default TargetingCard;
