import { ApolloConsumer, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import type { Offer } from 'types/offer';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import ModalEmailBodyPreview from 'components/ModalEmailBodyPreviewNoRefetching';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import ErrorAlert from 'components/ErrorAlert';

const SEND_VERIFICATION_REMINDER_EMAIL_MUTATION = gql`
  mutation SendVerificationReminderEmail($offerId: Int!) {
    sendVerificationReminderEmail(offerId: $offerId)
  }
`;

const EMAIL_QUERY = gql`
  query VerificationReminderEmail($offerId: Int!) {
    verificationReminderEmail(offerId: $offerId) {
      htmlBody
      to
    }
  }
`;

type SendVerificationReminderEmailModalProps = {
  offer: Offer;
  toggleClassName: string;
};

function SendVerificationReminderEmailModal(
  props: SendVerificationReminderEmailModalProps,
) {
  const { offer, toggleClassName } = props;
  const modalId = 'send-verification-reminder-email-modal';
  const selector = `#${modalId}`;

  const { data, loading, error } = useQuery(EMAIL_QUERY, {
    variables: { offerId: offer.id },
  });

  if (loading) return <Loader />;
  if (error) return <ErrorAlert />;

  const { verificationReminderEmail } = data;
  const { to, htmlBody } = verificationReminderEmail;

  return (
    <ApolloConsumer>
      {(client) => (
        <>
          <button
            className={toggleClassName}
            data-target={selector}
            data-toggle="modal"
            disabled={!offer.sponsorableProperty.organization.canReceiveEmails}
            title={
              offer.sponsorableProperty.organization.canReceiveEmails
                ? 'Contact email not set for this league'
                : undefined
            }
            type="button"
          >
            <Icon className="mr-1" value="mail" />
            Send Reminder
          </button>
          <Modal id={modalId} title="Send Verification Reminder Email?">
            <ModalBody>
              <p>
                Are you sure you want to send the following verification
                reminder email to&nbsp;
                <strong>{offer.sponsorableProperty.name}</strong>?
              </p>
              <ModalEmailBodyPreview
                to={to}
                email={htmlBody}
                title="Verification Reminder Email Preview"
              />
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-secondary"
                data-dismiss="modal"
                data-test={`${modalId}-cancel`}
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                data-test={`${modalId}-submit`}
                onClick={async () => {
                  await client.mutate({
                    mutation: SEND_VERIFICATION_REMINDER_EMAIL_MUTATION,
                    variables: { offerId: offer.id },
                  });
                  // @ts-ignore
                  global.$(selector).modal('hide');
                }}
                type="submit"
              >
                Send
              </button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </ApolloConsumer>
  );
}

export default SendVerificationReminderEmailModal;
