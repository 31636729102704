import * as React from 'react';
import { Link } from 'react-router-dom';
import { ORGANIZATIONS_PATH, OFFER_PREFIX } from 'utils/paths';
import { DataConfidence } from 'types/sponsorableProperty';
import DataConfidenceIndicator from 'components/DataConfidenceIndicator';

import Icon from 'components/Icon';

interface SponsorableProperty {
  id: number;
  name: string;
  organization: Organization;
  dataConfidence: DataConfidence;
}

interface Organization {
  id: number;
  websiteUrl?: string;
}

interface Props {
  sponsorableProperty: SponsorableProperty;
  offerId?: number;
}

const SponsorablePropertyCol: React.FunctionComponent<Props> = ({
  sponsorableProperty,
  offerId,
}: Props) => {
  const {
    organization,
    name: sponsorablePropertyName,
    dataConfidence,
  } = sponsorableProperty;
  const { id: organizationId, websiteUrl } = organization;

  const to = offerId
    ? `${OFFER_PREFIX}/${offerId}`
    : `${ORGANIZATIONS_PATH}/${organizationId}`;

  return (
    <td className="align-middle pr-1">
      <DataConfidenceIndicator dataConfidence={dataConfidence} />
      <Link data-test="sponsorable-property-name" to={to}>
        {sponsorablePropertyName}
      </Link>
      {!!websiteUrl && (
        <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
          <Icon className="ml-2" value="export" />
        </a>
      )}
    </td>
  );
};

SponsorablePropertyCol.defaultProps = {
  offerId: undefined,
};

export default SponsorablePropertyCol;
