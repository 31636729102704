import { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import jwtDecode from 'jwt-decode';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import WizardBody from './_sections/wizard-body';
import WizardNavigation from './_sections/wizard-navigation';
import { WizardProvider } from './provider';
import { WIZARD_SECTIONS_MAP } from './wizard-map';
import {
  APPLICATION_PROGRESS_QUERY,
  SPONSORSHIP_APPLICATION_QUERY,
} from './queries';
import { Organization, TokenData } from './types';

type QualifyPageProps = {
  match: { params: { token: string } };
  location: { search: string };
};

export default function QualifyPage(props: QualifyPageProps) {
  const env = runtimeEnv();

  const { match, location } = props;
  const tokenData: TokenData = jwtDecode(match?.params?.token);
  const { data } = useQuery(SPONSORSHIP_APPLICATION_QUERY, {
    context: {
      headers: { Authorization: `Bearer ${match?.params?.token}` },
    },
    variables: {
      id: tokenData.id,
      season: tokenData.season,
      year: tokenData.year,
      campaignId: tokenData.campaign_id,
      offerId: tokenData.offer_id,
    },
  });
  const {
    data: applicationProgressData,
    refetch: refetchApplicationProgressQuery,
  } = useQuery<{
    sponsorshipApplicationView: Partial<Organization>;
  }>(APPLICATION_PROGRESS_QUERY, {
    variables: { season: tokenData.season, year: tokenData.year },
    context: { headers: { Authorization: `Bearer ${match?.params?.token}` } },
    fetchPolicy: 'network-only',
  });

  const searchParams: {
    step?: string; // step is [sectionIndex]-[stepIndex] from WIZARD_SECTIONS_MAP e.g. 0-0, 1-0, 1-1, etc.
  } = useMemo(() => {
    const searchParamsParsed = new URLSearchParams(location.search);
    const params: { [key: string]: string } = {};
    Array.from(searchParamsParsed.entries()).forEach(([key, value]) => {
      params[key] = value;
    });
    return params;
  }, [location.search]);

  return (
    <WizardProvider
      wizardMapRaw={WIZARD_SECTIONS_MAP}
      organizationData={data?.sponsorshipApplicationView}
      applicationProgressData={{
        completionPercentage:
          applicationProgressData?.sponsorshipApplicationView
            ?.sponsorshipApplication?.completionPercentage ?? 0,
        applicationProgress:
          applicationProgressData?.sponsorshipApplicationView
            ?.sponsorshipApplication?.applicationProgress ?? {},
      }}
      refetchApplicationProgressQuery={refetchApplicationProgressQuery}
      tokenData={tokenData}
      token={match?.params?.token}
      step={searchParams?.step as `${string}-${string}`}
    >
      <img
        alt=""
        hidden
        height="1"
        width="1"
        src={`${env.REACT_APP_SERVICE_BASE_URL}survey_views/${data?.sponsorshipApplicationView?.sponsorshipApplication?.offer?.guid}`}
      />
      <header
        id="WIZARD_HEADER_BOX"
        className="sui-border-b sui-border-solid sui-border-gray-80"
        style={{ height: 93 }}
      />
      <main className="sui-max-w-w sui-mx-auto sui-flex">
        <WizardNavigation />
        <WizardBody />
      </main>
      <footer
        id="WIZARD_FOOTER_BOX"
        className="sui-border-t sui-border-solid sui-border-gray-80 sui-sticky sui-bg-white"
        style={{ bottom: 0 }}
      />
    </WizardProvider>
  );
}
