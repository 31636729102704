import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useWizardContext } from 'pages/qualify/provider';
import { Inputs } from 'pages/qualify/types';
import WizardStep from '../wizard-step';
import WizardInput from '../wizard-input';

export default function SocialAndEmailStep() {
  const { tokenData, values } = useWizardContext();
  const [performMutation] = useMutation(MUTATION);

  const modifyInputsBeforeMutation = (
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    ...data,
    socialMediaFollowingSize: Number(data.socialMediaFollowingSize),
    emailListSize: Number(data.emailListSize),
    emailOpenRate: Number(data.emailOpenRate),
  });

  return (
    <WizardStep
      performMutation={performMutation}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId: values.sponsorshipApplicationId,
        offerId: tokenData.offer_id,
      }}
      modifyInputsBeforeMutation={modifyInputsBeforeMutation}
    >
      <WizardStep.Content title="Provide available metrics for your organization's promotional reach.">
        <div className="sui-grid" style={{ maxWidth: 400, gap: 16 }}>
          <WizardInput
            type="number"
            name="socialMediaFollowingSize"
            label="Primary social media followers"
            rules={{
              required: 'Primary social media followers is required',
            }}
          />
          <WizardInput
            type="number"
            name="emailListSize"
            label="Estimated email audience size"
            rules={{ required: 'Estimated email audience size is required' }}
          />
          <WizardInput
            type="number"
            name="emailOpenRate"
            label="Email open rate (average)"
            max={100}
            rules={{
              required: 'Email open rate (average) is required',
            }}
            icon="%"
          />
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation updateOrganizationInSponsorshipApplication(
    $input: UpdateOrganizationInSponsorshipApplicationInput!
  ) {
    updateOrganizationInSponsorshipApplication(input: $input) {
      id
    }
  }
`;
