/* eslint react/forbid-prop-types: 0 */
import React, { useCallback, useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';
import { unsavedChangesPrompt } from 'utils/misc';
import SaveFooter from './SaveFooter';

const WithSaveFooter = ({
  additionalContent,
  children,
  large,
  savedValue,
  handleMutation,
}) => {
  const [changes, setChanges] = useState({});
  const [value, setValue] = useState(savedValue);
  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(false);

  const resetSaveState = () => {
    setIsSaving(false);
    setIsSaved(true);
  };

  const resetState = () => {
    setChanges({});
    resetSaveState();
    setError(false);
  };

  const addChanges = (newChanges) => {
    const key = Object.keys(newChanges)[0];
    const valueHasChanged = savedValue[key] !== newChanges[key];

    if (valueHasChanged) {
      setValue({ ...value, ...newChanges });
      setIsSaved(false);
    }

    setChanges((previousState) => ({ ...previousState, ...newChanges }));
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // eslint-disable-next-line no-param-reassign
      if (!isSaved) event.returnValue = unsavedChangesPrompt;

      return event.returnValue;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return function cleanup() {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSaved]);

  async function onSubmit() {
    if (!isSaved) {
      try {
        const timeoutID = setTimeout(() => setIsSaving(true), 10);
        await handleMutation(changes);
        clearTimeout(timeoutID);
        resetState();
      } catch (e) {
        setError(e);
        resetSaveState();
      }
    }
  }

  return (
    <>
      {children({ addChanges, handleMutation, value, error })}
      <Prompt message={useCallback(unsavedChangesPrompt)} when={!isSaved} />
      <SaveFooter
        additionalContent={additionalContent}
        large={large}
        save={useCallback(onSubmit)} // eslint-disable-line react/jsx-no-bind
        saving={isSaving}
        saved={isSaved}
      />
    </>
  );
};

WithSaveFooter.propTypes = {
  additionalContent: PropTypes.node,
  children: PropTypes.func,
  large: PropTypes.bool,
  savedValue: PropTypes.object.isRequired,
  handleMutation: PropTypes.func.isRequired,
};

WithSaveFooter.defaultProps = {
  additionalContent: null,
  large: undefined,
  children: () => {},
};

export default WithSaveFooter;
