import {
  __,
  both,
  contains,
  isEmpty,
  flatten,
  map,
  partition,
  pluck,
  pipe,
  prop,
  uniqBy,
} from 'ramda';
import React from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { sortByName } from 'utils/sort';
import Icon from 'components/Icon';
import NewWindowLink from 'components/NewWindowLink';
import CreativeFileTemplateLightbox from './CreativeFileTemplateLightbox';
import CampaignDashboardCreativeContentTemplateModal from './CampaignDashboardCreativeContentTemplateModal';

const flatPluck = pipe(pluck, flatten);

const isThumbnailContentType = pipe(
  prop('contentType'),
  contains(__, ['image/png', 'image/jpeg', 'image/gif']),
);

const withThumbnail = both(prop('thumbnailUrl'), isThumbnailContentType);

const uniqByRenderedContent = uniqBy(prop('renderedContent'));
const uniqByFullSizeUrl = uniqBy(prop('fullSizeUrl'));

const renderCreativeFileTemplateWithThumbnail = (creativeFileTemplate) => (
  <div key={creativeFileTemplate.id} className="my-2">
    <CreativeFileTemplateLightbox creativeFileTemplate={creativeFileTemplate} />
  </div>
);

const renderCreativeFileTemplateWithoutThumbnail = (creativeFileTemplate) => (
  <li key={creativeFileTemplate.id}>
    <NewWindowLink
      className="btn btn-link"
      download
      hideIcon
      to={creativeFileTemplate.fullSizeUrl}
    >
      <Icon className="mr-2" value="download" />
      {creativeFileTemplate.name}
    </NewWindowLink>
  </li>
);

const renderCreativeContentTemplate = (creativeContentTemplate) => (
  <li key={creativeContentTemplate.id}>
    <CampaignDashboardCreativeContentTemplateModal
      creativeContentTemplate={creativeContentTemplate}
    />
  </li>
);

const sortCreativeFileTemplates = pipe(uniqByFullSizeUrl, sortByName);
const renderCreativeFileTemplatesWithThumbnails = pipe(
  sortCreativeFileTemplates,
  map(renderCreativeFileTemplateWithThumbnail),
);
const renderCreativeFileTemplatesWithoutThumbnails = pipe(
  sortCreativeFileTemplates,
  map(renderCreativeFileTemplateWithoutThumbnail),
);
const renderCreativeContentTemplates = pipe(
  uniqByRenderedContent,
  sortByName,
  map(renderCreativeContentTemplate),
);

function CampaignDashboardCreative({ campaign }) {
  const creativeFileTemplates = flatPluck(
    'creativeFileTemplates',
    campaign.defaultVerificationDeadlines,
  );
  const creativeContentTemplates = flatPluck(
    'creativeContentTemplates',
    campaign.defaultVerificationDeadlines,
  );

  if (isEmpty(creativeFileTemplates) && isEmpty(creativeContentTemplates))
    return null;

  const [
    CreativeFileTemplatesWithThumbnails,
    CreativeFileTemplatesWithoutThumbnails,
  ] = partition(withThumbnail, creativeFileTemplates);

  return (
    <>
      <h2 className="text-center mb-4">{`Creative from ${campaign.sponsor.name}`}</h2>
      <div className="row justify-content-around align-items-center">
        {renderCreativeFileTemplatesWithThumbnails(
          CreativeFileTemplatesWithThumbnails,
        )}
        {isEmpty(CreativeFileTemplatesWithoutThumbnails) || (
          <div className="card my-2">
            <div className="card-header">Files</div>
            <div className="card-body p-2">
              <ul className="list-unstyled m-0">
                {renderCreativeFileTemplatesWithoutThumbnails(
                  CreativeFileTemplatesWithoutThumbnails,
                )}
              </ul>
            </div>
          </div>
        )}
        {isEmpty(creativeContentTemplates) || (
          <div className="card my-2">
            <div className="card-header">Templates</div>
            <div className="card-body p-2">
              <ul className="list-unstyled m-0">
                {renderCreativeContentTemplates(creativeContentTemplates)}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

CampaignDashboardCreative.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignDashboardCreative;
