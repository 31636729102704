import React from 'react';
import { Map, Marker, TileLayer, Tooltip } from 'react-leaflet';
import { filter, groupBy, map, pipe, uniq, values } from 'ramda';
import { divIcon } from 'leaflet';
import styled from 'styled-components';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { getPoint, isConfirmed } from 'utils/offer';
import { hasValidGeoData } from 'utils/sponsorableProperty';
import { getMapBounds } from 'utils/geo';
import MapCircle from 'components/MapCircle';
import MapGeoJSON from 'components/MapGeoJSON';
import 'leaflet/dist/leaflet.css';
import './CampaignDashboardMap.css';
import MapOfferDataPoints from './MapOfferDataPoints';

const StyledTooltipDiv = styled.div`
  max-width: 22rem;
  @media (max-width: 1280px) {
    max-width: 24vw;
  }
`;

const isConfirmedAndHasPlayLocation = (offer) =>
  isConfirmed(offer) && hasValidGeoData(offer.sponsorableProperty);
const filterIsConfirmedAndHasPlayLocation = filter(
  isConfirmedAndHasPlayLocation,
);
const getPoints = pipe(map(getPoint), uniq);
const groupByPoint = pipe(groupBy(getPoint), values);

const icon = (offersLength) => {
  const innerHtml =
    offersLength > 1
      ? `<strong>${offersLength}</strong>`
      : '<span class="icon icon-man" />';

  return divIcon({
    html: `<div class="campaign-dashboard-map__icon bg-white">${innerHtml}</div>`,
    iconAnchor: [12, 36],
  });
};

const renderOfferDetails = (offer) => (
  <MapOfferDataPoints key={offer.id} offer={offer} />
);

const renderMarker = (offers) => {
  const point = getPoint(offers[0]);

  return (
    <Marker
      key={`${point[0]}-${point[1]}`}
      position={point}
      icon={icon(offers.length)}
      zIndexOffset={100}
    >
      <Tooltip>
        <StyledTooltipDiv>{map(renderOfferDetails, offers)}</StyledTooltipDiv>
      </Tooltip>
    </Marker>
  );
};
const renderMarkers = map(renderMarker);

const renderRadii = map((radius) => (
  <MapCircle radius={radius} key={radius.label} />
));
const renderGeometries = map((geometry) => (
  <MapGeoJSON geometry={geometry} key={geometry.id} />
));

function CampaignDashboardMap({ campaign }) {
  const { campaignGeometries, radii } = campaign;
  const confirmedOffers = filterIsConfirmedAndHasPlayLocation(campaign.offers);
  const points = getPoints(confirmedOffers);
  const offersByPoints = groupByPoint(confirmedOffers);

  return (
    <Map
      bounds={getMapBounds(points, radii, campaignGeometries)}
      boundsOptions={{ padding: [6, 36] }}
      className="campaign-dashboard-map"
    >
      <TileLayer
        attribution='&copy; <a href="http:// www.openstreetmap.org/copyright\">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
      />
      {renderMarkers(offersByPoints)}
      {renderRadii(radii)}
      {renderGeometries(campaignGeometries)}
    </Map>
  );
}

CampaignDashboardMap.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignDashboardMap;
