import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';
import { history } from 'store';
import PropTypes from 'prop-types';
import hideModal from 'utils/hideModal';
import { getCampaignSeason } from 'utils/campaign';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import SeasonSelectInput from 'components/formInputs/SeasonSelectInput';
import YearSelectInput from 'components/formInputs/YearSelectInput';

const DUPLICATE_CAMPAIGN = gql`
  mutation DuplicateCampaign(
    $campaignId: Int!
    $name: String!
    $season: String!
    $year: Int!
  ) {
    duplicateCampaign(
      campaignId: $campaignId
      name: $name
      season: $season
      year: $year
    ) {
      id
      sponsor {
        id
      }
    }
  }
`;

export class DuplicateCampaignModalUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = getCampaignSeason();

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  get modalId() {
    const { campaignId } = this.props;
    return `duplicate-campaign-modal-${Number(campaignId)}`;
  }

  get modalSelector() {
    return `#${this.modalId}`;
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { props, state } = this;
    const { name, season, year } = state;

    try {
      this.setState({ error: null, loading: true });
      const result = await props.duplicate(name, season, year);
      this.setState({ loading: false });
      hideModal(this.modalSelector);
      const sponsorId = result.data.duplicateCampaign.sponsor.id;
      const campaignId = result.data.duplicateCampaign.id;
      props.history.push(
        `${CAMPAIGN_PLANNER_PATH}/${sponsorId}/campaigns/${campaignId}`,
      );
    } catch (e) {
      this.setState({ loading: false, error: e.message });
    }
  }

  render() {
    const { props, state } = this;
    const { error, loading, name, season, year } = state;
    const { originalName } = props;

    return (
      <ModalForm
        id={this.modalId}
        error={error}
        loading={loading}
        onSubmit={this.handleSubmit}
        submitProps={{
          children: loadingTense(loading, 'Duplicate'),
          disabled: !name || loading,
        }}
        toggleProps={{
          className: 'btn btn-link',
          children: (
            <>
              <Icon className="mr-2" value="documents" />
              Duplicate Campaign
            </>
          ),
        }}
        title={`Duplicate ${originalName}?`}
      >
        <div className="form-group">
          <label htmlFor="campaignName">Name of new campaign</label>
          <input
            type="text"
            className="form-control"
            id="campaignName"
            onChange={(event) => this.setState({ name: event.target.value })}
            disabled={loading}
          />
        </div>
        <SeasonSelectInput
          disabled={loading}
          onChange={(event) => this.setState({ season: event.target.value })}
          value={season}
        />
        <YearSelectInput
          disabled={loading}
          onChange={(event) =>
            this.setState({ year: Number(event.target.value) })
          }
          value={year}
        />
      </ModalForm>
    );
  }
}

DuplicateCampaignModalUnwrapped.propTypes = {
  campaignId: PropTypes.number.isRequired,
  originalName: PropTypes.string.isRequired,
  duplicate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const DuplicateCampaignModal = ({ campaignId, originalName }) => (
  <ApolloConsumer>
    {(client) => (
      <DuplicateCampaignModalUnwrapped
        campaignId={campaignId}
        originalName={originalName}
        duplicate={(name, season, year) =>
          client.mutate({
            mutation: DUPLICATE_CAMPAIGN,
            variables: { campaignId, name, season, year },
          })
        }
        history={history}
      />
    )}
  </ApolloConsumer>
);

DuplicateCampaignModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  originalName: PropTypes.string.isRequired,
};

export default DuplicateCampaignModal;
