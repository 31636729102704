import * as React from 'react';
import showModal from 'utils/showModal';
import { getModalId } from 'components/OrganizationAddressModal';

interface Props {
  organizationId: number;
  toggleClassName?: string;
}

const OrganizationAddressModalToggle: React.FunctionComponent<Props> = ({
  organizationId,
  toggleClassName,
}: Props) => (
  <button
    className={toggleClassName || 'nav-link'}
    data-test="organization-address-modal-toggle"
    onClick={() => showModal(`#${getModalId(organizationId)}`)}
    type="button"
  >
    Shipping Addresses
  </button>
);

OrganizationAddressModalToggle.defaultProps = {
  toggleClassName: undefined,
};

export default OrganizationAddressModalToggle;
