import React, { useState } from 'react';
import { map, pickBy } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { useSubmit } from 'utils/hooks';
import { capitalize, loadingTense } from 'utils/string';
import { getId } from 'utils/id';
import ModalForm from 'components/ModalForm';
import { RequiredAsterisk } from 'components/RequiredLabel';
import RichTextInput, { isEmpty } from 'components/RichTextInput';
import SelectInput from 'components/formInputs/SelectInput';

const iconOptions = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'plus', label: 'Hospital Cross' },
  { value: 'light-bulb', label: 'Light Bulb' },
  { value: 'newsletter', label: 'Mail' },
  { value: 'man', label: 'Person' },
  { value: 'pie-chart', label: 'Pie Chart' },
  { value: 'emoji-happy', label: 'Smiley Face' },
  { value: 'thumbs-up', label: "Thumb's Up" },
  { value: 'flag', label: 'Banner' },
  { value: 'bowl', label: 'Bowl' },
  { value: 'publish', label: 'Web' },
  { value: 'ticket', label: 'Ticket' },
  { value: 'trophy', label: 'Trophy' },
  { value: 'location', label: 'Location' },
  { value: 'home', label: 'Home' },
  { value: 'users', label: 'Users' },
];

const iconOptionTag = ({ value, label }) => (
  <option key={value} value={value}>
    {label}
  </option>
);

iconOptionTag.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const iconOptionTags = map(iconOptionTag, iconOptions);

function difference(oldObject, newObject) {
  const { description, iconName, name, number } = newObject;
  const isModified = (value, key) => oldObject[key] !== value;
  const pickByIsModified = pickBy(isModified);

  return pickByIsModified({ description, iconName, name, number });
}

const CampaignStatisticModal = ({
  campaignStatistic,
  onSubmit,
  label,
  shouldResetOnSubmit,
  toggleProps,
}) => {
  const [description, setDescription] = useState(campaignStatistic.description);
  const [iconName, setIconName] = useState(campaignStatistic.iconName);
  const [name, setName] = useState(campaignStatistic.name);
  const [number, setNumber] = useState(campaignStatistic.number);

  async function asyncSubmit() {
    await onSubmit(
      difference(campaignStatistic, { description, iconName, name, number }),
    );

    if (shouldResetOnSubmit) {
      setDescription('');
      setIconName('');
      setName('');
      setNumber('');
    }
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const submitLabel = capitalize(label);

  return (
    <ModalForm
      id={`campaign-statistic-${getId([campaignStatistic.id, label])}-modal`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, submitLabel),
        disabled:
          !description || isEmpty(description) || !name || !number || loading,
      }}
      title={`${submitLabel} a Campaign Statistic`}
      toggleProps={toggleProps}
    >
      <SelectInput
        disabled={loading}
        label="Icon"
        onChange={({ target: { value } }) => setIconName(value)}
        required
        value={iconName}
      >
        {iconOptionTags}
      </SelectInput>
      <div className="form-group">
        <label htmlFor="number">
          Number
          <RequiredAsterisk />
        </label>
        <input
          aria-required="true"
          className="form-control"
          disabled={loading}
          id="number"
          onChange={({ target: { value } }) => setNumber(value)}
          required
          type="text"
          value={number}
        />
      </div>
      <div className="form-group">
        <label htmlFor="name">
          Name
          <RequiredAsterisk />
        </label>
        <input
          aria-required="true"
          className="form-control"
          disabled={loading}
          id="name"
          onChange={({ target: { value } }) => setName(value)}
          required
          type="text"
          value={name}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">
          Description Text
          <RequiredAsterisk />
        </label>
        <RichTextInput
          disabled={loading}
          id="description"
          initialValue={description}
          onChange={(value) => setDescription(value)}
        />
      </div>
    </ModalForm>
  );
};

CampaignStatisticModal.propTypes = {
  campaignStatistic: LeagueSidePropTypes.campaignStatistic,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  shouldResetOnSubmit: PropTypes.bool,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
  }),
};

CampaignStatisticModal.defaultProps = {
  campaignStatistic: {
    description: '',
    iconName: '',
    name: '',
    number: '',
  },
  shouldResetOnSubmit: false,
  toggleProps: {},
};

export default CampaignStatisticModal;
