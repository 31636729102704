import formatDate from 'utils/formatDate';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import Card from 'components/Card';
import NewWindowLink from 'components/NewWindowLink';
import type { Offer } from 'types/offer';
import OfferFormWithUserRole from './offer-form';

type OfferCardProps = {
  offer: Offer;
  refetchSponsorship: () => void;
};

const OfferCard = (props: OfferCardProps) => {
  const { offer, refetchSponsorship } = props;

  return (
    <Card data-test="offer">
      <div className="row">
        <h2 className="col">Offer</h2>
        <div className="col-auto">
          <NewWindowLink
            to={`${CAMPAIGN_PLANNER_PATH}/${offer.campaign.sponsor.id}/campaigns/${offer.campaign.id}/requirements`}
          >
            Jump to Requirements
          </NewWindowLink>
        </div>
      </div>
      {offer.offerSentAt ? (
        <p>{`An offer was last sent to the league on ${formatDate(
          offer.offerSentAt,
        )}.`}</p>
      ) : (
        <p>No offers have been sent to the organization yet!</p>
      )}
      {!!offer.leagueRejectionReason && (
        <div className="alert alert-warning">
          Organization rejected offer and gave this reason:
          <br />
          {offer.leagueRejectionReason}
        </div>
      )}
      <hr />
      <OfferFormWithUserRole
        offer={offer}
        refetchSponsorship={refetchSponsorship}
      />
    </Card>
  );
};

export default OfferCard;
