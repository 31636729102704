import * as React from 'react';
import StringInput from 'components/formInputs/StringInput';

interface Props extends React.HTMLAttributes<HTMLElement> {
  description: string;
}

const PackageDescriptionInput: React.FunctionComponent<Props> = ({
  description,
  onChange,
}: Props) => (
  <StringInput
    label="package description"
    type="text"
    helpBlock="Why should a sponsor buy this package, how is it different from other packages, how will this help your organization, etc."
    onChange={onChange}
    value={description || ''}
  />
);

export default PackageDescriptionInput;
