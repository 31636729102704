import { connect } from 'react-redux';
import { getUserId } from 'modules/authentication';
import { getAuthentication } from 'modules/index';
import { parseQueryString } from './string';

export const wrapWithUserId = connect((state) => ({
  userId: getUserId(getAuthentication(state)),
}));

export function getUserIdFrom(queryString) {
  const { userId } = parseQueryString(queryString);

  return Number(userId);
}
