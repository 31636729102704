import * as React from 'react';
import { statusEq } from 'utils/misc';
import type { VerificationDeadline } from 'types/offer';
import { VerificationImageWithAllFields } from 'types/verificationImage';
import VerificationImageCard from 'components/VerificationImageCard';
import ReviewVerificationImageModal from './review-verification-image-modal';

const statusEqVerified = statusEq('verified');

interface Props {
  displayHeader?: boolean;
  initialVerificationDeadlineId?: number;
  pendingCard?: boolean;
  verificationDeadlines: VerificationDeadline[];
  verificationImage: VerificationImageWithAllFields;
}

const VerificationImageCardWithReviewFooter: React.FunctionComponent<Props> = ({
  displayHeader = false,
  initialVerificationDeadlineId,
  pendingCard,
  verificationDeadlines,
  verificationImage,
}: Props) => {
  const isVerified = statusEqVerified(verificationImage);

  return (
    <VerificationImageCard
      border={isVerified ? undefined : 'warning'}
      displayHeader={displayHeader}
      verificationImage={verificationImage}
    >
      <ReviewVerificationImageModal
        initialVerificationDeadlineId={initialVerificationDeadlineId}
        pendingCard={pendingCard}
        togglePropsChildren={isVerified ? 'Update' : 'Review'}
        verificationDeadlines={verificationDeadlines}
        verificationImage={verificationImage as any}
        initialFeaturedStatus={verificationImage.featured}
      />
    </VerificationImageCard>
  );
};

export default VerificationImageCardWithReviewFooter;
