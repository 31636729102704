import * as React from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import HoverTable from 'components/HoverTable';
import Card from 'components/Card';
import RemoveOrganizationCapabilityModal from 'components/RemoveOrganizationCapability';

const TeamOfGamesJerseysTable = ({ teamOfGameJerseysCapabilities }) => {
  const row = (teamOfGameJerseysCapability) => {
    const capabilityDetail =
      teamOfGameJerseysCapability &&
      teamOfGameJerseysCapability.capabilityDetail;

    const logoPlacements = () => {
      const logoPlacementsArray = [];
      const { logoPlacements: allLogoPlacements } =
        capabilityDetail.capabilityDetailable;

      for (let i = 0; i < allLogoPlacements.length; i += 1) {
        logoPlacementsArray.push(allLogoPlacements[i]);
      }
      return logoPlacementsArray;
    };

    const renderSpanTag = (string) => (
      <>
        <span>{string}</span>
        <br />
      </>
    );

    return (
      <>
        {capabilityDetail ? (
          <tr
            key={capabilityDetail.id}
            data-test={`teamOfGameJerseysCapability-${capabilityDetail.id}`}
          >
            {capabilityDetail.capabilityDetailable ? (
              <>
                <td>{`${
                  capabilityDetail.capabilityDetailable.canUseLogo
                    ? 'Yes'
                    : 'No'
                }`}</td>
                <td>{map(renderSpanTag, logoPlacements())}</td>
                <td>{`${
                  capabilityDetail.capabilityDetailable.otherBrands
                    ? 'Yes'
                    : 'No'
                }`}</td>
                <td>{`${capabilityDetail.capabilityDetailable.minTeamsRequired}`}</td>
              </>
            ) : null}
            <td>{`${capabilityDetail.price}`}</td>
            <td>{capabilityDetail.maxForSale}</td>
            <td className="d-flex justify-content-end">
              <RemoveOrganizationCapabilityModal
                organizationCapability={teamOfGameJerseysCapability}
              />
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Card>
        <h3 data-test="team-of-game-jerseys-table-header">
          Team of Game Jerseys
        </h3>
        <HoverTable>
          <thead>
            <tr className="th-font-family-11">
              <th scope="col">Can Use Logo</th>
              <th scope="col">Logo Placements</th>
              <th scope="col">Other Brands</th>
              <th scope="col">Minimum Teams Required</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col" className="d-flex justify-content-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{map(row, teamOfGameJerseysCapabilities)}</tbody>
        </HoverTable>
      </Card>
    </>
  );
};

TeamOfGamesJerseysTable.propTypes = {
  teamOfGameJerseysCapabilities: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default TeamOfGamesJerseysTable;
