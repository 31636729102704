import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import {
  filter,
  isEmpty,
  map,
  mergeDeepRight,
  partition,
  path,
  pipe,
  prop,
  uniq,
} from 'ramda';
import gql from 'graphql-tag';
import classNames from 'classnames';
import { history } from 'store';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import {
  isProposedOrRejectedByOrganization,
  sponsorablePropertyName,
  sortBySponsorablePropertyName,
} from 'utils/offer';
import { useSubmit } from 'utils/hooks';
import { getId } from 'utils/id';
import { loadingTense } from 'utils/string';
import { isNotEmpty, pluckId } from 'utils/misc';
import { sortByName } from 'utils/sort';
import BooleanRadioInputs from 'components/formInputs/BooleanRadioInputs';
import TextAreaInput from 'components/formInputs/TextAreaInput';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';

const partitionCommunicatedToLeague = partition(prop('communicatedToLeague'));
const partitionProposedOrRejectedByOrganization = partition(
  isProposedOrRejectedByOrganization,
);

const organizationsWithOffers = (offers) => {
  const organizationPath = path(['sponsorableProperty', 'organization']);
  const organizations = uniq(map(organizationPath, offers));

  const organizationWithOffers = (organization) => {
    const { id: organizationId } = organization;
    const belongsToOrganization = (offer) =>
      organizationPath(offer).id === organizationId;
    return mergeDeepRight(organization, {
      offers: filter(belongsToOrganization, offers),
    });
  };

  return map(organizationWithOffers, organizations);
};

const renderOffer = (offer) => (
  <li key={offer.id}>{sponsorablePropertyName(offer)}</li>
);

const renderOrganizationWithOffers = (organization) => (
  <li key={organization.id}>
    {organization.name}
    <ul>
      {map(renderOffer, sortBySponsorablePropertyName(organization.offers))}
    </ul>
  </li>
);

const renderOffers = pipe(
  organizationsWithOffers,
  sortByName,
  map(renderOrganizationWithOffers),
);

const MUTATION = gql`
  mutation RemoveFromCampaign(
    $input: RemoveSponsorablePropertiesFromCampaignInput!
  ) {
    removeSponsorablePropertiesFromCampaign(input: $input) {
      campaign {
        id
      }
    }
  }
`;

const RemoveFromCampaignModal = ({
  offers,
  onRemove,
  prefix,
  pushUrl,
  toggleProps,
}) => {
  const [markAsUnqualified, setMarkAsUnqualified] = useState(false);
  const [removalReason, setRemovalReason] = useState('');

  const [mutate] = useMutation(MUTATION);

  const id = getId([prefix, 'remove-from-campaign']);

  const [offersToReject, offersNotToReject] =
    partitionProposedOrRejectedByOrganization(offers);
  const [offersWithCommunication, offersWithoutCommunication] =
    partitionCommunicatedToLeague(offersNotToReject);
  const noOffersToReject = isEmpty(offersToReject);
  const includesOffersToReject = !noOffersToReject;

  const redirectOnSubmit =
    pushUrl && !markAsUnqualified && isEmpty(offersWithCommunication);

  async function asyncSubmit() {
    const removeFromCampaign = () =>
      mutate({
        variables: {
          input: {
            markAsUnqualified,
            offerIds: pluckId(offers),
            removalReason,
          },
        },
      });

    if (redirectOnSubmit) {
      removeFromCampaign();
      history.push(pushUrl);
    } else {
      await removeFromCampaign().then(onRemove);
      setMarkAsUnqualified(false);
      setRemovalReason('');
    }
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const willNotBeContactedMessage = (
    <>
      &nbsp;(They&nbsp;
      <strong>will not</strong>
      &nbsp;be contacted about this change.)
    </>
  );

  const renderMarkAsUnqualifiedInput = (
    <BooleanRadioInputs
      disabled={loading}
      id="mark-as-unqualified"
      option1={
        <>
          <strong>Delete:</strong>
          &nbsp;Move sponsorable property(s) to “Available” and delete offer(s).
          &nbsp;The sponsorable property(s) will be able to be re-added to this
          campaign.
        </>
      }
      option2={
        <>
          <strong>Mark As Unqualified:</strong>
          &nbsp;Move sponsorable property(s) to “Removed” and mark offer(s) as
          “unqualified”. &nbsp;The sponsorable property(s) will not be able to
          be re-added to this campaign.
        </>
      }
      setValue={setMarkAsUnqualified}
      value={markAsUnqualified}
    />
  );

  return (
    <ModalForm
      id={`${id}-modal`}
      error={error}
      hasRequiredFields={includesOffersToReject}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Remove from Campaign'),
        className: classNames('btn btn-danger', { 'font-italic': loading }),
        disabled: loading || (includesOffersToReject && !removalReason),
      }}
      toggleProps={toggleProps}
      title="Remove from Campaign?"
    >
      <p>
        Are you sure you want to remove sponsorable property(s) from this
        campaign?
        {noOffersToReject && willNotBeContactedMessage}
      </p>
      {isNotEmpty(offersWithoutCommunication) && (
        <>
          We have&nbsp;
          <strong>not</strong>
          &nbsp;previously communicated with the following organization(s) about
          this campaign.
          {includesOffersToReject && willNotBeContactedMessage}
          &nbsp;Would you liked to delete or mark as unqualified?
          {renderMarkAsUnqualifiedInput}
          <ul>{renderOffers(offersWithoutCommunication)}</ul>
        </>
      )}
      {isNotEmpty(offersWithCommunication) && (
        <>
          We have previously communicated with the following organization(s), so
          that this sponsorable property will be marked &nbsp;as unqualified and
          not be able to be re-added to this campaign.
          {includesOffersToReject && willNotBeContactedMessage}
          <ul>{renderOffers(offersWithCommunication)}</ul>
        </>
      )}
      {includesOffersToReject && (
        <div className="alert alert-warning" data-test="communication-warning">
          <p>
            <strong>Warning: </strong>
            We have previously communicated with the following organization(s)
            about this campaign,&nbsp; so they will be marked as rejected and
            not be able to be re-added to this campaign. (They&nbsp;
            <strong>will</strong>
            &nbsp;be contacted about this change and be notified that they are
            no longer being considered for this &nbsp;campaign, so only remove
            them if you are absolutely sure they will not be needed for the
            campaign.)
          </p>
          <ul>{renderOffers(offersToReject)}</ul>
        </div>
      )}
      <TextAreaInput
        disabled={loading}
        formGroupClassName="my-3"
        inputId="removal-reason"
        label={`Provide a removal reason${
          includesOffersToReject ? '' : ' (optional)'
        }:`}
        onChange={(event) => setRemovalReason(event.target.value || null)}
        required={includesOffersToReject}
        value={removalReason}
      />
    </ModalForm>
  );
};

RemoveFromCampaignModal.propTypes = {
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  onRemove: PropTypes.func,
  prefix: PropTypes.string,
  pushUrl: PropTypes.string,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
  }),
};

RemoveFromCampaignModal.defaultProps = {
  onRemove: () => {},
  prefix: undefined,
  pushUrl: undefined,
  toggleProps: {
    className: 'btn btn-link dropdown-item text-danger',
    children: (
      <>
        <Icon className="mr-1" value="trash" />
        Remove from Campaign
      </>
    ),
  },
};

export default RemoveFromCampaignModal;
