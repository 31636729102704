import moment from 'moment';

export const DEFAULT_DATE_FORMAT = 'M/D/YYYY';
export const DEFAULT_DATE_INPUT_FORMATS = [
  'MM-DD-YYYY',
  'YYYY-MM-DD',
  'YYYY-MM-DD-HH-MM-SS',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ssZ',
];

const formatDate = (dateString, format = DEFAULT_DATE_FORMAT) =>
  moment(dateString, DEFAULT_DATE_INPUT_FORMATS).format(format);

export const renderLastLogin = (lastLogin) =>
  !!lastLogin && formatDate(lastLogin, 'MM-DD-YYYY h:mma');

export const convertToUnix = (dateString) =>
  String(Math.floor(new Date(dateString).getTime() / 1000));
const stringToDate = (dateString) => new Date(moment(dateString));
export const convertStringToDate = (dateString) =>
  dateString ? stringToDate(dateString) : null;
export const formatDateStringToISO = (dateString) =>
  dateString ? stringToDate(dateString).toISOString() : null;

export const formatDateInput = (value) =>
  value ? value.format('YYYY-MM-DD') : null;

export const ONE_YEAR_BEFORE = moment().subtract(365, 'days');
export const ONE_YEAR_AFTER = moment().add(365, 'days');
export const TWO_YEARS_AFTER = moment().add(730, 'days');

export default formatDate;
