import NumberInput from 'components/formInputs/NumberInput';
import SelectInput from 'components/formInputs/SelectInput';
import MultiSelect from 'components/MultiSelect';
import { RequiredAsterisk } from 'components/RequiredLabel';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import ErrorAlert from 'components/ErrorAlert';

const MUTATION = gql`
  mutation CreateGameSignage($input: CreateGameSignageInput!) {
    createGameSignage(input: $input) {
      organization {
        id
        name
        organizationCapabilities {
          id
          capabilityDetail {
            id
            maxForSale
            price
            __typename
            capabilityDetailable {
              ... on GameSignage {
                id
                canHang24Hours
                bannerPlacements
                bannerSizes
                organizationPlayLocations {
                  id
                  playLocation {
                    id
                    address
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const GameSignageCapabilityForm = ({
  organization,
  capabilityId,
  redirect,
}) => {
  const [mutate] = useMutation(MUTATION, {
    fetchPolicy: 'no-cache',
  });

  const [canHang24Hours, setCanHang24Hours] = useState(null);
  const [bannerSizes, setBannerSizes] = useState([]);
  const [bannerPlacements, setBannerPlacements] = useState([]);
  const [canHang6ftBanner, setCanHang6ftBanner] = useState(null);
  const [estimatedPriceForBanner, setEstimatedPriceForBanner] = useState(null);
  const [maximumNumberOfBanners, setMaximumNumberOfBanners] = useState(null);

  const [eightFootBanner, setEightFootBanner] = useState(false);
  const [fourFootBanner, setFourFootBanner] = useState(false);
  const [threeFootBanner, setThreeFootBanner] = useState(false);

  const [bannerHungAtEntrance, setBannerHungAtEntrance] = useState(false);
  const [bannerHungAtOutfield, setBannerHungAtOutfield] = useState(false);
  const [bannerHungAtExteriorOrWall, setBannerHungAtExteriorOrWall] =
    useState(false);
  const [bannerHungOnIndoorWall, setBannerHungOnIndoorWall] = useState(false);

  const [selectedPlayLocations, setSelectedPlayLocations] = useState([]);
  const [error, setError] = useState(false);
  // eslint-disable-next-line react/prop-types
  const playLocationsModel = organization.organizationPlayLocations.map(
    (organizationPlayLocation) => {
      // eslint-disable-next-line no-param-reassign
      organizationPlayLocation.playLocation.name =
        organizationPlayLocation.playLocation.address;
      const { playLocation } = organizationPlayLocation;
      return playLocation;
    },
  );

  const arePlayLocationsAlreadyUsed = () => {
    const locationsWithBanners = organization.organizationPlayLocations.filter(
      (location) => location.gameSignage,
    );

    return locationsWithBanners.some(
      (location) =>
        selectedPlayLocations.indexOf(location.playLocation.id) >= 0,
    );
  };

  useEffect(() => {
    const result = arePlayLocationsAlreadyUsed();
    setError(result);
    // eslint-disable-next-line
  }, [selectedPlayLocations]);

  const onSubmit = async (event) => {
    event.preventDefault();

    await mutate({
      variables: {
        input: {
          organizationId: organization.id,
          capabilityId,
          price: estimatedPriceForBanner,
          maxForSale: maximumNumberOfBanners,
          canHang24Hours: canHang24Hours === 'Yes',
          bannerSizes,
          bannerPlacements,
          playLocationsIds: selectedPlayLocations,
        },
      },
    });

    redirect();
  };

  const handleBannerSizes = (bannerSize) => {
    if (bannerSizes.includes(bannerSize)) {
      setBannerSizes((previousBannerSizes) =>
        previousBannerSizes.filter((sizes) => sizes !== bannerSize),
      );
    } else {
      setBannerSizes((previousBannerSizes) => [
        ...previousBannerSizes,
        bannerSize,
      ]);
    }
  };

  const handleBannerPlacement = (placement) => {
    if (bannerPlacements.includes(placement)) {
      setBannerPlacements((previousBannerPlacements) =>
        previousBannerPlacements.filter(
          (previousPlacement) => previousPlacement !== placement,
        ),
      );
    } else {
      setBannerPlacements((previousBannerPlacements) => [
        ...previousBannerPlacements,
        placement,
      ]);
    }
  };

  return (
    <form data-target="game-signage-form" onSubmit={onSubmit}>
      <SelectInput
        inputId="can-you-hang-24-hours"
        label="Can you leave the banner up 24/7?"
        labelClassName="font-weight-bold"
        noDefault
        onChange={({ target: { value } }) => setCanHang24Hours(value)}
        required
        value={canHang24Hours}
      >
        <option value="">-- Please choose one --</option>
        <option>Yes</option>
        <option>No</option>
      </SelectInput>
      <label htmlFor="PlayLocations">
        <strong>Play Locations(s)</strong>
        <RequiredAsterisk />
      </label>
      <MultiSelect
        id="PlayLocations"
        onChange={setSelectedPlayLocations}
        models={playLocationsModel}
        selectedIds={selectedPlayLocations}
      />
      {error && (
        <ErrorAlert>
          Play Location Already Exists on Another Capability!
        </ErrorAlert>
      )}
      <SelectInput
        inputId="can-you-hang-banner"
        label="Can you hang a banner that is 6 feet wide and 4 feet tall?"
        labelClassName="font-weight-bold"
        noDefault
        onChange={({ target: { value } }) => {
          const sixFeetWide4FeetTallBanner = '6 feet wide, 4 feet tall';
          setCanHang6ftBanner(value);
          if (
            value === 'Yes' ||
            (value !== 'Yes' &&
              bannerSizes.includes(sixFeetWide4FeetTallBanner))
          ) {
            handleBannerSizes(sixFeetWide4FeetTallBanner);
          }
        }}
        required
        value={canHang6ftBanner}
      >
        <option value="">-- Please choose an answer --</option>
        <option>Yes</option>
        <option>No</option>
      </SelectInput>
      {canHang6ftBanner === 'No' ? (
        <fieldset className="mb-3">
          <strong>
            Can you hang any of the following banner sizes instead?
          </strong>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={eightFootBanner}
              onChange={() => {
                setEightFootBanner(!eightFootBanner);
                handleBannerSizes('8 feet wide, 4 feet tall');
              }}
              data-test="eight-foot-banner-checkbox"
              id="defaultCheck1"
            />
            <label
              className="form-check-label"
              htmlFor="eight-foot-banner-checkbox"
            >
              8 feet wide, 4 feet tall
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={fourFootBanner}
              onChange={() => {
                setFourFootBanner(!fourFootBanner);
                handleBannerSizes('4 feet wide, 4 feet tall');
              }}
              data-test="four-foot-banner-checkbox"
            />
            <label
              className="form-check-label"
              htmlFor="four-foot-banner-checkbox"
            >
              4 feet wide, 4 feet tall
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={threeFootBanner}
              onChange={() => {
                setThreeFootBanner(!threeFootBanner);
                handleBannerSizes('3 feet wide, 5 feet tall');
              }}
              data-test="three-foot-banner-checkbox"
            />
            <label
              className="form-check-label"
              htmlFor="three-foot-banner-checkbox"
            >
              3 feet wide, 5 feet tall
            </label>
          </div>
        </fieldset>
      ) : null}
      <NumberInput
        label="What is the estimated price to sponsor 1 banner?"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) =>
          setEstimatedPriceForBanner(Number(value))
        }
        customId="estimated-price-of-banner-input"
        required
        step={1}
        value={estimatedPriceForBanner}
        placeholder="Please enter the estimated price"
      />
      <NumberInput
        label="What is the maximum number of banners we can sell?"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) =>
          setMaximumNumberOfBanners(Number(value))
        }
        customId="maximum-number-of-banners-we-could-sell-input"
        required
        step={1}
        value={maximumNumberOfBanners}
        placeholder="Please enter the maximum number of banners"
      />
      <fieldset className="mb-3">
        <strong>Where will the banner be hung?</strong>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={bannerHungAtEntrance}
            onChange={() => {
              setBannerHungAtEntrance(!bannerHungAtEntrance);
              handleBannerPlacement('Entrance to facility or field');
            }}
            data-test="banner-hung-at-entrance-checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="banner-hung-at-entrance-checkbox"
          >
            Entrance to facility or field
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={bannerHungAtOutfield}
            onChange={() => {
              setBannerHungAtOutfield(!bannerHungAtOutfield);
              handleBannerPlacement('Outfield fence');
            }}
            data-test="banner-hung-at-outfield-checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="banner-hung-at-outfield-checkbox"
          >
            Outfield fence
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={bannerHungAtExteriorOrWall}
            onChange={() => {
              setBannerHungAtExteriorOrWall(!bannerHungAtExteriorOrWall);
              handleBannerPlacement('Exterior fence or wall facing street');
            }}
            data-test="banner-hung-at-exterior-wall-checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="banner-hung-at-exterior-wall-checkbox"
          >
            Exterior fence or wall facing street
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={bannerHungOnIndoorWall}
            onChange={() => {
              setBannerHungOnIndoorWall(!bannerHungOnIndoorWall);
              handleBannerPlacement('Indoor wall');
            }}
            data-test="banner-hung-on-indoor-wall-checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="banner-hung-on-indoor-wall-checkbox"
          >
            Indoor wall
          </label>
        </div>
      </fieldset>
      <button
        type="submit"
        className="btn btn-primary"
        data-test="game-signage-capability-form-submit-button"
      >
        Submit
      </button>
    </form>
  );
};

GameSignageCapabilityForm.propTypes = {
  organization: LeagueSidePropTypes.organization.isRequired,
  capabilityId: PropTypes.number.isRequired,
  redirect: PropTypes.func.isRequired,
};

export default GameSignageCapabilityForm;
