import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'utils/string';

const ContactUsLink = (props) => {
  const { capitalized } = props;
  const label = 'reach out to us';

  return (
    <a href="mailto:support@sponsorship.teamsnap.com">
      {capitalized ? capitalize(label) : label}
    </a>
  );
};

ContactUsLink.propTypes = {
  capitalized: PropTypes.bool,
};

ContactUsLink.defaultProps = {
  capitalized: false,
};

export default ContactUsLink;
