import React from 'react';
import { Query } from 'react-apollo';
import { compose, map } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { sortByName } from 'utils/sort';

const renderOption = (option) => (
  <option key={option.id} value={option.id}>
    {option.name}
  </option>
);

const renderOptions = map(renderOption);
const renderOptionsAlphabetically = compose(renderOptions, sortByName);

export const OrganizationTypeUnwrapped = ({
  addChanges,
  disabled,
  organizationTypeId,
  organizationTypes,
}) => (
  <div className="list-group-item">
    <label htmlFor="organizationType" className="font-weight-bold">
      Organization Type
    </label>
    <select
      className="form-control"
      id="organizationType"
      onChange={(event) =>
        addChanges('update organizationTypeId', {
          organizationTypeId: event.target.value
            ? Number(event.target.value)
            : null,
        })
      }
      value={organizationTypeId || ''}
      disabled={disabled}
    >
      <option aria-label="no-selection" />
      {renderOptionsAlphabetically(organizationTypes)}
    </select>
  </div>
);

OrganizationTypeUnwrapped.propTypes = {
  addChanges: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  organizationTypeId: PropTypes.number,
  organizationTypes: PropTypes.arrayOf(LeagueSidePropTypes.organizationType)
    .isRequired,
};

OrganizationTypeUnwrapped.defaultProps = {
  disabled: false,
  organizationTypeId: null,
};

const QUERY = gql`
  query OrganizationTypes {
    organizationTypes {
      id
      name
    }
  }
`;

const OrganizationType = ({ addChanges, disabled, organizationTypeId }) => (
  <Query fetchPolicy="network-only" query={QUERY}>
    {({ data }) => (
      <OrganizationTypeUnwrapped
        addChanges={addChanges}
        disabled={disabled}
        organizationTypeId={organizationTypeId}
        organizationTypes={(data && data.organizationTypes) || []}
      />
    )}
  </Query>
);

OrganizationType.propTypes = {
  addChanges: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  organizationTypeId: PropTypes.number,
};

OrganizationType.defaultProps = {
  disabled: false,
  organizationTypeId: null,
};

export default OrganizationType;
