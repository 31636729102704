import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import classNames from 'classnames';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { conjugateString } from 'utils/string';
import { CAMPAIGN_TARGETING_OFFER_FRAGMENT } from 'fragments/OfferFragment';
import Icon from 'components/Icon';
import useUserPilotTracking from 'hooks/useUserPilotTracking';

const MUTATION = gql`
  mutation AddSponsorablePropertiesToCampaign(
    $input: AddSponsorablePropertiesToCampaignInput!
  ) {
    addSponsorablePropertiesToCampaign(input: $input) {
      campaign {
        id
        sponsor {
          id
          name
        }
        offers {
          ...CampaignTargetingOfferFragment
        }
      }
    }
  }
  ${CAMPAIGN_TARGETING_OFFER_FRAGMENT}
`;

const AddAllToCampaignButton = ({
  allAddedOrRemoved,
  allRemoved,
  campaignId,
  sponsorablePropertyIds,
}) => {
  const { sendUserPilotTrackingEvent } = useUserPilotTracking();
  const [loading, setLoading] = useState(false);
  const [mutate] = useMutation(MUTATION, {
    variables: { input: { campaignId, sponsorablePropertyIds } },
    onCompleted: (data) => {
      const { campaign } = data.addSponsorablePropertiesToCampaign;
      const { offers } = campaign;

      // eslint-disable-next-line array-callback-return
      offers.forEach((offer) => {
        sendUserPilotTrackingEvent('PreQual Offer Sent', {
          campaign_id: campaign.id,
          sponsor_name: campaign.sponsor.name,
          offer_id: offer.id,
          sponsorable_property_name: offer.sponsorableProperty.name,
        });
      });
    },
  });

  async function handleAdd() {
    setLoading(true);
    await mutate();
    setLoading(false);
  }

  if (allRemoved) return null;

  const disabled = loading || allAddedOrRemoved;

  return (
    <button
      className={classNames(
        `ml-2 btn btn-${disabled ? 'success' : 'outline-success'}`,
        { 'font-italic': loading },
      )}
      data-test="add-all-to-campaign"
      disabled={disabled}
      onClick={handleAdd}
      type="button"
    >
      {!loading && (
        <Icon className="mr-1" value={allAddedOrRemoved ? 'check' : 'plus'} />
      )}
      {conjugateString('Add All', loading, allAddedOrRemoved)}
    </button>
  );
};

AddAllToCampaignButton.propTypes = {
  allAddedOrRemoved: PropTypes.bool.isRequired,
  allRemoved: PropTypes.bool.isRequired,
  campaignId: PropTypes.number.isRequired,
  sponsorablePropertyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default AddAllToCampaignButton;
