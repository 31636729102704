import React from 'react';
import PropTypes from 'prop-types';
import { hubspotUrl } from 'utils/urls';
import NewWindowLink from 'components/NewWindowLink';

const HubspotLink = ({ hubspotCompanyId }) => {
  const url = hubspotUrl(hubspotCompanyId);

  return (
    <NewWindowLink to={url} title="Visit Hubspot" data-test="hubspot-link" />
  );
};

HubspotLink.propTypes = {
  hubspotCompanyId: PropTypes.number.isRequired,
};

export default HubspotLink;
