import React from 'react';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import AddProspectsModal from './AddProspectsModal';

const AddProspectsDropdown = ({
  campaignId,
  campaignName,
  onAdd,
  sponsorId,
}) => (
  <div className="dropdown d-inline-block">
    <button
      className="btn btn-large btn-secondary dropdown-toggle"
      type="button"
      id="add-prospects-dropdown-menu-button"
      data-toggle="dropdown"
    >
      <span className="icon icon-plus mr-2" />
      Add
    </button>
    <div
      className="dropdown-menu"
      aria-labelledby="download-dropdown-menu-button"
    >
      <Link
        className="btn btn-link dropdown-item"
        to={`${CAMPAIGN_PLANNER_PATH}/${sponsorId}/campaigns/${campaignId}/campaign-search`}
      >
        <Icon className="mr-2" value="map" />
        By Location
      </Link>
      <AddProspectsModal
        campaign={{ id: campaignId, name: campaignName }}
        onAdd={onAdd}
        toggleProps={{
          className: 'btn btn-link dropdown-item',
          children: (
            <>
              <Icon className="mr-2" value="magnifying-glass" />
              By Name
            </>
          ),
        }}
      />
    </div>
  </div>
);

AddProspectsDropdown.propTypes = {
  campaignId: PropTypes.number.isRequired,
  campaignName: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  sponsorId: PropTypes.number.isRequired,
};

export default AddProspectsDropdown;
