import { filter, pipe, pluck, sum } from 'ramda';
import { statusEq } from './misc';

export const isPending = statusEq('pending');
export const isApproved = statusEq('approved');
export const isRejected = statusEq('rejected');

const sumAmountsWithStatus = (status) =>
  pipe(filter(statusEq(status)), pluck('amount'), sum);

export const sumApprovedAmounts = sumAmountsWithStatus('approved');
export const sumPendingAmounts = sumAmountsWithStatus('pending');
