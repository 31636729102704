/* eslint-disable object-shorthand */
import moment from 'moment';

const isDateBeforeMinSeasonStartDate = (seasonStartDate, date) =>
  moment(date).isBefore(seasonStartDate);

const isDateAfterMaxSeasonEndDate = (seasonEndDate, date) =>
  moment(date).isAfter(seasonEndDate) &&
  !moment(date).isSame(seasonEndDate, 'day');

export const dateOutsideMinAndMaxSeasonStartDates = (
  seasonStartDate,
  seasonEndDate,
  date,
) =>
  isDateBeforeMinSeasonStartDate(seasonStartDate, date) ||
  isDateAfterMaxSeasonEndDate(seasonEndDate, date);

let errorMessages = [];
const resetErrorMessagesState = () => {
  errorMessages = [];
};
const getIndexOfErrorMessage = (substring) => errorMessages.indexOf(substring);
const errorMessageDoesNotExistsInArray = (substring) =>
  getIndexOfErrorMessage(substring) === -1;
const errorMessageExistsInArray = (substring) =>
  getIndexOfErrorMessage(substring) !== -1;

const addErrorMessagesToArray = (
  isStartDateBetweenMinAndMaxSeasonStartDate,
  startDateRangeErrorMessage,
  isEndDateRangeFromStartDateCorrect,
  endDateRangeErrorMessage,
) => {
  if (
    !isStartDateBetweenMinAndMaxSeasonStartDate &&
    errorMessageDoesNotExistsInArray(startDateRangeErrorMessage)
  ) {
    errorMessages.push(startDateRangeErrorMessage);
  }

  if (
    !isEndDateRangeFromStartDateCorrect &&
    errorMessageDoesNotExistsInArray(endDateRangeErrorMessage)
  ) {
    errorMessages.push(endDateRangeErrorMessage);
  }
};

const removeErrorMessagesFromArray = (
  isStartDateBetweenMinAndMaxSeasonStartDate,
  startDateRangeErrorMessage,
  isEndDateRangeFromStartDateCorrect,
  endDateRangeErrorMessage,
) => {
  if (
    isStartDateBetweenMinAndMaxSeasonStartDate &&
    errorMessageExistsInArray(startDateRangeErrorMessage)
  ) {
    errorMessages.splice(errorMessages.indexOf(startDateRangeErrorMessage), 1);
  }

  if (
    isEndDateRangeFromStartDateCorrect &&
    errorMessageExistsInArray(endDateRangeErrorMessage)
  ) {
    errorMessages.splice(errorMessages.indexOf(endDateRangeErrorMessage), 1);
  }
};

// dec 11 2022
// march 1 2023

const seasonValidation = (
  startDate,
  endDate,
  minSeasonStartDate,
  maxSeasonStartDate,
  daysGreaterThan,
  daysLessThan,
  seasonName,
) => {
  if (!startDate) return errorMessages;

  const startDateWithStartingRange = startDate
    .clone()
    .add('days', daysGreaterThan);
  const startDatingWithEndingRange = startDate
    .clone()
    .add('days', daysLessThan);
  const isStartDateBetweenMinAndMaxSeasonStartDate =
    !dateOutsideMinAndMaxSeasonStartDates(
      minSeasonStartDate,
      maxSeasonStartDate,
      startDate,
    );
  const isEndDateRangeFromStartDateCorrect = endDate
    ? !dateOutsideMinAndMaxSeasonStartDates(
        startDateWithStartingRange,
        startDatingWithEndingRange,
        endDate,
      )
    : null;

  const startDateRangeErrorMessage = `Are you sure that this is the right start date? As a reminder this is for the ${seasonName} season`;
  const endDateRangeErrorMessage = `Do you think that this end date is correct? For the ${seasonName} season we expect it to be between ${daysGreaterThan} and ${daysLessThan} days from the selected start date.`;

  addErrorMessagesToArray(
    isStartDateBetweenMinAndMaxSeasonStartDate,
    startDateRangeErrorMessage,
    isEndDateRangeFromStartDateCorrect,
    endDateRangeErrorMessage,
  );

  removeErrorMessagesFromArray(
    isStartDateBetweenMinAndMaxSeasonStartDate,
    startDateRangeErrorMessage,
    isEndDateRangeFromStartDateCorrect,
    endDateRangeErrorMessage,
  );

  return errorMessages;
};

export const getSeasonsForValidation = (season) => {
  const seasons = {
    spring: {
      minSeasonStartDate: '02/05/23',
      maxSeasonStartDate: '04/26/23',
      reset: resetErrorMessagesState,
      // eslint-disable-next-line func-names
      validate: function (startDate, endDate) {
        return seasonValidation(
          startDate,
          endDate,
          this.minSeasonStartDate,
          this.maxSeasonStartDate,
          45,
          125,
          'spring',
        );
      },
    },
    summer: {
      minSeasonStartDate: '05/14/23',
      maxSeasonStartDate: '07/23/23',
      reset: resetErrorMessagesState,
      // eslint-disable-next-line func-names
      validate: function (startDate, endDate) {
        return seasonValidation(
          startDate,
          endDate,
          this.minSeasonStartDate,
          this.maxSeasonStartDate,
          1,
          75,
          'summer',
        );
      },
    },
    fall: {
      minSeasonStartDate: '07/17/23',
      maxSeasonStartDate: '10/05/23',
      reset: resetErrorMessagesState,
      // eslint-disable-next-line func-names
      validate: function (startDate, endDate) {
        return seasonValidation(
          startDate,
          endDate,
          this.minSeasonStartDate,
          this.maxSeasonStartDate,
          40,
          120,
          'fall',
        );
      },
    },
    winter: {
      minSeasonStartDate: '09/13/22',
      maxSeasonStartDate: '12/02/22',
      reset: resetErrorMessagesState,
      // eslint-disable-next-line func-names
      validate: function (startDate, endDate) {
        return seasonValidation(
          startDate,
          endDate,
          this.minSeasonStartDate,
          this.maxSeasonStartDate,
          40,
          120,
          'winter',
        );
      },
    },
  };

  return seasons[season];
};
