import React from 'react';
import { Mutation } from 'react-apollo';
import { map, pluck } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { useSubmit } from 'utils/hooks';
import ModalForm from 'components/ModalForm';
import { sortByOrganizationName } from 'utils/offer';
import Icon from 'components/Icon';

export const ADD_HUBSPOT_CAMPAIGN_PROPERTY_TO_CONTACTS_COPY =
  'Add Hubspot Campaign Property to Contacts';

const renderOffer = (offer) => (
  <li key={offer.id}>{offer.sponsorableProperty.organization.name}</li>
);

const renderToggle = () => (
  <>
    <Icon className="mr-1" value="check" />
    {ADD_HUBSPOT_CAMPAIGN_PROPERTY_TO_CONTACTS_COPY}
  </>
);

export const AddHubspotCampaignPropertyToContactsModalUnwrapped = ({
  id,
  offers,
  toggleClassName,
  onSubmit,
  showHubspotSyncAlert,
}) => {
  const { error, loading, handleSubmit } = useSubmit(onSubmit);

  const submitAndShowAlert = async (e) => {
    await handleSubmit(e);
    showHubspotSyncAlert(true);
  };

  return (
    <ModalForm
      id={id}
      error={error}
      loading={loading}
      onSubmit={submitAndShowAlert}
      submitProps={{ children: ADD_HUBSPOT_CAMPAIGN_PROPERTY_TO_CONTACTS_COPY }}
      toggleProps={{
        className: toggleClassName,
        children: renderToggle(),
      }}
      title={ADD_HUBSPOT_CAMPAIGN_PROPERTY_TO_CONTACTS_COPY}
    >
      Are you sure you want to add the campaign property to all of the contacts
      in the following organizations?
      <ul>{map(renderOffer, sortByOrganizationName(offers))}</ul>
    </ModalForm>
  );
};

AddHubspotCampaignPropertyToContactsModalUnwrapped.propTypes = {
  id: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  toggleClassName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showHubspotSyncAlert: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation AddHubspotCampaignPropertyToContacts(
    $input: AddHubspotCampaignPropertyToContactsInput!
  ) {
    addHubspotCampaignPropertyToContacts(input: $input) {
      offers {
        id
      }
    }
  }
`;

const AddHubspotCampaignPropertyToContactsModal = (props) => {
  const { offers } = props;
  return (
    <Mutation
      mutation={MUTATION}
      variables={{ input: { offerIds: pluck('id', offers) } }}
    >
      {(mutate) => (
        <AddHubspotCampaignPropertyToContactsModalUnwrapped
          onSubmit={() => mutate()}
          {...props}
        />
      )}
    </Mutation>
  );
};

AddHubspotCampaignPropertyToContactsModal.propTypes = {
  id: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  toggleClassName: PropTypes.string.isRequired,
  showHubspotSyncAlert: PropTypes.func.isRequired,
};

export default AddHubspotCampaignPropertyToContactsModal;
