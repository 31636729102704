import React from 'react';
import { useQuery } from 'react-apollo';
import { map } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import ORGANIZATION_PACKAGE_FRAGMENT from 'fragments/OrganizationPackageFragment';
import ModalDisplay from 'components/ModalDisplay';
import SponsorablePropertyPackageCard from 'components/SponsorablePropertyPackageCard';

const QUERY = gql`
  query SponsorableProperty($id: Int!) {
    sponsorableProperty(id: $id) {
      id
      organizationPackages {
        ...OrganizationPackageFragment
      }
    }
  }
  ${ORGANIZATION_PACKAGE_FRAGMENT}
`;

const DisplaySponsorablePropertyPackagesModal = ({
  sponsorablePropertyId,
  sponsorablePropertyPackagesLabel,
}) => {
  const { data, loading } = useQuery(QUERY, {
    variables: { id: sponsorablePropertyId },
  });

  const organizationPackages =
    data &&
    data.sponsorableProperty &&
    data.sponsorableProperty.organizationPackages;

  return !loading && organizationPackages ? (
    <ModalDisplay
      id={`sponsorable-property-packages-${sponsorablePropertyId}-modal`}
      size="large"
      title="Sponsorable Property Packages"
      toggleProps={{
        className: 'btn btn-link btn-inline-link font-inherit',
        children: sponsorablePropertyPackagesLabel,
      }}
    >
      <div className="row">
        {map(
          (organizationPackage) => (
            <div key={organizationPackage.id} className="col col-12 col-lg-6">
              <SponsorablePropertyPackageCard
                sponsorablePropertyPackage={organizationPackage}
              />
            </div>
          ),
          organizationPackages,
        )}
      </div>
    </ModalDisplay>
  ) : null;
};

DisplaySponsorablePropertyPackagesModal.propTypes = {
  sponsorablePropertyId: PropTypes.number.isRequired,
  sponsorablePropertyPackagesLabel: PropTypes.string.isRequired,
};

export default DisplaySponsorablePropertyPackagesModal;
