import { __, evolve, merge, prop, T, F, update } from 'ramda';
import { createReducer } from 'redux-create-reducer';
import { combineReducers } from 'redux';
import { LOCATION_CHANGE } from 'react-router-redux';
import LeaguesideAPI from 'utils/leagueside-api';

const LOAD_SUCCESS = 'searchFilters/loadSuccess';
const PATCH = 'searchFilters/patch';
const FILTER_RECEIVED = 'searchFilters/filterReceived';
const CREATE = 'searchFilters/create';
const DELETE_FILTER = 'searchFilters/delete';
const UPLOAD_SUCCESS = 'locationLists/uploadSuccess';

export const loadSuccess = (payload) => ({ type: LOAD_SUCCESS, payload });
export const patch = (payload) => ({ type: PATCH, payload });
export const filterReceived = (payload) => ({ type: FILTER_RECEIVED, payload });
export const deleteFilter = (payload) => async (dispatch, getState) => {
  const searchFilter = getState().campaign.searchFilters.byId[payload];

  if (!searchFilter.temporaryId) {
    await LeaguesideAPI.deleteSearchFilter(payload);
  }

  dispatch({ type: DELETE_FILTER, payload });
};

function getDefaultParameters(type) {
  switch (type) {
    case 'IncomeFilter':
      return { lowBound: 50000, highBound: 75000 };
    case 'SportFilter':
      return { sportIds: [] };
    case 'CapabilityFilter':
      return { capabilityIds: [] };
    default:
      return {};
  }
}

export function create(campaignId, type) {
  const id = new Date().getTime();
  const filter = {
    id,
    enabled: true,
    temporaryId: id,
    type,
    parameters: getDefaultParameters(type),
    campaignId,
  };

  return { type: CREATE, payload: filter };
}

export function load(campaignId) {
  return (dispatch) =>
    LeaguesideAPI.getSearchFilters(campaignId).then((searchFilters) =>
      dispatch(loadSuccess(searchFilters)),
    );
}

export function save(filterId, props) {
  return (dispatch, getState) => {
    dispatch(patch({ filterId, props }));

    const searchFilter = getState().campaign.searchFilters.byId[filterId];

    const promise = searchFilter.temporaryId
      ? LeaguesideAPI.createSearchFilter(searchFilter.campaignId, searchFilter)
      : LeaguesideAPI.patchSearchFilter(filterId, props);

    return promise.then((filter) =>
      dispatch(
        filterReceived({
          temporaryId: searchFilter.temporaryId,
          filter,
        }),
      ),
    );
  };
}

function createDictionaryById(payload) {
  return payload.reduce(
    (byId, record) => ({ ...byId, [record.id]: record }),
    {},
  );
}

export const allIds = createReducer([], {
  [LOAD_SUCCESS]: (state, action) => action.payload.map(prop('id')),
  [CREATE]: (state, { payload }) => state.concat([payload.id]),
  [DELETE_FILTER]: (state, { payload }) => state.filter((id) => id !== payload),
  [FILTER_RECEIVED]: (state, { payload }) => {
    const index = state.indexOf(payload.temporaryId);

    if (index > -1) {
      return update(index, payload.filter.id)(state);
    }

    return state;
  },
  [UPLOAD_SUCCESS]: (state, { payload }) => {
    const index = state.indexOf(payload.temporaryId);
    if (index > -1) {
      return update(index, payload.searchFilter.id)(state);
    }

    return state;
  },
});

export const byId = createReducer(
  {},
  {
    [LOAD_SUCCESS]: (state, { payload }) => createDictionaryById(payload),
    [PATCH]: (state, { payload }) =>
      evolve({
        [payload.filterId]: merge(__, payload.props),
      })(state),
    [FILTER_RECEIVED]: (state, { payload }) => ({
      ...state,
      [payload.filter.id]: payload.filter,
      [payload.temporaryId]: undefined,
    }),
    [CREATE]: (state, { payload }) => ({ ...state, [payload.id]: payload }),
    [DELETE_FILTER]: (state, { payload }) => ({
      ...state,
      [payload]: undefined,
    }),
    [UPLOAD_SUCCESS]: (state, { payload }) => ({
      ...state,
      [payload.searchFilter.id]: payload.searchFilter,
      [payload.temporaryId]: undefined,
    }),
  },
);

export const loaded = createReducer(false, {
  [LOAD_SUCCESS]: T,
  [LOCATION_CHANGE]: F,
});

export default combineReducers({
  allIds,
  byId,
  loaded,
});

export const getLoaded = prop('loaded');
export const getSearchFilter = (searchFilters, id) => searchFilters.byId[id];

export function getAll(searchFilters) {
  return searchFilters.allIds.map((id) => getSearchFilter(searchFilters, id));
}
