import gql from 'graphql-tag';

const PLAY_LOCATION_FRAGMENT = gql`
  fragment PlayLocationFragment on PlayLocation {
    id
    address
    lat
    lon
    nickname
    primary
  }
`;

export default PLAY_LOCATION_FRAGMENT;
