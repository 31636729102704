import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import rootReducer from 'modules';
import catchErrors from 'utils/catchErrors';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import userpilot from 'utils/userpilot';

export const history = createHistory();
const env = runtimeEnv();

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history), catchErrors];

if (!window.Cypress) {
  middleware.push(userpilot);
}

if (env.NODE_ENV === 'development') {
  const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

window.store = store;

export default store;
