export default function copyToClipboard(text) {
  const input = document.createElement('input');
  const { body } = document;

  body.appendChild(input);
  input.setAttribute('value', text);
  input.select();
  document.execCommand('copy');
  body.removeChild(input);
}
