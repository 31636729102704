import { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { useWizardContext } from 'pages/qualify/provider';
import {
  Inputs,
  Organization,
  SponsorshipApplicationOptions,
  StepIdsEnum,
} from 'pages/qualify/types';
import { parseAnyListToOptionsList } from 'pages/qualify/utils';
import { SPONSORSHIP_APPLICATION_OPTIONS_LIST_QUERY } from 'pages/qualify/queries';
import { WIZARD_IMAGES_PER_STEP_ID } from 'pages/qualify/wizard-images-urls';
import WizardInput from '../wizard-input';
import WizardStep from '../wizard-step';

export default function BannersStep() {
  const { tokenData, watch, values, token, setValues, organizationData } =
    useWizardContext();
  const {
    data: applicationAssetQueryData,
    loading,
    refetch,
  } = useQuery<{
    sponsorshipApplicationView: Partial<Organization>;
  }>(APPLICATION_BANNERS_ASSETS_QUERY, {
    variables: { season: tokenData.season, year: tokenData.year },
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });
  const { data: sponsorshipApplicationOptionsListQueryData } =
    useQuery<SponsorshipApplicationOptions>(
      SPONSORSHIP_APPLICATION_OPTIONS_LIST_QUERY,
      { context: { headers: { Authorization: `Bearer ${token}` } } },
    );
  const [performMutation] = useMutation(MUTATION);
  const accepted = watch('accepted');
  const fourBySixBanner = watch('fourBySixBanner');

  const determineBannerSizes = (
    fourBySix: boolean | undefined,
    popUp: boolean | undefined,
  ) => {
    return fourBySix ? ['6 feet wide, 4 feet tall'] : popUp ? ['Pop Up'] : []; //eslint-disable-line
  };
  const modifyInputsBeforeMutation = (
    data: Partial<Inputs>,
  ): Partial<Inputs> => {
    const { fourBySixBanner: inputFourBySix, popUpBanner, ...inputData } = data;
    return {
      ...inputData,
      maxForSale: Number(data.maxForSale),
      bannerSizes: determineBannerSizes(inputFourBySix, popUpBanner),
    }; // Add a comma here
  };

  useEffect(() => {
    if (values.organizationId) {
      const { savedStepId } = organizationData?.sponsorshipApplication;
      if (savedStepId === StepIdsEnum.GAME_SIGNAGE) return;
      const { gameSignageDetail } =
        applicationAssetQueryData?.sponsorshipApplicationView
          .sponsorshipApplication ?? {};
      setValues({
        ...values,
        accepted:
          applicationAssetQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.gameSignageAccepted ?? false,
        canHang24Hours:
          gameSignageDetail?.capabilityDetailable.canHang24Hours ?? false,
        maxForSale: gameSignageDetail?.maxForSale,
        price: gameSignageDetail?.price,
        bannerSizes: gameSignageDetail?.capabilityDetailable.bannerSizes,
        fourBySixBanner:
          gameSignageDetail?.capabilityDetailable.bannerSizes?.includes(
            '6 feet wide, 4 feet tall',
          ) || !gameSignageDetail,
        popUpBanner:
          gameSignageDetail?.capabilityDetailable.bannerSizes?.includes(
            'Pop Up',
          ),
        bannerPlacements:
          gameSignageDetail?.capabilityDetailable.bannerPlacements,
        sponsorablePropertyIds:
          gameSignageDetail?.associatedSponsorableProperties?.map(
            (property) => property.id,
          ),
        refusalReason:
          applicationAssetQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.gameSignageRefusalReason,
      });
    }
  }, [values.organizationId, applicationAssetQueryData]);

  return (
    <WizardStep
      isLoading={loading}
      performMutation={performMutation}
      refetchAfterMutation={refetch}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId:
          applicationAssetQueryData?.sponsorshipApplicationView
            ?.sponsorshipApplication?.id,
        offerId: tokenData.offer_id,
        id: applicationAssetQueryData?.sponsorshipApplicationView
          .sponsorshipApplication?.gameSignageDetail?.capabilityDetailable?.id,
      }}
      modifyInputsBeforeMutation={modifyInputsBeforeMutation}
    >
      <WizardStep.Picture
        src={WIZARD_IMAGES_PER_STEP_ID[StepIdsEnum.GAME_SIGNAGE]}
      />
      <WizardStep.Content
        title="Banners"
        subtitle="Banners are printed and delivered at no cost to you. Banners must be displayed in high-visibility areas at your primary location."
      >
        <div className="sui-grid" style={{ gap: 24 }}>
          <WizardInput
            type="boolean-question"
            name="accepted"
            label="My organization is capable of hanging at least one banner in a high-traffic  centralized location"
            helpText="Average sponsorship funding $225-$300 per banner"
          />

          {accepted && (
            <>
              <WizardInput
                type="boolean-question"
                name="canHang24Hours"
                label="Banners can be displayed 24/7"
              />
              <WizardInput
                type="number"
                name="maxForSale"
                label="Max number of banners that can be accommodated"
              />
              <WizardInput
                type="boolean-question"
                name="fourBySixBanner"
                label="Can you support a 4x6 banner?"
              />
              {!fourBySixBanner && (
                <WizardInput
                  type="boolean-question"
                  name="popUpBanner"
                  label="Can you support a pop up banner?"
                />
              )}

              <WizardInput
                type="select"
                isMulti
                name="bannerPlacements"
                label="Available high-visibility banner locations"
                options={parseAnyListToOptionsList(
                  sponsorshipApplicationOptionsListQueryData?.bannerPlacementsList ??
                    [],
                  { withEmptyOption: false },
                )}
              />
              <WizardInput
                type="select"
                isMulti
                name="sponsorablePropertyIds"
                label="Programs this applies to"
                rules={{ required: 'Please select at least one program' }}
                options={parseAnyListToOptionsList(
                  values.sponsorableProperties ?? [],
                  { withEmptyOption: false },
                )}
              />
            </>
          )}
          {!accepted && (
            <>
              <WizardInput
                type="textarea"
                name="refusalReason"
                label="If possible, please share your reasons and specify the amount of funding at which you might reconsider opting in to this asset"
              />
            </>
          )}
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const APPLICATION_BANNERS_ASSETS_QUERY = gql`
  query SponsorshipApplicationView($season: String!, $year: Int!) {
    sponsorshipApplicationView {
      id
      sponsorshipApplication(season: $season, year: $year) {
        id
        gameSignageAccepted
        gameSignageRefusalReason
        gameSignageDetail {
          id
          price
          maxForSale
          associatedSponsorableProperties {
            id
            name
          }
          capabilityDetailable {
            ... on GameSignage {
              id
              canHang24Hours
              bannerSizes
              bannerPlacements
            }
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation upsertGameSignage($input: UpsertGameSignageInput!) {
    upsertGameSignage(input: $input) {
      id
    }
  }
`;
