import LeagueCapabilityPackage from './leagueCapabilityPackage';

export enum PackageType {
  UserGenerated = 'user_generated',
  Website = 'website',
  LeaguesideOffer = 'leagueside_offer',
}

export default interface LeaguePackage {
  id: number;
  leagueCapabilityPackages: LeagueCapabilityPackage[];
  name: string;
  price: number;
}
