import * as React from 'react';
import classNames from 'classnames';
import OrganizationType from 'types/organizationCapability';
import ArchiveOrganizationCapabilityModal from 'components/ArchiveOrganizationCapabilityModal';

export interface OrganizationCapability extends OrganizationType {
  archived: boolean;
  organizationCapabilityPackages: any[];
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  added?: boolean;
  organizationCapability: OrganizationCapability;
}

const OrganizationCapabilityCard: React.FunctionComponent<Props> = ({
  added,
  children,
  organizationCapability,
}: Props) => {
  const {
    id: organizationCapabilityId,
    archived,
    description,
    name,
  } = organizationCapability;

  return (
    <div
      className={classNames('card bg-light border-0 my-2', {
        'text-muted': archived || added,
      })}
      data-test={`organization-capability-card-${organizationCapabilityId}`}
    >
      <div className="card-body pt-2 pb-3">
        <div className="row align-items-center">
          <h6 className="col m-0">{name}</h6>
          <div className="col col-auto text-right">
            <ArchiveOrganizationCapabilityModal
              organizationCapability={organizationCapability}
            />
          </div>
        </div>
        {!!description && <p className="card-text mt-1 mb-0">{description}</p>}
        {children}
      </div>
    </div>
  );
};

OrganizationCapabilityCard.defaultProps = {
  added: false,
};

export default OrganizationCapabilityCard;
