// @ts-ignore-next-line
import { Map, TileLayer, Marker } from 'react-leaflet';
import { both, complement, equals, filter, map, where } from 'ramda';
import { hasValidGeoData } from 'utils/league';
import { getPoint, isRemoved } from 'utils/offer';
import 'leaflet/dist/leaflet.css';
import MapCircle from 'components/MapCircle';
import LabeledTooltip from 'components/LabeledTooltip';
import { buildSponsorablePropertyIcon } from 'components/map/Map.utilities';
import type { Offer } from 'types/offer';
import type { Radius } from 'types/campaign';

const doesNotEqual = complement(equals);
const isNotRemoved = complement(isRemoved);

const MIN_ZOOM = 2;
const MAX_ZOOM = 13;

type SponsorshipMapProps = {
  offer: Offer;
};

const SponsorshipMap = (props: SponsorshipMapProps) => {
  const { offer } = props;
  const { campaign } = offer;

  const otherOffers = filter(
    both(
      isNotRemoved,
      where({
        id: doesNotEqual(offer.id),
        sponsorableProperty: hasValidGeoData,
      }),
    ),
    campaign.offers,
  );

  return (
    <Map
      className="sponsorship-map"
      center={getPoint(offer)}
      minZoom={MIN_ZOOM}
      maxZoom={MAX_ZOOM}
      zoom={MAX_ZOOM + 2}
      style={{ height: '50vh', width: '100%' }}
    >
      <TileLayer
        attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
        url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
      />
      {renderRadii(campaign.radii)}
      {renderOfferMarker(offer, ['focus'])}
      {otherOffers.map((otherOffer) => renderOfferMarker(otherOffer))}
    </Map>
  );
};

export default SponsorshipMap;

const renderRadii = map((radius) => (
  <MapCircle radius={radius as Radius} key={(radius as Radius).label} />
));

const renderOfferMarker = (offer: Offer, modifiers: string[] = []) => (
  <Marker
    key={offer.id}
    icon={buildSponsorablePropertyIcon({ modifiers })}
    position={getPoint(offer)}
  >
    <LabeledTooltip
      text={`${offer.sponsorableProperty.name} (${offer.status})`}
    />
  </Marker>
);
