import React from 'react';
import { useMutation } from 'react-apollo';
import { always, anyPass, cond, pipe, prop, map, merge, T } from 'ramda';
import {
  isCanceled,
  isConfirmed,
  isProposed,
  isQualified,
  isRejected,
  isRejectedByLeague,
  isUnqualified,
} from 'utils/offer';
import { isNotEmpty } from 'utils/misc';
import type { Offer } from 'types/offer';
import ChecksCard from './checks-card';
import PendingVerificationsCard from './pending-verifications-card';
import MarkAsQualifiedCard from './mark-as-qualified-card';
import OfferCard from './offer-card';
import ConfirmedSponsorshipsCard from './confirmed-sponsorships-card';
import SponsorablePropertyProfileCard from './sponsorable-property-profile-card';
import SurveyCard from './survey-card';
import ContractsCard from './contracts-card';
import { CREATE_CHECK_MUTATION } from '../queries';

const createElement = (columnProps: any) => (componentType: any) => {
  const { name } = componentType;
  return React.createElement(componentType, merge(columnProps, { key: name }));
};

type CardsColumnsProps = {
  offer: Offer;
  refetchSponsorship: () => void;
};

export default function CardsColumns(props: CardsColumnsProps) {
  const { offer, refetchSponsorship } = props;
  const { leftColumn, rightColumn } = getColumns(offer ?? {});
  const [mutate] = useMutation(CREATE_CHECK_MUTATION);

  const createCheck = (input: any) => mutate({ variables: { input } });
  const renderCard = createElement({
    offer,
    createCheck,
    refetchSponsorship,
  });
  const renderColumn = map(renderCard);

  return (
    <div className="row flex-row-reverse">
      <div className="col">{renderColumn(rightColumn)}</div>
      <div className="col">{renderColumn(leftColumn)}</div>
    </div>
  );
}

const hasSurveyResponses = pipe(
  prop('preQualSurveyResponses') as any,
  isNotEmpty,
);
const isConfirmedOrCanceled = anyPass([isConfirmed, isCanceled]);

const getColumns = cond([
  [
    anyPass([isQualified, isProposed, isRejectedByLeague, isRejected]),
    always({
      leftColumn: [
        ConfirmedSponsorshipsCard,
        SponsorablePropertyProfileCard,
        SurveyCard,
      ],
      rightColumn: [OfferCard],
    }),
  ],
  [
    isConfirmedOrCanceled,
    always({
      leftColumn: [
        OfferCard,
        ConfirmedSponsorshipsCard,
        SponsorablePropertyProfileCard,
        SurveyCard,
      ],
      rightColumn: [ContractsCard, ChecksCard, PendingVerificationsCard],
    }),
  ],
  [
    isUnqualified,
    always({
      leftColumn: [ConfirmedSponsorshipsCard, SponsorablePropertyProfileCard],
      rightColumn: [SurveyCard],
    }),
  ],
  [
    hasSurveyResponses,
    always({
      leftColumn: [
        SurveyCard,
        ConfirmedSponsorshipsCard,
        SponsorablePropertyProfileCard,
      ],
      rightColumn: [MarkAsQualifiedCard],
    }),
  ],
  [
    T,
    always({
      leftColumn: [ConfirmedSponsorshipsCard, SponsorablePropertyProfileCard],
      rightColumn: [SurveyCard, MarkAsQualifiedCard],
    }),
  ],
]);
