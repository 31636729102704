import React from 'react';
import { useMutation } from 'react-apollo';
import { mergeDeepRight } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import CreateOrSelectOrganizationModal from 'components/CreateOrSelectOrganizationModal';

const ADD_ORGANIZATION_SPONSORABLE_PROPERTIES_TO_CAMPAIGN = gql`
  mutation AddOrganizationSponsorablePropertiesToCampaign(
    $input: AddOrganizationSponsorablePropertiesToCampaignInput!
  ) {
    addOrganizationSponsorablePropertiesToCampaign(input: $input) {
      campaign {
        id
      }
    }
  }
`;

const CREATE_SPONSORABLE_PROPERTY_PROSPECTS = gql`
  mutation CreateYouthSportsSeasonProspects(
    $input: CreateYouthSportsSeasonProspectsInput!
  ) {
    createYouthSportsSeasonProspects(input: $input) {
      campaign {
        id
      }
    }
  }
`;

const AddProspectsModal = ({ campaign, onAdd, toggleProps }) => {
  const [createMutation] = useMutation(CREATE_SPONSORABLE_PROPERTY_PROSPECTS);
  const [addMutation] = useMutation(
    ADD_ORGANIZATION_SPONSORABLE_PROPERTIES_TO_CAMPAIGN,
  );

  const { id: campaignId } = campaign;

  return (
    <CreateOrSelectOrganizationModal
      campaign={campaign}
      create={(input) =>
        createMutation({
          variables: { input: mergeDeepRight(input, { campaignId }) },
        }).then(onAdd)
      }
      add={({ organizationId }) =>
        addMutation({
          variables: { input: { campaignId, organizationId } },
        }).then(onAdd)
      }
      toggleProps={toggleProps}
    />
  );
};

AddProspectsModal.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onAdd: PropTypes.func,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
  }).isRequired,
};

AddProspectsModal.defaultProps = {
  onAdd: () => {},
};

export default AddProspectsModal;
