import ContactUsLink from 'components/ContactUsLink';
import React from 'react';
import PropTypes from 'prop-types';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import LeaguesideAPI from 'utils/leagueside-api';
import { push } from 'react-router-redux';
import { receiveAuthenticationToken } from 'modules/authentication';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Alert from 'components/Alert';
import ErrorAlert from 'components/ErrorAlert';
import SingleCardPage from 'components/SingleCardPage';

const invalidTokenMessage = (
  <>
    <h1 data-test="token-invalid-message">Recovery Link is Invalid</h1>
    <p>
      This password reset link is invalid. It may have expired or this account
      is no longer active.
    </p>
    <p>
      Please return to the&nbsp;
      <Link to="/">login page</Link>
      &nbsp;to request another password reset link.
    </p>
    <p>
      If you believe this is an error or need assistance, please&nbsp;
      <ContactUsLink />.
    </p>
  </>
);

export class RecoverAccountUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = { changeSuccess: null, tokenIsValid: null };

    this.setPassword = (e) => this.setState({ password: e.target.value });
    this.setPasswordConfirmation = (e) =>
      this.setState({ passwordConfirmation: e.target.value });

    this.changePassword = this.changePassword.bind(this);
  }

  async componentDidMount() {
    const {
      match: {
        params: { recoveryToken },
      },
    } = this.props;
    try {
      await LeaguesideAPI.validateRecoveryToken(recoveryToken);
      this.setState({ tokenIsValid: true });
    } catch (error) {
      this.setState({ tokenIsValid: false });
    }
  }

  async changePassword(event) {
    try {
      event.preventDefault();
      const {
        match: {
          params: { recoveryToken },
        },
        receiveToken,
      } = this.props;
      const { password, passwordConfirmation } = this.state;
      const response = await LeaguesideAPI.changePassword(
        recoveryToken,
        password,
        passwordConfirmation,
      );

      this.setState({
        changeSuccess: true,
        password: '',
        passwordConfirmation: '',
      });
      receiveToken(response.jwt);
    } catch (error) {
      this.setState({ changeSuccess: false });
    }
  }

  renderChangeResult() {
    const { changeSuccess } = this.state;
    if (changeSuccess === null) return null;

    if (!changeSuccess) {
      return (
        <ErrorAlert data-test="failed-message">
          Uh oh! We could not update your password. Please be sure your new
          password meets the criteria above and try again.
        </ErrorAlert>
      );
    }

    return (
      <Alert alertStyle="success" role="alert">
        Password changed successfully!
      </Alert>
    );
  }

  render() {
    const { password, passwordConfirmation, tokenIsValid } = this.state;

    const form = (
      <>
        <h1>Reset Password</h1>
        <p>
          Your password must be more than 8 characters and contain an uppercase
          and lowercase letter, a number and a symbol.
        </p>
        {this.renderChangeResult()}
        <form onSubmit={this.changePassword}>
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              className="form-control"
              id="password"
              onChange={this.setPassword}
              placeholder="Password"
              type="password"
              value={password || ''}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password-confirmation">Confirm Password</label>
            <input
              className="form-control"
              id="password-confirmation"
              onChange={this.setPasswordConfirmation}
              placeholder="Confirm password"
              type="password"
              value={passwordConfirmation || ''}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            disabled={!password || password !== passwordConfirmation}
          >
            Change Password
          </button>
        </form>
      </>
    );

    if (tokenIsValid === null) return null;

    return (
      <FieldhousePageTitle title="Reset Password">
        <SingleCardPage>
          {tokenIsValid ? form : invalidTokenMessage}
        </SingleCardPage>
      </FieldhousePageTitle>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  receiveToken(token) {
    dispatch(receiveAuthenticationToken(token));
    dispatch(push('/'));
  },
});

const RecoverAccount = connect(
  () => ({}),
  mapDispatchToProps,
)(RecoverAccountUnwrapped);

RecoverAccountUnwrapped.propTypes = {
  receiveToken: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      recoveryToken: PropTypes.string,
    }),
  }).isRequired,
};

export default RecoverAccount;
