import * as React from 'react';
import { DateRangePicker, DateRangePickerShape } from 'react-dates';
import { DEFAULT_DATE_FORMAT } from '../utils/formatDate';

const DateRangePickerWrapper: React.FC<DateRangePickerShape> = ({
  startDate,
  endDate,
  onDatesChange,
  focusedInput,
  onFocusChange,
  ...rest
}: DateRangePickerShape) => (
  <DateRangePicker
    displayFormat={DEFAULT_DATE_FORMAT}
    endDate={endDate || null}
    endDatePlaceholderText="MM/DD/YYYY"
    focusedInput={focusedInput}
    onDatesChange={onDatesChange}
    onFocusChange={onFocusChange}
    startDate={startDate || null}
    startDatePlaceholderText="MM/DD/YYYY"
    numberOfMonths={1} // @ts-ignore
    startDateId="date-range-picker-start-date" // @ts-ignore
    endDateId="date-range-picker-end-date"
    {...rest}
  />
);

export default DateRangePickerWrapper;
