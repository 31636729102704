import React from 'react';
import Modal, { ModalBody } from 'components/Modal';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import styled from 'styled-components';
import DangerousInnerHtml from 'components/dangerous-inner-html/dangerous-inner-html';

const Preview = styled.div`
  max-height: 50vh;
  overflow: auto;
`;

const CampaignDashboardCreativeContentTemplateModal = ({
  creativeContentTemplate,
}) => {
  const { id, name } = creativeContentTemplate;
  const modalId = `creative-content-template-${id}`;

  return (
    <>
      <button
        type="button"
        className="btn btn-link"
        data-target={`#${modalId}`}
        data-toggle="modal"
      >
        <Icon className="mr-2" value="text-document" />
        {name}
      </button>
      <Modal id={modalId} title={name}>
        <ModalBody>
          <Preview className="border rounded p-3">
            <DangerousInnerHtml
              html={creativeContentTemplate.renderedContent}
            />
          </Preview>
        </ModalBody>
      </Modal>
    </>
  );
};

CampaignDashboardCreativeContentTemplateModal.propTypes = {
  creativeContentTemplate: PropTypes.shape({
    renderedContent: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CampaignDashboardCreativeContentTemplateModal;
