import * as React from 'react';
import ErrorAlert from 'components/ErrorAlert';

interface Props extends React.HTMLAttributes<HTMLElement> {
  message?: string;
}

const LoginMessage: React.FunctionComponent<Props> = ({ message }: Props) => {
  if (!message) return null;

  return <ErrorAlert data-test="login-message">{message}</ErrorAlert>;
};

export default LoginMessage;
