import * as React from 'react';
import { map, range, contains } from 'ramda';
import SelectInput from './SelectInput';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  formGroupClassName?: string;
  inputSize?: string;
  labelClassName?: string;
  value: number;
}

function getOptions(selectedYear: number) {
  const currentTime = new Date();
  const minYear = currentTime.getFullYear() - 1;
  const options = range(minYear, minYear + 7);
  if (contains(selectedYear, options)) {
    return options;
  }
  return minYear > selectedYear
    ? [selectedYear, ...options]
    : [...options, selectedYear];
}

const YearSelectInput: React.FunctionComponent<Props> = ({
  disabled,
  formGroupClassName,
  inputSize,
  labelClassName,
  onChange,
  value,
}: Props) => (
  <SelectInput
    disabled={disabled}
    formGroupClassName={formGroupClassName}
    inputSize={inputSize}
    label="Year"
    labelClassName={labelClassName}
    noDefault
    onChange={onChange}
    value={value}
  >
    {map(
      (option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ),
      getOptions(value),
    )}
  </SelectInput>
);

YearSelectInput.defaultProps = {
  disabled: false,
  formGroupClassName: undefined,
  inputSize: undefined,
  labelClassName: undefined,
};

export default YearSelectInput;
