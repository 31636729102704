import React from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import {
  replaceDashesWithSpaces,
  replaceUnderscoresWithSpaces,
} from 'utils/string';

const FilterDropdown = ({
  isChecked,
  displayList,
  label,
  onChange,
  options,
}) => {
  const id = `filter-menu-${label}-button`;

  const renderFilterInput = (option) => (
    <div className="form-check" key={option}>
      <input
        checked={isChecked(option)}
        className="form-check-input"
        id={`${label}-${option}`}
        onChange={onChange(option)}
        type="checkbox"
      />
      <label
        className="form-check-label text-capitalize"
        htmlFor={`${label}-${option}`}
      >
        {replaceUnderscoresWithSpaces(option)}
      </label>
    </div>
  );

  return (
    <div className="dropdown text-nowrap d-flex mr-3 my-0">
      <button
        className="btn btn-link dropdown-toggle p-0"
        type="button"
        id={id}
        data-toggle="dropdown"
      >
        <small className="text-capitalize">
          {replaceDashesWithSpaces(label)}:<strong>{displayList}</strong>
        </small>
      </button>
      <form className="dropdown-menu px-4" aria-labelledby={id}>
        {map(renderFilterInput, options)}
      </form>
    </div>
  );
};

FilterDropdown.propTypes = {
  displayList: PropTypes.string.isRequired,
  isChecked: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FilterDropdown;
