import * as React from 'react';
import { CampaignStatus } from 'types/campaign';
import { OfferStatus } from 'types/offer';
import { hasCompleteCampaign } from 'utils/offer';
import { capitalizeEachWord } from 'utils/string';
import Badge from 'components/Badge';

interface Campaign {
  status: CampaignStatus;
}
interface Offer {
  campaign: Campaign;
  status: OfferStatus;
}

interface Props {
  offer: Offer;
}

const StatusBadge: React.FunctionComponent<Props> = ({ offer }: Props) => {
  if (hasCompleteCampaign(offer)) {
    return <Badge badgeType="success">Complete</Badge>;
  }

  const { status } = offer;

  switch (status) {
    case 'confirmed':
      return <Badge badgeType="primary">Active</Badge>;
    case 'proposed':
      return <Badge badgeType="warning">Proposed</Badge>;
    default:
      return <Badge>{capitalizeEachWord(status)}</Badge>;
  }
};

export default StatusBadge;
