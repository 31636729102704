import React from 'react';
import ModalForm from 'components/ModalForm';
import leaguesideApi from 'utils/leagueside-api';
import { useSubmit } from 'utils/hooks';
import { conjugateString } from 'utils/string';
import PropTypes from 'prop-types';

const DeleteSearchFilterModal = ({ locationFilter, refetchData }) => {
  const { id, name } = locationFilter;

  async function remove() {
    await leaguesideApi.deleteSearchFilter(id);
    refetchData();
  }

  const { error, loading, handleSubmit } = useSubmit(remove);

  return (
    <ModalForm
      id={`delete-search-filter-modal-${id}`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      title="Delete Search Filter?"
      submitProps={{
        children: conjugateString('Delete', loading),
        disabled: loading,
      }}
      toggleProps={{
        children: 'Remove',
        className: 'btn btn-link text-danger',
      }}
    >
      <p>
        Are you sure you want to remove&nbsp;
        <strong>{name}</strong>?
      </p>
    </ModalForm>
  );
};

DeleteSearchFilterModal.propTypes = {
  locationFilter: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  refetchData: PropTypes.func.isRequired,
};

export default DeleteSearchFilterModal;
