import React from 'react';
import { Link } from 'react-router-dom';
import { ORGANIZATION_DASHBOARD_PATH } from 'utils/paths';
import ErrorAlert from 'components/ErrorAlert';
import ContactUsLink from 'components/ContactUsLink';

const OfferErrorAlert = () => (
  <ErrorAlert alertStyle="warning" className="my-3 text-center">
    <p>Oops! Something went wrong.</p>
    <p>
      Please return to&nbsp;
      <Link title="League Dashboard" to={ORGANIZATION_DASHBOARD_PATH}>
        your dashboard
      </Link>
      . If this problem continues, please&nbsp;
      <ContactUsLink />.
    </p>
  </ErrorAlert>
);

export default OfferErrorAlert;
