export default function retryPromise(fn, { retries = 2 } = {}) {
  let attempts = 1;

  function retry(error) {
    if (attempts > retries) throw error;
    attempts += 1;
    return fn().catch(retry);
  }

  return fn().catch(retry);
}
