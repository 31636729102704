import * as React from 'react';
import { equals, map } from 'ramda';
import { getInputId } from 'utils/id';
import { replaceUnderscoresWithSpaces } from 'utils/string';
import { RequiredAsterisk } from 'components/RequiredLabel';

interface Props extends React.HTMLAttributes<HTMLElement> {
  loading: boolean;
  offset: number;
  offsetType: string;
  prefix: string;
  setOffset(offset: string): void;
  setOffsetType(offsetType: string): void;
}

const equalsAcceptOfferDate = equals('accept_offer_date');
const offsetTypeOptions = [
  'accept_offer_date',
  'season_start_date',
  'season_end_date',
];

const renderOption = (option: string) => (
  <option key={option} value={option}>
    {replaceUnderscoresWithSpaces(option)}
  </option>
);

const DynamicDeadlineInput: React.FunctionComponent<Props> = ({
  loading,
  offset,
  offsetType,
  prefix,
  setOffset,
  setOffsetType,
}: Props) => {
  const offsetInputId = getInputId([prefix, 'offset']);
  const offsetTypeInputId = getInputId([prefix, 'offset-type']);

  function handleOffsetTypeSelection(value: string) {
    setOffsetType(value);

    if (equalsAcceptOfferDate(value) && !!offset && offset < 1) {
      setOffset('');
    }
  }

  return (
    <div className="form-group mt-3">
      <label htmlFor={offsetInputId}>
        Dynamic Verification Deadline
        <RequiredAsterisk />
      </label>
      <div className="form-inline">
        <input
          className="form-check-input form-control"
          disabled={loading}
          id={offsetInputId}
          min={equalsAcceptOfferDate(offsetType) ? 1 : ''}
          onChange={({ target: { value } }) => setOffset(value)}
          style={{ width: '6em' }}
          type="number"
          value={offset}
        />
        &nbsp;days from&nbsp;
        <select
          className="form-check-input form-control ml-1"
          disabled={loading}
          id={offsetTypeInputId}
          onChange={({ target: { value } }) => handleOffsetTypeSelection(value)}
          value={offsetType}
        >
          <option value=""> -- select an option -- </option>
          {map(renderOption, offsetTypeOptions)}
        </select>
      </div>
      <small className="text-muted">
        Negative numbers allowed except for accept offer date (which has a
        minimum of 1)
      </small>
    </div>
  );
};

export default DynamicDeadlineInput;
