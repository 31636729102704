import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import {
  flatten,
  isEmpty,
  length,
  map,
  partition,
  path,
  pipe,
  prop,
} from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import {
  sortBySponsorablePropertyName,
  sponsorablePropertyName,
} from 'utils/offer';
import Icon from 'components/Icon';
import SponsorshipEmailModal from 'components/SponsorshipEmailModal';

const SEND_MUTATION = gql`
  mutation SendSponsorablePropertyOnboardingEmails(
    $input: SendSponsorablePropertyOnboardingEmailsInput!
  ) {
    sendSponsorablePropertyOnboardingEmails(input: $input) {
      offers {
        id
      }
    }
  }
`;

const EMAIL_QUERY = gql`
  query SponsorablePropertyOnboardingEmail($offerId: Int!) {
    sponsorablePropertyOnboardingEmail(offerId: $offerId) {
      htmlBody
      to
    }
  }
`;

const renderOffer = (offer) => (
  <li key={offer.id}>{sponsorablePropertyName(offer)}</li>
);

const renderOffers = pipe(sortBySponsorablePropertyName, map(renderOffer));

const hasVerificationDeadlines = pipe(
  prop('verificationDeadlines'),
  flatten,
  length,
);

const email = path(['data', 'sponsorablePropertyOnboardingEmail']);

const SendSponsorablePropertyOnboardingEmailsModal = ({
  id,
  offers,
  toggleClassName,
}) => {
  const [sendableOffers, offersWithoutDeadlines] = partition(
    hasVerificationDeadlines,
    offers,
  );

  return (
    <ApolloConsumer>
      {(client) => (
        <SponsorshipEmailModal
          id={id}
          fetchEmail={(offerId) =>
            client
              .query({
                fetchPolicy: 'network-only',
                query: EMAIL_QUERY,
                variables: { offerId },
              })
              .then(email)
          }
          lastSentAtPath={['onboardingEmailSentAt']}
          offers={sendableOffers}
          sendEmails={(offerIds) =>
            client.mutate({
              mutation: SEND_MUTATION,
              variables: { input: { offerIds } },
            })
          }
          title="Send Onboarding Email?"
          toggleProps={{
            children: (
              <>
                <Icon className="mr-2" value="mail" />
                Send Onboarding
              </>
            ),
            className: toggleClassName,
          }}
        >
          {!isEmpty(offersWithoutDeadlines) && (
            <div className="alert alert-warning" data-test="deadlines-alert">
              The offers associated with the following sponsorable properties
              don&#39;t have any verification deadlines;&nbsp; on-boarding
              emails cannot be sent to them.
              <ul>{renderOffers(offersWithoutDeadlines)}</ul>
            </div>
          )}
        </SponsorshipEmailModal>
      )}
    </ApolloConsumer>
  );
};

SendSponsorablePropertyOnboardingEmailsModal.propTypes = {
  id: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  toggleClassName: PropTypes.string,
};

SendSponsorablePropertyOnboardingEmailsModal.defaultProps = {
  toggleClassName: 'btn btn-primary',
};

export default SendSponsorablePropertyOnboardingEmailsModal;
