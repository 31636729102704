import React from 'react';
import styled from 'styled-components';

const Footer = styled.footer`
  margin-bottom: 3rem;
  margin-top: 10rem;
  text-align: center;
`;

const TeamSnapFooter = () => (
  <Footer>
    <p className="mt-2 text-muted">
      TeamSnap For Brands
      <a
        className="ml-3 footer-legal-button"
        href="https://www.teamsnap.com/privacy-policy"
      >
        Privacy Policy
      </a>
      <a
        className="footer-legal-button ml-3"
        href="https://www.teamsnap.com/terms"
      >
        Terms Of Use
      </a>
    </p>
  </Footer>
);

export default TeamSnapFooter;
