import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import styled from 'styled-components';
import Paragraph from './Paragraph';

const Label = styled.label`
  font-size: 1.5rem;
`;

const PlayersAges = ({ addChanges, league }) => (
  <div className="container">
    <Paragraph bold>
      What is the age range of the players in your league?
    </Paragraph>
    <div className="mt-3">
      <Label htmlFor="minAge">Youngest Age</Label>
      <input
        className="form-control form-control-lg"
        id="minAge"
        onChange={(event) => addChanges({ minAge: Number(event.target.value) })}
        type="number"
        value={league.minAge || ''}
      />
    </div>
    <div className="mt-3">
      <Label htmlFor="minAge">Oldest Age</Label>
      <input
        className="form-control form-control-lg"
        id="maxAge"
        onChange={(event) => addChanges({ maxAge: Number(event.target.value) })}
        type="number"
        value={league.maxAge || ''}
      />
    </div>
  </div>
);

PlayersAges.propTypes = {
  addChanges: PropTypes.func.isRequired,
  league: LeagueSidePropTypes.league.isRequired,
};

export default PlayersAges;
