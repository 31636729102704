import type { Campaign } from 'types/campaign';
import AddCampaignCapabilityModal from './requirements/AddCampaignCapabilityModal';
import AdditionalRequirements from './requirements/AdditionalRequirements';
import CampaignCapabilityCards from './requirements/CampaignCapabilityCards';

type RequirementProps = {
  campaign: Campaign;
};

function Requirements(props: RequirementProps) {
  const { campaign } = props;
  const { id: campaignId, campaignCapabilities } = campaign;

  return (
    <div className="list-group list-group-flush">
      <div className="list-group-item">
        <div className="row">
          <strong className="col">Capabilities</strong>
          <div className="col-auto">
            <AddCampaignCapabilityModal
              addedCampaignCapabilities={campaignCapabilities}
              campaignId={campaignId}
            />
          </div>
        </div>
        <div className="alert alert-info mt-3">
          Verification deadlines will be automatically added to each sponsorship
          when it is confirmed&nbsp; based on the verification deadline defaults
          for each capability listed on this page.&nbsp; Changing the defaults
          here will not update sponsorship deadlines that have already been
          added.
        </div>
        <CampaignCapabilityCards campaign={campaign} />
      </div>
      <div className="list-group-item">
        <AdditionalRequirements campaign={campaign} />
      </div>
    </div>
  );
}

export default Requirements;
