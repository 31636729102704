import React from 'react';
import {
  concat,
  descend,
  map,
  pipe,
  prop,
  propEq,
  reject,
  sort,
  without,
} from 'ramda';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { OFFER_STATUSES } from 'utils/offer';
import { filterableDataConfidences } from 'types/sponsorableProperty';
import LocationFilterCard from './campaignFilters/LocationFilterCard';
import AddFilterButton from './campaignFilters/AddFilterButton';
import CapabilityFilterCard from './campaignFilters/filterCards/CapabilityFilterCard';
import EthnicityFilterCard from './campaignFilters/filterCards/EthnicityFilterCard';
import IncomeFilterCard from './campaignFilters/filterCards/IncomeFilterCard';
import NumberOfPlayersFilterCard from './campaignFilters/filterCards/NumberOfPlayersFilterCard';
import SeasonFilterCard from './campaignFilters/filterCards/SeasonFilterCard';
import SportFilterCard from './campaignFilters/filterCards/SportFilterCard';
import StateFilterCard from './campaignFilters/filterCards/StateFilterCard';
import FilterDropdown from './campaignFilters/FilterDropdown';
import MultipleChoiceFilterDropdown from './campaignFilters/MultipleChoiceFilterDropdown';

const FiltersList = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
`;

const byDescendingId = descend(prop('id'));
const sortNonLocationFilters = pipe(
  reject(propEq('type', 'LocationFilter')),
  sort(byDescendingId),
);

const renderLocationFilter = (markets) => (locationFilter) => {
  const { id } = locationFilter;

  return (
    <LocationFilterCard
      key={id}
      locationFilter={locationFilter}
      markets={markets}
    />
  );
};

/* eslint-disable react/destructuring-assignment */
const filterRenderByType = {
  CapabilityFilter: (filter, { campaignCapabilities, capabilities }) => (
    <CapabilityFilterCard
      key={filter.id}
      filterId={filter.id}
      campaignCapabilities={campaignCapabilities}
      capabilityIds={filter.parameters.capabilityIds}
      capabilities={capabilities}
    />
  ),
  EthnicityFilter: (filter) => (
    <EthnicityFilterCard
      key={filter.id}
      filterId={filter.id}
      parameters={filter.parameters}
    />
  ),
  IncomeFilter: (filter) => (
    <IncomeFilterCard
      key={filter.id}
      filterId={filter.id}
      lowBound={filter.parameters.lowBound}
      highBound={filter.parameters.highBound}
    />
  ),
  NumberOfPlayersFilter: (filter) => (
    <NumberOfPlayersFilterCard
      key={filter.id}
      filterId={filter.id}
      lowBound={filter.parameters.lowBound}
      highBound={filter.parameters.highBound}
    />
  ),
  SeasonFilter: (filter) => (
    <SeasonFilterCard
      key={filter.id}
      filterId={filter.id}
      spring={filter.parameters.spring}
      summer={filter.parameters.summer}
      fall={filter.parameters.fall}
      winter={filter.parameters.winter}
    />
  ),
  SportFilter: (filter) => (
    <SportFilterCard
      key={filter.id}
      filterId={filter.id}
      sportIds={filter.parameters.sportIds}
    />
  ),
  StateFilter: (filter) => (
    <StateFilterCard
      key={filter.id}
      filterId={filter.id}
      state={filter.parameters.state}
    />
  ),
};
/* eslint-enable react/destructuring-assignment */

function renderFilter(filter, { campaignCapabilities, capabilities }) {
  const render = filterRenderByType[filter.type];
  if (render) return render(filter, { campaignCapabilities, capabilities });

  console.error(`The requested filter type ${filter.type} was not found.`); // eslint-disable-line no-console

  return null;
}

function renderFilters({ filters, campaignCapabilities, capabilities }) {
  return map(
    (filter) => renderFilter(filter, { campaignCapabilities, capabilities }),
    sortNonLocationFilters(filters),
  );
}

const EventsSurveyFilterDropdown = ({ filterBySurveyResponse, onChange }) => (
  <FilterDropdown
    displayList={filterBySurveyResponse ? 'Has Response' : '--'}
    isChecked={() => filterBySurveyResponse}
    label="events-survey"
    onChange={() => onChange}
    options={['has_response']}
  />
);

EventsSurveyFilterDropdown.propTypes = {
  filterBySurveyResponse: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const DataConfidenceFilterDropdown = ({ onChange, selected }) => (
  <MultipleChoiceFilterDropdown
    label="data-confidence"
    onChange={onChange}
    options={filterableDataConfidences}
    selected={selected}
  />
);

DataConfidenceFilterDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const OfferStatusFilterDropdown = ({ onChange, selected }) => (
  <MultipleChoiceFilterDropdown
    label="status"
    onChange={onChange}
    options={OFFER_STATUSES}
    selected={selected}
  />
);

OfferStatusFilterDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const emptyFilterMessage = (
  <p className="text-center mt-4">
    <strong className="font-weight-bold">No filters yet!</strong>
    <br />
    Add a filter to target your campaign to specific areas and demographics.
  </p>
);

const CampaignFilters = (props) => {
  const { campaign, capabilities, filters, markets } = props;
  const { campaignCapabilities, locationFilters } = campaign;
  const {
    filterBySurveyResponse,
    selectedDataConfidences,
    selectedOfferStatuses,
  } = props;
  const {
    setFilterBySurveyResponse,
    setSelectedDataConfidences,
    setSelectedOfferStatuses,
  } = props;

  const handleDataConfidenceInputChange = (dataConfidence) => (event) =>
    setSelectedDataConfidences(
      event.target.checked
        ? concat([dataConfidence], selectedDataConfidences)
        : without([dataConfidence], selectedDataConfidences),
    );

  const handleOfferStatusInputChange = (offerStatus) => (event) =>
    setSelectedOfferStatuses(
      event.target.checked
        ? concat([offerStatus], selectedOfferStatuses)
        : without([offerStatus], selectedOfferStatuses),
    );

  const renderLocationFilterWithMarkets = renderLocationFilter(markets);

  return (
    <>
      <div className="mb-1">
        <div className="float-right">
          <AddFilterButton campaignId={campaign.id} />
        </div>
        <EventsSurveyFilterDropdown
          onChange={() => setFilterBySurveyResponse(!filterBySurveyResponse)}
          filterBySurveyResponse={filterBySurveyResponse}
        />
        <DataConfidenceFilterDropdown
          onChange={handleDataConfidenceInputChange}
          selected={selectedDataConfidences}
        />
        <OfferStatusFilterDropdown
          onChange={handleOfferStatusInputChange}
          selected={selectedOfferStatuses}
        />
      </div>
      <FiltersList>
        {map(renderLocationFilterWithMarkets, locationFilters)}
        {renderFilters({ filters, campaignCapabilities, capabilities })}
        {!filters.length && emptyFilterMessage}
      </FiltersList>
    </>
  );
};

CampaignFilters.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
  capabilities: LeagueSidePropTypes.capabilities.isRequired,
  filterBySurveyResponse: PropTypes.bool.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  markets: LeagueSidePropTypes.geometries.isRequired,
  selectedDataConfidences: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOfferStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFilterBySurveyResponse: PropTypes.func.isRequired,
  setSelectedDataConfidences: PropTypes.func.isRequired,
  setSelectedOfferStatuses: PropTypes.func.isRequired,
};

export default CampaignFilters;
