import Contact from './Contact';
import Finished from './Finished';
import Name from './Name';
import PlaySports from './PlaySports';
import PlayCompetitiveness from './PlayCompetitiveness';
import PlayersAges from './PlayersAges';
import PlayersGenders from './PlayersGenders';
import PrimaryPlayLocation from './PrimaryPlayLocation';
import Seasons from './Seasons';
import Size from './Size';
import Welcome from './Welcome';

const STEPS = [
  Welcome,
  Name,
  Contact,
  PrimaryPlayLocation,
  Size,
  Seasons,
  PlaySports,
  PlayersAges,
  PlayersGenders,
  PlayCompetitiveness,
  Finished,
];

export default STEPS;
