import * as React from 'react';
import OrganizationCapabilityPackage from 'types/organizationCapabilityPackage';
import CircledIcon from 'components/CircledIcon';
import RemoveOrganizationCapabilityPackageModal from 'components/RemoveOrganizationCapabilityPackageModal';

interface Props {
  displayModals?: boolean;
  organizationCapabilityPackage: OrganizationCapabilityPackage;
}

const AddedOrganizationCapabilityPackage: React.FunctionComponent<Props> = ({
  displayModals,
  organizationCapabilityPackage,
}: Props) => {
  const { quantity, organizationCapability } = organizationCapabilityPackage;
  const {
    id: organizationCapabilityId,
    description,
    name,
  } = organizationCapability;

  return (
    <div
      className="row"
      data-test={`added-organization-capability-package-${organizationCapabilityId}`}
    >
      <div className="col-auto pr-0">
        <CircledIcon
          value="check"
          className="mr-2 text-white"
          backgroundColor="primary"
          opacity={1}
          size={18}
        />
      </div>
      <div className="col pl-0">
        <h6
          data-test={`added-organization-capability-package-${organizationCapabilityId}-name`}
          className="mb-1"
        >
          {name}
        </h6>
        <div
          data-test={`added-organization-capability-package-${organizationCapabilityId}-description`}
          className="compact-line-height"
        >
          {description}
        </div>
        <small
          data-test={`added-organization-capability-package-${organizationCapabilityId}-quanitity`}
          className="compact-line-height text-muted"
        >{`Quantity: ${quantity}`}</small>
      </div>
      {displayModals && (
        <div className="col-auto text-right">
          <RemoveOrganizationCapabilityPackageModal
            organizationCapabilityPackage={organizationCapabilityPackage}
          />
        </div>
      )}
    </div>
  );
};

AddedOrganizationCapabilityPackage.defaultProps = {
  displayModals: false,
};

export default AddedOrganizationCapabilityPackage;
