import gql from 'graphql-tag';

const CAPABILITY_FRAGMENT = gql`
  fragment CapabilityFragment on Capability {
    id
    name
  }
`;

export default CAPABILITY_FRAGMENT;
