import React from 'react';
import { useQuery } from 'react-apollo';
import { map } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { sortByName } from 'utils/sort';
import ModalDisplay from 'components/ModalDisplay';
import { SPONSORABLE_PROPERTY_CAPABILITY_MINIMAL_FRAGMENT } from 'fragments/SponsorablePropertyCapabilityFragment';

const QUERY = gql`
  query SponsorableProperty($id: Int!) {
    sponsorableProperty(id: $id) {
      id
      sponsorablePropertyCapabilities(archived: false) {
        ...SponsorablePropertyCapabilityMinimalFragment
      }
    }
  }
  ${SPONSORABLE_PROPERTY_CAPABILITY_MINIMAL_FRAGMENT}
`;

const DisplaySponsorablePropertyCapabilitiesModal = ({
  sponsorablePropertyId,
  sponsorablePropertyCapabilitiesLabel,
}) => {
  const { data, loading } = useQuery(QUERY, {
    variables: { id: sponsorablePropertyId },
  });

  const sponsorablePropertyCapabilities =
    data &&
    data.sponsorableProperty &&
    data.sponsorableProperty.sponsorablePropertyCapabilities;

  const renderSponsorableCapabilityCard = (sponsorablePropertyCapability) => {
    const {
      id: sponsorablePropertyCapabilityId,
      description,
      name,
    } = sponsorablePropertyCapability;

    return (
      <div
        className="card bg-light border-0 my-2"
        key={sponsorablePropertyCapabilityId}
      >
        <div className="card-body pt-2 pb-3">
          <h6
            className="m-0"
            data-test={`sponsorable-property-capability-${sponsorablePropertyCapabilityId}-name`}
          >
            {name}
          </h6>
          {!!description && (
            <p
              data-test={`sponsorable-property-capability-${sponsorablePropertyCapabilityId}-description`}
              className="card-text mt-1 mb-0"
            >
              {description}
            </p>
          )}
        </div>
      </div>
    );
  };

  return !loading && sponsorablePropertyCapabilities ? (
    <ModalDisplay
      id={`sponsorable-property-capabilities-${sponsorablePropertyId}-modal`}
      title="Sponsorable Property Capabilities"
      toggleProps={{
        className: 'btn btn-link btn-inline-link font-inherit',
        children: sponsorablePropertyCapabilitiesLabel,
      }}
    >
      {map(
        renderSponsorableCapabilityCard,
        sortByName(sponsorablePropertyCapabilities),
      )}
    </ModalDisplay>
  ) : null;
};

DisplaySponsorablePropertyCapabilitiesModal.propTypes = {
  sponsorablePropertyCapabilitiesLabel: PropTypes.string.isRequired,
  sponsorablePropertyId: PropTypes.number.isRequired,
};

export default DisplaySponsorablePropertyCapabilitiesModal;
