import { LabelButton } from '@teamsnap/snap-ui';
import cx from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

type BooleanQuestionInputProps = Omit<
  ComponentPropsWithoutRef<'input'>,
  'onChange'
> & {
  label: string | undefined;
  helpText?: string;
  onChange: (value: boolean) => void;
};

export default function BooleanQuestionInput(props: BooleanQuestionInputProps) {
  const { label, name, checked, onChange, helpText } = props;

  return (
    <div
      className="sui-flex sui-items-center sui-justify-between sui-py-1"
      style={{ gap: 24 }}
    >
      <input
        data-testid={`checkbox-${name}`}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={() => onChange && onChange(!checked)}
        className="sui-opacity-0 sui-absolute"
      />
      <p
        className="sui-text-desktop-4 sui-font-semibold"
        style={{ maxWidth: 580 }}
      >
        {label}
        {helpText && (
          <>
            <br />
            <span className="sui-text-neutral-text-medium sui-font-normal sui-mt-1 sui-block">
              {helpText}
            </span>
          </>
        )}
      </p>
      <div className="sui-flex">
        <LabelButton
          type="button"
          labelText="Yes"
          size="small"
          icon="check"
          variantType="tertiary"
          className={cx(
            'sui-rounded-none sui-text-neutral-text-medium sui-font-semibold sui-pl-1 sui-pr-2 sui-text-desktop-4',
            {
              'sui-text-positive-text sui-bg-neutral-background-weak': checked,
            },
          )}
          onClick={() => onChange && onChange(true)}
        />
        <LabelButton
          type="button"
          labelText="No"
          size="small"
          icon="close"
          variantType="tertiary"
          className={cx(
            'sui-rounded-none sui-text-neutral-text-medium sui-font-semibold sui-pl-1 sui-pr-2 sui-text-desktop-4',
            { 'sui-text-red-6 sui-bg-neutral-background-weak': !checked },
          )}
          onClick={() => onChange(false)}
        />
      </div>
    </div>
  );
}
