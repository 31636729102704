import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import { filter, map, prop } from 'ramda';
import gql from 'graphql-tag';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import formatDate from 'utils/formatDate';
import { track } from 'utils/tracker';
import { defaultToEmptyString, loadingTense } from 'utils/string';
import ContactUsLink from 'components/ContactUsLink';
import Loader from 'components/Loader';
import SingleCardPage from 'components/SingleCardPage';
import ErrorAlert from 'components/ErrorAlert';

const selectable = filter(prop('selectable'));

const CONTENT = {
  ENTER_INFO: 0,
  CONFIRMATION: 1,
};

const errorPage = (
  <SingleCardPage>
    <div className="heading" data-test="error-page-card">
      Uh oh! Something went wrong.
    </div>
    <p>We couldn&#39;t find a deadline at this link.</p>
    <p>
      If you believe this is an error, please&nbsp;
      <ContactUsLink />.
    </p>
  </SingleCardPage>
);

const confirmationContent = (
  <>
    <h1>Thanks!</h1>
    <div className="text-left" id="confirmation-body">
      <p>
        We have received your response and someone from our team will get back
        to you shortly!
      </p>
      <p>
        To manage your organization further, please&nbsp;
        <Link to="/login">login</Link>
        &nbsp;with your account.
      </p>
    </div>
  </>
);

export class RespondToDeadlineUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: CONTENT.ENTER_INFO,
      verificationDeadlineCannedResponseId: null,
      comment: null,
    };

    this.handleVerificationDeadlineCannedResponseInputChange = (event) => {
      this.setState({
        verificationDeadlineCannedResponseId: event.target.value,
      });
    };
    this.handleCommentsInputChange = (event) =>
      this.setState({ comment: event.target.value });
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(event) {
    event.preventDefault();

    const { respondToDeadline } = this.props;
    const { comment, verificationDeadlineCannedResponseId } = this.state;

    try {
      this.setState({ error: null });
      const timeoutID = setTimeout(() => this.setState({ loading: true }), 200);
      await respondToDeadline(
        comment,
        Number(verificationDeadlineCannedResponseId),
      );
      clearTimeout(timeoutID);
      this.setState({ loading: false, content: CONTENT.CONFIRMATION });
    } catch (e) {
      this.setState({ loading: false, error: e.message });
      track(e);
    }
  }

  get enterInfoContent() {
    const { verificationDeadline, verificationDeadlineCannedResponses } =
      this.props;
    const { comment, error, loading, verificationDeadlineCannedResponseId } =
      this.state;
    const { campaign, sponsorableProperty } = verificationDeadline.asset.offer;
    const { name: sponsorablePropertyName } = sponsorableProperty;

    const selectableResponses = selectable(verificationDeadlineCannedResponses);

    const renderVerificationDeadlineCannedResponse = (
      verificationDeadlineCannedResponse,
    ) => (
      <div
        className="form-check ml-4"
        key={verificationDeadlineCannedResponse.id}
      >
        <input
          className="form-check-input"
          id={`verification-deadline-canned-response-${verificationDeadlineCannedResponse.id}`}
          name="verification-deadline-canned-response"
          onChange={this.handleVerificationDeadlineCannedResponseInputChange}
          type="radio"
          value={verificationDeadlineCannedResponse.id}
          disabled={loading}
        />
        <label
          className="form-check-label"
          htmlFor={`verification-deadline-canned-response-${verificationDeadlineCannedResponse.id}`}
        >
          {verificationDeadlineCannedResponse.response}
        </label>
      </div>
    );

    const renderVerificationDeadlineCannedResponses = (
      <div className="my-3" id="verification-deadline-canned-response">
        <label
          className="font-weight-bold"
          htmlFor="verification-deadline-canned-response"
        >
          {`${sponsorablePropertyName} can't meet the ${formatDate(
            verificationDeadline.deadline,
          )} ${verificationDeadline.name} deadline because...`}
        </label>
        {map(renderVerificationDeadlineCannedResponse, selectableResponses)}
      </div>
    );

    const renderComment = (
      <div className="my-3">
        <label className="font-weight-bold" htmlFor="comment">
          Please include your email below and any additional comments or
          questions to pass along to the TeamSnap Sponsorship team.
        </label>
        <textarea
          className="form-control"
          id="comment"
          onChange={this.handleCommentsInputChange}
          value={defaultToEmptyString(comment)}
          disabled={loading}
        />
      </div>
    );

    return (
      <>
        <h1>Can&#39;t meet deadline?</h1>
        <form onSubmit={this.onSubmit}>
          <div className="text-left" id="enter-info-intro">
            <p>
              {`Fulfilling your sponsorship requirements in a timely, high-quality manner encourages sponsors like ${
                campaign.sponsor.name
              } to keep sponsoring organizations like yours. However, if you can't send us your verifications for ${
                verificationDeadline.name
              } by ${formatDate(
                verificationDeadline.deadline,
              )}, let us know why so that we can adjust expectations to make this successful for both ${sponsorablePropertyName} and ${
                campaign.sponsor.name
              }.`}
            </p>
          </div>
          <div className="text-left">
            {renderVerificationDeadlineCannedResponses}
            {renderComment}
          </div>
          <div className="mt-4 text-center">
            {error && (
              <ErrorAlert className="text-left">
                Oops! We had some trouble submitting your information. If the
                problem persists, please&nbsp;
                <ContactUsLink />.
              </ErrorAlert>
            )}
            <button
              className={classNames('btn btn-lg btn-primary mb-3', {
                'font-italic': loading,
              })}
              disabled={!verificationDeadlineCannedResponseId || loading}
              id="submit-deadline-response"
              type="submit"
            >
              {loadingTense(loading, 'Submit')}
            </button>
          </div>
        </form>
      </>
    );
  }

  get cardContent() {
    const { content } = this.state;

    switch (content) {
      case CONTENT.CONFIRMATION:
        return confirmationContent;
      default:
        return this.enterInfoContent;
    }
  }

  render() {
    const { error, verificationDeadline } = this.props;

    if (error) return errorPage;
    if (!verificationDeadline) return <Loader />;

    return (
      <SingleCardPage>
        <div id="respond-to-deadline">{this.cardContent}</div>
      </SingleCardPage>
    );
  }
}

RespondToDeadlineUnwrapped.propTypes = {
  error: PropTypes.any /* eslint-disable-line */,
  respondToDeadline: PropTypes.func.isRequired,
  verificationDeadline: LeagueSidePropTypes.verificationDeadline,
  verificationDeadlineCannedResponses: PropTypes.arrayOf(
    LeagueSidePropTypes.verificationDeadlineCannedResponse,
  ),
};

RespondToDeadlineUnwrapped.defaultProps = {
  error: null,
  verificationDeadline: null,
  verificationDeadlineCannedResponses: [],
};

const RESPOND_TO_DEADLINE_MUTATION = gql`
  mutation RespondToDeadline($input: RespondToDeadlineInput!) {
    respondToDeadline(input: $input) {
      verificationDeadline {
        id
      }
    }
  }
`;

const VERIFICATION_DEADLINE_QUERY = gql`
  query DeadlineToRespondTo {
    verificationDeadline: deadlineToRespondTo {
      id
      asset {
        id
        offer {
          id
          campaign {
            id
            sponsor {
              id
              name
            }
          }
          sponsorableProperty {
            id
            name
          }
        }
      }
      deadline
      name
    }
    verificationDeadlineCannedResponses {
      id
      response
      selectable
    }
  }
`;

function RespondToDeadline({ match }) {
  const context = {
    headers: { Authorization: `Bearer ${match.params.token}` },
  };

  return (
    <Query
      fetchPolicy="network-only"
      context={context}
      query={VERIFICATION_DEADLINE_QUERY}
    >
      {({ data, error }) => (
        <Mutation mutation={RESPOND_TO_DEADLINE_MUTATION}>
          {(mutate) => (
            <RespondToDeadlineUnwrapped
              error={error}
              respondToDeadline={async (
                comment,
                verificationDeadlineCannedResponseId,
              ) =>
                mutate({
                  context,
                  variables: {
                    input: {
                      comment,
                      verificationDeadlineCannedResponseId,
                    },
                  },
                })
              }
              verificationDeadline={data && data.verificationDeadline}
              verificationDeadlineCannedResponses={
                data && data.verificationDeadlineCannedResponses
              }
            />
          )}
        </Mutation>
      )}
    </Query>
  );
}

RespondToDeadline.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

export default RespondToDeadline;
