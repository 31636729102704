import { map, pipe, prop, sortBy, toLower } from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Card from 'components/Card';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CampaignAutocomplete from './sponsors/CampaignAutocomplete';

const sortByNameCaseInsensitive = sortBy(pipe(prop('name'), toLower));

const renderSponsorRow = (sponsor) => (
  <tr key={sponsor.id}>
    <td>
      <Link to={`${CAMPAIGN_PLANNER_PATH}/${sponsor.id}`}>{sponsor.name}</Link>
    </td>
  </tr>
);

const renderSponsorRows = pipe(
  sortByNameCaseInsensitive,
  map(renderSponsorRow),
);

export class SponsorsUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = { withActiveOrNoCampaigns: true };

    this.toggleFilter = this.toggleFilter.bind(this);
  }

  toggleFilter() {
    const { props, state } = this;
    const withActiveOrNoCampaigns = state.withActiveOrNoCampaigns ? null : true;
    props.refetch({ withActiveOrNoCampaigns });
    this.setState({ withActiveOrNoCampaigns });
  }

  render() {
    const { sponsors } = this.props;
    const { withActiveOrNoCampaigns } = this.state;

    return (
      <Page loaded={!!sponsors}>
        {sponsors && (
          <RestrictTo roles={['admin']}>
            <FieldhousePageTitle title="Sponsors">
              <>
                <CampaignAutocomplete />
                <Card>
                  <div className="row">
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <h1>
                          {withActiveOrNoCampaigns
                            ? 'Sponsors with Active Campaigns'
                            : 'All Sponsors'}
                        </h1>
                        <button
                          className="btn btn-sm btn-secondary ml-2"
                          id="withActiveOrNoCampaignsToggle"
                          onClick={this.toggleFilter}
                          type="button"
                        >
                          {withActiveOrNoCampaigns
                            ? 'Show All Sponsors'
                            : 'Only Show Sponsors with Active Campaigns'}
                        </button>
                      </div>
                    </div>
                    <div className="col-auto">
                      <Link
                        className="btn btn-secondary"
                        to={`${CAMPAIGN_PLANNER_PATH}/add`}
                      >
                        <Icon className="mr-2" value="plus" />
                        Add A Sponsor
                      </Link>
                    </div>
                  </div>
                  <table className="mt-2 table">
                    <tbody>{renderSponsorRows(sponsors)}</tbody>
                  </table>
                </Card>
              </>
            </FieldhousePageTitle>
          </RestrictTo>
        )}
      </Page>
    );
  }
}

SponsorsUnwrapped.propTypes = {
  refetch: PropTypes.func,
  sponsors: PropTypes.arrayOf(LeagueSidePropTypes.sponsor),
};

SponsorsUnwrapped.defaultProps = {
  refetch: () => {},
  sponsors: null,
};

const QUERY = gql`
  query Sponsors($withActiveOrNoCampaigns: Boolean) {
    sponsors(withActiveOrNoCampaigns: $withActiveOrNoCampaigns) {
      id
      name
    }
  }
`;

const Sponsors = () => (
  <Query
    fetchPolicy="network-only"
    query={QUERY}
    variables={{ withActiveOrNoCampaigns: true }}
  >
    {({ data, refetch }) => (
      <SponsorsUnwrapped refetch={refetch} sponsors={data && data.sponsors} />
    )}
  </Query>
);

export default Sponsors;
