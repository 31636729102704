import { useState } from 'react';
import classNames from 'classnames';
import { formatExactCurrency } from 'utils/formatNumber';
import { sumApprovedAmounts, sumPendingAmounts } from 'utils/check';
import { useSubmit } from 'utils/hooks';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import Icon from 'components/Icon';
import NewWindowLink from 'components/NewWindowLink';
import ModalForm from 'components/ModalForm';
import Alert from 'components/Alert';
import PriceInput from 'components/formInputs/PriceInput';
import { RequiredAsterisk } from 'components/RequiredLabel';
import type { Offer, Organization } from 'types/offer';

const MAX_MEMO_LENGTH = 40;

type CreateCheckModalProps = {
  offer: Offer;
  create: (input: any) => void;
};

const CreateCheckModal = (props: CreateCheckModalProps) => {
  const { create, offer } = props;
  const { checks, id: offerId, sponsorableProperty } = offer;
  const { organization } = sponsorableProperty;

  const [amount, setAmount] = useState('');
  const [memo, setMemo] = useState('');

  const requiredDataPresent = !!(memo && amount);

  async function asyncSubmit() {
    await create({ amount: Number(amount), memo, offerId });

    setAmount('');
    setMemo('');
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  // @ts-ignore-next-line
  const pendingAmount = sumPendingAmounts(checks);
  // @ts-ignore-next-line
  const approvedAmount = sumApprovedAmounts(checks);
  const remainingBudget = offer.value - pendingAmount - approvedAmount;

  return (
    <ModalForm
      id="create-check-modal"
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        className: classNames('btn btn-primary', { 'font-italic': loading }),
        children: 'Send for Approval',
        disabled:
          !requiredDataPresent || memo.length > MAX_MEMO_LENGTH || loading,
      }}
      toggleProps={{
        className: 'btn btn-link',
        children: (
          <>
            <Icon className="mr-1" value="pencil" />
            Write Check
          </>
        ),
      }}
      title={`Write Check to ${sponsorableProperty.name}`}
    >
      {!organization.mailingAddress && noMailingAddressMessage(organization)}
      <table className="table">
        <tbody>
          <tr>
            <td>Offer</td>
            <td className="text-right">{formatExactCurrency(offer.value)}</td>
          </tr>
          <tr>
            <td>Sent</td>
            <td className="text-right">
              {formatExactCurrency(approvedAmount)}
            </td>
          </tr>
          <tr>
            <td>Pending</td>
            <td className="text-right">{formatExactCurrency(pendingAmount)}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr className="font-weight-bold">
            <td>Available to Send</td>
            <td className="text-right">
              {formatExactCurrency(remainingBudget)}
            </td>
          </tr>
        </tfoot>
      </table>
      <PriceInput
        disabled={loading}
        label="Amount"
        max={remainingBudget}
        onChange={(ev) => setAmount((ev.target as HTMLInputElement).value)}
        required
        value={Number(amount)}
      />
      <div className="form-group">
        <label htmlFor="check-memo">
          Memo
          <RequiredAsterisk />
        </label>
        <input
          className="form-control"
          disabled={loading}
          id="check-memo"
          maxLength={MAX_MEMO_LENGTH}
          onChange={(event) => setMemo(event.target.value)}
          required
          type="text"
          value={memo}
        />
      </div>
    </ModalForm>
  );
};

export default CreateCheckModal;

const shippingInfoTabLink = (organizationId: number) => (
  <NewWindowLink
    hideIcon
    to={`${ORGANIZATIONS_PATH}/${organizationId}/shippinginfo`}
    title="Shipping Info Tab"
  >
    Shipping Info Tab
  </NewWindowLink>
);
const noMailingAddressMessage = (organization: Organization) => (
  <Alert>
    {`Warning: No mailing address has been set for ${organization.name}. Visit the `}
    {shippingInfoTabLink(organization.id)}
    &nbsp;to set a mailing address.
  </Alert>
);
