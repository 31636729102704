import * as React from 'react';
import { filter, isEmpty, prop, reject } from 'ramda';
import type { Offer, VerificationDeadline } from 'types/offer';
import { statusEq } from 'utils/misc';
import { sponsorablePropertyName as getSponsorablePropertyName } from 'utils/offer';
import { pluralizedTense } from 'utils/string';
import formatDate from 'utils/formatDate';
import DeadlineActionsDropdown from 'components/DeadlineActionsDropdown';
import ExpandRowButton from 'components/ExpandRowButton';
import MissingCreativesIndicator from 'components/MissingCreativesIndicator';
import VerificationsListIcon from 'components/VerificationsListIcon';
import ExpandedCreativesRow from './expanded-creatives-row';
import ExpandedVerificationsRow from './expanded-verifications-row';
import ExpandVerificationsButton from './expand-verifications-button';
import MarkAsDidNotCompleteModal from './mark-as-did-not-complete-modal';
import RemoveVerificationDeadlineModal from './remove-verification-deadline-modal';
import UpdateVerificationDeadlineModal from './update-verification-deadline-modal';

const unaddressed = reject(prop<'addressed', boolean>('addressed'));
const filterPending = filter(statusEq('pending'));

type VerificationDeadlineRowProps = {
  offer: Offer;
  tableColCount: number;
  verificationDeadline: VerificationDeadline;
};

function VerificationDeadlineRow(props: VerificationDeadlineRowProps) {
  const { offer, tableColCount, verificationDeadline } = props;
  const [verificationsExpanded, setVerificationsExpanded] =
    React.useState(false);
  const [creativesExpanded, setCreativesExpanded] = React.useState(false);

  const {
    id: verificationDeadlineId,
    anyPendingVerificationImages,
    capabilityName,
    creativeContents,
    creativeFiles,
    deadline,
    didNotComplete,
    missingCreatives,
    name,
    overdue,
    verificationDeadlineResponses,
    verificationImages,
    verified,
  } = verificationDeadline;

  const expandedRowColClassName = 'col-sm-6 col-md-4 col-xl-3 mb-4';

  const unaddressedResponses = unaddressed(verificationDeadlineResponses);

  const numberOfVerificationImages = verificationImages.length;
  const numberOfUnaddressedResponses = unaddressedResponses.length;
  const numberOfCreatives = creativeContents.length + creativeFiles.length;

  const sponsorablePropertyName = getSponsorablePropertyName(offer);

  return (
    <>
      <tr data-test={`verification-deadline-row-${verificationDeadlineId}`}>
        <td className="text-nowrap">
          <VerificationsListIcon
            anyPendingVerificationImages={anyPendingVerificationImages}
            className="mr-2"
            didNotComplete={didNotComplete}
            key={deadline}
            overdue={overdue}
            verified={verified}
          />
          {formatDate(deadline)}
        </td>
        <td>{name}</td>
        <td>{capabilityName}</td>
        <td>
          {missingCreatives ? (
            <MissingCreativesIndicator />
          ) : (
            !!numberOfCreatives && (
              <ExpandRowButton
                expanded={creativesExpanded}
                idLabel={`creatives-${verificationDeadlineId}`}
                label={`${numberOfCreatives} ${pluralizedTense(
                  numberOfCreatives,
                  'creative',
                )}`}
                onClick={() => {
                  setVerificationsExpanded(false);
                  setCreativesExpanded(!creativesExpanded);
                }}
              />
            )
          )}
        </td>
        <td>
          <ExpandVerificationsButton
            expanded={verificationsExpanded}
            numberOfPendingVerifications={
              filterPending(verificationImages).length
            }
            numberOfUnaddressedResponses={numberOfUnaddressedResponses}
            numberOfVerificationImages={numberOfVerificationImages}
            onClick={() => {
              setCreativesExpanded(false);
              setVerificationsExpanded(!verificationsExpanded);
            }}
            verificationDeadlineId={verificationDeadlineId}
          />
        </td>
        <td className="text-center">
          {!numberOfVerificationImages && (
            <DeadlineActionsDropdown deadlineId={verificationDeadlineId}>
              <UpdateVerificationDeadlineModal
                sponsorablePropertyName={sponsorablePropertyName}
                verificationDeadline={verificationDeadline as any}
              />
              {!didNotComplete && (
                <MarkAsDidNotCompleteModal
                  sponsorablePropertyName={sponsorablePropertyName}
                  verificationDeadline={verificationDeadline}
                />
              )}
              <RemoveVerificationDeadlineModal
                sponsorablePropertyName={sponsorablePropertyName}
                verificationDeadline={verificationDeadline}
              />
            </DeadlineActionsDropdown>
          )}
        </td>
      </tr>
      {!(isEmpty(creativeFiles) && isEmpty(creativeContents)) &&
        creativesExpanded && (
          <ExpandedCreativesRow
            colClassName={expandedRowColClassName}
            creativeContents={creativeContents}
            creativeFiles={creativeFiles}
            tableColCount={tableColCount}
            verificationDeadlineId={verificationDeadlineId}
          />
        )}
      {!!(numberOfVerificationImages || numberOfUnaddressedResponses) &&
        verificationsExpanded && (
          <ExpandedVerificationsRow
            colClassName={expandedRowColClassName}
            tableColCount={tableColCount}
            verificationDeadlineId={verificationDeadlineId}
            verificationDeadlines={offer.verificationDeadlines}
            verificationDeadlineResponses={unaddressedResponses}
            verificationImages={verificationImages}
          />
        )}
    </>
  );
}

export default VerificationDeadlineRow;
