import * as React from 'react';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import { pluralizedTense } from 'utils/string';
import NewWindowLink from 'components/NewWindowLink';
import Badge from 'components/Badge';

interface SponsorableProperty {
  id: number;
  sponsorablePropertyPackagesCount: number;
  organization: Organization;
}

interface Organization {
  id: number;
}

interface Props {
  sponsorableProperty: SponsorableProperty;
}

const PackagesIndicator: React.FunctionComponent<Props> = ({
  sponsorableProperty,
}: Props) => {
  const {
    organization: { id: organizationId },
    sponsorablePropertyPackagesCount,
  } = sponsorableProperty;

  return sponsorablePropertyPackagesCount ? (
    <NewWindowLink
      hideIcon
      className="sui-block sui-mt-1"
      to={`${ORGANIZATIONS_PATH}/${organizationId}/packages`}
    >
      <Badge
        badgeType="packages"
        title="Sponsorable Property Packages"
        size="sm"
      >
        {`${sponsorablePropertyPackagesCount} ${pluralizedTense(
          sponsorablePropertyPackagesCount,
          'package',
        )}`}
      </Badge>
    </NewWindowLink>
  ) : null;
};

export default PackagesIndicator;
