import { useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { map, path, reject } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { sortByName } from 'utils/sort';
import { conjugateString } from 'utils/string';
import CAPABILITY_FRAGMENT from 'fragments/CapabilityFragment';
import { Input } from '@teamsnap/snap-ui';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';
import { findById } from 'utils/misc';
import QuantityGreaterThanVerificationDeadlineWarning from 'components/QuanitityGreaterThanVerificationDeadlineWarning';
import { DEFAULT_VERIFICATION_DEADLINE_FRAGMENT } from '../getCampaign';

const pluckCapabilityId = map(path(['capability', 'id']));

export const AddCampaignCapabilityModalUnwrapped = ({
  addedCampaignCapabilities,
  capabilities,
  create,
}) => {
  const [capabilityId, setCapabilityId] = useState('');
  const [minimumQuantity, setMinimumQuantity] = useState('');
  const [quantity, setQuantity] = useState('');

  const toggleDisabled =
    addedCampaignCapabilities.length === capabilities.length;

  const addedCapabilityIds = pluckCapabilityId(addedCampaignCapabilities);
  const isAdded = (capability) => addedCapabilityIds.includes(capability.id);
  const unaddedCapabilities = reject(isAdded, capabilities);

  const selectedCapability = findById(Number(capabilityId))(capabilities);

  const isQuanityGreaterThanCapabilityVerificationDeadlines =
    !!minimumQuantity &&
    selectedCapability &&
    selectedCapability.createDeadlineBasedOnQuantity &&
    selectedCapability.capabilityVerificationDeadlineCount < minimumQuantity;

  async function asyncSubmit() {
    await create({ capabilityId, minimumQuantity, quantity });
    setCapabilityId('');
    setMinimumQuantity('');
    setQuantity('');
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const prefix = 'add-campaign-capability';

  return (
    <ModalForm
      id={`${prefix}-modal`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: conjugateString('Add', loading),
        disabled: !capabilityId || loading,
      }}
      title="Add Campaign Capability"
      toggleProps={{
        className: 'btn btn-secondary',
        children: (
          <>
            <Icon className="mr-2" value={toggleDisabled ? 'check' : 'plus'} />
            {toggleDisabled
              ? 'All Campaign Capabilities Added'
              : 'Add A Campaign Capability'}
          </>
        ),
        disabled: toggleDisabled,
      }}
    >
      <section className="sui-grid sui-py-2" style={{ gap: 16 }}>
        <div>
          <Input
            type="select"
            label="Capability"
            name="capability"
            onChange={({ target: { value } }) => setCapabilityId(Number(value))}
            options={[
              { label: 'Select a Capability', value: '' },
              ...sortByName([...unaddedCapabilities]).map((capability) => ({
                label: capability.name,
                value: capability.id,
              })),
            ]}
            value={capabilityId}
          />
          {!!capabilityId && (
            <p
              className="form-text text-muted sui-mb-0 sui-mt-0"
              id="sponsor-facing-description"
            >
              {selectedCapability.sponsorFacingDescription}
            </p>
          )}
        </div>
        <Input
          type="number"
          label="Default Quantity"
          name="defaultQuantity"
          onChange={({ target: { value } }) =>
            setMinimumQuantity(Number(value))
          }
          required
          value={minimumQuantity}
          helpText="Please enter in the default quantity that should appear in each sports organization's league."
        />
        <Input
          type="number"
          label="Total Contracted Asset Quantity"
          name="quantity"
          onChange={({ target: { value } }) => setQuantity(Number(value))}
          required
          value={quantity}
        />
        {isQuanityGreaterThanCapabilityVerificationDeadlines && (
          <QuantityGreaterThanVerificationDeadlineWarning />
        )}
      </section>
    </ModalForm>
  );
};

const addCampaignCapabilityModalPropTypes = {
  addedCampaignCapabilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      capability: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
      minimumQuantity: PropTypes.number,
      executionExpectations: PropTypes.string,
    }),
  ),
};

AddCampaignCapabilityModalUnwrapped.propTypes = {
  addedCampaignCapabilities:
    addCampaignCapabilityModalPropTypes.addedCampaignCapabilities.isRequired,
  capabilities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  create: PropTypes.func.isRequired,
};

AddCampaignCapabilityModalUnwrapped.defaultProps = {
  capabilities: [],
};

const MUTATION = gql`
  mutation CreateCampaignCapability($input: CreateCampaignCapabilityInput!) {
    createCampaignCapability(input: $input) {
      campaign {
        id
        campaignCapabilities {
          id
          capability {
            id
            capabilityVerificationDeadlines {
              id
              name
            }
          }
          defaultVerificationDeadlines {
            ...DefaultVerificationDeadlineFragment
            creativeContentTemplates {
              id
            }
            creativeFileTemplates {
              id
            }
          }
          minimumQuantity
          quantity
          executionExpectations
        }
      }
    }
  }
  ${DEFAULT_VERIFICATION_DEADLINE_FRAGMENT}
`;

const CAPABILITIES_QUERY = gql`
  query GetCapabilities($campaignScope: Boolean) {
    capabilities(campaignScope: $campaignScope) {
      ...CapabilityFragment
      sponsorFacingDescription
      createDeadlineBasedOnQuantity
      capabilityVerificationDeadlineCount
    }
  }
  ${CAPABILITY_FRAGMENT}
`;

const capabilitiesPath = path(['data', 'capabilities']);

const AddCampaignCapabilityModal = ({
  addedCampaignCapabilities,
  campaignId,
}) => (
  <Query
    fetchPolicy="network-only"
    query={CAPABILITIES_QUERY}
    variables={{ campaignScope: true }}
  >
    {(capabilitiesResponse) => (
      <Mutation mutation={MUTATION}>
        {(mutate) => (
          <AddCampaignCapabilityModalUnwrapped
            addedCampaignCapabilities={addedCampaignCapabilities}
            capabilities={capabilitiesPath(capabilitiesResponse)}
            create={({ capabilityId, minimumQuantity, quantity }) =>
              mutate({
                variables: {
                  input: {
                    campaignId,
                    capabilityId,
                    minimumQuantity,
                    quantity,
                  },
                },
              })
            }
          />
        )}
      </Mutation>
    )}
  </Query>
);

AddCampaignCapabilityModal.propTypes = {
  addedCampaignCapabilities:
    addCampaignCapabilityModalPropTypes.addedCampaignCapabilities.isRequired,
  campaignId: PropTypes.number.isRequired,
};

export default AddCampaignCapabilityModal;
