import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { history as storeHistory } from 'store';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import Icon from 'components/Icon';
import CreateOrSelectOrganizationModal from 'components/CreateOrSelectOrganizationModal';

const navigateToOrganizationPage = ({ organizationId }) => {
  storeHistory.push(`${ORGANIZATIONS_PATH}/${organizationId}`);
};

const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      organization {
        id
      }
    }
  }
`;

const AddOrganizationModal = () => {
  const [mutate] = useMutation(CREATE_ORGANIZATION);

  return (
    <CreateOrSelectOrganizationModal
      add={navigateToOrganizationPage}
      create={(input) =>
        mutate({
          mutation: CREATE_ORGANIZATION,
          variables: { input },
        }).then((response) => response)
      }
      redirect={navigateToOrganizationPage}
      toggleProps={{
        className: 'btn btn-secondary',
        children: (
          <>
            <Icon className="mr-2" value="plus" />
            Add An Organization
          </>
        ),
      }}
    />
  );
};

export default AddOrganizationModal;
