import * as React from 'react';
import BooleanRadioInputs from 'components/formInputs/BooleanRadioInputs';

interface Props {
  disabled?: boolean;
  prefix?: string;
  replaceCreatives: boolean;
  setReplaceCreatives(replaceCreatives: boolean): void;
}

const ReplaceCreativesInput: React.FunctionComponent<Props> = ({
  disabled,
  prefix,
  replaceCreatives,
  setReplaceCreatives,
}: Props) => (
  <div className="mb-3">
    <strong>Would you also like to replace creatives for this deadline?</strong>
    <BooleanRadioInputs
      disabled={disabled}
      firstOptionTrue
      id="replace-creatives"
      indent
      option1="Yes"
      option2="Not Now"
      prefix={prefix}
      setValue={setReplaceCreatives}
      value={replaceCreatives}
    />
  </div>
);

ReplaceCreativesInput.defaultProps = {
  disabled: false,
  prefix: undefined,
};

export default ReplaceCreativesInput;
