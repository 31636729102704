import gql from 'graphql-tag';

const SPONSORSHIP_INSIGHT_REPORT_FRAGMENT = gql`
  fragment SponsorshipInsightReportFragment on SponsorshipInsightReport {
    averageConfirmedValue
    confirmedCount
    onTimeVerificationPercentage
  }
`;

export default SPONSORSHIP_INSIGHT_REPORT_FRAGMENT;
