import * as React from 'react';
import VerificationImage from 'types/verificationImage';
import formatDate from 'utils/formatDate';
import ImageCard from 'components/ImageCard';

interface Props extends React.HTMLAttributes<HTMLElement> {
  border?: string;
  displayHeader?: boolean;
  verificationImage: VerificationImage;
}

const VerificationImageCard: React.FunctionComponent<Props> = ({
  border,
  children,
  displayHeader,
  verificationImage,
}: Props) => {
  const {
    id: verificationImageId,
    createdAt,
    from,
    fullSizeUrl,
    thumbnailUrl,
  } = verificationImage;

  const header = (
    <>
      <span className="float-left" data-test="date">
        {formatDate(createdAt)}
      </span>
      <span className="float-right" data-test="sender">
        {from}
      </span>
    </>
  );

  return (
    <ImageCard
      border={border}
      cardId={`verification-image-${verificationImageId}`}
      fullSizeUrl={fullSizeUrl}
      header={displayHeader ? header : undefined}
      imgAlt={`${verificationImageId}`}
      thumbnailUrl={thumbnailUrl}
    >
      {children}
    </ImageCard>
  );
};

VerificationImageCard.defaultProps = {
  border: undefined,
  displayHeader: undefined,
};

export default VerificationImageCard;
