import { allPass, ascend, both, prop, propEq, sortWith } from 'ramda';
import { notProp } from './misc';

export const sortWithDeadlineAndName = sortWith([
  ascend(prop('deadline')),
  ascend(prop('name')),
]);

export const hasMatchingNameAndCapability = (name, capabilityName) =>
  both(propEq('capabilityName', capabilityName), propEq('name', name));

export const displayCreativeNotificationAlertByProp = (propValue) =>
  allPass([prop(propValue), notProp('verified'), notProp('didNotComplete')]);
export const displayCreativeNotificationAlert =
  displayCreativeNotificationAlertByProp('missingCreatives');
