import React from 'react';
import PropTypes from 'prop-types';
import { joinWithComma } from 'utils/string';

const ModalEmailBodyPreview = ({ to, email, title }) => {
  const srcDoc = () =>
    `<p style="color: white;"><strong>To:</strong> ${joinWithComma(
      to,
    )}</p><hr />${email}`;

  return (
    <iframe
      className="border rounded w-100"
      frameBorder={0}
      sandbox=""
      srcDoc={srcDoc()}
      title={title}
    />
  );
};

ModalEmailBodyPreview.propTypes = {
  to: PropTypes.arrayOf(PropTypes.string).isRequired,
  email: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModalEmailBodyPreview;
