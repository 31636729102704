import * as React from 'react';
import classNames from 'classnames';

export interface Props extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  value: string;
}

const Icon: React.FunctionComponent<Props> = ({
  className = '',
  value,
  ...rest
}: Props) => (
  <span className={classNames(`icon icon-${value}`, className)} {...rest} />
);

export default Icon;
