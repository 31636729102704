import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useWizardContext } from 'pages/qualify/provider';
import { parseAnyListToOptionsList } from 'pages/qualify/utils';
import { WIZARD_IMAGES_PER_STEP_ID } from 'pages/qualify/wizard-images-urls';
import moment from 'moment';
import WizardInput from '../wizard-input';
import WizardStep from '../wizard-step';
import { Inputs, Organization, StepIdsEnum } from '../../types';

export default function ProductSamplingStep() {
  const { tokenData, watch, setValues, values, token, organizationData } =
    useWizardContext();
  const {
    data: applicationAssetQueryData,
    loading,
    refetch,
  } = useQuery<{
    sponsorshipApplicationView: Partial<Organization>;
  }>(APPLICATION_ASSET_QUERY, {
    variables: { season: tokenData.season, year: tokenData.year },
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });
  const [performMutation] = useMutation(MUTATION);
  const accepted = watch('accepted');

  const modifyInputsBeforeMutation = (
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    ...data,
    attendance: Number(data.attendance),
  });

  useEffect(() => {
    if (values.organizationId) {
      const { savedStepId } = organizationData?.sponsorshipApplication;
      if (savedStepId === StepIdsEnum.SAMPLING) return;
      const { samplingDetail } =
        applicationAssetQueryData?.sponsorshipApplicationView
          .sponsorshipApplication ?? {};
      setValues({
        ...values,
        accepted:
          applicationAssetQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.samplingAccepted ?? false,
        description: samplingDetail?.capabilityDetailable?.description,
        attendance: samplingDetail?.capabilityDetailable?.attendance,
        eventDate: samplingDetail?.capabilityDetailable?.eventDate,
        canDistributeFood:
          samplingDetail?.capabilityDetailable?.canDistributeFood,
        canAllowOutsideFood:
          samplingDetail?.capabilityDetailable?.canAllowOutsideFood,
        hasElectricity: samplingDetail?.capabilityDetailable?.hasElectricity,
        sponsorablePropertyIds:
          samplingDetail?.associatedSponsorableProperties?.map(
            (property) => property.id,
          ),
        refusalReason:
          applicationAssetQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.samplingRefusalReason,
      });
    }
  }, [values.organizationId, applicationAssetQueryData]);

  return (
    <WizardStep
      performMutation={performMutation}
      refetchAfterMutation={refetch}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId:
          applicationAssetQueryData?.sponsorshipApplicationView
            ?.sponsorshipApplication?.id,
        offerId: tokenData.offer_id,
        id: applicationAssetQueryData?.sponsorshipApplicationView
          .sponsorshipApplication?.samplingDetail?.capabilityDetailable?.id,
      }}
      modifyInputsBeforeMutation={modifyInputsBeforeMutation}
      isLoading={loading}
    >
      <WizardStep.Picture
        src={WIZARD_IMAGES_PER_STEP_ID[StepIdsEnum.SAMPLING]}
      />
      <WizardStep.Content title="Tabling and product sampling">
        <div className="sui-grid" style={{ gap: 24 }}>
          <WizardInput
            type="boolean-question"
            name="accepted"
            label="My organization hosts a large event where a sponsor representative can set up a table for product samples and/or giveaways"
          />
          {accepted && (
            <>
              <WizardInput
                type="number"
                name="attendance"
                label="Anticipated number of event attendees"
              />
              <WizardInput
                type="date"
                name="eventDate"
                label="If scheduled, provide the approximate date of the event"
                rules={{
                  validate: {
                    todayOrAfterToday: (value) => {
                      const pickedDate = moment(value);
                      const today = moment().startOf('day');
                      if (pickedDate.isBefore(today)) {
                        return 'Approximate date of the event must be today or later';
                      }
                      return true;
                    },
                  },
                }}
              />
              <WizardInput
                type="textarea"
                name="description"
                label="Description of event"
              />

              <div className="sui-grid sui-py-2" style={{ gap: 32 }}>
                <WizardInput
                  type="boolean-question"
                  name="canDistributeFood"
                  label="Sponsors are allowed to distribute food at my organization's events"
                />
                <WizardInput
                  type="boolean-question"
                  name="canAllowOutsideFood"
                  label="My organization has concession stands that allow outside food distribution"
                />
                <WizardInput
                  type="boolean-question"
                  name="hasElectricity"
                  label="My organization's event venue has access to electricity for sponsors"
                />
              </div>
              <WizardInput
                type="select"
                isMulti
                name="sponsorablePropertyIds"
                label="Programs this applies to"
                rules={{ required: 'Please select at least one program' }}
                options={parseAnyListToOptionsList(
                  values.sponsorableProperties ?? [],
                  { withEmptyOption: false },
                )}
              />
            </>
          )}
          {!accepted && (
            <WizardInput
              type="textarea"
              name="refusalReason"
              label="If possible, please share your reasons and specify the amount of funding at which you might reconsider opting in to this asset"
            />
          )}
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const APPLICATION_ASSET_QUERY = gql`
  query SponsorshipApplicationView($season: String!, $year: Int!) {
    sponsorshipApplicationView {
      id
      sponsorshipApplication(season: $season, year: $year) {
        id
        samplingAccepted
        samplingRefusalReason
        samplingDetail {
          id
          price
          associatedSponsorableProperties {
            id
            name
          }
          capabilityDetailable {
            ... on InPersonProductSampling {
              id
              description
              attendance
              eventDate
              canDistributeFood
              canAllowOutsideFood
              hasElectricity
            }
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation upsertSamplingAndTabling($input: UpsertSamplingAndTablingInput!) {
    upsertSamplingAndTabling(input: $input) {
      id
    }
  }
`;
