import PropTypes from 'prop-types';
import { joinWithComma } from 'utils/string';
import useFetchAndSetEmailPreview from '../hooks/useFetchAndSetEmailBodyPreview';

const ModalEmailBodyPreview = ({ fetchEmail, title }) => {
  const [email, error] = useFetchAndSetEmailPreview(fetchEmail);

  const srcDoc = () => {
    if (error)
      return '<p>Uh oh! We had troubling loading this email preview.</p>';
    if (email)
      return `<p style="color: white;"><strong>To:</strong> ${joinWithComma(
        email.to,
      )}</p><hr />${email.htmlBody}`;

    return '<i>Loading email preview...</i>';
  };

  return (
    <iframe
      className="border rounded w-100"
      sandbox=""
      srcDoc={srcDoc()}
      title={title}
    />
  );
};

ModalEmailBodyPreview.propTypes = {
  fetchEmail: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModalEmailBodyPreview;
