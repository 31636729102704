import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { map } from 'ramda';
import gql from 'graphql-tag';
import { history } from 'store';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { useSubmit } from 'utils/hooks';
import hideModal from 'utils/hideModal';
import { ORGANIZATION_PACKAGES_PATH } from 'utils/paths';
import { capitalizeEachWord, loadingTense } from 'utils/string';
import { PackageType } from 'types/leaguePackage';
import ModalForm from 'components/ModalForm';
import PackageNameInput from 'components/formInputs/PackageNameInput';
import PriceInput from 'components/formInputs/PriceInput';
import SelectInput from 'components/formInputs/SelectInput';
import SponsorablePropertyMultiSelect from 'components/formInputs/SponsorablePropertyMultiSelect';

export const AddOrganizationPackageModalUnwrapped = ({
  add,
  internal,
  sponsorableProperties,
}) => {
  const packageTypes = [PackageType.UserGenerated, PackageType.Website];

  const [name, setName] = useState('');
  const [packageType, setPackageType] = useState(packageTypes[0]);
  const [price, setPrice] = useState('');
  const [sponsorablePropertyIds, setSponsorablePropertyIds] = useState([]);

  const requiredDataPresent = !!(
    name &&
    price &&
    packageType &&
    sponsorablePropertyIds.length
  );

  const modalId = 'createPackage';

  const hideModalAndNavigateToLeaguePackage = (id) => {
    if (id) {
      hideModal(`#${modalId}`);
      history.push(`${ORGANIZATION_PACKAGES_PATH}/${id}`);
    }
  };

  async function asyncSubmit() {
    const result = await add({
      name,
      packageType,
      price: Number(price),
      sponsorablePropertyIds,
    });

    hideModalAndNavigateToLeaguePackage(
      result.data.createOrganizationPackage.organizationPackage.id,
    );
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  return (
    <ModalForm
      id={modalId}
      error={error}
      handleSubmit={handleSubmit}
      hasRequiredFields
      loading={loading}
      submitProps={{
        children: loadingTense(loading, 'Save'),
        disabled: !requiredDataPresent || loading,
      }}
      title="Create Package"
      toggleProps={{
        className: 'btn btn-primary',
        children: 'Create Package',
      }}
    >
      <PackageNameInput disabled={loading} name={name} setName={setName} />
      <PriceInput
        disabled={loading}
        onChange={({ target: { value } }) => setPrice(value)}
        required
        value={price}
      />
      <SponsorablePropertyMultiSelect
        setSponsorablePropertyIds={setSponsorablePropertyIds}
        sponsorableProperties={sponsorableProperties}
        sponsorablePropertyIds={sponsorablePropertyIds}
        sponsorablePropertyIdsEmpty={!sponsorablePropertyIds.length}
        loading={loading}
      />
      {internal && (
        <SelectInput
          disabled={loading}
          label="Package Type"
          noDefault
          onChange={({ target: { value } }) => setPackageType(value)}
          required
          value={packageType}
        >
          {map(
            (type) => (
              <option key={type} value={type}>
                {capitalizeEachWord(type)}
              </option>
            ),
            packageTypes,
          )}
        </SelectInput>
      )}
    </ModalForm>
  );
};

AddOrganizationPackageModalUnwrapped.propTypes = {
  add: PropTypes.func.isRequired,
  internal: PropTypes.bool.isRequired,
  sponsorableProperties: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ).isRequired,
};

const MUTATION = gql`
  mutation CreateOrganizationPackage($input: CreateOrganizationPackageInput!) {
    createOrganizationPackage(input: $input) {
      organizationPackage {
        id
      }
    }
  }
`;

const AddOrganizationPackageModal = ({ internal, league }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <AddOrganizationPackageModalUnwrapped
        add={({ name, packageType, price, sponsorablePropertyIds }) =>
          mutate({
            variables: {
              input: {
                organizationId: Number(league.id),
                name,
                packageType,
                price,
                sponsorablePropertyIds,
              },
            },
          })
        }
        internal={internal}
        sponsorableProperties={league.sponsorableProperties}
      />
    )}
  </Mutation>
);

AddOrganizationPackageModal.propTypes = {
  internal: PropTypes.bool,
  league: LeagueSidePropTypes.league.isRequired,
};

AddOrganizationPackageModal.defaultProps = {
  internal: false,
};

export default AddOrganizationPackageModal;
