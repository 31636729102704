import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import RemoveGenericCreativeModal from 'components/RemoveGenericCreativeModal';
import { DEFAULT_VERIFICATION_DEADLINE_MINIMAL_FRAGMENT } from '../getCampaign';

const MUTATION = gql`
  mutation DeleteCreativeContentTemplate(
    $input: CreativeContentTemplateInput!
  ) {
    deleteCreativeContentTemplate(input: $input) {
      defaultVerificationDeadline {
        ...DefaultVerificationDeadlineMinimalFragment
        creativeContentTemplates {
          id
        }
      }
    }
  }
  ${DEFAULT_VERIFICATION_DEADLINE_MINIMAL_FRAGMENT}
`;

const RemoveCreativeContentTemplateModal = ({
  creativeContentTemplate,
  toggleClassName,
}) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <RemoveGenericCreativeModal
        creativeType="content"
        id={creativeContentTemplate.id}
        name={creativeContentTemplate.name}
        remove={() =>
          mutate({
            variables: {
              input: { id: Number(creativeContentTemplate.id) },
            },
          })
        }
        toggleClassName={toggleClassName}
      />
    )}
  </Mutation>
);

RemoveCreativeContentTemplateModal.propTypes = {
  creativeContentTemplate: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  toggleClassName: PropTypes.string,
};

RemoveCreativeContentTemplateModal.defaultProps = {
  toggleClassName: 'btn btn-link text-danger p-0',
};

export default RemoveCreativeContentTemplateModal;
