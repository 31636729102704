import { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { useWizardContext } from 'pages/qualify/provider';
import { Organization, StepIdsEnum } from 'pages/qualify/types';
import { parseAnyListToOptionsList } from 'pages/qualify/utils';
import { WIZARD_IMAGES_PER_STEP_ID } from 'pages/qualify/wizard-images-urls';
import WizardInput from '../wizard-input';
import WizardStep from '../wizard-step';

export default function SurveysStep() {
  const { tokenData, watch, values, token, setValues, organizationData } =
    useWizardContext();
  const {
    data: applicationAssetQueryData,
    loading,
    refetch,
  } = useQuery<{
    sponsorshipApplicationView: Partial<Organization>;
  }>(APPLICATION_SURVEYS_ASSETS_QUERY, {
    variables: { season: tokenData.season, year: tokenData.year },
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });
  const [performMutation] = useMutation(MUTATION);
  const accepted = watch('accepted');

  useEffect(() => {
    if (values.organizationId) {
      const { savedStepId } = organizationData?.sponsorshipApplication;
      if (savedStepId === StepIdsEnum.SURVEYS) return;
      const { surveysDetail } =
        applicationAssetQueryData?.sponsorshipApplicationView
          .sponsorshipApplication ?? {};
      setValues({
        ...values,
        accepted:
          applicationAssetQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.surveysAccepted ?? false,
        sponsorablePropertyIds:
          surveysDetail?.associatedSponsorableProperties?.map(
            (property) => property.id,
          ),
        refusalReason:
          applicationAssetQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.surveysRefusalReason,
      });
    }
  }, [values.organizationId, applicationAssetQueryData]);

  return (
    <WizardStep
      isLoading={loading}
      performMutation={performMutation}
      refetchAfterMutation={refetch}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId:
          applicationAssetQueryData?.sponsorshipApplicationView
            ?.sponsorshipApplication?.id,
        offerId: tokenData.offer_id,
        id: applicationAssetQueryData?.sponsorshipApplicationView
          .sponsorshipApplication?.surveysDetail?.id,
      }}
    >
      <WizardStep.Picture
        src={WIZARD_IMAGES_PER_STEP_ID[StepIdsEnum.SURVEYS]}
      />
      <WizardStep.Content
        title="Surveys"
        subtitle="Surveys will be provided to your organization to share. Surveys typically take from 5-10 minutes to complete"
      >
        <div className="sui-grid" style={{ gap: 24 }}>
          <WizardInput
            type="boolean-question"
            name="accepted"
            label="My organization is capable of distributing feedback surveys to parents/families before and after the sponsorship"
            helpText="Average sponsorship funding $390-$455"
          />

          {accepted && (
            <>
              <WizardInput
                type="select"
                isMulti
                name="sponsorablePropertyIds"
                label="Programs this applies to"
                rules={{ required: 'Please select at least one program' }}
                options={parseAnyListToOptionsList(
                  values.sponsorableProperties ?? [],
                  { withEmptyOption: false },
                )}
              />
            </>
          )}
          {!accepted && (
            <>
              <WizardInput
                type="textarea"
                name="refusalReason"
                label="If possible, please share your reasons and specify the amount of funding at which you might reconsider opting in to this asset"
              />
            </>
          )}
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const APPLICATION_SURVEYS_ASSETS_QUERY = gql`
  query SponsorshipApplicationView($season: String!, $year: Int!) {
    sponsorshipApplicationView {
      id
      sponsorshipApplication(season: $season, year: $year) {
        id
        surveysAccepted
        surveysRefusalReason
        surveysDetail {
          id
          price
          maxForSale
          associatedSponsorableProperties {
            id
            name
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation upsertSurveys($input: UpsertSurveysInput!) {
    upsertSurveys(input: $input) {
      id
    }
  }
`;
