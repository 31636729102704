import { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useWizardContext } from 'pages/qualify/provider';
import { useFieldArray } from 'react-hook-form';
import { LabelButton } from '@teamsnap/snap-ui';
import { geocodeByAddress } from 'react-places-autocomplete';
import {
  COUNTRIES_LIST,
  COUNTRIES_SHORT_NAME_LIST,
  STATES_LIST,
  parseAnyListToOptionsList,
} from 'pages/qualify/utils';
import { Inputs } from 'pages/qualify/types';
import { getAddressComponents } from 'components/PlacesAutocomplete';
import WizardStep from '../wizard-step';
import WizardInput from '../wizard-input';

export default function PlayLocationsStep() {
  const {
    tokenData,
    control,
    values,
    setValue,
    watch,
    trigger,
    setError,
    formState: { isValid },
  } = useWizardContext();
  const [performMutation] = useMutation(MUTATION);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'playLocations',
  });
  const [isLoading, setIsLoading] = useState(true);
  const playLocations = watch('playLocations');
  const playLocationsPrimaryProgramnsIdsPicked = playLocations
    ?.filter((playLocation) => playLocation?.primaryProgramIds?.length)
    .map((playLocation) => [...playLocation?.primaryProgramIds])
    .flatMap((primaryProgramIds) => primaryProgramIds);

  const modifyValuesAfterMutation = (
    response: any,
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    ...data,
    playLocations: data?.playLocations?.map((playLocation, index) => ({
      ...playLocation,
      id: response?.data?.upsertPlayLocations?.playLocations?.[index]?.id,
    })),
  });

  const geocodeAndSetLocation = async (
    addressToGeocode: string,
    index: number,
  ) => {
    const geocodedAddress = await geocodeByAddress(addressToGeocode);
    const { country, route, streetNumber, state, city, zipcode } =
      getAddressComponents(geocodedAddress[0]);
    if (['US', 'CA'].includes(country)) {
      setValue(
        `playLocations.${index}.address`,
        [streetNumber, route].join(' ').trim(),
      );
      setValue(
        `playLocations.${index}.country`,
        COUNTRIES_SHORT_NAME_LIST[
          country as keyof typeof COUNTRIES_SHORT_NAME_LIST
        ] ?? '',
      );
      setValue(`playLocations.${index}.state`, state ?? '');
      setValue(`playLocations.${index}.city`, city ?? '');
      setValue(`playLocations.${index}.zipcode`, zipcode ?? '');
      // Trigger validation for the fields that were set
      trigger(`playLocations.${index}.country`);
      trigger(`playLocations.${index}.state`);
      trigger(`playLocations.${index}.city`);
      trigger(`playLocations.${index}.zipcode`);
    } else {
      setError(`playLocations.${index}.address`, {
        type: 'manual',
        message: 'Address must be in the US or Canada',
      });
    }
  };

  useEffect(() => {
    if (values.organizationId) {
      setValue(
        'playLocations',
        values.playLocations?.length
          ? values.playLocations
          : [DEFAULT_PLAY_LOCATION],
      );
      setIsLoading(false);
    }
  }, [values.organizationId]);

  const allPrimaryProgramIdsPicked = () => {
    return (values?.sponsorableProperties ?? []).every(
      (program) => playLocationsPrimaryProgramnsIdsPicked?.includes(program.id),
    );
  };

  return (
    <WizardStep
      performMutation={performMutation}
      modifyValuesAfterMutation={modifyValuesAfterMutation}
      isLoading={isLoading}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId: values.sponsorshipApplicationId,
        offerId: tokenData.offer_id,
      }}
    >
      <WizardStep.Content title="Provide details for the primary locations where sponsored assets (banners, product tables, etc) could be placed.">
        <h2 className="sui-text-mobile-5 d:sui-text-desktop-6 sui-font-bold sui-mb-3">
          Locations
        </h2>

        {fields.map((field, index) => (
          <section
            key={field.id}
            className="sui-grid sui-p-2 sui-bg-neutral-background-weak sui-mb-2"
            style={{ gap: 16 }}
          >
            <header className="sui-flex sui-justify-between sui-items-center sui-h-6">
              <p className="sui-text-mobile-4 t:sui-text-desktop-5">
                Location {index + 1}
              </p>
              {fields.length > 1 && (
                <LabelButton
                  icon="delete"
                  iconPosition="left"
                  labelText="Remove"
                  sentiment="negative"
                  variantType="tertiary"
                  onClick={() => remove(index)}
                />
              )}
            </header>

            <WizardInput
              type="address"
              name={`playLocations.${index}.address`}
              label="Address"
              rules={{ required: 'Address is required' }}
              onSelectAddressLocation={(placesLocation: string) =>
                geocodeAndSetLocation(placesLocation, index)
              }
            />
            <div
              className="sui-flex sui-flex-col t:sui-grid"
              style={{
                gridTemplateColumns: '1fr 180px',
                gap: 16,
              }}
            >
              <WizardInput
                type="text"
                name={`playLocations.${index}.city`}
                label="City"
                rules={{ required: 'City is required' }}
              />
              <WizardInput
                type="select"
                name={`playLocations.${index}.state`}
                label="State/Province"
                rules={{ required: 'State/Province is required' }}
                options={STATES_LIST}
                placeholder="Select"
              />
            </div>

            <div
              className="sui-flex sui-flex-col t:sui-grid sui-mb-2"
              style={{
                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                gap: 16,
              }}
            >
              {playLocations &&
                (!playLocations[index]?.country ||
                  playLocations[index]?.country ===
                    'United States of America') && (
                  <WizardInput
                    type="text"
                    name={`playLocations.${index}.zipcode`}
                    label="Zip/Postal Code"
                    rules={{
                      required: 'Zip/Postal Code is required',
                      validate: (value) =>
                        value && !/^\d+$/.test(value)
                          ? 'Zip/Postal Code must be numeric for US'
                          : true,
                    }}
                  />
                )}
              {playLocations && playLocations[index]?.country === 'Canada' && (
                <WizardInput
                  type="text"
                  name={`playLocations.${index}.zipcode`}
                  label="Zip/Postal Code"
                  rules={{ required: 'Zip/Postal Code is required' }}
                />
              )}
              <WizardInput
                type="select"
                name={`playLocations.${index}.country`}
                label="Country"
                rules={{ required: 'Country is required' }}
                options={COUNTRIES_LIST}
                placeholder="Select a country"
              />
            </div>

            <WizardInput
              type="select"
              isMulti
              name={`playLocations.${index}.primaryProgramIds`}
              label="Add the programs that use this as their primary location."
              rules={
                !allPrimaryProgramIdsPicked()
                  ? {
                      required:
                        'Programs that use as primary location is required',
                    }
                  : {}
              }
              options={parseAnyListToOptionsList(
                /* 
                  Filter out the programs that are already picked in other
                  play locations and other programs in the same play location
                */
                (playLocations &&
                  values.sponsorableProperties?.filter(
                    (property) =>
                      (playLocations[index]?.primaryProgramIds?.includes(
                        property.id,
                      ) ||
                        !playLocationsPrimaryProgramnsIdsPicked?.includes(
                          property.id,
                        )) &&
                      !playLocations[index]?.otherProgramIds?.includes(
                        property.id,
                      ),
                  )) ??
                  [],
                { withEmptyOption: false },
              )}
              showHelpIcon
              helpIconTooltipText="Specifying the primary location for your program(s) enables us to align you with relevant sponsorship opportunities. Each program is required to have an associated primary location, and multiple locations can be added."
            />

            {values.sponsorableProperties &&
              values.sponsorableProperties.length > 1 && (
                <WizardInput
                  type="select"
                  isMulti
                  name={`playLocations.${index}.otherProgramIds`}
                  label="List any other programs that use this location but have another primary location."
                  options={parseAnyListToOptionsList(
                    // Filter out the programs that are already picked in as primary location in the same play location
                    (playLocations &&
                      values.sponsorableProperties?.filter(
                        (property) =>
                          !playLocations[index]?.primaryProgramIds?.includes(
                            property.id,
                          ),
                      )) ??
                      [],
                    { withEmptyOption: false },
                  )}
                />
              )}
          </section>
        ))}

        <LabelButton
          icon="add"
          iconPosition="left"
          labelText="Add another play location"
          variantType="tertiary"
          disabled={!isValid}
          onClick={() => append(DEFAULT_PLAY_LOCATION)}
          data-testid="add-play-location"
        />
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation upsertPlayLocations($input: UpsertPlayLocationsInput!) {
    upsertPlayLocations(input: $input) {
      id
      playLocations {
        id
      }
    }
  }
`;

const DEFAULT_PLAY_LOCATION = {
  id: 0, // 0 is an invalid id used to indicate a new play location
  address: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
  primaryProgramIds: [],
  otherProgramIds: [],
};
