import { both, map, path, pathEq, pipe, sortBy } from 'ramda';
import { joinWithComma } from './string';

const isPhysical = pathEq(
  ['campaignCapability', 'capability', 'digital'],
  false,
);
const hasQuantity = (asset) => asset.quantity > 0;

export const hasPhysicalQuantity = both(isPhysical, hasQuantity);

export const campaignCapabilityCapabilityName = path([
  'campaignCapability',
  'capability',
  'name',
]);
export const campaignCapabilityCapabilityVerificationDeadlines = path([
  'campaignCapability',
  'capability',
  'capabilityVerificationDeadlines',
]);
export const sortByCampaignCapabilityCapabilityName = sortBy(
  campaignCapabilityCapabilityName,
);
export const listAssetsWithCount = pipe(
  sortByCampaignCapabilityCapabilityName,
  map(
    (asset) => `${asset.quantity} ${asset.campaignCapability.capability.name}`,
  ),
  joinWithComma,
);
export const listAssets = pipe(
  sortByCampaignCapabilityCapabilityName,
  map(campaignCapabilityCapabilityName),
  joinWithComma,
);
