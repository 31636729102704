import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import React from 'react';
import TESTIMONIAL_FRAGMENT from 'fragments/TestimonialFragment';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import TextAreaInput from 'components/formInputs/TextAreaInput';

const INITIAL_STATE = { attribution: null, quote: null };

class TestimonialCreateModalUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = INITIAL_STATE;
  }

  async handleSubmit() {
    const { props, state } = this;
    const { attribution, quote } = state;

    await props.onSubmit(attribution, quote);

    this.setState(INITIAL_STATE);
  }

  render() {
    const { props, state } = this;
    const { campaignId } = props;
    const { quote } = state;

    return (
      <ModalForm
        id={`testimonial-create-${campaignId}-modal`}
        hasRequiredFields
        onSubmit={this.handleSubmit}
        submitProps={{
          children: 'Add',
          disabled: !quote,
        }}
        toggleProps={{
          className: 'btn btn-secondary mr-3',
          children: (
            <>
              <Icon className="mr-2" value="plus" />
              Add A Testimonial
            </>
          ),
        }}
        title="Create a Testimonial"
      >
        <TextAreaInput
          label="Quote"
          onChange={({ target: { value } }) => this.setState({ quote: value })}
          required
          value={quote || ''}
        />
        <div className="form-group">
          <label htmlFor="attribution-input">Attribution</label>
          <input
            className="form-control"
            id="attribution-input"
            onChange={({ target: { value } }) =>
              this.setState({ attribution: value })
            }
            type="text"
            value={state.attribution || ''}
          />
        </div>
      </ModalForm>
    );
  }
}

TestimonialCreateModalUnwrapped.propTypes = {
  campaignId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { TestimonialCreateModalUnwrapped };

const MUTATION = gql`
  mutation CreateTestimonial($input: TestimonialInput!) {
    createTestimonial(input: $input) {
      id
      testimonials {
        ...TestimonialFragment
      }
    }
  }
  ${TESTIMONIAL_FRAGMENT}
`;

const TestimonialCreateModal = ({ campaignId }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <TestimonialCreateModalUnwrapped
        campaignId={campaignId}
        onSubmit={(attribution, quote) =>
          mutate({
            variables: {
              input: {
                campaignId,
                attribution,
                quote,
              },
            },
          })
        }
      />
    )}
  </Mutation>
);

TestimonialCreateModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
};

export default TestimonialCreateModal;
