import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Alert from 'components/Alert';
import ErrorAlert from 'components/ErrorAlert';

class ChangePasswordUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = { changeSuccess: null };

    this.setPassword = (e) => this.setState({ password: e.target.value });
    this.setPasswordConfirmation = (e) =>
      this.setState({ passwordConfirmation: e.target.value });

    this.changePassword = this.changePassword.bind(this);
  }

  async changePassword(event) {
    try {
      event.preventDefault();
      const { updatePassword } = this.props;
      const { password, passwordConfirmation } = this.state;
      await updatePassword(password, passwordConfirmation);
      this.setState({
        changeSuccess: true,
        password: '',
        passwordConfirmation: '',
      });
    } catch (error) {
      this.setState({ changeSuccess: false });
    }
  }

  renderChangeResult() {
    const { changeSuccess } = this.state;
    if (changeSuccess === null) return null;

    if (!changeSuccess) {
      return (
        <ErrorAlert>
          Uh oh! We could not update your password. Please be sure your new
          password meets the criteria above and try again.
        </ErrorAlert>
      );
    }

    return (
      <Alert alertStyle="success" role="alert">
        Password changed successfully!
      </Alert>
    );
  }

  render() {
    const { password, passwordConfirmation } = this.state;
    return (
      <FieldhousePageTitle title="Change Password">
        <Page>
          <div className="card mt-4">
            <div className="card-body">
              <h3>Change Password</h3>
              <p>
                Your password must be more than 8 characters and contain an
                uppercase and lowercase letter, a number and a symbol.
              </p>
              {this.renderChangeResult()}
              <form onSubmit={this.changePassword}>
                <div className="form-group">
                  <label htmlFor="password">New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    placeholder="Password"
                    onChange={this.setPassword}
                    value={password}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="password-confirmation">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="password-confirmation"
                    placeholder="Confirm password"
                    onChange={this.setPasswordConfirmation}
                    value={passwordConfirmation}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!password || password !== passwordConfirmation}
                >
                  Change Password
                </button>
              </form>
            </div>
          </div>
        </Page>
      </FieldhousePageTitle>
    );
  }
}

ChangePasswordUnwrapped.propTypes = {
  updatePassword: PropTypes.func,
};

ChangePasswordUnwrapped.defaultProps = {
  updatePassword: () => {},
};

const updatePassword = graphql(
  gql`
    mutation UpdatePassword(
      $password: String!
      $passwordConfirmation: String!
    ) {
      updatePassword(
        password: $password
        passwordConfirmation: $passwordConfirmation
      )
    }
  `,
  {
    props: ({ mutate }) => ({
      updatePassword(password, passwordConfirmation) {
        return mutate({
          variables: {
            password,
            passwordConfirmation,
          },
        });
      },
    }),
  },
);

const ChangePassword = updatePassword(ChangePasswordUnwrapped);

export default ChangePassword;
