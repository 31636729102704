import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Paragraph from './Paragraph';

const Size = ({ addChanges, league }) => (
  <div className="container">
    <label htmlFor="numberOfPlayers">
      <Paragraph bold>How many players are there in your league?</Paragraph>
    </label>
    <Paragraph>A rough estimate is okay!</Paragraph>
    <input
      className="form-control form-control-lg"
      id="numberOfPlayers"
      onChange={(event) =>
        addChanges({ numberOfPlayers: Number(event.target.value) })
      }
      type="number"
      value={league.numberOfPlayers || ''}
    />
  </div>
);

Size.propTypes = {
  addChanges: PropTypes.func.isRequired,
  league: LeagueSidePropTypes.league.isRequired,
};

export default Size;
