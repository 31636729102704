import { map, merge } from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlacesAutocomplete from 'react-places-autocomplete';

const DEFAULT_INPUT_PROPS = {
  className:
    'form-control sui-h-6 sui-outline-none sui-text-desktop-4 sui-text-black',
  placeholder: 'Search...',
};

function PlacesAutocomplete({ inputProps, ...rest }) {
  return (
    <ReactPlacesAutocomplete {...rest}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <>
          <input
            {...getInputProps(merge(DEFAULT_INPUT_PROPS, inputProps))}
            className={classNames(
              DEFAULT_INPUT_PROPS.className,
              inputProps.className,
              {
                'sui-border sui-border-red-30': inputProps.error,
              },
            )}
            data-testid={`places-autocomplete-input-${rest.name}`}
          />
          <div className="dropdown">
            <div
              className={classNames('dropdown-menu sui-w-full', {
                show: suggestions.length,
              })}
              data-testid={`places-autocomplete-dropdown-${rest.name}`}
            >
              {map(
                (suggestion) => (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className: classNames('dropdown-item', {
                        active: suggestion.active,
                      }),
                      key: suggestion.description,
                    })}
                    style={{ whiteSpace: 'wrap' }}
                  >
                    {suggestion.description}
                  </div>
                ),
                suggestions,
              )}
            </div>
          </div>
        </>
      )}
    </ReactPlacesAutocomplete>
  );
}

PlacesAutocomplete.propTypes = {
  inputProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

PlacesAutocomplete.defaultProps = {
  inputProps: undefined,
};

export default PlacesAutocomplete;

export function getAddressComponents(geocodedAddress) {
  if (!geocodedAddress) {
    return {};
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { address_components } = geocodedAddress;
  const country =
    address_components?.find((component) => component.types.includes('country'))
      ?.short_name ?? '';
  const route = address_components?.find((component) =>
    component.types.includes('route'),
  )?.short_name;
  const streetNumber = address_components?.find((component) =>
    component.types.includes('street_number'),
  )?.short_name;
  const state = address_components?.find((component) =>
    component.types.includes('administrative_area_level_1'),
  )?.short_name;
  const city = address_components?.find((component) =>
    component.types.includes('locality'),
  )?.short_name;
  const zipcode = address_components?.find((component) =>
    component.types.includes('postal_code'),
  )?.short_name;
  return {
    country,
    route,
    streetNumber,
    state,
    city,
    zipcode,
  };
}
