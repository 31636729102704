import { ReactNode, useEffect } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import { Checkbox, Input, Label, Select } from '@teamsnap/snap-ui';
import type { InputProps } from '@teamsnap/snap-ui/build/components/Input/Input.types';
import PlacesAutocomplete from 'components/PlacesAutocomplete';
import { RemoveIndexSignature } from 'utils/ts/misc';
import cx from 'classnames';
import { useWizardContext } from '../provider';
import {
  CustomResponsesFieldNames,
  Inputs,
  PlayLocationsFieldNames,
  ProgramsFieldNames,
} from '../types';
import CheckboxGroup, { CheckboxGroupProps } from './checkbox-group';
import BooleanQuestionInput from './boolean-question-input';
import s from './styles.module.css';

type SupportedInputNames =
  | keyof Inputs
  | PlayLocationsFieldNames
  | ProgramsFieldNames
  | CustomResponsesFieldNames;

type WizardInputProps = Omit<
  RemoveIndexSignature<InputProps>,
  'type' | 'name' | 'onChange'
> & {
  type:
    | InputProps['type']
    | 'checkbox-group'
    | 'checkbox'
    | 'boolean-question'
    | 'address';
  name: SupportedInputNames;
  rules?: RegisterOptions;
  groupedOptions?: CheckboxGroupProps['groupedOptions'];
  selectAllOption?: boolean;
  triggerAnotherInput?: SupportedInputNames;
  onChange?: InputProps['onChange'] & CheckboxGroupProps['onChange'];
  max?: number;
  onSelectAddressLocation?: (value: string) => void;
  isMulti?: boolean;
  icon?: ReactNode;
};

export default function WizardInput(props: WizardInputProps) {
  const { control, values, setValue, trigger } = useWizardContext();
  const {
    name,
    rules,
    type,
    label,
    helpText,
    options,
    groupedOptions,
    selectAllOption,
    triggerAnotherInput,
    onSelectAddressLocation,
    onChange,
    icon,
  } = props;
  const nameToUse = name as keyof Inputs;

  useEffect(() => {
    // Explicitly set false values to checkbox instead of undefined
    if (values && values[nameToUse]) {
      setValue(nameToUse, values[nameToUse] as string | string[] | boolean);
    } else if (['checkbox', 'boolean-question'].includes(type)) {
      setValue(nameToUse, false);
    }
  }, [values]);

  return (
    <Controller
      name={nameToUse}
      control={control}
      shouldUnregister
      rules={rules}
      render={({ field, fieldState }) => {
        if (type === 'checkbox-group') {
          return (
            <CheckboxGroup
              {...field}
              {...(onChange && { onChange })}
              value={(field.value ?? []) as string[]}
              groupedOptions={groupedOptions ?? []}
              selectAllOption={selectAllOption}
            />
          );
        }
        if (type === 'checkbox') {
          return (
            <Checkbox
              {...field}
              {...props}
              value={field.name}
              extraProps={{ input: { checked: !!field.value ?? false } }}
            />
          );
        }
        if (type === 'boolean-question') {
          return (
            <BooleanQuestionInput
              name={field.name}
              label={label}
              checked={!!field.value}
              onChange={field.onChange}
              helpText={helpText}
            />
          );
        }
        if (type === 'select') {
          return (
            <Select
              {...props}
              {...field}
              options={options ?? []}
              value={field.value as string | string[]}
              required={Boolean(rules?.required)}
              errors={
                !fieldState.error?.message ? [] : [fieldState.error.message]
              }
            />
          );
        }
        if (type === 'address') {
          return (
            <fieldset className={s.fieldset}>
              <Label htmlFor={field.name} required={Boolean(rules?.required)}>
                Address
              </Label>
              <PlacesAutocomplete
                id={field.name}
                name={field.name}
                inputProps={{
                  disabled: false,
                  placeholder: 'Address',
                  error: Boolean(fieldState.error?.message),
                  'data-testid': `input-${field.name}`,
                }}
                onChange={field.onChange}
                onSelect={onSelectAddressLocation}
                value={field.value}
                data-testid={field.name}
                searchOptions={{
                  componentRestrictions: { country: ['us', 'ca'] },
                  fields: ['address_components', 'geometry'],
                  types: ['address'],
                }}
              />
              {fieldState.error?.message && (
                <p className="sui-text-desktop-2 sui-mt-1 sui-text-red-30">
                  {fieldState.error?.message}
                </p>
              )}
            </fieldset>
          );
        }
        if (type === 'phone') {
          return (
            <fieldset className={s.fieldset}>
              <Label htmlFor={field.name} required={Boolean(rules?.required)}>
                {label}
              </Label>
              <div className="sui-flex" style={{ gap: 6 }}>
                <Input
                  type="text"
                  name="countryCode"
                  value="+1"
                  inputProps={{
                    readOnly: true,
                    style: { width: 38 },
                  }}
                />
                <Input
                  {...field}
                  {...props}
                  label=""
                  type="phone"
                  value={field.value as string}
                  className="sui-flex-1"
                  errors={
                    fieldState.error?.message ? [fieldState.error.message] : []
                  }
                />
              </div>
            </fieldset>
          );
        }
        return (
          <fieldset
            className={cx(s.fieldset, {
              [s.withIcon]: Boolean(icon),
            })}
          >
            <Input
              {...field}
              {...props}
              onChange={(ev) => {
                const valueToSet = modifyValuePerProps(ev.target.value, props);
                field.onChange(valueToSet);
                if (triggerAnotherInput && trigger) {
                  trigger(triggerAnotherInput);
                }
              }}
              inputProps={{
                min: new Date().toISOString().split('T')[0],
                onKeyDown: (e: any) => {
                  if (type === 'number') {
                    if (['e', 'E', '+', '-'].includes(e.key)) {
                      e.preventDefault();
                    }
                  }
                },
              }}
              value={(field.value ?? '') as string}
              type={type}
              errors={
                fieldState.error?.message ? [fieldState.error.message] : []
              }
              errorState={!!fieldState.error?.message}
              required={!!rules?.required}
            />
            {icon && <span className={s.icon}>{icon}</span>}
          </fieldset>
        );
      }}
    />
  );
}

const DEFAULT_MAX_FOR_NUMBERS = 999999;
function modifyValuePerProps(value: string, props: WizardInputProps) {
  if (props.type === 'text') {
    // Only allow english characters and numbers
    return value.replace(/[^a-zA-Z0-9 ]/g, '');
  }
  if (props.type === 'email') {
    // Only allow english characters and numbers and @, ., _, -,
    return value.replace(/[^a-zA-Z0-9@._-]/g, '');
  }
  if (props.type === 'number') {
    /*
      Validations:
      - Only allow numbers less than max
      - Only allow numbers greater than 0 
      - Remove leading zero
    */
    const maxValue = props.max ?? DEFAULT_MAX_FOR_NUMBERS;
    let valueToSet = Number(value) > maxValue ? maxValue : value;
    valueToSet = Number(value) < 0 ? 1 : valueToSet;
    return String(valueToSet).replace(/^0+/, '');
  }
  return value;
}
