import ContactUsLink from 'components/ContactUsLink';
import {
  __,
  always,
  dec,
  evolve,
  inc,
  keys,
  length,
  merge,
  pipe,
  prop,
  propOr,
} from 'ramda';
import React from 'react';
import numeral from 'numeral';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Icon from 'components/Icon';
import Loader from 'components/Loader';
import Modal, { ModalBody } from 'components/Modal';
import ProgressBar from 'components/ProgressBar';
import BrandBar from 'components/BrandBar';
import SingleCardPage from 'components/SingleCardPage';
import jwtDecode from 'jwt-decode';
import retryPromise from 'utils/retryPromise';
import styled from 'styled-components';
import STEPS from './leagueSelfOnboarding/STEPS';

const UNSAVED_MESSAGE =
  "It looks like you made some changes that haven't been saved yet. Are you sure you want to leave this page?";

const decrementCurrentStepIndex = evolve({ currentStepIndex: dec });
const incrementCurrentStepIndex = evolve({ currentStepIndex: inc });
const formatPercent = (value) => numeral(value).format('0%');
const hasChanges = pipe(prop('changes'), keys, length);
const mergeInNewChanges = (newChanges) =>
  evolve({ changes: merge(__, newChanges) });

const Footer = styled.footer`
  background-color: #fff;
  border-top: 1px solid var(--success);
  position: fixed;
  bottom: 0;
  width: 100%;
`;

const FooterRow = styled.div`
  flex-direction: row-reverse;
  height: 4.7rem;
`;

const Content = styled.div`
  padding-bottom: 8rem;
  padding-top: 8rem;
`;

const errorPage = (
  <SingleCardPage>
    <div className="heading" id="error">
      Profile Not Found
    </div>
    <p>We couldn&#39;t find your organization&#39;s profile at this link.</p>
    <p>
      There may be an issue with your Internet connection or this link may have
      expired.
    </p>
    <p>
      Please try refreshing the page. If the error continues, please&nbsp;
      <ContactUsLink />.
    </p>
  </SingleCardPage>
);

export class LeagueSelfOnboardingUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = { changes: {}, currentStepIndex: 0 };
    this.addChanges = pipe(mergeInNewChanges, this.setState.bind(this));
    this.goToPreviousStep = this.goToPreviousStep.bind(this);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  goToPreviousStep() {
    const { props } = this;
    this.setState(decrementCurrentStepIndex, props.highlightFirstInput);
    this.updateIfThereAreChanges();
  }

  handleBeforeUnload(event) {
    if (!hasChanges(this.state)) return undefined;
    /* eslint-disable-next-line no-param-reassign */
    event.returnValue = UNSAVED_MESSAGE;
    return event.returnValue;
  }

  handleSubmit(event) {
    const { props } = this;

    event.preventDefault();
    this.setState(incrementCurrentStepIndex, props.highlightFirstInput);
    this.updateIfThereAreChanges();
  }

  async updateIfThereAreChanges() {
    const { props, state } = this;

    if (!hasChanges(state)) return;

    try {
      await retryPromise(() => props.update(state.changes));
    } catch (e) {
      props.$('#updateError').modal('show');
    }

    this.setState({ changes: {} });
  }

  render() {
    const { props } = this;

    const savedLeague = props.league;

    if (!savedLeague) return <Loader />;

    const { addChanges } = this;
    const { currentStepIndex, changes } = this.state;
    const { competitivenesses, sports, steps } = this.props;
    const numberOfStepsToComplete = steps.length - 2;
    const numberOfStepsCompleted = Math.max(currentStepIndex - 1, 0);
    const percentComplete = numberOfStepsCompleted / numberOfStepsToComplete;
    const currentStep = steps[currentStepIndex];
    const getValidationError = propOr(
      always(null),
      'getValidationError',
      currentStep,
    );
    const notFirstStep = currentStepIndex > 0;
    const notLastStep = currentStepIndex < steps.length - 1;
    const league = merge(savedLeague, changes);
    const validationError = getValidationError({ league });
    const stepProps = {
      addChanges,
      competitivenesses,
      league,
      savedLeague,
      sports,
    };

    return (
      <form id="form" onSubmit={this.handleSubmit}>
        <Modal canClose={false} id="updateError" title="Uh oh, we hit a snag!">
          <ModalBody>
            <p>
              Unfortunately we had a little trouble saving some of your data.
            </p>
            <p>
              {'Please '}
              <ContactUsLink />
              {
                ' so we can finish filling out your profile and you can start receiving sponsorship offers.'
              }
            </p>
          </ModalBody>
        </Modal>
        <BrandBar brandHref="#" />
        <Content id="currentStep">
          {React.createElement(currentStep, stepProps)}
        </Content>
        <Footer>
          <div className="container">
            <FooterRow className="row align-items-center">
              <div className="col text-right">
                {notLastStep && (
                  <button
                    className="btn btn-primary btn-lg"
                    disabled={!!validationError}
                    id="next"
                    title={validationError || ''}
                    type="submit"
                  >
                    Next
                    <Icon className="ml-1" value="arrow-right" />
                  </button>
                )}
              </div>
              <div className="col text-center">
                <ProgressBar
                  value={numberOfStepsCompleted}
                  maximum={numberOfStepsToComplete}
                />
                {formatPercent(percentComplete)}
                <span className="d-none d-sm-inline-block ml-1">Complete</span>
              </div>
              <div className="col">
                {notFirstStep && (
                  <button
                    className="btn btn-link"
                    id="previous"
                    onClick={this.goToPreviousStep}
                    type="button"
                  >
                    <Icon className="mr-1" value="arrow-left" />
                    Back
                  </button>
                )}
              </div>
            </FooterRow>
          </div>
        </Footer>
      </form>
    );
  }
}

LeagueSelfOnboardingUnwrapped.propTypes = {
  $: PropTypes.func,
  competitivenesses: PropTypes.arrayOf(LeagueSidePropTypes.competitiveness),
  highlightFirstInput: PropTypes.func,
  league: LeagueSidePropTypes.league,
  sports: PropTypes.arrayOf(LeagueSidePropTypes.sport),
  steps: PropTypes.arrayOf(PropTypes.func),
  update: PropTypes.func.isRequired,
};

LeagueSelfOnboardingUnwrapped.defaultProps = {
  $: global.$,
  competitivenesses: [],
  highlightFirstInput: () => global.$('form input').first().focus(),
  league: null,
  sports: [],
  steps: STEPS,
};

const LEAGUE_SELF_ONBOARDING_QUERY = gql`
  query LeagueSelfOnboarding($id: Int!) {
    competitivenesses {
      id
      name
    }
    sports {
      id
      name
    }
    league(id: $id) {
      id
      competitivenessIds
      contactEmail
      contactPhone
      fallGames
      female
      male
      maxAge
      minAge
      name
      numberOfPlayers
      playLocation {
        id
        address
      }
      sportIds
      springGames
      summerGames
      winterGames
    }
  }
`;

const UPDATE_LEAGUE_SELF_ONBOARDING_MUTATION = gql`
  mutation UpdateLeagueSelfOnboarding($id: Int!, $input: LeagueInput!) {
    updateLeague(id: $id, input: $input) {
      id
      competitivenessIds
      contactEmail
      contactPhone
      fallGames
      female
      male
      maxAge
      minAge
      name
      numberOfPlayers
      playLocation {
        id
        address
      }
      sportIds
      springGames
      summerGames
      winterGames
    }
  }
`;

function LeagueSelfOnboarding({ match }) {
  const { token } = match.params;
  const context = { headers: { Authorization: `Bearer ${token}` } };
  const { ctx, id } = jwtDecode(token);

  if (ctx !== 'league') return errorPage;

  return (
    <Query
      context={context}
      fetchPolicy="network-only"
      query={LEAGUE_SELF_ONBOARDING_QUERY}
      variables={{ id }}
    >
      {({ data, error }) => {
        if (error) return errorPage;

        return (
          <Mutation
            context={context}
            mutation={UPDATE_LEAGUE_SELF_ONBOARDING_MUTATION}
          >
            {(mutate) => (
              <LeagueSelfOnboardingUnwrapped
                competitivenesses={data && data.competitivenesses}
                sports={data && data.sports}
                league={data && data.league}
                update={(input) => mutate({ variables: { id, input } })}
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

LeagueSelfOnboarding.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

export default LeagueSelfOnboarding;
