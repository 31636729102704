import React from 'react';
import { prop, take } from 'ramda';
import styled from 'styled-components';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { mapWithIndex } from 'utils/array';
import Lightbox from 'components/Lightbox';
import { addCloudinaryAutoFormat } from 'utils/image';
import OfferDataPoints from './OfferDataPoints';

const MAX_THUMBNAILS = 3;

const Thumbnail = styled.div`
  max-height: 120px;
  overflow: hidden;
`;

const imageTitle = (sponsorablePropertyName, capabilityName) => {
  const capability = capabilityName ? ` - ${capabilityName}` : '';
  return `${sponsorablePropertyName}${capability}`;
};

const toUrl = prop('fullSizeUrl');
const getVerificationImages = (offer) => {
  const { verificationImages } = offer;
  verificationImages.sort(
    (image, otherImage) => otherImage.featured - image.featured,
  );
  return verificationImages.map(toUrl);
};

function OfferListItem({ offer }) {
  const { sponsorableProperty, verificationImages } = offer;
  const { name: sponsorablePropertyName } = sponsorableProperty;

  const images = getVerificationImages(offer);
  const imageProps = (index) => ({
    imageCaption: `${index + 1} of ${images.length}`,
    imageTitle: imageTitle(
      sponsorablePropertyName,
      verificationImages[index].capabilityName,
    ),
  });

  return (
    <div className="list-group-item" id={`offer-${offer.id}`}>
      <h6 className="text-truncate">{sponsorablePropertyName}</h6>
      <OfferDataPoints offer={offer} />
      {!!verificationImages.length && (
        <div className="row mt-3">
          <Lightbox images={images} imageProps={imageProps}>
            {(open) => {
              const renderThumbnail = (verificationImage, photoIndex) => (
                <Thumbnail key={verificationImage.id} className="col-4">
                  <button
                    className="btn btn-link"
                    onClick={() => open({ photoIndex })}
                    type="button"
                  >
                    <img
                      alt={imageTitle(
                        sponsorablePropertyName,
                        verificationImage.capabilityName,
                      )}
                      className="w-100"
                      src={addCloudinaryAutoFormat(
                        verificationImage.thumbnailUrl,
                      )}
                    />
                  </button>
                </Thumbnail>
              );

              return mapWithIndex(
                renderThumbnail,
                take(MAX_THUMBNAILS, verificationImages),
              );
            }}
          </Lightbox>
        </div>
      )}
    </div>
  );
}

OfferListItem.propTypes = {
  offer: LeagueSidePropTypes.offer.isRequired,
};

export default OfferListItem;
