import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { surveyGizmoUrl } from 'utils/urls';
import NewWindowLink from 'components/NewWindowLink';

const EventsSurveyResponseLink = ({ eventsSurveyResponse, label, small }) => {
  const url = surveyGizmoUrl(eventsSurveyResponse.surveyGizmoResponseId);

  const externalLink = (
    <NewWindowLink to={url} title="Visit SurveyGizmo">
      {label}
    </NewWindowLink>
  );

  return small ? <small>{externalLink}</small> : externalLink;
};

EventsSurveyResponseLink.propTypes = {
  eventsSurveyResponse: LeagueSidePropTypes.eventsSurveyResponse.isRequired,
  label: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

EventsSurveyResponseLink.defaultProps = {
  small: false,
};

export default EventsSurveyResponseLink;
