import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import copyToClipboard from 'utils/copyToClipboard';
import Icon from 'components/Icon';

const initialState = { copied: false };

class CopyButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.resetState = () => this.setState(initialState);
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  copyToClipboard() {
    const { content } = this.props;

    copyToClipboard(content);

    this.setState({
      copied: true,
    });

    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(this.resetState, 1000);
  }

  render() {
    const { copied } = this.state;
    const { disabled, label, large, width } = this.props;

    return (
      <button
        className={classNames(`btn btn-${copied ? 'success' : 'primary'}`, {
          'btn-lg': large,
          [`w-${width}`]: width,
        })}
        disabled={disabled}
        onClick={this.copyToClipboard}
        type="button"
      >
        <Icon className="mr-2" value={copied ? 'check' : 'documents'} />
        {copied ? 'Copied' : `Copy ${label}`}
      </button>
    );
  }
}

CopyButton.propTypes = {
  content: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  large: PropTypes.bool,
  width: PropTypes.number,
};

CopyButton.defaultProps = {
  disabled: false,
  label: 'Link',
  large: false,
  width: null,
};

export default CopyButton;
