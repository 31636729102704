import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Select } from '@teamsnap/snap-ui';
import WithSaveFooter from 'components/WithSaveFooter';
import { sortByName } from 'utils/sort';
import { Campaign } from 'types/campaign';

type PrimaryContactsTabProps = {
  campaign: Campaign;
};

export default function PrimaryContactsTab(props: PrimaryContactsTabProps) {
  const { campaign } = props;
  const { data } = useQuery<EMPLOYEE_QUERY_RESPONSE>(EMPLOYEE_QUERY, {
    variables: { employee: true },
  });
  const [performMutation] = useMutation(MUTATION);

  const onSave = (input: {
    primarySalesContactId?: number;
    primaryStrategistContactId?: number;
    primaryBdrContactId?: number;
  }) => {
    performMutation({ variables: { input: { ...input, id: campaign.id } } });
  };

  const employeeOptions = useMemo(
    () =>
      data?.users
        ? sortByName(data.users).map((employee) => ({
            label: employee.name,
            value: String(employee.id),
          }))
        : [],
    [data],
  );

  return (
    <WithSaveFooter handleMutation={onSave} savedValue={campaign}>
      {({ addChanges, value: campaignValue }) => (
        <form className="list-group list-group-flush" id="update-campaign">
          <div className="list-group-item">
            <Select
              // @ts-ignore
              // id="sales-contact"
              label="Sales Contact"
              name="sales-contact"
              onChange={(value) =>
                addChanges({ primarySalesContactId: Number(value) })
              }
              options={employeeOptions}
              value={campaignValue.primarySalesContactId || ''}
              data-testid="sales-contact"
            />
          </div>
          <div className="list-group-item">
            <Select
              label="Strategist Contact"
              name="strategist-contact"
              options={employeeOptions}
              onChange={(value) =>
                addChanges({ primaryStrategistContactId: Number(value) })
              }
              value={campaignValue.primaryStrategistContactId || ''}
            />
          </div>
          <div className="list-group-item">
            <Select
              label="BDR Contact"
              name="bdr-contact"
              onChange={(value) =>
                addChanges({ primaryBdrContactId: Number(value) })
              }
              options={employeeOptions}
              value={campaignValue.primaryBdrContactId || ''}
            />
          </div>
        </form>
      )}
    </WithSaveFooter>
  );
}

const EMPLOYEE_QUERY = gql`
  query GetEmployees($employee: Boolean) {
    users(employee: $employee) {
      id
      name
    }
  }
`;
type EMPLOYEE_QUERY_RESPONSE = {
  users: {
    id: number;
    name: string;
  }[];
};

const MUTATION = gql`
  mutation UpdateCampaign($input: CampaignInput!) {
    updateCampaign(input: $input) {
      id
      primarySalesContactId
      primaryStrategistContactId
      primaryBdrContactId
    }
  }
`;
