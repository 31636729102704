import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatNumber } from 'utils/formatNumber';
import { ageRange } from 'utils/sponsorableProperty';
import { listAssets } from 'utils/asset';
import Icon from 'components/Icon';

const ListItem = ({ predicate, iconValue, children }) =>
  predicate && (
    <li className="mb-1 mr-3">
      <div className="d-flex">
        <Icon className="text-muted mr-2" value={iconValue} />
        {children}
      </div>
    </li>
  );

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  iconValue: PropTypes.string.isRequired,
  predicate: PropTypes.bool.isRequired,
};

const OfferDataPoints = ({ offer }) => {
  const { assets, sponsorableProperty } = offer;
  const { numberOfPlayers, sport } = sponsorableProperty;

  return (
    <ul className="list-unstyled mb-0 d-flex align-items-center flex-wrap">
      <ListItem predicate={!!numberOfPlayers} iconValue="man">
        {`${formatNumber(numberOfPlayers)} players`}
      </ListItem>
      <ListItem
        predicate={!!sponsorableProperty.minAge && !!sponsorableProperty.maxAge}
        iconValue="users"
      >
        {`Age ${ageRange(sponsorableProperty)}`}
      </ListItem>
      <ListItem predicate={!!sport} iconValue="trophy">
        {sport && sport.name}
      </ListItem>
      <ListItem predicate={!!assets.length} iconValue="images">
        {listAssets(assets)}
      </ListItem>
    </ul>
  );
};

OfferDataPoints.propTypes = {
  offer: LeagueSidePropTypes.offer.isRequired,
};

export default OfferDataPoints;
