import * as React from 'react';
import { RequiredAsterisk } from 'components/RequiredLabel';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  name: string;
  setName: (name: string) => void;
}

const PackageNameInput: React.FC<Props> = ({
  disabled,
  name,
  setName,
}: Props) => (
  <div className="form-group">
    <label htmlFor="package-name">
      Package Name
      <RequiredAsterisk />
    </label>
    <input
      aria-labelledby="package-name"
      aria-required="true"
      className="form-control"
      disabled={disabled}
      id="package-name"
      onChange={({ target: { value } }) => setName(value)}
      placeholder="Ex. Bronze, Silver, Gold"
      required
      type="text"
      value={name}
    />
  </div>
);

PackageNameInput.defaultProps = {
  disabled: false,
};

export default PackageNameInput;
