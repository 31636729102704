import * as React from 'react';
import { getInputId } from 'utils/id';

interface Props {
  disabled?: boolean;
  firstOptionTrue?: boolean;
  id: string;
  indent?: boolean;
  option1: JSX.Element | string;
  option2: JSX.Element | string;
  prefix?: string;
  setValue(value: boolean): void;
  value: boolean;
}

const BooleanRadioInputs: React.FunctionComponent<Props> = ({
  disabled,
  firstOptionTrue,
  id,
  indent,
  option1,
  option2,
  prefix,
  setValue,
  value,
}: Props) => {
  const id1 = getInputId([prefix, id, firstOptionTrue ? 'true' : 'false']);
  const id2 = getInputId([prefix, id, firstOptionTrue ? 'false' : 'true']);

  return (
    <div className={`ml-${indent ? 3 : 1} my-2`}>
      <div className="form-check">
        <input
          checked={value === !!firstOptionTrue}
          className="form-check-input"
          id={id1}
          onClick={() => setValue(!!firstOptionTrue)}
          type="radio"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor={id1}>
          {option1}
        </label>
      </div>
      <div className="form-check mt-1">
        <input
          checked={value === !firstOptionTrue}
          className="form-check-input"
          id={id2}
          onClick={() => setValue(!firstOptionTrue)}
          type="radio"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor={id2}>
          {option2}
        </label>
      </div>
    </div>
  );
};

BooleanRadioInputs.defaultProps = {
  disabled: false,
  firstOptionTrue: false,
  indent: false,
  prefix: undefined,
};

export default BooleanRadioInputs;
