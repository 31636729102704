import React from 'react';
import { Query } from 'react-apollo';
import { concat, isEmpty } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { getUserIdFrom } from 'utils/authentication';
import Card from 'components/Card';
import Page from 'components/Page';
import {
  LEAGUE_ADDRESSES_OFFER_FRAGMENT,
  ORGANIZATION_ADDRESSES_MAILING_AND_SHIPPING_FRAGMENT,
} from 'components/organizationDashboardWrapper/LeagueAddressesFragment';
import OrganizationDashboardWrapper from 'components/OrganizationDashboardWrapper';
import MissingAddressAlert from 'components/MissingAddressAlert';
import OrganizationOffersTable from 'pages/organizationDashboardMain/OrganizationOffersTable';

const emptyMessage = (
  <p className="text-center m-4" data-test="empty-message">
    This league doesn&#39;t have any active sponsorships right now. Keep an eye
    on your inbox for sponsorship offers!
  </p>
);

export const OrganizationDashboardMainUnwrapped = ({
  selectedOrganization,
}) => {
  const { confirmedOffers, proposedOffers } = selectedOrganization;
  const offers = concat(confirmedOffers, proposedOffers);

  return (
    <>
      <MissingAddressAlert
        organization={selectedOrganization}
        offers={confirmedOffers}
      />
      <Card verticalMargins={0}>
        {isEmpty(offers) ? (
          emptyMessage
        ) : (
          <OrganizationOffersTable offers={offers} />
        )}
      </Card>
    </>
  );
};

OrganizationDashboardMainUnwrapped.propTypes = {
  selectedOrganization: LeagueSidePropTypes.organization.isRequired,
};

const QUERY = gql`
  query OrganizationDashboard($userId: Int) {
    myOrganizations(userId: $userId) {
      id
      name
      confirmedOffers: offers(
        statuses: [confirmed]
        campaignStatuses: [active, complete, proposed]
      ) {
        id
        campaign {
          id
          season
          sponsor {
            id
            name
          }
          status
          year
        }
        status
        ...LeagueAddressesOfferFragment
      }
      proposedOffers: offers(
        statuses: [proposed]
        campaignStatuses: [active, proposed]
      ) {
        id
        campaign {
          id
          season
          sponsor {
            id
            name
          }
          status
          year
        }
        status
      }
      ...OrganizationAddressesMailingAndShippingFragment
    }
  }
  ${LEAGUE_ADDRESSES_OFFER_FRAGMENT}
  ${ORGANIZATION_ADDRESSES_MAILING_AND_SHIPPING_FRAGMENT}
`;

const OrganizationDashboardMain = ({ location }) => (
  <Query
    fetchPolicy="network-only"
    query={QUERY}
    variables={{ userId: getUserIdFrom(location.search) }}
  >
    {({ data, error }) => {
      if (error) throw error;

      const organizations = data && data.myOrganizations;

      return organizations ? (
        <OrganizationDashboardWrapper organizations={organizations} main>
          {({ selectedOrganization }) => (
            <OrganizationDashboardMainUnwrapped
              selectedOrganization={selectedOrganization}
            />
          )}
        </OrganizationDashboardWrapper>
      ) : (
        <Page loaded={false} />
      );
    }}
  </Query>
);

OrganizationDashboardMain.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrganizationDashboardMain;
