import gql from 'graphql-tag';

const CAMPAIGN_STATISTIC_FRAGMENT = gql`
  fragment CampaignStatisticFragment on CampaignStatistic {
    id
    description
    iconName
    name
    number
  }
`;

export default CAMPAIGN_STATISTIC_FRAGMENT;
