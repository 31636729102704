import React from 'react';
import { Mutation } from 'react-apollo';
import { any, filter, flatten, isEmpty, length, map, pipe, pluck } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { pluckId } from 'utils/misc';
import { sortByLeagueName } from 'utils/offer';
import { OFFER_PREFIX } from 'utils/paths';
import { loadingTense, pluralizedTense } from 'utils/string';
import {
  displayCreativeNotificationAlertByProp,
  hasMatchingNameAndCapability,
} from 'utils/verificationDeadlines';
import Alert from 'components/Alert';
import ModalForm from 'components/ModalForm';
import NewWindowLink from 'components/NewWindowLink';

const getAllValueLength = (value) => pipe(pluck(value), flatten, length);
const getCreativeContentsLength = getAllValueLength('creativeContents');
const getCreativeFilesLength = getAllValueLength('creativeFiles');
const getCreativesLength = (matchingVerificationDeadlines) => {
  const creativeContentsLength = getCreativeContentsLength(
    matchingVerificationDeadlines,
  );
  const creativeFilesLength = getCreativeFilesLength(
    matchingVerificationDeadlines,
  );

  return creativeContentsLength + creativeFilesLength;
};

const filterMatchingDeadlines = (deadlineName, capabilityName) =>
  filter(hasMatchingNameAndCapability(deadlineName, capabilityName));

const renderOfferLink = (offer, additionalText = null) => {
  const {
    id: offerId,
    league: { name: leagueName },
  } = offer;

  return (
    <li key={offerId}>
      <NewWindowLink hideIcon to={`${OFFER_PREFIX}/${offerId}`}>
        {leagueName}
      </NewWindowLink>
      {additionalText}
    </li>
  );
};

const displayCreativeNotificationAlert =
  displayCreativeNotificationAlertByProp('requiresCreatives');
const doesAnyDeadlinesRequireCreatives = (verificationDeadlines) =>
  any(displayCreativeNotificationAlert, verificationDeadlines);

export const ReplaceCreativesModalUnwrapped = ({
  capabilityName,
  defaultVerificationDeadline,
  offersWithMatchingDeadline,
  replaceCreatives,
}) => {
  const { name: defaultVerificationDeadlineName } = defaultVerificationDeadline;

  const { error, loading, handleSubmit } = useSubmit(replaceCreatives);

  const hasNoMatchingDeadlines = isEmpty(offersWithMatchingDeadline);
  const filterByMatch = filterMatchingDeadlines(
    defaultVerificationDeadlineName,
    capabilityName,
  );

  const renderListedOffer = (offer) => {
    const matchingVerificationDeadlines = filterByMatch(
      offer.verificationDeadlines,
    );
    const creativesLength = getCreativesLength(matchingVerificationDeadlines);
    const additionalText =
      !!creativesLength &&
      ` (${creativesLength} existing ${pluralizedTense(
        creativesLength,
        'creative',
      )} will be deleted)`;

    return renderOfferLink(offer, additionalText);
  };

  const allMatchingVerificationDeadlines = flatten(
    offersWithMatchingDeadline.map((offer) =>
      filterByMatch(offer.verificationDeadlines),
    ),
  );

  return (
    <ModalForm
      id={`${defaultVerificationDeadline.id}-replace-creatives-modal`}
      error={error}
      loading={loading}
      onSubmit={hasNoMatchingDeadlines ? null : handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Replace Creatives'),
        className: 'btn btn-danger',
        disabled: hasNoMatchingDeadlines,
      }}
      toggleProps={{
        children: 'Replace creatives on offers',
        className: 'btn btn-link py-1',
        disabled: hasNoMatchingDeadlines,
        title: hasNoMatchingDeadlines
          ? 'No offers with this deadline exist'
          : null,
      }}
      title="Replace Creatives?"
    >
      {hasNoMatchingDeadlines ? (
        <Alert alertStyle="danger" className="my-0">
          No offers with this deadline exist so there are no creatives to
          replace.
        </Alert>
      ) : (
        <>
          {doesAnyDeadlinesRequireCreatives(
            allMatchingVerificationDeadlines,
          ) && (
            <Alert alertStyle="warning">
              Replacing the creative will email the majority of leagues with
              this deadline at the end of the day. If you dont want this to
              happen, please contact dev@leagueside.com for a workaround.
            </Alert>
          )}
          The following leagues have a matching verification deadline named
          &quot;
          <strong>{defaultVerificationDeadlineName}</strong>
          &quot;:
          <ul>
            {map(
              renderListedOffer,
              sortByLeagueName(offersWithMatchingDeadline),
            )}
          </ul>
          <Alert alertStyle="danger" className="mt-3 mb-0">
            {`Are you sure you want to replace all creatives? This will delete and replace the creatives for the deadline named "${defaultVerificationDeadlineName}" on each offer.`}
            <br />
            <br />
            We recommend reviewing each offer above before proceeding.
          </Alert>
        </>
      )}
    </ModalForm>
  );
};

ReplaceCreativesModalUnwrapped.propTypes = {
  capabilityName: PropTypes.string.isRequired,
  defaultVerificationDeadline: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  offersWithMatchingDeadline: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      league: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      verificationDeadlines: PropTypes.arrayOf(
        PropTypes.shape({
          capabilityName: PropTypes.string.isRequired,
          creativeContents: PropTypes.arrayOf(PropTypes.shape).isRequired,
          creativeFiles: PropTypes.arrayOf(PropTypes.shape).isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  replaceCreatives: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation ReplaceCreatives($input: ReplaceCreativesInput!) {
    replaceCreatives(input: $input) {
      confirmedOffers {
        id
        verificationDeadlines {
          id
          creativeContents {
            id
          }
          creativeFiles {
            id
          }
        }
      }
    }
  }
`;

const ReplaceCreativesModal = ({
  capabilityName,
  defaultVerificationDeadline,
  offersWithMatchingDeadline,
}) => {
  const input = {
    defaultVerificationDeadlineId: defaultVerificationDeadline.id,
    offerIds: pluckId(offersWithMatchingDeadline),
  };

  return (
    <Mutation mutation={MUTATION} variables={{ input }}>
      {(mutate) => (
        <ReplaceCreativesModalUnwrapped
          capabilityName={capabilityName}
          defaultVerificationDeadline={defaultVerificationDeadline}
          offersWithMatchingDeadline={offersWithMatchingDeadline}
          replaceCreatives={mutate}
        />
      )}
    </Mutation>
  );
};

ReplaceCreativesModal.propTypes = {
  capabilityName: PropTypes.string.isRequired,
  defaultVerificationDeadline: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  offersWithMatchingDeadline: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      league: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      verificationDeadlines: PropTypes.arrayOf(
        PropTypes.shape({
          capabilityName: PropTypes.string.isRequired,
          creativeContents: PropTypes.arrayOf(PropTypes.shape).isRequired,
          creativeFiles: PropTypes.arrayOf(PropTypes.shape).isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default ReplaceCreativesModal;
