import { prop, T } from 'ramda';
import { createReducer } from 'redux-create-reducer';
import { combineReducers } from 'redux';
import LeaguesideAPI from 'utils/leagueside-api';

const LOAD_SUCCESS = 'locationLists/loadSuccess';
const UPLOAD_SUCCESS = 'locationLists/uploadSuccess';

export const loadSuccess = (payload) => ({ type: LOAD_SUCCESS, payload });

export const uploadSuccess = (payload) => ({ type: UPLOAD_SUCCESS, payload });

export const createLocationFilter =
  (locationList, campaignId, filterId) => async (dispatch) => {
    const parameters = { locationListId: locationList.id, radius: 15 };
    const filter = { type: 'LocationFilter', parameters };
    const searchFilter = await LeaguesideAPI.createSearchFilter(
      campaignId,
      filter,
    );
    dispatch(
      uploadSuccess({
        locationList,
        searchFilter,
        temporaryId: filterId,
      }),
    );
  };

function createDictionaryById(payload) {
  return payload.reduce(
    (byId, record) => ({ ...byId, [record.id]: record }),
    {},
  );
}

export const byId = createReducer(
  {},
  {
    [LOAD_SUCCESS]: (state, { payload }) => createDictionaryById(payload),
    [UPLOAD_SUCCESS]: (state, { payload }) => ({
      ...state,
      [payload.locationList.id]: payload.locationList,
    }),
  },
);

export const loaded = createReducer(false, { [LOAD_SUCCESS]: T });

export const getLoaded = prop('loaded');

export function getLocationList(locationListId, locationLists) {
  return locationLists.byId[locationListId];
}

export function getLocationCount(locationListId, locationLists) {
  const list = getLocationList(locationListId, locationLists);

  return list ? list.locations.length : 0;
}

export default combineReducers({
  byId,
  loaded,
});
