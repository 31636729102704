import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StringInput from 'components/formInputs/StringInput';
import LoginMessage from 'components/LoginMessage';
import ModalSpinner from 'components/ModalSpinner';
import RequiredLabel from 'components/RequiredLabel';

class InvitationForm extends Component {
  constructor() {
    super();

    this.state = {};

    this.handleAcceptInvitation = this.handleAcceptInvitation.bind(this);
    this.handlePasswordChange = (e) =>
      this.setState({ password: e.target.value });
    this.handlePasswordConfirmationChange = (e) =>
      this.setState({ passwordConfirmation: e.target.value });
    this.handleNameChange = (e) => this.setState({ name: e.target.value });
    this.handleTitleChange = (e) => this.setState({ title: e.target.value });
    this.handleCellPhoneChange = (e) =>
      this.setState({ cellPhone: e.target.value });
  }

  handleAcceptInvitation(e) {
    const { props, state } = this;
    const { password, passwordConfirmation, name, title, cellPhone } = state;
    props.onAcceptInvitation(
      password,
      passwordConfirmation,
      name,
      title,
      cellPhone,
    );
    e.preventDefault();
  }

  emailFieldContent() {
    const { invitation, isWorking } = this.props;

    if (invitation) {
      return invitation.email;
    }

    if (isWorking) {
      return 'Loading your invitation...';
    }

    return 'Invitation not found';
  }

  render() {
    const { invitation, isWorking, message } = this.props;
    const { name, password, passwordConfirmation, title, cellPhone } =
      this.state;
    const disabled = isWorking || !invitation;
    const labelClassName = 'mb-0';

    return (
      <>
        <ModalSpinner show={isWorking} />
        <h1 className="mb-4">Create your TeamSnap Sponsorship account</h1>
        <LoginMessage message={message} />
        <form className="text-left" onSubmit={this.handleAcceptInvitation}>
          <StringInput
            disabled
            helpBlock="This email address was provided to us when your account was created."
            inputSize="lg"
            label="Email Address"
            labelClassName={labelClassName}
            type="text"
            value={this.emailFieldContent()}
          />
          <StringInput
            disabled={disabled}
            inputSize="lg"
            label="Full Name"
            labelClassName={labelClassName}
            onChange={this.handleNameChange}
            required
            type="text"
            value={name || ''}
          />
          <StringInput
            disabled={disabled}
            inputSize="lg"
            label="Title"
            labelClassName={labelClassName}
            onChange={this.handleTitleChange}
            type="text"
            value={title || ''}
          />
          <StringInput
            disabled={disabled}
            inputSize="lg"
            label="Cell Phone"
            labelClassName={labelClassName}
            onChange={this.handleCellPhoneChange}
            type="tel"
            value={cellPhone || ''}
            pattern="([0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10})"
          />
          <StringInput
            disabled={disabled}
            helpBlock="Your password must be more than 8 characters and contain an uppercase and lowercase letter, a number and a symbol."
            inputSize="lg"
            label="Password"
            labelClassName={labelClassName}
            onChange={this.handlePasswordChange}
            required
            type="password"
            value={password || ''}
          />
          <StringInput
            disabled={disabled}
            helpBlock="Just so we are sure we have it right, enter your password again."
            inputSize="lg"
            label="Confirm Password"
            labelClassName={labelClassName}
            onChange={this.handlePasswordConfirmationChange}
            required
            type="password"
            value={passwordConfirmation || ''}
          />
          <p className="sui-text-desktop-4 sui-mt-4 sui-mb-3">
            By entering in your email and phone number, you hereby consent to
            receive calls, emails and text messages from TeamSnap, in accordance
            with our privacy policy and terms of service that can be found at{' '}
            <a
              href="https://www.teamsnap.com/"
              target="_blank"
              rel="noreferrer"
            >
              teamsnap.com
            </a>
            . Message and data rates may apply. Message frequency varies.
          </p>

          <p className="text-center">
            <input
              type="submit"
              value="Create My Account"
              id="login-submit"
              className="btn btn-primary sui-px-3 sui-py-2"
              disabled={disabled}
            />
          </p>
          <span className="float-right">
            <RequiredLabel />
          </span>
        </form>
      </>
    );
  }
}

InvitationForm.propTypes = {
  isWorking: PropTypes.bool,
  invitation: PropTypes.shape({
    email: PropTypes.string,
  }),
  message: PropTypes.string,
  onAcceptInvitation: PropTypes.func.isRequired,
};

InvitationForm.defaultProps = {
  isWorking: false,
  invitation: null,
  message: null,
};

export default InvitationForm;
