import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { map, mergeAll } from 'ramda';
import gql from 'graphql-tag';
import moment from 'moment';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatDateInput, TWO_YEARS_AFTER } from 'utils/formatDate';
import { useSubmit } from 'utils/hooks';
import { getId } from 'utils/id';
import { findById } from 'utils/misc';
import { capitalize, conjugateString } from 'utils/string';
import { seasons } from 'types/season';
import ModalForm from 'components/ModalForm';
import NameInput from 'components/formInputs/NameInput';
import SelectInput from 'components/formInputs/SelectInput';
import Icon from 'components/Icon';
import DateRangePickerWrapper from 'components/DateRangePickerWrapper';
import MultiSelect from 'components/MultiSelect';
import { RequiredAsterisk } from 'components/RequiredLabel';
import SPONSORABLE_PROPERTY_FRAGMENT from './SponsorablePropertyFragment';

const COMPETITIVENESSES_AND_SPORTS_QUERY = gql`
  query CompetitivenessesAndSports {
    competitivenesses {
      id
      name
    }
    sports {
      id
      name
    }
  }
`;

const MUTATION = gql`
  mutation CreateSponsorableProperty($input: CreateSponsorablePropertyInput!) {
    createSponsorableProperty(input: $input) {
      organization {
        id
        name
        organizationPlayLocations {
          id
          primary
          playLocation {
            id
            address
          }
        }
        sponsorableProperties {
          ...ProgramDetailsSponsorablePropertyFragment
          playLocation {
            id
            address
          }
          playLocations {
            id
            address
            primary
          }
          sponsorablePropertyPlayLocations {
            id
            primary
          }
          organization {
            id
            name
          }
        }
        playLocations {
          id
          address
        }
      }
    }
  }
  ${SPONSORABLE_PROPERTY_FRAGMENT}
`;

const CreateSponsorablePropertyModal = ({
  organization,
  sponsorableProperty,
}) => {
  const { data: competitivenessesAndSportsData } = useQuery(
    COMPETITIVENESSES_AND_SPORTS_QUERY,
  );
  const competitivenesses =
    competitivenessesAndSportsData &&
    competitivenessesAndSportsData.competitivenesses;
  const sports =
    competitivenessesAndSportsData && competitivenessesAndSportsData.sports;
  const organizationId = organization ? organization.id : null;
  const organizationName = organization ? organization.name : null;
  const playLocations = organization ? organization.playLocations : [];

  const { id: sponsorablePropertyId } = sponsorableProperty;

  const [mutate] = useMutation(MUTATION);
  const onSubmit = (input) =>
    mutate({
      variables: {
        input: mergeAll([
          input,
          organizationId ? { organizationId } : null,
          sponsorablePropertyId ? { id: sponsorablePropertyId } : null,
        ]),
      },
    });

  const playLocationsModel = playLocations.map((playLocation) => {
    // eslint-disable-next-line no-param-reassign
    playLocation.name = playLocation.address;
    return playLocation;
  });

  const [name, setName] = useState(
    sponsorableProperty.name || organizationName,
  );
  const [season, setSeason] = useState(sponsorableProperty.season);
  const [sportId, setSportId] = useState(
    sponsorableProperty.sport ? sponsorableProperty.sport.id : null,
  );
  const [competitivenessId, setCompetitivenessId] = useState(
    sponsorableProperty.competitiveness
      ? sponsorableProperty.competitiveness.id
      : null,
  );
  const [numberOfPlayers, setNumberOfPlayers] = useState(
    sponsorableProperty.numberOfPlayers,
  );
  const [minAge, setMinAge] = useState(sponsorableProperty.minAge);
  const [maxAge, setMaxAge] = useState(sponsorableProperty.maxAge);
  const [female, setFemale] = useState(sponsorableProperty.female);
  const [male, setMale] = useState(sponsorableProperty.male);

  const [startDateHook, setStartDateHook] = useState(
    sponsorableProperty.startDate
      ? moment(sponsorableProperty.startDate)
      : null,
  );
  const [endDateHook, setEndDateHook] = useState(
    sponsorableProperty.endDate ? moment(sponsorableProperty.endDate) : null,
  );
  const [focusedInput, setFocusedInput] = useState(null);
  const [selectedPlayLocations, setSelectedPlayLocations] = useState([]);
  const selectedSport = sports && findById(Number(sportId))(sports);
  const selectedCompetitiveness =
    competitivenesses && findById(Number(competitivenessId))(competitivenesses);

  const showAdditionalInputs =
    season && sportId && competitivenessId && selectedPlayLocations.length;
  const requiredInputsPresent = showAdditionalInputs && name;

  async function asyncSubmit() {
    await onSubmit({
      name,
      startDate: formatDateInput(startDateHook),
      endDate: formatDateInput(endDateHook),
      female,
      male,
      minAge,
      maxAge,
      numberOfPlayers,
      season,
      sportId,
      competitivenessId,
      playLocationsIds: selectedPlayLocations,
    });

    setName(organizationName);
    setSeason(null);
    setSportId(null);
    setCompetitivenessId(null);
    setNumberOfPlayers(null);
    setMinAge(null);
    setMaxAge(null);
    setStartDateHook(null);
    setEndDateHook(null);
    setSelectedPlayLocations([]);
  }

  const handleSeasonChange = ({ startDate, endDate }) => {
    setStartDateHook(startDate);
    setEndDateHook(endDate);
  };

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const label = 'create';
  const submitLabel = capitalize(label);
  const prefix = getId([label, 'sponsorable-property', sponsorablePropertyId]);

  const divStyle = {
    display: 'inline-block',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    width: '4.5rem',
  };

  const renderAdditionalInputs = () => (
    <>
      <div className="form-group">
        <label htmlFor={`${prefix}-number-of-players-input`}>
          Number of players in
          <strong>
            {` ${season} ${selectedCompetitiveness.name.toLowerCase()} ${selectedSport.name.toLowerCase()}`}
          </strong>
        </label>
        <input
          aria-required
          className="form-control form-control-lg"
          disabled={loading}
          onChange={({ target: { value } }) =>
            setNumberOfPlayers(Number(value))
          }
          id={`${prefix}-number-of-players-input`}
          type="number"
          value={numberOfPlayers}
        />
      </div>
      <div className="row">
        <div className="col">
          <strong>Age Range</strong>
          <>
            <p className="text-nowrap">
              <input
                className="form-control mr-2"
                id="minAge"
                onChange={({ target: { value } }) => setMinAge(Number(value))}
                placeholder="Min"
                style={divStyle}
                type="number"
                value={minAge}
              />
              -
              <input
                className="form-control ml-2"
                id="maxAge"
                onChange={({ target: { value } }) => setMaxAge(Number(value))}
                placeholder="Max"
                style={divStyle}
                type="number"
                value={maxAge}
              />
            </p>
          </>
        </div>
        <div className="col">
          <strong>Genders</strong>
          <div className="form-check">
            <input
              checked={female}
              className="form-check-input"
              id="female"
              onChange={(event) => setFemale(event.target.checked)}
              type="checkbox"
            />
            <label className="form-check-label" htmlFor="female">
              Female
            </label>
          </div>
          <div className="form-check">
            <input
              checked={male}
              className="form-check-input"
              id="male"
              onChange={(event) => setMale(event.target.checked)}
              type="checkbox"
            />
            <label className="form-check-label" htmlFor="male">
              Male
            </label>
          </div>
        </div>
      </div>
      <label htmlFor={`${prefix}-dates`}>
        First and last games dates for
        <strong>
          {` ${season} ${selectedCompetitiveness.name.toLowerCase()} ${selectedSport.name.toLowerCase()}`}
        </strong>
      </label>
      <DateRangePickerWrapper
        endDate={endDateHook}
        focusedInput={focusedInput}
        onDatesChange={handleSeasonChange}
        isOutsideRange={(date) => date.isAfter(TWO_YEARS_AFTER)}
        onFocusChange={setFocusedInput}
        startDate={startDateHook}
      />
    </>
  );

  return competitivenessesAndSportsData ? (
    <ModalForm
      id={`${prefix}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: conjugateString(submitLabel, loading),
        disabled: !requiredInputsPresent || loading,
      }}
      title={`${submitLabel} Sponsorable Property`}
      toggleProps={{
        className: 'btn btn-primary',
        children: (
          <>
            <Icon className="mr-1" value="plus" />
            Create Sponsorable Property
          </>
        ),
      }}
    >
      <NameInput
        isRequired
        loading={loading}
        name={name}
        onChange={({ target: { value } }) => setName(value)}
        prefix={prefix}
      />
      <SelectInput
        formGroupClassName="font-weight-bold"
        disabled={loading}
        label="Season"
        onChange={({ target: { value } }) => setSeason(value)}
        prefix={prefix}
        required
        value={season || ''}
      >
        {map(
          (type) => (
            <option key={type} value={type}>
              {capitalize(type)}
            </option>
          ),
          seasons,
        )}
      </SelectInput>
      <SelectInput
        formGroupClassName="font-weight-bold"
        disabled={loading}
        label="Sport"
        onChange={({ target: { value } }) => setSportId(Number(value))}
        prefix={prefix}
        required
        value={selectedSport ? selectedSport.id : ''}
      >
        {map(
          (sport) => (
            <option key={sport.id} value={sport.id}>
              {sport.name}
            </option>
          ),
          sports,
        )}
      </SelectInput>
      <SelectInput
        formGroupClassName="font-weight-bold"
        disabled={loading}
        label="Competitiveness"
        onChange={({ target: { value } }) =>
          setCompetitivenessId(Number(value))
        }
        prefix={prefix}
        required
        value={selectedCompetitiveness ? selectedCompetitiveness.id : ''}
      >
        {map(
          (competitiveness) => (
            <option key={competitiveness.id} value={competitiveness.id}>
              {competitiveness.name}
            </option>
          ),
          competitivenesses,
        )}
      </SelectInput>
      <label htmlFor="PlayLocations">
        <strong>Play Locations(s)</strong>
        <RequiredAsterisk />
      </label>
      <MultiSelect
        id="PlayLocations"
        onChange={setSelectedPlayLocations}
        models={playLocationsModel}
        selectedIds={selectedPlayLocations}
        disabled={loading}
      />
      {showAdditionalInputs ? renderAdditionalInputs() : null}
    </ModalForm>
  ) : null;
};

CreateSponsorablePropertyModal.propTypes = {
  organization: LeagueSidePropTypes.organization.isRequired,
  sponsorableProperty: PropTypes.shape({
    id: PropTypes.number,
    competitiveness: LeagueSidePropTypes.competitiveness,
    endDate: PropTypes.string,
    female: PropTypes.bool,
    male: PropTypes.bool,
    minAge: PropTypes.number,
    maxAge: PropTypes.number,
    name: PropTypes.string,
    numberOfPlayers: PropTypes.number,
    season: PropTypes.string,
    sport: LeagueSidePropTypes.sport,
    startDate: PropTypes.string,
  }),
};

CreateSponsorablePropertyModal.defaultProps = {
  sponsorableProperty: {
    id: null,
    competitiveness: { id: null },
    endDate: null,
    female: false,
    male: false,
    minAge: null,
    maxAge: null,
    name: null,
    numberOfPlayers: null,
    season: '',
    sport: { id: null },
    startDate: null,
  },
};

export default CreateSponsorablePropertyModal;
