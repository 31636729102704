import * as React from 'react';
import {
  __,
  ascend,
  contains,
  isEmpty,
  map,
  path,
  pipe,
  prop,
  sortWith,
} from 'ramda';
import OrganizationCapabilityCard, {
  OrganizationCapability,
} from 'components/OrganizationCapabilityCard';
import { Link } from 'react-router-dom';
import AddOrganizationCapabilityToPackageModal from './AddOrganizationCapabilityToPackageModal';

interface Organization {
  id: number;
  organizationCapabilities: OrganizationCapability[];
}

interface OrganizationCapabilityPackage {
  id: number;
}

interface OrganizationPackage {
  id: number;
  organization: Organization;
  organizationCapabilityPackages: OrganizationCapabilityPackage[];
}

interface Props {
  organizationPackage: OrganizationPackage;
}

const getAddedOrganizationCapabilityIds = map(
  path(['organizationCapability', 'id']),
);

const SponsorshipLibrary: React.FunctionComponent<Props> = ({
  organizationPackage,
}: Props) => {
  const {
    id: organizationPackageId,
    organization,
    organizationCapabilityPackages,
  } = organizationPackage;
  const { id: organizationId, organizationCapabilities } = organization;

  const addedOrganizationCapabilityIds = getAddedOrganizationCapabilityIds(
    organizationCapabilityPackages,
  );
  const isAdded = pipe(
    path(['id']),
    contains(__, addedOrganizationCapabilityIds),
  );

  const sortOrganizationCapabilities = (xs: OrganizationCapability[]) =>
    sortWith(
      [ascend(prop('archived')), ascend(isAdded), ascend(prop('name'))],
      xs,
    );

  const sortedOrganizationCapabilities = sortOrganizationCapabilities(
    organizationCapabilities,
  );

  return (
    <>
      <div className="row align-items-center my-3">
        <h3 className="col mb-0">Sponsorship Library</h3>
        <div className="col-auto text-right">
          <Link to={`/organizations/${organizationId}/capability-form`}>
            <button type="button" className="btn btn-primary">
              + Add Capability
            </button>
          </Link>
        </div>
      </div>
      <p>
        {isEmpty(organizationCapabilities)
          ? 'Create a new sponsorship opportunity to add to your package'
          : 'Add a sponsorship opportunity to your package or create a new one'}
        . You can reuse opportunities across multiple sponsorship packages.
      </p>
      {map((organizationCapability) => {
        const { archived } = organizationCapability;
        const added = isAdded(organizationCapability);

        return (
          <OrganizationCapabilityCard
            added={added}
            key={organizationCapability.id}
            organizationCapability={organizationCapability}
          >
            {!archived && (
              <AddOrganizationCapabilityToPackageModal
                added={added}
                organizationCapability={organizationCapability}
                organizationPackageId={organizationPackageId}
              />
            )}
          </OrganizationCapabilityCard>
        );
      }, sortedOrganizationCapabilities)}
    </>
  );
};

export default SponsorshipLibrary;
