import { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { useWizardContext } from 'pages/qualify/provider';
import { Organization, StepIdsEnum } from 'pages/qualify/types';
import { parseAnyListToOptionsList } from 'pages/qualify/utils';
import { WIZARD_IMAGES_PER_STEP_ID } from 'pages/qualify/wizard-images-urls';
import WizardStep from '../wizard-step';
import WizardInput from '../wizard-input';

export default function EmailAndSocialMediaStep() {
  const { tokenData, watch, setValues, values, token, organizationData } =
    useWizardContext();
  const {
    data: applicationAssetsQueryData,
    loading,
    refetch,
  } = useQuery<{
    sponsorshipApplicationView: Partial<Organization>;
  }>(APPLICATION_WARM_UPS_ASSETS_QUERY, {
    variables: { season: tokenData.season, year: tokenData.year },
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });
  const [performMutation] = useMutation(MUTATION);
  const accepted = watch('accepted');

  useEffect(() => {
    if (values.organizationId) {
      const { savedStepId } = organizationData?.sponsorshipApplication;
      if (savedStepId === StepIdsEnum.DEDICATED_EMAIL) return;
      const { emailOrSocialMediaDetail } =
        applicationAssetsQueryData?.sponsorshipApplicationView
          .sponsorshipApplication ?? {};
      setValues({
        ...values,
        accepted:
          applicationAssetsQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.emailOrSocialMediaAccepted ?? false,
        sponsorablePropertyIds:
          emailOrSocialMediaDetail?.associatedSponsorableProperties?.map(
            (property) => property.id,
          ),
        refusalReason:
          applicationAssetsQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.emailOrSocialMediaRefusalReason,
      });
    }
  }, [values.organizationId, applicationAssetsQueryData]);

  return (
    <WizardStep
      performMutation={performMutation}
      refetchAfterMutation={refetch}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId:
          applicationAssetsQueryData?.sponsorshipApplicationView
            ?.sponsorshipApplication?.id,
        offerId: tokenData.offer_id,
      }}
      isLoading={loading}
    >
      <WizardStep.Picture
        src={WIZARD_IMAGES_PER_STEP_ID[StepIdsEnum.DEDICATED_EMAIL]}
      />
      <WizardStep.Content
        title="Sponsor-branded email and social media posts"
        subtitle="Sponsor-branded content will be provided to your organization to send out"
      >
        <div className="sui-grid" style={{ gap: 24 }}>
          <WizardInput
            type="boolean-question"
            name="accepted"
            label="My organization is capable of sending sponsor-branded content via email and/or social media posts"
            helpText="Average sponsorship funding $140-$175 per email or post "
          />
          {accepted && (
            <>
              <WizardInput
                type="select"
                isMulti
                name="sponsorablePropertyIds"
                label="Programs this applies to"
                rules={{ required: 'Please select at least one program' }}
                options={parseAnyListToOptionsList(
                  values.sponsorableProperties ?? [],
                  { withEmptyOption: false },
                )}
              />
            </>
          )}
          {!accepted && (
            <>
              <WizardInput
                type="textarea"
                name="refusalReason"
                label="If possible, please share your reasons and specify the amount of funding at which you might reconsider opting in to this asset"
              />
            </>
          )}
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const APPLICATION_WARM_UPS_ASSETS_QUERY = gql`
  query SponsorshipApplicationView($season: String!, $year: Int!) {
    sponsorshipApplicationView {
      id
      sponsorshipApplication(season: $season, year: $year) {
        id
        emailOrSocialMediaAccepted
        emailOrSocialMediaRefusalReason
        emailOrSocialMediaDetail {
          id
          associatedSponsorableProperties {
            id
            name
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation upsertEmailOrSocialMedia($input: UpsertEmailOrSocialMediaInput!) {
    upsertEmailOrSocialMedia(input: $input) {
      id
    }
  }
`;
