import { createReducer } from 'redux-create-reducer';
import { F, T } from 'ramda';
import { LOCATION_CHANGE, push } from 'react-router-redux';
import { combineReducers } from 'redux';
import LeaguesideAPI from '../../utils/leagueside-api';
import { CAMPAIGN_PLANNER_PATH } from '../../utils/paths';

export const SAVE = 'sponsor/add/save';
export const SAVE_SUCCEEDED = 'sponsor/add/saveSucceeded';
export const SAVE_FAILED = 'sponsor/add/saveFailed';

export const saveSucceeded = (savedSponsor) => (dispatch) => {
  dispatch({ type: SAVE_SUCCEEDED, payload: savedSponsor });
  setTimeout(
    () => dispatch(push(`${CAMPAIGN_PLANNER_PATH}/${savedSponsor.id}`)),
    500,
  );
};

export const save = (sponsorProperties) => (dispatch) => {
  dispatch({ type: SAVE, payload: sponsorProperties });

  const dispatchSaveSucceeded = (savedSponsor) =>
    dispatch(saveSucceeded(savedSponsor));
  const dispatchSaveFailed = () => dispatch({ type: SAVE_FAILED });

  return LeaguesideAPI.createSponsor(sponsorProperties).then(
    dispatchSaveSucceeded,
    dispatchSaveFailed,
  );
};

export const saving = createReducer(false, {
  [SAVE]: T,
  [LOCATION_CHANGE]: F,
  [SAVE_SUCCEEDED]: F,
  [SAVE_FAILED]: F,
});

export const saved = createReducer(false, {
  [SAVE_SUCCEEDED]: T,
  [LOCATION_CHANGE]: F,
});

const add = combineReducers({
  saving,
  saved,
});

export default add;
