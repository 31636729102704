import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

export function FeatureFlagUnwrapped({ children, disabled, value }) {
  if (value === null) return null;

  const show = disabled ? !value : value;

  return show ? children : null;
}

FeatureFlagUnwrapped.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
};

FeatureFlagUnwrapped.defaultProps = {
  disabled: false,
  value: null,
};

function FeatureFlag({ name, token, ...rest }) {
  const context = token
    ? { headers: { Authorization: `Bearer ${token}` } }
    : undefined;

  return (
    <Query
      context={context}
      variables={{ name }}
      query={gql`
        query FeatureFlag($name: String!) {
          value: feature(name: $name)
        }
      `}
    >
      {({ data }) => (
        <FeatureFlagUnwrapped {...rest} value={data && data.value} />
      )}
    </Query>
  );
}

FeatureFlag.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  token: PropTypes.string,
};

FeatureFlag.defaultProps = {
  disabled: false,
  token: null,
};

export default FeatureFlag;
