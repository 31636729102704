/* eslint-disable prefer-destructuring */
/* eslint-disable no-await-in-loop */
import React, { useCallback, useEffect, useState } from 'react';
import { useSeasonalDashboardContext } from 'context/SeasonalDashboardContext';
import gql from 'graphql-tag';
import Loader from 'components/Loader';
import CampaignDashboardCreative from 'pages/campaignDashboard/CampaignDashboardCreative';
import { useApolloClient } from 'react-apollo';
import { isEmpty, map } from 'ramda';

const QUERY = gql`
  query SeasonalCampaignVerifications(
    $season: String!
    $year: Int!
    $cursor: String
  ) {
    seasonalDashboard {
      sponsor {
        id
        name
        campaignsConnection(
          season: $season
          year: $year
          first: 10
          after: $cursor
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              defaultVerificationDeadlines {
                id
                creativeContentTemplates {
                  id
                  renderedContent
                  name
                }
                creativeFileTemplates {
                  id
                  contentType
                  fullSizeUrl
                  name
                  thumbnailUrl
                }
                name
              }
            }
          }
        }
      }
    }
  }
`;

const buildAllCampaigns = (sponsor) => {
  if (!sponsor) return;

  const { campaignsConnection } = sponsor;
  const { edges: campaigns } = campaignsConnection;
  const creativeFileTemplatesMap = [];
  const creativeContentTemplatesMap = [];

  function uniqBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      // eslint-disable-next-line no-return-assign, no-prototype-builtins
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  for (let i = 0; i < campaigns.length; i += 1) {
    const { defaultVerificationDeadlines } = campaigns[i].node;

    if (!isEmpty(defaultVerificationDeadlines)) {
      map((dvd) => {
        if (!isEmpty(dvd.creativeFileTemplates)) {
          creativeFileTemplatesMap.push(dvd.creativeFileTemplates);
        }
        if (!isEmpty(dvd.creativeFileTemplates)) {
          creativeContentTemplatesMap.push(dvd.creativeContentTemplates);
        }
      }, defaultVerificationDeadlines);
    }
  }

  const creativeFileTemplatesUnique = uniqBy(
    creativeFileTemplatesMap.flat(),
    (creative) => creative.name,
  );
  const creativeContentTemplatesUnique = uniqBy(
    creativeContentTemplatesMap.flat(),
    (creative) => creative.name,
  );

  const allCampaigns = {
    sponsor: { name: sponsor.name },
    defaultVerificationDeadlines: [
      {
        creativeFileTemplates: creativeFileTemplatesUnique,
        creativeContentTemplates: creativeContentTemplatesUnique,
      },
    ],
  };

  // eslint-disable-next-line consistent-return
  return allCampaigns;
};

const Creatives = () => {
  const { token, season, year } = useSeasonalDashboardContext();

  const client = useApolloClient();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  const fetchAll = useCallback(async () => {
    let hasNextPage = true;
    let cursor = null;
    let allResults = null;

    while (hasNextPage) {
      const { data: fetchedData } = await client.query({
        query: QUERY,
        variables: { season, year, cursor },
        context: { headers: { Authorization: `Bearer ${token}` } },
      });
      const fetchedDataCampaignsConnection =
        fetchedData.seasonalDashboard.sponsor.campaignsConnection;

      if (!allResults) {
        allResults = fetchedData;
      } else {
        const { campaignsConnection } = allResults.seasonalDashboard.sponsor;

        campaignsConnection.edges = campaignsConnection.edges.concat(
          fetchedDataCampaignsConnection.edges,
        );
        campaignsConnection.pageInfo = fetchedDataCampaignsConnection.pageInfo;
      }
      hasNextPage = fetchedDataCampaignsConnection.pageInfo.hasNextPage;
      cursor = fetchedDataCampaignsConnection.pageInfo.endCursor;
    }
    setLoading(false);
    setData(allResults);
  }, [client, season, token, year]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  if (loading) return <Loader />;

  const sponsor = data && data.seasonalDashboard.sponsor;

  return (!loading, sponsor) ? (
    <>
      <CampaignDashboardCreative campaign={buildAllCampaigns(sponsor)} />
    </>
  ) : null;
};

export default Creatives;
