import React from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

const MUTATION = gql`
  mutation DissociateUserAndOrganization(
    $input: DissociateUserAndOrganizationInput!
  ) {
    dissociateUserAndOrganization(input: $input) {
      organization {
        id
        users {
          id
        }
      }
    }
  }
`;

const DeleteUserModal = ({ id, organizationId, email }) => {
  const [deleteUser] = useMutation(MUTATION, {
    variables: { input: { userId: id, organizationId } },
  });

  async function asyncSubmit() {
    await deleteUser({ id });
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  return (
    <ModalForm
      id={`delete-user-${id}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Remove'),
        className: 'btn btn-danger',
      }}
      title="Remove This User?"
      toggleProps={{
        className: 'btn btn-link text-danger p-0',
        children: <Icon value="trash" />,
      }}
    >
      Are you sure you want to remove&nbsp;
      <strong>{email}</strong>
      &nbsp;from this organization?
    </ModalForm>
  );
};

DeleteUserModal.propTypes = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
};

export default DeleteUserModal;
