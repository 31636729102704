import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAddCampaign } from 'modules';
import { save } from 'modules/campaign/add';
import PropTypes from 'prop-types';
import { getCampaignSeason } from 'utils/campaign';
import AddButton from 'components/AddButton';
import Card from 'components/Card';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import SeasonSelectInput from 'components/formInputs/SeasonSelectInput';
import YearSelectInput from 'components/formInputs/YearSelectInput';

class AddCampaign extends React.Component {
  constructor(props) {
    super(props);

    this.state = getCampaignSeason();

    this.save = this.save.bind(this);
  }

  get disabled() {
    const { saving, saved } = this.props;
    return saving || saved;
  }

  save(event) {
    event.preventDefault();

    const { props, state } = this;
    const { name, season, year } = state;
    props.save({
      name,
      sponsorId: Number(props.match.params.sponsorId),
      season,
      year,
    });
  }

  render() {
    const { props, state } = this;
    const { saving, saved } = props;
    const { season, year } = state;

    return (
      <Page>
        <RestrictTo roles={['admin']}>
          <FieldhousePageTitle title="Add Campaign">
            <Card>
              <h3>Add Campaign</h3>
              <form onSubmit={this.save}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    required
                    disabled={this.disabled}
                    onChange={(event) =>
                      this.setState({ name: event.target.value })
                    }
                  />
                </div>
                <SeasonSelectInput
                  disabled={this.disabled}
                  onChange={(event) =>
                    this.setState({ season: event.target.value })
                  }
                  value={season}
                />
                <YearSelectInput
                  disabled={this.disabled}
                  onChange={(event) =>
                    this.setState({ year: Number(event.target.value) })
                  }
                  value={year}
                />
                <AddButton added={saved} adding={saving} subject="Campaign" />
              </form>
            </Card>
          </FieldhousePageTitle>
        </RestrictTo>
      </Page>
    );
  }
}

AddCampaign.propTypes = {
  save: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      sponsorId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => getAddCampaign(state);
const mapDispatchToProps = (dispatch) => bindActionCreators({ save }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddCampaign);
