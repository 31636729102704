import React, { useState } from 'react';
import { equals } from 'ramda';
import PropTypes from 'prop-types';
import { capitalize } from 'utils/string';
import { useSubmit } from 'utils/hooks';
import { getId } from 'utils/id';
import { findById } from 'utils/misc';
import {
  isInvalidContent,
  invalidMustacheTagError,
} from 'utils/mustacheTagErrors';
import showModal from 'utils/showModal';
import { displayCreativeNotificationAlert } from 'utils/verificationDeadlines';
import AvailableCreativeNotificationAlert from 'components/AvailableCreativeNotificationAlert';
import ModalFormWithMustacheTagHandling from 'components/ModalFormWithMustacheTagHandling';
import { isEmpty } from 'components/RichTextInput';
import NameInput from 'components/formInputs/NameInput';
import ContentInput from 'components/formInputs/ContentInput';
import DeadlineSelectInput from 'components/formInputs/DeadlineSelectInput';
import ReplaceCreativesInput from 'components/formInputs/ReplaceCreativesInput';

const GenericCreativeContentModal = (props) => {
  const {
    capabilityName,
    deadlineId,
    deadlines,
    deadlineType,
    genericCreativeContent,
    onSubmit,
    toggleProps,
  } = props;
  const [replaceCreatives, setReplaceCreatives] = useState(false);
  const [content, setContent] = useState(
    (genericCreativeContent && genericCreativeContent.content) || '',
  );
  const [name, setName] = useState(
    (genericCreativeContent && genericCreativeContent.name) || '',
  );
  const [selectedDeadlineId, setSelectedDeadlineId] = useState(
    deadlineId || '',
  );
  const selectedDeadline = findById(Number(selectedDeadlineId))(deadlines);

  const isNew = !genericCreativeContent;
  const isVerificationDeadline = equals('verificationDeadline', deadlineType);
  const isDefaultVerificationDeadline = equals(
    'defaultVerificationDeadline',
    deadlineType,
  );

  const requiredDataPresent = !!(
    content &&
    !isEmpty(content) &&
    name &&
    !(isNew && !selectedDeadlineId)
  );
  const displayAlert =
    isVerificationDeadline &&
    selectedDeadline &&
    displayCreativeNotificationAlert(selectedDeadline);

  async function asyncSubmit() {
    if (isInvalidContent(content)) {
      throw new Error(invalidMustacheTagError);
    }

    await onSubmit({ content, name, selectedDeadlineId });

    if (isNew) {
      setContent('');
      setName('');
      setSelectedDeadlineId('');
      setReplaceCreatives(false);
    }

    if (replaceCreatives)
      showModal(`#${selectedDeadlineId}-replace-creatives-modal`);
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const label = isNew ? 'create' : 'update';
  const submitLabel = capitalize(label);
  const id = getId([
    label,
    capabilityName,
    'generic-creative-content',
    genericCreativeContent && genericCreativeContent.id,
  ]);

  return (
    <ModalFormWithMustacheTagHandling
      id={`${id}-modal`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      requiredDataPresent={requiredDataPresent}
      size="large"
      submitLabel={submitLabel}
      title={`${submitLabel} Creative Content?`}
      toggleProps={toggleProps}
    >
      {isNew && (
        <>
          <p>
            {`To ${label} content, please specify which deadline the content should be associated with.`}
          </p>
          <DeadlineSelectInput
            data-test="deadline-select-input"
            deadlineId={selectedDeadlineId}
            deadlines={deadlines}
            isRequired
            loading={loading}
            onChange={({ target: { value } }) => setSelectedDeadlineId(value)}
            prefix={id}
          />
          {displayAlert && <AvailableCreativeNotificationAlert />}
        </>
      )}
      <NameInput
        data-test="name-input"
        isRequired
        loading={loading}
        name={name}
        onChange={({ target: { value } }) => setName(value)}
        prefix={id}
      />
      <ContentInput content={content} prefix={id} setContent={setContent} />
      {isDefaultVerificationDeadline && (
        <ReplaceCreativesInput
          disabled={loading}
          prefix={id}
          replaceCreatives={replaceCreatives}
          setReplaceCreatives={setReplaceCreatives}
        />
      )}
    </ModalFormWithMustacheTagHandling>
  );
};

GenericCreativeContentModal.propTypes = {
  capabilityName: PropTypes.string,
  deadlineId: PropTypes.number,
  deadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      deadline: PropTypes.string.isRequired,
      didNotComplete: PropTypes.bool,
      missingCreatives: PropTypes.bool,
      name: PropTypes.string.isRequired,
      verified: PropTypes.bool,
    }),
  ),
  deadlineType: PropTypes.oneOf([
    'verificationDeadline',
    'defaultVerificationDeadline',
  ]).isRequired,
  genericCreativeContent: PropTypes.shape({
    id: PropTypes.number,
    content: PropTypes.string,
    name: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
};

GenericCreativeContentModal.defaultProps = {
  capabilityName: '',
  deadlineId: undefined,
  deadlines: [],
  genericCreativeContent: undefined,
};

export default GenericCreativeContentModal;
