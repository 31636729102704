import { received as receivedError } from 'modules/caughtErrors';

const catchErrors = () => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    return next(receivedError(error));
  }
};

export default catchErrors;
