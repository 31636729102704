import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import Loader from 'components/Loader';
import DangerousInnerHtml from 'components/dangerous-inner-html/dangerous-inner-html';
import { useSeasonalDashboardContext } from '../../context/SeasonalDashboardContext';

export const query = gql`
  query SeasonalDashboard($season: String!, $year: Int!) {
    seasonalDashboard {
      sponsor {
        id
        name
        seasonalGoal(season: $season, year: $year) {
          id
          sponsorFacingGoalBody
        }
      }
    }
  }
`;

function SeasonalGoalTab() {
  const { token, season, year } = useSeasonalDashboardContext();
  const { data, loading } = useQuery(query, {
    variables: { season, year },
    context: {
      headers: {
        Authorization: token,
      },
    },
  });

  if (loading) return <Loader />;

  const { sponsor } = data && data.seasonalDashboard;
  const goal = data && sponsor.seasonalGoal;
  const goalBody = goal ? goal.sponsorFacingGoalBody : '';

  return (
    <>
      {goal ? (
        <div className="container">
          <div className="row">
            <div className="col-md-7 my-2">
              <div id="goal-body">
                <DangerousInnerHtml html={goalBody} />
              </div>{' '}
            </div>
          </div>
        </div>
      ) : (
        <h1
          className="d-flex justify-content-center"
          data-test="no-results-message"
        >
          {' '}
          Sorry! Goals have not been created yet!{' '}
        </h1>
      )}
    </>
  );
}

export default SeasonalGoalTab;
