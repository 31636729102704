import React from 'react';
import { contains, equals, head, map, pluck } from 'ramda';
import PropTypes from 'prop-types';
import { getInputId } from 'utils/id';
import SelectInput from './SelectInput';

const STANDARDIZED_WRITE_IN_OPTION = 'Other (Write-In)';

const getDeadlineNames = (verificationDeadlines) =>
  verificationDeadlines ? pluck('name', verificationDeadlines) : [];

export const getInitialStandardizedDeadlineName = (verificationDeadlines) =>
  head(getDeadlineNames(verificationDeadlines)) || '';

const renderStandarizedOptions = (standardizedOption) => (
  <option key={standardizedOption} value={standardizedOption}>
    {standardizedOption}
  </option>
);

const DeadlineNameInput = ({
  capabilityVerificationDeadlines,
  isRequired,
  loading,
  name,
  onChange,
  prefix,
}) => {
  const standardizedDeadlineNames = [
    ...getDeadlineNames(capabilityVerificationDeadlines),
    STANDARDIZED_WRITE_IN_OPTION,
  ];
  const nameIsStandardized = contains(name, standardizedDeadlineNames);
  const standardizedInputValue = nameIsStandardized
    ? name
    : STANDARDIZED_WRITE_IN_OPTION;
  const nameInputId = getInputId([prefix, 'name']);

  return (
    <>
      <SelectInput
        disabled={loading}
        inputId="standardized"
        label="Name"
        noDefault
        onChange={({ target: { value } }) =>
          onChange(equals(value, STANDARDIZED_WRITE_IN_OPTION) ? '' : value)
        }
        prefix={prefix}
        required={isRequired}
        value={standardizedInputValue}
      >
        {map(renderStandarizedOptions, standardizedDeadlineNames)}
      </SelectInput>
      {!nameIsStandardized && (
        <div className="form-group mb-1">
          <input
            className="form-control"
            id={nameInputId}
            onChange={({ target: { value } }) => onChange(value)}
            type="text"
            value={name}
            disabled={loading}
          />
        </div>
      )}
    </>
  );
};

DeadlineNameInput.propTypes = {
  capabilityVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isRequired: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
};

DeadlineNameInput.defaultProps = {
  isRequired: false,
  prefix: null,
};

export default DeadlineNameInput;
