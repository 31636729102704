import 'react-app-polyfill/ie11';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import 'utils/logoutIfRequestUnauthorized';
import PresenterFactory from 'PresenterFactory';
import LeaguesideAPI from 'utils/leagueside-api';
import { createConsumer } from 'actioncable-js-jwt';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import * as Sentry from '@sentry/react';
import ErrorFallback from 'components/ErrorFallback';
import { ampli } from 'ampli';
import store, { history } from './store';
import App from './App';
import LeagueSideApolloProvider from './LeagueSideApolloProvider';

const env = runtimeEnv();
const target = document.querySelector('#root');
const factory = new PresenterFactory(LeaguesideAPI);
const yourToken = localStorage.token;
const cable = createConsumer(env.REACT_APP_WEBSOCKET_URI, yourToken);

ampli.load({
  environment: env.REACT_APP_AMP_ENV,
  client: { configuration: { defaultTracking: false } },
  disable: window.Cypress,
});

if (env.REACT_APP_PRODUCTION !== 'local' && !window.Cypress) {
  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,
    environment: env.REACT_APP_PRODUCTION === 'true' ? 'production' : 'staging',
    // integrations: [
    //   Sentry.replayIntegration(),
    // ],
    // // Session Replay
    // replaysSessionSampleRate: 1.0,
    // replaysOnErrorSampleRate: 1.0,
  });
}

// if (window.location.hostname.includes('review')) {
//   window.location.replace('https://fieldhouse.leagueside.com');
// }

render(
  <Provider store={store}>
    <LeagueSideApolloProvider>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <FieldhousePageTitle>
          <ConnectedRouter history={history}>
            <App factory={factory} cable={cable} />
          </ConnectedRouter>
        </FieldhousePageTitle>
      </Sentry.ErrorBoundary>
    </LeagueSideApolloProvider>
  </Provider>,
  target,
);
