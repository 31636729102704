import { pickBy } from 'ramda';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import TESTIMONIAL_FRAGMENT from 'fragments/TestimonialFragment';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import RequiredLabel from 'components/RequiredLabel';
import TextAreaInput from 'components/formInputs/TextAreaInput';
import PropTypes from 'prop-types';
import React from 'react';

function difference(oldObject, newObject) {
  const { attribution, quote } = newObject;
  const isModified = (value, key) => oldObject[key] !== value;
  const pickByIsModified = pickBy(isModified);

  return pickByIsModified({ attribution, quote });
}

class TestimonialUpdateModalUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    const { testimonial } = this.props;
    this.state = testimonial;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    const { props } = this;
    const { testimonial } = props;

    props.onSubmit(difference(testimonial, this.state));

    props.$(`#testimonial-${testimonial.id}-update-modal`).modal('hide');
  }

  render() {
    const { props, state } = this;
    const { id } = props.testimonial;

    return (
      <>
        <button
          className="btn btn-link text-primary"
          data-target={`#testimonial-${id}-update-modal`}
          data-test={`testimonial-${id}-update-modal-button`}
          data-toggle="modal"
          type="button"
        >
          <span className="icon icon-pencil" />
        </button>
        <Modal
          id={`testimonial-${id}-update-modal`}
          title="Update a Testimonial"
        >
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              <TextAreaInput
                label="Quote"
                onChange={({ target: { value } }) =>
                  this.setState({ quote: value })
                }
                required
                value={state.quote || ''}
              />
              <div className="form-group">
                <label htmlFor="attribution-input">Attribution</label>
                <input
                  className="form-control"
                  id="attribution-input"
                  onChange={({ target: { value } }) =>
                    this.setState({ attribution: value })
                  }
                  type="text"
                  value={state.attribution || ''}
                />
              </div>
              <RequiredLabel />
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-secondary"
                data-test={`update-testimonial-${id}-modal-cancel`}
                data-dismiss="modal"
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                data-test={`update-testimonial-modal-${id}-confirm`}
                type="submit"
              >
                Update
              </button>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
  }
}

TestimonialUpdateModalUnwrapped.defaultProps = {
  $: global.$,
};

TestimonialUpdateModalUnwrapped.propTypes = {
  $: PropTypes.func,
  testimonial: LeagueSidePropTypes.testimonial.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export { TestimonialUpdateModalUnwrapped };

const MUTATION = gql`
  mutation UpdateTestimonial($input: TestimonialInput!) {
    updateTestimonial(input: $input) {
      ...TestimonialFragment
    }
  }
  ${TESTIMONIAL_FRAGMENT}
`;

const TestimonialUpdateModal = ({ testimonial }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <TestimonialUpdateModalUnwrapped
        testimonial={testimonial}
        onSubmit={(input) =>
          mutate({
            variables: {
              input: {
                id: testimonial.id,
                ...input,
              },
            },
          })
        }
      />
    )}
  </Mutation>
);

TestimonialUpdateModal.propTypes = {
  testimonial: LeagueSidePropTypes.testimonial.isRequired,
};

export default TestimonialUpdateModal;
