import {
  anyPass,
  complement,
  defaultTo,
  equals,
  find,
  isEmpty,
  map,
  not,
  partition,
  pipe,
  pluck,
  prop,
  propEq,
  reject,
} from 'ramda';
import { joinTruthyWithSpace } from './string';

export const statusEq = propEq('status');

export const idEq = propEq('id');
export const findById = (id) => find(idEq(id));

export const rejectById = (id) => reject(idEq(id));
export const partitionByIds = (ids) => partition(anyPass(map(idEq, ids)));

export const findByProp = (propValue) => (value) =>
  find(propEq(propValue)(value));
export const findByEmail = findByProp('email');

export const isNotEmpty = complement(isEmpty);

export const pluckId = pluck('id');

export const defaultToEmptyArray = defaultTo([]);
export const defaultToEmptyObject = defaultTo({});

export const randomInt = (max = 100, min = 0) =>
  Math.round(Math.random() * (max - min)) + min;

export const unsavedChangesPrompt =
  "It looks like you made some changes that haven't been saved yet. Are you sure you want to leave this page?";
export const getUnsavedChangesPrompt = (additionalInfo = null) =>
  joinTruthyWithSpace([
    'It looks like you made some changes',
    additionalInfo,
    "that haven't been saved yet. Are you sure you want to leave this page?",
  ]);

export const isAllShownOrHidden = (filtered, all) => {
  const noFiltered = isEmpty(filtered);
  const allFiltered = equals(all.length, filtered.length);
  return noFiltered || allFiltered;
};

export const notProp = (value) => pipe(prop(value), not);

export const toPrecisionNumber = (precision) => (value) =>
  Number(value.toPrecision(precision));
