import { map } from 'ramda';
import { sortByReversedCreatedAt } from 'utils/sort';
import Card from 'components/Card';
import type { Offer } from 'types/offer';
import SendRequestAdditionalVerificationEmailModal from './send-request-additional-verification-email-modal';
import VerificationImageCardWithReviewFooter from './verification-image-card-with-review-footer';

type PendingVerificationsCardProps = {
  offer: Offer;
};

function PendingVerificationsCard(props: PendingVerificationsCardProps) {
  const { offer } = props;
  const { pendingImages } = offer;

  return (
    <Card data-test="pending-verifications">
      <div className="row">
        <h2 className="col text-nowrap">Pending Verifications</h2>
        <div className="col-auto text-right">
          <SendRequestAdditionalVerificationEmailModal offer={offer} />
        </div>
      </div>
      {pendingImages?.length ? (
        <div className="row">
          {map(
            (verificationImage) => {
              const { verificationDeadline } = verificationImage;

              return (
                <div className="col-lg-6 my-2" key={verificationImage.id}>
                  <VerificationImageCardWithReviewFooter
                    displayHeader
                    initialVerificationDeadlineId={
                      verificationDeadline ? verificationDeadline.id : undefined
                    }
                    pendingCard
                    verificationDeadlines={offer.verificationDeadlines}
                    verificationImage={verificationImage}
                  />
                </div>
              );
            },
            // @ts-ignore
            sortByReversedCreatedAt(pendingImages) as any,
          )}
        </div>
      ) : (
        <p className="text-center my-4">
          No new verification images have been received recently.
        </p>
      )}
    </Card>
  );
}

export default PendingVerificationsCard;
