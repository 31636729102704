import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'components/DatePicker';
import { RequiredAsterisk } from 'components/RequiredLabel';

const DeadlineDateInput = ({
  label,
  deadline,
  isRequired,
  onChange,
  ...rest
}) => (
  <div className="form-group mt-3">
    <label htmlFor="deadline-input">
      {label || 'Deadline'}
      {!!isRequired && <RequiredAsterisk />}
    </label>
    <div>
      <DatePicker
        date={deadline}
        id="deadline-input"
        onDateChange={(date) => onChange(date)}
        {...rest}
      />
    </div>
  </div>
);

DeadlineDateInput.propTypes = {
  deadline: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isRequired: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

DeadlineDateInput.defaultProps = {
  deadline: null,
  isRequired: false,
  label: null,
};

export default DeadlineDateInput;
