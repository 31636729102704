import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { formatToDollar, formatNumber } from 'utils/formatNumber';

import './IncomeFilterForm.css';

const toValue = (input) => numeral(formatNumber(input)).value();

class IncomeFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueOne: props.savedLowBound,
      valueTwo: props.savedHighBound,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setValueOne = (event) =>
      this.setState({ valueOne: toValue(event.target.value) });
    this.setValueTwo = (event) =>
      this.setState({ valueTwo: toValue(event.target.value) });
  }

  handleSubmit(event) {
    const { props, state } = this;
    const { valueOne, valueTwo } = state;
    event.preventDefault();

    props.onSave({
      lowBound: Math.min(valueOne, valueTwo),
      highBound: Math.max(valueOne, valueTwo),
    });
  }

  render() {
    const { props, state } = this;
    const { valueOne, valueTwo } = state;

    return (
      <form onSubmit={this.handleSubmit}>
        <p>
          Between
          <input
            className="income-filter-form__input form-control ml-1 mr-1"
            type="text"
            value={formatToDollar(valueOne)}
            onChange={this.setValueOne}
            required
          />
          and
          <input
            className="income-filter-form__input form-control ml-1 mr-1"
            style={{ display: 'inline-block', width: '10rem' }}
            type="text"
            value={formatToDollar(valueTwo)}
            onChange={this.setValueTwo}
            required
          />
        </p>
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={this.save} type="submit">
            Save
          </button>
        </div>
      </form>
    );
  }
}

IncomeFilterForm.propTypes = {
  savedLowBound: PropTypes.number.isRequired,
  savedHighBound: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default IncomeFilterForm;
