import React from 'react';
import PropTypes from 'prop-types';
import { both, cond, pipe, prop } from 'ramda';
import { formatNumber } from 'utils/formatNumber';
import { pluralizedTense } from 'utils/string';

import FilterCard from './FilterCard';
import NumberOfPlayersFilterForm from './NumberOfPlayersFilterForm';

const labelPlayers = (count) =>
  `${formatNumber(count)} ${pluralizedTense(count, 'player')}`;
const lowBoundProp = prop('lowBound');
const highBoundProp = prop('highBound');
const formatLowBound = pipe(lowBoundProp, formatNumber);
const labelHighBound = pipe(highBoundProp, labelPlayers);
const labelLowBound = pipe(lowBoundProp, labelPlayers);

const renderDescription = cond([
  [
    both(lowBoundProp, highBoundProp),
    (props) => (
      <>
        Between&nbsp;
        <strong className="font-weight-bold">{formatLowBound(props)}</strong>
        &nbsp;and&nbsp;
        <strong className="font-weight-bold">{labelHighBound(props)}</strong>
      </>
    ),
  ],
  [
    lowBoundProp,
    (props) => (
      <>
        At least&nbsp;
        <strong
          data-test="number-of-players-filter-description"
          className="font-weight-bold"
        >
          {labelLowBound(props)}
        </strong>
      </>
    ),
  ],
  [
    highBoundProp,
    (props) => (
      <>
        At most&nbsp;
        <strong
          data-test="number-of-players-filter-description"
          className="font-weight-bold"
        >
          {labelHighBound(props)}
        </strong>
      </>
    ),
  ],
]);

const NumberOfPlayersFilterCard = ({ filterId, lowBound, highBound }) => (
  <FilterCard
    filterId={filterId}
    title="Target Number of Players"
    iconValue="man"
    description={renderDescription({ lowBound, highBound })}
    formRenderer={({ save, cancel }) => (
      <NumberOfPlayersFilterForm
        savedLowBound={lowBound}
        savedHighBound={highBound}
        onCancel={cancel}
        onSave={save}
      />
    )}
  />
);

NumberOfPlayersFilterCard.propTypes = {
  filterId: PropTypes.number.isRequired,
  lowBound: PropTypes.number,
  highBound: PropTypes.number,
};

NumberOfPlayersFilterCard.defaultProps = {
  lowBound: null,
  highBound: null,
};

export default NumberOfPlayersFilterCard;
