import React from 'react';
import PropTypes from 'prop-types';
import ReactImageLightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class CreativeFileTemplateLightbox extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };

    this.close = () => this.setState({ isOpen: false });
    this.open = () => this.setState({ isOpen: true });
  }

  render() {
    const { creativeFileTemplate } = this.props;
    const { isOpen } = this.state;
    const { name } = creativeFileTemplate;

    return (
      <>
        <button className="btn btn-link p-0" type="button" onClick={this.open}>
          <figure className="figure">
            <img
              alt={name}
              className="figure-img img-thumbnail"
              data-test="thumbnail"
              key={creativeFileTemplate.id}
              src={creativeFileTemplate.thumbnailUrl}
            />
            <figcaption className="figure-caption">{name}</figcaption>
          </figure>
        </button>
        {isOpen && (
          <ReactImageLightbox
            imageTitle={name}
            mainSrc={creativeFileTemplate.fullSizeUrl}
            onCloseRequest={this.close}
          />
        )}
      </>
    );
  }
}

CreativeFileTemplateLightbox.propTypes = {
  creativeFileTemplate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullSizeUrl: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default CreativeFileTemplateLightbox;
