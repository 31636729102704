import { map, pipe, sortBy } from 'ramda';
import { prop } from 'utils/ts/misc';
import type { Campaign, CampaignCapability, Capability } from 'types/campaign';
import CampaignCapabilityCard from './CampaignCapabilityCard';

type CampaignCapabilityCardsProps = {
  campaign: Campaign;
};

const capabilityNamePath = pipe(
  prop<CampaignCapability, 'capability'>('capability'),
  prop<Capability, 'name'>('name'),
);

const sortByCapabilityName = sortBy(capabilityNamePath);

function CampaignCapabilityCards(props: CampaignCapabilityCardsProps) {
  const { campaign } = props;
  const { campaignCapabilities } = campaign;

  return campaignCapabilities.length ? (
    <div data-test="campaign-capability-cards">
      {map(
        (campaignCapability) => (
          <CampaignCapabilityCard
            key={campaignCapability.id}
            campaign={campaign}
            campaignCapability={campaignCapability}
          />
        ),
        sortByCapabilityName(campaignCapabilities),
      )}
    </div>
  ) : (
    <p className="mt-3 text-center">
      No capabilities have been added to this campaign.&nbsp; Use the &quot;Add
      A Campaign Capability&quot; button to add capabilities.
    </p>
  );
}

export default CampaignCapabilityCards;
