import React from 'react';
import Autocomplete from 'react-autocomplete';
import { prop } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';

function OrganizationAutocomplete({
  items,
  loading,
  localQuery,
  onChange,
  onSelect,
  placeholder,
  renderOrganizationItem,
}) {
  return (
    <Autocomplete
      getItemValue={prop('name')}
      inputProps={{
        className: 'form-control form-control-lg',
        id: 'organization-autocomplete',
        placeholder,
        disabled: loading,
      }}
      items={items || []}
      onChange={onChange}
      onSelect={onSelect}
      renderItem={(organization, isHighlighted) => (
        <li
          data-test={`organization-result-${organization.id}`}
          key={organization.id}
          className={classNames('dropdown-item mt-2', {
            active: isHighlighted,
          })}
        >
          {renderOrganizationItem(organization)}
        </li>
      )}
      renderMenu={(menuItems) =>
        menuItems.length ? (
          <div className="dropdown">
            <ul
              className={classNames('dropdown-menu w-100', {
                show: !!localQuery,
              })}
            >
              {menuItems}
            </ul>
          </div>
        ) : (
          <></>
        )
      }
      value={localQuery || ''}
      wrapperProps={{ className: 'd-block' }}
    />
  );
}

OrganizationAutocomplete.propTypes = {
  items: LeagueSidePropTypes.leagues.isRequired,
  loading: PropTypes.bool,
  localQuery: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  renderOrganizationItem: PropTypes.func.isRequired,
};

OrganizationAutocomplete.defaultProps = {
  loading: false,
  localQuery: '',
  placeholder: '',
};

export default OrganizationAutocomplete;
