/* eslint-disable react/no-danger */

import React from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatNumber } from 'utils/formatNumber';
import { pluralizedTense } from 'utils/string';
import { addCloudinaryAutoFormat } from 'utils/image';
import DangerousInnerHtml from 'components/dangerous-inner-html/dangerous-inner-html';
import CampaignDashboardMapWithList from './CampaignDashboardMapWithList';
import CampaignDashboardTestimonials from './CampaignDashboardTestimonials';
import CampaignDashboardStatistics from './CampaignDashboardStatistics';
import CampaignDashboardCreative from './CampaignDashboardCreative';
import CampaignDashboardFeaturedPhoto from './CampaignDashboardFeaturedPhoto';

const CampaignDashboardOverview = ({ campaign }) => {
  const {
    impressions,
    offers: confirmedOffers,
    sponsorDashboardAdditionalContent,
    sponsorFacingGoalImageUrl,
    lookerUrl,
  } = campaign;
  const numberConfirmed = confirmedOffers.length;

  return (
    <div className="container">
      <div className="row">
        {sponsorFacingGoalImageUrl && (
          <div className="col-md-5 my-2">
            <img
              alt=""
              className="mw-100"
              id="goal-image"
              src={addCloudinaryAutoFormat(sponsorFacingGoalImageUrl)}
            />
          </div>
        )}
        <div className="col-md-7 my-2">
          <h2 id="goal-title">{campaign.sponsorFacingGoalTitle}</h2>
          <div id="goal-body">
            <DangerousInnerHtml html={campaign.sponsorFacingGoalBody} />
          </div>
        </div>
      </div>
      <hr className="my-5" />
      <CampaignDashboardFeaturedPhoto campaign={campaign} />
      {!!impressions && (
        <div className="text-center">
          <h2 className="mb-4">Measuring Effectiveness</h2>
          <h1 id="impressions">{formatNumber(impressions)}</h1>
          <h6 className="mb-3">Impressions</h6>
          <hr className="my-5" />
        </div>
      )}
      {lookerUrl && (
        <div style={{ textAlign: 'center' }}>
          <h2 className="text-center mb-4">Digital Ad Report</h2>
          <iframe
            data-test="look-report-url"
            src={lookerUrl}
            title="lookerUrl"
            width="70%"
            height="500"
            style={{ textAlign: 'center' }}
          />
        </div>
      )}
      {!!campaign.testimonials.length && (
        <>
          <CampaignDashboardTestimonials campaign={campaign} />
          <hr className="my-5" />
        </>
      )}
      {!!campaign.campaignStatistics.length && (
        <>
          <CampaignDashboardStatistics campaign={campaign} />
          <hr className="my-5" />
        </>
      )}
      <h2 className="text-center mb-4">
        {`${numberConfirmed} Sponsored ${pluralizedTense(
          numberConfirmed,
          'League',
        )}`}
      </h2>
      <CampaignDashboardMapWithList campaign={campaign} />
      <hr className="my-5" />
      {!!sponsorDashboardAdditionalContent && (
        <>
          <div id="additional-content">
            <DangerousInnerHtml html={sponsorDashboardAdditionalContent} />
          </div>
          <hr className="my-5" />
        </>
      )}
      <CampaignDashboardCreative campaign={campaign} />
    </div>
  );
};

CampaignDashboardOverview.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignDashboardOverview;
