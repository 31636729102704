import * as React from 'react';
import { any, filter, map, pipe } from 'ramda';
import { prop } from 'utils/ts/misc';
import { sortWithDeadlineAndName } from 'utils/ts/sort';
import { hasMatchingNameAndCapability } from 'utils/verificationDeadlines';
import type { Offer, VerificationDeadline } from 'types/offer';
import DefaultVerificationDeadlineRow from './DefaultVerificationDeadlineRow';

interface Props {
  capabilityName: string;
  capabilityVerificationDeadlines: VerificationDeadline[];
  confirmedOffers: Offer[];
  defaultVerificationDeadlines: VerificationDeadline[];
}

const DefaultVerificationDeadlinesTable: React.FunctionComponent<Props> = ({
  capabilityName,
  capabilityVerificationDeadlines,
  confirmedOffers,
  defaultVerificationDeadlines,
}: Props) => {
  const sortedDefaultVerificationDeadlines = sortWithDeadlineAndName(
    defaultVerificationDeadlines,
  );
  const headers = ['Deadline', 'Name', 'Creatives', 'Actions'];

  const filterOffersByVerificationDeadlinesName = (name: string) =>
    filter(
      pipe(
        prop<Offer, 'verificationDeadlines'>('verificationDeadlines'),
        any(hasMatchingNameAndCapability(name, capabilityName)),
      ),
    );

  return (
    <table className="table table-borderless mb-0">
      <thead>
        <tr>
          {map(
            (header) => (
              <th scope="col" key={header}>
                {header}
              </th>
            ),
            headers,
          )}
        </tr>
      </thead>
      <tbody>
        {map((defaultVerificationDeadline) => {
          const filterOffers = filterOffersByVerificationDeadlinesName(
            defaultVerificationDeadline.name,
          );

          return (
            <DefaultVerificationDeadlineRow
              key={defaultVerificationDeadline.id}
              capabilityName={capabilityName}
              capabilityVerificationDeadlines={capabilityVerificationDeadlines}
              offersWithMatchingDeadline={filterOffers(confirmedOffers)}
              defaultVerificationDeadline={defaultVerificationDeadline}
              tableColCount={headers.length}
            />
          );
        }, sortedDefaultVerificationDeadlines)}
      </tbody>
    </table>
  );
};

export default DefaultVerificationDeadlinesTable;
