import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { useWizardContext } from 'pages/qualify/provider';
import { parseAnyListToOptionsList } from 'pages/qualify/utils';
import { WIZARD_IMAGES_PER_STEP_ID } from 'pages/qualify/wizard-images-urls';
import moment from 'moment';
import WizardInput from '../wizard-input';
import WizardStep from '../wizard-step';
import { Inputs, Organization, StepIdsEnum } from '../../types';

export default function JerseysStep() {
  const { tokenData, watch, setValues, values, token, organizationData } =
    useWizardContext();
  const {
    data: applicationAssetQueryData,
    loading,
    refetch,
  } = useQuery<{
    sponsorshipApplicationView: Partial<Organization>;
  }>(APPLICATION_JERSEYS_ASSETS_QUERY, {
    variables: { season: tokenData.season, year: tokenData.year },
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });
  const [performMutation] = useMutation(MUTATION);
  const accepted = watch('accepted');

  const modifyInputsBeforeMutation = (
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    ...data,
    maxForSale: Number(data.maxForSale),
    price: Number(data.price),
  });

  useEffect(() => {
    if (values.organizationId) {
      const { savedStepId } = organizationData?.sponsorshipApplication;
      if (savedStepId === StepIdsEnum.GAME_JERSEYS) return;
      const { teamGameJerseyDetail } =
        applicationAssetQueryData?.sponsorshipApplicationView
          .sponsorshipApplication ?? {};
      setValues({
        ...values,
        accepted:
          applicationAssetQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.teamGameJerseyAccepted ?? false,
        otherBrands:
          teamGameJerseyDetail?.capabilityDetailable?.otherBrands ?? false,
        maxForSale: teamGameJerseyDetail?.maxForSale,
        price: teamGameJerseyDetail?.price,
        orderingDeadline:
          teamGameJerseyDetail?.capabilityDetailable?.orderingDeadline,
        sponsorablePropertyIds:
          teamGameJerseyDetail?.associatedSponsorableProperties?.map(
            (property) => property.id,
          ),
        refusalReason:
          applicationAssetQueryData?.sponsorshipApplicationView
            .sponsorshipApplication?.teamGameJerseyRefusalReason,
      });
    }
  }, [values.organizationId, applicationAssetQueryData]);

  return (
    <WizardStep
      performMutation={performMutation}
      refetchAfterMutation={refetch}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId:
          applicationAssetQueryData?.sponsorshipApplicationView
            ?.sponsorshipApplication?.id,
        offerId: tokenData.offer_id,
        id: applicationAssetQueryData?.sponsorshipApplicationView
          .sponsorshipApplication?.teamGameJerseyDetail?.capabilityDetailable
          ?.id,
      }}
      modifyInputsBeforeMutation={modifyInputsBeforeMutation}
      isLoading={loading}
    >
      <WizardStep.Picture
        src={WIZARD_IMAGES_PER_STEP_ID[StepIdsEnum.GAME_JERSEYS]}
      />
      <WizardStep.Content
        title="Logo on jerseys"
        subtitle="Sponsor logo file will be provided to your organization to arrange for printing on your jerseys. Before production, you will need to send a digital mockup for approval."
      >
        <div className="sui-grid" style={{ gap: 24 }}>
          <WizardInput
            type="boolean-question"
            name="accepted"
            label="My organization is capable of placing a sponsor's logo on either the front or back of one or more team's game jerseys for the upcoming season"
            helpText="Average sponsorship funding $175-225 per team"
          />
          {accepted && (
            <>
              <WizardInput
                type="boolean-question"
                name="otherBrands"
                label="My organization needs to accommodate other sponsor logos on the same jerseys"
              />
              <WizardInput
                type="number"
                name="maxForSale"
                label="Max number of jerseys available for featuring a sponsor logo"
              />
              <WizardInput
                type="financial"
                currency="USD"
                name="price"
                label="Expected funding for logo placement on the max number of jerseys"
              />
              <div style={{ maxWidth: 276 }}>
                <WizardInput
                  type="date"
                  name="orderingDeadline"
                  label="Jersey ordering deadline"
                  rules={{
                    validate: {
                      todayOrAfterToday: (value) => {
                        const pickedDate = moment(value);
                        const today = moment().startOf('day');
                        if (pickedDate.isBefore(today)) {
                          return 'Jersey ordering deadline date must be today or later';
                        }
                        return true;
                      },
                    },
                  }}
                />
              </div>
              <WizardInput
                type="select"
                isMulti
                name="sponsorablePropertyIds"
                label="Programs this applies to"
                rules={{ required: 'Please select at least one program' }}
                options={parseAnyListToOptionsList(
                  values.sponsorableProperties ?? [],
                  { withEmptyOption: false },
                )}
              />
            </>
          )}
          {!accepted && (
            <>
              <WizardInput
                type="textarea"
                name="refusalReason"
                label="If possible, please share your reasons and specify the amount of funding at which you might reconsider opting in to this asset"
              />
            </>
          )}
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const APPLICATION_JERSEYS_ASSETS_QUERY = gql`
  query SponsorshipApplicationView($season: String!, $year: Int!) {
    sponsorshipApplicationView {
      id
      sponsorshipApplication(season: $season, year: $year) {
        id
        teamGameJerseyAccepted
        teamGameJerseyRefusalReason
        teamGameJerseyDetail {
          id
          price
          maxForSale
          associatedSponsorableProperties {
            id
            name
          }
          capabilityDetailable {
            ... on TeamGameJersey {
              id
              minTeamsRequired
              otherBrands
              orderingDeadline
            }
          }
        }
      }
    }
  }
`;

const MUTATION = gql`
  mutation upsertTeamGameJersey($input: UpsertTeamGameJerseyInput!) {
    upsertTeamGameJersey(input: $input) {
      id
    }
  }
`;
