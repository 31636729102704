import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import PlacesAutocomplete from 'react-places-autocomplete';
import { equals, map } from 'ramda';
import classNames from 'classnames';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmitWithGQL } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import PLAY_LOCATION_FRAGMENT from 'fragments/PlayLocationFragment';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';
import { RequiredAsterisk } from 'components/RequiredLabel';
import StringInput from 'components/formInputs/StringInput';

export const AddPlayLocationModalUnwrapped = ({ addPlayLocation }) => {
  const [address, setAddress] = useState('');
  const [nickname, setNickname] = useState('');
  const [selection, setSelection] = useState('');

  const addressValidated = equals(address, selection);
  const requiredDataPresent = address && nickname && addressValidated;

  async function asyncSubmit() {
    await addPlayLocation({ nickname, address });

    setAddress('');
    setNickname('');
    setSelection('');
  }

  const { error, loading, handleSubmit } = useSubmitWithGQL(asyncSubmit);
  const determineError = () => {
    if (!!error && error.graphQLErrors[0] !== undefined) {
      return error.graphQLErrors[0].message;
    }

    return 'Oops! Something went wrong!';
  };
  return (
    <ModalForm
      id="add-play-location-modal"
      error={error}
      errorMessage={
        <div className="alert alert-danger mb-0 mt-3">
          <ul className="mb-0">{determineError()}</ul>
        </div>
      }
      hasRequiredFields
      loading={loading}
      handleSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Add'),
        disabled: !requiredDataPresent || loading,
      }}
      title="Add A Play Location"
      toggleProps={{
        className: 'btn btn-secondary',
        children: (
          <>
            <Icon className="mr-1" value="plus" />
            Add A Play Location
          </>
        ),
      }}
    >
      <StringInput
        disabled={loading}
        label="Enter A Nickname"
        onChange={({ target: { value } }) => setNickname(value)}
        required
        type="text"
        value={nickname}
      />
      <div className="form-group">
        <label htmlFor="address-input">
          Search for and select an address:
          <RequiredAsterisk />
        </label>
        <PlacesAutocomplete
          className="form-group"
          data-test="places-autocomplete-input"
          highlightFirstSuggestion
          onChange={(value) => setAddress(value)}
          onSelect={(value) => {
            setAddress(value);
            setSelection(value);
          }}
          shouldFetchSuggestions
          value={address}
          disabled={loading}
        >
          {({ getInputProps, getSuggestionItemProps, suggestions }) => (
            <div className="sui-relative">
              <input
                {...getInputProps({
                  className: 'form-control',
                  id: 'address-input',
                  placeholder: '100 Example St',
                  required: true,
                })}
              />
              <div className="dropdown">
                <ul
                  className={classNames('dropdown-menu sui-w-full', {
                    show: suggestions.length,
                  })}
                >
                  {map((suggestion) => {
                    return (
                      <li
                        key={suggestion.id}
                        {...getSuggestionItemProps(suggestion, {
                          className: classNames('dropdown-item', {
                            active: suggestion.active,
                          }),
                          key: suggestion.description,
                        })}
                        style={{ whiteSpace: 'wrap' }}
                      >
                        {suggestion.description}
                      </li>
                    );
                  }, suggestions)}
                </ul>
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </div>
    </ModalForm>
  );
};

AddPlayLocationModalUnwrapped.propTypes = {
  addPlayLocation: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation CreateOrganizationPlayLocation(
    $input: CreateOrganizationPlayLocationInput!
  ) {
    createOrganizationPlayLocation(input: $input) {
      organization {
        id
        playLocations {
          ...PlayLocationFragment
        }
      }
    }
  }
  ${PLAY_LOCATION_FRAGMENT}
`;

const AddPlayLocationModal = ({ organizationId }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <AddPlayLocationModalUnwrapped
        addPlayLocation={({ address, nickname }) =>
          mutate({
            variables: {
              input: {
                organizationId: Number(organizationId),
                playLocationInput: { address, nickname },
              },
            },
          })
        }
      />
    )}
  </Mutation>
);

AddPlayLocationModal.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default AddPlayLocationModal;
