import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import SingleCardPage from 'components/SingleCardPage';
import { getInvitation as getInvitationState } from 'modules';
import {
  loadInvitation,
  getInvitation,
  getIsWorking,
  acceptInvitation,
  getMessage,
} from 'modules/invitation';
import InvitationForm from 'pages/invitation/InvitationForm';

class InvitationUnwrapped extends Component {
  // eslint-disable-next-line react/no-deprecated
  componentWillMount() {
    const { props } = this;
    props.loadInvitation();
  }

  render() {
    const { props } = this;

    return (
      <FieldhousePageTitle title="Accept Invitation">
        <SingleCardPage>
          <InvitationForm
            isWorking={props.isWorking}
            invitation={props.invitation}
            onAcceptInvitation={props.acceptInvitation}
            message={props.message}
          />
        </SingleCardPage>
      </FieldhousePageTitle>
    );
  }
}

InvitationUnwrapped.propTypes = {
  isWorking: PropTypes.bool,
  invitation: PropTypes.shape({
    email: PropTypes.string,
  }),
  message: PropTypes.string,

  loadInvitation: PropTypes.func.isRequired,
  acceptInvitation: PropTypes.func.isRequired,
};

InvitationUnwrapped.defaultProps = {
  message: null,
  invitation: null,
  isWorking: false,
};

const mapStateToProps = (state) => ({
  invitation: getInvitation(getInvitationState(state)),
  isWorking: getIsWorking(getInvitationState(state)),
  message: getMessage(getInvitation(state)),
});

const mapDispatchToProps = (dispatch, props) => ({
  loadInvitation: () => dispatch(loadInvitation(props.match.params.token)),
  acceptInvitation: (password, passwordConfirmation, name, title, cellPhone) =>
    dispatch(
      acceptInvitation(
        props.match.params.token,
        password,
        passwordConfirmation,
        name,
        title,
        cellPhone,
      ),
    ),
});

const Invitation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvitationUnwrapped);
export default Invitation;
