import React from 'react';
import { Link } from 'react-router-dom';
import { pluck, sum } from 'ramda';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatToDollar, formatNumber } from 'utils/formatNumber';
import { pluralizedTense } from 'utils/string';
import Card from 'components/Card';
import ProgressBar from 'components/ProgressBar';
import SponsorablePropertyListModal from './SponsorablePropertyListModal';

const campaignProgress = (offers) => ({
  confirmedLeagues: offers.length,
  confirmedValue: sum(pluck('value', offers)),
});

function SponsorablePropertyProspectingCard({ campaign }) {
  const { confirmedOffers, leagueBudget, targetNumberOfLeagues } = campaign;
  const progress = campaignProgress(confirmedOffers);
  const { confirmedLeagues, confirmedValue } = progress;

  return (
    <Card id="progress-card">
      <h3 className="card-title">
        <Link to={`/prospect-campaign/${campaign.id}`}>
          Sponsorable Property Prospecting
        </Link>
      </h3>
      <div>
        <strong>
          {`${formatToDollar(confirmedValue)} of ${formatToDollar(
            leagueBudget,
          )} Confirmed`}
        </strong>
        <ProgressBar
          data-test="confirmed-value-progress-bar"
          maximum={leagueBudget}
          value={confirmedValue}
        />
      </div>
      <div className="mt-4">
        <strong>
          {`${formatNumber(confirmedLeagues)} of ${formatNumber(
            targetNumberOfLeagues,
          )} ${pluralizedTense(
            targetNumberOfLeagues,
            'Sponsorable Property',
          )} Confirmed`}
        </strong>
        <ProgressBar
          data-test="confirmed-sponsorable-properties-progress-bar"
          maximum={targetNumberOfLeagues}
          value={confirmedLeagues}
        />
      </div>
      <div className="mt-4 text-center">
        <SponsorablePropertyListModal campaign={campaign} />
      </div>
    </Card>
  );
}

SponsorablePropertyProspectingCard.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default SponsorablePropertyProspectingCard;
