import React from 'react';
import PropTypes from 'prop-types';

import defaultLogo from 'images/teamsnap_logo_white.png';

import './SingleCardPage.css';

const SingleCardPage = ({ logo, children }) => (
  <div className="container-fill-height single-card-page">
    <div className="container-content-middle">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="logo">
              <img src={logo} alt="TeamSnap" />
            </div>
            <div className="card m-2">
              <div className="card-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
      {/* TODO: footer placeholder .. tbd */}
    </div>
  </div>
);

SingleCardPage.propTypes = {
  logo: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SingleCardPage.defaultProps = {
  logo: defaultLogo,
};

export default SingleCardPage;
