import React from 'react';
import PropTypes from 'prop-types';
import Portal from 'components/Portal';
import classNames from 'classnames';
import hideModal from 'utils/hideModal';

const ModalBody = (props) => <div className="modal-body" {...props} />;
const ModalFooter = (props) => <div className="modal-footer" {...props} />;
const SIZES = {
  small: 'modal-sm',
  medium: '',
  large: 'modal-lg',
};

class Modal extends React.Component {
  componentWillUnmount() {
    const { id, onClose } = this.props;
    onClose();
    hideModal(`#${id}`);
  }

  render() {
    const { canClose, children, id, onClose, size, title } = this.props;
    return (
      <Portal
        className="modal"
        data-backdrop={canClose || 'static'}
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`${id}-label`}
        aria-hidden="true"
      >
        <div
          className={classNames(
            'modal-dialog modal-dialog-centered',
            SIZES[size],
          )}
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title m-0" id={`${id}-label`}>
                {title}
              </h1>
              {canClose && (
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => onClose()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              )}
            </div>
            {children}
          </div>
        </div>
      </Portal>
    );
  }
}

Modal.propTypes = {
  canClose: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  title: PropTypes.node,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  canClose: true,
  children: null,
  size: 'medium',
  title: null,
  onClose: () => {},
};

export { ModalBody, ModalFooter };
export default Modal;
