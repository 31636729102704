import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './NumberOfPlayersFilterForm.css';

const NumberOfPlayersFilterForm = ({
  savedLowBound,
  savedHighBound,
  onSave,
  onCancel,
}) => {
  const [lowBound, setLowBound] = useState(savedLowBound);
  const [highBound, setHighBound] = useState(savedHighBound);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSave({
      lowBound: lowBound ? Number(lowBound) : null,
      highBound: highBound ? Number(highBound) : null,
    });
  };

  const inputIsValid = () => {
    const lowerNumber = Number(lowBound);
    const higherNumber = Number(highBound);

    if (lowerNumber && higherNumber) return lowerNumber < higherNumber;

    return !!(lowerNumber || higherNumber);
  };

  return (
    <form onSubmit={handleSubmit}>
      <p>
        Between
        <input
          className="number-of-players-filter-form__input form-control ml-1 mr-1"
          data-test="number-of-players-filter-form-low-bound-input"
          onChange={(event) => setLowBound(event.target.value)}
          min="0"
          type="number"
          value={lowBound || ''}
        />
        and
        <input
          className="number-of-players-filter-form__input form-control ml-1 mr-1"
          data-test="number-of-players-filter-form-high-bound-input"
          onChange={(event) => setHighBound(event.target.value)}
          min="0"
          style={{ display: 'inline-block', width: '10rem' }}
          type="number"
          value={highBound || ''}
        />
      </p>
      <div className="text-right">
        <button type="button" className="btn btn-link" onClick={onCancel}>
          Cancel
        </button>
        <button
          data-test="submit-number-of-players-filter"
          className="btn btn-primary"
          disabled={!inputIsValid()}
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
};

NumberOfPlayersFilterForm.propTypes = {
  savedLowBound: PropTypes.number,
  savedHighBound: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

NumberOfPlayersFilterForm.defaultProps = {
  savedHighBound: null,
  savedLowBound: null,
};

export default NumberOfPlayersFilterForm;
