import gql from 'graphql-tag';
import SPONSORSHIP_INSIGHT_REPORT_FRAGMENT from 'fragments/SponsorshipInsightReportFragment';

// eslint-disable-next-line import/prefer-default-export
export const AVAILABLE_SPONSORABLE_PROPERTY_FRAGMENT = gql`
  fragment AvailableSponsorablePropertyFragment on SponsorableProperty {
    id
    city
    lat
    lon
    name
    dataConfidence
    offerCountsReport {
      prospecting
      qualified
      proposed
      confirmed
    }
    organization {
      id
      engagementStatus
      city
      state
      dataConfidence
      sportsManagementSoftwareId
      name
      sponsorshipInsightReport {
        ...SponsorshipInsightReportFragment
      }
      websiteUrl
    }
    sponsorablePropertyPackagesCount
    state
  }
  ${SPONSORSHIP_INSIGHT_REPORT_FRAGMENT}
`;
