import * as React from 'react';
import { Link } from 'react-router-dom';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';

interface Sponsor {
  id: number;
  name: string;
}
interface Campaign {
  id: number;
  name: string;
  sponsor: Sponsor;
}

interface Props {
  campaign: Campaign;
  className?: string;
}

const SponsorAndCampaignLinksHeader: React.FunctionComponent<Props> = ({
  campaign,
  className,
}: Props) => {
  const { id: campaignId, name: campaignName, sponsor } = campaign;
  const { id: sponsorId, name: sponsorName } = sponsor;

  return (
    <p className={className}>
      <Link to={`${CAMPAIGN_PLANNER_PATH}/${sponsorId}`}>{sponsorName}</Link>
      &nbsp;-&nbsp;
      <Link
        to={`${CAMPAIGN_PLANNER_PATH}/${sponsorId}/campaigns/${campaignId}`}
      >
        {campaignName}
      </Link>
    </p>
  );
};

SponsorAndCampaignLinksHeader.defaultProps = {
  className: undefined,
};

export default SponsorAndCampaignLinksHeader;
