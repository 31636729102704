import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { isNil } from 'ramda';
import moment from 'moment';
import PropTypes from 'prop-types';
import WithSaveFooter from 'components/WithSaveFooter';
import RichTextInput from 'components/RichTextInput';
import PercentageInput from 'components/formInputs/PercentageInput';
import PriceInput from 'components/formInputs/PriceInput';
import TextAreaInput from 'components/formInputs/TextAreaInput';
import VisibleToSponsorIndicator from 'components/VisibleToSponsorIndicator';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatCurrency } from 'utils/formatNumber';
import DatePicker from 'components/DatePicker';
import SeasonSelectInput from 'components/formInputs/SeasonSelectInput';
import YearSelectInput from 'components/formInputs/YearSelectInput';

function calculateBudget({ leagueSharePercentage, totalSpend }) {
  if (isNil(leagueSharePercentage) || isNil(totalSpend)) return '';

  return formatCurrency(leagueSharePercentage * totalSpend);
}

export const CampaignTabUnwrapped = (props) => {
  const formGroupClassName = 'col-lg-4 col-xl-3';

  return (
    <WithSaveFooter
      handleMutation={props.onSave} // eslint-disable-line react/destructuring-assignment
      savedValue={props.campaign} // eslint-disable-line react/destructuring-assignment
    >
      {({ addChanges, value: campaign }) => (
        <form className="list-group list-group-flush" id="update-campaign">
          <div className="list-group-item">
            <div className="row">
              <div className="col-lg-4 col-xl-6 form-group">
                <label htmlFor="name" className="font-weight-bold">
                  Campaign Name
                </label>
                <VisibleToSponsorIndicator>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="name"
                    required
                    onChange={({ target: { value } }) =>
                      addChanges({ name: value })
                    }
                    value={campaign.name || ''}
                  />
                </VisibleToSponsorIndicator>
              </div>
              <SeasonSelectInput
                formGroupClassName={formGroupClassName}
                inputSize="lg"
                labelClassName="font-weight-bold"
                onChange={(event) => addChanges({ season: event.target.value })}
                value={campaign.season}
              />
              <YearSelectInput
                addChanges={addChanges}
                formGroupClassName={formGroupClassName}
                inputSize="lg"
                labelClassName="font-weight-bold mb-2"
                onChange={({ target: { value } }) =>
                  addChanges({ year: Number(value) })
                }
                value={campaign.year}
              />
            </div>
            <div className="row">
              <PriceInput
                formGroupClassName={formGroupClassName}
                inputSize="lg"
                label="Total Spend"
                labelClassName="font-weight-bold"
                min={1}
                onChange={({ target: { value } }) =>
                  addChanges({
                    totalSpend: value ? Math.floor(Number(value)) : null,
                  })
                }
                step={1}
                value={campaign.totalSpend}
              />
              <PercentageInput
                formGroupClassName={formGroupClassName}
                inputSize="lg"
                label="League Share Percentage"
                labelClassName="font-weight-bold"
                onChange={(value) =>
                  addChanges({ leagueSharePercentage: value })
                }
                value={campaign.leagueSharePercentage}
              />
              <div className={`${formGroupClassName} form-group`}>
                <label htmlFor="league-budget" className="font-weight-bold">
                  League Budget
                </label>
                <input
                  className="form-control form-control-lg"
                  disabled
                  id="league-budget"
                  value={calculateBudget(campaign)}
                />
              </div>
              <div className={`${formGroupClassName} form-group`}>
                <label
                  htmlFor="target-number-of-leagues"
                  className="font-weight-bold"
                >
                  Desired Leagues
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  id="target-number-of-leagues"
                  onChange={({ target: { value } }) =>
                    addChanges({ targetNumberOfLeagues: Number(value) })
                  }
                  value={campaign.targetNumberOfLeagues || ''}
                />
              </div>
            </div>
          </div>
          <div
            className="list-group-item"
            style={{ position: 'relative', zIndex: '100', borderTop: 'none' }}
          >
            <label
              htmlFor="leagues-confirmed-date-goal"
              className="font-weight-bold"
            >
              League Confirmation Goal
            </label>
            <div>
              <DatePicker
                date={
                  campaign.leaguesConfirmedGoalDate
                    ? moment(campaign.leaguesConfirmedGoalDate)
                    : null
                }
                id="leagues-confirmed-date-goal"
                onDateChange={(value) =>
                  addChanges({ leaguesConfirmedGoalDate: value })
                }
              />
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group">
              <label className="font-weight-bold" htmlFor="goal">
                Campaign Goal
              </label>
              <RichTextInput
                id="goal"
                onChange={(goal) => addChanges({ goal })}
                initialValue={campaign.goal || ''}
              />
            </div>
          </div>
          <div className="list-group-item">
            <TextAreaInput
              helpBlock="Please be aware that this text will be sent directly to any leagues receiving offers. It should be used as a sales pitch for the sponsorship."
              label="Sponsor Overview"
              labelClassName="font-weight-bold"
              onChange={({ target: { value } }) =>
                addChanges({ sponsorOverview: value })
              }
              value={campaign.sponsorOverview || ''}
            />
          </div>
        </form>
      )}
    </WithSaveFooter>
  );
};

CampaignTabUnwrapped.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
  onSave: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation UpdateCampaign($input: CampaignInput!) {
    updateCampaign(input: $input) {
      id
      leagueBudget
      leagueSharePercentage
      goal
      name
      targetNumberOfLeagues
      totalSpend
      leaguesConfirmedGoalDate
      sponsorOverview
      season
      year
    }
  }
`;

const CampaignTab = ({ campaign }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CampaignTabUnwrapped
        campaign={campaign}
        onSave={(input) =>
          mutate({ variables: { input: { ...input, id: campaign.id } } })
        }
      />
    )}
  </Mutation>
);

CampaignTab.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignTab;
