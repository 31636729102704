/* eslint-disable max-len */

import PropTypes from 'prop-types';

const capability = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  digital: PropTypes.bool,
});

const capabilities = PropTypes.arrayOf(capability);

const campaignCapability = PropTypes.shape({
  id: PropTypes.number,
  capability,
});

const campaignCapabilities = PropTypes.arrayOf(campaignCapability);

const campaignGeometry = PropTypes.shape({
  id: PropTypes.number,
  geoJson: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.string,
});

const campaignGeometries = PropTypes.arrayOf(campaignGeometry);

const campaignStatistic = PropTypes.shape({
  description: PropTypes.string,
  iconName: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  number: PropTypes.string,
});

const asset = PropTypes.shape({
  id: PropTypes.number,
  campaignCapability,
});

const competitiveness = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const creativeContentTemplate = PropTypes.shape({
  id: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  renderedContent: PropTypes.string.isRequired,
});

const creativeContentTemplates = PropTypes.arrayOf(creativeContentTemplate);

const creativeFileTemplate = PropTypes.shape({
  id: PropTypes.number.isRequired,
  fullSizeUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
});

const creativeFileTemplates = PropTypes.arrayOf(creativeFileTemplate);

const defaultVerificationDeadline = PropTypes.shape({
  id: PropTypes.number.isRequired,
  creativeContentTemplates: creativeContentTemplates.isRequired,
  creativeFileTemplates: creativeFileTemplates.isRequired,
  deadline: PropTypes.string.isRequired,
  missingCreatives: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  offset: PropTypes.number,
  offsetType: PropTypes.string,
  requiresCreatives: PropTypes.bool.isRequired,
  verificationReminderInstructions: PropTypes.string.isRequired,
});

const defaultVerificationDeadlines = PropTypes.arrayOf(
  defaultVerificationDeadline,
);

const deliverableAddress = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  line1: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  country: PropTypes.string,
});

const eventsSurveyResponse = PropTypes.shape({
  submittedAt: PropTypes.string,
  surveyGizmoResponseId: PropTypes.string,
});

const eventsSurveyResponses = PropTypes.arrayOf(eventsSurveyResponse);

const geometry = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const geometries = PropTypes.arrayOf(geometry);

const genderComposition = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const leaguePackage = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  price: PropTypes.number,
});

const leagueProspectingReport = PropTypes.shape({
  available: PropTypes.number.isRequired,
  confirmed: PropTypes.number.isRequired,
  lastTouchpoint: PropTypes.string,
  proposed: PropTypes.number.isRequired,
  prospecting: PropTypes.number.isRequired,
  qualified: PropTypes.number.isRequired,
  target: PropTypes.number.isRequired,
});

const location = PropTypes.shape({
  id: PropTypes.number.isRequired,
  leagueProspectingReport,
  name: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
});

const locations = PropTypes.arrayOf(location);

const locationFilter = PropTypes.shape({
  id: PropTypes.number.isRequired,
  enabled: PropTypes.bool.isRequired,
  leagueProspectingReport,
  locations,
  name: PropTypes.string.isRequired,
  radius: PropTypes.number.isRequired,
  strategy: PropTypes.string.isRequired,
  strategyCount: PropTypes.number.isRequired,
});

const organizationType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const playLocation = PropTypes.shape({
  address: PropTypes.string,
  id: PropTypes.number,
  nickname: PropTypes.string,
  primary: PropTypes.bool,
});

const organizationPlayLocation = PropTypes.shape({
  id: PropTypes.number,
  primary: PropTypes.bool,
});

const socialMediaChannel = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const socialMediaChannels = PropTypes.arrayOf(socialMediaChannel);

const testimonial = PropTypes.shape({
  attribution: PropTypes.string,
  id: PropTypes.number,
  quote: PropTypes.string,
});

const organizationSocialMediaChannel = PropTypes.shape({
  id: PropTypes.number,
  canPost: PropTypes.bool,
  channel: socialMediaChannel,
  url: PropTypes.shape,
});

const organizationSocialMediaChannels = PropTypes.arrayOf(
  organizationSocialMediaChannel,
);

const sponsor = PropTypes.shape({
  name: PropTypes.string,
});

const sponsors = PropTypes.arrayOf(sponsor);

const sponsorshipInsightReport = PropTypes.shape({
  averageConfirmedValue: PropTypes.number,
  confirmedCount: PropTypes.number,
  leagueId: PropTypes.number,
  onTimeVerificationPercentage: PropTypes.number,
});

const season = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  numberOfPlayers: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  leagueId: PropTypes.number,
  sportId: PropTypes.number,
  competitivenessId: PropTypes.number,
});

const sport = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
});

const user = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
});

const sponsorshipEvent = PropTypes.shape({
  createdAt: PropTypes.string,
  eventType: PropTypes.string,
  user,
  description: PropTypes.string,
  changeReason: PropTypes.string,
});

const preQualSurveyQuestion = PropTypes.shape({
  id: PropTypes.number,
  question: PropTypes.string.isRequired,
});

const preQualSurveyResponse = PropTypes.shape({
  id: PropTypes.number,
  booleanResponse: PropTypes.bool,
  dissentingReason: PropTypes.string,
  preQualSurveyQuestionId: PropTypes.number,
});

const preQualSurvey = PropTypes.shape({
  id: PropTypes.number,
  descriptionToLeague: PropTypes.string,
  campaign: PropTypes.shape({ name: PropTypes.string }),
  questions: PropTypes.arrayOf(preQualSurveyQuestion),
});

const campaign = PropTypes.shape({
  id: PropTypes.number,
  additionalRequirements: PropTypes.string,
  campaignCapabilities,
  campaignGeometries,
  campaignStatistics: PropTypes.arrayOf(campaignStatistic),
  leagueBudget: PropTypes.number,
  leagueProspectingReport,
  locationFilters: PropTypes.arrayOf(locationFilter),
  name: PropTypes.string,
  preQualSurvey,
  season: PropTypes.string,
  sponsor,
  status: PropTypes.string,
  targetNumberOfLeagues: PropTypes.number,
  testimonial,
  year: PropTypes.number,
});

const organization = PropTypes.shape({
  campaigns: PropTypes.arrayOf(campaign),
  eventsSurveyResponses,
  hubspotCompanyId: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  organizationType,
  playLocations: PropTypes.arrayOf(playLocation),
  socialMedia: organizationSocialMediaChannels,
  sponsorshipInsightReport,
  users: PropTypes.arrayOf(user),
  websiteUrl: PropTypes.string,
  zips: PropTypes.arrayOf(PropTypes.string),
  mailingAddress: deliverableAddress,
  socialMediaFollowingSize: PropTypes.number,
  emailListSize: PropTypes.number,
});

const organizations = PropTypes.arrayOf(organization);

const sponsorableProperty = PropTypes.shape({
  id: PropTypes.number,
  competitivenesses: PropTypes.arrayOf(PropTypes.string),
  dataConfidence: PropTypes.string,
  sponsorablePropertyCapabilitiesCount: PropTypes.number,
  sponsorablePropertyPackagesCount: PropTypes.number,
  maxAge: PropTypes.number,
  minAge: PropTypes.number,
  name: PropTypes.string,
  numberOfPlayers: PropTypes.number,
  organization,
  sports: PropTypes.arrayOf(PropTypes.string),
  websiteUrl: PropTypes.string,
});

const sponsorableProperties = PropTypes.arrayOf(sponsorableProperty);

const league = PropTypes.shape({
  campaigns: PropTypes.arrayOf(campaign),
  eventsSurveyResponses,
  hubspotCompanyId: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  organizationType,
  playLocations: PropTypes.arrayOf(playLocation),
  socialMedia: organizationSocialMediaChannels,
  sponsorshipInsightReport,
  users: PropTypes.arrayOf(user),
  websiteUrl: PropTypes.string,
  zips: PropTypes.arrayOf(PropTypes.string),
});

const leagues = PropTypes.arrayOf(league);

const offer = PropTypes.shape({
  id: PropTypes.number,
  league,
  offerOpenedAt: PropTypes.string,
  offerSentAt: PropTypes.string,
  onboardingEmailSentAt: PropTypes.string,
  preQualSurveyResponses: PropTypes.arrayOf(preQualSurveyResponse),
  surveySentAt: PropTypes.string,
  status: PropTypes.string,
  value: PropTypes.number,
  sponsorshipEvent,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
});

const offers = PropTypes.arrayOf(offer);

const radius = PropTypes.shape({
  label: PropTypes.string,
  lat: PropTypes.number,
  lon: PropTypes.number,
  miles: PropTypes.number,
});

const verification = PropTypes.shape({
  campaign,
  id: PropTypes.number,
  league,
  sponsor,
});

const verificationDeadline = PropTypes.shape({
  id: PropTypes.number,
  asset,
  deadline: PropTypes.string,
  name: PropTypes.string.isRequired,
});

const verificationDeadlineCannedResponse = PropTypes.shape({
  id: PropTypes.number,
  response: PropTypes.string,
  selectable: PropTypes.bool,
});

const check = PropTypes.shape({
  id: PropTypes.number,
  amount: PropTypes.number,
});

export default {
  asset,
  campaign,
  campaignCapabilities,
  campaignCapability,
  campaignStatistic,
  capabilities,
  capability,
  check,
  competitiveness,
  defaultVerificationDeadlines,
  deliverableAddress,
  eventsSurveyResponse,
  eventsSurveyResponses,
  geometry,
  geometries,
  genderComposition,
  league,
  leaguePackage,
  leagueProspectingReport,
  leagues,
  organizationSocialMediaChannels,
  location,
  locations,
  locationFilter,
  campaignGeometries,
  campaignGeometry,
  offer,
  offers,
  organization,
  organizations,
  organizationType,
  playLocation,
  organizationPlayLocation,
  preQualSurvey,
  preQualSurveyQuestion,
  preQualSurveyResponse,
  radius,
  socialMediaChannels,
  sponsor,
  sponsorableProperty,
  sponsorableProperties,
  sponsors,
  sponsorshipEvent,
  sponsorshipInsightReport,
  season,
  sport,
  testimonial,
  user,
  verification,
  verificationDeadline,
  verificationDeadlineCannedResponse,
};
