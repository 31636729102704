/* eslint-disable react/no-danger */

import { map } from 'ramda';
import React from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import CircledIcon from 'components/CircledIcon';
import DangerousInnerHtml from 'components/dangerous-inner-html/dangerous-inner-html';

const renderCampaignStatistic = (campaignStatistic) => (
  <div className="col-md-4 my-2 text-center" key={campaignStatistic.id}>
    <CircledIcon
      backgroundColor="success"
      className="mb-2"
      data-test="statistic-icon"
      fontSize={25}
      size={40}
      value={campaignStatistic.iconName}
    />
    <h2 data-test="statistic-number">{campaignStatistic.number}</h2>
    <h5 data-test="statistic-name">{campaignStatistic.name}</h5>
    <div className="text-muted" data-test="statistic-description">
      <DangerousInnerHtml html={campaignStatistic.description} />
    </div>
  </div>
);

const renderCampaignStatistics = map(renderCampaignStatistic);

const CampaignDashboardStatistics = ({ campaign }) => (
  <>
    <h2 className="text-center mb-4">
      {`${campaign.season} ${campaign.year} Results`}
    </h2>
    <div className="row justify-content-around">
      {renderCampaignStatistics(campaign.campaignStatistics)}
    </div>
  </>
);

CampaignDashboardStatistics.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignDashboardStatistics;
