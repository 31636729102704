import React from 'react';
import { map, prop } from 'ramda';
import PropTypes from 'prop-types';
import { seasons } from 'types/season';
import { capitalize } from 'utils/string';

class SeasonFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      winter: props.winter,
      spring: props.spring,
      summer: props.summer,
      fall: props.fall,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateToggleState = this.updateToggleState.bind(this);
  }

  updateToggleState(event) {
    this.setState({ [event.target.id]: event.target.checked });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { props, state } = this;
    const { winter, spring, summer, fall } = state;

    props.onSave({ winter, spring, summer, fall });
  }

  render() {
    const { props, state } = this;

    const renderCheckInput = (season) => (
      <div className="form-check" key={season}>
        <input
          className="form-check-input"
          type="checkbox"
          checked={prop(season, state)}
          onChange={this.updateToggleState}
          id={season}
        />
        <label className="form-check-label" htmlFor={season}>
          {capitalize(season)}
        </label>
      </div>
    );

    return (
      <form onSubmit={this.handleSubmit} className="form-group">
        {map(renderCheckInput, seasons)}
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            data-test="submit-season-filter"
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

SeasonFilterForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  winter: PropTypes.bool,
  spring: PropTypes.bool,
  summer: PropTypes.bool,
  fall: PropTypes.bool,
};

SeasonFilterForm.defaultProps = {
  winter: false,
  spring: false,
  summer: false,
  fall: false,
};

export default SeasonFilterForm;
