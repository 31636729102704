import { Status } from '@teamsnap/snap-ui';
import { Organization } from 'types/offer';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import {
  APPLICATION_NOT_STARTED_VALUE,
  COMPLETION_PERCENTAGE_VALUE,
} from 'pages/qualify/constants';

const COMPLETION_PERCENTAGE_QUERY = gql`
  query Organization(
    $id: Int!
    $season: String!
    $year: Int!
    $campaignId: Int
  ) {
    organization(id: $id) {
      id
      sponsorshipApplication(season: $season, year: $year) {
        id
        completionPercentage(campaignId: $campaignId)
        notInterested
      }
    }
  }
`;

export type CompletionStatusProps = {
  organizationId: number;
  season: string;
  year: number;
  campaignId: number;
  condensed?: boolean;
};

const ApplicationCompletionStatus = (props: CompletionStatusProps) => {
  const { organizationId, season, year, campaignId, condensed } = props;
  const { data } = useQuery<{ organization: Organization }>(
    COMPLETION_PERCENTAGE_QUERY,
    {
      variables: { id: organizationId, season, year, campaignId },
    },
  );

  const completionPercentage =
    data?.organization?.sponsorshipApplication?.completionPercentage ||
    APPLICATION_NOT_STARTED_VALUE;
  const notInterested =
    data?.organization?.sponsorshipApplication?.notInterested;

  const { state, text } = notInterested // eslint-disable-line
    ? {
        state: 'negative',
        text: `Not Interested`,
      }
    : completionPercentage === COMPLETION_PERCENTAGE_VALUE // eslint-disable-line
    ? {
        state: 'success',
        text: condensed ? 'Complete' : `Sponsorship application complete`,
      }
    : completionPercentage === APPLICATION_NOT_STARTED_VALUE
    ? {
        state: 'warning',
        text: condensed ? '0%' : 'Sponsorship application 0% complete',
      }
    : {
        state: 'warning',
        text: condensed
          ? `${completionPercentage}% complete`
          : `Sponsorship application ${completionPercentage}% complete`,
      };

  return (
    <Status
      data-test="sponsorship-application-completion-badge"
      state={state as any}
      text={text}
    />
  );
};

export default ApplicationCompletionStatus;
