export const reducer = (state, action) => {
  switch (action.type) {
    case 'initial data': {
      return action.payload;
    }
    case 'update city': {
      return { ...state, city: action.payload.city };
    }
    case 'update contactEmail': {
      return { ...state, contactEmail: action.payload.contactEmail };
    }
    case 'update contactPhone': {
      return { ...state, contactPhone: action.payload.contactPhone };
    }
    case 'update dataConfidence': {
      return { ...state, dataConfidence: action.payload.dataConfidence };
    }
    case 'update dataConfidenceReason': {
      return {
        ...state,
        dataConfidenceReason: action.payload.dataConfidenceReason,
      };
    }
    case 'update salesforceId': {
      return { ...state, salesforceId: action.payload.salesforceId };
    }
    case 'update sportsManagementSoftwareId': {
      return {
        ...state,
        sportsManagementSoftwareId: action.payload.sportsManagementSoftwareId,
      };
    }
    case 'update name': {
      return { ...state, name: action.payload.name };
    }
    case 'update numberOfUniqueParticipantsPerYear': {
      return {
        ...state,
        numberOfUniqueParticipantsPerYear:
          action.payload.numberOfUniqueParticipantsPerYear,
      };
    }
    case 'update organizationId': {
      return { ...state, organizationId: action.payload.organizationId };
    }
    case 'update organizationTypeId': {
      return {
        ...state,
        organizationTypeId: action.payload.organizationTypeId,
      };
    }
    case 'create socialMedia': {
      return {
        ...state,
        socialMedia: [...state.socialMedia, action.payload.socialMedia],
      };
    }
    case 'update socialMedia channel': {
      state.socialMedia.forEach((obj) => {
        if ((obj.id || obj.temporaryId) === action.payload.id) {
          // eslint-disable-next-line no-param-reassign
          obj.channel.id = action.payload.channelId;
        }
      });
      return { ...state };
    }
    case 'update socialMedia url': {
      state.socialMedia.forEach((obj) => {
        if ((obj.id || obj.temporaryId) === action.payload.id) {
          // eslint-disable-next-line no-param-reassign
          obj.url = action.payload.url;
        }
      });
      return { ...state };
    }
    case 'update socialMedia canPost': {
      state.socialMedia.forEach((obj) => {
        if ((obj.id || obj.temporaryId) === action.payload.id) {
          // eslint-disable-next-line no-param-reassign
          obj.canPost = action.payload.canPost;
        }
      });
      return { ...state };
    }
    case 'remove socialMedia': {
      return {
        ...state,
        socialMedia: state.socialMedia.filter(
          (obj) => (obj.id || obj.temporaryId) !== action.payload.id,
        ),
      };
    }
    case 'update state': {
      return { ...state, state: action.payload.state };
    }
    case 'update websiteUrl': {
      return { ...state, websiteUrl: action.payload.websiteUrl };
    }
    case 'update websiteSponsorshipsUrl': {
      return {
        ...state,
        websiteSponsorshipsUrl: action.payload.websiteSponsorshipsUrl,
      };
    }
    case 'update followingSize': {
      return {
        ...state,
        socialMediaFollowingSize: action.payload.socialMediaFollowingSize,
      };
    }
    case 'update emailListSize': {
      return { ...state, emailListSize: action.payload.emailListSize };
    }
    default:
      return state;
  }
};

export default reducer;
