import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink, Switch, Route, withRouter } from 'react-router-dom';
import { getAuthentication } from 'modules';
import FeatureFlag from 'components/FeatureFlag';
import { getRole } from 'modules/authentication';
import classNames from 'classnames';

export const Section = ({ children }) => children;

Section.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  restrictToRoles: PropTypes.arrayOf(PropTypes.string),
  path: PropTypes.string,
};

Section.defaultProps = {
  path: null,
};

function joinPath(url, path) {
  const urlWithoutBlackSlash =
    url && url.endsWith('/') ? url.slice(0, -1) : url;
  const pathParts = [urlWithoutBlackSlash];
  if (path) {
    pathParts.push(path);
  }

  return pathParts.join('/');
}

function SectionsUnwrapped({
  append,
  children,
  match,
  noContainingCard,
  isSeasonalDashboardSections,
  colWidth,
  userRole,
}) {
  function getAvailableSections() {
    if (userRole === 'superadmin') return React.Children.toArray(children);

    function isAvailable(section) {
      return (
        !section.props.restrictToRoles ||
        section.props.restrictToRoles.includes(userRole)
      );
    }

    return React.Children.toArray(children).filter(isAvailable);
  }

  function renderButton(section) {
    return (
      <NavLink
        className="list-group-item list-group-item-action"
        activeClassName="active"
        data-test={`section: ${section.props.name}`}
        key={section.props.name}
        to={joinPath(match.url, section.props.path)}
        exact
      >
        {section.props.name}
      </NavLink>
    );
  }

  function renderSection(section) {
    return (
      <Route
        key={section.props.path}
        exact
        path={joinPath(match.path, section.props.path)}
      >
        {section}
      </Route>
    );
  }

  function renderSections(sections) {
    const nullPathsLast = (a, b) =>
      (a.props.path === null) - (b.props.path === null);

    return sections.sort(nullPathsLast).map(renderSection);
  }

  function renderSectionButton(section) {
    const button = renderButton(section);
    return section.props.featureFlag ? (
      <FeatureFlag key={section.props.name} name={section.props.featureFlag}>
        {button}
      </FeatureFlag>
    ) : (
      button
    );
  }

  const availableSections = getAvailableSections();

  return (
    <div className="row">
      <div className="col-md-3">
        <div
          className={classNames('card', {
            'seasonal-dashboard-sections-sizing': isSeasonalDashboardSections,
          })}
        >
          <div className="list-group list-group-flush">
            {availableSections.map(renderSectionButton)}
          </div>
        </div>
        {append}
      </div>
      <div className={`col-md-${colWidth || 9}`}>
        <div className={noContainingCard ? null : 'card mb-4'}>
          <Switch>{renderSections(availableSections)}</Switch>
        </div>
      </div>
    </div>
  );
}

SectionsUnwrapped.propTypes = {
  append: PropTypes.node,
  children: PropTypes.node.isRequired,
  noContainingCard: PropTypes.bool,
  isSeasonalDashboardSections: PropTypes.bool,
  colWidth: PropTypes.string,
  userRole: PropTypes.string.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
  }).isRequired,
};

SectionsUnwrapped.defaultProps = {
  append: null,
  noContainingCard: false,
  isSeasonalDashboardSections: false,
  colWidth: null,
};

const mapStateToProps = (state) => ({
  userRole: getRole(getAuthentication(state)),
});

const Sections = withRouter(connect(mapStateToProps)(SectionsUnwrapped));

export default Sections;
