import { useEffect, useState } from 'react';
import { LabelButton } from '@teamsnap/snap-ui';
import { createPortal } from 'react-dom';
import { useWizardContext } from '../provider';
import { StepTypeEnum } from '../types';

type WizardFooterProps = {
  onSubmit: any;
  nextLabel?: string;
};

export default function WizardFooter(props: WizardFooterProps) {
  const { onSubmit, nextLabel = 'Next' } = props;
  const [footerBox, setFooterBox] = useState<HTMLElement | null>(null);
  const {
    handlePreviousStep,
    wizardMap,
    activeSectionIndex,
    activeStepIndex,
    formState: { isSubmitting },
  } = useWizardContext();

  useEffect(() => {
    setFooterBox(document.getElementById('WIZARD_FOOTER_BOX'));
  }, []);
  if (!footerBox || !wizardMap) return null;
  const wizardMapKeys = wizardMap && (Object.keys(wizardMap) as StepTypeEnum[]);

  // Note: Render via portal because the footer goes outside the wizardBody container
  return createPortal(
    <div className="sui-max-w-w sui-mx-auto sui-flex sui-py-3 sui-px-2">
      {(activeSectionIndex > 0 || activeStepIndex > 0) && (
        <LabelButton
          icon="keyboard_backspace"
          iconPosition="left"
          labelText="Back"
          variantType="secondary"
          onClick={handlePreviousStep}
        />
      )}

      {(activeSectionIndex < wizardMapKeys.length - 1 ||
        activeStepIndex <
          wizardMap[wizardMapKeys[activeSectionIndex]].steps.length - 1) && (
        <LabelButton
          icon="arrow_forward"
          iconPosition="right"
          labelText={isSubmitting ? 'Saving...' : nextLabel}
          onClick={onSubmit}
          className="sui-ml-auto"
          disabled={isSubmitting}
          data-testid="wizard-next-button"
        />
      )}
    </div>,
    footerBox,
  );
}

// // TODO: Find a way to proper type this recursive function
// const renderErrorMessages = (errors: any): any => {
//   return Object.keys(errors).map((key) => {
//     if (typeof (errors[key] as FieldError[])?.length === 'number') {
//       return errors[key]?.map((nestedError: any) =>
//         renderErrorMessages(nestedError),
//       );
//     }
//     return (
//       <p
//         key={key}
//         className="sui-text-red sui-mb-1"
//         data-testid="wizard-error-message"
//       >
//         {errors[key]?.message}
//       </p>
//     );
//   });
// };
