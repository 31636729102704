import * as React from 'react';
import { sortWithDeadlineAndName } from 'utils/ts/sort';
import { Season } from 'types/season';
import GenericCreativeContent from 'types/genericCreativeContent';
import GenericCreativeFile from 'types/genericCreativeFile';
import { VerificationDeadlineWithAdditionalStatuses } from 'types/verificationDeadline';
import ChecksProgressBar from 'components/ChecksProgressBar';
import MissingAddressAlert from 'components/MissingAddressAlert';
import AdditionalRequirements from './AdditionalRequirements';
import VerificationDeadlinesSections from './VerificationDeadlinesSections';

interface Capability {
  digital: boolean;
}
interface CampaignCapability {
  capability: Capability;
}
interface Asset {
  campaignCapability: CampaignCapability;
  quantity: number;
}

interface Sponsor {
  name: string;
}
interface Campaign {
  additionalRequirements: string;
  season: Season;
  sponsor: Sponsor;
  year: number;
}

interface Organization {
  id: number;
  mailingAddress: any;
}
interface SponsorableProperty {
  id: number;
  organization: Organization;
}

interface VerificationDeadline
  extends VerificationDeadlineWithAdditionalStatuses {
  creativeContents: GenericCreativeContent[];
  creativeFiles: GenericCreativeFile[];
  renderedVerificationReminderInstructions: string;
}

interface Offer {
  additionalRequirements: string;
  assets: Asset[];
  campaign: Campaign;
  sponsorableProperty: SponsorableProperty;
  verificationDeadlines: VerificationDeadline[];
}

interface Props {
  offer: Offer;
  refetch: Function;
  presenterFactory: Object;
}

const ConfirmedOffer: React.FunctionComponent<Props> = ({
  offer,
  refetch,
  presenterFactory,
}: Props) => {
  const { campaign, sponsorableProperty, verificationDeadlines } = offer;
  const sortedVerificationDeadlines = sortWithDeadlineAndName(
    verificationDeadlines,
  );
  const { sponsor, season, year } = campaign;

  return (
    <>
      <div className="row align-items-center">
        <div
          className="col-auto text-capitalize my-2"
          data-test="campaign-details"
        >
          <h2 className="mb-0">{sponsor.name}</h2>
          <strong>{`${season} ${year}`}</strong>
        </div>
        <div className="col-12 col-sm my-2">
          <ChecksProgressBar organizationDashboard offer={offer} />
        </div>
      </div>
      <MissingAddressAlert
        organization={sponsorableProperty.organization}
        offers={[offer]}
      />
      <p className="py-4 mb-1">
        To verify that you&#39;ve completed each task, please send pictures,
        screenshots, or emails directly to&nbsp;
        <a href="mailto:support@sponsorship.teamsnap.com">
          support@sponsorship.teamsnap.com
        </a>
        &nbsp;or upload them directly to the relevant task below.
      </p>
      <AdditionalRequirements offer={offer} />
      <VerificationDeadlinesSections
        verificationDeadlines={sortedVerificationDeadlines}
        refetch={refetch}
        presenterFactory={presenterFactory}
      />
    </>
  );
};

export default ConfirmedOffer;
