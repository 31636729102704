import React from 'react';
import {
  __,
  always,
  cond,
  contains,
  difference,
  isEmpty,
  pipe,
  prepend,
  propEq,
  T,
} from 'ramda';
import PropTypes from 'prop-types';
import { joinWithComma, replaceUnderscoresWithSpaces } from 'utils/string';
import { ADDED, REMOVED } from 'utils/offer';
import FilterDropdown from './FilterDropdown';

const allSelected = (options) => propEq('length', options.length);

const abbreviateSelectedStatuses =
  (statusesToAbbreviate, abbreviation) => (selectedStatuses) => {
    const canShorten = pipe(difference(statusesToAbbreviate), isEmpty);
    const updatedStatuses = pipe(
      difference(__, statusesToAbbreviate),
      prepend(abbreviation),
    );

    return canShorten(selectedStatuses)
      ? updatedStatuses(selectedStatuses)
      : selectedStatuses;
  };
const joinFilterText = pipe(
  abbreviateSelectedStatuses(REMOVED, 'Removed'),
  abbreviateSelectedStatuses(ADDED, 'Added'),
  joinWithComma,
);
const filterText = (allOptionsSelected) =>
  cond([
    [allOptionsSelected, always('All')],
    [isEmpty, always('None')],
    [T, joinFilterText],
  ]);
const formatList = (options, selected) =>
  replaceUnderscoresWithSpaces(filterText(allSelected(options))(selected));

const MultipleChoiceFilterDropdown = ({
  label,
  onChange,
  options,
  selected,
}) => (
  <FilterDropdown
    displayList={formatList(options, selected)}
    isChecked={(option) => contains(option, selected)}
    label={label}
    onChange={onChange}
    options={options}
  />
);

MultipleChoiceFilterDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MultipleChoiceFilterDropdown;
