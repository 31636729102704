import React from 'react';
import Paragraph from './Paragraph';

const Finished = () => (
  <div className="container" id="finished">
    <Paragraph bold>All done!</Paragraph>
    <Paragraph>
      Thank you for taking the time to share this information with us. It is
      extremely helpful when matching your organization with great sponsors.
      Keep an eye on your inbox for sponsorship offers!
    </Paragraph>
  </div>
);

export default Finished;
