import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from './ProgressBar';

const NestedProgressBar = ({ confirmed, maximum, pending }) => (
  <ProgressBar>
    <ProgressBar maximum={maximum} value={confirmed} />
    <ProgressBar
      color="warning"
      maximum={maximum}
      value={Math.min(pending, maximum - confirmed)}
    />
  </ProgressBar>
);

NestedProgressBar.propTypes = {
  confirmed: PropTypes.number.isRequired,
  maximum: PropTypes.number.isRequired,
  pending: PropTypes.number.isRequired,
};

export default NestedProgressBar;
