import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { map, uniq } from 'ramda';
import gql from 'graphql-tag';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { parseZips } from 'utils/zipCode';
import PLAY_LOCATION_FRAGMENT from 'fragments/PlayLocationFragment';
import RestrictTo from 'components/RestrictTo';
import AddPlayLocationModal from './playLocationsTab/AddPlayLocationModal';
import DeletePlayLocationModal from './playLocationsTab/DeletePlayLocationModal';

const emptyPlayLocationMsg = (
  <div className="col text-center mb-2">
    <strong className="font-weight-bold">
      There is no play location data for this organization
    </strong>
  </div>
);

const AddZipForm = styled.form`
  display: inline-block;
  width: 15rem;
`;

class PlayLocationsTabUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = { newZips: '' };

    this.handleAddZips = this.handleAddZips.bind(this);
    this.renderPlayLocationCard = this.renderPlayLocationCard.bind(this);
    this.updateNewZips = this.updateNewZips.bind(this);
  }

  handleAddZips() {
    const { addZips } = this.props;

    return (event) => {
      event.preventDefault();
      const { newZips } = this.state;
      const parsedZips = parseZips(newZips);
      addZips(uniq([...parsedZips]));
      this.setState({ newZips: '' });
    };
  }

  updateNewZips(event) {
    this.setState({ newZips: event.target.value });
  }

  // eslint-disable-next-line class-methods-use-this
  renderPlayLocationCard({ address, id, nickname }) {
    return (
      <div key={id} className="col-sm-6" data-test={`play-location-card-${id}`}>
        <div className="card mb-3">
          <h6 className="card-header">{nickname}</h6>
          <div className="card-body">{address}</div>
          <div className="card-footer">
            <div className="justified-content">
              <DeletePlayLocationModal id={id} nickname={nickname} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { props, state } = this;
    const { organization, removeZip } = props;
    const { id: organizationId, playLocations, zips } = organization;

    function renderZipButton(zip) {
      return (
        <button
          type="button"
          key={zip}
          id={`zip${zip}`}
          className="btn btn-secondary mr-2 my-1"
          onClick={() => removeZip(zip)}
        >
          {zip}
          <small className="ml-2">x</small>
        </button>
      );
    }

    return (
      <div className="list-group list-group-flush">
        <RestrictTo roles={['admin']}>
          <div className="list-group-item">
            <strong className="d-block mb-2">Zip Codes</strong>
            {!!zips && map(renderZipButton, zips)}
            <AddZipForm id="addNewZip" onSubmit={this.handleAddZips()}>
              <div className="input-group">
                <input
                  className="form-control"
                  id="newZipInput"
                  onChange={this.updateNewZips}
                  placeholder="Enter five-digit zip"
                  value={state.newZips}
                />
                <div className="input-group-append">
                  <button className="btn btn-secondary" type="submit">
                    + Add
                  </button>
                </div>
              </div>
            </AddZipForm>
          </div>
        </RestrictTo>
        <div className="list-group-item">
          <div className="row align-items-center mb-2">
            <strong className="col">Play Locations</strong>
            <div className="col-auto">
              <AddPlayLocationModal organizationId={organizationId} />
            </div>
          </div>
          <div className="row">
            {playLocations.length
              ? map(this.renderPlayLocationCard, playLocations)
              : emptyPlayLocationMsg}
          </div>
        </div>
      </div>
    );
  }
}

PlayLocationsTabUnwrapped.propTypes = {
  addZips: PropTypes.func.isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    playLocations: PropTypes.arrayOf(LeagueSidePropTypes.playLocation),
    organizationPlayLocations: PropTypes.arrayOf(
      LeagueSidePropTypes.organizationPlayLocation,
    ),
    zips: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  removeZip: PropTypes.func.isRequired,
};

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      playLocations {
        ...PlayLocationFragment
      }
      zips
    }
  }
  ${PLAY_LOCATION_FRAGMENT}
`;

const ADD_ZIPS_MUTATION = gql`
  mutation AddZips($input: AddZipsInput!) {
    addZips(input: $input) {
      organization {
        id
        zips
      }
    }
  }
`;

const REMOVE_ZIP_MUTATION = gql`
  mutation RemoveZip($input: RemoveZipInput!) {
    removeZip(input: $input) {
      organization {
        id
        zips
      }
    }
  }
`;

const PlayLocationsTab = ({ organizationId }) => {
  const { data, loading } = useQuery(ORGANIZATION_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: organizationId },
  });
  const [mutateAddZips] = useMutation(ADD_ZIPS_MUTATION);
  const [mutateRemoveZip] = useMutation(REMOVE_ZIP_MUTATION);

  const organization = data && data.organization;

  return !loading && organization ? (
    <PlayLocationsTabUnwrapped
      addZips={(zips) =>
        mutateAddZips({ variables: { input: { organizationId, zips } } })
      }
      organization={organization}
      removeZip={(zip) =>
        mutateRemoveZip({ variables: { input: { organizationId, zip } } })
      }
    />
  ) : null;
};

PlayLocationsTab.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export { PlayLocationsTabUnwrapped };
export default PlayLocationsTab;
