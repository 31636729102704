import { map, pathOr } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import GoogleMap from 'components/GoogleMap';
import PlacesAutocomplete from 'react-places-autocomplete';
import classNames from 'classnames';
import Paragraph from './Paragraph';

const playLocationAddress = pathOr('', ['playLocation', 'address']);

const suggestionClassName = ({ active }) =>
  classNames('list-group-item list-group-item-action', { active });

class PrimaryPlayLocation extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: playLocationAddress(props.league) };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  getDerivedStateFromProps({ league }) {
    const value = playLocationAddress(league);
    if (value) this.setState({ value: playLocationAddress(league) });
  }

  handleSelect(value) {
    const { addChanges } = this.props;
    addChanges({
      playLocation: { address: value, nickname: 'Default Nickname' },
    });
    this.setState({ value });
  }

  handleValueChange(value) {
    const { addChanges } = this.props;
    addChanges({ playLocation: null });
    this.setState({ value });
  }

  render() {
    const { league } = this.props;
    const { value } = this.state;

    return (
      <div className="container">
        <label htmlFor="playLocation">
          <Paragraph bold>
            At what field or facility do you play your games?
          </Paragraph>
          <Paragraph>
            Please enter the field or facility address where you play the
            majority of your games and verify on the map that the address looks
            correct.
          </Paragraph>
        </label>
        <PlacesAutocomplete
          className="form-group"
          data-test="places-autocomplete-input"
          highlightFirstSuggestion
          onChange={this.handleValueChange}
          onSelect={this.handleSelect}
          shouldFetchSuggestions
          value={value}
        >
          {({ getInputProps, getSuggestionItemProps, suggestions }) => (
            <>
              <input
                {...getInputProps({
                  className: 'form-control form-control-lg',
                  id: 'playLocation',
                })}
              />
              <div className="autocomplete-dropdown-container mt-2">
                <ul className="list-group">
                  {map(
                    (suggestion) => (
                      <li
                        key={suggestion.id}
                        {...getSuggestionItemProps(suggestion, {
                          className: suggestionClassName(suggestion),
                        })}
                      >
                        {suggestion.description}
                      </li>
                    ),
                    suggestions,
                  )}
                </ul>
              </div>
            </>
          )}
        </PlacesAutocomplete>
        <div className="card">
          <GoogleMap
            address={playLocationAddress(league) || 'United States of America'}
          />
        </div>
      </div>
    );
  }
}

PrimaryPlayLocation.propTypes = {
  addChanges: PropTypes.func.isRequired,
  league: LeagueSidePropTypes.league.isRequired,
};

PrimaryPlayLocation.getValidationError = ({ league }) =>
  league.playLocation
    ? null
    : "We need your league's primary play location because geographic location is extremely important to sponsors.";

export default PrimaryPlayLocation;
