import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import CheckModalTable from 'components/CheckModalTable';
import Icon from 'components/Icon';
import RestrictTo from 'components/RestrictTo';

export const RejectCheckModalUnwrapped = ({ check, reject }) => {
  const [reason, setReason] = useState('');
  const { error, loading, handleSubmit } = useSubmit(() => reject(reason));

  const renderBody = (
    <>
      <p>Are you sure you want to reject the following check?</p>
      <CheckModalTable check={check} />
      <div className="form-group">
        <label htmlFor={`rejection-reason-${check.id}`}>Rejection Reason</label>
        <input
          className="form-control"
          id={`rejection-reason-${check.id}`}
          onChange={(event) => setReason(event.target.value)}
          required
          type="text"
          value={reason}
        />
      </div>
    </>
  );

  return (
    <RestrictTo roles={['superadmin']}>
      <ModalForm
        id={`reject-check-modal-${check.id}`}
        error={error}
        loading={loading}
        onSubmit={handleSubmit}
        submitProps={{
          className: classNames('btn btn-primary', { 'font-italic': loading }),
          children: loadingTense(loading, 'Reject Check'),
          disabled: !reason || loading,
        }}
        toggleProps={{
          className: 'btn btn-outline-danger ml-2',
          children: (
            <>
              <Icon className="mr-1" value="cross" />
              Reject
            </>
          ),
        }}
        title="Reject Check"
      >
        {renderBody}
      </ModalForm>
    </RestrictTo>
  );
};

RejectCheckModalUnwrapped.propTypes = {
  check: LeagueSidePropTypes.check.isRequired,
  reject: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation RejectCheck($input: RejectCheckInput!) {
    rejectCheck(input: $input) {
      id
      status
    }
  }
`;

const RejectCheckModal = ({ check }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <RejectCheckModalUnwrapped
        check={check}
        reject={(reason) =>
          mutate({ variables: { input: { checkId: check.id, reason } } })
        }
      />
    )}
  </Mutation>
);

RejectCheckModal.propTypes = {
  check: LeagueSidePropTypes.check.isRequired,
};

export default RejectCheckModal;
