import { map } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import classNames from 'classnames';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';

const CAMPAIGN_STATUS = [
  'proposed',
  'contracted',
  'active',
  'complete',
  'rejected',
];

const renderOption = (option) => (
  <option
    data-test={`campaign-status-select-${option}`}
    key={option}
    value={option}
  >
    {option}
  </option>
);

const renderOptions = map(renderOption);

export const CampaignStatusSelectUnwrapped = ({ status, onChange }) => (
  <select
    className={classNames(
      'btn btn-sm ml-2 text-capitalize sui-text-desktop-5 sui-ml-2',
      {
        'btn-primary':
          status === 'active' ||
          status === 'proposed' ||
          status === 'contracted',
      },
    )}
    id="campaignStatus"
    data-test="campaign-status-select"
    onChange={(event) => onChange(event.target.value)}
    value={status}
  >
    {renderOptions(CAMPAIGN_STATUS)}
  </select>
);

CampaignStatusSelectUnwrapped.propTypes = {
  status: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation UpdateCampaignStatus($id: Int!, $status: CampaignStatus!) {
    updateCampaign(input: { id: $id, status: $status }) {
      id
      status
    }
  }
`;

const CampaignStatusSelect = ({ campaign }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CampaignStatusSelectUnwrapped
        status={campaign.status}
        onChange={(status) =>
          mutate({
            variables: {
              id: campaign.id,
              status,
            },
          })
        }
      />
    )}
  </Mutation>
);

CampaignStatusSelect.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignStatusSelect;
