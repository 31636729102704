import gql from 'graphql-tag';

const SPORT_FRAGMENT = gql`
  fragment SportFragment on Sport {
    id
    name
  }
`;

export default SPORT_FRAGMENT;
