import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { loadingTense } from 'utils/string';
import {
  addressOnly,
  validatedAddressMessage,
  validatedAddressErrorMessage,
} from 'utils/deliverableAddress';
import DeliverableAddressFormInputs from 'components/DeliverableAddressFormInputs';
import classNames from 'classnames';
import Icon from 'components/Icon';
import Alert from 'components/Alert';
import ErrorAlert from 'components/ErrorAlert';
import CONTENT from './CONTENT';

const ValidateDeliverableAddressContent = ({
  addressType,
  organization,
  verifyAddress,
  upsertAddress,
  setContent,
  address,
  setAddress,
}) => {
  const [loading, setLoading] = useState(false);
  const [addressVerified, setAddressVerified] = useState(false);
  const [verifiedAddress, setVerifiedAddress] = useState(null);
  const [inputValidationError, setInputValidationError] = useState(null);
  const [lobError, setLobError] = useState(null);

  const shouldDisableSave = !addressVerified;

  const validateAddressInputs = (input) => {
    const cloneInput = { ...input };
    delete cloneInput.line2;

    let isInValid = false;
    const invalidInputs = [];
    const errorMessage = () =>
      `The following inputs are required to validate address: ${invalidInputs.join(
        ', ',
      )}`;

    Object.keys(cloneInput).forEach((key) => {
      if (!cloneInput[key]) {
        invalidInputs.push(key);
        isInValid = true;
      }
    });

    return { isInValid, message: errorMessage() };
  };

  useEffect(() => {
    if (JSON.stringify(verifiedAddress) !== JSON.stringify(address)) {
      setAddressVerified(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  async function handleValidateDeliverableAddressSubmit(event) {
    event.preventDefault();

    setLoading(true);
    setAddressVerified(false);
    const getAddressOnly = addressOnly(address);
    const { isInValid, message } = validateAddressInputs(getAddressOnly);
    let newData;

    if (isInValid) {
      setInputValidationError(message);
      setLoading(false);
      return;
    }

    setInputValidationError(null);

    try {
      newData = await verifyAddress(getAddressOnly);

      setVerifiedAddress(newData.verifyAddressFromOffer);
      setAddressVerified(true);
    } catch (exception) {
      setLobError(exception.message.replace(/GraphQL error:/, ''));
      setLoading(false);
      return;
    }

    setLobError(null);
    setAddress(newData.verifyAddressFromOffer);
    setLoading(false);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    await upsertAddress({
      ...addressOnly(verifiedAddress),
      deliverability: verifiedAddress.deliverability,
      addressType,
      organizationId: organization.id,
    });
    setContent(CONTENT.REVIEW_DETAILS);

    setLoading(false);
  }

  const addressVerifiedAlert = (
    <Alert alertStyle="success" role="alert" data-test="address-verified-alert">
      {validatedAddressMessage}
    </Alert>
  );
  const invalidInputErrorAlert = (
    <ErrorAlert role="alert" data-test="address-error-alert">
      {inputValidationError || validatedAddressErrorMessage}
    </ErrorAlert>
  );
  const lobErrorAlert = (
    <ErrorAlert role="alert" data-test="address-error-alert">
      {lobError}
    </ErrorAlert>
  );

  return (
    <form onSubmit={handleSubmit}>
      {addressVerified && addressVerifiedAlert}
      {lobError && lobErrorAlert}
      {inputValidationError && invalidInputErrorAlert}
      <DeliverableAddressFormInputs
        deliverableAddress={address}
        disabled={loading}
        inputLabel={`${addressType}-address`}
        setDeliverableAddress={setAddress}
      />
      <div className="mt-3 text-right">
        <button
          className={classNames('btn btn-primary mr-2', { 'btn-lg': false })}
          data-test="validate-button"
          type="button"
          disabled={addressVerified}
          onClick={handleValidateDeliverableAddressSubmit}
        >
          {loadingTense(loading, 'Validate')}
        </button>
        <button
          className={classNames('btn btn-primary', { 'btn-lg': false })}
          data-test="save-button"
          type="submit"
          disabled={shouldDisableSave}
          onClick={() => {}}
        >
          {loading ? 'Loading...' : 'Review Offer Details'}
          {!loading && <Icon className="ml-2" value="arrow-right" />}
        </button>
      </div>
    </form>
  );
};

ValidateDeliverableAddressContent.propTypes = {
  addressType: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    mailingAddress: LeagueSidePropTypes.deliverableAddress,
    name: PropTypes.string.isRequired,
    shippingAddress: LeagueSidePropTypes.deliverableAddress,
    id: PropTypes.number.isRequired,
  }).isRequired,
  setContent: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  address: PropTypes.object.isRequired,
  setAddress: PropTypes.func.isRequired,
  upsertAddress: PropTypes.func.isRequired,
  verifyAddress: PropTypes.func.isRequired,
};

export default ValidateDeliverableAddressContent;
