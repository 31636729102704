import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import ContactUsLink from 'components/ContactUsLink';
import ErrorAlert from 'components/ErrorAlert';

const errorMessage = (
  <ErrorAlert className="mt-3">
    Oops! We had trouble rejecting the offer.&nbsp; Please check your internet
    connection and try again. If the problem persists, please&nbsp;
    <ContactUsLink />.
  </ErrorAlert>
);

const RejectOfferModal = ({ onReject, rejectOffer }) => {
  const [leagueRejectionReason, setLeagueRejectionReason] = useState('');
  const reject = () => rejectOffer(leagueRejectionReason).then(onReject);
  const { error, loading, handleSubmit } = useSubmit(reject);

  return (
    <ModalForm
      id="reject-offer"
      error={error}
      errorMessage={errorMessage}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        className: classNames('btn btn-danger', { 'font-italic': loading }),
        children: (
          <>
            {loading ? null : <Icon className="mr-1" value="cross" />}
            {loadingTense(loading, 'Reject Offer')}
          </>
        ),
        disabled: !leagueRejectionReason || loading,
      }}
      toggleProps={{
        className: 'btn btn-secondary ml-2 my-1',
        children: (
          <>
            <Icon className="mr-1" value="cross" />
            Reject
          </>
        ),
      }}
      title="Reject Offer?"
    >
      <label htmlFor="rejection-reason">
        Please provide us with a brief explanation of why you are rejecting this
        offer:
      </label>
      <textarea
        className="form-control"
        id="rejection-reason"
        onChange={(event) => setLeagueRejectionReason(event.target.value)}
        value={leagueRejectionReason}
        disabled={loading}
      />
    </ModalForm>
  );
};

RejectOfferModal.propTypes = {
  onReject: PropTypes.func.isRequired,
  rejectOffer: PropTypes.func.isRequired,
};

export default RejectOfferModal;
