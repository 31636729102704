import NumberInput from 'components/formInputs/NumberInput';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import TextAreaInput from 'components/formInputs/TextAreaInput';

const MUTATION = gql`
  mutation CreateDefaultCapability($input: CreateOrganizationCapabilityInput!) {
    createOrganizationCapability(input: $input) {
      organization {
        id
        name
        organizationCapabilities {
          id
        }
      }
    }
  }
`;

const DefaultCapabilityForm = ({
  organization,
  capabilityId,
  capabilityName,
  redirect,
}) => {
  const [mutate] = useMutation(MUTATION);

  const [estimatedPriceForPlaque, setEstimatedPriceForPlaque] = useState(null);
  const [maximumNumberOfPlaques, setMaximumNumberOfPlaques] = useState(null);
  const [description, setDescription] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    await mutate({
      variables: {
        input: {
          organizationId: organization.id,
          capabilityId,
          price: estimatedPriceForPlaque,
          maxForSale: maximumNumberOfPlaques,
          description,
        },
      },
    });

    redirect();
  };

  return (
    <form data-target="game-signage-form" onSubmit={onSubmit}>
      <NumberInput
        label={`What is the estimated price to sponsor 1 ${capabilityName}?`}
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) =>
          setEstimatedPriceForPlaque(Number(value))
        }
        customId="estimated-price-of-plaque-input"
        required
        step={1}
        value={estimatedPriceForPlaque}
        placeholder="Please enter the estimated price"
      />
      <NumberInput
        label={`What is the maximum number of ${capabilityName} we can sell?`}
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) =>
          setMaximumNumberOfPlaques(Number(value))
        }
        customId="maximum-number-of-plaques-we-could-sell-input"
        required
        step={1}
        value={maximumNumberOfPlaques}
        placeholder="Please enter the maximum number"
      />
      <TextAreaInput
        label="Description"
        labelClassName="font-weight-bold"
        onChange={({ target: { value } }) => setDescription(value)}
        value={description || ''}
        required
      />
      <button
        type="submit"
        className="btn btn-primary"
        data-test="default-capability-form-submit-button"
      >
        Submit
      </button>
    </form>
  );
};

DefaultCapabilityForm.propTypes = {
  organization: LeagueSidePropTypes.organization.isRequired,
  capabilityId: PropTypes.number.isRequired,
  capabilityName: PropTypes.string.isRequired,
  redirect: PropTypes.func.isRequired,
};

export default DefaultCapabilityForm;
