import { map } from 'ramda';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import CsvInput from 'components/formInputs/CsvInput';
import ModalForm from 'components/ModalForm';
import leaguesideApi from 'utils/leagueside-api';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import RefetchDataContext from './RefetchDataContext';

function CreateLocationsByCsvModal({ locationFilterId }) {
  const [file, setFile] = useState(null);
  const refetchData = useContext(RefetchDataContext);

  function onSubmit() {
    return leaguesideApi
      .addCsvLocationsToSearchFilter(locationFilterId, file)
      .then(refetchData);
  }

  const { error, loading, handleSubmit } = useSubmit(onSubmit);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  return (
    <ModalForm
      id={`create-csv-locations-${locationFilterId}`}
      error={error}
      errorMessage={
        error && (
          <div className="alert alert-danger mb-0 mt-3">
            <ul className="mb-0">
              {map(
                (e) => (
                  <li key={e}>{e}</li>
                ),
                error,
              )}
            </ul>
          </div>
        )
      }
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Upload'),
        disabled: !file || loading,
      }}
      title="Upload Location CSV"
      toggleProps={{
        className: 'dropdown-item',
        children: 'CSV',
      }}
    >
      <p>
        Please upload your location CSV file.&nbsp; In order for us to import
        the locations, it should have some combination of the following columns:
      </p>
      <p>
        <code>address, address_1, address_2, city, state, zip</code>
      </p>
      <p>
        You do not need to provide all of these columns.&nbsp; Lists of
        city/state combinations and zip codes are acceptable. You may also
        include a&nbsp;
        <code>name</code>
        &nbsp;for identification purposes.
      </p>
      <CsvInput
        disabled={loading}
        required
        onChange={handleChange} // eslint-disable-line react/jsx-no-bind
      />
    </ModalForm>
  );
}

CreateLocationsByCsvModal.propTypes = {
  locationFilterId: PropTypes.number.isRequired,
};

export default CreateLocationsByCsvModal;
