import React from 'react';
import PropTypes from 'prop-types';
import ModalSpinner from 'components/ModalSpinner';
import useInputValue from 'utils/useInputValue';
import Alert from 'components/Alert';
import LoginMessage from 'components/LoginMessage';
import { RequiredAsterisk } from 'components/RequiredLabel';

function LoginForm({ message, isLoggingIn, createdAccount, onAuthenticate }) {
  const [email, handleEmailChange] = useInputValue(createdAccount);
  const [password, handlePasswordChange] = useInputValue();
  const accountCreatedCopy = `Account created! You may now log in as ${createdAccount}.`;

  function handleLogin(event) {
    event.preventDefault();
    onAuthenticate(email, password);
  }

  return (
    <div>
      <LoginMessage message={message} />
      {createdAccount ? (
        <Alert alertStyle="info" role="alert" id="account-created-message">
          {accountCreatedCopy}
        </Alert>
      ) : null}
      <ModalSpinner show={isLoggingIn} />
      <div className="heading">Sign in to TeamSnap</div>
      <form className="ts-form">
        <div className="form-group ts-form-control">
          <label htmlFor="login-email">
            Email
            <RequiredAsterisk />
          </label>
          <input
            type="email"
            id="login-email"
            className="form-control form-control-lg"
            onChange={handleEmailChange}
            placeholder="Email"
            defaultValue={createdAccount || ''}
          />
        </div>
        <div className="form-group ts-form-control">
          <label htmlFor="login-password">
            Password
            <RequiredAsterisk />
          </label>
          <input
            type="password"
            id="login-password"
            className="form-control form-control-lg"
            onChange={handlePasswordChange}
            placeholder="Password"
          />
        </div>
        <div className="text-right">
          <input
            type="submit"
            value="Login"
            id="login-submit"
            onClick={handleLogin}
            className="btn btn-primary"
          />
        </div>
      </form>
    </div>
  );
}

LoginForm.propTypes = {
  message: PropTypes.string,
  isLoggingIn: PropTypes.bool,
  createdAccount: PropTypes.string,
  onAuthenticate: PropTypes.func.isRequired,
};

LoginForm.defaultProps = {
  message: null,
  isLoggingIn: false,
  createdAccount: null,
};

export default LoginForm;
