import * as React from 'react';
import NumberInput from './NumberInput';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  formGroupClassName?: string;
  inputSize?: string;
  label?: string;
  labelClassName?: string;
  max?: number;
  min?: number;
  required?: boolean;
  step?: number;
  value?: number;
}

const PriceInput: React.FC<Props> = ({
  disabled,
  formGroupClassName,
  inputSize,
  label,
  labelClassName,
  max,
  min,
  onChange,
  required,
  step,
  value,
}: Props) => (
  <NumberInput
    disabled={disabled}
    formGroupClassName={formGroupClassName}
    inputSize={inputSize}
    label={label || 'Price'}
    labelClassName={labelClassName}
    max={max}
    min={min || 0.01}
    onChange={onChange}
    prepend="$"
    required={required}
    step={step || 0.01}
    value={value}
  />
);

PriceInput.defaultProps = {
  disabled: false,
  formGroupClassName: undefined,
  inputSize: undefined,
  label: 'Price',
  labelClassName: undefined,
  max: undefined,
  min: 0.01,
  required: undefined,
  step: 0.01,
  value: undefined,
};

export default PriceInput;
