import { replaceUnderscoresWithSpaces } from 'utils/string';
import moment from 'moment';
import Card from 'components/Card';
import type { Offer, SponsorshipEvent } from 'types/offer';
import CreateLogCommentForm from './create-log-comment-form';

const DATE_FORMAT_STRING = 'M/D/YYYY h:mm:ssA';

const renderEvent = (event: SponsorshipEvent) => (
  <tr key={event.id}>
    <td data-test="time" className="align-middle">
      {moment(event.createdAt).format(DATE_FORMAT_STRING)}
    </td>
    <td data-test="event" className="text-nowrap align-middle text-capitalize">
      {replaceUnderscoresWithSpaces(event.eventType)}
    </td>
    <td data-test="user" className="text-nowrap align-middle">
      {event.user ? (
        <small>
          {event.user.name}
          <br />
          {event.user.email}
        </small>
      ) : (
        ''
      )}
    </td>
    <td data-test="description" className="align-middle">
      <small className="style-string-with-new-lines">{event.description}</small>
      {event.changeReason && event.description && <br />}
      <small>{event.changeReason}</small>
    </td>
  </tr>
);

const emptyMessage = (
  <div className="media-body">
    <p className="text-center">No events have been logged yet!</p>
  </div>
);

type AuditLogCardProps = {
  offer: Offer;
};

function AuditLogCard(props: AuditLogCardProps) {
  const { offer } = props;
  return (
    <Card data-test="audit-log">
      <h2 className="card-title">Sponsorship Audit Log</h2>
      <div className="list-group list-group-flush">
        <div className="list-group-item">
          <CreateLogCommentForm offer={offer} />
        </div>
        <div className="list-group-item">
          {offer.sponsorshipEvents.length ? (
            <table className="table mb-0">
              <thead>
                <tr>
                  <th scope="col">Time</th>
                  <th scope="col">Event</th>
                  <th scope="col">User</th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody data-test="audit-log-body">
                {offer.sponsorshipEvents.map(renderEvent)}
              </tbody>
            </table>
          ) : (
            emptyMessage
          )}
        </div>
      </div>
    </Card>
  );
}

export default AuditLogCard;
