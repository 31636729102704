import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useWizardContext } from 'pages/qualify/provider';
import { WIZARD_IMAGES_PER_STEP_ID } from 'pages/qualify/wizard-images-urls';
import { StepIdsEnum } from 'pages/qualify/types';
import WizardInput from '../wizard-input';
import WizardStep from '../wizard-step';

export default function WrapUpStep() {
  const { tokenData } = useWizardContext();
  const [performMutation] = useMutation(MUTATION);

  return (
    <WizardStep
      performMutation={performMutation}
      mutationStaticIds={{
        organizationId: tokenData.id,
        season: tokenData.season,
        year: tokenData.year,
        offerId: tokenData.offer_id,
      }}
    >
      <WizardStep.Picture
        src={WIZARD_IMAGES_PER_STEP_ID[StepIdsEnum.REWARDED_ADD_ONS]}
      />
      <WizardStep.Content title="Rewarded add-ons">
        <p className="sui-text-desktop-4 sui-mb-2">
          In addition to the sponsorship opportunities you have expressed
          interest in through this application, TeamSnap offers a unique
          partnership with HundredX Causes.
        </p>
        <p className="sui-text-desktop-4 sui-mb-2">
          With this collaboration, your organization has the potential to earn
          up to $15,000 in one month by encouraging members of your
          organization&apos;s network to participate in quick one-minute surveys
          about national brands.
        </p>
        <p className="sui-text-desktop-4 sui-mb-4">
          Each survey completed contributes $1 to your organization, and each
          participant can take up to 75 surveys during the 30-day fundraising
          period.
        </p>
        <div className="sui-grid" style={{ gap: 24 }}>
          <WizardInput
            type="boolean-question"
            name="interestedInHundredX"
            label="My organization is interested in learning more about organizing a fundraiser through HundredX Causes"
          />
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation UpsertSponsorshipApplication(
    $input: UpsertSponsorshipApplicationInput!
  ) {
    upsertSponsorshipApplication(input: $input) {
      id
    }
  }
`;
