import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { ascend, descend, map, path, sortWith } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import HoverTable from 'components/HoverTable';
import formatDate from 'utils/formatDate';
import { capitalize } from 'utils/string';
import DataConfidenceIndicator from 'components/DataConfidenceIndicator';
import DataConfidenceIndicatorForOrganizations from 'components/DataConfidenceIndicatorForOrganizations';
import jwtDecode from 'jwt-decode';
import DeleteSponsorablePropertyModal from './programDetails/DeleteSponsorablePropertyModal';
import SPONSORABLE_PROPERTY_FRAGMENT from './programDetails/SponsorablePropertyFragment';
import UpdateSponsorablePropertyModal from './programDetails/UpdateSponsorablePropertyModal';
import CreateSponsorablePropertyModal from './programDetails/CreateSponsorablePropertyModal';

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      name
      organizationPlayLocations {
        id
        primary
        playLocation {
          id
          address
        }
      }
      sponsorableProperties {
        ...ProgramDetailsSponsorablePropertyFragment
        playLocation {
          id
          address
        }
        playLocations {
          id
          address
          primary
        }
        sponsorablePropertyPlayLocations {
          id
          primary
        }
        organization {
          id
          name
        }
      }
      playLocations {
        id
        address
      }
    }
  }
  ${SPONSORABLE_PROPERTY_FRAGMENT}
`;

const sortByStartDate = sortWith([
  descend(path(['startDate'])),
  ascend(path(['season'])),
]);

const ProgramDetails = ({ organizationId }) => {
  const { data: organizationData, loading } = useQuery(ORGANIZATION_QUERY, {
    variables: { id: organizationId },
  });

  const organization = organizationData && organizationData.organization;
  const sponsorableProperties =
    organization && organization.sponsorableProperties;

  const sponsorablePropertiesExist =
    sponsorableProperties && sponsorableProperties.length;

  const activeSponsorableProperties =
    sponsorableProperties &&
    sponsorableProperties.filter((sp) => sp.dataConfidence !== 'ended');

  const [showAllOffers, setShowAllOffers] = useState(false);

  const userRole = () => {
    const token = localStorage.getItem('token');
    const claims = jwtDecode(token);
    return claims.role;
  };

  const renderRow = (sponsorableProperty) => {
    const {
      id: sponsorablePropertyId,
      competitiveness,
      name,
      numberOfPlayers,
      season,
      sport,
      startDate,
      endDate,
      dataConfidence,
    } = sponsorableProperty;

    return (
      <tr
        key={sponsorablePropertyId}
        data-test={`sponsorable-property-${sponsorablePropertyId}-row`}
      >
        <td className="align-middle">
          {userRole() === 'organization' ? (
            <DataConfidenceIndicatorForOrganizations
              dataConfidence={dataConfidence}
            />
          ) : (
            <DataConfidenceIndicator dataConfidence={dataConfidence} />
          )}
          {name}
        </td>
        {sport ? (
          <td className="align-middle">{sport.name}</td>
        ) : (
          <td aria-label="No Sport" />
        )}
        {competitiveness ? (
          <td className="align-middle">{competitiveness.name}</td>
        ) : (
          <td aria-label="No Competitiveness:" />
        )}
        <td className="align-middle">{numberOfPlayers}</td>
        <td className="align-middle">{season && capitalize(season)}</td>
        <td className="align-middle">{!!startDate && formatDate(startDate)}</td>
        <td className="align-middle">{!!endDate && formatDate(endDate)}</td>
        <td className="align-middle">
          <UpdateSponsorablePropertyModal
            organizationPlayLocations={organization.organizationPlayLocations}
            sponsorableProperty={sponsorableProperty}
          />
          <DeleteSponsorablePropertyModal
            sponsorableProperty={sponsorableProperty}
          />
        </td>
      </tr>
    );
  };

  return !loading && organization ? (
    <div className="card-body">
      <div className="row">
        <div className="col">
          <strong className="ml-3 mr-3 my-1">Sponsorable Properties</strong>
          {!!sponsorableProperties &&
            sponsorableProperties.length !==
              activeSponsorableProperties.length && (
              <button
                className="btn btn-sm btn-seconday my-1"
                id="showAllToggle"
                onClick={() => setShowAllOffers(!showAllOffers)}
                type="button"
              >
                {showAllOffers ? 'Show Active' : 'Show All'}
              </button>
            )}
        </div>
        <div className="ml-auto mr-3">
          <CreateSponsorablePropertyModal organization={organization} />
        </div>
      </div>
      {sponsorablePropertiesExist ? (
        <HoverTable>
          <thead>
            <tr>
              <th scope="col" className="align-middle">
                Name
              </th>
              <th scope="col" className="align-middle">
                Sport
              </th>
              <th scope="col" className="align-middle">
                Competitiveness
              </th>
              <th scope="col" className="align-middle">
                Number of Players
              </th>
              <th scope="col" className="align-middle">
                Season
              </th>
              <th scope="col" className="align-middle">
                Start
              </th>
              <th scope="col" className="align-middle">
                End
              </th>
              <th scope="col" className="align-middle">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {map(
              renderRow,
              showAllOffers
                ? sortByStartDate(sponsorableProperties)
                : sortByStartDate(activeSponsorableProperties),
            )}
          </tbody>
        </HoverTable>
      ) : (
        <p className="text-center">No sponsorable properties exist!</p>
      )}
    </div>
  ) : null;
};

ProgramDetails.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default ProgramDetails;
