import { useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import type { VerificationDeadline } from 'types/offer';
import formatDate from 'utils/formatDate';
import { useSubmit } from 'utils/hooks';
import SPONSORSHIP_EVENT_FRAGMENT from 'fragments/SponsorshipEventFragment';
import ModalForm from 'components/ModalForm';
import TextAreaInput from 'components/formInputs/TextAreaInput';

type MarkAsDidNotCompleteModalProps = {
  sponsorablePropertyName: string;
  verificationDeadline: VerificationDeadline;
};

function MarkAsDidNotCompleteModal(props: MarkAsDidNotCompleteModalProps) {
  const { sponsorablePropertyName, verificationDeadline } = props;
  const { id: verificationDeadlineId, deadline, name } = verificationDeadline;
  const [mutate] = useMutation(MUTATION);
  const [reason, setReason] = useState('');

  const markAsDidNotComplete = ({ reason: reasonToSet }: { reason: string }) =>
    mutate({
      variables: {
        input: {
          reason: reasonToSet,
          verificationDeadlineId: verificationDeadline.id,
        },
      },
    });

  async function asyncSubmit() {
    await markAsDidNotComplete({ reason });
    setReason('');
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const prefix = `${verificationDeadlineId}-mark-as-did-not-complete`;

  return (
    <ModalForm
      id={`${prefix}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: 'Mark as "Did Not Complete"',
        disabled: loading,
      }}
      toggleProps={{
        className: 'btn btn-link py-1',
        children: 'Mark as "Did Not Complete"',
      }}
      title={'Mark as "Did Not Complete"'}
    >
      <p>
        Are you sure you want to mark the&nbsp;
        <strong>{`${formatDate(deadline)} ${name}`}</strong>
        &nbsp;deadline for&nbsp;
        <strong>{sponsorablePropertyName}</strong>
        &nbsp;as &quot;Did Not Complete&quot;?
      </p>
      <TextAreaInput
        disabled={loading}
        inputId="reason"
        label="Provide a reason (optional):"
        onChange={(ev) => setReason((ev.target as HTMLTextAreaElement).value)}
        prefix={prefix}
        value={reason}
      />
    </ModalForm>
  );
}

const MUTATION = gql`
  mutation MarkDeadlineAsDidNotComplete(
    $input: MarkDeadlineAsDidNotCompleteInput!
  ) {
    markDeadlineAsDidNotComplete(input: $input) {
      offer {
        id
        anyVerificationDeadlinesToIncludeInEmails
        sponsorshipEvents {
          ...SponsorshipEventFragment
        }
        verificationDeadlines {
          id
          didNotComplete
        }
      }
    }
  }
  ${SPONSORSHIP_EVENT_FRAGMENT}
`;

export default MarkAsDidNotCompleteModal;
