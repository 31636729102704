import { FormEvent, useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import type { VerificationDeadline } from 'types/offer';
import formatDate from 'utils/formatDate';
import { loadingTense } from 'utils/string';
import SPONSORSHIP_EVENT_FRAGMENT from 'fragments/SponsorshipEventFragment';
import TextAreaInput from 'components/formInputs/TextAreaInput';
import ModalForm from 'components/ModalForm';

const INITIAL_STATE = { changeReason: '', loading: false };

type RemoveVerificationDeadlineModalProps = {
  sponsorablePropertyName: string;
  verificationDeadline: VerificationDeadline;
};

function RemoveVerificationDeadlineModal(
  props: RemoveVerificationDeadlineModalProps,
) {
  const { sponsorablePropertyName, verificationDeadline } = props;
  const [state, setState] = useState(INITIAL_STATE);
  const [mutate] = useMutation(MUTATION);

  const remove = (changeReason: string) =>
    mutate({
      variables: {
        input: {
          changeReason,
          id: verificationDeadline.id,
        },
      },
    });

  const handleTextChange = (event: FormEvent<HTMLElement>) =>
    setState({
      ...state,
      changeReason: (event.target as HTMLInputElement).value,
    });

  const handleSubmit = async () => {
    setState({ ...state, loading: true });
    await remove(state.changeReason);
    setState(INITIAL_STATE);
  };

  const { changeReason, loading } = state;
  const { deadline, name } = verificationDeadline;

  return (
    <ModalForm
      id={`remove-deadline-${verificationDeadline.id}-modal`}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Remove'),
        disabled: loading || !changeReason,
      }}
      toggleProps={{
        className: 'btn btn-link text-danger py-1',
        children: 'Delete verification deadline',
      }}
      title="Remove Deadline"
    >
      <p>
        {'Are you sure you want to remove the '}
        <strong>{`${formatDate(deadline)} ${name}`}</strong>
        {' deadline for '}
        <strong>{sponsorablePropertyName}</strong>?
      </p>
      <TextAreaInput
        formGroupClassName="mt-3"
        inputId="reason"
        label="Reason for Removal:"
        onChange={handleTextChange}
        value={changeReason}
      />
    </ModalForm>
  );
}

const MUTATION = gql`
  mutation RemoveVerificationDeadline(
    $input: RemoveVerificationDeadlineInput!
  ) {
    removeVerificationDeadline(input: $input) {
      offer {
        id
        anyVerificationDeadlinesToIncludeInEmails
        sponsorshipEvents {
          ...SponsorshipEventFragment
        }
        verificationDeadlines {
          id
        }
      }
    }
  }
  ${SPONSORSHIP_EVENT_FRAGMENT}
`;

export default RemoveVerificationDeadlineModal;
