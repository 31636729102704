import * as React from 'react';
import formatDate from 'utils/formatDate';

interface Check {
  estimatedArrivalDate: string;
  number: number;
}

interface Props {
  check: Check;
}

const CheckDetails: React.FunctionComponent<Props> = ({ check }: Props) => {
  const { estimatedArrivalDate, number } = check;
  const formattedArrivalDate = formatDate(estimatedArrivalDate, 'M/D/YY');

  return (
    <span className="d-inline-block text-center">
      {`Check #${number}`}
      {!!estimatedArrivalDate && (
        <>
          :&nbsp;
          <span className="d-none d-md-inline-block">
            {`Expected arrival on ${formattedArrivalDate}`}
          </span>
          <span className="d-block d-md-none">
            {`ETA ${formattedArrivalDate}`}
          </span>
        </>
      )}
    </span>
  );
};

export default CheckDetails;
