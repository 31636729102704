import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { __, divide, pipe } from 'ramda';
import { formatPercentageValue } from 'utils/formatNumber';

const toDecimalString = pipe(Number, divide(__, 100), String);

const ETHNICITY_OPTIONS = [
  { label: 'Asian', value: 'asian' },
  { label: 'Black', value: 'black' },
  { label: 'Hispanic', value: 'hispanic' },
  { label: 'Islander', value: 'islander' },
  { label: 'Native', value: 'native' },
  { label: 'White', value: 'white' },
];

class EthnicityFilterForm extends React.Component {
  constructor(props) {
    super(props);

    const { ethnicity, ratio } = this.props;
    this.state = { ethnicity, ratio: formatPercentageValue(ratio) };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setEthnicity = ({ value }) => this.setState({ ethnicity: value });
    this.setRatio = ({ target: { value } }) => this.setState({ ratio: value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { ethnicity, ratio } = this.state;
    const { onSave } = this.props;
    onSave({ [ethnicity]: toDecimalString(ratio) });
  }

  render() {
    const { onCancel } = this.props;
    const { ethnicity, ratio } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <Select
            onChange={this.setEthnicity}
            options={ETHNICITY_OPTIONS}
            value={ethnicity}
          />
        </div>
        <div className="form-group">
          <label htmlFor="ratio-input">Percentage</label>
          <div className="input-group-append">
            <input
              id="ratio-input"
              className="form-control"
              data-test="ethnicity-filter-ratio-input"
              onChange={this.setRatio}
              value={ratio || ''}
            />
            <span className="input-group-text">%</span>
          </div>
        </div>
        <div className="form-group">
          <input
            type="range"
            className="form-control"
            value={ratio || ''}
            min="0"
            step="1"
            max="100"
            data-test="ethnicity-filter-ratio-range"
            onChange={this.setRatio}
          />
        </div>
        <div className="text-right">
          <button type="button" className="btn btn-link" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            data-test="submit-ethnicity-filter"
            disabled={!ethnicity || !ratio}
            onClick={this.save}
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

EthnicityFilterForm.propTypes = {
  ethnicity: PropTypes.string.isRequired,
  ratio: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default EthnicityFilterForm;
