import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useWizardContext } from 'pages/qualify/provider';
import WizardInput from '../wizard-input';
import WizardStep from '../wizard-step';

export default function CommunityImpactStep() {
  const { tokenData } = useWizardContext();
  const [performMutation] = useMutation(MUTATION);

  return (
    <WizardStep
      performMutation={performMutation}
      mutationStaticIds={{
        organizationId: tokenData.id,
        season: tokenData.season,
        year: tokenData.year,
        offerId: tokenData.offer_id,
      }}
    >
      <WizardStep.Content title="Briefly provide background information on your organization and how sponsorships can make a meaningful impact.">
        <WizardInput type="textarea" name="additionalInformation" />
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation UpsertSponsorshipApplication(
    $input: UpsertSponsorshipApplicationInput!
  ) {
    upsertSponsorshipApplication(input: $input) {
      id
    }
  }
`;
