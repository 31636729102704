import React from 'react';
import PropTypes from 'prop-types';

const RecenterButton = ({ onClick }) => (
  <button
    type="button"
    className="recenter-button btn btn-s btn-pill btn-light"
    onClick={onClick}
  >
    Recenter
  </button>
);

RecenterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RecenterButton;
