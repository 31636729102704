import React from 'react';
import { useQuery } from 'react-apollo';
import PropTypes from 'prop-types';
import Sections, { Section } from 'components/Sections';
import { getUserIdFrom } from 'utils/authentication';
import gql from 'graphql-tag';
import ORGANIZATION_ADDRESSES_FRAGMENT from 'components/organizationDashboardWrapper/LeagueAddressesFragment';
import ORGANIZATION_PACKAGE_FRAGMENT_V2 from 'fragments/OrganizationPackageFragmentV2';
import Loader from 'components/Loader';
import OrganizationDashboardWrapper from 'components/OrganizationDashboardWrapper';
// eslint-disable-next-line import/no-named-as-default
import OrganizationPackages from './OrganizationPackages';
import OrganizationDashboardCapabilities from './OrganizationDashboardCapabilities';

const QUERY = gql`
  query OrganizationDashboard($userId: Int) {
    myOrganizations(userId: $userId) {
      id
      name
      ...OrganizationAddressesFragment
      organizationPackages {
        ...OrganizationPackageFragment
      }
      sponsorableProperties {
        id
        name
      }
    }
  }
  ${ORGANIZATION_ADDRESSES_FRAGMENT}
  ${ORGANIZATION_PACKAGE_FRAGMENT_V2}
`;

const OrganizationPackageBuilderSections = ({ location }) => {
  const { data, loading } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: { userId: getUserIdFrom(location.search) },
  });

  if (loading) return <Loader />;

  const organizations = data && data.myOrganizations;

  return (
    <OrganizationDashboardWrapper organizations={organizations}>
      {({ selectedOrganization }) => (
        <Sections noContainingCard colWidth={9}>
          <Section name="Capabilities">
            <OrganizationDashboardCapabilities
              organizationId={selectedOrganization.id}
            />
          </Section>
          <Section name="Packages" path="packages">
            <OrganizationPackages selectedOrganization={selectedOrganization} />
          </Section>
        </Sections>
      )}
    </OrganizationDashboardWrapper>
  );
};

OrganizationPackageBuilderSections.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrganizationPackageBuilderSections;
