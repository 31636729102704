import React from 'react';
import PropTypes from 'prop-types';
import { capitalize, loadingTense } from 'utils/string';
import { useSubmit } from 'utils/hooks';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

const RemoveGenericCreativeModal = ({
  id,
  creativeType,
  name,
  remove,
  toggleClassName,
}) => {
  const { error, loading, handleSubmit } = useSubmit(remove);

  return (
    <ModalForm
      id={`remove-generic-creative-${creativeType}-${id}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Delete'),
        disabled: loading,
      }}
      title={`Remove Creative ${capitalize(creativeType)}?`}
      toggleProps={{
        className: toggleClassName,
        children: <Icon value="trash" />,
        title: 'Delete',
      }}
    >
      <p>
        {'Are you sure you want to delete '}
        <strong>{name}</strong>?
      </p>
    </ModalForm>
  );
};

RemoveGenericCreativeModal.propTypes = {
  id: PropTypes.number.isRequired,
  creativeType: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string.isRequired,
};

export default RemoveGenericCreativeModal;
