/* eslint react/forbid-prop-types: 0 */
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  CAMPAIGN_DASHBOARD_PATH_PREFIX,
  SEASONAL_DASHBOARD_PATH_PREFIX,
  CAMPAIGN_PLANNER_PREFIX,
  ORGANIZATION_DASHBOARD_PATH,
  ORGANIZATIONS_PATH,
  OFFER_PREFIX,
  SPONSOR_DASHBOARD_PATH,
  ORGANIZATION_PACKAGES_PATH,
  ORGANIZATION_DASHBOARD_OVERVIEW_PATH,
} from 'utils/paths';
import ReviewOfferFromEmail from 'pages/ReviewOfferFromEmail';
import CampaignTargeting from 'pages/CampaignTargeting';
import ChangePassword from 'pages/ChangePassword';
import Checks from 'pages/Checks';
import Home from 'pages/Home';
import Invitation from 'pages/Invitation';
import Organization from 'pages/Organization';
import QualifyPage from 'pages/qualify/page';
import Organizations from 'pages/Organizations';
import LeagueImport from 'pages/LeagueImport';
// eslint-disable-next-line import/no-named-as-default
import OrganizationPackages from 'pages/OrganizationPackages';
import LeagueSelfOnboarding from 'pages/LeagueSelfOnboarding';
import OrganizationSelfSignup from 'pages/OrganizationSelfSignup';
import { LoginContainer } from 'pages/Login';
import { LogoutContainer } from 'pages/Logout';
import ProspectCampaign from 'pages/ProspectCampaign';
import ProspectFilter from 'pages/ProspectFilter';
import ProspectLocation from 'pages/ProspectLocation';
import RecoverAccount from 'pages/RecoverAccount';
import RespondToDeadline from 'pages/RespondToDeadline';
import CampaignDashboard from 'pages/CampaignDashboard';
import SponsorDashboardMain from 'pages/SponsorDashboardMain';
import OfferDetailsPage from 'pages/offers/offer-details/offer-details-page';
import SeasonalDashboard from 'pages/SeasonalDashboard';
import Verifications from 'pages/Verifications';
import PropTypes from 'prop-types';
import OrganizationDashboardRouter from 'OrganizationDashboardRouter';
import AddCapability from 'pages/AddCapability';
import CampaignPlannerRouter from './CampaignPlannerRouter';
import '@teamsnap/snap-ui/build/snap-ui.min.css';
import './App.css';

const BOX_URL = 'https://leagueside.box.com/s/pe4lscyf63w0pizc2n7fmzohur06higz';

function redirectToBoxUrl() {
  window.location = BOX_URL;
  return null;
}

const OLD_LEAGUES_PATH = '/leagues';
const LEAGUE_DASHBOARD_PATH = '/league-dashboard';
const LEAGUE_PACKAGES_PATH = `${LEAGUE_DASHBOARD_PATH}/packages`;
const LEAGUE_DASHBOARD_OVERVIEW_PATH = `${LEAGUE_DASHBOARD_PATH}/overview`;

const App = ({ factory, cable }) => (
  <Switch>
    <Route
      path="/campaign-targeting/:campaignId"
      component={CampaignTargeting}
    />
    <Route path="/accept-offer/:token" component={ReviewOfferFromEmail} />
    <Route path="/invitation/:token" component={Invitation} />
    <Route
      path={`${ORGANIZATIONS_PATH}/:organizationId/capability-form`}
      component={AddCapability}
    />
    <Route path={`${ORGANIZATIONS_PATH}/:id`} component={Organization} />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id/campaigns`}
      to={`${ORGANIZATIONS_PATH}/:id/campaigns`}
    />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id/comments`}
      to={`${ORGANIZATIONS_PATH}/:id/comments`}
    />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id/locations`}
      to={`${ORGANIZATIONS_PATH}/:id/locations`}
    />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id/packages`}
      to={`${ORGANIZATIONS_PATH}/:id/packages`}
    />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id/preQuals`}
      to={`${ORGANIZATIONS_PATH}/:id/preQuals`}
    />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id/program`}
      to={`${ORGANIZATIONS_PATH}/:id/program`}
    />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id/shippinginfo`}
      to={`${ORGANIZATIONS_PATH}/:id/shippinginfo`}
    />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id/users`}
      to={`${ORGANIZATIONS_PATH}/:id/users`}
    />
    <Redirect
      from={`${OLD_LEAGUES_PATH}/:id`}
      to={`${ORGANIZATIONS_PATH}/:id`}
    />

    <Redirect from="/verifications/:id" to={`${OFFER_PREFIX}/:id`} />
    <Route path={`${OFFER_PREFIX}/:id`} component={OfferDetailsPage} />

    <Route
      path="/prospect-campaign/:campaignId"
      render={(props) => <ProspectCampaign {...props} cable={cable} />}
    />
    <Route
      path="/prospect-filter/:filterId"
      render={(props) => <ProspectFilter {...props} cable={cable} />}
    />
    <Route
      path="/prospect-location/:locationId"
      render={(props) => <ProspectLocation {...props} cable={cable} />}
    />
    <Route path="/qualify/:token" component={QualifyPage} />
    <Route path="/recover-account/:recoveryToken" component={RecoverAccount} />
    <Route path="/respond-to-deadline/:token" component={RespondToDeadline} />
    <Route
      path={`${CAMPAIGN_DASHBOARD_PATH_PREFIX}/:token`}
      component={CampaignDashboard}
    />
    <Route
      path={`${SEASONAL_DASHBOARD_PATH_PREFIX}/:token`}
      component={SeasonalDashboard}
    />
    <Redirect
      from="/sponsor-dashboard/:token"
      to={`${CAMPAIGN_DASHBOARD_PATH_PREFIX}/:token`}
    />
    <Route path={SPONSOR_DASHBOARD_PATH} component={SponsorDashboardMain} />
    <Route path="/welcome/:token" component={LeagueSelfOnboarding} />
    <Route path={CAMPAIGN_PLANNER_PREFIX} component={CampaignPlannerRouter} />
    <Route path="/change-password" component={ChangePassword} />
    <Route path="/checks" component={Checks} />
    <Route path="/league-import" component={LeagueImport} />
    <Route path="/lanier-soccer-association" component={redirectToBoxUrl} />
    <Redirect
      from={`${LEAGUE_DASHBOARD_PATH}/offer/:offerId`}
      to={`${ORGANIZATION_DASHBOARD_PATH}/offer/:offerId`}
    />
    <Redirect
      from={`${LEAGUE_DASHBOARD_PATH}/sponsorship/:offerId`}
      to={`${ORGANIZATION_DASHBOARD_PATH}/sponsorship/:offerId`}
    />
    <Redirect
      from={`${LEAGUE_PACKAGES_PATH}/:organizationPackageId`}
      to={`${ORGANIZATION_PACKAGES_PATH}/:organizationPackageId`}
    />
    <Redirect
      from={LEAGUE_DASHBOARD_OVERVIEW_PATH}
      to={ORGANIZATION_DASHBOARD_OVERVIEW_PATH}
    />
    <Route
      path={ORGANIZATION_DASHBOARD_PATH}
      render={(props) => (
        <OrganizationDashboardRouter {...props} presenterFactory={factory} />
      )}
    />
    <Route path="/league-packages" component={OrganizationPackages} />
    <Route
      path="/organization-self-signup"
      component={OrganizationSelfSignup}
    />
    <Redirect from="/league-self-signup" to="/organization-self-signup" />
    <Route path={ORGANIZATIONS_PATH} component={Organizations} />
    <Redirect from={OLD_LEAGUES_PATH} to={ORGANIZATIONS_PATH} />
    <Route path="/login" component={LoginContainer} />
    <Route path="/logout" component={LogoutContainer} />
    <Route path="/verifications" component={Verifications} />
    <Route path="/" component={Home} />
  </Switch>
);

App.propTypes = {
  factory: PropTypes.objectOf(PropTypes.any).isRequired,
  cable: PropTypes.shape.isRequired,
};

export default App;
