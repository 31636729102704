import * as React from 'react';
import { seasonAndYearLabel } from 'utils/campaign';
import { CAMPAIGN_DASHBOARD_PATH_PREFIX } from 'utils/paths';
import { capitalize } from 'utils/string';
import { CampaignStatus } from 'types/campaign';
import { Season } from 'types/season';
import Badge, { BadgeProps } from 'components/Badge';
import NewWindowLink from 'components/NewWindowLink';

const statusBadgeType = {
  active: 'primary',
  complete: 'success',
  proposed: 'warning',
  rejected: 'secondary',
};

interface Campaign {
  id: number;
  magicToken: string;
  season: Season;
  name: string;
  status: CampaignStatus;
  year: string;
}

interface Props {
  campaign: Campaign;
}

const SponsorCampaignsTableRow: React.FunctionComponent<Props> = ({
  campaign,
}: Props) => {
  const { id: campaignId, magicToken, status, name } = campaign;
  const seasonAndYear = seasonAndYearLabel(campaign);

  return (
    <tr data-test={`campaign-${campaignId}`}>
      <td className="align-middle width-50">{name}</td>
      <td className="align-middle">
        <NewWindowLink
          hideIcon
          id={`campaign-${campaignId}-link`}
          title={`View ${seasonAndYear} Campaign Details`}
          to={`${CAMPAIGN_DASHBOARD_PATH_PREFIX}/${magicToken}`}
        >
          View
        </NewWindowLink>
      </td>
      <td className="align-middle width-20">
        <Badge badgeType={statusBadgeType[status] as BadgeProps['badgeType']}>
          {capitalize(status)}
        </Badge>
      </td>
    </tr>
  );
};

export default SponsorCampaignsTableRow;
