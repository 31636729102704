import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Play from './Play';

function PlayCompetitiveness({ addChanges, league, competitivenesses }) {
  return (
    <Play
      addChanges={addChanges}
      league={league}
      names="competitiveness"
      collections={competitivenesses}
      question="At which competitiveness levels does your league play?"
    />
  );
}

PlayCompetitiveness.propTypes = {
  addChanges: PropTypes.func.isRequired,
  competitivenesses: PropTypes.arrayOf(LeagueSidePropTypes.competitiveness)
    .isRequired,
  league: LeagueSidePropTypes.league.isRequired,
};

export default PlayCompetitiveness;
