import React from 'react';
import Card from 'components/Card';
import ExpandRowButton from 'components/ExpandRowButton';
import moment from 'moment';

type ContractsCardProps = {
  // Temporary Offer type
  offer: {
    offerContracts: {
      contractUrl: string;
      createdAt: string;
      versionNumber: string;
    }[];
  };
};

export default function ContractsCard(props: ContractsCardProps) {
  const {
    offer: { offerContracts = [] },
  } = props;

  return (
    <Card>
      <div className="row">
        <h2 className="col">Contracts</h2>
      </div>

      {offerContracts.length > 0 && (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Version</th>
              <th scope="col">Created</th>
              <th scope="col">Contract</th>
            </tr>
          </thead>
          <tbody>
            {offerContracts.map((contract) => (
              <tr key={`offer-contract-v-${contract.versionNumber}`}>
                <td>{contract.versionNumber}</td>
                <td>{moment(contract.createdAt).format('M/D/YYYY')}</td>
                <td>
                  <a
                    href={contract.contractUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ExpandRowButton
                      expanded={false}
                      idLabel="view-contract-button-$contractId"
                      label="View"
                    />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {offerContracts.length === 0 && (
        <p className="text-center my-4">No contracts for this offer.</p>
      )}
    </Card>
  );
}
