import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { filterCurrent } from 'utils/campaign';
import { isAllShownOrHidden } from 'utils/misc';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import Card from 'components/Card';
import Icon from 'components/Icon';
import CampaignsTable from './CampaignsTable';
import SponsorFormCard from './SponsorFormCard';

const emptyMessage = (
  <p className="text-center">
    No campaigns yet! Add a campaign to use the campaign planner.
  </p>
);

const SponsorTab = ({ refetch, sponsor }) => {
  const { campaigns, id: sponsorId } = sponsor;

  const currentCampaigns = filterCurrent(campaigns);
  const campaignsHaveSameStatus = isAllShownOrHidden(
    currentCampaigns,
    campaigns,
  );

  const [showAllCampaigns, setShowAllCampaigns] = useState(false);

  const allDisplayed = showAllCampaigns || campaignsHaveSameStatus;

  return (
    <>
      <SponsorFormCard refetch={refetch} sponsor={sponsor} />
      <Card>
        <div className="row">
          <div className="col">
            <strong>
              {allDisplayed ? 'All Campaigns' : 'Current Campaigns'}
              {!campaignsHaveSameStatus && (
                <button
                  className="btn btn-sm btn-secondary ml-2"
                  id="activeCampaignFilterToggle"
                  onClick={() => setShowAllCampaigns(!showAllCampaigns)}
                  type="button"
                >
                  {showAllCampaigns ? 'Show Current' : 'Show All'}
                </button>
              )}
            </strong>
          </div>
          <div className="col-auto">
            <Link
              className="btn btn-secondary"
              data-test="adding-button"
              to={`${CAMPAIGN_PLANNER_PATH}/${sponsorId}/campaigns/add`}
            >
              <Icon className="mr-2" value="plus" />
              Add A Campaign
            </Link>
          </div>
        </div>
        {campaigns.length ? (
          <CampaignsTable
            campaigns={allDisplayed ? campaigns : currentCampaigns}
            sponsorId={sponsorId}
          />
        ) : (
          emptyMessage
        )}
      </Card>
    </>
  );
};

SponsorTab.propTypes = {
  refetch: PropTypes.func,
  sponsor: LeagueSidePropTypes.sponsor.isRequired,
};

SponsorTab.defaultProps = {
  refetch: () => {},
};

export default SponsorTab;
