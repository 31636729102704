import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatNumber } from 'utils/formatNumber';
import { ageRange } from 'utils/sponsorableProperty';
import { listAssets } from 'utils/asset';
import Icon from 'components/Icon';

const style = `
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 22rem;
  @media (max-width: 1280px) {
    max-width: 24vw;
  }
`;
const StyledEllipsisDiv = styled.div`
  ${style}
`;
const StyledEllipsisHeader = styled.h6`
  ${style}
`;

const StyledListItem = ({ predicate, iconValue, children }) =>
  predicate && (
    <li className="mb-1 mr-3">
      <StyledEllipsisDiv>
        <Icon className="text-muted mr-2" value={iconValue} />
        {children}
      </StyledEllipsisDiv>
    </li>
  );

StyledListItem.propTypes = {
  children: PropTypes.node.isRequired,
  iconValue: PropTypes.string.isRequired,
  predicate: PropTypes.bool.isRequired,
};

const MapOfferDataPoints = ({ offer }) => {
  const { assets, sponsorableProperty } = offer;
  const { numberOfPlayers, sport } = sponsorableProperty;

  return (
    <div className="mt-1 mb-2">
      <StyledEllipsisHeader className="mb-2">
        {sponsorableProperty.name}
      </StyledEllipsisHeader>
      <ul className="list-unstyled mb-0 d-flex align-items-center flex-wrap">
        <StyledListItem predicate={!!numberOfPlayers} iconValue="man">
          {`${formatNumber(numberOfPlayers)} players`}
        </StyledListItem>
        <StyledListItem
          predicate={
            !!sponsorableProperty.minAge && !!sponsorableProperty.maxAge
          }
          iconValue="users"
        >
          {`Age ${ageRange(sponsorableProperty)}`}
        </StyledListItem>
        <StyledListItem predicate={!!sport} iconValue="trophy">
          {sport && sport.name}
        </StyledListItem>
        <StyledListItem predicate={!!assets.length} iconValue="images">
          {listAssets(assets)}
        </StyledListItem>
      </ul>
    </div>
  );
};

MapOfferDataPoints.propTypes = {
  offer: LeagueSidePropTypes.offer.isRequired,
};

export default MapOfferDataPoints;
