/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import PropTypes from 'prop-types';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import store from 'store';
import { logout } from 'modules/authentication';
import introspectionQueryResultData from './fragmentTypes.json';

const env = runtimeEnv();

const httpLink = new HttpLink({ uri: env.REACT_APP_GRAPHQL_URI });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  if (!token) return { headers };

  return {
    headers: {
      ...headers,
      Authorization: (headers && headers.Authorization) || `Bearer ${token}`,
    },
  };
});

const operationsThatDoNotRequireLogin = [
  'CampaignDashboard',
  'OfferToAccept',
  'LeagueFacingPreQualificationSurveyPage',
];

const errorLink = onError(({ operation, networkError }) => {
  // these operations don't require a login, so do not redirect to login when an error occurs
  if (operationsThatDoNotRequireLogin.includes(operation.operationName)) return;

  // TODO: Capture with Raven

  if (networkError && networkError.statusCode === 401) store.dispatch(logout());
});
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent

const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({ fragmentMatcher }),
});

const LeagueSideApolloProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

LeagueSideApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LeagueSideApolloProvider;
