import { __, anyPass, contains, filter, pipe, prop } from 'ramda';
import { capitalize } from './string';
import { statusEq } from './misc';

export function getCampaignSeason() {
  const delta = 3;
  const campaignSeason = {};

  const currentTime = new Date();
  const month = currentTime.getMonth() + 1;

  campaignSeason.year =
    currentTime.getFullYear() + Math.floor((month + delta) / 12);
  const seasonIndex = (month + delta) % 12;

  if (seasonIndex < 3) {
    campaignSeason.season = 'winter';
  } else if (seasonIndex < 6) {
    campaignSeason.season = 'spring';
  } else if (seasonIndex < 9) {
    campaignSeason.season = 'summer';
  } else {
    campaignSeason.season = 'fall';
  }

  return campaignSeason;
}

export const seasonAndYearLabel = (campaign) => {
  const { season, year } = campaign;

  return `${capitalize(season)} ${year}`;
};

export const nameAndSeasonLabel = (campaign) => {
  const { name } = campaign;

  const seasonAndYear = seasonAndYearLabel(campaign);

  return contains(seasonAndYear, name) ? name : `${name} (${seasonAndYear})`;
};

export const label = (campaign) =>
  `${campaign.sponsor.name} - ${nameAndSeasonLabel(campaign)}`;

const ordinalBySeason = { winter: 1, spring: 2, summer: 3, fall: 4 };
export const seasonOrdinal = pipe(prop('season'), prop(__, ordinalBySeason));

const hasCurrentStatus = anyPass([
  statusEq('contracted'),
  statusEq('active'),
  statusEq('proposed'),
]);
export const filterCurrent = filter(hasCurrentStatus);
