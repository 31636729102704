import React from 'react';
import PropTypes from 'prop-types';

const TeamSnapIndicator = ({ sportsManagementSoftwareId, className }) => (
  <>
    {sportsManagementSoftwareId === 1 && (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        alt="TeamSnap Logo"
        data-test="teamsnap-logo-indicator"
        className={className}
        src="https://storage.googleapis.com/ts_assets_prod-classic_assets/logo-icon-dafd29abff7b6ca55ad71c35bd34d679.png"
      />
    )}
  </>
);

TeamSnapIndicator.propTypes = {
  sportsManagementSoftwareId: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
};

export default TeamSnapIndicator;
