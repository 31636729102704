import * as React from 'react';
import { CampaignStatus } from 'types/campaign';
import { OfferStatus } from 'types/offer';
import { hasActiveCampaign } from 'utils/offer';
import { capitalizeEachWord } from 'utils/string';
import Badge, { BadgeProps } from 'components/Badge';

interface Campaign {
  status: CampaignStatus;
}

interface Offer {
  campaign: Campaign;
  status: OfferStatus;
}

interface Props {
  offer: Offer;
}

const OfferStatusBadge: React.FunctionComponent<Props> = ({ offer }: Props) => {
  const { status: offerStatus } = offer;

  const label = capitalizeEachWord(offerStatus);

  if (!hasActiveCampaign(offer)) {
    return <Badge badgeType="secondary">{label}</Badge>;
  }

  let badgeType: BadgeProps['badgeType'];
  switch (offerStatus) {
    case 'prospecting':
      badgeType = offerStatus;
      break;
    case 'qualified':
      badgeType = offerStatus;
      break;
    case 'proposed':
      badgeType = offerStatus;
      break;
    case 'rejected_by_league':
      badgeType = 'proposed';
      break;
    case 'confirmed':
      badgeType = offerStatus;
      break;
    default:
      badgeType = 'secondary';
      break;
  }

  return (
    <Badge className="border-0" badgeType={badgeType}>
      {label}
    </Badge>
  );
};

export default OfferStatusBadge;
