import React from 'react';
import PropTypes from 'prop-types';
import { formatToDollar } from 'utils/formatNumber';

import FilterCard from './FilterCard';
import IncomeFilterForm from './IncomeFilterForm';

const IncomeFilterCard = ({ filterId, lowBound, highBound }) => (
  <FilterCard
    filterId={filterId}
    title="Target Househould Income"
    iconValue="credit"
    description={
      <>
        Between&nbsp;
        <strong>{formatToDollar(lowBound)}</strong>
        &nbsp;and&nbsp;
        <strong>{formatToDollar(highBound)}</strong>
      </>
    }
    formRenderer={({ save, cancel }) => (
      <IncomeFilterForm
        savedLowBound={lowBound}
        savedHighBound={highBound}
        onCancel={cancel}
        onSave={save}
      />
    )}
  />
);

IncomeFilterCard.propTypes = {
  filterId: PropTypes.number.isRequired,
  lowBound: PropTypes.number.isRequired,
  highBound: PropTypes.number.isRequired,
};

export default IncomeFilterCard;
