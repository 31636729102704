import React from 'react';
import {
  all,
  append,
  contains,
  evolve,
  filter,
  intersection,
  isEmpty,
  map,
  pluck,
  without,
} from 'ramda';
import PropTypes from 'prop-types';
import { pluralizedTense } from 'utils/string';
import { sponsorablePropertyLocation } from 'utils/sponsorableProperty';
import ActiveOffersBadges from 'components/ActiveOffersBadges';
import SponsorshipInsightReport from 'components/SponsorshipInsightReport';
import { sortAvailableSPsByOrganization } from 'utils/offer';
import AddToCampaignModal from './AddToCampaignModal';
import SponsorablePropertyCol from './SponsorablePropertyCol';
import PackagesIndicator from './PackagesIndicator';
import OrganizationCol from './OrganizationCol';

class AvailableTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = { selectedSponsorablePropertyIds: [] };

    this.evolveSelectedSponsorablePropertyIds = (
      selectedSponsorablePropertyIds,
    ) =>
      this.setState(
        evolve({
          selectedSponsorablePropertyIds,
        }),
      );
    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggleAll = this.handleToggleAll.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const nextSponsorablePropertyIds = pluck(
      'id',
      nextProps.sponsorableProperties,
    );

    this.evolveSelectedSponsorablePropertyIds(
      intersection(nextSponsorablePropertyIds),
    );
  }

  get allSponsorablePropertyIds() {
    const { props } = this;
    return pluck('id', props.sponsorableProperties);
  }

  get allSelected() {
    const { state } = this;
    const selectedIdsSet = new Set(state.selectedSponsorablePropertyIds);
    return all((id) => selectedIdsSet.has(id), this.allSponsorablePropertyIds);
  }

  get selectedSponsorableProperties() {
    const { props, state } = this;
    const selected = ({ id }) =>
      contains(id, state.selectedSponsorablePropertyIds);

    return filter(selected, props.sponsorableProperties);
  }

  handleToggle(sponsorablePropertyId) {
    const includesSponsorablePropertyId = contains(sponsorablePropertyId);
    const removeSponsorablePropertyId = without([sponsorablePropertyId]);
    const addSponsorablePropertyId = append(sponsorablePropertyId);

    return () =>
      this.evolveSelectedSponsorablePropertyIds(
        (selectedSponsorablePropertyIds) => {
          if (includesSponsorablePropertyId(selectedSponsorablePropertyIds)) {
            return removeSponsorablePropertyId(selectedSponsorablePropertyIds);
          }

          return addSponsorablePropertyId(selectedSponsorablePropertyIds);
        },
      );
  }

  handleToggleAll() {
    this.evolveSelectedSponsorablePropertyIds(() =>
      this.allSelected ? [] : this.allSponsorablePropertyIds,
    );
  }

  renderRow(sponsorableProperty) {
    const { state } = this;
    const {
      id: sponsorablePropertyId,
      offerCountsReport,
      organization,
    } = sponsorableProperty;

    return (
      <tr
        key={sponsorablePropertyId}
        data-test={`sponsorable-property-${sponsorablePropertyId}-row`}
      >
        <td className="align-middle">
          <input
            checked={contains(
              sponsorablePropertyId,
              state.selectedSponsorablePropertyIds,
            )}
            data-test={`available-toggle-${sponsorablePropertyId}`}
            onChange={this.handleToggle(sponsorablePropertyId)}
            type="checkbox"
          />
        </td>
        <OrganizationCol organization={organization} />
        <SponsorablePropertyCol sponsorableProperty={sponsorableProperty} />
        <td className="align-middle pl-1">
          <SponsorshipInsightReport
            sponsorshipInsightReport={organization.sponsorshipInsightReport}
          />
          <div className="sui-mt-1">
            <ActiveOffersBadges
              offerCountsReport={offerCountsReport}
              sponsorablePropertyId={sponsorablePropertyId}
            />
            <PackagesIndicator sponsorableProperty={sponsorableProperty} />
          </div>
        </td>
        <td
          className="align-middle d-none d-lg-table-cell"
          data-test="location"
        >
          <small>
            {sponsorablePropertyLocation({
              state: organization.state,
              city: organization.city,
            })}
          </small>
        </td>
      </tr>
    );
  }

  render() {
    const { campaignId, sponsorableProperties, onAddProspects } = this.props;
    const { selectedSponsorableProperties } = this;

    return (
      <>
        <div
          className="d-flex mb-2 justify-content-between align-items-center"
          data-test="available-table"
        >
          <p className="m-0 sui-font-bold">
            {`${sponsorableProperties.length} available`}
          </p>
          <span>
            <div className="dropdown d-inline-block ml-2">
              <button
                className="btn btn-large btn-secondary dropdown-toggle"
                disabled={isEmpty(selectedSponsorableProperties)}
                data-toggle="dropdown"
                id="bulk-available-dropdown-menu-button"
                type="button"
              >
                {`With ${
                  selectedSponsorableProperties.length
                } Selected Sponsorable ${pluralizedTense(
                  selectedSponsorableProperties.length,
                  'Property',
                )}`}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="bulk-available-dropdown-menu-button"
              >
                <AddToCampaignModal
                  campaignId={campaignId}
                  onAdd={onAddProspects}
                  sponsorableProperties={selectedSponsorableProperties}
                />
              </div>
            </div>
          </span>
        </div>
        {sponsorableProperties.length ? (
          <table className="table">
            <thead>
              <tr>
                <th className="align-middle" scope="col">
                  <input
                    checked={this.allSelected}
                    data-test="all-available-toggle"
                    onChange={this.handleToggleAll}
                    type="checkbox"
                  />
                </th>
                <th className="align-middle pr-1" scope="col">
                  Organization
                </th>
                <th className="align-middle pr-1" scope="col">
                  Sponsorable Property
                </th>
                <th
                  className="align-middle pl-1"
                  scope="col"
                  aria-label="report-column"
                />
                <th className="align-middle d-none d-lg-table-cell" scope="col">
                  Location
                </th>
              </tr>
            </thead>
            <tbody>
              {map(
                this.renderRow,
                sortAvailableSPsByOrganization(sponsorableProperties),
              )}
            </tbody>
          </table>
        ) : (
          <p className="text-center">
            No more sponsorable properties available to add!
          </p>
        )}
      </>
    );
  }
}

AvailableTable.propTypes = {
  campaignId: PropTypes.number.isRequired,
  onAddProspects: PropTypes.func.isRequired,
  sponsorableProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      city: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      offerCountsReport: PropTypes.shape({
        prospecting: PropTypes.number,
        qualified: PropTypes.number,
        proposed: PropTypes.number,
        confirmed: PropTypes.number,
      }).isRequired,
      organization: PropTypes.shape({
        id: PropTypes.number.isRequired,
        dataConfidence: PropTypes.string.isRequired,
        sponsorshipInsightReport: PropTypes.shape({
          averageConfirmedValue: PropTypes.number,
          confirmedCount: PropTypes.number,
          onTimeVerificationPercentage: PropTypes.number,
          name: PropTypes.string,
        }),
        websiteUrl: PropTypes.string,
      }).isRequired,
      sponsorablePropertyPackagesCount: PropTypes.number.isRequired,
      state: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AvailableTable;
