import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';

const ModalDisplay = ({ children, id, toggleProps, ...modalProps }) => {
  const selector = `#${id}`;

  return (
    <>
      <button
        data-target={selector}
        data-toggle="modal"
        type="button"
        {...toggleProps}
      />
      <Modal id={id} {...modalProps}>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            data-test={`${id}-close`}
            data-dismiss="modal"
            type="button"
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ModalDisplay.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
  }),
};

ModalDisplay.defaultProps = {
  children: null,
  toggleProps: {},
};

export default ModalDisplay;
