import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Paragraph from './Paragraph';

const Name = ({ addChanges, league, savedLeague }) => (
  <div className="container">
    <label htmlFor="what-league-is-called">
      <Paragraph bold>Do we have the name of your league right?</Paragraph>
      <Paragraph className="d-none d-sm-block">
        {'We think your league is called '}
        <strong>{savedLeague.name}</strong>, but please correct us if we have it
        wrong.
      </Paragraph>
    </label>
    {/*
      We're using a strange name for this control to confuse chrome out of using "address autocompletion."
      This is a really funky feature that leads to details being blown away, and so we give these things
      weird names to keep Chrome from changing them.
    */}
    <input
      className="form-control form-control-lg"
      autoComplete="off"
      id="what-league-is-called"
      onChange={(event) => addChanges({ name: event.target.value })}
      type="text"
      value={league.name}
    />
  </div>
);

Name.propTypes = {
  addChanges: PropTypes.func.isRequired,
  league: LeagueSidePropTypes.league.isRequired,
  savedLeague: LeagueSidePropTypes.league.isRequired,
};

Name.getValidationError = ({ league }) =>
  league.name
    ? null
    : "We need your organization's name to identify it in our system.";

export default Name;
