import ContactUsLink from 'components/ContactUsLink';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCaughtErrors } from 'modules';
import { hasErrors } from 'modules/caughtErrors';
import fumbledBall from './errorBoundary/fumbled-ball.jpg';
import SingleCardPage from './SingleCardPage';

class ErrorBoundaryUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showError: false };
  }

  componentDidCatch() {
    this.setState({ showError: true });
  }

  render() {
    const { showError: propsShowError, children } = this.props;
    const { showError: stateShowError } = this.state;
    if (!propsShowError && !stateShowError) {
      return children;
    }

    return (
      <SingleCardPage>
        <div className="heading">Uh oh! Something went wrong.</div>
        <img
          className="mt-4 mb-4 mw-100"
          src={fumbledBall}
          alt="Fumbled ball. By Ben Hershey on Unsplash."
        />
        <p>Sorry, it looks like we fumbled the ball.</p>
        <p>Try checking your internet connection and refreshing the page.</p>
        <p>
          If the problem continues, please
          <ContactUsLink />.
        </p>
      </SingleCardPage>
    );
  }
}

ErrorBoundaryUnwrapped.propTypes = {
  children: PropTypes.node.isRequired,
  showError: PropTypes.bool,
};

ErrorBoundaryUnwrapped.defaultProps = {
  showError: false,
};

const mapStateToProps = (state) => ({
  showError: hasErrors(getCaughtErrors(state)),
});

const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryUnwrapped);

export default ErrorBoundary;
