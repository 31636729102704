import React from 'react';
import { map } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import MissingCreativesIndicator from 'components/MissingCreativesIndicator';
import ConfirmedOfferOverviewSection from './ConfirmedOfferOverviewSection';

const MissingCreativesDeadlinesSection = ({ verificationDeadlines }) =>
  verificationDeadlines.length ? (
    <ConfirmedOfferOverviewSection header="No Deadlines Set">
      {map(
        (verificationDeadline) => (
          <div
            className="row mb-5"
            key={verificationDeadline.id}
            data-test={`missing-creatives-deadline-row-${verificationDeadline.id}`}
          >
            <div className="col">
              <div className="d-flex mb-2">
                <div className="d-flex flex-wrap align-items-center">
                  <div
                    className={classNames(
                      'verification-deadline-details-header pr-2 text-really-muted',
                    )}
                  >
                    {verificationDeadline.name}
                  </div>
                </div>
              </div>
              <div className={classNames('mb-1 text-really-muted')} />
              <MissingCreativesIndicator />
            </div>
          </div>
        ),
        verificationDeadlines,
      )}
    </ConfirmedOfferOverviewSection>
  ) : null;

MissingCreativesDeadlinesSection.defaultProps = {
  verificationDeadlines: null,
};

MissingCreativesDeadlinesSection.propTypes = {
  verificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      renderedVerificationReminderInstructions: PropTypes.string.isRequired,
    }),
  ),
};

export default MissingCreativesDeadlinesSection;
