import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import VisibleToSponsorIndicator from 'components/VisibleToSponsorIndicator';
import WithSaveFooter from 'components/WithSaveFooter';
import { Input } from '@teamsnap/snap-ui';
import QuantityGreaterThanVerificationDeadlineWarning from 'components/QuanitityGreaterThanVerificationDeadlineWarning';
import type { Campaign, CampaignCapability } from 'types/campaign';
import DefaultVerificationDeadlines from './DefaultVerificationDeadlines';
import RemoveCampaignCapabilityModal from './RemoveCampaignCapabilityModal';
import './CampaignCapabilityCard.css';

type CampaignCapabilityCardProps = {
  campaign: Campaign;
  campaignCapability: CampaignCapability;
};

function CampaignCapabilityCard(props: CampaignCapabilityCardProps) {
  const { campaign, campaignCapability } = props;
  const { id: campaignCapabilityId, capability } = campaignCapability;
  const {
    id: capabilityId,
    name: capabilityName,
    createDeadlineBasedOnQuantity,
  } = capability;
  const [updateCampaignCapability] = useMutation(MUTATION);

  const onSave = ({
    executionExpectations,
    minimumQuantity,
    quantity,
  }: {
    executionExpectations: string;
    minimumQuantity: number;
    quantity: number;
  }) => {
    updateCampaignCapability({
      variables: {
        input: {
          id: campaignCapability.id,
          executionExpectations,
          minimumQuantity,
          quantity,
        },
      },
    });
  };

  return (
    <div
      className="mt-3 mb-4"
      data-test={`campaign-capability-card-${capabilityId}`}
    >
      <VisibleToSponsorIndicator>
        <div className="campaign-capability-card-header card-header border-bottom-0">
          <h5 className="mb-0">{capabilityName}</h5>
          <RemoveCampaignCapabilityModal
            campaignCapabilityId={campaignCapabilityId}
            capabilityName={capabilityName}
          />
        </div>
        <div className="card-body position-relative">
          <WithSaveFooter
            large={false}
            handleMutation={onSave}
            savedValue={campaignCapability}
          >
            {({ addChanges, value: campaignCapabilityValue }) => {
              const { defaultVerificationDeadlines, minimumQuantity } =
                campaignCapabilityValue;
              const isQuantityGreaterThanVerificationDeadlines =
                createDeadlineBasedOnQuantity &&
                !!minimumQuantity &&
                defaultVerificationDeadlines.length < minimumQuantity;

              return (
                <div className="sui-grid" style={{ gap: 16 }}>
                  <Input
                    type="number"
                    label="Default Quantity"
                    name={`defaultQuantity-${capabilityId}`}
                    onChange={({ target: { value } }) =>
                      addChanges({
                        minimumQuantity: value ? Number(value) : null,
                      })
                    }
                    required
                    value={campaignCapabilityValue.minimumQuantity || ''}
                    helpText="Please enter in the default quantity that should appear in each sports organization's league."
                  />
                  {isQuantityGreaterThanVerificationDeadlines && (
                    <QuantityGreaterThanVerificationDeadlineWarning />
                  )}
                  <Input
                    type="number"
                    label="Total Contracted Asset Quantity"
                    name={`quantity-${capabilityId}`}
                    onChange={({ target: { value } }) =>
                      addChanges({
                        quantity: value ? Number(value) : null,
                      })
                    }
                    required
                    value={campaignCapabilityValue.quantity || ''}
                  />
                  <Input
                    type="textarea"
                    label="Description in Offer to League"
                    name={`executionExpectations-${capabilityId}`}
                    onChange={({ target: { value } }) =>
                      addChanges({
                        executionExpectations: value,
                      })
                    }
                    required
                    value={campaignCapabilityValue.executionExpectations || ''}
                    className="sui-sui-text-desktop-10"
                    // TODO: Remove this once we fully remove toolkit.css file from project
                    inputProps={{
                      style: { lineHeight: '1.6rem' },
                      rows: 6,
                    }}
                  />
                </div>
              );
            }}
          </WithSaveFooter>
          <DefaultVerificationDeadlines
            campaign={campaign}
            campaignCapability={campaignCapability}
          />
        </div>
      </VisibleToSponsorIndicator>
    </div>
  );
}

const MUTATION = gql`
  mutation UpdateCampaignCapability($input: UpdateCampaignCapabilityInput!) {
    updateCampaignCapability(input: $input) {
      campaign {
        id
        campaignCapabilities {
          id
          minimumQuantity
          quantity
          executionExpectations
        }
      }
    }
  }
`;

export default CampaignCapabilityCard;
