import { map } from 'ramda';
import React from 'react';
import Dotdotdot from 'react-dotdotdot';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const OuterDiv = styled.div`
  height: 200px;
`;

const QuoteCol = styled.div`
  background-color: #fafafa;
  padding: 1rem;
  height: 100%;
`;

const renderTestimonial = (testimonial) => (
  <OuterDiv className="col-3" key={testimonial.id}>
    <QuoteCol>
      <blockquote className="blockquote m-0 h-100">
        <Dotdotdot clamp={4}>{testimonial.quote}</Dotdotdot>
        {testimonial.attribution && (
          <footer className="blockquote-footer mt-1" data-test="attribution">
            {testimonial.attribution}
          </footer>
        )}
      </blockquote>
    </QuoteCol>
  </OuterDiv>
);

const renderTestimonials = map(renderTestimonial);

const Testimonials = ({ testimonials }) => (
  <>
    <div className="row justify-content-start mb-4">
      {renderTestimonials(testimonials)}
    </div>
  </>
);

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(LeagueSidePropTypes.testimonial).isRequired,
};

export default Testimonials;
