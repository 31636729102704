import { connect } from 'react-redux';
import { getAuthentication } from 'modules';
import { getRole } from 'modules/authentication';
import {
  isConfirmed,
  isProposedOrRejectedByLeague,
  REMOVABLE,
} from 'utils/offer';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import { equalsSuperadmin } from 'utils/user';
import type { Offer } from 'types/offer';
import Icon from 'components/Icon';
import Dropddown from 'components/Dropdown';
import RemoveFromCampaignModal from 'components/RemoveFromCampaignModal';
import SendSponsorablePropertyOnboardingEmailsModal from 'components/sponsorablePropertyTables/SendSponsorablePropertyOnboardingEmailsModal';
import MarkAsConfirmedModal from './mark-as-confirmed-modal';
import MoveOfferToCampaignModal from './move-offer-to-campaign-modal';
import MarkAsCanceledModal from './mark-as-canceled-modal';

type ActionsDropdownProps = {
  offer: Offer;
  refetchSponsorship: () => void;
  userRole: string;
};

function ActionsDropdown(props: ActionsDropdownProps) {
  const { offer, refetchSponsorship, userRole } = props;
  const offerIsConfirmed = isConfirmed(offer as Record<string, any>);
  const offerIsProposedOrRejectedByLeague = isProposedOrRejectedByLeague(offer);
  const offerIsRemovable = REMOVABLE.includes(offer.status);
  const userIsSuperadmin = equalsSuperadmin(userRole);
  const showDropdown = offerIsConfirmed || offerIsRemovable;

  if (!showDropdown) return null;

  const { campaign } = offer;

  /*
    Refactor to use ActionMenu from snap-ui will require re-think how modals and its triggers are handled
  */
  return (
    <Dropddown
      menuProps={{
        className: 'dropdown-menu dropdown-menu-right',
        'data-test': 'actions-dropdown-menu',
      }}
      toggleProps={{
        children: 'Actions',
        className: 'btn btn-large btn-secondary dropdown-toggle',
      }}
    >
      {offer.sponsorshipApplicationMagicToken && (
        <a
          href={`/qualify/${offer.sponsorshipApplicationMagicToken}`}
          target="_blank"
          rel="noreferrer"
        >
          <button className="btn btn-link dropdown-item" type="button">
            <Icon className="mr-2" value="arrow-right" />
            View application
          </button>
        </a>
      )}
      {offerIsConfirmed && (
        <SendSponsorablePropertyOnboardingEmailsModal
          id="send-onboarding-email-modal"
          offers={[offer]}
          toggleClassName="btn btn-link dropdown-item"
        />
      )}
      {userIsSuperadmin && (
        <>
          {offerIsProposedOrRejectedByLeague && (
            <MarkAsConfirmedModal
              id="mark-as-confirmed-modal"
              offer={offer}
              refetchSponsorship={refetchSponsorship}
              toggleClassName="btn btn-link dropdown-item"
            />
          )}
          {offerIsConfirmed && (
            <>
              <MoveOfferToCampaignModal
                id="move-offer-to-campaign-modal"
                offer={offer}
                refetchSponsorship={refetchSponsorship}
                toggleClassName="btn btn-link dropdown-item"
              />
              <MarkAsCanceledModal
                id="mark-as-canceled-modal"
                offer={offer}
                refetchSponsorship={refetchSponsorship}
                toggleClassName="btn btn-link text-danger dropdown-item"
              />
            </>
          )}
        </>
      )}
      {offerIsRemovable && (
        <RemoveFromCampaignModal
          offers={[offer]}
          pushUrl={`${CAMPAIGN_PLANNER_PATH}/${campaign.sponsor.id}/campaigns/${campaign.id}/included-leagues`}
        />
      )}
    </Dropddown>
  );
}

const wrapWithUserRole = connect((state) => ({
  userRole: getRole(getAuthentication(state)),
}));

export default wrapWithUserRole(ActionsDropdown);
