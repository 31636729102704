import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getAddSponsor } from 'modules';
import { save } from 'modules/sponsor/add';
import AddButton from 'components/AddButton';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import NameInput from 'components/formInputs/NameInput';
import Page from 'components/Page';
import RequiredLabel from 'components/RequiredLabel';
import RestrictTo from 'components/RestrictTo';

const AddSponsor = (props) => {
  const { saving, saved, save: saveFunc } = props;

  const [name, setName] = useState(null);

  function handleSubmit(event) {
    event.preventDefault();
    saveFunc({ name });
  }

  return (
    <Page>
      <RestrictTo roles={['admin']}>
        <FieldhousePageTitle title="Add Sponsor">
          <div className="card mt-4">
            <form onSubmit={handleSubmit}>
              <div className="card-body">
                <h3>Add Sponsor</h3>
                <NameInput
                  isRequired
                  loading={saving || saved}
                  name={name}
                  onChange={({ target: { value } }) => setName(value)}
                />
                <RequiredLabel />
              </div>
              <div className="card-footer text-right">
                <AddButton
                  added={saved}
                  adding={saving}
                  disabled={!name}
                  subject="Sponsor"
                />
              </div>
            </form>
          </div>
        </FieldhousePageTitle>
      </RestrictTo>
    </Page>
  );
};

AddSponsor.propTypes = {
  save: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => getAddSponsor(state);
const mapDispatchToProps = (dispatch) => bindActionCreators({ save }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddSponsor);
