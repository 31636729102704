import React, { useContext, useState } from 'react';
import { Mutation } from 'react-apollo';
import { isEmpty } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import MultiSelect from 'components/MultiSelect';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import RefetchDataContext from './RefetchDataContext';

function CreateLocationsByMarketsModalUnwrapped({
  create,
  markets,
  locationFilterId,
}) {
  const [selectedMarketIds, setSelectedMarketIds] = useState([]);
  const refetchData = useContext(RefetchDataContext);

  async function onSubmit() {
    await create(selectedMarketIds);
    refetchData();
    setSelectedMarketIds([]);
  }

  const { error, loading, handleSubmit } = useSubmit(onSubmit);

  return (
    <ModalForm
      id={`create-locations-by-markets-${locationFilterId}`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Add'),
        disabled: isEmpty(selectedMarketIds) || loading,
      }}
      title="Add Markets"
      toggleProps={{
        className: 'dropdown-item',
        children: 'Markets',
      }}
    >
      <div className="form-group">
        <MultiSelect
          id="markets-input"
          models={markets}
          selectedIds={selectedMarketIds}
          onChange={setSelectedMarketIds}
        />
      </div>
    </ModalForm>
  );
}

CreateLocationsByMarketsModalUnwrapped.propTypes = {
  create: PropTypes.func.isRequired,
  locationFilterId: PropTypes.number.isRequired,
  markets: LeagueSidePropTypes.geometries.isRequired,
};

const MUTATION = gql`
  mutation CreateLocationsByMarkets(
    $input: AddGeometriesToLocationFilterInput!
  ) {
    addGeometriesToLocationFilter(input: $input) {
      locationFilter {
        id
      }
    }
  }
`;

const CreateLocationsByMarketsModal = ({ markets, locationFilterId }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CreateLocationsByMarketsModalUnwrapped
        create={(marketIds) =>
          mutate({
            variables: { input: { locationFilterId, geometryIds: marketIds } },
          })
        }
        locationFilterId={locationFilterId}
        markets={markets}
      />
    )}
  </Mutation>
);

CreateLocationsByMarketsModal.propTypes = {
  markets: LeagueSidePropTypes.geometries.isRequired,
  locationFilterId: PropTypes.number.isRequired,
};

export default CreateLocationsByMarketsModal;
