import { useEffect } from 'react';
import { Inputs } from 'pages/qualify/types';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useWizardContext } from 'pages/qualify/provider';
import WizardStep from '../wizard-step';
import WizardInput from '../wizard-input';

export default function AdditionalInformationStep() {
  const { tokenData, values, organizationData, setValue, register } =
    useWizardContext();
  const [performMutation] = useMutation(MUTATION);

  const modifyValuesAfterMutation = (
    response: any,
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    ...data,
    customResponses: data?.customResponses?.map((customResponse, index) => ({
      ...customResponse,
      id: response?.data?.upsertCustomResponses?.customResponses?.[index]?.id,
    })),
  });

  useEffect(() => {
    setValue(
      'customResponses',
      organizationData.sponsorshipApplication.customQuestions.map(
        (question) => {
          const response = values.customResponses?.find(
            (responseToFind) => responseToFind.customQuestionId === question.id,
          );
          return {
            id: response?.id ?? null,
            customQuestionId: question.id,
            response: response?.response ?? '',
          };
        },
      ),
    );
  }, [values]);

  return (
    <WizardStep
      performMutation={performMutation}
      mutationStaticIds={{
        sponsorshipApplicationId: values.sponsorshipApplicationId,
        offerId: tokenData.offer_id,
      }}
      modifyValuesAfterMutation={modifyValuesAfterMutation}
    >
      <WizardStep.Content
        title={`Please share the following details about your ${tokenData.season} ${tokenData.year} season`}
      >
        <div className="sui-grid" style={{ maxWidth: 400, gap: 16 }}>
          {organizationData.sponsorshipApplication.customQuestions.map(
            (question, index) => (
              <>
                <input
                  type="hidden"
                  {...register(`customResponses.${index}.id`)}
                />
                <input
                  type="hidden"
                  {...register(`customResponses.${index}.customQuestionId`)}
                />
                <WizardInput
                  type="text"
                  name={`customResponses.${index}.response`}
                  label={question.question}
                  rules={{
                    required: 'This question is required',
                  }}
                />
              </>
            ),
          )}
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation upsertCustomResponses($input: UpsertCustomResponsesInput!) {
    upsertCustomResponses(input: $input) {
      __typename
      id
      customResponses {
        id
      }
    }
  }
`;
