import React from 'react';
import PropTypes from 'prop-types';

const addButton = (subject, disabled, dataTest) => (
  <button
    className="btn btn-primary"
    data-test={dataTest || 'add-button'}
    disabled={disabled}
    type="submit"
  >
    {subject ? `Add ${subject}` : 'Add'}
  </button>
);

const addingButton = (subject) => (
  <button
    className="btn btn-secondary"
    data-test="adding-button"
    disabled
    type="submit"
  >
    {`${subject ? `Adding ${subject}` : 'Adding'}...`}
  </button>
);

const addedButton = (subject) => (
  <button
    className="btn btn-success"
    data-test="added-button"
    disabled
    type="submit"
  >
    {`${subject ? `Added ${subject}` : 'Added'}!`}
  </button>
);

const AddButton = ({ added, adding, subject, dataTest, disabled }) => {
  if (adding) {
    return addingButton(subject);
  }

  return added ? addedButton(subject) : addButton(subject, disabled, dataTest);
};

AddButton.propTypes = {
  dataTest: PropTypes.string,
  disabled: PropTypes.bool,
  added: PropTypes.bool,
  adding: PropTypes.bool,
  subject: PropTypes.string,
};

AddButton.defaultProps = {
  dataTest: undefined,
  disabled: false,
  added: false,
  adding: false,
  subject: null,
};

export default AddButton;
