import React from 'react';
import PropTypes from 'prop-types';
import useGetTeamSnapLogoByRole from 'hooks/useGetTeamSnapLogoByRole';

const BrandBar = ({ brandHref, children }) => {
  const [logo] = useGetTeamSnapLogoByRole();

  return (
    <div className="bg-success">
      <div className="container pl-0">
        <nav className="navbar navbar-expand-md navbar-dark px-0">
          <a className="navbar-brand pt-1 pb-1" href={brandHref}>
            <img src={logo} alt="TeamSnap" width="180" />
          </a>
          {children}
        </nav>
      </div>
    </div>
  );
};

BrandBar.propTypes = {
  brandHref: PropTypes.string,
  children: PropTypes.node,
};

BrandBar.defaultProps = {
  brandHref: '/',
  children: undefined,
};

export default BrandBar;
