import gql from 'graphql-tag';
import ASSET_FRAGMENT from 'fragments/AssetFragment';
import SPONSORSHIP_INSIGHT_REPORT_FRAGMENT from 'fragments/SponsorshipInsightReportFragment';

const SPONSORABLE_PROPERTY_TABLE_OFFER_FRAGMENT = gql`
  fragment SponsorablePropertyTableOfferFragment on Offer {
    id
    fundraiserConflict {
      conflict
      conflictingOfferId
      conflictingOfferStatus
    }
    surveyConflict {
      conflict
      conflictingOfferId
      conflictingOfferStatus
    }
    recentComment {
      id
      createdAt
      description
    }
  }
`;

const OFFER_COUNT_REPORT_FRAGMENT = gql`
  fragment OfferCountsReportFragment on Offer {
    offerCountsReport {
      prospecting
      qualified
      proposed
      confirmed
    }
  }
`;

export const REMOVED_OFFER_FRAGMENT = gql`
  fragment RemovedOfferFragment on Offer {
    ...SponsorablePropertyTableOfferFragment
    removalReason
    sponsorableProperty {
      id
      lat @include(if: $withLatLon)
      lon @include(if: $withLatLon)
      name
      dataConfidence
      organization {
        id
        dataConfidence
        sportsManagementSoftwareId
        websiteUrl
      }
    }
    status
  }
  ${SPONSORABLE_PROPERTY_TABLE_OFFER_FRAGMENT}
`;

export const PROSPECTING_OFFER_FRAGMENT = gql`
  fragment ProspectingOfferFragment on Offer {
    ...SponsorablePropertyTableOfferFragment
    ...OfferCountsReportFragment
    communicatedToLeague
    numberOfSurveyViews
    preQualSurveyResponses {
      id
      booleanResponse
    }
    campaign {
      id
      season
      sponsor {
        id
        name
      }
      year
    }
    sponsorableProperty {
      id
      lat @include(if: $withLatLon)
      lon @include(if: $withLatLon)
      name
      dataConfidence
      organization {
        id
        canReceiveEmails
        engagementStatus
        city
        dataConfidence
        sportsManagementSoftwareId
        hubspotCompanyId
        name
        sponsorshipInsightReport {
          ...SponsorshipInsightReportFragment
        }
        state
        websiteUrl
      }
      sponsorablePropertyPackagesCount
    }
    surveyOpenedAt
    surveySentAt
    sponsorshipApplicationSentAt
  }
  ${SPONSORABLE_PROPERTY_TABLE_OFFER_FRAGMENT}
  ${OFFER_COUNT_REPORT_FRAGMENT}
  ${SPONSORSHIP_INSIGHT_REPORT_FRAGMENT}
`;

export const QUALIFIED_OFFER_FRAGMENT = gql`
  fragment QualifiedOfferFragment on Offer {
    ...SponsorablePropertyTableOfferFragment
    ...OfferCountsReportFragment
    assets {
      ...AssetFragment
    }
    campaign {
      id
      season
      year
    }
    communicatedToLeague
    offerSentAt
    preQualSurveyResponses {
      id
      booleanResponse
    }
    sponsorableProperty {
      id
      lat @include(if: $withLatLon)
      lon @include(if: $withLatLon)
      name
      dataConfidence
      organization {
        id
        canReceiveEmails
        engagementStatus
        city
        dataConfidence
        sportsManagementSoftwareId
        name
        state
        websiteUrl
      }
    }
    sponsorshipApplicationSentAt
    value
  }
  ${SPONSORABLE_PROPERTY_TABLE_OFFER_FRAGMENT}
  ${OFFER_COUNT_REPORT_FRAGMENT}
  ${ASSET_FRAGMENT}
`;

export const PROPOSED_OFFER_FRAGMENT = gql`
  fragment ProposedOfferFragment on Offer {
    ...SponsorablePropertyTableOfferFragment
    ...OfferCountsReportFragment
    assets {
      ...AssetFragment
    }
    communicatedToLeague
    numberOfOfferViews
    offerOpenedAt
    offerSentAt
    sponsorableProperty {
      id
      lat @include(if: $withLatLon)
      lon @include(if: $withLatLon)
      name
      dataConfidence
      organization {
        id
        canReceiveEmails
        engagementStatus
        dataConfidence
        sportsManagementSoftwareId
        name
        websiteUrl
      }
    }
    status
    value
  }
  ${SPONSORABLE_PROPERTY_TABLE_OFFER_FRAGMENT}
  ${OFFER_COUNT_REPORT_FRAGMENT}
  ${ASSET_FRAGMENT}
`;

export const CONFIRMED_OFFER_FRAGMENT = gql`
  fragment ConfirmedOfferFragment on Offer {
    ...SponsorablePropertyTableOfferFragment
    ...OfferCountsReportFragment
    checks {
      id
      amount
      status
    }
    hasVerifiedPhysicalAssetVerificationDeadline
    onboardingEmailSentAt
    sponsorableProperty {
      id
      lat @include(if: $withLatLon)
      lon @include(if: $withLatLon)
      name
      dataConfidence
      organization {
        id
        canReceiveEmails
        engagementStatus
        dataConfidence
        sportsManagementSoftwareId
        name
        websiteUrl
      }
    }
    value
    verificationDeadlines {
      id
    }
  }
  ${SPONSORABLE_PROPERTY_TABLE_OFFER_FRAGMENT}
  ${OFFER_COUNT_REPORT_FRAGMENT}
`;

export const CAMPAIGN_TARGETING_OFFER_FRAGMENT = gql`
  fragment CampaignTargetingOfferFragment on Offer {
    id
    communicatedToLeague
    sponsorableProperty {
      id
      name
      organization {
        id
        name
      }
    }
    status
  }
`;
