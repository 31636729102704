import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import GenericCreativeContentModal from 'components/GenericCreativeContentModal';
import { CREATIVE_CONTENT_TEMPLATE_FRAGMENT } from '../getCampaign';

export const UpdateCreativeContentTemplateModalUnwrapped = ({
  creativeContentTemplate,
  defaultVerificationDeadlineId,
  toggleClassName,
  update,
}) => (
  <GenericCreativeContentModal
    deadlineId={defaultVerificationDeadlineId}
    deadlineType="defaultVerificationDeadline"
    genericCreativeContent={creativeContentTemplate}
    onSubmit={update}
    toggleProps={{
      className: toggleClassName,
      children: <Icon value="pencil" />,
      title: 'Update',
    }}
  />
);

UpdateCreativeContentTemplateModalUnwrapped.propTypes = {
  creativeContentTemplate: PropTypes.shape({
    id: PropTypes.number,
    content: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  defaultVerificationDeadlineId: PropTypes.number.isRequired,
  toggleClassName: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation UpdateCreativeContentTemplate(
    $input: CreativeContentTemplateInput!
  ) {
    updateCreativeContentTemplate(input: $input) {
      creativeContentTemplate {
        ...CreativeContentTemplateFragment
      }
    }
  }
  ${CREATIVE_CONTENT_TEMPLATE_FRAGMENT}
`;

const UpdateCreativeContentTemplateModal = ({
  creativeContentTemplate,
  defaultVerificationDeadlineId,
  toggleClassName,
}) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <UpdateCreativeContentTemplateModalUnwrapped
        creativeContentTemplate={creativeContentTemplate}
        defaultVerificationDeadlineId={defaultVerificationDeadlineId}
        toggleClassName={toggleClassName}
        update={({ content, name }) =>
          mutate({
            variables: {
              input: { content, name, id: Number(creativeContentTemplate.id) },
            },
          })
        }
      />
    )}
  </Mutation>
);

UpdateCreativeContentTemplateModal.propTypes = {
  creativeContentTemplate: PropTypes.shape({
    id: PropTypes.number,
    content: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  defaultVerificationDeadlineId: PropTypes.number.isRequired,
  toggleClassName: PropTypes.string,
};

UpdateCreativeContentTemplateModal.defaultProps = {
  toggleClassName: 'btn btn-link p-0',
};

export default UpdateCreativeContentTemplateModal;
