import * as React from 'react';
import { getInputId } from 'utils/id';
import { RequiredAsterisk } from 'components/RequiredLabel';

interface Props extends React.HTMLAttributes<HTMLElement> {
  instructions: string;
  loading?: boolean;
  prefix?: string;
  setInstructions(instructions: string): void;
}

const VerificationReminderInstructionsInput: React.FunctionComponent<Props> = ({
  instructions,
  loading,
  prefix,
  setInstructions,
}: Props) => {
  const inputId = getInputId([prefix, 'verification-reminder-instructions']);

  return (
    <div className="form-group mt-3">
      <label className="mb-0" htmlFor={inputId}>
        Verification Reminder Instructions
        <RequiredAsterisk />
      </label>
      <ul className="mb-1">
        <li>
          <small>
            To insert the sponsor&#39;s name, use&nbsp;
            <code className="text-inherit">{'{{sponsor_name}}'}</code>
          </small>
        </li>
        <li>
          <small>
            To insert the verification email address, use&nbsp;
            <code className="text-inherit">
              {'{{verification_email_address}}'}
            </code>
          </small>
        </li>
      </ul>
      <textarea
        className="form-control"
        id={inputId}
        onChange={({ target: { value } }) => setInstructions(value)}
        value={instructions}
        disabled={loading}
      />
    </div>
  );
};

VerificationReminderInstructionsInput.defaultProps = {
  loading: false,
  prefix: undefined,
};

export default VerificationReminderInstructionsInput;
