import gql from 'graphql-tag';

export default gql`
  fragment ProgramDetailsSponsorablePropertyFragment on SponsorableProperty {
    id
    competitiveness {
      id
      name
    }
    endDate
    female
    male
    minAge
    maxAge
    name
    numberOfPlayers
    dataConfidence
    season
    minAge
    maxAge
    sport {
      id
      name
    }
    startDate
    dataConfidence
  }
`;
