import React from 'react';
import { isNil } from 'ramda';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Card = (props) => {
  const {
    bodyPadding,
    border,
    bottomMargin,
    children,
    footer,
    topMargin,
    verticalMargins,
    ...rest
  } = props;
  const bottomMarginIsNil = isNil(bottomMargin);
  const topMarginIsNil = isNil(topMargin);
  const uniformVerticalMargins = bottomMarginIsNil && topMarginIsNil;

  return (
    <div
      className={classNames('card d-md-block d-lg-block', {
        [`my-${verticalMargins}`]: uniformVerticalMargins,
        [`mb-${bottomMarginIsNil ? verticalMargins : bottomMargin}`]:
          !uniformVerticalMargins,
        [`mt-${topMarginIsNil ? verticalMargins : topMargin}`]:
          !uniformVerticalMargins,
        [`border-${border}`]: !!border,
      })}
      {...rest}
    >
      <div
        className={classNames('card-body', {
          [`p-${bodyPadding}`]: !isNil(bodyPadding),
        })}
      >
        {children}
      </div>
      {!!footer && (
        <div
          className={classNames('card-footer', {
            [`border-${border}`]: !!border,
          })}
        >
          {footer}
        </div>
      )}
    </div>
  );
};

Card.propTypes = {
  bodyPadding: PropTypes.number,
  border: PropTypes.string,
  bottomMargin: PropTypes.number,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  topMargin: PropTypes.number,
  verticalMargins: PropTypes.number,
};

Card.defaultProps = {
  bodyPadding: undefined,
  border: undefined,
  bottomMargin: undefined,
  footer: undefined,
  topMargin: undefined,
  verticalMargins: 4,
};

export default Card;
