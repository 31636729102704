import { Redirect } from 'react-router-dom';
import { useWizardContext } from '../provider';

export default function WizardBody() {
  const { getActiveStepComponent, wizardMap, token } = useWizardContext();
  const stepToRender = getActiveStepComponent();
  if (wizardMap && !stepToRender) {
    return <Redirect to={`/qualify/${token}?step=0-0`} />;
  }
  return stepToRender;
}
