import React from 'react';
import { useMutation } from 'react-apollo';
import { map } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { useSubmit } from 'utils/hooks';
import { pluckId } from 'utils/misc';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import { sortBySponsorablePropertyName } from 'utils/offer';
import useUserPilotTracking from 'hooks/useUserPilotTracking';

const MUTATION = gql`
  mutation MarkAsQualified($input: MarkAsQualifiedInput!) {
    markAsQualified(input: $input)
  }
`;

const renderOffer = (offer) => (
  <li key={offer.id}>{offer.sponsorableProperty.name}</li>
);

const MarkAsQualifiedModal = ({ id, offers, onSubmit, toggleClassName }) => {
  const [mutate] = useMutation(MUTATION);
  const { sendUserPilotTrackingEvent } = useUserPilotTracking();

  const markAsQualified = () => {
    offers.forEach((offer) => {
      sendUserPilotTrackingEvent('PreQual Offer Marked Qualified', {
        campaign_id: offer.campaign.id,
        sponsor_name: offer.campaign.sponsor.name,
        offer_id: offer.id,
        sponsorable_property_name: offer.sponsorableProperty.name,
      });
    });

    return mutate({
      variables: { input: { offerIds: pluckId(offers) } },
    }).then(onSubmit);
  };

  const { error, loading, handleSubmit } = useSubmit(markAsQualified);

  return (
    <ModalForm
      id={id}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{ children: 'Mark as Qualified' }}
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon className="mr-1" value="check" />
            Mark as Qualified
          </>
        ),
      }}
      title="Mark as Qualified?"
    >
      Are you sure you want to mark the following sponsorable propreties as
      qualified for this campaign?
      <ul>{map(renderOffer, sortBySponsorablePropertyName(offers))}</ul>
    </ModalForm>
  );
};

MarkAsQualifiedModal.propTypes = {
  id: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  onSubmit: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string.isRequired,
};

export default MarkAsQualifiedModal;
