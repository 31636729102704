import gql from 'graphql-tag';
import SPONSORABLE_PROPERTY_CAPABILITY_PACKAGE_FRAGMENT from './SponsorablePropertyCapabilityPackageFragment';

export const ORGANIZATION_PACKAGE_MINIMAL_FRAGMENT = gql`
  fragment OrganizationPackageMinimalFragment on OrganizationPackage {
    id
    name
    packageDescription
    price
  }
`;

const ORGANIZATION_PACKAGE_FRAGMENT = gql`
  fragment OrganizationPackageFragment on OrganizationPackage {
    ...OrganizationPackageMinimalFragment
    organizationCapabilityPackages {
      ...SponsorablePropertyCapabilityPackageFragment
    }
  }
  ${ORGANIZATION_PACKAGE_MINIMAL_FRAGMENT}
  ${SPONSORABLE_PROPERTY_CAPABILITY_PACKAGE_FRAGMENT}
`;

export default ORGANIZATION_PACKAGE_FRAGMENT;
