import React from 'react';
import { useQuery } from 'react-apollo';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import { all, find, map, pathEq, reject } from 'ramda';
import gql from 'graphql-tag';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { pluckId } from 'utils/misc';
import { isConfirmed } from 'utils/offer';
import { compact } from 'utils/array';
import SponsorablePropertyCard from './campaignSponsorableProperties/SponsorablePropertyCard';
import AddAllToCampaignButton from './campaignSponsorableProperties/AddAllToCampaignButton';
import RemoveAllFromCampaignButton from './campaignSponsorableProperties/RemoveAllFromCampaignButton';

const SponsorablePropertiesList = styled.div`
  flex-grow: 1;
`;

const findOfferBySponsorablePropertyId = (id, offers) =>
  find(pathEq(['sponsorableProperty', 'id'], id), offers);
const notConfirmed = reject(isConfirmed);

const CAMPAIGN_WITH_OFFERS_QUERY = gql`
  query CampaignWithOffers($id: Int!) {
    campaign(id: $id) {
      id
      offers {
        id
        status
      }
    }
  }
`;

const CampaignSponsorableProperties = (props) => {
  const {
    addedSponsorablePropertyIds,
    addedOffers,
    campaign,
    sponsorableProperties,
    removedSponsorablePropertyIds,
    hoveredSponsorablePropertyId,
    scrollToIndex,
    setHoveredSponsorablePropertyId,
  } = props;

  const { refetch: onRemove } = useQuery(CAMPAIGN_WITH_OFFERS_QUERY, {
    variables: { id: campaign.id },
  });

  const handleHoveredSponsorablePropertyId = (hoveredId) => () =>
    setHoveredSponsorablePropertyId(hoveredId);
  const unsetHoveredSponsorablePropertyId = () =>
    setHoveredSponsorablePropertyId(null);

  const removableOffers = notConfirmed(addedOffers);

  const findAnyOffer = (id) =>
    findOfferBySponsorablePropertyId(id, campaign.offers);
  const findRemovableOffer = (id) =>
    findOfferBySponsorablePropertyId(id, removableOffers);

  const sponsorablePropertyIds = pluckId(sponsorableProperties);
  const allRemoved = all(
    (id) => removedSponsorablePropertyIds.has(id),
    sponsorablePropertyIds,
  );
  const allAddedOrRemoved = all(
    (id) =>
      addedSponsorablePropertyIds.has(id) ||
      removedSponsorablePropertyIds.has(id),
    sponsorablePropertyIds,
  );
  const removableListedOffers = compact(
    map(findRemovableOffer, sponsorablePropertyIds),
  );

  const renderSponsorablePropertyCard = (sponsorableProperty, style) => {
    const offer = findAnyOffer(sponsorableProperty.id);

    return (
      <div className="pb-0 pr-2" key={sponsorableProperty.id} style={style}>
        <SponsorablePropertyCard
          campaignId={campaign.id}
          isHovered={sponsorableProperty.id === hoveredSponsorablePropertyId}
          sponsorableProperty={sponsorableProperty}
          offer={offer}
          onMouseEnter={handleHoveredSponsorablePropertyId(
            sponsorableProperty.id,
          )}
          onMouseLeave={unsetHoveredSponsorablePropertyId}
          onRemove={onRemove}
        />
      </div>
    );
  };

  return (
    <>
      {!!sponsorablePropertyIds.length && (
        <div className="text-right mb-3">
          <RemoveAllFromCampaignButton
            allRemoved={allRemoved}
            offers={removableListedOffers}
            onRemove={onRemove}
          />
          <AddAllToCampaignButton
            allAddedOrRemoved={allAddedOrRemoved}
            allRemoved={allRemoved}
            campaignId={campaign.id}
            sponsorablePropertyIds={sponsorablePropertyIds}
          />
        </div>
      )}
      <SponsorablePropertiesList data-test="sponsorableProperty-results">
        <AutoSizer>
          {({ height, width }) => (
            <List
              className="react-virtualized-overflow-visible"
              height={height}
              rowCount={sponsorableProperties.length}
              rowHeight={134}
              rowRenderer={({ index, style }) =>
                renderSponsorablePropertyCard(
                  sponsorableProperties[index],
                  style,
                )
              }
              scrollToIndex={scrollToIndex}
              width={width}
            />
          )}
        </AutoSizer>
      </SponsorablePropertiesList>
    </>
  );
};

CampaignSponsorableProperties.propTypes = {
  addedSponsorablePropertyIds: PropTypes.instanceOf(Set).isRequired,
  addedOffers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  campaign: LeagueSidePropTypes.campaign.isRequired,
  hoveredSponsorablePropertyId: PropTypes.number,
  sponsorableProperties: LeagueSidePropTypes.sponsorableProperties.isRequired,
  removedSponsorablePropertyIds: PropTypes.instanceOf(Set).isRequired,
  scrollToIndex: PropTypes.number.isRequired,
  setHoveredSponsorablePropertyId: PropTypes.func.isRequired,
};

CampaignSponsorableProperties.defaultProps = {
  hoveredSponsorablePropertyId: null,
};

export default CampaignSponsorableProperties;
