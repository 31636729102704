import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-apollo';
import { history } from 'store';
import {
  SponsorshipFormViewed,
  SponsorshipFormStepCompleted,
  SponsorshipFormAbandoned,
  ampli,
} from 'ampli';

import {
  Inputs,
  Organization,
  StepIdsEnum,
  StepTypeEnum,
  TokenData,
  WizardContextParams,
  WizardMapType,
} from './types';
import {
  getNextStep,
  getPreviousStep,
  getValuesFromOrganizationDataToPopulate,
} from './utils';
import WizardExit from './_sections/wizard-exit';
import { SAVE_APPLICATION_MUTATION } from './queries';

const WizardContext = createContext<WizardContextParams>(null!);

type WizardProviderProps = PropsWithChildren<{
  wizardMapRaw: WizardMapType;
  organizationData: Organization;
  tokenData: TokenData;
  token: string;
  step?: `${string}-${string}`;
  applicationProgressData: {
    completionPercentage: Organization['sponsorshipApplication']['completionPercentage'];
    applicationProgress: Organization['sponsorshipApplication']['applicationProgress'];
  };
  refetchApplicationProgressQuery: () => void;
}>;

export function WizardProvider(props: WizardProviderProps) {
  const {
    children,
    wizardMapRaw,
    organizationData,
    tokenData,
    token,
    step,
    applicationProgressData,
    refetchApplicationProgressQuery,
  } = props;
  const [values, setValues] = useState<Partial<Inputs>>({});
  const [exitState, setExitState] = useState<boolean>(false);
  const [performSaveAndExit] = useMutation(SAVE_APPLICATION_MUTATION);
  const queryParams = new URLSearchParams(window.location.search);
  const stepQueryParam = queryParams.get('step');
  const [querySectionIndex, queryStepIndex] = stepQueryParam?.split('-') ?? [
    0, 0,
  ];
  const activeSectionIndex = Number(querySectionIndex);
  const activeStepIndex = Number(queryStepIndex);

  const reactHookFormMethods = useForm<Inputs>({
    mode: 'all',
    shouldUnregister: true,
    delayError: 1000,
  });

  const getActiveStepComponent = (): JSX.Element | null => {
    if (!wizardMap) return null;
    const wizardMapKeys = Object.keys(wizardMap ?? {}) as StepTypeEnum[];
    const section = wizardMap[wizardMapKeys[activeSectionIndex ?? 0]];
    if (!section || !section?.steps[activeStepIndex]) return null;
    return section?.steps[activeStepIndex].component ?? null;
  };

  const getActiveStepStruct = (): any => {
    if (!wizardMap) return null;
    const wizardMapKeys = Object.keys(wizardMap ?? {}) as StepTypeEnum[];
    const section = wizardMap[wizardMapKeys[activeSectionIndex ?? 0]];
    if (!section || !section?.steps[activeStepIndex]) return null;
    return (
      { ...section?.steps[activeStepIndex], section: section.title } ?? null
    );
  };

  const getSponsorshipFormViewedPayload = () =>
    new SponsorshipFormViewed({
      fieldhouse_campaign_name: tokenData.campaign_name,
      fieldhouse_campaign_id: tokenData.campaign_id.toString(),
      fieldhouse_organization_id: organizationData.id.toString(),
      fieldhouse_form_chapter_id: getActiveStepStruct().id,
      fieldhouse_form_chapter_name: getActiveStepStruct().name,
      fieldhouse_form_contains_custom_questions:
        organizationData.sponsorshipApplication.customQuestions.length > 0,
      sponsorship_application_id:
        values?.sponsorshipApplicationId?.toString() || '',
      // eslint-disable-next-line no-restricted-globals
      fieldhouse_form_url: location.href,
      fieldhouse_offer_id: tokenData.offer_id.toString(),
      fieldhouse_form_chapter_section: getActiveStepStruct().section, // Add this property
      form_percentage_complete: values?.completionPercentage?.toString() || '0', // Add this property
    });

  const getSponsorshipStepCompletedPayload = () =>
    new SponsorshipFormStepCompleted({
      fieldhouse_campaign_name: tokenData.campaign_name,
      fieldhouse_campaign_id: tokenData.campaign_id.toString(),
      fieldhouse_organization_id: tokenData.id.toString(),
      fieldhouse_form_chapter_id: getActiveStepStruct().id,
      fieldhouse_form_chapter_name: getActiveStepStruct().name,
      sponsorship_application_id:
        values?.sponsorshipApplicationId?.toString() || '',
      // eslint-disable-next-line no-restricted-globals
      fieldhouse_form_url: location.href,
      fieldhouse_offer_id: tokenData.offer_id.toString(),
      count_play_locations_entered: values?.playLocations?.length || 0, // Add this property
      count_programs_entered: values?.sponsorableProperties?.length || 0, // Add this property
      fieldhouse_form_chapter_section: getActiveStepStruct().section, // Add this property
    });

  const handleNextStep = (skipToEnd = false) => {
    if (!wizardMap) return;
    if (skipToEnd) {
      const sectionIndex = Object.keys(wizardMap).length - 1;
      const lastStep = wizardMap[StepTypeEnum.WRAP_UP].steps.length - 1;
      history.push(`/qualify/${token}?step=${sectionIndex}-${lastStep}`);
      return;
    }
    const nextStep = getNextStep(
      wizardMap,
      activeSectionIndex,
      activeStepIndex,
    );
    if (nextStep) {
      history.push(
        `/qualify/${token}?step=${nextStep.sectionIndex}-${nextStep.activeIndex}`,
      );
    }
  };

  const handlePreviousStep = () => {
    if (!wizardMap) return;
    const prevStep = getPreviousStep(
      wizardMap,
      activeSectionIndex,
      activeStepIndex,
    );
    if (prevStep) {
      history.push(
        `/qualify/${token}?step=${prevStep.sectionIndex}-${prevStep.activeIndex}`,
      );
    }
  };

  const handleOnSaveAndExit = async (
    sectionIndex: number,
    stepIndex: number,
  ) => {
    const keys = Object.keys(wizardMap ?? {}) as StepTypeEnum[];
    const stepToSave =
      wizardMap && wizardMap[keys[sectionIndex]].steps[stepIndex];
    await performSaveAndExit({
      context: { headers: { Authorization: `Bearer ${token}` } },
      variables: {
        input: {
          sponsorshipApplicationId: organizationData.sponsorshipApplication.id,
          stepId: stepToSave?.id,
          formData: filterUndefinedValues(reactHookFormMethods.getValues()),
        },
      },
    });
    setExitState(true);
  };

  // Note: Set wizardMap based on different rules
  const wizardMap = useMemo(() => {
    if (wizardMapRaw && values.organizationId) {
      const wizardMapToSet = filterSectionsPerChosenInterests({
        wizardMap: wizardMapRaw,
        sectionNames: [
          StepTypeEnum.BRANDED_COLLATERAL,
          StepTypeEnum.DIGITAL_ASSETS,
          StepTypeEnum.EXPERIENCES,
        ],
        chosenInterests: values.interests ?? [],
      });
      // Filter out the custom questions step if there are no data in it
      if (!organizationData.sponsorshipApplication.customQuestions.length) {
        wizardMapToSet[StepTypeEnum.SEASON_INFORMATION].steps = wizardMapToSet[
          StepTypeEnum.SEASON_INFORMATION
        ].steps.filter(
          (stepToCompare) => stepToCompare.id !== StepIdsEnum.CUSTOM_QUESTIONS,
        );
      }
      return wizardMapToSet;
    }
    return null;
  }, [values.organizationId, values.interests, wizardMapRaw]);

  // Note: Perform actions based on organizationData useEffect
  useEffect(() => {
    if (organizationData?.id) {
      // Populate values from organizationData
      setValues(getValuesFromOrganizationDataToPopulate(organizationData));
    }
  }, [organizationData?.id]);

  // Note: Redirect to savedStepId if it exists useEffect
  useEffect(() => {
    if (!organizationData || !wizardMap) return;
    const { savedStepId } = organizationData.sponsorshipApplication;
    if (!savedStepId) return;
    const stepToRedirect = getStepQueryParamBasedOnStepId(
      wizardMap,
      savedStepId,
    );
    if (step !== stepToRedirect) {
      history.push(`/qualify/${token}?step=${stepToRedirect}`);
    }
  }, [organizationData, wizardMap]);

  useEffect(() => {
    const sendAbandonedEvent = (event: any) => {
      event.preventDefault();

      ampli.track(
        new SponsorshipFormAbandoned({
          fieldhouse_campaign_name: values.name ?? '',
          fieldhouse_campaign_id: '',
          fieldhouse_organization_id: tokenData.id.toString(),
          fieldhouse_form_chapter_id: getActiveStepStruct()?.id,
          fieldhouse_form_chapter_name: getActiveStepStruct()?.name,
          sponsorship_application_id:
            values?.sponsorshipApplicationId?.toString() || '',
          // eslint-disable-next-line no-restricted-globals
          fieldhouse_form_url: location.href,
          fieldhouse_offer_id: '',
          fieldhouse_form_chapter_section: getActiveStepStruct()?.section, // Add this property
        }),
      );
    };

    if (window) {
      window.onbeforeunload = sendAbandonedEvent;
    }
  }, []); // Fix: Pass an empty object as the second argument

  return (
    <WizardContext.Provider
      value={{
        ...reactHookFormMethods,
        activeSectionIndex,
        activeStepIndex,
        handleNextStep,
        handlePreviousStep,
        organizationData,
        getActiveStepComponent,
        getActiveStepStruct,
        setValues,
        token,
        tokenData,
        values,
        wizardMap,
        handleOnSaveAndExit,
        applicationProgressData,
        refetchApplicationProgressQuery,
        getSponsorshipFormViewedPayload,
        getSponsorshipStepCompletedPayload,
      }}
    >
      {!wizardMap && 'Loading...'}
      {exitState ? (
        <WizardExit name={organizationData.name} />
      ) : (
        wizardMap && children
      )}
    </WizardContext.Provider>
  );
}

export function useWizardContext() {
  return useContext(WizardContext);
}

function filterSectionsPerChosenInterests(params: {
  wizardMap: WizardMapType;
  sectionNames: StepTypeEnum[];
  chosenInterests: string[];
}) {
  const { wizardMap, sectionNames, chosenInterests } = params;
  const wizardMapToSet = { ...wizardMap };
  sectionNames.forEach((sectionName) => {
    const section = wizardMapToSet[sectionName];
    if (!section) return;
    const steps = section.steps.filter(
      (step) => step.id && chosenInterests.includes(step.id),
    );
    wizardMapToSet[sectionName] = { ...section, steps };
  });
  return wizardMapToSet;
}

// Note: This function is used to filter out empty values from ReactHookForm.getValues()
function filterUndefinedValues<T extends object>(obj: T) {
  const keys = Object.keys(obj) as (keyof T)[];
  return keys.reduce((acc, key) => {
    if (obj[key] === undefined) return acc;
    if (
      Array.isArray(obj[key]) &&
      (obj[key] as object[]).length > 0 &&
      typeof (obj[key] as object[])[0] === 'object'
    ) {
      const arrayFound = obj[key] as object[];
      const filteredArray = arrayFound.map((item) =>
        filterUndefinedValues(item),
      );
      // Check if filteredArray has empty objects otherwise ignore it
      if (filteredArray.some((item) => Object.keys(item).length)) {
        acc[key] = filteredArray as T[keyof T];
      } else {
        return acc;
      }
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {} as T);
}

function getStepQueryParamBasedOnStepId(
  wizardMap: WizardMapType,
  stepId: StepIdsEnum,
) {
  const wizardMapKeys = Object.keys(wizardMap) as StepTypeEnum[];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < wizardMapKeys.length; i++) {
    const section = wizardMap[wizardMapKeys[i]];
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < section.steps.length; j++) {
      if (section.steps[j].id === stepId) {
        return `${i}-${j}`;
      }
    }
  }
  return '0-0';
}
