import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { ORGANIZATION_TYPES_LIST_QUERY } from 'pages/qualify/queries';
import { Inputs } from 'pages/qualify/types';
import { useWizardContext } from 'pages/qualify/provider';
import WizardStep from '../wizard-step';
import WizardInput from '../wizard-input';

export default function NonProfitStatusStep() {
  const { tokenData, token, values } = useWizardContext();
  const { data: organizationListData } = useQuery<{
    organizationTypes: { id: string; name: string }[];
  }>(ORGANIZATION_TYPES_LIST_QUERY, {
    context: { headers: { Authorization: `Bearer ${token}` } },
  });
  const [performMutation] = useMutation(MUTATION);

  const modifyInputsBeforeMutation = (
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    ...data,
    organizationTypeId: Number(data.organizationTypeId),
  });

  const organizationListParsed = useMemo(() => {
    if (!organizationListData) return [];
    const optionsList = organizationListData.organizationTypes.map(
      (organizationType) => ({
        value: organizationType.id,
        label: organizationType.name,
      }),
    );
    return optionsList;
  }, [organizationListData]);

  return (
    <WizardStep
      performMutation={performMutation}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId: values.sponsorshipApplicationId,
        offerId: tokenData.offer_id,
      }}
      modifyInputsBeforeMutation={modifyInputsBeforeMutation}
    >
      <WizardStep.Content title="Indicate your organization's tax classification">
        <div className="sui-grid" style={{ maxWidth: 400, gap: 16 }}>
          <WizardInput
            type="select"
            name="organizationTypeId"
            label="Organization Type"
            rules={{
              required: 'Organization Type is required',
            }}
            options={organizationListParsed}
          />
        </div>
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation updateOrganizationInSponsorshipApplication(
    $input: UpdateOrganizationInSponsorshipApplicationInput!
  ) {
    updateOrganizationInSponsorshipApplication(input: $input) {
      id
    }
  }
`;
