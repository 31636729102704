import React, { useCallback, useState } from 'react';
import RichTextEditor from 'react-rte';
import { equals } from 'ramda';
import PropTypes from 'prop-types';

export const isEmpty = equals('<p><br></p>');

function RichTextInput({ disabled, initialValue, onChange }) {
  const [richText, setRichText] = useState(
    RichTextEditor.createValueFromString(initialValue, 'html'),
  );

  function handleChange(value) {
    onChange(value.toString('html'));
    setRichText(value);
  }

  function clear() {
    onChange(null);
    setRichText(RichTextEditor.createEmptyValue());
  }

  return (
    <>
      <button
        className="btn btn-link float-right"
        data-test="clear-rich-text-editor"
        onClick={clear}
        type="button"
      >
        Clear
      </button>
      <RichTextEditor
        data-test="rich-text-editor"
        disabled={disabled}
        onChange={useCallback(handleChange)}
        value={richText}
      />
    </>
  );
}

RichTextInput.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
};

RichTextInput.defaultProps = {
  disabled: false,
  initialValue: '',
  onChange: () => {},
};

export default RichTextInput;
