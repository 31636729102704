import React, { useState } from 'react';
import { equals, isNil } from 'ramda';
import PropTypes from 'prop-types';
import { getId, getInputId } from 'utils/id';
import { useSubmit } from 'utils/hooks';
import {
  invalidMustacheTagError,
  isInvalidInstructions,
} from 'utils/mustacheTagErrors';
import ModalFormWithMustacheTagHandling from 'components/ModalFormWithMustacheTagHandling';
import DeadlineNameInput from 'components/formInputs/DeadlineNameInput';
import DeadlineDateInput from 'components/formInputs/DeadlineDateInput';
import VerificationReminderInstructionsInput from 'components/formInputs/VerificationReminderInstructionsInput';
import DynamicDeadlineInput from 'components/formInputs/DynamicDeadlineInput';
import RequiresCreativesInput from 'components/formInputs/RequiresCreativesInput';

const equalsEmptyString = equals('');

const DefaultVerificationDeadlineModal = ({
  capabilityName,
  capabilityVerificationDeadlines,
  defaultVerificationDeadlineId,
  initialDeadline,
  initialInstructions,
  initialName,
  initialOffset,
  initialOffsetType,
  initialRequiresCreatives,
  modalId,
  onSubmit,
  question,
  toggleProps,
}) => {
  const [deadline, setDeadline] = useState(initialDeadline);
  const [name, setName] = useState(initialName);
  const [instructions, setInstructions] = useState(initialInstructions);
  const [isDynamic, setIsDynamic] = React.useState(!!initialOffsetType);
  const [offsetType, setOffsetType] = useState(initialOffsetType);
  const [offset, setOffset] = useState(initialOffset);
  const [requiresCreatives, setRequiresCreatives] = useState(
    initialRequiresCreatives,
  );

  const prefix = getId([capabilityName, defaultVerificationDeadlineId]);

  const requiredDataPresent = !!(
    deadline &&
    instructions &&
    name &&
    (!isDynamic || (offsetType && offset))
  );

  const submitLabel = defaultVerificationDeadlineId ? 'Update' : 'Add';

  async function asyncSubmit() {
    if (isInvalidInstructions(instructions)) {
      throw new Error(invalidMustacheTagError);
    }

    await onSubmit({
      deadline: deadline.format('YYYY-MM-DD'),
      id: defaultVerificationDeadlineId,
      name,
      verificationReminderInstructions: instructions,
      offset: equalsEmptyString(offset) ? null : Number(offset),
      offsetType: equalsEmptyString(offsetType) ? null : offsetType,
      requiresCreatives,
    });

    if (isNil(defaultVerificationDeadlineId)) {
      setDeadline(null);
      setName(initialName);
      setInstructions('');
      setIsDynamic(false);
      setOffsetType('');
      setOffset('');
      setRequiresCreatives(false);
    }
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  function handleIsDynamic() {
    setIsDynamic(!isDynamic);

    if (!isDynamic) {
      setOffsetType('');
      setOffset('');
    }
  }

  const renderDynamicToggle = () => {
    const inputId = getInputId([prefix, 'dynamic-toggle']);

    return (
      <div className="form-check d-inline-block mt-0">
        <input
          checked={isDynamic}
          className="form-check-input"
          id={inputId}
          onChange={handleIsDynamic}
          type="checkbox"
        />
        <label className="form-check-label align-middle" htmlFor={inputId}>
          <small>Deadline is dynamic</small>
        </label>
      </div>
    );
  };

  return (
    <ModalFormWithMustacheTagHandling
      id={modalId}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      requiredDataPresent={requiredDataPresent}
      submitLabel={submitLabel}
      title={`${submitLabel} Default Verification Deadline for ${capabilityName}`}
      toggleProps={toggleProps}
    >
      {question}
      <DeadlineNameInput
        capabilityName={capabilityName}
        capabilityVerificationDeadlines={capabilityVerificationDeadlines}
        isRequired
        loading={loading}
        name={name}
        onChange={setName}
        prefix={prefix}
      />
      <DeadlineDateInput
        label="Default Deadline"
        deadline={deadline}
        isRequired
        onChange={setDeadline}
      />
      {renderDynamicToggle()}
      {!!isDynamic && (
        <DynamicDeadlineInput
          loading={loading}
          offset={offset}
          offsetType={offsetType}
          prefix={prefix}
          setOffset={setOffset}
          setOffsetType={setOffsetType}
        />
      )}
      <VerificationReminderInstructionsInput
        instructions={instructions}
        loading={loading}
        prefix={prefix}
        setInstructions={setInstructions}
      />
      <RequiresCreativesInput
        disabled={loading}
        onChange={(event) => setRequiresCreatives(event.target.checked)}
        prefix={prefix}
        requiresCreatives={requiresCreatives}
      />
    </ModalFormWithMustacheTagHandling>
  );
};

DefaultVerificationDeadlineModal.propTypes = {
  capabilityName: PropTypes.string.isRequired,
  capabilityVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultVerificationDeadlineId: PropTypes.number,
  initialDeadline: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  initialInstructions: PropTypes.string,
  initialName: PropTypes.string.isRequired,
  initialOffset: PropTypes.string,
  initialOffsetType: PropTypes.string,
  initialRequiresCreatives: PropTypes.bool,
  modalId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  question: PropTypes.node,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
  }).isRequired,
};

DefaultVerificationDeadlineModal.defaultProps = {
  defaultVerificationDeadlineId: undefined,
  initialDeadline: null,
  initialInstructions: '',
  initialOffset: '',
  initialOffsetType: '',
  initialRequiresCreatives: false,
  question: null,
};

export default DefaultVerificationDeadlineModal;
