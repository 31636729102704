import React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { isProposed } from 'utils/offer';
import Loader from 'components/Loader';
import ReviewOffer, {
  ACCEPT_OFFER_MUTATION,
  LEAGUE_REJECT_OFFER_MUTATION,
  REVIEW_OFFER_OFFER_FRAGMENT,
} from 'components/ReviewOffer';
import CONTENT from 'components/reviewOffer/CONTENT';
import ErrorPage from 'components/reviewOffer/ErrorPage';

const OFFER_QUERY = gql`
  query Offer($id: Int!) {
    offer(id: $id) {
      ...ReviewOfferOfferFragment
    }
  }
  ${REVIEW_OFFER_OFFER_FRAGMENT}
`;

function ReviewOfferFromDashboard({ match }) {
  const offerId = Number(match.params.offerId);

  return (
    <Query
      fetchPolicy="network-only"
      query={OFFER_QUERY}
      variables={{ id: offerId }}
    >
      {({ data, error }) => {
        if (error) return <ErrorPage />;

        const offer = data && data.offer;

        if (!offer) return <Loader />;
        if (!isProposed(offer))
          return <ErrorPage loggedIn offerId={offerId} status={offer.status} />;

        return (
          <Mutation mutation={ACCEPT_OFFER_MUTATION}>
            {(mutateAccept) => (
              <Mutation mutation={LEAGUE_REJECT_OFFER_MUTATION}>
                {(mutateReject) => {
                  async function acceptOffer(rest) {
                    await mutateAccept({
                      variables: { input: { offerId, ...rest } },
                    });
                  }

                  async function rejectOffer(rest) {
                    await mutateReject({
                      variables: { input: { offerId, ...rest } },
                    });
                  }

                  return (
                    <ReviewOffer
                      // eslint-disable-next-line react/jsx-no-bind
                      acceptOffer={acceptOffer}
                      initialContent={CONTENT.REVIEW_DETAILS}
                      loggedIn
                      offer={offer}
                      // eslint-disable-next-line react/jsx-no-bind
                      rejectOffer={rejectOffer}
                    />
                  );
                }}
              </Mutation>
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

ReviewOfferFromDashboard.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      offerId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default ReviewOfferFromDashboard;
