import { Link } from 'react-router-dom';
import { map, pipe, uniq } from 'ramda';
import moment from 'moment';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import { sortByName } from 'utils/sort';
import { defaultToEmptyString } from 'utils/string';
import type { Offer } from 'types/offer';
import Alert from 'components/Alert';
import Card from 'components/Card';
import NewWindowLink from 'components/NewWindowLink';
import { hubspotUrl } from 'utils/urls';
import SponsorshipMap from './sponsorship-map';

const renderRelation = (relation: any) => (
  <dd key={relation.id}>{relation.name}</dd>
);
const renderRelations = pipe(uniq, sortByName, map(renderRelation));

type SponsorablePropertyProfileCardProps = {
  offer: Offer;
};

function SponsorablePropertyProfileCard(
  props: SponsorablePropertyProfileCardProps,
) {
  const { offer } = props;
  const { sponsorableProperty } = offer;
  const { organization, sponsorablePropertyCapabilities } = sponsorableProperty;

  return (
    <Card data-test="sponsorable_property-profile">
      <h2 className="card-title mb-1">
        <Link to={`${ORGANIZATIONS_PATH}/${organization.id}`}>
          Organization Profile
        </Link>
      </h2>
      {!!organization.websiteUrl && (
        <div>
          <small>
            <strong>Organization Website: </strong>
            <NewWindowLink
              className="ml-1"
              to={organization.websiteUrl}
              title="Visit organization website"
            >
              {organization.websiteUrl}
            </NewWindowLink>
          </small>
        </div>
      )}
      {!!organization.hubspotCompanyId && (
        <div>
          <small>
            <strong>Hubspot Url: </strong>
            <NewWindowLink
              className="ml-1"
              to={hubspotUrl(organization.hubspotCompanyId)}
            >
              {hubspotUrl(organization.hubspotCompanyId)}
            </NewWindowLink>
          </small>
        </div>
      )}
      <dl className="d-flex justify-content-between flex-wrap mt-4">
        <div className="mr-4 mb-2">
          <dt>Number of Players</dt>
          <dd data-test="number-of-players">
            {sponsorableProperty.numberOfPlayers}
          </dd>
        </div>
        <div className="mr-4 mb-2">
          <dt>Age Range</dt>
          <dd data-test="age-range">
            {`${defaultToEmptyString(
              sponsorableProperty.minAge,
            )} - ${defaultToEmptyString(sponsorableProperty.maxAge)}`}
          </dd>
        </div>
        <div className="mr-4 mb-2">
          <dt>Genders</dt>
          {sponsorableProperty.female && <dd data-test="female">Female</dd>}
          {sponsorableProperty.male && <dd data-test="male">Male</dd>}
        </div>
        <div className="mr-4 mb-2" data-test="season">
          <dt>Season</dt>
          {sponsorableProperty.season &&
            sponsorableProperty.season.charAt(0).toUpperCase() +
              sponsorableProperty.season.slice(1)}
        </div>
        <div className="mr-4 mb-2" data-test="sport">
          <dt>Sport</dt>
          {sponsorableProperty.sport && sponsorableProperty.sport.name}
        </div>
        <div className="mr-4 mb-2" data-test="competitiveness">
          <dt>Competitiveness</dt>
          {sponsorableProperty.competitiveness &&
            sponsorableProperty.competitiveness.name}
        </div>
        <div className="mr-4 mb-2" data-test="start_date">
          <dt>Season Start</dt>
          {sponsorableProperty.startDate &&
            moment(sponsorableProperty.startDate).format('MM-DD-YYYY')}
        </div>
        <div className="mr-4 mb-2" data-test="end_date">
          <dt>Season End</dt>
          {sponsorableProperty.endDate &&
            moment(sponsorableProperty.endDate).format('MM-DD-YYYY')}
        </div>
        <div className="mr-4 mb-2" data-test="capabilities">
          <dt>Capabilities</dt>
          {renderRelations(sponsorablePropertyCapabilities)}
        </div>
      </dl>
      {sponsorableProperty.playLocation ? (
        <SponsorshipMap offer={offer} />
      ) : (
        <Alert alertStyle="warning">
          No play location has been added for this league.
        </Alert>
      )}
    </Card>
  );
}

export default SponsorablePropertyProfileCard;
