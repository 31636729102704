import * as React from 'react';
import { map } from 'ramda';
import { sortBySponsorablePropertyName } from 'utils/offer';
import { DataConfidence } from 'types/sponsorableProperty';
import { OfferStatus } from 'types/offer';
import RemovedTableRow from './RemovedTableRow';

interface Organization {
  id: number;
  dataConfidence: DataConfidence;
  websiteUrl?: string;
}

interface SponsorableProperty {
  id: number;
  organization: Organization;
  name: string;
  dataConfidence: DataConfidence;
}

interface RecentComment {
  createdAt: Date;
  description: string;
}
interface Offer {
  id: number;
  sponsorableProperty: SponsorableProperty;
  recentComment: RecentComment;
  removalReason: string;
  status: OfferStatus;
}

interface Props {
  offers: Offer[];
}

const RemovedTable: React.FunctionComponent<Props> = ({ offers }: Props) => {
  const columnHeaders = [
    <th scope="col" key="spacer-column" aria-label="spacer-column" />,
    <th scope="col" className="align-middle" key="league">
      Sponsorable Property
    </th>,
    <th scope="col" className="align-middle" key="removal-reason">
      Removal Reason
    </th>,
    <th scope="col" className="align-middle" key="status">
      Status
    </th>,
  ];

  return (
    <>
      <div
        className="d-flex mb-2 justify-content-between align-items-center"
        data-test="removed-table"
      >
        <p className="m-0 sui-font-bold">{`${offers.length} Removed`}</p>
      </div>
      {offers.length ? (
        <table className="table" data-test="removed-table-offers">
          <thead>
            <tr>{map((header) => header, columnHeaders)}</tr>
          </thead>
          <tbody>
            {map(
              (offer) => (
                <RemovedTableRow
                  colLength={columnHeaders.length}
                  key={offer.id}
                  offer={offer}
                />
              ),
              sortBySponsorablePropertyName(offers),
            )}
          </tbody>
        </table>
      ) : (
        <p className="text-center" data-test="no-removed-message">
          No removed sponsorships exist!
        </p>
      )}
    </>
  );
};

export default RemovedTable;
