import * as React from 'react';
import { assoc, map } from 'ramda';
import { MAX_NAME_LENGTH } from 'utils/deliverableAddress';
import { COUNTRIES } from 'utils/country';
import { RequiredAsterisk } from 'components/RequiredLabel';

const renderCountryOptions = map(
  (option) => (
    <option key={option.alpha2Code} value={option.alpha2Code}>
      {option.name}
    </option>
  ),
  COUNTRIES,
);

interface Target {
  value: any;
}
interface Event {
  target: Target;
}

interface DeliverableAddress {
  city?: string;
  country?: string;
  line1?: string;
  line2?: string;
  name?: string;
  state?: string;
  zip?: string;
}

interface Props {
  deliverableAddress: DeliverableAddress;
  disabled?: boolean;
  inputLabel: string;
  setDeliverableAddress(deliverableAddress: DeliverableAddress): void;
}

const DeliverableAddressFormInputs: React.FunctionComponent<Props> = ({
  deliverableAddress,
  disabled,
  inputLabel,
  setDeliverableAddress,
}: Props) => {
  const handleDeliverableAddressComponentChange =
    (propName: string) => (event: Event) =>
      setDeliverableAddress(
        assoc(propName, event.target.value, deliverableAddress),
      );

  const labelClassName = 'mb-0';

  return (
    <>
      <div className="form-group mt-1">
        <label className={labelClassName} htmlFor={`${inputLabel}-name`}>
          {`Recipient (${MAX_NAME_LENGTH} character limit)`}
          <RequiredAsterisk />
        </label>
        <input
          type="text"
          className="form-control"
          disabled={disabled}
          onChange={handleDeliverableAddressComponentChange('name')}
          name={`${inputLabel}-name`}
          value={deliverableAddress.name}
          maxLength={MAX_NAME_LENGTH}
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-8">
          <label className={labelClassName} htmlFor={`${inputLabel}-line-1`}>
            Address Line 1
            <RequiredAsterisk />
          </label>
          <input
            type="text"
            className="form-control"
            disabled={disabled}
            onChange={handleDeliverableAddressComponentChange('line1')}
            name={`${inputLabel}-line-1`}
            value={deliverableAddress.line1}
          />
        </div>
        <div className="form-group col-md-4">
          <label className={labelClassName} htmlFor={`${inputLabel}-line-2`}>
            Address Line 2
          </label>
          <input
            type="text"
            className="form-control"
            disabled={disabled}
            onChange={handleDeliverableAddressComponentChange('line2')}
            name={`${inputLabel}-line-2`}
            value={deliverableAddress.line2}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className={labelClassName} htmlFor={`${inputLabel}-city`}>
            City
            <RequiredAsterisk />
          </label>
          <input
            type="text"
            className="form-control"
            disabled={disabled}
            onChange={handleDeliverableAddressComponentChange('city')}
            name={`${inputLabel}-city`}
            value={deliverableAddress.city}
          />
        </div>
        <div className="form-group col-md-2">
          <label className={labelClassName} htmlFor={`${inputLabel}-state`}>
            State
            <RequiredAsterisk />
          </label>
          <input
            type="text"
            className="form-control"
            disabled={disabled}
            onChange={handleDeliverableAddressComponentChange('state')}
            name={`${inputLabel}-state`}
            value={deliverableAddress.state}
          />
        </div>
        <div className="form-group col-md-4">
          <label className={labelClassName} htmlFor={`${inputLabel}-zip`}>
            Zip Code
            <RequiredAsterisk />
          </label>
          <input
            type="text"
            className="form-control"
            disabled={disabled}
            onChange={handleDeliverableAddressComponentChange('zip')}
            name={`${inputLabel}-zip`}
            value={deliverableAddress.zip}
          />
        </div>
      </div>
      <div className="form-group">
        <label className={labelClassName} htmlFor={`${inputLabel}-country`}>
          Country
          <RequiredAsterisk />
        </label>
        <select
          className="form-control"
          disabled={disabled}
          onChange={handleDeliverableAddressComponentChange('country')}
          name={`${inputLabel}-country`}
          value={deliverableAddress.country}
        >
          {renderCountryOptions}
        </select>
      </div>
    </>
  );
};

DeliverableAddressFormInputs.defaultProps = {
  disabled: false,
};

export default DeliverableAddressFormInputs;
