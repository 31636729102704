import React from 'react';
import { Mutation } from 'react-apollo';
import { map } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { pluckId } from 'utils/misc';
import { loadingTense } from 'utils/string';
import { sortByName } from 'utils/sort';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';

const renderSponsorableProperty = (sponsorableProperty) => (
  <li key={sponsorableProperty.id}>{sponsorableProperty.name}</li>
);

const MUTATION = gql`
  mutation AddSponsorablePropertiesToCampaign(
    $input: AddSponsorablePropertiesToCampaignInput!
  ) {
    addSponsorablePropertiesToCampaign(input: $input) {
      campaign {
        id
      }
    }
  }
`;

function AddToCampaignModalUnwrapped({
  campaignId,
  sponsorableProperties,
  mutate,
  onAdd,
}) {
  const add = () => mutate().then(onAdd);
  const { error, loading, handleSubmit } = useSubmit(add);

  return (
    <ModalForm
      id={`add-to-campaign-${campaignId}`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{ children: loadingTense(loading, 'Add') }}
      toggleProps={{
        className: 'dropdown-item',
        children: (
          <>
            <Icon className="mr-1" value="plus" />
            Add as Prospects
          </>
        ),
      }}
      title="Add as Prospects?"
    >
      Are you sure you want to add the following sponsorable properties to this
      campaign as prospects?
      <ul>
        {map(renderSponsorableProperty, sortByName(sponsorableProperties))}
      </ul>
    </ModalForm>
  );
}

AddToCampaignModalUnwrapped.propTypes = {
  campaignId: PropTypes.number.isRequired,
  mutate: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  sponsorableProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

function AddToCampaignModal({ campaignId, sponsorableProperties, onAdd }) {
  return (
    <Mutation
      mutation={MUTATION}
      variables={{
        input: {
          campaignId,
          sponsorablePropertyIds: pluckId(sponsorableProperties),
        },
      }}
    >
      {(mutate) => (
        <AddToCampaignModalUnwrapped
          campaignId={campaignId}
          sponsorableProperties={sponsorableProperties}
          mutate={mutate}
          onAdd={onAdd}
        />
      )}
    </Mutation>
  );
}

AddToCampaignModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  onAdd: PropTypes.func.isRequired,
  sponsorableProperties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default AddToCampaignModal;
