import styled from 'styled-components';

const Sidebar = styled.div`
  display: flex;
  height: 100%;
  flex-flow: column;
  overflow-y: scroll;
`;

export default Sidebar;
