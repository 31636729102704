import React from 'react';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

export const RemoveLeaguePackageModalUnwrapped = ({
  leaguePackage,
  remove,
}) => {
  const { id, name } = leaguePackage;

  const title = 'Remove';

  const { error, loading, handleSubmit } = useSubmit(remove);

  return (
    <ModalForm
      id={`remove-league-package-${id}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, title),
        className: 'btn btn-danger',
      }}
      title={`${title} League Package?`}
      toggleProps={{
        children: <Icon value="trash" />,
        className: 'btn btn-link text-danger border-0 p-0',
        title,
      }}
    >
      <p>
        Are you sure you want to remove&nbsp;
        <strong>{name}</strong>? This action cannot be undone.
      </p>
    </ModalForm>
  );
};

RemoveLeaguePackageModalUnwrapped.propTypes = {
  leaguePackage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
};

const RemoveLeaguePackageModal = ({ leaguePackage, mutation }) => (
  <Mutation mutation={mutation}>
    {(mutate) => (
      <RemoveLeaguePackageModalUnwrapped
        leaguePackage={leaguePackage}
        remove={() =>
          mutate({ variables: { input: { id: Number(leaguePackage.id) } } })
        }
      />
    )}
  </Mutation>
);

RemoveLeaguePackageModal.propTypes = {
  leaguePackage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  mutation: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default RemoveLeaguePackageModal;
