import { PropsWithChildren } from 'react';
import cx from 'classnames';

type WizardNavigationLegendProps = PropsWithChildren<{
  active?: boolean;
  done?: boolean;
  listStyle?: 'default' | 'small';
}>;

export default function WizardNavigationLegend(
  props: WizardNavigationLegendProps,
) {
  const { children, active, listStyle = 'default', done } = props;

  return (
    <div className="sui-flex sui-items-center">
      {listStyles[listStyle](!!done)}
      <p
        className={cx('sui-font-semibold sui-text-desktop-4', {
          'sui-text-action-text': active,
        })}
      >
        {children}
      </p>
    </div>
  );
}

const listStyles = {
  default: (done: boolean) => (
    <span
      className={cx(
        'sui-block sui-border-2 sui-border-solid sui-border-gray-40 sui-mr-1 sui-relative sui-h-2',
        {
          'sui-border-green-40': done,
        },
      )}
      style={{ borderRadius: '100%', minWidth: 16 }}
    >
      {done && (
        <span
          className="sui-absolute sui-border-b-2 sui-border-solid sui-border-green-40 sui-border-l-2 sui-block"
          style={{
            width: 8,
            height: 5,
            top: 3,
            left: 2,
            transform: 'rotate(-45deg)',
          }}
        />
      )}
    </span>
  ),
  small: (done: boolean) => (
    <span
      className={cx(
        'sui-block sui-border-2 sui-border-solid sui-border-gray-40 sui-mr-1 sui-relative sui-h-1',
        {
          'sui-border-green-40 sui-bg-green-40': done,
        },
      )}
      style={{ borderRadius: '100%', minWidth: 8 }}
    />
  ),
};
