import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React from 'react';
import { SingleDatePicker } from 'react-dates';
import { F } from 'ramda';
import PropTypes from 'prop-types';
import { DEFAULT_DATE_FORMAT } from 'utils/formatDate';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFocusChange = ({ focused }) => this.setState({ focused });
  }

  render() {
    const { focused } = this.state;
    return (
      <SingleDatePicker
        focused={focused}
        isOutsideRange={F}
        numberOfMonths={3}
        onFocusChange={this.handleFocusChange}
        displayFormat={DEFAULT_DATE_FORMAT}
        placeholder={DEFAULT_DATE_FORMAT}
        showClearDate
        {...this.props}
      />
    );
  }
}

DatePicker.propTypes = {
  date: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  id: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
};

DatePicker.defaultProps = {
  date: null,
};

export default DatePicker;
