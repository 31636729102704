import React from 'react';
import PropTypes from 'prop-types';
import { CAMPAIGN_DASHBOARD_PATH_PREFIX } from 'utils/paths';
import Card from 'components/Card';
import CopyButton from 'components/CopyButton';
import NewWindowLink from 'components/NewWindowLink';

const url = (magicToken) =>
  `${global.location.origin}${CAMPAIGN_DASHBOARD_PATH_PREFIX}/${magicToken}`;

const CampaignDashboardCard = ({ magicToken }) => (
  <Card>
    <div className="justified-content mb-3">
      <strong>Campaign Dashboard</strong>
      <NewWindowLink to={url(magicToken)} id="campaign-dashboard-link">
        View
      </NewWindowLink>
    </div>
    <CopyButton content={url(magicToken)} large width={100} />
  </Card>
);

CampaignDashboardCard.propTypes = {
  magicToken: PropTypes.string.isRequired,
};

export default CampaignDashboardCard;
