import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Loader from 'components/Loader';
import StagingBanner from 'components/StagingBanner';
import BrandBar from 'components/BrandBar';
import styled from 'styled-components';
import jwtDecode from 'jwt-decode';
import SeasonalDashboardContext, {
  useSeasonalDashboardContext,
} from 'context/SeasonalDashboardContext';
import LeagueSideFooter from 'components/TeamSnapFooter';
import headerImage from './campaignDashboard/header.jpg';
import SeasonalDashboardSections from './seasonalDashboard/SeasonalDashboardSections';

const Header = styled.div`
  background-image: url(${headerImage});
`;

const Logo = styled.img`
  max-height: 33vh;
`;

export function SeasonalDashboardUnwrapped({ seasonalDashboard }) {
  const { season, year } = useSeasonalDashboardContext();

  if (!seasonalDashboard) return <Loader />;

  const { sponsor } = seasonalDashboard;
  const { name, logoFullSizeUrl } = sponsor;
  return (
    <div className="deprecated-styles">
      <FieldhousePageTitle title={`${name}`}>
        <StagingBanner />
        <BrandBar brandHref="#" />
        <Header className="text-center py-5 mb-5">
          {logoFullSizeUrl ? (
            <Logo
              alt={`${name}`}
              className="mw-100 mx-2"
              id="sponsor-logo"
              src={logoFullSizeUrl}
            />
          ) : (
            <h1 id="sponsor-name">{name}</h1>
          )}
          <h1 className="mt-4" id="campaign-name">
            {`${season} ${year} `}
          </h1>
        </Header>
        <div className="px-4 mx-4">
          <SeasonalDashboardSections />
        </div>
        <LeagueSideFooter />
      </FieldhousePageTitle>
    </div>
  );
}

SeasonalDashboardUnwrapped.propTypes = {
  seasonalDashboard: PropTypes.shape({ sponsor: LeagueSidePropTypes.sponsor }),
};

SeasonalDashboardUnwrapped.defaultProps = {
  seasonalDashboard: null,
};

export const query = gql`
  query SeasonalDashboard {
    seasonalDashboard {
      sponsor {
        id
        logoFullSizeUrl
        name
      }
    }
  }
`;

const SeasonalDashboard = ({ match }) => {
  const { token } = match.params;
  const { season } = jwtDecode(match.params.token);
  const { year } = jwtDecode(match.params.token);

  return (
    <Query
      context={{ headers: { Authorization: `Bearer ${token}` } }}
      fetchPolicy="network-only"
      query={query}
      variables={{ season, year }}
    >
      {({ data, error }) => {
        if (error) throw error;

        return (
          <SeasonalDashboardContext.Provider value={{ token, season, year }}>
            <SeasonalDashboardUnwrapped
              seasonalDashboard={data && data.seasonalDashboard}
            />
          </SeasonalDashboardContext.Provider>
        );
      }}
    </Query>
  );
};

const matchType = PropTypes.shape({
  params: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
});

SeasonalDashboard.propTypes = {
  match: matchType.isRequired,
};

export default SeasonalDashboard;
