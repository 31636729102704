import { useState } from 'react';

export default function useErrorBoundary() {
  const [, setError] = useState();

  const throwError = (error: Error) => {
    setError(() => {
      throw error;
    });
  };
  return { throwError };
}
