import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

export const DeleteDefaultVerificationDeadlineButtonUnwrapped = ({
  onClick,
}) => (
  <button
    type="button"
    className="btn btn-link text-danger py-1"
    data-test="delete-verification-deadline"
    onClick={onClick}
  >
    Delete default verification deadline
  </button>
);

DeleteDefaultVerificationDeadlineButtonUnwrapped.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation DeleteDefaultVerificationDeadline($id: Int!) {
    deleteDefaultVerificationDeadline(id: $id) {
      campaignCapability {
        id
        defaultVerificationDeadlines {
          id
        }
      }
    }
  }
`;

const DeleteDefaultVerificationDeadlineButton = ({
  defaultVerificationDeadlineId,
}) => (
  <Mutation
    mutation={MUTATION}
    variables={{ id: defaultVerificationDeadlineId }}
  >
    {(mutate) => (
      <DeleteDefaultVerificationDeadlineButtonUnwrapped
        onClick={async () => {
          await mutate();
        }}
      />
    )}
  </Mutation>
);

DeleteDefaultVerificationDeadlineButton.propTypes = {
  defaultVerificationDeadlineId: PropTypes.number.isRequired,
};

export default DeleteDefaultVerificationDeadlineButton;
