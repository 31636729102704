import { ascend, descend, map, sortWith, path, pipe } from 'ramda';
import React from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import styled from 'styled-components';
import OfferListItem from './OfferListItem';
import CampaignDashboardMap from './CampaignDashboardMap';

const SponsorableProperties = styled.div`
  @media (min-width: 768px) {
    /* md breakpoint */
    height: 75vh;
    overflow-y: scroll;
  }
`;

const renderOfferListItem = (offer) => (
  <OfferListItem key={offer.id} offer={offer} />
);

const renderOfferListItems = pipe(
  sortWith([
    descend(path(['verificationImages', 'length'])),
    ascend(path(['sponsorableProperty', 'name'])),
  ]),
  map(renderOfferListItem),
);

// TODO: Make a copy pass it an array of campaigns
const CampaignDashboardMapWithList = ({ campaign }) => (
  <div className="row border no-gutters">
    <SponsorableProperties className="col-md-5">
      <div className="list-group list-group-flush">
        {renderOfferListItems(campaign.offers)}
      </div>
    </SponsorableProperties>
    <div className="d-none d-md-block col-md-7">
      <CampaignDashboardMap campaign={campaign} />
    </div>
  </div>
);

CampaignDashboardMapWithList.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignDashboardMapWithList;
