import { StepIdsEnum } from './types';

// eslint-disable-next-line import/prefer-default-export
export const WIZARD_IMAGES_PER_STEP_ID = {
  [StepIdsEnum.GAME_JERSEYS]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/logo-on-jersey.png',
  [StepIdsEnum.WARM_UPS]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/warm-ups.png',
  [StepIdsEnum.GAME_SIGNAGE]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/banners.png',
  [StepIdsEnum.DEDICATED_EMAIL]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/dedicated-email.png',
  [StepIdsEnum.WEBSITE_BANNER]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/web-banners.png',
  [StepIdsEnum.SEMINAR]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/seminars.png',
  [StepIdsEnum.SURVEYS]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/surveys.png',
  [StepIdsEnum.COUPONS]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/product-distribution.png',
  [StepIdsEnum.PHOTOS]: [
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/photography-1.png',
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/photography-2.png',
  ],
  [StepIdsEnum.SAMPLING]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/tabling.png',
  [StepIdsEnum.REWARDED_ADD_ONS]:
    'https://res.cloudinary.com/hkbmuwkku/image/upload/f_auto/c_pad,w_315/v1709827261/sponsorship_application/hundredx.png',
};
