import React from 'react';
import { useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import Loader from 'components/Loader';
import CapabilitiesTableController from './CapabilitiesTableController';

const QUERY = gql`
  query OrganizationCapabilities($id: Int!) {
    organization(id: $id) {
      id
      name
      organizationCapabilities {
        id
        description
        capability {
          name
        }
        capabilityDetail {
          id
          maxForSale
          price
          __typename
          capabilityDetailable {
            ... on GameSignage {
              id
              canHang24Hours
              bannerPlacements
              bannerSizes
              organizationPlayLocations {
                id
                playLocation {
                  id
                  address
                }
              }
            }
            ... on DedicatedEmail {
              id
              emailListSize
            }
            ... on SocialMediaPost {
              id
              socialMediaFollowingSize
              socialMediaChannel {
                id
                name
              }
            }
            ... on TeamGameJersey {
              id
              minTeamsRequired
              canUseLogo
              otherBrands
              logoPlacements
            }
          }
        }
      }
    }
  }
`;

const OrganizationDashboardCapabilities = ({ organizationId, history }) => {
  const { data, loading } = useQuery(QUERY, {
    variables: { id: organizationId },
    fetchPolicy: 'network-only',
  });

  if (loading) return <Loader />;
  const organization = data && data.organization;
  const organizationCapabilities =
    data && organization.organizationCapabilities;
  const redirect = () =>
    history.push(`/organization-dashboard/capability-form/${organizationId}`);

  return (
    <>
      {organizationCapabilities ? (
        <>
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary"
              onClick={redirect}
            >
              + Add Capability
            </button>
          </div>
          <CapabilitiesTableController
            organizationCapabilities={organizationCapabilities}
          />
        </>
      ) : (
        <h1
          className="d-flex justify-content-center"
          data-test="no-results-message"
        >
          {' '}
          Sorry! Capabilities have not been created yet!{' '}
        </h1>
      )}
    </>
  );
};

OrganizationDashboardCapabilities.propTypes = {
  organizationId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(OrganizationDashboardCapabilities);
