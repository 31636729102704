import { __, append, contains, map, of, pipe, without } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { sortByName } from 'utils/sort';
import styled from 'styled-components';
import CheckboxButton from 'components/CheckboxButton';
import Paragraph from './Paragraph';

const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
`;

function Play({ addChanges, league, names, collections, question }) {
  function renderModelCollection(name, collection) {
    const idsKey = `${name}Ids`;
    const ids = league[idsKey];
    const selected = contains(__, ids);
    const addId = append(__, ids);
    const removeId = pipe(of, without(__, ids));

    const renderModel = (model) => (
      <CheckboxButton
        checked={selected(model.id)}
        id={`${name}${model.id}`}
        key={model.id}
        onChange={(event) =>
          addChanges({
            [idsKey]: event.target.checked
              ? addId(model.id)
              : removeId(model.id),
          })
        }
      >
        <h3 className="m-0">{model.name}</h3>
      </CheckboxButton>
    );

    return map(renderModel, sortByName(collection));
  }

  return (
    <div className="container">
      <Paragraph bold>{question}</Paragraph>
      <Paragraph className="mb-4">Select all that apply.</Paragraph>
      <Inputs className="mb-4">
        {renderModelCollection(names, collections)}
      </Inputs>
    </div>
  );
}

Play.propTypes = {
  addChanges: PropTypes.func.isRequired,
  league: LeagueSidePropTypes.league.isRequired,
  names: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
};

export default Play;
