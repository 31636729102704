import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logout } from '../modules/authentication';

const Logout = ({ onLogout }) => {
  onLogout();

  return null;
};

Logout.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onLogout: logout,
    },
    dispatch,
  );

const LogoutContainer = connect(null, mapDispatchToProps)(Logout);
export { Logout, LogoutContainer };
