import * as React from 'react';
import { isNil, multiply } from 'ramda';
import NumberInput from 'components/formInputs/NumberInput';
import { toPrecisionNumber } from 'utils/misc';

const exactPrecision = 4;
const toExactPrecision = toPrecisionNumber(exactPrecision);
const toDecimal = multiply(0.01);
const toPercentage = multiply(100);

function percentageValue(value: number | undefined) {
  if (isNil(value)) return undefined;

  return toExactPrecision(toPercentage(value));
}

interface Props {
  formGroupClassName?: string;
  onChange(value: number | null): number | null;
  inputSize?: string;
  label: string;
  labelClassName?: string;
  value?: number;
}

const PercentageInput: React.FC<Props> = ({
  formGroupClassName,
  onChange,
  inputSize,
  label,
  labelClassName,
  value,
}: Props) => (
  <NumberInput
    append="%"
    formGroupClassName={formGroupClassName}
    inputSize={inputSize}
    label={label}
    labelClassName={labelClassName}
    max={100}
    min={0}
    onChange={(e) => {
      const { value: eventTargetValue } = e.target as HTMLInputElement;
      onChange(
        eventTargetValue
          ? toExactPrecision(toDecimal(Number(eventTargetValue)))
          : null,
      );
    }}
    step={1 / 10 ** (exactPrecision - 2)}
    value={percentageValue(value)}
  />
);

PercentageInput.defaultProps = {
  formGroupClassName: undefined,
  inputSize: undefined,
  labelClassName: undefined,
  value: undefined,
};

export default PercentageInput;
