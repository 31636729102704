import React from 'react';
import leaguesideAPI from 'utils/leagueside-api';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

const SponsorablePropertyListDownloadButton = ({ campaignId, statuses }) => (
  <button
    className="btn btn-link d-inline-block float-right p-0"
    disabled={!statuses.length}
    onClick={() =>
      leaguesideAPI.downloadOfferSponsorablePropertyList(campaignId, statuses)
    }
    type="button"
  >
    <Icon value="text-document" />
    Sponsorable Property List
  </button>
);

SponsorablePropertyListDownloadButton.propTypes = {
  campaignId: PropTypes.number.isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SponsorablePropertyListDownloadButton;
