import React, { Fragment, useState } from 'react';
import { all, append, contains, filter, isEmpty, map, without } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { sponsorablePropertyLocation } from 'utils/sponsorableProperty';
import { pluckId } from 'utils/misc';
import { sortOffersBySurveyColV2 } from 'utils/offer';
import { pluralizedTense } from 'utils/string';
import ActiveOffersBadges from 'components/ActiveOffersBadges';
import SendOfferEmailsModal from 'components/SendOfferEmailsModal';
import RemoveFromCampaignModal from 'components/RemoveFromCampaignModal';
import RecentCommentRow from './RecentCommentRow';
import SurveyCol from './SurveyCol';
import SponsorablePropertyCol from './SponsorablePropertyCol';
import OrganizationCol from './OrganizationCol';

const QualifiedTable = ({
  offers,
  onRemoveFromCampaign,
  onSendOfferEmails,
}) => {
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);

  const allOfferIds = pluckId(offers);
  const selectedIdsSet = new Set(selectedOfferIds);
  const allSelected = all((id) => selectedIdsSet.has(id), allOfferIds);
  const selectedOffers = filter(
    ({ id }) => contains(id, selectedOfferIds),
    offers,
  );

  const removeOfferIdAndRefetchQualifiedAndRemovedOffers = () => {
    setSelectedOfferIds([]);
    onRemoveFromCampaign();
  };

  const removeOfferIdAndRefetchQualifiedAndProposedOffers = () => {
    setSelectedOfferIds([]);
    onSendOfferEmails();
  };

  const handleToggle = (offerId) => {
    const includesOfferId = contains(offerId, selectedOfferIds);
    const removeOfferId = without([offerId], selectedOfferIds);
    const addOfferId = append(offerId, selectedOfferIds);

    return () =>
      setSelectedOfferIds(() => (includesOfferId ? removeOfferId : addOfferId));
  };

  const handleToggleAll = () => {
    setSelectedOfferIds(allSelected ? [] : allOfferIds);
  };

  const columnHeaders = [
    <th scope="col" className="align-middle" key="checkbox">
      <input
        data-test="all-qualified-toggle"
        checked={allSelected}
        onChange={handleToggleAll}
        type="checkbox"
      />
    </th>,
    <th scope="col" className="align-middle pr-1" key="organization">
      Organization
    </th>,
    <th scope="col" className="align-middle pr-1" key="sponsorableProperty">
      Sponsorable Property
    </th>,
    <th
      scope="col"
      className="align-middle pl-1"
      key="report-column"
      aria-label="report-column"
    />,
    <th
      scope="col"
      className="align-middle d-none d-lg-table-cell"
      key="location"
    >
      Location
    </th>,
    <th scope="col" className="align-middle" key="survey">
      Survey
    </th>,
  ];

  const renderRow = (offer) => {
    const {
      id: offerId,
      offerCountsReport,
      sponsorableProperty,
      recentComment,
      fundraiserConflict,
      surveyConflict,
    } = offer;
    const { organization } = sponsorableProperty;

    return (
      <Fragment key={offerId}>
        <tr data-test={`offer-${offerId}-row`} className="p-2">
          <td className="align-middle">
            <input
              data-test={`qualified-toggle-${offerId}`}
              checked={contains(offerId, selectedOfferIds)}
              onChange={handleToggle(offerId)}
              type="checkbox"
            />
          </td>
          <td
            className="align-middle d-none d-lg-table-cell"
            data-test="organization"
          >
            <OrganizationCol organization={organization} />
          </td>
          <SponsorablePropertyCol
            sponsorableProperty={sponsorableProperty}
            offerId={offerId}
          />
          <td className="align-middle pl-1">
            <ActiveOffersBadges
              offerCountsReport={offerCountsReport}
              sponsorablePropertyId={sponsorableProperty.id}
              fundraiserConflict={fundraiserConflict}
              surveyConflict={surveyConflict}
            />
          </td>
          <td
            className="align-middle d-none d-lg-table-cell"
            data-test="location"
          >
            <small>
              {sponsorablePropertyLocation({
                state: organization.state,
                city: organization.city,
              })}
            </small>
          </td>
          <SurveyCol offer={offer} />
        </tr>
        {!!recentComment && (
          <RecentCommentRow colLength={columnHeaders.length} offer={offer} />
        )}
      </Fragment>
    );
  };

  return (
    <>
      <div
        className="d-flex mb-2 justify-content-between align-items-center"
        data-test="qualified-table"
      >
        <p className="m-0 sui-font-bold">{`${offers.length} Qualified`}</p>
        <div className="dropdown">
          <button
            className="btn btn-large btn-secondary dropdown-toggle"
            disabled={isEmpty(selectedOffers)}
            data-toggle="dropdown"
            data-test="qualified-dropdown"
            id="bulk-qualified-dropdown-menu-button"
            type="button"
          >
            {`With ${
              selectedOffers.length
            } Selected Sponsorable ${pluralizedTense(
              selectedOffers.length,
              'Property',
            )}`}
          </button>
          <div
            className="dropdown-menu"
            aria-labelledby="bulk-qualified-dropdown-menu-button"
          >
            <SendOfferEmailsModal
              id="bulk-qualified-offer-email-modal"
              offers={selectedOffers}
              onSend={removeOfferIdAndRefetchQualifiedAndProposedOffers}
              toggleClassName="btn btn-link dropdown-item"
            />
            <RemoveFromCampaignModal
              offers={selectedOffers}
              onRemove={removeOfferIdAndRefetchQualifiedAndRemovedOffers}
              prefix="bulk-qualified"
            />
          </div>
        </div>
      </div>
      {offers.length ? (
        <table className="table" data-test="qualified-table-offers">
          <thead>
            <tr>{map((header) => header, columnHeaders)}</tr>
          </thead>
          <tbody>{map(renderRow, sortOffersBySurveyColV2(offers))}</tbody>
        </table>
      ) : (
        <p className="text-center" data-test="no-qualified-message">
          No qualified sponsorships exist!
        </p>
      )}
    </>
  );
};

QualifiedTable.propTypes = {
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  onRemoveFromCampaign: PropTypes.func.isRequired,
  onSendOfferEmails: PropTypes.func.isRequired,
};

export default QualifiedTable;
