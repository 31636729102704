import gql from 'graphql-tag';

export const ORGANIZATION_CAPABILITY_MINIMAL_FRAGMENT = gql`
  fragment OrganizationCapabilityMinimalFragment on OrganizationCapability {
    id
    description
    name
  }
`;

export const ORGANIZATION_CAPABILITY_FRAGMENT = gql`
  fragment OrganizationCapabilityFragment on OrganizationCapability {
    ...OrganizationCapabilityMinimalFragment
    archived
    organizationCapabilityPackages {
      id
    }
  }
  ${ORGANIZATION_CAPABILITY_MINIMAL_FRAGMENT}
`;

export default ORGANIZATION_CAPABILITY_MINIMAL_FRAGMENT;
