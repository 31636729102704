import { propEq } from 'ramda';
import {
  getRadii as getRadiiLocation,
  getGeometries as getGeometriesLocation,
} from './location';

export const getRadii = (locationFilter) =>
  getRadiiLocation(locationFilter.locations, locationFilter.radius);
export const getGeometries = (locationFilter) =>
  getGeometriesLocation(locationFilter.locations);

export const isTotalStrategy = propEq('strategy', 'total');
