import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import CAMPAIGN_STATISTIC_FRAGMENT from 'fragments/CampaignStatisticFragment';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';

export class CampaignStatisticDestroyModalUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    const { props } = this;
    const { campaignStatisticId } = props;

    props.onSubmit();
    props
      .$(`#campaign-statistic-${campaignStatisticId}-delete-modal`)
      .modal('hide');
  }

  render() {
    const { campaignStatisticId } = this.props;

    return (
      <>
        <button
          className="btn btn-link text-danger bl-2"
          data-target={`#campaign-statistic-${campaignStatisticId}-delete-modal`}
          data-test="campaign-statistic-delete-modal"
          data-toggle="modal"
          type="button"
        >
          <span className="icon icon-trash" />
        </button>
        <Modal
          id={`campaign-statistic-${campaignStatisticId}-delete-modal`}
          title="Delete a Statistic"
        >
          <form onSubmit={this.handleSubmit}>
            <ModalBody>
              Are you sure you want to delete this statistic? This is permanent.
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-secondary"
                data-test={`delete-campaign-statistic-${campaignStatisticId}-modal-cancel`}
                data-dismiss="modal"
                type="button"
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                data-test={`delete-campaign-statistic-${campaignStatisticId}-modal-confirm`}
                type="submit"
              >
                Delete
              </button>
            </ModalFooter>
          </form>
        </Modal>
      </>
    );
  }
}

CampaignStatisticDestroyModalUnwrapped.defaultProps = {
  $: global.$,
};

CampaignStatisticDestroyModalUnwrapped.propTypes = {
  $: PropTypes.func,
  campaignStatisticId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation DeleteCampaignStatistic($id: Int!) {
    deleteCampaignStatistic(id: $id) {
      id
      campaignStatistics {
        ...CampaignStatisticFragment
      }
    }
  }
  ${CAMPAIGN_STATISTIC_FRAGMENT}
`;

const CampaignStatisticDestroyModal = ({ campaignStatisticId }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CampaignStatisticDestroyModalUnwrapped
        onSubmit={() =>
          mutate({
            variables: {
              id: campaignStatisticId,
            },
          })
        }
        campaignStatisticId={campaignStatisticId}
      />
    )}
  </Mutation>
);

CampaignStatisticDestroyModal.propTypes = {
  campaignStatisticId: PropTypes.number.isRequired,
};

export default CampaignStatisticDestroyModal;
