import * as React from 'react';
import { map } from 'ramda';
import { capitalize } from 'utils/string';
import { Season } from 'types/season';
import SelectInput from './SelectInput';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  formGroupClassName?: string;
  inputSize?: string;
  labelClassName?: string;
  required?: boolean;
  value: string;
}

const SeasonSelectInput: React.FunctionComponent<Props> = ({
  disabled,
  formGroupClassName,
  inputSize,
  labelClassName,
  onChange,
  required,
  value,
}: Props) => (
  <SelectInput
    disabled={disabled}
    formGroupClassName={formGroupClassName}
    inputSize={inputSize}
    label="Season"
    labelClassName={labelClassName}
    noDefault
    onChange={onChange}
    required={required}
    value={value}
  >
    {map(
      ([objKey, objValue]) => (
        <option key={objKey} value={objValue}>
          {capitalize(objValue)}
        </option>
      ),
      Object.entries(Season),
    )}
  </SelectInput>
);

SeasonSelectInput.defaultProps = {
  disabled: false,
  formGroupClassName: undefined,
  inputSize: undefined,
  labelClassName: undefined,
  required: false,
};

export default SeasonSelectInput;
