import gql from 'graphql-tag';
import { REMOVED_OFFERS_QUERY_ARGS } from 'utils/queryArgs';
import LOCATION_FRAGMENT from 'fragments/LocationFragment';
import {
  PROSPECTING_OFFER_FRAGMENT,
  QUALIFIED_OFFER_FRAGMENT,
  PROPOSED_OFFER_FRAGMENT,
  CONFIRMED_OFFER_FRAGMENT,
  REMOVED_OFFER_FRAGMENT,
} from 'fragments/OfferFragment';
import { AVAILABLE_SPONSORABLE_PROPERTY_FRAGMENT } from 'fragments/SponsorablePropertyFragment';

const PROSPECT_LOCATION_QUERY = gql`
  query Location($id: Int!, $withLatLon: Boolean = true) {
    location(id: $id) {
      ...LocationFragment
      geoJson
      prospectingReportId
      locationFilter {
        id
        campaign {
          id
          hasSurveyQuestions
          name
          sponsor {
            id
            name
          }
        }
        name
        radius
      }
      availableSponsorableProperties {
        ...AvailableSponsorablePropertyFragment
      }
      confirmedOffers: offers(statuses: [confirmed]) {
        ...ConfirmedOfferFragment
      }
      proposedOffers: offers(statuses: [proposed rejected_by_league]) {
        ...ProposedOfferFragment
      }
      prospectingOffers: offers(statuses: [prospecting]) {
        ...ProspectingOfferFragment
      }
      qualifiedOffers: offers(statuses: [qualified]) {
        ...QualifiedOfferFragment
      }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        ...RemovedOfferFragment
      }
    }
  }
  ${LOCATION_FRAGMENT}
  ${AVAILABLE_SPONSORABLE_PROPERTY_FRAGMENT}
  ${CONFIRMED_OFFER_FRAGMENT}
  ${PROPOSED_OFFER_FRAGMENT}
  ${PROSPECTING_OFFER_FRAGMENT}
  ${QUALIFIED_OFFER_FRAGMENT}
  ${REMOVED_OFFER_FRAGMENT}
`;

export const AVAILABLE_AND_PROSPECTING_OFFERS_REFETCH_QUERY = gql`
  query LocationWithProspectingOffers($id: Int!, $withLatLon: Boolean = true) {
    location(id: $id) {
      id
      availableSponsorableProperties {
        id
      }
      prospectingReportId
      prospectingOffers: offers(statuses: [prospecting]) {
        ...ProspectingOfferFragment
      }
    }
  }
  ${PROSPECTING_OFFER_FRAGMENT}
`;

export const PROSPECTING_AND_QUALIFIED_OFFERS_REFETCH_QUERY = gql`
  query LocationWithProspectingAndQualifiedOffers(
    $id: Int!
    $withLatLon: Boolean = true
  ) {
    location(id: $id) {
      id
      prospectingReportId
      prospectingOffers: offers(statuses: [prospecting]) {
        id
      }
      qualifiedOffers: offers(statuses: [qualified]) {
        ...QualifiedOfferFragment
      }
    }
  }
  ${QUALIFIED_OFFER_FRAGMENT}
`;

export const PROSPECTING_AND_REMOVED_OFFERS_REFETCH_QUERY = gql`
  query LocationWithProspectingAndRemovedOffers($id: Int!, $withLatLon: Boolean = true) {
    location(id: $id) {
      id
      availableSponsorableProperties {
        ...AvailableSponsorablePropertyFragment
      }
      prospectingReportId
      prospectingOffers: offers(statuses: [prospecting]) { id }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        ...RemovedOfferFragment
      }
    }
  }
  ${AVAILABLE_SPONSORABLE_PROPERTY_FRAGMENT}
  ${REMOVED_OFFER_FRAGMENT}
`;

export const QUALIFIED_AND_PROPOSED_OFFERS_REFETCH_QUERY = gql`
  query LocationWithQualifiedAndProposedOffers(
    $id: Int!
    $withLatLon: Boolean = true
  ) {
    location(id: $id) {
      id
      prospectingReportId
      qualifiedOffers: offers(statuses: [qualified]) {
        id
      }
      proposedOffers: offers(statuses: [proposed, rejected_by_league]) {
        ...ProposedOfferFragment
      }
    }
  }
  ${PROPOSED_OFFER_FRAGMENT}
`;

export const QUALIFIED_AND_REMOVED_OFFERS_REFETCH_QUERY = gql`
  query LocationWithQualifiedAndRemovedOffers($id: Int!, $withLatLon: Boolean = true) {
    location(id: $id) {
      id
      availableSponsorableProperties {
        ...AvailableSponsorablePropertyFragment
      }
      prospectingReportId
      qualifiedOffers: offers(statuses: [qualified]) { id }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        ...RemovedOfferFragment
      }
    }
  }
  ${AVAILABLE_SPONSORABLE_PROPERTY_FRAGMENT}
  ${REMOVED_OFFER_FRAGMENT}
`;

export const PROPOSED_AND_REMOVED_OFFERS_REFETCH_QUERY = gql`
  query LocationWithProposedAndRemovedOffers($id: Int!, $withLatLon: Boolean = true) {
    location(id: $id) {
      id
      prospectingReportId
      proposedOffers: offers(statuses: [proposed rejected_by_league]) { id }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        ...RemovedOfferFragment
      }
    }
  }
  ${REMOVED_OFFER_FRAGMENT}
`;

export const SEND_SURVEY_LOCATION_REFETCH_QUERY = gql`
  query SendSurveyLocationQuery($id: Int!) {
    location(id: $id) {
      id
      prospectingOffers: offers(statuses: [prospecting]) {
        id
        communicatedToLeague
        numberOfSurveyViews
        surveySentAt
      }
    }
  }
`;

export const SEND_OFFER_EMAILS_LOCATION_REFETCH_QUERY = gql`
  query SendOfferEmailsLocationQuery($id: Int!) {
    location(id: $id) {
      id
      proposedOffers: offers(statuses: [proposed, rejected_by_league]) {
        id
        numberOfOfferViews
        offerOpenedAt
        offerSentAt
        status
      }
    }
  }
`;

export default PROSPECT_LOCATION_QUERY;
