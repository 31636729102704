import * as React from 'react';
import ContactUsLink from 'components/ContactUsLink';

const NeedHelp: React.FunctionComponent = () => (
  <p className="text-center capitalize p-4">
    Need help?&nbsp;
    <ContactUsLink capitalized />!
  </p>
);

export default NeedHelp;
