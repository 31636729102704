import { Checkbox } from '@teamsnap/snap-ui';
import cx from 'classnames';
import { useMemo } from 'react';

export type CheckboxGroupProps = {
  groupedOptions: {
    title: string;
    subtitle?: string;
    options: { label: string; value: string }[];
  }[];
  name: string;
  value: string[];
  onChange: (value: string[]) => void;
  selectAllOption?: boolean;
};

export default function CheckboxGroup(props: CheckboxGroupProps) {
  const {
    groupedOptions = [],
    name,
    value,
    onChange,
    selectAllOption = false,
  } = props;

  const handleOnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    return onChange(
      ev.target?.checked
        ? [...value, ev.target.value]
        : value.filter((singleValue) => singleValue !== ev.target.value),
    );
  };
  const optionsFlattened = useMemo(() => {
    return groupedOptions.reduce(
      (acc, group) => [...acc, ...group.options],
      [] as { label: string; value: string }[],
    );
  }, [groupedOptions]);

  return (
    <div data-testid="checkbox-group-list">
      {selectAllOption && (
        <div className="sui-px-0.5 sui-py-2 sui-mb-1">
          <Checkbox
            name={name}
            label="Select All"
            value="all"
            extraProps={{
              input: {
                checked: optionsFlattened.length === value.length,
                onChange: (ev) =>
                  ev.target.checked
                    ? onChange(
                        optionsFlattened.map((option) => String(option.value)),
                      )
                    : onChange([]),
              },
            }}
          />
        </div>
      )}
      {groupedOptions.map((group) => (
        <div key={group.title} className="sui-mb-3">
          <h3 className="sui-text-mobile-6 d:sui-text-desktop-6 sui-font-bold sui-mb-1">
            {group.title}
          </h3>
          <p className="sui-text-mobile-4 d:sui-text-desktop-5 sui-text-gray-40 sui-mb-2">
            {group.subtitle}
          </p>
          {group.options.map((option, optionIndex) => (
            <div
              key={`${name}-${option.value}`}
              className={cx('sui-px-0.5 sui-py-2', {
                'sui-bg-gray-98': selectAllOption
                  ? optionIndex % 2 === 0
                  : optionIndex % 2 === 1,
              })}
            >
              <Checkbox
                id={`${name}-${option.value}`}
                name={name}
                label={option.label}
                value={option.value}
                extraProps={{
                  input: {
                    checked: value.includes(String(option.value)),
                    onChange: handleOnChange,
                  },
                }}
                className="sui-text-mobile-4 d:sui-text-desktop-5"
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
