import * as React from 'react';

interface Props extends React.HTMLAttributes<HTMLElement> {}

const HoverTable: React.FunctionComponent<Props> = ({ children }: Props) => (
  <table className="table table-hover mt-2 mb-0" data-test="hover-table">
    {children}
  </table>
);

export default HoverTable;
