import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmitWithGQL } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

export const DeletePlayLocationModalUnwrapped = ({
  id,
  deletePlayLocation,
  nickname,
}) => {
  async function asyncSubmit() {
    await deletePlayLocation();
  }

  const { error, loading, handleSubmit } = useSubmitWithGQL(asyncSubmit);

  return (
    <ModalForm
      id={`delete-play-location-${id}-modal`}
      error={error}
      errorMessage={
        <div className="alert alert-danger mb-0 mt-3">
          <ul className="mb-0">{!!error && error.graphQLErrors[0].message}</ul>
        </div>
      }
      loading={loading}
      handleSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Remove'),
        className: 'btn btn-danger',
        disabled: loading,
      }}
      title="Remove This Play Location?"
      toggleProps={{
        className: 'btn btn-outline-danger',
        children: (
          <>
            <Icon className="mr-1" value="trash" />
            Remove
          </>
        ),
      }}
    >
      Are you sure you want to remove&nbsp;
      <strong>{nickname}</strong>
      &nbsp;from this organization?
    </ModalForm>
  );
};

DeletePlayLocationModalUnwrapped.propTypes = {
  id: PropTypes.number.isRequired,
  deletePlayLocation: PropTypes.func.isRequired,
  nickname: PropTypes.string.isRequired,
};

const MUTATION = gql`
  mutation DeleteOrganizationPlayLocation(
    $input: DeleteOrganizationPlayLocationInput!
  ) {
    deleteOrganizationPlayLocation(input: $input) {
      organization {
        id
        playLocations {
          id
          primary
        }
      }
    }
  }
`;

const DeletePlayLocationModal = ({ id, nickname }) => (
  <Mutation mutation={MUTATION} variables={{ input: { id } }}>
    {(mutate) => (
      <DeletePlayLocationModalUnwrapped
        id={id}
        nickname={nickname}
        deletePlayLocation={() => mutate()}
      />
    )}
  </Mutation>
);

DeletePlayLocationModal.propTypes = {
  id: PropTypes.number.isRequired,
  nickname: PropTypes.string.isRequired,
};

export default DeletePlayLocationModal;
