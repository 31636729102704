export const CAMPAIGN_DASHBOARD_PATH_PREFIX = '/campaign-dashboard';
export const SEASONAL_DASHBOARD_PATH_PREFIX = '/seasonal-dashboard';

export const CAMPAIGN_PLANNER_PREFIX = '/campaign-planner';
export const CAMPAIGN_PLANNER_PATH = `${CAMPAIGN_PLANNER_PREFIX}/sponsors`;

export const ORGANIZATION_DASHBOARD_PATH = '/organization-dashboard';

export const ORGANIZATION_PACKAGES_PATH = `${ORGANIZATION_DASHBOARD_PATH}/packages`;

export const ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH = `${ORGANIZATION_DASHBOARD_PATH}/package-builder`;

export const ORGANIZATION_DASHBOARD_OVERVIEW_PATH = `${ORGANIZATION_DASHBOARD_PATH}/overview`;

export const OFFER_PREFIX = '/offers';

export const ORGANIZATIONS_PATH = '/organizations';

export const SPONSOR_DASHBOARD_PATH = '/sponsor-dashboard';
