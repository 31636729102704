import React from 'react';
import { isEmpty, map, partition, pathEq } from 'ramda';
import PropTypes from 'prop-types';
import GameSignageTable from './GameSignageTable';
import DedicatedEmailTable from './DedicatedEmailTable';
import SocialMediaTable from './SocialMediaTable';
import DefaultCapabilityTable from './DefaultCapabilityTable';
import TeamOfGamesJerseysTable from './TeamOfGameJerseysTable';

const CapabilitiesTableController = ({ organizationCapabilities }) => {
  const [gameSignageCapabilities, otherCapabilities] = partition(
    pathEq(['capability', 'name'], 'Game signage'),
    organizationCapabilities,
  );
  const [dedicatedEmailCapabilities, remainingCapabilitiesFromDedicatedEmails] =
    partition(
      pathEq(['capability', 'name'], 'Dedicated emails'),
      otherCapabilities,
    );
  const [
    socialMediaPostsCapabilities,
    remainingCapabilitiesFromSocialMediaPostsCapabilities,
  ] = partition(
    pathEq(['capability', 'name'], 'Social media posts'),
    remainingCapabilitiesFromDedicatedEmails,
  );
  const [teamOfGameJerseysCapabilities, remainingCapabilities] = partition(
    pathEq(['capability', 'name'], 'Teams of Game Jerseys'),
    remainingCapabilitiesFromSocialMediaPostsCapabilities,
  );

  const buildAllOrganizationCapabilites = () => {
    const organizationCapabilityMap = {};

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < remainingCapabilities.length; i++) {
      if (
        organizationCapabilityMap[`${remainingCapabilities[i].capability.name}`]
      ) {
        organizationCapabilityMap[
          `${remainingCapabilities[i].capability.name}`
        ].push(remainingCapabilities[i]);
      } else {
        organizationCapabilityMap[
          `${remainingCapabilities[i].capability.name}`
        ] = [remainingCapabilities[i]];
      }
    }

    return Object.entries(organizationCapabilityMap).map(([_, value]) => value);
  };

  return (
    <>
      {!isEmpty(gameSignageCapabilities) ? (
        <GameSignageTable gameSignageCapabilites={gameSignageCapabilities} />
      ) : null}
      {!isEmpty(dedicatedEmailCapabilities) ? (
        <DedicatedEmailTable
          dedicatedEmailCapabilites={dedicatedEmailCapabilities}
        />
      ) : null}
      {!isEmpty(socialMediaPostsCapabilities) ? (
        <SocialMediaTable
          socialMediaCapabilities={socialMediaPostsCapabilities}
        />
      ) : null}
      {!isEmpty(teamOfGameJerseysCapabilities) ? (
        <TeamOfGamesJerseysTable
          teamOfGameJerseysCapabilities={teamOfGameJerseysCapabilities}
        />
      ) : null}
      {!isEmpty(remainingCapabilities)
        ? map(
            (oc) => <DefaultCapabilityTable defaultCapabilites={oc} />,
            buildAllOrganizationCapabilites(),
          )
        : null}
    </>
  );
};

CapabilitiesTableController.propTypes = {
  organizationCapabilities: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.object),
  ).isRequired,
};

export default CapabilitiesTableController;
