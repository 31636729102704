import * as React from 'react';
import { map } from 'ramda';
import classNames from 'classnames';
import GenericCreativeContent from 'types/genericCreativeContent';
import GenericCreativeFile from 'types/genericCreativeFile';
import { VerificationDeadlineWithAdditionalStatuses } from 'types/verificationDeadline';
import formatDate from 'utils/formatDate';
import { sortByName } from 'utils/sort';
import Badge from 'components/Badge';
import MissingCreativesIndicator from 'components/MissingCreativesIndicator';
import NewWindowLink from 'components/NewWindowLink';
import VerificationsListIcon from 'components/VerificationsListIcon';
import PreviewGenericCreativeContentModal from 'components/PreviewGenericCreativeContentModal';
import './VerificationDeadlineDetails.css';
import DangerousInnerHtml from 'components/dangerous-inner-html/dangerous-inner-html';
import OfferUserVerificationImageModal from './OfferUserVerificationImageModal';

interface VerificationDeadline
  extends VerificationDeadlineWithAdditionalStatuses {
  creativeContents: GenericCreativeContent[];
  creativeFiles: GenericCreativeFile[];
  renderedVerificationReminderInstructions: string;
}

interface Props {
  verificationDeadline: VerificationDeadline;
  refetch: Function;
  presenterFactory: PresenterFactory;
}
interface PresenterFactory {
  buildLeagueOfferPagePresenter: Function;
}

const VerificationDeadlineDetails: React.FunctionComponent<Props> = ({
  verificationDeadline,
  refetch,
  presenterFactory,
}: Props) => {
  const {
    id,
    anyPendingVerificationImages,
    creativeContents,
    creativeFiles,
    deadline,
    didNotComplete,
    missingCreatives,
    name,
    overdue,
    renderedVerificationReminderInstructions,
    verified,
  } = verificationDeadline;

  const creativeClassName = 'btn btn-link mr-3 p-0';
  const muteText = missingCreatives || anyPendingVerificationImages;
  const presenter = presenterFactory.buildLeagueOfferPagePresenter();

  return (
    <div className="row mb-5" data-test={`verification-deadline-row-${id}`}>
      <div className="col-2 col-md-1 text-nowrap">
        <VerificationsListIcon
          anyPendingVerificationImages={anyPendingVerificationImages}
          className="mr-2"
          didNotComplete={didNotComplete}
          key={deadline}
          overdue={overdue}
          verified={verified}
        />
        <h3
          className={classNames('verification-deadline-details-date', {
            'text-really-muted': muteText,
          })}
        >
          {formatDate(deadline, 'M/D/YY')}
        </h3>
      </div>
      <div className="col">
        <div className="d-flex mb-2">
          <div className="d-flex flex-wrap align-items-center">
            <div
              className={classNames(
                'verification-deadline-details-header pr-2',
                { 'text-really-muted': muteText },
              )}
            >
              {name}
            </div>
            {anyPendingVerificationImages && (
              <div className="verification-deadline-details-badge pr-2">
                <Badge
                  badgeType="warning"
                  title="Thank you for uploading your verifications! We will update the status of this verification deadline after we review!"
                >
                  Under Review!
                </Badge>
              </div>
            )}
          </div>
          <div className="ml-auto">
            {!verified && (
              <OfferUserVerificationImageModal
                verificationDeadline={verificationDeadline}
                refetch={refetch as any}
                presenter={presenter}
              />
            )}
          </div>
        </div>
        <div className={classNames('mb-1', { 'text-really-muted': muteText })}>
          <DangerousInnerHtml html={renderedVerificationReminderInstructions} />
        </div>
        {missingCreatives ? (
          <MissingCreativesIndicator />
        ) : (
          <>
            {!!creativeFiles.length && (
              <div>
                {map(
                  (creativeFile) => (
                    <NewWindowLink
                      className={creativeClassName}
                      hideIcon
                      key={creativeFile.id}
                      to={creativeFile.fullSizeUrl}
                    >
                      {creativeFile.name}
                    </NewWindowLink>
                  ),
                  sortByName(creativeFiles),
                )}
              </div>
            )}
            {!!creativeContents.length && (
              <div>
                {map(
                  (creativeContent) => (
                    <PreviewGenericCreativeContentModal
                      genericCreativeContent={creativeContent}
                      key={creativeContent.id}
                      toggleClassName={creativeClassName}
                    />
                  ),
                  sortByName(creativeContents),
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VerificationDeadlineDetails;
