import { useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';
import type { Check } from 'types/offer';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import SPONSORSHIP_EVENT_FRAGMENT from 'fragments/SponsorshipEventFragment';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import CheckModalTable from 'components/CheckModalTable';
import RestrictTo from 'components/RestrictTo';

type VoidCheckModalProps = {
  check: Check;
};

function VoidCheckModal(props: VoidCheckModalProps) {
  const { check } = props;
  const [reason, setReason] = useState('');
  const [mutate] = useMutation(MUTATION, {
    variables: { input: { checkId: check.id, reason } },
  });

  const voidCheck = (reasonToSet: string) =>
    mutate({
      variables: { input: { checkId: check.id, reason: reasonToSet } },
    });

  const { error, loading, handleSubmit } = useSubmit(() => voidCheck(reason));

  const renderBody = (
    <>
      <p>Are you sure you want to void the following check?</p>
      <CheckModalTable check={check} />
      <div className="form-group">
        <label htmlFor={`void-reason-${check.id}`}>Void Reason</label>
        <input
          className="form-control"
          disabled={loading}
          id={`void-reason-${check.id}`}
          onChange={(event) => setReason(event.target.value)}
          type="text"
          value={reason}
        />
      </div>
    </>
  );

  return (
    <RestrictTo roles={['superadmin']}>
      <ModalForm
        id={`void-check-modal-${check.id}`}
        error={error}
        loading={loading}
        onSubmit={handleSubmit}
        submitProps={{
          className: classNames('btn btn-primary', { 'font-italic': loading }),
          children: (
            <>
              {loading ? null : <Icon className="mr-1" value="cross" />}
              {loadingTense(loading, 'Void Check')}
            </>
          ),
          disabled: !reason || loading,
        }}
        toggleProps={{
          className: 'btn btn-link text-danger p-0',
          children: <Icon value="block" />,
        }}
        title="Void Check"
      >
        {renderBody}
      </ModalForm>
    </RestrictTo>
  );
}

const MUTATION = gql`
  mutation VoidCheck($input: VoidCheckInput!) {
    voidCheck(input: $input) {
      offer {
        id
        checks {
          id
          actionReason
          status
          voidedBy {
            id
            name
          }
        }
        sponsorshipEvents {
          ...SponsorshipEventFragment
        }
      }
    }
  }
  ${SPONSORSHIP_EVENT_FRAGMENT}
`;

export default VoidCheckModal;
