import React from 'react';
import PropTypes from 'prop-types';
import leaguesideAPI from 'utils/leagueside-api';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import ContactUsLink from 'components/ContactUsLink';
import ErrorAlert from './ErrorAlert';

const errorMessage = (
  <ErrorAlert data-test="forgot-password-error">
    An error occurred. Please try again. If the problem persists, please&nbsp;
    <ContactUsLink />.
  </ErrorAlert>
);

/* eslint-disable max-len */
const submittedMessage = (
  <ModalBody>
    <div data-test="success-message" className="text-align-center">
      If there&#39;s an account associated with this email address, you&#39;ll
      receive an email shortly to reset your password and access your Teamsnap
      account.
    </div>
  </ModalBody>
);
/* eslint-enable max-len */

export default class ForgotPasswordModal extends React.Component {
  constructor(props) {
    super(props);
    const { initialEmail } = props;
    this.state = { email: initialEmail, submitted: false, error: null };
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();

    const { email } = this.state;
    try {
      await leaguesideAPI.accountRecovery(email);
      this.setState({ error: null, submitted: true });
    } catch (error) {
      if (error && error.response) {
        this.setState({ error: error.response });
      }
    }
  }

  render() {
    const { submitted, error, email } = this.state;
    return (
      <>
        <button
          className="btn btn-link btn-inline-link ts-blue"
          data-dismiss="modal"
          data-target="#recover-account-modal"
          data-toggle="modal"
          id="recover-account-modal-button"
          type="button"
        >
          Forgot Password?
        </button>
        <Modal id="recover-account-modal" title="Forgot Password?">
          {submitted ? (
            submittedMessage
          ) : (
            <form onSubmit={this.handleSubmit}>
              <ModalBody>
                <p>
                  Please enter the email address associated with your Teamsnap
                  account, and we will email you a link to reset your password.
                </p>
                {error && errorMessage}
                <div className="form-control-group">
                  <label htmlFor="email-input">Email</label>
                  <input
                    className="form-control"
                    name="email-input"
                    id="email-input"
                    type="email"
                    placeholder="Your email"
                    required
                    onChange={this.handleEmailChange}
                    value={email || ''}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  data-test="submit-forgot-password"
                  className="btn btn-primary"
                  disabled={!email}
                >
                  Confirm
                </button>
              </ModalFooter>
            </form>
          )}
        </Modal>
      </>
    );
  }
}

ForgotPasswordModal.propTypes = {
  initialEmail: PropTypes.string,
};

ForgotPasswordModal.defaultProps = {
  initialEmail: null,
};
