import OrganizationOfferPresenter from 'pages/leagueOffer/OrganizationOfferPresenter';

export default class PresenterFactory {
  constructor(apiService) {
    this.apiService = apiService;
  }

  buildLeagueOfferPagePresenter() {
    return new OrganizationOfferPresenter(this.apiService);
  }
}
