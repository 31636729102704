import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import TextAreaInput from 'components/formInputs/TextAreaInput';
import WithSaveFooter from 'components/WithSaveFooter';

export const AdditionalRequirementsUnwrapped = ({
  additionalRequirements,
  onSave,
}) => (
  <WithSaveFooter
    handleMutation={onSave}
    savedValue={{ additionalRequirements }}
  >
    {({ addChanges, value }) => (
      <TextAreaInput
        formGroupClassName="mb-0"
        helpBlock="Please be aware that this text will be sent directly to any leagues receiving offers. It should be used to inform leagues of additional requirements or limitations on their part."
        label="Additional Requirements"
        labelClassName="font-weight-bold"
        onChange={({ target }) =>
          addChanges({ additionalRequirements: target.value })
        }
        value={value.additionalRequirements || ''}
      />
    )}
  </WithSaveFooter>
);

AdditionalRequirementsUnwrapped.propTypes = {
  additionalRequirements: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

AdditionalRequirementsUnwrapped.defaultProps = {
  additionalRequirements: '',
};

const MUTATION = gql`
  mutation UpdateCampaign($input: CampaignInput!) {
    updateCampaign(input: $input) {
      id
      additionalRequirements
    }
  }
`;

const AdditionalRequirements = ({ campaign }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <AdditionalRequirementsUnwrapped
        additionalRequirements={campaign.additionalRequirements}
        onSave={({ additionalRequirements }) =>
          mutate({
            variables: { input: { additionalRequirements, id: campaign.id } },
          })
        }
      />
    )}
  </Mutation>
);

AdditionalRequirements.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    additionalRequirements: PropTypes.string,
  }).isRequired,
};

export default AdditionalRequirements;
