import gql from 'graphql-tag';

export const SPONSORSHIP_APPLICATION_QUERY = gql`
  query SponsorshipApplicationView(
    $season: String!
    $year: Int!
    $campaignId: Int!
    $offerId: Int!
  ) {
    sponsorshipApplicationView {
      id
      name
      organizationTypeId
      socialMediaFollowingSize
      emailListSize
      emailOpenRate
      currentSponsorableProperties(season: $season) {
        id
        name
        competitiveness {
          id
        }
        sport {
          id
        }
        numberOfPlayers
        minAge
        maxAge
        startDate
        endDate
        male
        female
        season
      }
      playLocations {
        id
        address
        streetAddress
        city
        state
        postalCode
        country
        primaryPrograms {
          id
          name
        }
        otherPrograms {
          id
          name
        }
      }
      sponsorshipApplication(season: $season, year: $year) {
        id
        season
        year
        chosenInterests
        completionPercentage
        notInterested
        primaryContactFirstName
        primaryContactLastName
        primaryContactEmail
        primaryContactPhone
        additionalInformation
        interestedInHundredx
        tournament {
          id
          hostTournament
          name
          firstDate
          lastDate
          numberOfPlayers
          address
          city
          state
          postalCode
          country
          freeParking
          canShipInAdvance
          shipmentsArrival
          requireCoi
          hasContingencyPlan
        }
        customQuestions(campaignId: $campaignId) {
          id
          question
        }
        customResponses {
          id
          customQuestionId
          response
        }
        savedStepId
        savedFormData
        offer(offerId: $offerId) {
          id
          guid
        }
      }
    }
  }
`;

export const APPLICATION_PROGRESS_QUERY = gql`
  query SponsorshipApplicationView($season: String!, $year: Int!) {
    sponsorshipApplicationView {
      id
      sponsorshipApplication(season: $season, year: $year) {
        id
        completionPercentage
        applicationProgress
      }
    }
  }
`;

export const SPONSORSHIP_APPLICATION_OPTIONS_LIST_QUERY = gql`
  query sponsorshipApplicationOptionsList {
    interestsList
    bannerSizesList
    bannerPlacementsList
  }
`;

export const ORGANIZATION_TYPES_LIST_QUERY = gql`
  query OrganizationTypes {
    organizationTypes {
      id
      name
    }
  }
`;

export const COMPETITIVENESSES_LIST_QUERY = gql`
  query Competitivenesses {
    competitivenesses {
      id
      name
    }
  }
`;

export const SPORTS_LIST_QUERY = gql`
  query Sports {
    sports {
      id
      name
    }
  }
`;

export const SAVE_APPLICATION_MUTATION = gql`
  mutation saveApplicationStepData($input: SaveApplicationStepDataInput!) {
    saveApplicationStepData(input: $input) {
      id
    }
  }
`;
