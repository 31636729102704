import React from 'react';
import PropTypes from 'prop-types';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import BackToLink from 'components/BackToLink';
import Sections, { Section } from 'components/Sections';
import Page from 'components/Page';
import { useQuery } from 'react-apollo';
import CampaignDashboardCard from './campaignOverview/CampaignDashboardCard';
import CampaignDashboardTab from './campaignOverview/CampaignDashboardTab';
import CampaignStatusSelect from './campaignOverview/CampaignStatusSelect';
import CampaignTab from './campaignOverview/CampaignTab';
import getCampaign from './campaignOverview/getCampaign';
import SponsorablePropertyProspectingCard from './campaignOverview/SponsorablePropertyProspectingCard';
import SponsorablePropertiesTab from './campaignOverview/SponsorablePropertiesTab';
import Requirements from './campaignOverview/Requirements';
import TargetingCard from './campaignOverview/TargetingCard';
import VerificationsCard from './campaignOverview/VerificationsCard';
import PrimaryContactsTab from './campaignOverview/PrimaryContactsTab';
import SponsorshipApplicationTab from './campaignOverview/SponsorshipApplicationTab';

const CampaignOverview = ({ match }) => {
  const { data } = useQuery(getCampaign, {
    variables: { id: Number(match.params.campaignId) },
  });

  const campaign = data && data.campaign;

  return (
    <Page loaded={!!campaign}>
      {campaign && (
        <>
          <BackToLink
            label={campaign.sponsor.name}
            path={`${CAMPAIGN_PLANNER_PATH}/${match.params.sponsorId}`}
          />
          <h1>
            {campaign.name}
            <CampaignStatusSelect campaign={campaign} />
          </h1>
          <Sections
            append={
              <>
                <TargetingCard campaign={campaign} />
                <SponsorablePropertyProspectingCard campaign={campaign} />
                <VerificationsCard campaign={campaign} />
                {campaign.magicToken && (
                  <CampaignDashboardCard magicToken={campaign.magicToken} />
                )}
              </>
            }
          >
            <Section name="Campaign">
              <CampaignTab campaign={campaign} />
            </Section>
            <Section name="Campaign Dashboard" path="dashboard">
              <CampaignDashboardTab campaign={campaign} />
            </Section>
            <Section name="Requirements" path="requirements">
              <Requirements campaign={campaign} />
            </Section>
            <Section
              name="Sponsorship Application"
              path="sponsorship-application"
            >
              <SponsorshipApplicationTab campaign={campaign} />
            </Section>
            <Section name="Sponsorable Properties" path="included-leagues">
              <SponsorablePropertiesTab campaignId={campaign.id} />
            </Section>
            <Section name="Primary Contacts" path="primary-contacts">
              <PrimaryContactsTab campaign={campaign} />
            </Section>
          </Sections>
        </>
      )}
    </Page>
  );
};

CampaignOverview.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sponsorId: PropTypes.string,
    }),
  }).isRequired,
};

CampaignOverview.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string,
      sponsorId: PropTypes.string,
    }),
  }).isRequired,
};

export default CampaignOverview;
