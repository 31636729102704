import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { lensPath, merge, prop, set } from 'ramda';
import PropTypes from 'prop-types';
import { updateLocalStorageByKeyAndState } from 'utils/localStorage';
import { pluralizedTense } from 'utils/string';
import {
  inferAndReturnStatuses,
  LOCAL_STORAGE_KEY,
  DEFAULT_STATE,
  getStateFromLocalStorage,
} from 'utils/statusVisibility';
import QualifiedTable from 'components/sponsorablePropertyTables/QualifiedTable';
import ConfirmedTable from 'components/sponsorablePropertyTables/ConfirmedTable';
import ProposedTable from 'components/sponsorablePropertyTables/ProposedTable';
import ProspectsTable from 'components/sponsorablePropertyTables/ProspectsTable';
import RemovedTable from 'components/sponsorablePropertyTables/RemovedTable';
import ModalSpinner from 'components/ModalSpinner';
import SponsorablePropertyListDownloadButton from './SponsorablePropertyListDownloadButton';
import CAMPAIGN_QUERY, {
  PROSPECTING_AND_QUALIFIED_OFFERS_REFETCH_QUERY,
  PROSPECTING_AND_REMOVED_OFFERS_REFETCH_QUERY,
  SEND_SURVEY_CAMPAIGN_REFETCH_QUERY,
  SEND_OFFER_EMAILS_CAMPAIGN_REFETCH_QUERY,
  PROPOSED_AND_REMOVED_OFFERS_REFETCH_QUERY,
  QUALIFIED_AND_PROPOSED_OFFERS_REFETCH_QUERY,
  QUALIFIED_AND_REMOVED_OFFERS_REFETCH_QUERY,
  PROSPECTING_OFFERS_REFETCH_QUERY,
} from './sponsorablePropertiesTabQueries';

const updateLocalStorageByState =
  updateLocalStorageByKeyAndState(LOCAL_STORAGE_KEY);

const SponsorablePropertiesTab = ({ campaignId }) => {
  const [state, setState] = useState(getStateFromLocalStorage());
  const stateForCampaign = merge(DEFAULT_STATE, state[campaignId]);

  const { loading, data } = useQuery(CAMPAIGN_QUERY, {
    variables: { id: campaignId },
  });

  const { refetch: refetchProspectingOffers } = useQuery(
    PROSPECTING_OFFERS_REFETCH_QUERY,
    {
      variables: { id: campaignId },
      skip: true,
    },
  );

  const { refetch: refetchSendSurveyCampaign } = useQuery(
    SEND_SURVEY_CAMPAIGN_REFETCH_QUERY,
    {
      variables: { id: campaignId },
      skip: true,
    },
  );

  const { refetch: refetchProspectingAndQualifiedOffers } = useQuery(
    PROSPECTING_AND_QUALIFIED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: campaignId },
      skip: true,
    },
  );

  const { refetch: refetchProspectingAndRemovedOffers } = useQuery(
    PROSPECTING_AND_REMOVED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: campaignId },
      skip: true,
    },
  );

  const { refetch: refetchQualifiedAndProposedOffers } = useQuery(
    QUALIFIED_AND_PROPOSED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: campaignId },
      skip: true,
    },
  );

  const { refetch: refetchQualifiedAndRemovedOffers } = useQuery(
    QUALIFIED_AND_REMOVED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: campaignId },
      skip: true,
    },
  );

  const { refetch: refetchSendOfferEmailsCampaign } = useQuery(
    SEND_OFFER_EMAILS_CAMPAIGN_REFETCH_QUERY,
    {
      variables: { id: campaignId },
      skip: true,
    },
  );

  const { refetch: refetchProposedAndRemovedOffers } = useQuery(
    PROPOSED_AND_REMOVED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: campaignId },
      skip: true,
    },
  );

  useEffect(updateLocalStorageByState(state));

  const campaign = data && data.campaign;

  if (loading || !campaign) return <ModalSpinner show />;

  const toggle = (statusProp) => {
    const lens = lensPath([campaignId, statusProp]);

    return (event) => setState(set(lens, event.target.checked));
  };

  const renderCheckbox = (status, label, classNameProps = '') => {
    const checked = prop(`${status}Visible`, stateForCampaign);

    return (
      <div className={`form-check d-inline-block mr-3 ${classNameProps}`}>
        <input
          checked={checked}
          className="form-check-input"
          id={`${status}-toggle`}
          onChange={toggle(`${status}Visible`)}
          type="checkbox"
        />
        <label
          data-test={`${status}-toggle`}
          className="form-check-label align-middle"
          htmlFor={`${status}-toggle`}
        >
          {label}
        </label>
      </div>
    );
  };

  const { prospecting, qualified, proposed, confirmed, removed } =
    campaign.offerCountsReport;

  return (
    <div className="card-body">
      <h2>Sponsorable Properties</h2>
      <strong className="d-block mb-4">
        {renderCheckbox(
          'prospects',
          `${prospecting} ${pluralizedTense(prospecting, 'Prospect')}`,
        )}
        {renderCheckbox('qualified', `${qualified} Qualified`)}
        {renderCheckbox('proposed', `${proposed} Proposed`)}
        {renderCheckbox('confirmed', `${confirmed} Confirmed`)}
        {renderCheckbox('removed', `${removed} Removed`, 'text-muted')}
        <SponsorablePropertyListDownloadButton
          campaignId={campaignId}
          statuses={inferAndReturnStatuses(stateForCampaign)}
        />
      </strong>
      {stateForCampaign.prospectsVisible && (
        <ProspectsTable
          campaignId={campaignId}
          campaignName={campaign.name}
          displayProspectsDropdown
          hasSurveyQuestions={campaign.hasSurveyQuestions}
          offers={campaign.prospectingOffers}
          onAddProspects={refetchProspectingOffers}
          onMarkAsQualified={refetchProspectingAndQualifiedOffers}
          onRemoveFromCampaign={refetchProspectingAndRemovedOffers}
          onSendPreQualSurvey={refetchSendSurveyCampaign}
          sponsorId={campaign.sponsor.id}
          organizationCount={campaign.organizationCount}
        />
      )}
      {stateForCampaign.qualifiedVisible && (
        <QualifiedTable
          offers={campaign.qualifiedOffers}
          onRemoveFromCampaign={refetchQualifiedAndRemovedOffers}
          onSendOfferEmails={refetchQualifiedAndProposedOffers}
        />
      )}
      {stateForCampaign.proposedVisible && (
        <ProposedTable
          offers={campaign.proposedOffers}
          onRemoveFromCampaign={refetchProposedAndRemovedOffers}
          onSendOfferEmails={refetchSendOfferEmailsCampaign}
        />
      )}
      {stateForCampaign.confirmedVisible && (
        <ConfirmedTable offers={campaign.confirmedOffers} />
      )}
      {stateForCampaign.removedVisible && (
        <RemovedTable offers={campaign.removedOffers} />
      )}
    </div>
  );
};

SponsorablePropertiesTab.propTypes = {
  campaignId: PropTypes.number.isRequired,
};

export default SponsorablePropertiesTab;
