import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { capitalize, conjugateString } from 'utils/string';
import { useSubmitWithGQL } from 'utils/hooks';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

const MUTATION = gql`
  mutation DeleteSponsorableProperty($input: DeleteSponsorablePropertyInput!) {
    deleteSponsorableProperty(input: $input) {
      organization {
        id
        sponsorableProperties {
          id
        }
      }
    }
  }
`;

const DeleteSponsorablePropertyModal = ({ sponsorableProperty }) => {
  const { id: sponsorablePropertyId } = sponsorableProperty;

  const [mutate] = useMutation(MUTATION);
  const remove = () =>
    mutate({ variables: { input: { id: sponsorablePropertyId } } });

  const { error, loading, handleSubmit } = useSubmitWithGQL(remove);

  return (
    <ModalForm
      id={`delete-sponsorable-property-${sponsorablePropertyId}-modal`}
      error={error}
      errorMessage={
        <div className="alert alert-danger mb-0 mt-3">
          <ul className="mb-0">{!!error && error.graphQLErrors[0].message}</ul>
        </div>
      }
      loading={loading}
      handleSubmit={handleSubmit}
      submitProps={{
        children: conjugateString('Delete', loading),
        className: 'btn btn-danger',
      }}
      title={`Delete ${capitalize(sponsorableProperty.name)}?`}
      toggleProps={{
        className: 'btn btn-link text-danger p-0',
        children: <Icon value="trash" />,
      }}
    >
      <p>Are you sure you want to delete this sponsorable property?</p>
    </ModalForm>
  );
};

DeleteSponsorablePropertyModal.propTypes = {
  sponsorableProperty: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteSponsorablePropertyModal;
