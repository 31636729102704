import * as React from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils/formatNumber';
import { ORGANIZATION_PACKAGES_PATH } from 'utils/paths';
import SponsorablePropertyPackage from 'types/sponsorablePropertyPackage';
import OrganizationCapabilityPackage from 'types/organizationCapabilityPackage';
import RemoveLeaguePackageModal from './leaguePackageCard/RemoveLeaguePackageModal';
import AddedSponsorablePropertyCapabilityPackages from './AddedSponsorablePropertyCapabilityPackages';

interface SponsorablePropertyPackageWithDescription
  extends SponsorablePropertyPackage {
  organizationCapabilityPackages: OrganizationCapabilityPackage[];
  packageDescription?: string;
}

interface Props {
  additionalHeaderLabel?: JSX.Element;
  sponsorablePropertyPackage: SponsorablePropertyPackageWithDescription;
  mutation?: object;
}

const SponsorablePropertyPackageCard: React.FunctionComponent<Props> = ({
  additionalHeaderLabel,
  sponsorablePropertyPackage,
  mutation,
}: Props) => {
  const {
    id,
    name,
    price,
    sponsorablePropertyCapabilityPackages,
    packageDescription,
  } = sponsorablePropertyPackage;

  return (
    <div
      className="card bg-lighter my-3"
      data-test={`sponsorable-property-package-card-${id}`}
    >
      <div className="card-header row bg-success mx-0 px-3">
        <div className="col-1 p-0" />
        <div className="col-10 text-white text-center p-0">
          <h6 className="my-0">{name}</h6>
          <h1 className="my-0">{formatCurrency(price)}</h1>
          {!!packageDescription && (
            <div className="mt-2">{packageDescription}</div>
          )}
          {additionalHeaderLabel}
        </div>
        <div className="col-1 p-0 text-right">
          {!!mutation && (
            <RemoveLeaguePackageModal
              leaguePackage={sponsorablePropertyPackage}
              mutation={mutation}
            />
          )}
        </div>
      </div>
      <ul className="list-group list-group-flush">
        <AddedSponsorablePropertyCapabilityPackages
          sponsorablePropertyCapabilityPackages={
            sponsorablePropertyCapabilityPackages ||
            sponsorablePropertyPackage.organizationCapabilityPackages
          }
        />
      </ul>
      {!!mutation && (
        <div className="card-footer text-center">
          <Link
            className="font-weight-bold"
            to={`${ORGANIZATION_PACKAGES_PATH}/${id}`}
          >
            Update Package
          </Link>
        </div>
      )}
    </div>
  );
};

SponsorablePropertyPackageCard.defaultProps = {
  additionalHeaderLabel: undefined,
  mutation: undefined,
};

export default SponsorablePropertyPackageCard;
