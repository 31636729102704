import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import ImageInput from 'components/formInputs/ImageInput';
import Alert from 'components/Alert';

const OfferUserVerificationImageModal = ({
  verificationDeadline,
  refetch,
  presenter,
}) => {
  const [loading, setLoading] = useState(false);
  const preview = React.createRef();
  const toggleClassName = useState('btn btn-secondary btn-sm');
  const [image, setImage] = useState(null);
  const [successNotification, setSuccessNotification] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const id = `organization-user-verification-image-${verificationDeadline.id}`;

  function setSuccessfulUploadState() {
    setLoading(false);
    setImage(null);
    setSuccessNotification(true);
    setUploadError(false);
  }
  function handleImageChange(event) {
    const newImage = event.target.files[0];
    setImage(newImage);
    if (newImage) {
      const currentPreview = preview.current;
      currentPreview.src = URL.createObjectURL(newImage);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);

    const response = await presenter.submitVerificationImageFromLeagueUser(
      verificationDeadline.id,
      image,
    );
    if (response && response.isAxiosError) {
      setUploadError(true);
      setSuccessNotification(false);
      setLoading(false);
    } else {
      setSuccessfulUploadState();
    }
  }

  return (
    <ModalForm
      id={`${id}-modal`}
      loading={loading}
      /* eslint-disable react/jsx-no-bind */
      handleSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Upload'),
        disabled: !image || loading,
      }}
      title="Upload Verification Image"
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon className="mr-1" value="image" />
            Upload Verification
          </>
        ),
      }}
      onClose={refetch}
    >
      <ImageInput
        image={image}
        isRequired
        loading={loading}
        /* eslint-disable react/jsx-no-bind */
        onChange={handleImageChange}
        prefix={id}
      />
      <span>Please upload a JPEG, PNG, or GIF of your verification.</span>
      {successNotification && (
        <Alert data-test="verification-success-alert" alertStyle="primary">
          Image successfully uploaded. If necessary, you can now upload another
          image.
        </Alert>
      )}
      {uploadError && (
        <Alert data-test="verification-error-alert" alertStyle="danger">
          Image did not upload. Please try again or send an email to
          support@sponsorship.teamsnap.com with the image as an attachment
        </Alert>
      )}
      <div className="form-group">
        {image && (
          <label className="font-weight-bold" htmlFor="upload-preview">
            Image Preview
          </label>
        )}
        <img
          alt="preview"
          id="upload-preview"
          className="form-control img-thumbnail"
          ref={preview}
          style={{ display: image ? '' : 'none' }}
        />
      </div>
    </ModalForm>
  );
};

OfferUserVerificationImageModal.propTypes = {
  verificationDeadline: PropTypes.shape({
    id: PropTypes.number.isRequired,
    deadline: PropTypes.string.isRequired,
    didNotComplete: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
  presenter: PropTypes.shape({
    submitVerificationImageFromLeagueUser: PropTypes.func.isRequired,
  }).isRequired,
};

OfferUserVerificationImageModal.defaultProps = {};

export default OfferUserVerificationImageModal;
