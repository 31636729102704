import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import USER_FRAGMENT from 'fragments/UserFragment';
import AddExternalUserModal from 'components/AddExternalUserModal';

const MUTATION = gql`
  mutation InviteOrganizationUser($input: InviteOrganizationUserInput!) {
    inviteOrganizationUser(input: $input) {
      organization {
        id
        users {
          ...UserFragment
          lastLogin
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

const AddOrganizationUserModal = ({ internal, organizationId }) => {
  const [mutate] = useMutation(MUTATION);
  return (
    <AddExternalUserModal
      addUser={(email) =>
        mutate({ variables: { input: { organizationId, email } } })
      }
      internal={internal}
    />
  );
};

AddOrganizationUserModal.propTypes = {
  internal: PropTypes.bool,
  organizationId: PropTypes.number.isRequired,
};

AddOrganizationUserModal.defaultProps = {
  internal: false,
};

export default AddOrganizationUserModal;
