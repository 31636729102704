import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import RemoveGenericCreativeModal from 'components/RemoveGenericCreativeModal';
import type { CreativeFile } from 'types/offer';

type RemoveCreativeFileModalProps = {
  creativeFile: CreativeFile;
  toggleClassName?: string;
};

function RemoveCreativeFileModal(props: RemoveCreativeFileModalProps) {
  const {
    creativeFile,
    toggleClassName = 'btn btn-link text-danger p-0 ml-auto',
  } = props;

  return (
    <Mutation mutation={MUTATION}>
      {(mutate: any) => (
        <RemoveGenericCreativeModal
          creativeType="file"
          id={creativeFile.id}
          name={creativeFile.name}
          remove={() =>
            mutate({
              variables: {
                input: { id: Number(creativeFile.id) },
              },
            })
          }
          toggleClassName={toggleClassName}
        />
      )}
    </Mutation>
  );
}

export default RemoveCreativeFileModal;

const MUTATION = gql`
  mutation DeleteCreativeFile($input: CreativeFileInput!) {
    deleteCreativeFile(input: $input) {
      verificationDeadline {
        id
        creativeFiles {
          id
        }
        missingCreatives
      }
    }
  }
`;
