import React, { Fragment, useState } from 'react';
import {
  all,
  append,
  ascend,
  contains,
  descend,
  filter,
  isEmpty,
  map,
  path,
  sortWith,
  without,
} from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatCurrency } from 'utils/formatNumber';
import { pluralizedTense } from 'utils/string';
import { sumApprovedAmounts, sumPendingAmounts } from 'utils/check';
import ActiveOffersBadges from 'components/ActiveOffersBadges';
import ProgressBar from 'components/ProgressBar';
import { pluckId } from 'utils/misc';
import RecentCommentRow from './RecentCommentRow';
import SponsorablePropertyCol from './SponsorablePropertyCol';
import SendSponsorablePropertyOnboardingEmailsModal from './SendSponsorablePropertyOnboardingEmailsModal';
import OrganizationCol from './OrganizationCol';

const remainingBudget = (offer) =>
  offer.value -
  sumApprovedAmounts(offer.checks) -
  sumPendingAmounts(offer.checks);
const noVerifiedPhysicalAssetVerificationDeadlines = ({
  hasVerifiedPhysicalAssetVerificationDeadline,
}) => !hasVerifiedPhysicalAssetVerificationDeadline;

const sortOffers = sortWith([
  descend(remainingBudget),
  ascend(noVerifiedPhysicalAssetVerificationDeadlines),
  ascend(path(['sponsorableProperty', 'name'])),
]);

const renderCheckProgress = (offer) => {
  const approvedAmount = sumApprovedAmounts(offer.checks);
  const pendingAmount = sumPendingAmounts(offer.checks);

  return (
    <>
      <small>
        <div className="d-none d-sm-inline-block text-nowrap">
          {`${formatCurrency(approvedAmount)} / ${formatCurrency(
            offer.value,
          )} Sent`}
        </div>
        <div className="d-none d-lg-inline-block">
          {!!pendingAmount && `, ${formatCurrency(pendingAmount)} Pending`}
        </div>
      </small>
      <ProgressBar>
        <ProgressBar maximum={offer.value} value={approvedAmount} />
        <ProgressBar
          color="warning"
          maximum={offer.value}
          value={pendingAmount}
        />
      </ProgressBar>
    </>
  );
};

const ConfirmedTable = ({ offers }) => {
  const [selectedOfferIds, setselectedOfferIds] = useState([]);

  const allOfferIds = pluckId(offers);
  const selectedIdsSet = new Set(selectedOfferIds);
  const allSelected = all((id) => selectedIdsSet.has(id), allOfferIds);
  const selectedOffers = filter(
    ({ id }) => contains(id, selectedOfferIds),
    offers,
  );

  const handleToggle = (offerId) => {
    const includesOfferId = contains(offerId, selectedOfferIds);
    const removeOfferId = without([offerId], selectedOfferIds);
    const addOfferId = append(offerId, selectedOfferIds);

    return () =>
      setselectedOfferIds(() => (includesOfferId ? removeOfferId : addOfferId));
  };

  const handleToggleAll = () => {
    setselectedOfferIds(allSelected ? [] : allOfferIds);
  };

  const columnHeaders = [
    <th scope="col" className="align-middle" key="checkbox">
      <input
        checked={allSelected}
        data-test="all-confirmed-toggle"
        onChange={handleToggleAll}
        type="checkbox"
      />
    </th>,
    <th scope="col" className="align-middle pr-1" key="organization">
      Organization
    </th>,
    <th scope="col" className="align-middle pr-1" key="sponsorableProperty">
      Sponsorable Prooperty
    </th>,
    <th
      scope="col"
      className="align-middle pl-1"
      key="report-column"
      aria-label="report-column"
    />,
    <th scope="col" className="align-middle" key="checks">
      Checks
    </th>,
    <th scope="col" className="align-middle text-center" key="verification">
      Physical Verification Received?
    </th>,
  ];

  const renderRow = (offer) => {
    const {
      id: offerId,
      offerCountsReport,
      sponsorableProperty,
      recentComment,
      fundraiserConflict,
      surveyConflict,
    } = offer;
    const { organization } = sponsorableProperty;

    return (
      <Fragment key={offerId}>
        <tr data-test={`offer-${offerId}-row`} className="p-2">
          <td className="align-middle">
            <input
              checked={contains(offerId, selectedOfferIds)}
              data-test={`confirmed-toggle-${offerId}`}
              onChange={handleToggle(offerId)}
              type="checkbox"
            />
          </td>
          <td className="align-middle pl-1">
            <OrganizationCol organization={organization} />
          </td>
          <SponsorablePropertyCol
            sponsorableProperty={sponsorableProperty}
            offerId={offerId}
          />
          <td className="align-middle pl-1">
            <ActiveOffersBadges
              offerCountsReport={offerCountsReport}
              sponsorablePropertyId={sponsorableProperty.id}
              fundraiserConflict={fundraiserConflict}
              surveyConflict={surveyConflict}
            />
          </td>
          <td className="align-middle" data-test="checks">
            {renderCheckProgress(offer)}
          </td>
          <td
            className="align-middle text-center"
            data-test="physical-verifications"
          >
            {offer.hasVerifiedPhysicalAssetVerificationDeadline ? 'Yes' : 'No'}
          </td>
        </tr>
        {!!recentComment && (
          <RecentCommentRow colLength={columnHeaders.length} offer={offer} />
        )}
      </Fragment>
    );
  };

  return (
    <>
      <div
        className="d-flex mb-2 justify-content-between align-items-center"
        data-test="confirmed-table"
      >
        <p className="m-0 sui-font-bold">{`${offers.length} Confirmed`}</p>
        <span>
          <div className="dropdown d-inline-block ml-2">
            <button
              className="btn btn-large btn-secondary dropdown-toggle"
              disabled={isEmpty(selectedOffers)}
              data-toggle="dropdown"
              id="bulk-confirmed-dropdown-menu-button"
              type="button"
            >
              {`With ${selectedOffers.length} Selected ${pluralizedTense(
                selectedOffers.length,
                'Sponsorable Property',
              )}`}
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="bulk-confirmed-dropdown-menu-button"
            >
              <SendSponsorablePropertyOnboardingEmailsModal
                id="bulk-send-onboarding-emails-modal"
                offers={selectedOffers}
                toggleClassName="btn btn-link dropdown-item"
              />
            </div>
          </div>
        </span>
      </div>
      {offers.length ? (
        <table className="table">
          <thead>
            <tr>{map((header) => header, columnHeaders)}</tr>
          </thead>
          <tbody>{map(renderRow, sortOffers(offers))}</tbody>
        </table>
      ) : (
        <p className="text-center">No confirmed sponsorships exist!</p>
      )}
    </>
  );
};

ConfirmedTable.propTypes = {
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
};

export default ConfirmedTable;
