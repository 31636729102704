import { Icon, Input, LabelButton } from '@teamsnap/snap-ui';
import gql from 'graphql-tag';
import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Campaign } from 'types/campaign';

type SponsorshipApplicationTabProps = {
  campaign: Campaign;
};

export default function SponsorshipApplicationTab(
  props: SponsorshipApplicationTabProps,
) {
  const { campaign } = props;
  const { handleSubmit, control, formState, reset } =
    useForm<SponsorshipApplicationTabInputs>({
      defaultValues: {
        seasonName: campaign.seasonName,
        customQuestions: campaign.customQuestions?.map((question) => ({
          id: question.id,
          questionSent: Boolean(question.id),
          question: question.question,
        })),
      },
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'customQuestions',
  });
  const [mutate] = useMutation(MUTATION);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onHandleSubmit = async (data: SponsorshipApplicationTabInputs) => {
    try {
      const response = await mutate({
        variables: {
          input: {
            ...data,
            id: campaign.id,
            customQuestions: data.customQuestions.map((question) => ({
              id: question.id,
              question: question.question,
            })),
          },
        },
      });
      const responseData: SponsorshipApplicationTabInputs =
        response.data.updateCampaign;
      reset({
        seasonName: responseData.seasonName,
        customQuestions: responseData.customQuestions?.map((question) => ({
          id: question.id,
          questionSent: Boolean(
            campaign.customQuestions?.find(
              (customQuestion) => customQuestion.id === question.id,
            ),
          ),
          question: question.question,
        })),
      });
      setShowSuccessMessage(true);
      setTimeout(() => setShowSuccessMessage(false), 3000);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw new Error('Error saving sponsorship application');
    }
  };

  return (
    <form className="sui-p-3" onSubmit={handleSubmit(onHandleSubmit)}>
      <p className="sui-text-desktop-10 sui-font-bold sui-mb-3">
        Sponsorship Application
      </p>

      <div className="sui-mb-3">
        <Controller
          name="seasonName"
          control={control}
          rules={{ required: 'Season name is required' }}
          render={({ field }) => (
            <Input
              {...field}
              label="Season Name"
              type="text"
              required
              errors={
                formState.errors.seasonName?.message
                  ? [formState.errors.seasonName?.message]
                  : []
              }
            />
          )}
        />
      </div>

      <p className="sui-text-desktop-6 sui-font-bold sui-text-gray-40">
        Custom questions
      </p>
      <p className="sui-text-desktop-3">
        Add campaign-specific open text questions.
      </p>

      <section className="sui-p-2">
        {fields.map((field, index) => (
          <div
            key={field.id}
            className="sui-flex sui-mb-3"
            style={{ gap: '1rem' }}
          >
            <Controller
              name={`customQuestions.${index}.question`}
              control={control}
              rules={{ required: 'Question is required' }}
              render={({ field: controllerField, fieldState }) => (
                <Input
                  className="sui-flex-1"
                  {...controllerField}
                  label="Question name"
                  type="text"
                  required
                  errors={
                    fieldState.error?.message ? [fieldState.error?.message] : []
                  }
                />
              )}
            />
            <LabelButton
              className="sui-mt-3"
              icon="delete"
              iconPosition="left"
              labelText="Remove"
              sentiment="negative"
              variantType="tertiary"
              onClick={() => remove(index)}
              data-testid={`remove-question-button-${index}`}
              disabled={
                campaign.sponsorshipApplicationsSent && field.questionSent
              }
            />
          </div>
        ))}
        <LabelButton
          icon="add"
          iconPosition="left"
          labelText="Add question"
          variantType="tertiary"
          onClick={() => append({ question: '' })}
          data-testid="add-question-button"
        />
      </section>

      <footer
        className="sui-flex sui-justify-end sui-mt-4"
        style={{ gap: '1rem' }}
      >
        {showSuccessMessage && (
          <p className="sui-flex sui-items-center sui-mb-0 sui-text-green-50">
            <Icon name="check" />
            Changes saved successfully!
          </p>
        )}
        <LabelButton
          labelText={formState.isSubmitting ? 'Saving...' : 'Save'}
          type="submit"
          disabled={formState.isSubmitting}
          data-testid="save-button"
        />
      </footer>
    </form>
  );
}

type SponsorshipApplicationTabInputs = {
  seasonName: string;
  customQuestions: {
    id?: number;
    question: string;
    questionSent?: boolean;
  }[];
};

const MUTATION = gql`
  mutation updateCampaign($input: CampaignInput!) {
    updateCampaign(input: $input) {
      __typename
      id
      seasonName
      customQuestions {
        id
        question
      }
    }
  }
`;
