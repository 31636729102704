import { append, join, pipe, toLower } from 'ramda';
import { rejectFalsy } from './array';
import { replaceSpacesWithDashes } from './string';

const joinWithDashes = (appendValue = '') =>
  pipe(
    append(appendValue),
    rejectFalsy,
    join('-'),
    toLower,
    replaceSpacesWithDashes,
  );
export const getId = joinWithDashes();
export const getInputId = joinWithDashes('input');
