import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ModalForm from './ModalForm';

const MustCreateSponsorablePropertyAlertModal = ({ history }) => {
  const redirect = () =>
    history.push('/organization-dashboard/overview/program');

  return (
    <ModalForm
      id="must-create-sponsorable-property-alert"
      onSubmit={redirect}
      submitProps={{
        children: 'Go to Programs',
      }}
      title="Must Create A Program"
      toggleProps={{
        className: 'btn btn-primary',
        children: 'Create Package',
      }}
    >
      You must create a season, program, or event first before creating a page
    </ModalForm>
  );
};

MustCreateSponsorablePropertyAlertModal.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(MustCreateSponsorablePropertyAlertModal);
