import React, { useCallback, useEffect, useState } from 'react';
import { lensPath, prop, set } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { compact } from 'utils/array';
import {
  getStateFromLocalStorageByKey,
  updateLocalStorageByKeyAndState,
} from 'utils/localStorage';
import { defaultToEmptyObject, findById } from 'utils/misc';
import { joinWithSpace } from 'utils/string';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import HubSpotChat from 'components/HubSpotChat';
import NeedHelp from 'components/NeedHelp';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import SwitchSelectedDropdown from 'components/SwitchSelectedDropdown';

const selectedSponsorProp = 'selectedSponsor';
const LOCAL_STORAGE_KEY = 'SponsorDashboard';
const updateLocalStorageStateByState =
  updateLocalStorageByKeyAndState(LOCAL_STORAGE_KEY);

const SponsorDashboardWrapper = ({ children, sponsors, title, userId }) => {
  const [state, setState] = useState(
    getStateFromLocalStorageByKey(LOCAL_STORAGE_KEY),
  );
  const stateForUser = defaultToEmptyObject(state[userId]);
  const selectedSponsorId = prop(selectedSponsorProp, stateForUser);
  const selectedSponsor = findById(selectedSponsorId)(sponsors) || sponsors[0];
  const { name: sponsorName } = selectedSponsor;

  const lens = lensPath([userId, selectedSponsorProp]);
  function toggleSponsor(sponsor) {
    setState(set(lens, sponsor.id));
  }

  useEffect(updateLocalStorageStateByState(state), [state]);

  return (
    <RestrictTo roles={['admin', 'sponsor']}>
      <Page
        additionalDropdownItems={compact([
          <SwitchSelectedDropdown
            options={sponsors}
            selected={selectedSponsor}
            toggleSelected={useCallback(toggleSponsor)}
          />,
        ])}
      >
        <FieldhousePageTitle
          title={joinWithSpace(compact([sponsorName, title]))}
        >
          <HubSpotChat />
          <h1 className="mt-3 mb-1">{sponsorName}</h1>
          {children({ selectedSponsor })}
          <NeedHelp />
        </FieldhousePageTitle>
      </Page>
    </RestrictTo>
  );
};

SponsorDashboardWrapper.propTypes = {
  children: PropTypes.func,
  sponsors: LeagueSidePropTypes.sponsors.isRequired,
  title: PropTypes.string,
  userId: PropTypes.number,
};

SponsorDashboardWrapper.defaultProps = {
  children: () => {},
  title: null,
  userId: null,
};

export default SponsorDashboardWrapper;
