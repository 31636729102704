import NumberInput from 'components/formInputs/NumberInput';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import SelectInput from 'components/formInputs/SelectInput';

const MUTATION = gql`
  mutation CreateTeamGameJerseys($input: CreateTeamGameJerseysInput!) {
    createTeamGameJerseys(input: $input) {
      organization {
        id
        name
        organizationCapabilities {
          id
          capabilityDetail {
            id
            maxForSale
            price
            __typename
            capabilityDetailable {
              ... on TeamGameJersey {
                id
                canUseLogo
                minTeamsRequired
              }
            }
          }
        }
      }
    }
  }
`;

const GameJerseyCapabilityForm = ({ organization, capabilityId, redirect }) => {
  const [mutate] = useMutation(MUTATION, {
    fetchPolicy: 'no-cache',
  });

  const [price, setPrice] = useState(null);
  const [canSponsorSingleTeam, setCanSponsorSingleTeam] = useState(null);
  const [minimumNumberOfTeamsToSponsor, setMinimumNumberOfTeamsToSponsor] =
    useState(null);
  const [bannerPlacements, setBannerPlacements] = useState([]);
  const [maxForSale, setMaxForSale] = useState(null);

  const [
    canPrintSponsorLogoInsteadOfText,
    setCanPrintSponsorLogoInsteadOfText,
  ] = useState(null);
  const [
    canPlaceSponsorsLogoOnFrontChest,
    setCanPlaceSponsorsLogoOnFrontChest,
  ] = useState(null);
  const [frontPocket, setFrontPocket] = useState(null);
  const [acrossTheBack, setAcrossTheBack] = useState(null);
  const [sleeve, setSleeve] = useState(null);
  const [
    willThereOnlyBeOneSponsorOnTheJersey,
    setWillThereOnlyBeOneSponsorOnTheJersey,
  ] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    await mutate({
      variables: {
        input: {
          organizationId: organization.id,
          capabilityId,
          maxForSale,
          price,
          minTeamsRequired: minimumNumberOfTeamsToSponsor,
          canUseLogo: canPrintSponsorLogoInsteadOfText === 'Yes',
          logoPlacements: bannerPlacements,
          otherBrands: willThereOnlyBeOneSponsorOnTheJersey === 'Yes',
        },
      },
    });

    redirect();
  };

  const handleBannerPlacement = (placement) => {
    if (bannerPlacements.includes(placement)) {
      setBannerPlacements((previousBannerPlacements) =>
        previousBannerPlacements.filter(
          (previousPlacement) => previousPlacement !== placement,
        ),
      );
    } else {
      setBannerPlacements((previousBannerPlacements) => [
        ...previousBannerPlacements,
        placement,
      ]);
    }
  };

  return (
    <form data-target="game-signage-form" onSubmit={onSubmit}>
      <SelectInput
        inputId="can-you-sponsor-a-single-team"
        label="Can a company sponsor a single team?"
        labelClassName="font-weight-bold"
        noDefault
        onChange={({ target: { value } }) => {
          setCanSponsorSingleTeam(value);
          setMinimumNumberOfTeamsToSponsor(1);
        }}
        required
        value={canSponsorSingleTeam}
      >
        <option value="">-- Please choose one --</option>
        <option>Yes</option>
        <option>No</option>
      </SelectInput>
      {canSponsorSingleTeam === 'No' ? (
        <NumberInput
          label="What is the minimum number of teams a company can sponsor?"
          labelClassName="font-weight-bold"
          min={0}
          onChange={({ target: { value } }) =>
            setMinimumNumberOfTeamsToSponsor(Number(value))
          }
          customId="minimum-numbers-of-teams-to-sponsor-input"
          required
          step={1}
          value={minimumNumberOfTeamsToSponsor}
          placeholder="Please enter the size"
        />
      ) : null}
      {minimumNumberOfTeamsToSponsor ? (
        <NumberInput
          label={`What is the estimated price to sponsor ${
            canSponsorSingleTeam !== 'No'
              ? '1 team'
              : `${minimumNumberOfTeamsToSponsor} teams`
          }?`}
          labelClassName="font-weight-bold"
          min={0}
          onChange={({ target: { value } }) => setPrice(Number(value))}
          customId="estimated-price-to-sponsor-team-input"
          required
          step={1}
          value={price}
          placeholder="Please enter the estimated price"
        />
      ) : null}
      <NumberInput
        label="What is the maximum number of teams a company can sponsor?"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) => setMaxForSale(Number(value))}
        customId="maximum-number-of-teams-to-sponsor-input"
        required
        step={1}
        value={maxForSale}
        placeholder="Please enter the maximum number"
      />
      <SelectInput
        inputId="can-you-print-logo"
        label="Can you print a sponsor's logo on the jersey instead of text?"
        labelClassName="font-weight-bold"
        noDefault
        onChange={({ target: { value } }) =>
          setCanPrintSponsorLogoInsteadOfText(value)
        }
        required
        value={canPrintSponsorLogoInsteadOfText}
      >
        <option value="">-- Please choose one --</option>
        <option>Yes</option>
        <option>No</option>
      </SelectInput>
      <SelectInput
        inputId="can-you-place-logo-on-front-chest"
        label="Can you place the sponsor's logo on the front chest of your jersey?"
        labelClassName="font-weight-bold"
        noDefault
        onChange={({ target: { value } }) => {
          const frontChestPlacement = 'Front chest';

          setCanPlaceSponsorsLogoOnFrontChest(value);
          if (
            value === 'Yes' ||
            (value !== 'Yes' && bannerPlacements.includes(frontChestPlacement))
          ) {
            handleBannerPlacement(frontChestPlacement);
          }
        }}
        required
        value={canPlaceSponsorsLogoOnFrontChest}
      >
        <option value="">-- Please choose one --</option>
        <option>Yes</option>
        <option>No</option>
      </SelectInput>
      {canPlaceSponsorsLogoOnFrontChest === 'No' ? (
        <fieldset className="mb-3">
          <strong>
            Can you place the sponsors logo on any of the following places
            instead?
          </strong>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={frontPocket}
              onChange={() => {
                setFrontPocket(!frontPocket);
                handleBannerPlacement('Front pocket');
              }}
              data-test="front-pocket-checkbox"
              id="defaultCheck1"
            />
            <label className="form-check-label" htmlFor="front-pocket-checkbox">
              Front Pocket
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={acrossTheBack}
              onChange={() => {
                setAcrossTheBack(!acrossTheBack);
                handleBannerPlacement('Across the back');
              }}
              data-test="across-the-back-checkbox"
            />
            <label
              className="form-check-label"
              htmlFor="across-the-back-checkbox"
            >
              Across the back
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={sleeve}
              onChange={() => {
                setSleeve(!sleeve);
                handleBannerPlacement('Sleeve');
              }}
              data-test="sleeve-checkbox"
            />
            <label className="form-check-label" htmlFor="sleeve-checkbox">
              Sleeve
            </label>
          </div>
        </fieldset>
      ) : null}
      <SelectInput
        inputId="will-there-only-be-one-sponsor"
        label="Will there only be one sponsor on the jersey? This does not include your organization's logo"
        labelClassName="font-weight-bold"
        noDefault
        onChange={({ target: { value } }) =>
          setWillThereOnlyBeOneSponsorOnTheJersey(value)
        }
        required
        value={willThereOnlyBeOneSponsorOnTheJersey}
      >
        <option value="">-- Please choose one --</option>
        <option>Yes</option>
        <option>No</option>
      </SelectInput>
      <button
        type="submit"
        className="btn btn-primary"
        data-test="team-of-game-jerseys-capability-form-submit-button"
      >
        Submit
      </button>
    </form>
  );
};

GameJerseyCapabilityForm.propTypes = {
  organization: LeagueSidePropTypes.organization.isRequired,
  capabilityId: PropTypes.number.isRequired,
  redirect: PropTypes.func.isRequired,
};

export default GameJerseyCapabilityForm;
