import classNames from 'classnames';
import { LabelButton } from '@teamsnap/snap-ui';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useWizardContext } from '../provider';
import { StepTypeEnum } from '../types';
import s from './styles.module.css';

export default function WizardHeader() {
  const {
    organizationData,
    activeSectionIndex,
    activeStepIndex,
    wizardMap,
    handleOnSaveAndExit,
  } = useWizardContext();
  const [headerBox, setHeaderBox] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setHeaderBox(document.getElementById('WIZARD_HEADER_BOX'));
  }, []);

  if (!headerBox || !wizardMap) return null;
  const wizardMapKeys = wizardMap && (Object.keys(wizardMap) as StepTypeEnum[]);
  const isLastStep =
    activeSectionIndex === wizardMapKeys.length - 1 &&
    activeStepIndex ===
      wizardMap[wizardMapKeys[activeSectionIndex]].steps.length - 1;

  // Note: Render via portal because the header goes outside the wizardBody container
  return createPortal(
    <div
      className={classNames(
        'sui-flex sui-items-center sui-py-3 sui-px-2 sui-max-w-w sui-mx-auto',
        s.wizardHeader,
        {
          'sui-justify-between': !isLastStep,
          'sui-justify-center': isLastStep,
        },
      )}
    >
      <p
        className="sui-mb-0 sui-text-mobile-4 d:sui-text-desktop-6 sui-font-bold"
        data-testid="wizard-header"
      >
        {organizationData?.name} sponsorship application
      </p>
      {!isLastStep && (
        <LabelButton
          labelText="Save and Exit"
          size="small"
          variantType="secondary"
          onClick={() =>
            handleOnSaveAndExit(activeSectionIndex, activeStepIndex)
          }
          data-testid="wizard-save-and-exit-button"
          className="sui-text-desktop-4"
        />
      )}
    </div>,
    headerBox,
  );
}
