import gql from 'graphql-tag';

const SPONSORSHIP_EVENT_FRAGMENT = gql`
  fragment SponsorshipEventFragment on SponsorshipEvent {
    id
    changeReason
    createdAt
    description
    eventType
    user {
      id
      email
      name
    }
  }
`;

export default SPONSORSHIP_EVENT_FRAGMENT;
