import React from 'react';
import PropTypes from 'prop-types';

function DataConfidence({ addChanges, dataConfidence, disabled }) {
  const onChange = (event) =>
    addChanges('update dataConfidence', { dataConfidence: event.target.value });

  function renderAdditionalOptions() {
    return (
      <div className="form-check">
        <input
          checked={dataConfidence === 'not_a_good_fit'}
          className="form-check-input"
          id="dataConfidenceNotAGoodFit"
          name="dataConfidence"
          onChange={onChange}
          type="radio"
          value="not_a_good_fit"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="dataConfidenceNotAGoodFit">
          <dt>Not A Good Fit</dt>
          <dd>
            This organization has been reviewed and determined to not be a good
            fit for a TeamSnap campaign.
          </dd>
        </label>
      </div>
    );
  }

  return dataConfidence ? (
    <>
      <label htmlFor="dataConfidence" className="font-weight-bold">
        Data Confidence
      </label>
      <div className="form-check">
        <input
          checked={dataConfidence === 'speculative'}
          className="form-check-input"
          id="dataConfidenceSpeculative"
          name="dataConfidence"
          onChange={onChange}
          type="radio"
          value="speculative"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="dataConfidenceSpeculative">
          <dt>Speculative</dt>
          <dd>This data could be incomplete or inaccurate.</dd>
        </label>
      </div>
      <div className="form-check">
        <input
          checked={dataConfidence === 'incomplete'}
          className="form-check-input"
          id="dataConfidenceIncomplete"
          name="dataConfidence"
          onChange={onChange}
          type="radio"
          value="incomplete"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="dataConfidenceIncomplete">
          <dt>Incomplete</dt>
          <dd>This data is in the process of being developed and completed.</dd>
        </label>
      </div>
      <div className="form-check">
        <input
          checked={dataConfidence === 'stale'}
          className="form-check-input"
          id="dataConfidenceStale"
          name="dataConfidence"
          onChange={onChange}
          type="radio"
          value="stale"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="dataConfidenceStale">
          <dt>Stale</dt>
          <dd>
            Organization was previously verified or complete, but data has not
            been updated for 6+ months.
          </dd>
        </label>
      </div>
      <div className="form-check">
        <input
          checked={dataConfidence === 'complete'}
          className="form-check-input"
          id="dataConfidenceComplete"
          name="dataConfidence"
          onChange={onChange}
          type="radio"
          value="complete"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="dataConfidenceComplete">
          <dt>Complete</dt>
          <dd>
            This data has been entered during the self-onboarding process. Needs
            verification.
          </dd>
        </label>
      </div>
      <div className="form-check">
        <input
          checked={dataConfidence === 'verified'}
          className="form-check-input"
          id="dataConfidenceVerified"
          name="dataConfidence"
          onChange={onChange}
          type="radio"
          value="verified"
          disabled={disabled}
        />
        <label className="form-check-label" htmlFor="dataConfidenceVerified">
          <dt>Verified</dt>
          <dd>
            This data has been reviewed for completeness and verified by a team
            member.
          </dd>
        </label>
      </div>
      {renderAdditionalOptions()}
    </>
  ) : null;
}

DataConfidence.propTypes = {
  addChanges: PropTypes.func.isRequired,
  dataConfidence: PropTypes.string,
  disabled: PropTypes.bool,
};

DataConfidence.defaultProps = {
  dataConfidence: undefined,
  disabled: false,
};

export default DataConfidence;
