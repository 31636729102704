import * as React from 'react';
import { descend, map, path, reject, sortWith } from 'ramda';
import { CampaignStatus } from 'types/campaign';
import { OfferStatus } from 'types/offer';
import { Season } from 'types/season';
import { isAllShownOrHidden } from 'utils/misc';
import {
  campaignSeasonOrdinal,
  hasCompleteCampaign,
  isProposed,
} from 'utils/offer';
import HoverTable from 'components/HoverTable';
import OrganizationOffersTableRow from './OrganizationOffersTableRow';

const rejectByCompleteCampaign = reject(hasCompleteCampaign);

const sortWithStatusYearSeason = sortWith([
  descend(isProposed),
  descend(path(['campaign', 'year'])),
  descend(campaignSeasonOrdinal),
]);

interface Sponsor {
  name: string;
}
interface Campaign {
  season: Season;
  sponsor: Sponsor;
  status: CampaignStatus;
  year: string;
}
interface Offer {
  id: number;
  campaign: Campaign;
  status: OfferStatus;
}
interface Props {
  offers: Offer[];
}

const OrganizationOffersTable: React.FunctionComponent<Props> = ({
  offers,
}: Props) => {
  const sortedOffers = sortWithStatusYearSeason(offers) as Offer[];
  const offersWithCurrentCampaign = rejectByCompleteCampaign(
    sortedOffers,
  ) as Offer[];
  const offersHaveSameStatus = isAllShownOrHidden(
    offersWithCurrentCampaign,
    offers,
  );

  const [showAllOffers, setShowAllOffers] = React.useState(false);

  return (
    <>
      <div
        className="d-inline-flex align-self-center my-1"
        data-test="offers-table"
      >
        <strong>Sponsorships</strong>
      </div>
      {!offersHaveSameStatus && (
        <button
          className="btn btn-sm btn-secondary ml-2"
          id="showAllToggle"
          onClick={() => setShowAllOffers(!showAllOffers)}
          type="button"
        >
          {showAllOffers ? 'Show Current' : 'Show All'}
        </button>
      )}
      <HoverTable>
        <thead>
          <tr>
            <th scope="col">Sponsor</th>
            <th scope="col">Season</th>
            <th scope="col">Status</th>
            <th scope="col">Details</th>
          </tr>
        </thead>
        <tbody>
          {map(
            (offer) => (
              <OrganizationOffersTableRow key={offer.id} offer={offer} />
            ),
            showAllOffers || offersHaveSameStatus
              ? sortedOffers
              : offersWithCurrentCampaign,
          )}
        </tbody>
      </HoverTable>
    </>
  );
};

export default OrganizationOffersTable;
