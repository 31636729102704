import React from 'react';
import gql from 'graphql-tag';
import RichTextInput from 'components/RichTextInput';
import VisibleToSponsorIndicator from 'components/VisibleToSponsorIndicator';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Dropzone from 'react-dropzone';
import PLACEHOLDER from 'images/sponsor-facing-goal-placeholder.png';
import LeagueSideAPI from 'utils/leagueside-api';
import WithSaveFooter from 'components/WithSaveFooter';
import ErrorAlert from 'components/ErrorAlert';
import { useMutation, useQuery } from 'react-apollo';
import CampaignStatisticCards from './campaignDashboardTab/CampaignStatisticCards';
import CampaignStatisticCreateModal from './campaignDashboardTab/CampaignStatisticCreateModal';
import TestimonialCards from './campaignDashboardTab/TestimonialCards';
import TestimonialCreateModal from './campaignDashboardTab/TestimonialCreateModal';

const MUTATION = gql`
  mutation UpdateCampaign($input: CampaignInput!) {
    updateCampaign(input: $input) {
      id
      sponsorDashboardAdditionalContent
      sponsorFacingGoalTitle
      sponsorFacingGoalBody
      impressionsOverride
      lookerUrl
    }
  }
`;

const REFETCH_QUERY = gql`
  query Campaign($id: Int!) {
    campaign(id: $id) {
      id
      sponsorFacingGoalImageUrl
    }
  }
`;

const CampaignDashboardTab = ({ campaign }) => {
  const { id } = campaign;

  const { refetch } = useQuery(REFETCH_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  const [mutate] = useMutation(MUTATION);

  const onSubmit = (input) =>
    mutate({
      variables: { input: { ...input, id } },
    });

  return (
    <WithSaveFooter handleMutation={onSubmit} savedValue={campaign}>
      {({ addChanges, value: campaignUpdates, error }) => (
        <form
          className="list-group list-group-flush"
          id="update-campaign-dashboard"
        >
          <div className="list-group-item">
            <strong>Campaign Goal</strong>
            <div className="row">
              <div className="col-md-5">
                <VisibleToSponsorIndicator>
                  <Dropzone
                    activeClassName="border-primary"
                    className="sponsor-facing-goal-dropzone text-center"
                    onDrop={async ([file]) => {
                      // setLoading(true);
                      await LeagueSideAPI.createSponsorFacingGoalImage(
                        campaign.id,
                        file,
                      );
                      refetch({ id: campaign.id }); // eslint-disable-line react/destructuring-assignment
                      // setLoading(false);
                    }}
                  >
                    <img
                      className="mw-100"
                      src={campaign.sponsorFacingGoalImageUrl || PLACEHOLDER}
                      alt="sponsor-facing goal"
                    />
                  </Dropzone>
                </VisibleToSponsorIndicator>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <VisibleToSponsorIndicator>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="sponsor-facing-goal-title"
                      required
                      onChange={({ target: { value } }) =>
                        addChanges({ sponsorFacingGoalTitle: value })
                      }
                      placeholder="Title"
                      value={campaignUpdates.sponsorFacingGoalTitle || ''}
                    />
                  </VisibleToSponsorIndicator>
                </div>
                <div className="form-group">
                  <VisibleToSponsorIndicator>
                    <RichTextInput
                      id="sponsor-facing-goal-body"
                      onChange={(sponsorFacingGoalBody) =>
                        addChanges({ sponsorFacingGoalBody })
                      }
                      initialValue={campaignUpdates.sponsorFacingGoalBody || ''}
                    />
                  </VisibleToSponsorIndicator>
                  <small
                    id="sponsor-facing-goal-body-help-block"
                    className="form-text text-muted"
                  >
                    {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                    {
                      "The campaign goal field is used for ensuring that the sponsor and TeamSnap have a common understanding of what they're trying to accomplish with their campaign. Use this space to outline their key metrics, the actions we are taking towards them, and our desired outcomes so that throughout the campaign they can view their results in relation to those goals."
                    }
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group">
              <label
                htmlFor="impressions-override"
                className="font-weight-bold mb-2"
              >
                Impressions
              </label>
              <VisibleToSponsorIndicator>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  id="impressions-override"
                  required
                  onChange={({ target: { value } }) =>
                    addChanges({ impressionsOverride: Number(value) })
                  }
                  value={campaignUpdates.impressionsOverride || ''}
                />
              </VisibleToSponsorIndicator>
            </div>
          </div>
          <div className="list-group-item">
            <div className="form-group">
              <label
                htmlFor="looker-url-report"
                className="font-weight-bold mb-2"
              >
                Looker Report Url
              </label>
              <input
                type="url"
                className="form-control form-control-lg"
                id="looker-report-url"
                required
                onChange={({ target: { value } }) =>
                  addChanges({ lookerUrl: value })
                }
                placeholder="https://lookerstudio.google.com/embed/reporting/..."
                value={campaignUpdates.lookerUrl || ''}
              />
              {error &&
                error.message.includes('get_looker_report_ids_from_url ') && (
                  <ErrorAlert
                    data-test="invalid-looker-url-error-message"
                    className="mt-2"
                  >
                    The url entered is invalid, please use the embed url from
                    Looker.
                  </ErrorAlert>
                )}
            </div>
          </div>
          <div className="list-group-item">
            <div className="row">
              <strong className="col">Campaign Statistics</strong>
              <CampaignStatisticCreateModal campaignId={campaign.id} />
            </div>
            {!!(
              campaign.campaignStatistics && campaign.campaignStatistics.length
            ) && (
              <CampaignStatisticCards
                campaignStatistics={campaign.campaignStatistics}
              />
            )}
          </div>
          <div className="list-group-item">
            <div className="row">
              <strong className="col">Testimonials</strong>
              <TestimonialCreateModal campaignId={campaign.id} />
            </div>
            {campaign.testimonials && campaign.testimonials.length ? (
              <TestimonialCards testimonials={campaign.testimonials} />
            ) : null}
          </div>
          <div className="list-group-item">
            <div className="form-group">
              <label
                htmlFor="additional-content"
                className="font-weight-bold mb-2"
              >
                Additional Content
              </label>
              <VisibleToSponsorIndicator>
                <RichTextInput
                  id="additional-content"
                  onChange={(sponsorDashboardAdditionalContent) =>
                    addChanges({ sponsorDashboardAdditionalContent })
                  }
                  initialValue={
                    campaign.sponsorDashboardAdditionalContent || ''
                  } // eslint-disable-line react/destructuring-assignment
                />
              </VisibleToSponsorIndicator>
            </div>
          </div>
        </form>
      )}
    </WithSaveFooter>
  );
};

CampaignDashboardTab.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignDashboardTab;
