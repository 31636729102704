import { map } from 'ramda';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import CampaignStatisticCard from './CampaignStatisticCard';

function CampaignStatisticCards({ campaignStatistics }) {
  const card = (campaignStatistic) => (
    <div key={campaignStatistic.id} className="col-md-4 mt-2">
      <CampaignStatisticCard
        campaignStatistic={campaignStatistic}
        key={campaignStatistic.id}
      />
    </div>
  );

  return <div className="row">{map(card, campaignStatistics)}</div>;
}

CampaignStatisticCards.propTypes = {
  campaignStatistics: PropTypes.arrayOf(LeagueSidePropTypes.campaignStatistic)
    .isRequired,
};

export default CampaignStatisticCards;
