import * as React from 'react';

interface VerificationDeadlineResponse {
  id: number;
  cannedResponse: string;
  comment: string;
}

interface Props {
  verificationDeadlineResponse: VerificationDeadlineResponse;
}

const UnaddressedVerificationDeadlineResponse: React.FunctionComponent<
  Props
> = ({ verificationDeadlineResponse }: Props) => {
  const {
    id: verificationDeadlineResponseId,
    cannedResponse,
    comment,
  } = verificationDeadlineResponse;
  return (
    <div
      className="row alert alert-warning px-1 align-items-center"
      key={`verification-deadline-response-${verificationDeadlineResponseId}`}
      data-test={`response-${verificationDeadlineResponseId}`}
    >
      <div className="col-auto">
        <small>{cannedResponse}</small>
      </div>
      <div className="col">{comment}</div>
    </div>
  );
};

export default UnaddressedVerificationDeadlineResponse;
