import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { isEmpty, isNil, map } from 'ramda';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import classNames from 'classnames';
import Loader from 'components/Loader';
import HoverTable from 'components/HoverTable';
import Card from 'components/Card';
import { useSeasonalDashboardContext } from '../../context/SeasonalDashboardContext';

const query = gql`
  query SeasonalDashboard($season: String!, $year: Int!) {
    seasonalDashboard {
      sponsor {
        id
        campaigns(season: $season, year: $year) {
          id
          name
          impressions
        }
      }
    }
  }
`;

const TitleRow = styled.div`
  background-color: #fafafa;
  padding: 1rem;
`;

export function ImpressionsTabUnwrapped({ campaigns }) {
  const filteredImpressions = campaigns.filter(
    (campaign) => !isNil(campaign.impressions) && campaign.impressions > 0,
  );
  const totalImpressions = campaigns
    .map((campaign) => Number(campaign.impressions))
    .reduce((p, n) => p + n);
  const totalImpressionsClassNames = classNames('mx-auto', {
    'align-self-center': campaigns.length < 11,
    'align-self-start mt-4': campaigns.length >= 11,
  });
  const row = (campaign) => (
    <tr key={campaign.id} data-test={`impressions-${campaign.id}`}>
      <td>{campaign.name}</td>
      <td>{Number(campaign.impressions).toLocaleString('en-US')}</td>
    </tr>
  );

  return (
    <>
      <div className="col-md-12 pl-0">
        <Card bodyPadding={0} verticalMargins={0} id="mb-40-important">
          <TitleRow classname="row">
            <div className="col">
              <h4>Measuring Effectiveness</h4>
            </div>
          </TitleRow>
          <div className="row d-flex no-gutters">
            <div className="col-3 align-self-stretch border-right d-flex">
              <div
                className={totalImpressionsClassNames}
                data-test="total-impressions"
              >
                <h2 className="text-center">
                  {totalImpressions.toLocaleString('en-US')}
                </h2>
                <h6>Impressions</h6>
              </div>
            </div>
            <div className="col-9">
              <HoverTable>
                <thead>
                  <tr>
                    <th scope="col" className="col-2 border-top-0">
                      Campaign
                    </th>
                    <th scope="col" className="col-1 border-top-0">
                      Impressions
                    </th>
                  </tr>
                </thead>
                <tbody>{map(row, filteredImpressions)}</tbody>
              </HoverTable>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
}

ImpressionsTabUnwrapped.propTypes = {
  campaigns: PropTypes.arrayOf(LeagueSidePropTypes.campaign),
};

ImpressionsTabUnwrapped.defaultProps = {
  campaigns: [],
};

const ImpressionsTab = () => {
  const { token, season, year } = useSeasonalDashboardContext();
  const { data, loading } = useQuery(query, {
    variables: { season, year },
    context: {
      headers: {
        Authorization: token,
      },
    },
  });

  if (loading) return <Loader />;

  const campaigns = data && data.seasonalDashboard.sponsor.campaigns;
  const campaignImpressionsExist = !isEmpty(
    campaigns.filter(
      (campaign) => !isNil(campaign.impressions) && campaign.impressions > 0,
    ),
  );

  return campaignImpressionsExist ? (
    <>
      <h1 className="mb-4">Estimated Seasonal Impressions</h1>
      <ImpressionsTabUnwrapped campaigns={campaigns} />
    </>
  ) : (
    <h1
      className="d-flex justify-content-center"
      data-test="no-results-message"
    >
      {' '}
      Sorry! Impressions have yet to be collected!{' '}
    </h1>
  );
};

export default ImpressionsTab;
