import React from 'react';
import PropTypes from 'prop-types';

const ModalSpinner = ({ show }) => {
  const hiddenClassName = show ? '' : 'modal-spinner-container-hidden';
  const messages = [
    'Calling a time out',
    'Throwing a flag on the play',
    'Reviewing the footage',
    'Engraving your trophy',
    'Rounding the bases',
    'Running down the clock',
  ];
  const message = messages[Math.floor(Math.random() * messages.length)];

  return (
    <div className={`modal-spinner-container ${hiddenClassName}`}>
      <div>
        <span
          className="JelloAnimation"
          style={{ width: '48px', height: '48px' }}
        >
          <span
            className="JelloAnimation-shadow"
            style={{ width: '40px', height: '5px', bottom: '-5px' }}
          />
          <span
            className="JelloAnimation-box"
            style={{ height: '40px', width: '40px' }}
          />
        </span>
      </div>
      <div className="mt-4 mb-4 heading">{message}</div>
    </div>
  );
};

ModalSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default ModalSpinner;
