import { StepIdsEnum, StepTypeEnum, WizardMapType } from './types';
// Interest
import SponsorActivitiesStep from './_sections/interest/sponsor-activities-step';
// Season Information
import PrimaryContactStep from './_sections/season-information/primary-contact-step';
import PlayLocationsStep from './_sections/season-information/play-locations-step';
import SocialAndEmailStep from './_sections/season-information/social-and-email-step';
import NonProfitStatusStep from './_sections/season-information/non-profit-status-step';
import ProgramsStep from './_sections/season-information/programs';
import AdditionalInformationStep from './_sections/season-information/additional-information';
// Tournament Information
import TournamentInformationStep from './_sections/tournament-information/tournament-information-step';
// Assets
import JerseysStep from './_sections/assets/jerseys-step';
import BannersStep from './_sections/assets/banners-step';
import SurveysStep from './_sections/assets/surveys-step';
import WarmUpsStep from './_sections/assets/warm-ups-step';
import WebBannersStep from './_sections/assets/web-banners-step';
import EmailAndSocialMediaStep from './_sections/assets/email-and-social-media-step';
import SeminarsStep from './_sections/assets/seminars-step';
import GiveawaysAndCouponsStep from './_sections/assets/giveaways-and-coupons-step';
import ProductSamplingStep from './_sections/assets/product-sampling-step';
import PhotographyStep from './_sections/assets/photography-step';
// Wrap Up
import ThankYouStep from './_sections/wrap-up/thank-you-step';
import WrapUpStep from './_sections/wrap-up/wrap-up-step';
import IntroductionStep from './_sections/interest/introduction';
import CommunityImpactStep from './_sections/interest/community-impact';

// eslint-disable-next-line import/prefer-default-export
export const WIZARD_SECTIONS_MAP: WizardMapType = {
  [StepTypeEnum.INTEREST]: {
    title: 'Sponsorship package',
    steps: [
      {
        id: StepIdsEnum.INTRODUCTION,
        name: 'Introduction',
        component: <IntroductionStep />,
      },
      {
        id: StepIdsEnum.INTERESTS,
        name: 'Sponsor benefits',
        component: <SponsorActivitiesStep />,
      },
      {
        id: StepIdsEnum.COMMUNITY_IMPACT,
        name: 'Community impact',
        component: <CommunityImpactStep />,
      },
    ],
  },
  [StepTypeEnum.SEASON_INFORMATION]: {
    title: 'Season Information',
    steps: [
      {
        id: StepIdsEnum.PRIMARY_CONTACT,
        name: 'Primary Contact',
        component: <PrimaryContactStep />,
      },
      {
        id: StepIdsEnum.PROGRAMS,
        name: 'Programs',
        component: <ProgramsStep />,
      },
      {
        id: StepIdsEnum.PLAY_LOCATIONS,
        name: 'Play Locations',
        component: <PlayLocationsStep />,
      },
      {
        id: StepIdsEnum.SOCIAL_AND_EMAIL,
        name: 'Social & Email',
        component: <SocialAndEmailStep />,
      },
      {
        id: StepIdsEnum.NON_PROFIT_STATUS,
        name: 'Non-Profit Status',
        component: <NonProfitStatusStep />,
      },
      {
        id: StepIdsEnum.CUSTOM_QUESTIONS,
        name: 'Additional information',
        component: <AdditionalInformationStep />,
      },
    ],
  },
  [StepTypeEnum.TOURNAMENT_INFORMATION]: {
    title: 'Tournament Information',
    steps: [
      {
        id: StepIdsEnum.TOURNAMENT_INFORMATION,
        name: 'Information',
        component: <TournamentInformationStep />,
      },
    ],
  },
  [StepTypeEnum.BRANDED_COLLATERAL]: {
    title: 'Branded Collateral',
    steps: [
      {
        id: StepIdsEnum.GAME_JERSEYS,
        name: 'Logo on jerseys',
        component: <JerseysStep />,
      },
      {
        id: StepIdsEnum.WARM_UPS,
        name: 'Warm-up apparel',
        component: <WarmUpsStep />,
      },
      {
        id: StepIdsEnum.GAME_SIGNAGE,
        name: 'Banners',
        component: <BannersStep />,
      },
      {
        id: StepIdsEnum.COUPONS,
        name: 'Product & coupon distribution',
        component: <GiveawaysAndCouponsStep />,
      },
    ],
  },
  [StepTypeEnum.EXPERIENCES]: {
    title: 'Experiences',
    steps: [
      {
        id: StepIdsEnum.SEMINAR,
        name: 'Seminars',
        component: <SeminarsStep />,
      },
      {
        id: StepIdsEnum.SAMPLING,
        name: 'Table and product',
        component: <ProductSamplingStep />,
      },
      {
        id: StepIdsEnum.PHOTOS,
        name: 'Professional photography',
        component: <PhotographyStep />,
      },
    ],
  },
  [StepTypeEnum.DIGITAL_ASSETS]: {
    title: 'Digital Assets',
    steps: [
      {
        id: StepIdsEnum.WEBSITE_BANNER,
        name: 'Logo on website',
        component: <WebBannersStep />,
      },
      {
        id: StepIdsEnum.SURVEYS,
        name: 'Surveys',
        component: <SurveysStep />,
      },
      {
        id: StepIdsEnum.DEDICATED_EMAIL,
        name: 'Email and social media',
        component: <EmailAndSocialMediaStep />,
      },
    ],
  },
  [StepTypeEnum.WRAP_UP]: {
    title: 'Rewarded add-ons',
    steps: [
      {
        id: StepIdsEnum.REWARDED_ADD_ONS,
        name: 'HundredX',
        component: <WrapUpStep />,
      },
      {
        id: StepIdsEnum.THANK_YOU,
        name: 'Thanks',
        component: <ThankYouStep />,
      },
    ],
  },
};
