import React from 'react';
import PropTypes from 'prop-types';
import ContactUsLink from 'components/ContactUsLink';

const AcceptConfirmationContent = ({ email, sponsorName }) => (
  <div className="text-left" id="accept-confirmation-body">
    <h4>NEXT STEP TO ACCEPT OFFER: Check your email!</h4>
    <p>
      To finalize accepting this offer, you must finish creating your account.
      You should receive an email at&nbsp;
      <strong>{email}</strong>
      &nbsp;with instructions on how to do this.
    </p>
    <p>
      {`Once you create your account, the offer from ${sponsorName} will automatically be accepted and you will receive a confirmation email with further next steps for this sponsorship.`}
    </p>
    <p>
      If you do not receive an email from us shortly, please&nbsp;
      <ContactUsLink />.
    </p>
  </div>
);

AcceptConfirmationContent.propTypes = {
  email: PropTypes.string.isRequired,
  sponsorName: PropTypes.string.isRequired,
};

export default AcceptConfirmationContent;
