import { filter, keys, pipe } from 'ramda';
import { getStateFromLocalStorageByKey } from './localStorage';

export const DEFAULT_STATE = {
  availableVisible: false,
  confirmedVisible: false,
  proposedVisible: false,
  prospectsVisible: true,
  qualifiedVisible: false,
  removedVisible: false,
};

export const LOCAL_STORAGE_KEY = 'LeaguesTab';

const STATUS_TO_VISIBILITY = {
  confirmed: 'confirmedVisible',
  proposed: 'proposedVisible',
  rejected_by_league: 'proposedVisible',
  prospecting: 'prospectsVisible',
  qualified: 'qualifiedVisible',
  rejected: 'removedVisible',
  unqualified: 'removedVisible',
  canceled: 'removedVisible',
};

export function getStateFromLocalStorage() {
  return getStateFromLocalStorageByKey(LOCAL_STORAGE_KEY);
}

export function inferAndReturnStatuses(visibilities) {
  if (!visibilities) return [];

  const filterByVisibility = filter((status) => !!visibilities[status]);
  const createStatusList = pipe(filterByVisibility, keys);

  return createStatusList(STATUS_TO_VISIBILITY);
}

export const statusProp = (status) => `${status}Visible`;
