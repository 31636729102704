import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import DELIVERABLE_ADDRESS_FRAGMENT from 'fragments/DeliverableAddressFragment';
import FormattedAddress from './shippingInfo/FormattedAddress';
import DeliverableAddressModal from './shippingInfo/DeliverableAddressModal';

const UPSERT_MAILING_ADDRESS = gql`
  mutation UpsertMailingAddress($input: UpsertAddressInput!) {
    upsertAddress(input: $input) {
      id
      mailingAddress {
        ...DeliverableAddressFragment
      }
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

const UPSERT_SHIPPING_ADDRESS = gql`
  mutation UpsertShippingAddress($input: UpsertAddressInput!) {
    upsertAddress(input: $input) {
      id
      shippingAddress {
        ...DeliverableAddressFragment
      }
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      mailingAddress {
        ...DeliverableAddressFragment
      }
      name
      shippingAddress {
        ...DeliverableAddressFragment
      }
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

const ShippingInfo = ({ organizationId }) => {
  const [upsertMailingAddress] = useMutation(UPSERT_MAILING_ADDRESS);
  const [upsertShippingAddress] = useMutation(UPSERT_SHIPPING_ADDRESS);
  const { data, loading } = useQuery(ORGANIZATION_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: organizationId },
  });

  const organization = data && data.organization;

  return !loading && organization ? (
    <div className="list-group list-group-flush">
      <div className="list-group-item" data-test="mailing-address">
        <div className="d-block">
          <strong>Mailing Address</strong>
          <DeliverableAddressModal
            addressType="mailing"
            organization={organization}
            upsertAddress={upsertMailingAddress}
          />
        </div>
        {organization.mailingAddress ? (
          <FormattedAddress deliverableAddress={organization.mailingAddress} />
        ) : (
          'Mailing Address Not Set'
        )}
      </div>
      <div className="list-group-item" data-test="shipping-address">
        <div className="d-block">
          <strong>Shipping Address</strong>
          <DeliverableAddressModal
            addressType="shipping"
            organization={organization}
            upsertAddress={upsertShippingAddress}
          />
        </div>
        {organization.shippingAddress ? (
          <FormattedAddress deliverableAddress={organization.shippingAddress} />
        ) : (
          'Shipping Address Not Set'
        )}
      </div>
    </div>
  ) : null;
};

ShippingInfo.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default ShippingInfo;
