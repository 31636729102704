/* eslint react/forbid-prop-types: 0 */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ORGANIZATION_DASHBOARD_OVERVIEW_PATH,
  ORGANIZATION_DASHBOARD_PATH,
  ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH,
  ORGANIZATION_PACKAGES_PATH,
} from 'utils/paths';
import ReviewOfferFromDashboard from 'pages/ReviewOfferFromDashboard';
import OrganizationDashboardMain from 'pages/OrganizationDashboardMain';
import OrganizationDashboardOverview from 'pages/OrganizationDashboardOverview';
import ConfirmedOfferOverview from 'pages/ConfirmedOfferOverview';
import PackageBuilder from 'pages/PackageBuilder';
import PropTypes from 'prop-types';
import OrganizationPackageBuilderSections from 'pages/OrganizationPackageBuilderSections';
import CapabilitiesFormController from 'pages/AddCapability';

function OrganizationDashboardRouter(presenterFactory) {
  return (
    <Switch>
      <Route
        path={`${ORGANIZATION_DASHBOARD_PATH}/offer/:offerId`}
        component={ReviewOfferFromDashboard}
      />
      <Route
        path={`${ORGANIZATION_DASHBOARD_PATH}/sponsorship/:offerId`}
        render={(props) => (
          <ConfirmedOfferOverview
            {...props}
            presenterFactory={presenterFactory}
          />
        )}
      />
      <Route
        path={`${ORGANIZATION_PACKAGES_PATH}/:organizationPackageId`}
        component={PackageBuilder}
      />
      <Route
        path={ORGANIZATION_DASHBOARD_OVERVIEW_PATH}
        component={OrganizationDashboardOverview}
      />
      <Route
        path={ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH}
        component={OrganizationPackageBuilderSections}
      />
      <Route
        path={`${ORGANIZATION_DASHBOARD_PATH}/capability-form/:organizationId`}
        component={CapabilitiesFormController}
      />
      <Route
        path={ORGANIZATION_DASHBOARD_PATH}
        component={OrganizationDashboardMain}
      />
    </Switch>
  );
}

OrganizationDashboardRouter.propTypes = {
  presenterFactory: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OrganizationDashboardRouter;
