import { prop, propOr } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import Icon from 'components/Icon';

const fontSize = propOr(({ size }) => size - 5, 'fontSize');
const sizeProp = prop('size');

const Circle = styled.div`
  border-radius: 50%;
  color: white;
  display: inline-block;
  font-size: ${fontSize}px;
  height: ${sizeProp}px;
  line-height: ${sizeProp}px;
  opacity: ${prop('opacity')};
  text-align: center;
  width: ${sizeProp}px;
`;

const CircledIcon = ({
  backgroundColor,
  className,
  opacity,
  size,
  value,
  ...rest
}) => (
  <Circle
    className={classNames(
      { [`bg-${backgroundColor}`]: !!backgroundColor },
      className,
    )}
    opacity={opacity}
    size={size}
    {...rest}
  >
    <Icon value={value} />
  </Circle>
);

CircledIcon.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  opacity: PropTypes.number,
  size: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

CircledIcon.defaultProps = {
  backgroundColor: undefined,
  className: undefined,
  opacity: 1,
};

export default CircledIcon;
