import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import ForgotPasswordModal from 'components/ForgotPasswordModal';
import SingleCardPage from 'components/SingleCardPage';
import { getAuthentication } from 'modules';
import {
  authenticate,
  getMessage,
  getIsLoggingIn,
} from 'modules/authentication';
import { parseQueryString } from 'utils/string';
import LoginForm from './login/LoginForm';

const Login = ({ message, onAuthenticate, isLoggingIn, location }) => {
  const { created_account: createdAccount } = parseQueryString(location.search);

  return (
    <div className="deprecated-styles">
      <FieldhousePageTitle title="Login">
        <SingleCardPage>
          <LoginForm
            message={message}
            onAuthenticate={onAuthenticate}
            isLoggingIn={isLoggingIn}
            createdAccount={createdAccount}
          />
          <div className="d-flex justify-content-between">
            <ForgotPasswordModal />
            <a
              className="btn btn-link p-0 border-0"
              href="https://www.teamsnap.com/leagues-and-clubs/join-leagueside-sports-organization-sponsorships"
            >
              Sign up for an account
            </a>
          </div>
        </SingleCardPage>
      </FieldhousePageTitle>
    </div>
  );
};

Login.propTypes = {
  message: PropTypes.string,
  isLoggingIn: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,

  onAuthenticate: PropTypes.func.isRequired,
};

Login.defaultProps = {
  message: null,
  isLoggingIn: false,
};

const mapStateToProps = (state) => ({
  message: getMessage(getAuthentication(state)),
  isLoggingIn: getIsLoggingIn(getAuthentication(state)),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onAuthenticate: authenticate,
    },
    dispatch,
  );

const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);
export { Login, LoginContainer };
