/* eslint-disable no-await-in-loop */
import React, { useCallback, useEffect, useState } from 'react';
import { useSeasonalDashboardContext } from 'context/SeasonalDashboardContext';
import gql from 'graphql-tag';

import { useApolloClient } from 'react-apollo';
import CampaignDashboardMapWithList from 'pages/campaignDashboard/CampaignDashboardMapWithList';
import Loader from 'components/Loader';

const QUERY = gql`
  query SeasonalCampaignVerifications(
    $season: String!
    $year: Int!
    $cursor: String
  ) {
    seasonalDashboard {
      sponsor {
        id
        campaignsConnection(
          season: $season
          year: $year
          first: 10
          after: $cursor
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              campaignGeometries {
                id
                geoJson
                label
              }
              offers(statuses: [confirmed]) {
                id
                assets {
                  id
                  campaignCapability {
                    id
                    capability {
                      id
                      name
                    }
                  }
                  quantity
                }
                status
                sponsorableProperty {
                  id
                  minAge
                  maxAge
                  name
                  numberOfPlayers
                  playLocation {
                    id
                    address
                    lat
                    lon
                  }
                  sport {
                    id
                    name
                  }
                }
                verificationImages(status: verified) {
                  id
                  capabilityName
                  fullSizeUrl
                  thumbnailUrl
                  featured
                }
              }
              radii {
                label
                lat
                lon
                miles
              }
            }
          }
        }
      }
    }
  }
`;

const buildAllCampaigns = (campaigns) => {
  const allCampaigns = {
    offers: [],
    radii: [],
    campaignGeometries: [],
  };

  for (let i = 0; i < campaigns.length; i += 1) {
    if (campaigns[i].node.offers.length) {
      allCampaigns.offers.push(campaigns[i].node.offers);
      allCampaigns.radii.push(campaigns[i].node.radii);
      allCampaigns.campaignGeometries.push(
        campaigns[i].node.campaignGeometries,
      );
    }
  }

  allCampaigns.offers = allCampaigns.offers.flat();
  allCampaigns.radii = allCampaigns.radii.flat();
  allCampaigns.campaignGeometries = allCampaigns.campaignGeometries.flat();
  return allCampaigns;
};
const VerificationsMap = () => {
  const { token, season, year } = useSeasonalDashboardContext();

  const client = useApolloClient();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const fetchAll = useCallback(async () => {
    let hasNextPage = true;
    let cursor = null;
    let allResults = null;

    while (hasNextPage) {
      const { data: fetchedData } = await client.query({
        query: QUERY,
        variables: { season, year, cursor },
        context: { headers: { Authorization: `Bearer ${token}` } },
      });
      const fetchedDataCampaignsConnection =
        fetchedData.seasonalDashboard.sponsor.campaignsConnection;

      if (!allResults) {
        allResults = fetchedData;
      } else {
        const { campaignsConnection } = allResults.seasonalDashboard.sponsor;

        campaignsConnection.edges = campaignsConnection.edges.concat(
          fetchedDataCampaignsConnection.edges,
        );
        campaignsConnection.pageInfo = fetchedDataCampaignsConnection.pageInfo;
      }
      hasNextPage = fetchedDataCampaignsConnection.pageInfo.hasNextPage;
      cursor = fetchedDataCampaignsConnection.pageInfo.endCursor;
    }
    setLoading(false);
    setData(allResults);
  }, [client, season, token, year]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  if (loading) return <Loader />;

  const campaigns = data && data.seasonalDashboard.sponsor.campaignsConnection;

  return (!loading, campaigns) ? (
    <>
      <h1>ALL ORGANIZATIONS BY REGION</h1>
      <CampaignDashboardMapWithList
        campaign={buildAllCampaigns(campaigns.edges)}
      />
    </>
  ) : null;
};

export default VerificationsMap;
