import * as React from 'react';
import { isEmpty } from 'ramda';
import formatDate from 'utils/formatDate';
import { pluralizedTense } from 'utils/string';
import type { VerificationDeadline } from 'types/offer';
import DeadlineActionsDropdown from 'components/DeadlineActionsDropdown';
import ExpandRowButton from 'components/ExpandRowButton';
import MissingCreativesIndicator from 'components/MissingCreativesIndicator';
import DeleteDefaultVerificationDeadlineButton from './DeleteDefaultVerificationDeadlineButton';
import ExpandedCreativeTemplatesRow from './ExpandedCreativeTemplatesRow';
import ReplaceCreativesModal from './ReplaceCreativesModal';
import UpdateDefaultVerificationDeadlineModal from './UpdateDefaultVerificationDeadlineModal';

interface League {
  name: string;
}
interface Offer {
  id: number;
  league: League;
  verificationDeadlines: VerificationDeadline[];
}
interface CapabilityVerificationDeadline {
  id: number;
  name: string;
}

interface Props {
  capabilityName: string;
  capabilityVerificationDeadlines: CapabilityVerificationDeadline[];
  offersWithMatchingDeadline: Offer[];
  defaultVerificationDeadline: VerificationDeadline;
  tableColCount: number;
}

const DefaultVerificationDeadlineRow: React.FunctionComponent<Props> = ({
  capabilityName,
  capabilityVerificationDeadlines,
  offersWithMatchingDeadline,
  defaultVerificationDeadline,
  tableColCount,
}: Props) => {
  const [creativeTemplatesExpanded, setCreativeTemplatesExpanded] =
    React.useState(false);

  const {
    id: defaultVerificationDeadlineId,
    creativeContentTemplates,
    creativeFileTemplates,
    deadline,
    name,
    missingCreatives,
  } = defaultVerificationDeadline;

  const expandedRowColClassName = 'col-md-6 col-xl-4 mb-4';

  const numberOfCreativeTemplates =
    creativeContentTemplates.length + creativeFileTemplates.length;

  return (
    <>
      <tr
        data-test={`default-verification-deadline-row-${defaultVerificationDeadlineId}`}
      >
        <td className="text-nowrap">{formatDate(deadline)}</td>
        <td>{name}</td>
        <td>
          {missingCreatives ? (
            <MissingCreativesIndicator />
          ) : (
            !!numberOfCreativeTemplates && (
              <ExpandRowButton
                expanded={creativeTemplatesExpanded}
                idLabel={`creative-templates-${defaultVerificationDeadlineId}`}
                label={`${numberOfCreativeTemplates} ${pluralizedTense(
                  numberOfCreativeTemplates,
                  'creative',
                )}`}
                onClick={() =>
                  setCreativeTemplatesExpanded(!creativeTemplatesExpanded)
                }
              />
            )
          )}
        </td>
        <td>
          <DeadlineActionsDropdown deadlineId={defaultVerificationDeadlineId}>
            <UpdateDefaultVerificationDeadlineModal
              capabilityName={capabilityName}
              capabilityVerificationDeadlines={capabilityVerificationDeadlines}
              defaultVerificationDeadline={defaultVerificationDeadline}
            />
            <ReplaceCreativesModal
              capabilityName={capabilityName}
              defaultVerificationDeadline={defaultVerificationDeadline}
              offersWithMatchingDeadline={offersWithMatchingDeadline}
            />
            <DeleteDefaultVerificationDeadlineButton
              defaultVerificationDeadlineId={defaultVerificationDeadlineId}
            />
          </DeadlineActionsDropdown>
        </td>
      </tr>
      {!(isEmpty(creativeFileTemplates) && isEmpty(creativeContentTemplates)) &&
        creativeTemplatesExpanded && (
          <ExpandedCreativeTemplatesRow
            colClassName={expandedRowColClassName}
            creativeContentTemplates={creativeContentTemplates}
            creativeFileTemplates={creativeFileTemplates}
            tableColCount={tableColCount}
            defaultVerificationDeadlineId={defaultVerificationDeadlineId}
          />
        )}
    </>
  );
};

export default DefaultVerificationDeadlineRow;
