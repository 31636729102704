import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { nameIsTooLong, MAX_NAME_LENGTH } from 'utils/deliverableAddress';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import { pluralizedTense, loadingTense } from 'utils/string';
import { useSubmitWithGQL } from 'utils/hooks';
import Icon from 'components/Icon';
import CheckModalTable from 'components/CheckModalTable';
import ModalForm from 'components/ModalForm';
import RestrictTo from 'components/RestrictTo';
import NewWindowLink from 'components/NewWindowLink';
import ErrorAlert from 'components/ErrorAlert';

const shouldDisableSubmit = (mailingAddress) =>
  !mailingAddress || nameIsTooLong(mailingAddress);

const shippingInfoTabLink = (organizationId) => (
  <NewWindowLink
    hideIcon
    to={`${ORGANIZATIONS_PATH}/${organizationId}/shippinginfo`}
    title="Shipping Info Tab"
  >
    Shipping Info Tab
  </NewWindowLink>
);
const noMailingAddressMessage = (organization) => (
  <ErrorAlert>
    A mailing address is not set for&nbsp;
    {organization.name}
    &nbsp;so this check cannot be sent yet. Visit the&nbsp;
    {shippingInfoTabLink(organization.id)}
    &nbsp;to set a mailing address.
  </ErrorAlert>
);
const nameIsTooLongMessage = (organization, mailingAddress) => {
  const { name } = mailingAddress;
  const difference = name.length - MAX_NAME_LENGTH;

  return (
    <ErrorAlert>
      {`"${name}" is ${difference} ${pluralizedTense(
        difference,
        'character',
      )} too long, so this check cannot be sent. Visit the `}
      {shippingInfoTabLink(organization.id)}
      &nbsp;to change the check recipient name on the mailing address.
    </ErrorAlert>
  );
};

export const ApproveCheckModalUnwrapped = ({ check, sendCheck }) => {
  const { offer } = check;
  const { sponsorableProperty } = offer;
  const { organization } = sponsorableProperty;
  const { mailingAddress } = organization;

  const { error, loading, handleSubmit } = useSubmitWithGQL(
    sendCheck,
    `approve-check-modal-${check.id}`,
  );

  const renderBody = (
    <>
      {mailingAddress && !nameIsTooLong(mailingAddress) && (
        <p>Are you sure you want to send the following check?</p>
      )}
      {!mailingAddress && noMailingAddressMessage(organization)}
      {mailingAddress &&
        nameIsTooLong(mailingAddress) &&
        nameIsTooLongMessage(organization, mailingAddress)}
      <CheckModalTable check={check} />
    </>
  );

  const renderErrorMsg = () =>
    error && error.message.includes('Check value') ? (
      <ErrorAlert className="mt-3 mb-0">
        {error.message.substring(15)}
      </ErrorAlert>
    ) : (
      <ErrorAlert className="mt-3 mb-0" />
    );

  return (
    <RestrictTo roles={['superadmin']}>
      <ModalForm
        id={`approve-check-modal-${check.id}`}
        error={error}
        errorMessage={renderErrorMsg()}
        loading={loading}
        handleSubmit={handleSubmit}
        submitProps={{
          className: classNames('btn btn-primary', { 'font-italic': loading }),
          children: loadingTense(loading, 'Send Check'),
          disabled: shouldDisableSubmit(mailingAddress) || loading,
        }}
        toggleProps={{
          className: 'btn btn-outline-success',
          children: (
            <>
              <Icon className="mr-1" value="check" />
              Approve
            </>
          ),
        }}
        title="Approve Check"
      >
        {renderBody}
      </ModalForm>
    </RestrictTo>
  );
};

ApproveCheckModalUnwrapped.propTypes = {
  check: LeagueSidePropTypes.check.isRequired,
  sendCheck: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation ApproveCheck($input: ApproveCheckInput!) {
    approveCheck(input: $input) {
      id
      number
      status
    }
  }
`;

const ApproveCheckModal = ({ check }) => (
  <Mutation mutation={MUTATION} variables={{ input: { checkId: check.id } }}>
    {(mutate) => (
      <ApproveCheckModalUnwrapped check={check} sendCheck={mutate} />
    )}
  </Mutation>
);

ApproveCheckModal.propTypes = {
  check: LeagueSidePropTypes.check.isRequired,
};

export default ApproveCheckModal;
