import { map } from 'ramda';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import TestimonialCard from './TestimonialCard';

function TestimonialCards({ testimonials }) {
  const card = (testimonial) => (
    <div key={testimonial.id} className="col-md-6 mt-2">
      <TestimonialCard testimonial={testimonial} key={testimonial.id} />
    </div>
  );

  return <div className="row">{map(card, testimonials)}</div>;
}

TestimonialCards.propTypes = {
  testimonials: PropTypes.arrayOf(LeagueSidePropTypes.testimonial).isRequired,
};

export default TestimonialCards;
