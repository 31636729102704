import numeral from 'numeral';
import { multiply, pipe } from 'ramda';

const FORMAT_NUMBER_STRING = '0,0';
export const formatNumber = (value) =>
  numeral(value).format(FORMAT_NUMBER_STRING);

export const formatPercentageValue = pipe(
  Number,
  multiply(100),
  Math.round,
  String,
);

export const formatToDollar = (value) => `$${formatNumber(value)}`;

const FORMAT_CURRENCY_STRING = '$0,0.00';
export const formatExactCurrency = (value) =>
  numeral(value).format(FORMAT_CURRENCY_STRING);

const isInt = (value) => Number.isInteger(value);
export const formatCurrency = (value) =>
  isInt(value) ? formatToDollar(value) : formatExactCurrency(value);
