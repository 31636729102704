import { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import useUserPilotTracking from 'hooks/useUserPilotTracking';
import SponsorshipEmailModal from './SponsorshipEmailModal';

const SEND_MUTATION = gql`
  mutation SendSponsorshipApplication(
    $input: SendSponsorshipApplicationInput!
  ) {
    sendSponsorshipApplication(input: $input) {
      offers {
        __typename
        id
      }
    }
  }
`;

const EMAIL_QUERY = gql`
  query SponsorshipApplicationEmail($offerId: Int!) {
    sponsorshipApplicationEmail(offerId: $offerId) {
      htmlBody
      to
    }
  }
`;

const email = path(['data', 'sponsorshipApplicationEmail']);

const SendSponsorshipApplicationModal = ({
  id,
  offers,
  onSend,
  toggleClassName,
  sponsorshipApplicationOfferId,
}) => {
  const [shouldFetchData, setShouldFetchData] = useState(true);
  const [offerId, setOfferId] = useState(sponsorshipApplicationOfferId);

  const { refetch } = useQuery(EMAIL_QUERY, {
    skip: shouldFetchData,
    fetchPolicy: 'no-cache',
    variables: { offerId },
  });
  const [mutate] = useMutation(SEND_MUTATION);
  const { sendUserPilotTrackingEvent } = useUserPilotTracking();

  return (
    <SponsorshipEmailModal
      id={id}
      fetchEmail={() => {
        setOfferId(offerId);
        setShouldFetchData(false);
        return refetch().then(email);
      }}
      lastSentAtPath={['sponsorshipApplicationSentAt']}
      offers={offers}
      trackingEvents={(offersToTrack) => {
        // eslint-disable-next-line array-callback-return
        offersToTrack.forEach((offer) => {
          sendUserPilotTrackingEvent(
            'Send Sponsorship Application Confirmation',
            {
              campaign_id: offer.campaign.id,
              sponsor_name: offer.campaign.sponsor.name,
              offer_id: offer.id,
              sponsorable_property_name: offer.sponsorableProperty.name,
            },
          );
        });
      }}
      sendEmails={async (offerIds) => {
        await mutate({
          variables: { input: { offerIds } },
        });
        onSend();
      }}
      title="Send Sponsorship Application?"
      toggleProps={{
        children: (
          <>
            <Icon className="mr-2" value="mail" />
            Send Sponsorship Application
          </>
        ),
        className: toggleClassName,
        onClick: () =>
          sendUserPilotTrackingEvent('Send Sponsorship Application Click'),
      }}
    />
  );
};

SendSponsorshipApplicationModal.propTypes = {
  id: PropTypes.string.isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  onSend: PropTypes.func,
  toggleClassName: PropTypes.string,
  sponsorshipApplicationOfferId: PropTypes.number,
};

SendSponsorshipApplicationModal.defaultProps = {
  onSend: () => {},
  toggleClassName: 'btn btn-primary',
  sponsorshipApplicationOfferId: null,
};

export default SendSponsorshipApplicationModal;
