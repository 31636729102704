import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Paragraph from './Paragraph';

const Contact = ({ addChanges, league }) => (
  <div className="container">
    <Paragraph bold>How can we get in touch with you?</Paragraph>
    <div className="row">
      <div className="col-md-6 mt-3">
        {/*
          We're using a strange name for this control to confuse chrome out of using "address autocompletion."
          This is a really funky feature that leads to details being blown away, and so we give these things
          weird names to keep Chrome from changing them.
        */}
        <input
          placeholder="Email Address"
          className="form-control form-control-lg"
          id="electronic-means-of-contact"
          onChange={(event) => addChanges({ contactEmail: event.target.value })}
          type="email"
          value={league.contactEmail || ''}
        />
      </div>
      <div className="col-md-6 mt-3">
        <input
          placeholder="Phone Number"
          className="form-control form-control-lg"
          id="audible-means-of-contact"
          onChange={(event) => addChanges({ contactPhone: event.target.value })}
          type="tel"
          value={league.contactPhone || ''}
        />
      </div>
    </div>
    <p className="form-text mt-3 text-muted">
      TeamSnap will only use this information to contact you regarding potential
      sponsorship opportunities and tools to help you manage your sponsorship.
      It will not be shared with any third parties.
    </p>
  </div>
);

Contact.propTypes = {
  addChanges: PropTypes.func.isRequired,
  league: LeagueSidePropTypes.league.isRequired,
};

Contact.getValidationError = ({ league }) =>
  league.contactEmail
    ? null
    : 'We need a way to contact your league when there are sponsorship opportunities available.';

export default Contact;
