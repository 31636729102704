import React from 'react';
import PropTypes from 'prop-types';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import Page from 'components/Page';
import { any, complement, propEq } from 'ramda';
import { history as browserHistory } from 'store';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const active = complement(propEq('status', 'rejected'));
const hasActiveOffer = any(active);

function redirect(campaign, history) {
  const context = hasActiveOffer(campaign.offers) ? 'leagues' : 'filters';
  history.replace(
    `${CAMPAIGN_PLANNER_PATH}/${campaign.sponsor.id}/campaigns/${campaign.id}/${context}`,
  );
}

class CampaignUnwrapped extends React.Component {
  constructor(props) {
    super(props);
    if (props.campaign) redirect(props.campaign, props.history);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps({ campaign, history }) {
    if (campaign) redirect(campaign, history);
  }

  render() {
    return <Page loaded={false} title="Loading. . ." />;
  }
}

const wrap = graphql(
  gql`
    query Campaign($id: Int!) {
      campaign(id: $id) {
        id
        offers {
          status
        }
        sponsor {
          id
        }
      }
    }
  `,
  {
    options: ({ match }) => ({
      fetchPolicy: 'network-only',
      variables: { id: Number(match.params.campaignId) },
    }),
    props: ({ data }) => ({
      campaign: data.campaign,
    }),
  },
);

const Campaign = wrap(CampaignUnwrapped);

CampaignUnwrapped.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

CampaignUnwrapped.defaultProps = {
  campaign: null,
  history: browserHistory,
};

export default Campaign;
