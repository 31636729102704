import React, { useEffect, useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { path } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import hideModal from 'utils/hideModal';
import {
  addressOnly,
  addressesEqual,
  getInitialDeliverableAddress,
  isAddressIncomplete,
  validatedAddressMessage,
  validatedAddressErrorMessage,
} from 'utils/deliverableAddress';
import { hasAnyPhysicalAssets } from 'utils/offer';
import { conjugateString } from 'utils/string';
import DELIVERABLE_ADDRESS_FRAGMENT from 'fragments/DeliverableAddressFragment';
import Alert from 'components/Alert';
import CheckboxInput from 'components/formInputs/CheckboxInput';
import DeliverableAddressFormInputs from 'components/DeliverableAddressFormInputs';
import ErrorAlert from 'components/ErrorAlert';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';

export const getModalId = (organizationId) =>
  `organization-${organizationId}-address-modal`;

const sameExistingMailingAndShippingAddresses = (organization) => {
  const { mailingAddress, shippingAddress } = organization;
  return (
    !!mailingAddress &&
    !!shippingAddress &&
    addressesEqual(mailingAddress, shippingAddress)
  );
};

export const OrganizationAddressModalUnwrapped = ({
  organization,
  verifyAddress,
  upsertMailingAddress,
  upsertShippingAddress,
}) => {
  const {
    id: organizationId,
    confirmedOffers,
    name: organizationName,
  } = organization;

  const [loading, setLoading] = useState(false);
  const [mailingAddress, setMailingAddress] = useState(
    getInitialDeliverableAddress(organization, 'mailing'),
  );
  const [shippingAddress, setShippingAddress] = useState(
    getInitialDeliverableAddress(organization, 'shipping'),
  );
  const [mailingAddressVerified, setMailingAddressVerified] = useState(false);
  const [mailingAddressError, setMailingAddressError] = useState(false);
  const [shippingAddressVerified, setShippingAddressVerified] = useState(false);
  const [shippingAddressError, setShippingAddressError] = useState(false);
  const [sameAddress, setSameAddress] = useState(
    sameExistingMailingAndShippingAddresses(organization),
  );

  const modalId = getModalId(organizationId);

  const requiresShippingAddress = hasAnyPhysicalAssets(confirmedOffers);
  const shouldDisableSave =
    loading ||
    isAddressIncomplete(mailingAddress) ||
    (!sameAddress &&
      requiresShippingAddress &&
      isAddressIncomplete(shippingAddress));

  function setVerifiedAddresses(
    verifyMailingError,
    verifiedMailingAddress,
    verifyShippingError,
    verifiedShippingAddress,
  ) {
    if ((requiresShippingAddress || sameAddress) && !verifyShippingError) {
      setShippingAddress(verifiedShippingAddress);
      setShippingAddressVerified(true);
    }

    if (!verifyMailingError) {
      setMailingAddress(verifiedMailingAddress);
      setMailingAddressVerified(true);
    }
  }

  function resetAddressAlerts() {
    setMailingAddressError(false);
    setMailingAddressVerified(false);
    setShippingAddressError(false);
    setShippingAddressVerified(false);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);
    resetAddressAlerts();

    let verifiedMailingAddress = {};
    let verifyMailingError = false;
    try {
      verifiedMailingAddress = await verifyAddress(addressOnly(mailingAddress));
    } catch (e) {
      verifyMailingError = true;
    }

    let verifiedShippingAddress = {};
    let verifyShippingError = false;
    if (sameAddress) {
      verifiedShippingAddress = verifiedMailingAddress;
      verifyShippingError = verifyMailingError;
    } else if (requiresShippingAddress) {
      try {
        verifiedShippingAddress = await verifyAddress(
          addressOnly(shippingAddress),
        );
      } catch (e) {
        verifyShippingError = true;
      }
    }

    setMailingAddressError(verifyMailingError);
    setShippingAddressError(verifyShippingError);

    if (verifyMailingError || verifyShippingError) {
      setVerifiedAddresses(
        verifyMailingError,
        verifiedMailingAddress,
        verifyShippingError,
        verifiedShippingAddress,
      );
      setLoading(false);
      return;
    }

    const mailingAddressIsVerified = addressesEqual(
      verifiedMailingAddress,
      mailingAddress,
    );
    const shippingAddressIsVerified = addressesEqual(
      verifiedShippingAddress,
      shippingAddress,
    );

    if (
      mailingAddressIsVerified &&
      (sameAddress || !requiresShippingAddress || shippingAddressIsVerified)
    ) {
      await upsertMailingAddress({
        organizationId,
        deliverability: verifiedMailingAddress.deliverability,
        ...addressOnly(verifiedMailingAddress),
      });
      if (requiresShippingAddress) {
        await upsertShippingAddress({
          organizationId,
          deliverability: verifiedShippingAddress.deliverability,
          ...addressOnly(verifiedShippingAddress),
        });
      }
      hideModal(`#${modalId}`);
    } else {
      setVerifiedAddresses(
        verifyMailingError,
        verifiedMailingAddress,
        verifyShippingError,
        verifiedShippingAddress,
      );
    }

    setLoading(false);
  }

  useEffect(() => {
    setMailingAddress(getInitialDeliverableAddress(organization, 'mailing'));
    setShippingAddress(getInitialDeliverableAddress(organization, 'shipping'));
    resetAddressAlerts();
    setSameAddress(sameExistingMailingAndShippingAddresses(organization));
  }, [organization]);

  const addressVerifiedAlert = (
    <Alert alertStyle="success" role="alert" data-test="address-verified-alert">
      {validatedAddressMessage}
    </Alert>
  );
  const addressErrorAlert = (
    <ErrorAlert role="alert" data-test="address-error-alert">
      {validatedAddressErrorMessage}
    </ErrorAlert>
  );

  return (
    <Modal
      id={modalId}
      title={`Addresses for ${organizationName}`}
      key={organizationId}
    >
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="form-group" data-test="mailing-address">
            <strong>Where should we send the sponsorship check?</strong>
            {mailingAddressVerified && addressVerifiedAlert}
            {mailingAddressError && addressErrorAlert}
            <DeliverableAddressFormInputs
              deliverableAddress={mailingAddress}
              disabled={loading}
              inputLabel={`mailing-address-${organizationId}`}
              setDeliverableAddress={setMailingAddress}
            />
          </div>
          {requiresShippingAddress && (
            <div className="form-group mt-4" data-test="shipping-address">
              <strong>
                Where should we send supplies (e.g. banners, coupons) for the
                campaign?
              </strong>
              <CheckboxInput
                checked={sameAddress}
                disabled={loading}
                label="Use same address"
                onChange={(event) => setSameAddress(event.target.checked)}
                prefix={organizationId}
              />
              {!sameAddress && (
                <>
                  {shippingAddressVerified && addressVerifiedAlert}
                  {shippingAddressError && addressErrorAlert}
                  <DeliverableAddressFormInputs
                    deliverableAddress={shippingAddress}
                    disabled={loading}
                    inputLabel={`shipping-address-${organizationId}`}
                    setDeliverableAddress={setShippingAddress}
                  />
                </>
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-primary"
            data-test={`${modalId}-submit`}
            disabled={shouldDisableSave}
            type="submit"
          >
            {conjugateString('Save', loading)}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

OrganizationAddressModalUnwrapped.propTypes = {
  organization: LeagueSidePropTypes.organization.isRequired,
  verifyAddress: PropTypes.func.isRequired,
  upsertMailingAddress: PropTypes.func.isRequired,
  upsertShippingAddress: PropTypes.func.isRequired,
};

const VERIFY_ADDRESS_QUERY = gql`
  query VerifyAddress($input: VerifyAddressInput!) {
    verifyAddress(input: $input) {
      name
      line1
      line2
      city
      state
      zip
      deliverability
      country
    }
  }
`;

const UPSERT_MAILING_ADDRESS_MUTATION = gql`
  mutation UpsertMailingAddress($input: UpsertAddressInput!) {
    upsertAddress(input: $input) {
      id
      mailingAddress {
        ...DeliverableAddressFragment
      }
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

const UPSERT_SHIPPING_ADDRESS_MUTATION = gql`
  mutation UpsertShippingAddress($input: UpsertAddressInput!) {
    upsertAddress(input: $input) {
      id
      shippingAddress {
        ...DeliverableAddressFragment
      }
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

const verifiedAddress = path(['data', 'verifyAddress']);
const OrganizationAddressModal = ({ organization }) => (
  <ApolloConsumer>
    {(client) => (
      <OrganizationAddressModalUnwrapped
        organization={organization}
        verifyAddress={(address) =>
          client
            .query({
              fetchPolicy: 'network-only',
              query: VERIFY_ADDRESS_QUERY,
              variables: { input: address },
            })
            .then(verifiedAddress)
        }
        upsertMailingAddress={(address) =>
          client.mutate({
            mutation: UPSERT_MAILING_ADDRESS_MUTATION,
            variables: { input: { ...address, addressType: 'mailing' } },
          })
        }
        upsertShippingAddress={(address) =>
          client.mutate({
            mutation: UPSERT_SHIPPING_ADDRESS_MUTATION,
            variables: { input: { ...address, addressType: 'shipping' } },
          })
        }
      />
    )}
  </ApolloConsumer>
);

OrganizationAddressModal.propTypes = {
  organization: LeagueSidePropTypes.organization.isRequired,
};

export default OrganizationAddressModal;
