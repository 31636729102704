import { merge } from 'ramda';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import React, { useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import ModalForm from 'components/ModalForm';
import PlacesAutocomplete from 'components/PlacesAutocomplete';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import RefetchDataContext from './RefetchDataContext';

function CreateLocationByAddressModalUnwrapped({ locationFilterId, create }) {
  const [addressInputValue, setAddressInputValue] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [name, setName] = useState('');
  const refetchData = useContext(RefetchDataContext);

  function handlePlaceChange(address) {
    setAddressInputValue(address);
  }

  function handlePlaceSelect(address) {
    setAddressInputValue(address);
    setSelectedAddress(address);
    setName(address);
  }

  function handleNameChange(event) {
    setName(event.target.value);
  }

  async function onSubmit() {
    await create({ name, address: selectedAddress });
    refetchData();
    setAddressInputValue('');
    setSelectedAddress(null);
    setName('');
  }

  const { error, loading, handleSubmit } = useSubmit(onSubmit);

  return (
    <ModalForm
      id={`create-location-by-address-${locationFilterId}`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Add'),
        disabled: !name || !selectedAddress || loading,
      }}
      title="Add Address"
      toggleProps={{
        className: 'dropdown-item',
        children: 'Address',
        'data-test': 'by-address',
      }}
    >
      <div className="form-group">
        <label htmlFor="address">Address</label>
        <PlacesAutocomplete
          inputProps={{ name: 'address', disabled: loading }}
          onChange={useCallback(handlePlaceChange)}
          onSelect={useCallback(handlePlaceSelect)}
          shouldFetchSuggestions
          value={addressInputValue}
        />
      </div>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          className="form-control"
          onChange={handleNameChange}
          type="text"
          value={name}
        />
      </div>
    </ModalForm>
  );
}

CreateLocationByAddressModalUnwrapped.propTypes = {
  create: PropTypes.func.isRequired,
  locationFilterId: PropTypes.number.isRequired,
};

const MUTATION = gql`
  mutation CreateLocationByAddress($input: AddAddressToLocationFilterInput!) {
    addAddressToLocationFilter(input: $input) {
      locationFilter {
        id
      }
    }
  }
`;

const CreateLocationByAddressModal = ({ locationFilterId }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CreateLocationByAddressModalUnwrapped
        create={(input) =>
          mutate({ variables: { input: merge(input, { locationFilterId }) } })
        }
        locationFilterId={locationFilterId}
      />
    )}
  </Mutation>
);

CreateLocationByAddressModal.propTypes = {
  locationFilterId: PropTypes.number.isRequired,
};

export default CreateLocationByAddressModal;
