import React from 'react';
import { Link } from 'react-router-dom';
import { Query, useQuery } from 'react-apollo';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';
import { isTotalStrategy } from 'utils/locationFilter';
import AddAllAvailableSponsorablePropertiesModal from 'components/AddAllAvailableSponsorablePropertiesModal';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import ProspectFilterPerLocation from 'components/ProspectFilterPerLocation';
import ProspectLocations from 'components/ProspectLocations';
import ProspectingReportTotalProgressBar from 'components/ProspectingReportTotalProgressBar';
import SponsorAndCampaignLinksHeader from 'components/SponsorAndCampaignLinksHeader';
import AddPreviousPartnersModal from 'components/AddPreviousPartnersModal';
import PROSPECT_FILTER_QUERY, {
  ADD_ALL_LOCATION_FILTER_REFETCH_QUERY,
} from './prospectFilter/prospectFilterQueries';

const ProspectFilter = ({ match, cable }) => {
  const filterId = Number(match.params.filterId);

  const { refetch: refetchAddAllLocationFilter } = useQuery(
    ADD_ALL_LOCATION_FILTER_REFETCH_QUERY,
    {
      variables: { id: filterId },
      skip: true,
    },
  );

  return (
    <Query
      fetchPolicy="network-only"
      query={PROSPECT_FILTER_QUERY}
      variables={{ id: filterId }}
    >
      {({ data }) => {
        const locationFilter = pathOr({}, ['locationFilter'], data);
        const campaign = pathOr(null, ['locationFilter', 'campaign'], data);
        const locations = pathOr([], ['locationFilter', 'locations'], data);
        const availableSponsorableProperties = pathOr(
          [],
          ['locationFilter', 'availableSponsorableProperties'],
          data,
        );
        const availableCount = pathOr(
          0,
          ['locationFilter', 'leagueProspectingReport', 'available'],
          data,
        );
        const confirmedOffers = pathOr(
          [],
          ['locationFilter', 'confirmedOffers'],
          data,
        );
        const proposedOffers = pathOr(
          [],
          ['locationFilter', 'proposedOffers'],
          data,
        );
        const prospectingOffers = pathOr(
          [],
          ['locationFilter', 'prospectingOffers'],
          data,
        );
        const qualifiedOffers = pathOr(
          [],
          ['locationFilter', 'qualifiedOffers'],
          data,
        );
        const removedOffers = pathOr(
          [],
          ['locationFilter', 'removedOffers'],
          data,
        );
        const isLoaded = !!campaign && !!locationFilter;

        return (
          <Page fullScreen loaded={isLoaded} mainClassName="sui-flex">
            {isLoaded && (
              <RestrictTo roles={['admin']}>
                <FieldhousePageTitle title={campaign.name} />
                <div className="d-flex justify-content-around">
                  <div className="mr-auto mt-3">
                    <SponsorAndCampaignLinksHeader
                      campaign={campaign}
                      className="ml-2"
                    />
                    <h1 className="ml-2">
                      <Link to={`/prospect-campaign/${campaign.id}`}>
                        Prospecting
                      </Link>
                      &nbsp;/&nbsp;
                      {locationFilter.name}
                    </h1>
                  </div>
                  {!!locations.length && (
                    <div className="col-6 sui-px-3 sui-py-3 text-center align-self-center">
                      <ProspectingReportTotalProgressBar
                        prospectingReportId={locationFilter.prospectingReportId}
                        cable={cable}
                      />
                    </div>
                  )}
                  <div className="col-2 align-self-center text-right">
                    {!isTotalStrategy(locationFilter) && (
                      <AddPreviousPartnersModal
                        input={{ searchFilterId: locationFilter.id }}
                        onAdd={refetchAddAllLocationFilter}
                      />
                    )}
                    {!isTotalStrategy(locationFilter) && (
                      <AddAllAvailableSponsorablePropertiesModal
                        availableCount={availableCount}
                        input={{ searchFilterId: locationFilter.id }}
                        onAdd={refetchAddAllLocationFilter}
                      />
                    )}
                  </div>
                </div>
                {isTotalStrategy(locationFilter) ? (
                  <ProspectLocations
                    locations={locations}
                    campaign={campaign}
                    locationFilter={locationFilter}
                    availableSponsorableProperties={
                      availableSponsorableProperties
                    }
                    confirmedOffers={confirmedOffers}
                    proposedOffers={proposedOffers}
                    prospectingOffers={prospectingOffers}
                    qualifiedOffers={qualifiedOffers}
                    removedOffers={removedOffers}
                  />
                ) : (
                  <ProspectFilterPerLocation
                    locationFilter={locationFilter}
                    cable={cable}
                  />
                )}
              </RestrictTo>
            )}
          </Page>
        );
      }}
    </Query>
  );
};

ProspectFilter.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      filterId: PropTypes.string,
    }),
  }).isRequired,
  cable: PropTypes.shape.isRequired,
};

export default ProspectFilter;
