import { useState, useEffect } from 'react';
import hideModal from './hideModal';

export function useSubmit(onSubmit) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setError(null);
      setLoading(true);
      await onSubmit(event);
      setLoading(false);
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), 1500);
    } catch (e) {
      if (e.response !== undefined) {
        setError(e.response.data);
      } else {
        setError(e);
      }
      setLoading(false);
      throw e;
    }
  }

  return { error, loading, handleSubmit, submitted };
}

export function useSubmitWithGQL(onSubmit, selector = null) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setError(null);
      setLoading(true);
      await onSubmit(event);
      setLoading(false);
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), 1500);
      if (selector) hideModal(`#${selector}`);
    } catch (e) {
      setError(e);
      setSubmitted(false);
    }
    setLoading(false);
  }
  return { error, loading, handleSubmit, submitted };
}

const useScript = (url, id) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.defer = true;
    script.id = id;
    script.type = 'text/javascript';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, id]);
};

export { useScript };
