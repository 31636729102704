import * as React from 'react';
import { Link } from 'react-router-dom';
import { CampaignStatus } from 'types/campaign';
import { OfferStatus } from 'types/offer';
import { Season } from 'types/season';
import { nameAndSeasonLabel } from 'utils/campaign';
import { CAMPAIGN_PLANNER_PATH, OFFER_PREFIX } from 'utils/paths';
import CampaignStatusBadge from './CampaignStatusBadge';
import OfferStatusBadge from './OfferStatusBadge';

interface Sponsor {
  id: number;
  name: string;
}

interface SponsorableProperty {
  name: string;
}

interface Campaign {
  id: number;
  name: string;
  season: Season;
  sponsor: Sponsor;
  status: CampaignStatus;
  year: string;
}

interface Offer {
  id: number;
  campaign: Campaign;
  sponsorableProperty: SponsorableProperty;
  status: OfferStatus;
}

interface Props {
  offer: Offer;
}

const CampaignsTableRow: React.FunctionComponent<Props> = ({
  offer,
}: Props) => {
  const { id: offerId, campaign, sponsorableProperty } = offer;
  const { id: campaignId, sponsor, status: campaignStatus } = campaign;
  const { id: sponsorId, name: sponsorName } = sponsor;
  const { name: sponsorablePropertyName } = sponsorableProperty;

  return (
    <tr data-test={`campaign-${campaignId}`}>
      <td>{sponsorablePropertyName}</td>
      <td>
        <Link
          data-test={`campaign-${campaignId}-link`}
          to={`${OFFER_PREFIX}/${offerId}`}
        >
          {nameAndSeasonLabel(campaign)}
        </Link>
        <CampaignStatusBadge campaignStatus={campaignStatus} />
      </td>
      <td>
        <Link
          data-test={`campaign-${campaignId}-sponsor-link`}
          to={`${CAMPAIGN_PLANNER_PATH}/${sponsorId}`}
        >
          {sponsorName}
        </Link>
      </td>
      <td>
        <OfferStatusBadge offer={offer} />
      </td>
    </tr>
  );
};

export default CampaignsTableRow;
