import * as React from 'react';
import { conjugateString } from 'utils/string';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  label: string;
  loading?: boolean;
  submitted?: boolean;
}

const GenericButton: React.FC<Props> = ({
  disabled,
  label,
  loading,
  submitted,
}: Props) => (
  <button
    data-test="generic-button"
    className={`btn btn-${submitted ? 'success' : 'primary'}`}
    type="submit"
    disabled={disabled || loading || submitted}
  >
    {conjugateString(label, loading, submitted)}
  </button>
);

GenericButton.defaultProps = {
  disabled: false,
  loading: false,
  submitted: false,
};

export default GenericButton;
