import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Icon from 'components/Icon';
import RemoveFromCampaignModal from 'components/RemoveFromCampaignModal';

const removeAllButton = (offers, onRemove) => (
  <RemoveFromCampaignModal
    offers={offers}
    onRemove={onRemove}
    prefix="bulk"
    toggleProps={{
      className: 'btn btn-outline-danger',
      children: (
        <>
          <Icon className="mr-1" value="trash" />
          Remove All
        </>
      ),
    }}
  />
);

const allRemovedButton = (
  <button
    className="btn btn-danger"
    data-test="all-removed"
    disabled
    type="button"
  >
    <Icon className="mr-1" value="cross" />
    All Removed
  </button>
);

const RemoveAllFromCampaignButton = ({ allRemoved, offers, onRemove }) => {
  if (allRemoved) return allRemovedButton;

  return offers.length ? removeAllButton(offers, onRemove) : null;
};

RemoveAllFromCampaignButton.propTypes = {
  allRemoved: PropTypes.bool.isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer),
  onRemove: PropTypes.func.isRequired,
};

RemoveAllFromCampaignButton.defaultProps = {
  offers: [],
};

export default RemoveAllFromCampaignButton;
