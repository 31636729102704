import { useState } from 'react';
import LeaguesideApi from 'utils/leagueside-api';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import { getId } from 'utils/id';
import { findById } from 'utils/misc';
import { displayCreativeNotificationAlert } from 'utils/verificationDeadlines';
import type { Offer } from 'types/offer';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import ImageInput from 'components/formInputs/ImageInput';
import NameInput from 'components/formInputs/NameInput';
import DeadlineSelectInput from 'components/formInputs/DeadlineSelectInput';
import AvailableCreativeNotificationAlert from 'components/AvailableCreativeNotificationAlert';

type CreateCreativeFileModalProps = {
  disabled: boolean;
  onUploadComplete: () => void;
  toggleClassName: string;
  verificationDeadlines: Offer['verificationDeadlines'];
};

function CreateCreativeFileModal(props: CreateCreativeFileModalProps) {
  const { disabled, onUploadComplete, toggleClassName, verificationDeadlines } =
    props;
  const [name, setName] = useState('');
  const [image, setImage] = useState<File | undefined>(undefined);
  const [deadlineId, setDeadlineId] = useState('');
  const selectedDeadline = findById(Number(deadlineId))(verificationDeadlines);

  const requiredDataPresent = name && image && deadlineId;

  async function asyncSubmit() {
    await LeaguesideApi.createCreativeFile(deadlineId, image, name);

    setName('');
    setImage(undefined);
    setDeadlineId('');

    onUploadComplete();
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const submitLabel = 'Create';
  const id = getId([submitLabel, 'creative-file']);

  return (
    <ModalForm
      id={`${id}-modal`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, submitLabel),
        disabled: !requiredDataPresent || loading,
      }}
      title={`${submitLabel} Creative File?`}
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon value="plus" />
            Add Creative File
          </>
        ),
        disabled,
      }}
    >
      <p>
        To upload creative file, please specify which deadline the file should
        be associated with.
      </p>
      <DeadlineSelectInput
        data-test="deadline-select-input"
        deadlineId={deadlineId}
        deadlines={verificationDeadlines}
        isRequired
        loading={loading}
        onChange={(ev) =>
          setDeadlineId((ev.target as HTMLButtonElement)?.value)
        }
        prefix={id}
      />
      {selectedDeadline &&
        displayCreativeNotificationAlert(selectedDeadline) && (
          <AvailableCreativeNotificationAlert />
        )}
      <NameInput
        data-test="name-input"
        isRequired
        loading={loading}
        name={name}
        onChange={(ev) => setName((ev.target as HTMLButtonElement)?.value)}
        prefix={id}
      />
      <ImageInput
        data-test="image-input"
        image={image}
        isRequired
        loading={loading}
        onChange={(ev) => {
          const target = ev.target as HTMLInputElement;
          const file: File = (target.files as FileList)[0];
          setImage(file);
        }}
        prefix={id}
      />
    </ModalForm>
  );
}

export default CreateCreativeFileModal;
