import {
  StepIdsEnum,
  Inputs,
  Organization,
  StepTypeEnum,
  WizardMapType,
} from './types';

export function getNextStep(
  wizardMap: WizardMapType,
  activeSectionIndex: number,
  activeStepIndex: number,
): {
  sectionIndex: number;
  activeIndex: number;
} | null {
  const wizardMapKeys = Object.keys(wizardMap) as StepTypeEnum[];
  const key = wizardMapKeys[activeSectionIndex];
  const activeSection = wizardMap[key];
  if (!activeSection) return null;

  const stepToGo = activeStepIndex + 1;
  if (stepToGo >= activeSection.steps.length) {
    // find next sectionToGo that has steps on it
    const sectionToGo = wizardMapKeys.findIndex(
      (keyToFind, index) =>
        index > activeSectionIndex && wizardMap[keyToFind].steps.length > 0,
    );
    if (sectionToGo >= wizardMapKeys.length) return null;
    return { sectionIndex: sectionToGo, activeIndex: 0 };
  }
  return { sectionIndex: activeSectionIndex, activeIndex: stepToGo };
}

export function getPreviousStep(
  wizardMap: WizardMapType,
  activeSectionIndex: number,
  activeStepIndex: number,
): {
  sectionIndex: number;
  activeIndex: number;
} | null {
  const wizardMapKeys = Object.keys(wizardMap) as StepTypeEnum[];
  const key = wizardMapKeys[activeSectionIndex];
  const activeSection = wizardMap[key];
  if (!activeSection) return null;

  const stepToGo = activeStepIndex - 1;
  if (stepToGo < 0) {
    // find prev sectionToGo that has steps on it
    const prevKeyWithSteps: StepTypeEnum | undefined = wizardMapKeys
      .slice(0, activeSectionIndex)
      .reverse()
      .find((keyToFind) => wizardMap[keyToFind].steps.length > 0);
    if (!prevKeyWithSteps) return null;
    const sectionToGo = wizardMapKeys.indexOf(prevKeyWithSteps);
    if (sectionToGo < 0) return null;
    const nextSection = wizardMap[wizardMapKeys[sectionToGo]];
    return {
      sectionIndex: sectionToGo,
      activeIndex: nextSection.steps.length - 1,
    };
  }
  return { sectionIndex: activeSectionIndex, activeIndex: stepToGo };
}

export function parseAnyListToOptionsList(
  list: any[],
  options?: {
    labelKey?: string;
    valueKey?: string;
    withEmptyOption?: boolean;
  },
): { label: string; value: string }[] {
  const {
    labelKey = 'name',
    valueKey = 'id',
    withEmptyOption = true,
  } = options || {};
  return [
    ...(withEmptyOption ? [{ label: 'Select an option', value: '' }] : []),
    ...list.map((item) => ({
      label: item[labelKey] ?? item,
      value: item[valueKey] ?? item,
    })),
  ];
}

export function getValuesFromOrganizationDataToPopulate(
  organizationData: Partial<Organization>,
): Partial<Inputs> {
  return {
    organizationId: organizationData?.id,
    sponsorshipApplicationId: organizationData?.sponsorshipApplication?.id,
    interests: organizationData?.sponsorshipApplication?.chosenInterests,
    notInterested: organizationData?.sponsorshipApplication?.notInterested,
    primaryContactFirstName:
      organizationData?.sponsorshipApplication?.primaryContactFirstName,
    primaryContactLastName:
      organizationData?.sponsorshipApplication?.primaryContactLastName,
    primaryContactEmail:
      organizationData?.sponsorshipApplication?.primaryContactEmail,
    primaryContactPhone:
      organizationData?.sponsorshipApplication?.primaryContactPhone,
    socialMediaFollowingSize: organizationData?.socialMediaFollowingSize,
    emailListSize: organizationData?.emailListSize,
    emailOpenRate: organizationData?.emailOpenRate,
    organizationTypeId: organizationData?.organizationTypeId,
    playLocations: organizationData?.playLocations?.map((playLocation) => {
      return {
        id: playLocation.id,
        address: playLocation.streetAddress,
        city: playLocation.city,
        state: playLocation.state,
        zipcode: playLocation.postalCode,
        country: playLocation.country,
        primaryProgramIds: playLocation.primaryPrograms.map(
          (program) => program.id,
        ),
        otherProgramIds: playLocation.otherPrograms.map(
          (program) => program.id,
        ),
      };
    }),
    sponsorableProperties: organizationData?.currentSponsorableProperties?.map(
      (sponsorableProperty) => ({
        id: sponsorableProperty.id,
        name: sponsorableProperty.name,
        competitivenessId: sponsorableProperty.competitiveness?.id,
        sportId: sponsorableProperty.sport?.id,
        numberOfPlayers: sponsorableProperty.numberOfPlayers,
        gender: getGenderKeyFromBooleans(
          sponsorableProperty.female,
          sponsorableProperty.male,
        ),
        minAge: sponsorableProperty.minAge,
        maxAge: sponsorableProperty.maxAge,
        startDate: sponsorableProperty.startDate,
        endDate: sponsorableProperty.endDate,
        season: sponsorableProperty.season,
      }),
    ),
    customResponses:
      organizationData?.sponsorshipApplication?.customResponses?.map(
        (customResponse) => ({
          id: customResponse.id,
          customQuestionId: customResponse.customQuestionId,
          response: customResponse.response,
        }),
      ),
    additionalInformation:
      organizationData?.sponsorshipApplication?.additionalInformation,
    interestedInHundredX:
      organizationData?.sponsorshipApplication?.interestedInHundredx,
    ...(organizationData?.sponsorshipApplication?.tournament ?? {}),
    ...organizationData?.sponsorshipApplication?.savedFormData,
  };
}

function getGenderKeyFromBooleans(
  female: boolean,
  male: boolean,
): 'coed' | 'girls' | 'boys' | null {
  if (female && male) return 'coed';
  if (female) return 'girls';
  if (male) return 'boys';
  return null;
}

export const INTERESTS_LIST_PER_GROUP = {
  brandedCollateral: [
    StepIdsEnum.GAME_JERSEYS,
    StepIdsEnum.WARM_UPS,
    StepIdsEnum.GAME_SIGNAGE,
    StepIdsEnum.COUPONS,
  ],
  experiences: [StepIdsEnum.SEMINAR, StepIdsEnum.SAMPLING, StepIdsEnum.PHOTOS],
  digitalAssets: [
    StepIdsEnum.SURVEYS,
    StepIdsEnum.DEDICATED_EMAIL,
    StepIdsEnum.WEBSITE_BANNER,
  ],
};

export const SHIPMENTS_ARRIVALS_LIST = [
  { label: 'One week before', value: 'one_week' },
  { label: 'Two weeks before', value: 'two_weeks' },
  { label: 'Three weeks before', value: 'three_weeks' },
  { label: 'Four weeks before', value: 'four_weeks' },
];

export const COUNTRIES_LIST = [
  { label: 'United States of America', value: 'United States of America' },
  { label: 'Canada', value: 'Canada' },
];
export const COUNTRIES_SHORT_NAME_LIST = {
  US: 'United States of America',
  CA: 'Canada',
};

export const STATES_LIST = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const GENDERS_LIST = [
  { label: 'Girls', value: 'girls' },
  { label: 'Boys', value: 'boys' },
  { label: 'Coed', value: 'coed' },
];
