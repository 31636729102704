import { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  allPass,
  contains,
  equals,
  filter,
  find,
  isEmpty,
  median,
  not,
  pathEq,
  pipe,
  pluck,
  prop,
  propEq,
  reject,
} from 'ramda';
import classNames from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { pluralizedTense } from 'utils/string';
import {
  findSponsorablePropertyId,
  isRemoved,
  OFFER_STATUSES,
} from 'utils/offer';
import { rejectFalsy } from 'utils/array';
import { filterableDataConfidences } from 'types/sponsorableProperty';
import { getCampaignSearchFilters, getSports } from 'modules';
import { getLoaded as sportsLoaded, load as loadSports } from 'modules/sports';
import {
  getAll,
  load as loadSearchFilters,
  getLoaded,
} from 'modules/campaign/searchFilters';
import Map from 'components/Map';
import RestrictTo from 'components/RestrictTo';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Page from 'components/Page';
import SponsorAndCampaignLinksHeader from 'components/SponsorAndCampaignLinksHeader';
import wrapWithCampaignAndProspectiveSponsorableProperties from './campaignTargeting/wrapWithCampaignAndProspectiveSponsorableProperties';
import CampaignSponsorableProperties from './campaignTargeting/CampaignSponsorableProperties';
import CampaignFilters from './campaignTargeting/CampaignFilters';
import RefetchDataContext from './campaignTargeting/campaignFilters/RefetchDataContext';

const ButtonTab = styled.button`
  :focus {
    outline: none;
  }
`;

const PageSidebarAndSummary = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 0;
  overflow-y: hidden;
`;

const PageSidebar = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: hidden;
  padding: 1rem;
  padding-bottom: 0px;
  width: 550px;
`;

const Sidebar = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow-y: hidden;
`;

const findOfferBySponsorablePropertyId = (sponsorablePropertyId, offers) =>
  find(pathEq(['sponsorableProperty', 'id'], sponsorablePropertyId), offers);
const added = reject(isRemoved);
const removed = filter(isRemoved);
const allSelected = (options) => propEq('length', options.length);
const allDataConfidencesSelected = allSelected(filterableDataConfidences);
const allOfferStatusesSelected = allSelected(OFFER_STATUSES);
const hasSurveyResponse = pipe(prop('eventsSurveyResponses'), isEmpty, not);
const getMedianNumberOfPlayers = (sponsorableProperties) =>
  median(rejectFalsy(pluck('numberOfPlayers', sponsorableProperties)));

export const CampaignTargetingUnwrapped = (props) => {
  const [visibleTab, setVisibleTab] = useState('filters');
  const [filterBySurveyResponse, setFilterBySurveyResponse] = useState(false);
  const [selectedDataConfidences, setSelectedDataConfidences] = useState(
    filterableDataConfidences,
  );
  const [selectedOfferStatuses, setSelectedOfferStatuses] =
    useState(OFFER_STATUSES);
  const [hoveredSponsorablePropertyId, setHoveredSponsorablePropertyId] =
    useState(null);
  const [scrollToIndex, setScrollToIndex] = useState(-1);

  const handleSponsorablePropertyMarkerClick = useCallback(
    (hoveredSponsorablePropertyIdentifiers) => {
      setHoveredSponsorablePropertyId(hoveredSponsorablePropertyIdentifiers.id);
      setScrollToIndex(hoveredSponsorablePropertyIdentifiers.index);
    },
    [],
  );

  const {
    campaign,
    capabilities,
    filters,
    load,
    loaded,
    markets,
    prospectiveSponsorableProperties,
    refetchCampaign,
    refetchProspectiveSponsorableProperties,
  } = props;
  const { name: campaignName, offers } = campaign;

  const filteredSponsorableProperties = () => {
    const showAllDataConfidences = allDataConfidencesSelected(
      selectedDataConfidences,
    );
    const showAllOfferStatuses = allOfferStatusesSelected(
      selectedOfferStatuses,
    );
    if (
      showAllDataConfidences &&
      showAllOfferStatuses &&
      !filterBySurveyResponse
    ) {
      return prospectiveSponsorableProperties;
    }

    const findSponsorablePropertyOfferStatus = (sponsorablePropertyId) => {
      const offer = findOfferBySponsorablePropertyId(
        sponsorablePropertyId,
        offers,
      );
      return offer ? offer.status : 'unadded';
    };

    const dataConfidenceFilter = (sponsorableProperty) =>
      contains(sponsorableProperty.dataConfidence, selectedDataConfidences);
    const offerStatusFilter = ({ id }) =>
      contains(findSponsorablePropertyOfferStatus(id), selectedOfferStatuses);
    const surveyResponseFilter = (sponsorableProperty) =>
      !filterBySurveyResponse ||
      hasSurveyResponse(sponsorableProperty.organization);
    const isVisible = allPass([
      dataConfidenceFilter,
      offerStatusFilter,
      surveyResponseFilter,
    ]);

    return filter(isVisible, prospectiveSponsorableProperties);
  };

  const sponsorableProperties = filteredSponsorableProperties();
  const sponsorablePropertiesLength = sponsorableProperties.length;
  const sponsorablePropertiesLengthLabel = `${sponsorablePropertiesLength} ${pluralizedTense(
    sponsorablePropertiesLength,
    'Sponsorable Property',
  )}`;
  const medianNumberOfPlayers = getMedianNumberOfPlayers(sponsorableProperties);

  const addedOffers = added(offers);

  const addedSponsorablePropertyIds = new Set(
    addedOffers.map(findSponsorablePropertyId),
  );
  const removedSponsorablePropertyIds = new Set(
    removed(offers).map(findSponsorablePropertyId),
  );

  const renderButtonTab = (tabKey, content) => (
    <ButtonTab
      className={classNames('nav-link btn-link', {
        active: equals(tabKey, visibleTab),
      })}
      onClick={() => setVisibleTab(tabKey)}
      type="button"
      data-test={`${tabKey}-button`}
    >
      {content}
    </ButtonTab>
  );

  return (
    <Page load={load} loaded={loaded} fullScreen mainClassName="sui-flex">
      <RefetchDataContext.Provider
        value={() => {
          refetchCampaign();
          refetchProspectiveSponsorableProperties();
        }}
      >
        <RestrictTo roles={['admin']}>
          <FieldhousePageTitle title={campaignName}>
            <PageSidebarAndSummary>
              <PageSidebar>
                <SponsorAndCampaignLinksHeader
                  campaign={campaign}
                  className="mb-0"
                />
                <div className="d-flex align-items-center mb-2">
                  <div className="flex-fill">
                    <h1 className="mb-0">Targeting</h1>
                    <hr className="mt-0 mb-1" />
                  </div>
                  <ul className="nav nav-tabs" id="targetingTab" role="tablist">
                    <li className="nav-item">
                      {renderButtonTab('filters', 'Filters')}
                    </li>
                    <li className="nav-item">
                      {renderButtonTab(
                        'sponsorableProperties',
                        sponsorablePropertiesLengthLabel,
                      )}
                    </li>
                  </ul>
                </div>
                <Sidebar>
                  {equals('filters', visibleTab) && (
                    <CampaignFilters
                      campaign={campaign}
                      capabilities={capabilities}
                      filterBySurveyResponse={filterBySurveyResponse}
                      filters={filters}
                      markets={markets}
                      selectedDataConfidences={selectedDataConfidences}
                      selectedOfferStatuses={selectedOfferStatuses}
                      setFilterBySurveyResponse={setFilterBySurveyResponse}
                      setSelectedDataConfidences={setSelectedDataConfidences}
                      setSelectedOfferStatuses={setSelectedOfferStatuses}
                    />
                  )}
                  {equals('sponsorableProperties', visibleTab) && (
                    <CampaignSponsorableProperties
                      addedSponsorablePropertyIds={addedSponsorablePropertyIds}
                      addedOffers={addedOffers}
                      campaign={campaign}
                      hoveredSponsorablePropertyId={
                        hoveredSponsorablePropertyId
                      }
                      sponsorableProperties={sponsorableProperties}
                      removedSponsorablePropertyIds={
                        removedSponsorablePropertyIds
                      }
                      scrollToIndex={scrollToIndex}
                      setHoveredSponsorablePropertyId={
                        setHoveredSponsorablePropertyId
                      }
                    />
                  )}
                </Sidebar>
              </PageSidebar>
              <div className="summary">
                <div className="bg-white p-3">
                  <h6 className="m-0">{sponsorablePropertiesLengthLabel}</h6>
                  {!!medianNumberOfPlayers && (
                    <h3 className="m-0" data-test="median-number-of-players">
                      {`Median Number of Players: ${medianNumberOfPlayers}`}
                    </h3>
                  )}
                </div>
                <Map
                  addedSponsorablePropertyIds={addedSponsorablePropertyIds}
                  campaignGeometries={campaign.campaignGeometries}
                  removedSponsorablePropertyIds={removedSponsorablePropertyIds}
                  hoveredSponsorablePropertyId={hoveredSponsorablePropertyId}
                  sponsorableProperties={sponsorableProperties}
                  radii={campaign.radii}
                  onSponsorablePropertyMarkerClick={
                    handleSponsorablePropertyMarkerClick
                  }
                />
              </div>
            </PageSidebarAndSummary>
          </FieldhousePageTitle>
        </RestrictTo>
      </RefetchDataContext.Provider>
    </Page>
  );
};

CampaignTargetingUnwrapped.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
  capabilities: LeagueSidePropTypes.capabilities,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
  load: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  markets: LeagueSidePropTypes.geometries.isRequired,
  prospectiveSponsorableProperties:
    LeagueSidePropTypes.sponsorableProperties.isRequired,
  refetchCampaign: PropTypes.func.isRequired,
  refetchProspectiveSponsorableProperties: PropTypes.func.isRequired,
};

CampaignTargetingUnwrapped.defaultProps = {
  capabilities: [],
};

const mapStateToProps = (state) => ({
  filters: getAll(getCampaignSearchFilters(state)),
  loaded:
    getLoaded(getCampaignSearchFilters(state)) &&
    sportsLoaded(getSports(state)),
});

const mapDispatchToProps = (dispatch, { match }) => ({
  load() {
    dispatch(loadSports());
    dispatch(loadSearchFilters(match.params.campaignId));
  },
});

const wrapWithReduxStore = connect(mapStateToProps, mapDispatchToProps);
export const CampaignTargetingWithReduxStore = wrapWithReduxStore(
  CampaignTargetingUnwrapped,
);
const CampaignTargeting = wrapWithCampaignAndProspectiveSponsorableProperties(
  CampaignTargetingWithReduxStore,
);

CampaignTargeting.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CampaignTargeting;
