import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { wrapWithUserId } from 'utils/authentication';
import CAMPAIGN_STATISTIC_FRAGMENT from 'fragments/CampaignStatisticFragment';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Loader from 'components/Loader';
import DelightedSurvey from 'components/DelightedSurvey';
import StagingBanner from 'components/StagingBanner';
import BrandBar from 'components/BrandBar';
import styled from 'styled-components';
import LeagueSideFooter from 'components/TeamSnapFooter';
import ErrorPage from 'components/reviewOffer/ErrorPage';
import CampaignDashboardOverview from './campaignDashboard/CampaignDashboardOverview';
import headerImage from './campaignDashboard/header.jpg';

const Header = styled.div`
  background-image: url(${headerImage});
`;

const Logo = styled.img`
  max-height: 33vh;
`;

export function CampaignDashboardUnwrapped({ campaignDashboard, userId }) {
  if (!campaignDashboard) return <Loader />;

  const { campaign } = campaignDashboard;

  return (
    <div className="deprecated-styles">
      <FieldhousePageTitle
        title={`${campaign.name} - ${campaign.sponsor.name}`}
      >
        <StagingBanner />
        <BrandBar brandHref="#" />
        {!userId /* do not survey logged in users (i.e. admins) */ && (
          <DelightedSurvey
            options={{
              name: campaign.sponsor.name,
              properties: {
                campaignId: campaign.id,
                page: 'Campaign Dashboard',
                sponsorId: campaign.sponsor.id,
              },
            }}
          />
        )}
        <Header className="text-center py-5 mb-5">
          {campaign.sponsor.logoFullSizeUrl ? (
            <Logo
              alt={`${campaign.sponsor.name}`}
              className="mw-100 mx-2"
              id="sponsor-logo"
              src={campaign.sponsor.logoFullSizeUrl}
            />
          ) : (
            <h1 id="sponsor-name">{campaign.sponsor.name}</h1>
          )}
          <h1 className="mt-4" id="campaign-name">
            {campaign.name}
          </h1>
        </Header>
        <CampaignDashboardOverview campaign={campaign} />
        <LeagueSideFooter />
      </FieldhousePageTitle>
    </div>
  );
}

CampaignDashboardUnwrapped.propTypes = {
  campaignDashboard: PropTypes.shape({
    campaign: LeagueSidePropTypes.campaign,
  }),
  userId: PropTypes.number,
};

CampaignDashboardUnwrapped.defaultProps = {
  campaignDashboard: null,
  userId: null,
};

export const query = gql`
  query CampaignDashboard {
    campaignDashboard {
      campaign {
        id
        season
        year
        campaignCapabilities {
          id
          capability {
            id
            name
          }
        }
        campaignGeometries {
          id
          geoJson
          label
        }
        campaignStatistics {
          ...CampaignStatisticFragment
        }
        defaultVerificationDeadlines {
          id
          creativeContentTemplates {
            id
            renderedContent
            name
          }
          creativeFileTemplates {
            id
            contentType
            fullSizeUrl
            name
            thumbnailUrl
          }
        }
        name
        offers(statuses: [confirmed]) {
          id
          assets {
            id
            campaignCapability {
              id
              capability {
                id
                name
              }
            }
            quantity
          }
          status
          sponsorableProperty {
            id
            minAge
            maxAge
            name
            numberOfPlayers
            playLocation {
              id
              address
              lat
              lon
            }
            sport {
              id
              name
            }
          }
          verificationImages(status: verified) {
            id
            featured
            capabilityName
            fullSizeUrl
            thumbnailUrl
          }
        }
        radii {
          label
          lat
          lon
          miles
        }
        sponsor {
          id
          logoFullSizeUrl
          name
        }
        sponsorDashboardAdditionalContent
        sponsorFacingGoalTitle
        sponsorFacingGoalBody
        sponsorFacingGoalImageUrl
        lookerUrl
        testimonials {
          id
          attribution
          quote
        }
        impressions
      }
    }
  }
  ${CAMPAIGN_STATISTIC_FRAGMENT}
`;

const CampaignDashboardUnwrappedWithUserId = wrapWithUserId(
  CampaignDashboardUnwrapped,
);

const CampaignDashboard = ({ match }) => (
  <Query
    context={{ headers: { Authorization: `Bearer ${match.params.token}` } }}
    fetchPolicy="network-only"
    query={query}
  >
    {({ data, error }) => {
      if (error) return <ErrorPage status="expired link" />;

      return (
        <CampaignDashboardUnwrappedWithUserId
          campaignDashboard={data && data.campaignDashboard}
        />
      );
    }}
  </Query>
);

const matchType = PropTypes.shape({
  params: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
});

CampaignDashboard.propTypes = {
  match: matchType.isRequired,
};

export default CampaignDashboard;
