import { ascend, descend, map, path, prop, sortWith } from 'ramda';
import { formatToDollar } from 'utils/formatNumber';
import { OFFER_PREFIX } from 'utils/paths';
import type { Offer } from 'types/offer';
import Card from 'components/Card';
import SponsorshipInsightReport from 'components/SponsorshipInsightReport';

type ConfirmedSponsorshipsCardProps = {
  offer: Offer;
};

function ConfirmedSponsorshipsCard(props: ConfirmedSponsorshipsCardProps) {
  const { offer } = props;
  const { sponsorableProperty } = offer;
  const { organization } = sponsorableProperty;
  const { sponsorshipInsightReport, confirmedOffers } = organization;

  return (
    <Card data-test="confirmed-sponsorships">
      <h2 className="text-nowrap">
        Confirmed Sponsorships
        <span className="float-right mb-2">
          <SponsorshipInsightReport
            sponsorshipInsightReport={sponsorshipInsightReport}
          />
        </span>
      </h2>
      {confirmedOffers.length ? (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Sponsorships</th>
              <th scope="col">Value</th>
            </tr>
          </thead>
          <tbody>{map(renderRow, sortOffers(confirmedOffers))}</tbody>
        </table>
      ) : (
        <p className="text-center">
          This sponsorable property has no confirmed offers!
        </p>
      )}
    </Card>
  );
}

export default ConfirmedSponsorshipsCard;

const renderRow = (offer: any) => {
  const { name, sponsor } = offer.campaign;

  return (
    <tr key={offer.id}>
      <td data-test="sponsor">
        <a data-test="league-name" href={`${OFFER_PREFIX}/${offer.id}`}>
          {`${sponsor.name} - ${name}`}
        </a>
      </td>
      <td data-test="value">{formatToDollar(offer.value)}</td>
    </tr>
  );
};

const sortOffers = sortWith([
  descend(prop('updatedAt')),
  ascend(path(['campaign', 'sponsor', 'name'])),
]);
