import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

class OrganizationSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: '' };
    this.setValue = (event) => this.setState({ value: event.target.value });
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    const { props, state } = this;
    props.onSubmit(state.value);
  }

  render() {
    const { state } = this;

    const label = 'Find organizations by name, email, or website url';

    return (
      <form id="organizationSearchForm" onSubmit={this.onSubmit}>
        <div className="input-group mb-4">
          <input
            type="text"
            className="form-control form-control-lg"
            placeholder={label}
            aria-label={label}
            value={state.value}
            onChange={this.setValue}
          />
          <div className="input-group-append">
            <button className="btn btn-primary btn-lg" type="submit">
              <Icon value="magnifying-glass" />
            </button>
          </div>
        </div>
      </form>
    );
  }
}

OrganizationSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default OrganizationSearchForm;
