import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { compose, pick, prop } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import RestrictTo from 'components/RestrictTo';
import AddOrganizationModal from './organizations/AddOrganizationModal';
import OrganizationSearchForm from './organizations/OrganizationSearchForm';
import OrganizationsResults from './organizations/OrganizationsResults';

class OrganizationsUnwrapped extends Component {
  constructor() {
    super();

    this.state = {
      query: '',
      searched: false,
    };

    this.search = (query) => this.setState({ query, searched: true });
  }

  render() {
    const { props, state } = this;
    const { count } = props.organizationStats;

    return (
      <FieldhousePageTitle title="All Organizatons">
        <Page>
          <RestrictTo roles={['admin']}>
            {!!count && (
              <>
                <div className="row mt-4 mb-4">
                  <div className="col">
                    <h1>{`Search ${count} Organizations`}</h1>
                  </div>
                  <div className="col-auto">
                    <AddOrganizationModal />
                  </div>
                </div>
                <OrganizationSearchForm onSubmit={this.search} />
                <OrganizationsResults
                  query={state.query}
                  searched={state.searched}
                />
              </>
            )}
          </RestrictTo>
        </Page>
      </FieldhousePageTitle>
    );
  }
}

OrganizationsUnwrapped.propTypes = {
  organizationStats: PropTypes.shape({
    count: PropTypes.number,
  }),
};

OrganizationsUnwrapped.defaultProps = {
  organizationStats: {},
};

const getOrganizationStats = graphql(
  gql`
    query OrganizationStats {
      organizationStats {
        count
      }
    }
  `,
  {
    options: () => ({
      fetchPolicy: 'network-only',
    }),
    props: compose(pick(['organizationStats']), prop('data')),
  },
);

export default getOrganizationStats(OrganizationsUnwrapped);
