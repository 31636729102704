import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { pluralizedTense } from 'utils/string';
import NestedProgressBar from './NestedProgressBar';

const reportCategory = (label, value) => (
  <div className="col d-none d-lg-flex align-self-center text-center text-uppercase text-nowrap">
    <small>
      <strong>{value}</strong>
      <div>{label}</div>
    </small>
  </div>
);

const ProspectingReportTotalProgressBar = ({
  displayDetails,
  prospectingReportId,
  cable,
}) => {
  const INITIAL_STATE = {
    available: 0,
    prospecting: 0,
    qualified: 0,
    proposed: 0,
    confirmed: 0,
    target: 0,
    lastTouchpoint: null,
  };
  const [prospectingReport, setProspectingReport] = useState(INITIAL_STATE);

  useEffect(() => {
    const subscription = cable.subscriptions.create(
      { channel: 'ProspectingReportChannel', id: prospectingReportId },
      {
        received(subscriptionData) {
          setProspectingReport(subscriptionData);
        },
      },
    );

    return function cleanup() {
      cable.subscriptions.remove(subscription);
    }; // eslint-disable-next-line
  }, []);

  if (!prospectingReport) {
    return null;
  }
  const { available, prospecting, qualified, proposed, confirmed, target } =
    prospectingReport;

  return (
    <>
      <NestedProgressBar
        confirmed={confirmed}
        maximum={target}
        pending={prospecting + qualified + proposed}
      />
      <div
        className="row text-nowrap mt-1"
        data-test="prospecting-report-total-progress-bar"
      >
        {displayDetails && (
          <>
            {reportCategory('Available', available)}
            {reportCategory(
              `${pluralizedTense(prospecting, 'Prospect')}`,
              prospecting,
            )}
            {reportCategory('Qualified', qualified)}
            {reportCategory('Proposed', proposed)}
          </>
        )}
        <div className="col align-self-center text-right">
          <small>
            <strong>{`${confirmed} of ${target} Confirmed`}</strong>
          </small>
        </div>
      </div>
    </>
  );
};

ProspectingReportTotalProgressBar.propTypes = {
  displayDetails: PropTypes.bool,
  prospectingReportId: PropTypes.number.isRequired,
  cable: PropTypes.shape.isRequired,
};

ProspectingReportTotalProgressBar.defaultProps = {
  displayDetails: true,
};

export default ProspectingReportTotalProgressBar;
