import gql from 'graphql-tag';

const COMMENT_FRAGMENT = gql`
  fragment CommentFragment on Comment {
    id
    content
    createdAt
    user {
      name
    }
  }
`;

export default COMMENT_FRAGMENT;
