export default class OrganizationOfferPresenter {
  constructor(apiService) {
    this.apiService = apiService;
  }

  submitVerificationImageFromLeagueUser(deadlineId, image) {
    return this.apiService.createVerificationImageWithDeadline(
      deadlineId,
      image,
      false,
      'Organization Dashboard',
      'pending',
    );
  }
}
