/* eslint-disable jsx-a11y/alt-text */
import PhotosGrid from 'pages/seasonalDashboard/PhotosGrid';
import React from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';

const buildVerificationImages = (offers) => {
  const verificationImages = [];

  for (let i = 0; i < offers.length; i += 1) {
    if (offers[i].verificationImages.length) {
      for (let j = 0; j < offers[i].verificationImages.length; j += 1) {
        if (offers[i].verificationImages[j].featured === true) {
          verificationImages.push(offers[i].verificationImages[j]);
        }
      }
    }
  }

  return verificationImages;
};
function CampaignDashboardFeaturedPhoto({ campaign }) {
  const { offers } = campaign;
  const allVerificationImages = offers ? buildVerificationImages(offers) : [];

  return allVerificationImages.length ? (
    <>
      <div className="text-center">
        <h2 className="mb-4">Featured Photo</h2>
        <PhotosGrid images={allVerificationImages} />
      </div>
      <hr className="my-5" />
    </>
  ) : null;
}

CampaignDashboardFeaturedPhoto.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignDashboardFeaturedPhoto;
