import * as React from 'react';
import { getExtension } from 'utils/string';
import NewWindowLink from 'components/NewWindowLink';
import Icon from 'components/Icon';
import ImageFallback from 'components/ImageFallback';
import PreviewCard from 'components/PreviewCard';
import { addCloudinaryAutoFormat } from 'utils/image';

interface Props extends React.HTMLAttributes<HTMLElement> {
  border?: string;
  cardId: string;
  fullSizeUrl: string;
  header?: JSX.Element;
  imgAlt: string;
  thumbnailUrl?: string;
}

const ImageCard: React.FunctionComponent<Props> = ({
  border,
  cardId,
  children,
  fullSizeUrl,
  header,
  imgAlt,
  thumbnailUrl,
}: Props) => (
  <PreviewCard
    border={border}
    cardId={cardId}
    footer={children}
    header={header}
  >
    <NewWindowLink data-test="image-full-size-link" hideIcon to={fullSizeUrl}>
      <ImageFallback
        alt={imgAlt}
        fallback={
          <>
            {`.${getExtension(fullSizeUrl)}`}
            <Icon className="ml-2" value="download" />
          </>
        }
        imgClassName="img-thumbnail"
        src={addCloudinaryAutoFormat(thumbnailUrl)}
      />
    </NewWindowLink>
  </PreviewCard>
);

ImageCard.defaultProps = {
  border: undefined,
  header: undefined,
  thumbnailUrl: undefined,
};

export default ImageCard;
