import * as React from 'react';
import SelectInput from './SelectInput';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  required?: boolean;
  visibility: 'true' | 'false';
}

const VisibilityInput: React.FC<Props> = ({
  disabled,
  onChange,
  required,
  visibility,
}: Props) => (
  <SelectInput
    label="Visibility"
    disabled={disabled}
    labelClassName="font-weight-bold"
    noDefault
    onChange={onChange}
    required={required}
    value={visibility}
  >
    <option value="false">Visible To Sponsor</option>
    <option value="true">Hidden From Sponsor</option>
  </SelectInput>
);

VisibilityInput.defaultProps = {
  disabled: undefined,
  required: undefined,
};

export default VisibilityInput;
