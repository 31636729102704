import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import classNames from 'classnames';
import styled from 'styled-components';

const Label = styled.label`
  :focus-within {
    /* copied from btn-success:focus */
    -webkit-box-shadow: 0 0 0 0.2rem rgba(68, 119, 93, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(68, 119, 93, 0.5);
  }
`;

const CheckboxButton = ({ children, checked, id, onChange }) => (
  <div className="btn-group-toggle mb-3 mr-3">
    <Label
      className={classNames('btn btn-lg w-100', {
        'btn-primary': checked,
        'btn-outline-primary': !checked,
      })}
      htmlFor={id}
    >
      <input checked={checked} id={id} onChange={onChange} type="checkbox" />
      {checked && <Icon className="mr-2" value="check" />}
      {children}
    </Label>
  </div>
);

CheckboxButton.propTypes = {
  children: PropTypes.node,
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CheckboxButton.defaultProps = {
  children: null,
  checked: false,
  onChange: () => {},
};

export default CheckboxButton;
