import * as React from 'react';
import { missingNeededShippingAddress } from 'utils/organization';
import Alert from 'components/Alert';
import OrganizationAddressModalToggle from 'components/OrganizationAddressModalToggle';

interface Capability {
  digital: boolean;
}
interface CampaignCapability {
  capability: Capability;
}
interface Asset {
  campaignCapability: CampaignCapability;
  quantity: number;
}
interface Offer {
  assets: Asset[];
}
interface Organization {
  id: number;
  mailingAddress?: any;
  shippingAddress?: any;
}

interface Props {
  organization: Organization;
  offers: Offer[];
}

const MissingAddressAlert: React.FunctionComponent<Props> = ({
  organization,
  offers,
}: Props) => {
  const missingMailingAddress = !organization.mailingAddress;
  const missingShippingAddress = missingNeededShippingAddress(
    organization,
    offers,
  );

  return missingMailingAddress || missingShippingAddress ? (
    <Alert
      alertStyle="warning"
      className="mb-2"
      data-test="missing-address-alert"
    >
      {missingMailingAddress &&
        'We cannot send the sponsorship check until we have a mailing address on file. '}
      {missingShippingAddress &&
        'We cannot ship necessary sponsorship supplies until we have a shipping address on file. '}
      Please use the&nbsp;
      <OrganizationAddressModalToggle
        organizationId={organization.id}
        toggleClassName="btn btn-link btn-inline-link"
      />
      &nbsp;button to update addresses.
    </Alert>
  ) : null;
};

export default MissingAddressAlert;
