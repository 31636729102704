/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

class DelightedSurvey extends React.Component {
  componentDidMount() {
    const { delighted } = global;
    const { survey } = delighted;
    const { options } = this.props;

    survey(options);
  }

  render() {
    return null;
  }
}

DelightedSurvey.propTypes = {
  options: PropTypes.object,
};

DelightedSurvey.defaultProps = {
  options: null,
};

export default DelightedSurvey;
