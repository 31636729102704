import React from 'react';
import { GeoJSON } from 'react-leaflet';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { SUCCESS } from './colors';
import LabeledTooltip from './LabeledTooltip';

const MapGeoJSON = ({ geometry }) => (
  <GeoJSON
    data={geometry.geoJson}
    style={{
      color: SUCCESS,
      fillOpacity: 1,
    }}
  >
    <LabeledTooltip text={geometry.label} />
  </GeoJSON>
);

MapGeoJSON.propTypes = {
  geometry: LeagueSidePropTypes.campaignGeometry.isRequired,
};

export default MapGeoJSON;
