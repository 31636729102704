import * as React from 'react';
import ResendUserInviteModal from 'components/organizationSections/usersTab/ResendUserInviteModal';
import { User } from 'types/user';

interface Props {
  additionalColumns?(user: User): JSX.Element;
  user: User;
}

const UsersTableRow: React.FunctionComponent<Props> = ({
  additionalColumns,
  user,
}: Props) => (
  <tr data-test={`users-table-row-${user.id}`}>
    <td>{user.email}</td>
    <td>{user.name}</td>
    <td>{user.title}</td>
    <td>{user.cellPhone}</td>
    <td className="text-capitalize">
      {user.status}
      {user.status === 'invited' ? (
        <ResendUserInviteModal id={user.id} email={user.email} />
      ) : null}
    </td>
    {!!additionalColumns && additionalColumns(user)}
  </tr>
);

UsersTableRow.defaultProps = {
  additionalColumns: undefined,
};

export default UsersTableRow;
