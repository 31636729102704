import * as React from 'react';
import { getInputId } from 'utils/id';

interface Props extends React.HTMLAttributes<HTMLElement> {
  checked: boolean;
  disabled?: boolean;
  label: string;
  prefix?: string;
}

const CheckboxInput: React.FunctionComponent<Props> = ({
  checked,
  disabled,
  label,
  onChange,
  prefix,
}: Props) => {
  const id = getInputId([prefix, label]);

  return (
    <div className="form-check my-3">
      <input
        checked={checked}
        className="form-check-input"
        id={id}
        onChange={onChange}
        type="checkbox"
        disabled={disabled}
      />
      <label className="form-check-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

CheckboxInput.defaultProps = {
  disabled: false,
  prefix: undefined,
};

export default CheckboxInput;
