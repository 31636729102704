import { push } from 'react-router-redux';
import { createReducer } from 'redux-create-reducer';
import jwtDecode from 'jwt-decode';
import { prop, path } from 'ramda';
import { parseQueryString } from 'utils/string';
import { setUserContext } from 'utils/tracker';
import { convertToUnix } from 'utils/formatDate';
import api from 'utils/leagueside-api';

export const AUTHENTICATION_TOKEN_REQUESTED =
  'authentication/authentication_token_requested';
export const RECEIVE_AUTHENTICATION_TOKEN =
  'authentication/receive_authentication_token';
export const AUTHENTICATION_FAILED = 'authentication/authentication_failed';
export const LOGOUT = 'authentication/logout';

const initialToken = localStorage.getItem('token');

export function decodeClaims(token) {
  const claims = jwtDecode(token);
  const fsId = `${claims.role}:${claims.sub}`;
  const userId = String(claims.sub);
  const createdAtToUnix = convertToUnix(claims.created_at);

  setUserContext(userId, claims.email);

  global.FS.identify(fsId, {
    displayName: fsId,
  });

  if (!window.Cypress && global.userpilot) {
    global.userpilot.identify(userId, {
      name: claims.name,
      email: claims.email,
      created_at: createdAtToUnix,
      role: claims.role,
    });
  }

  return claims;
}

const decodeInitialClaims = () =>
  initialToken ? decodeClaims(initialToken) : null;

const initialState = {
  isLoggingIn: false,
  token: initialToken,
  claims: decodeInitialClaims(),
};

export default createReducer(initialState, {
  [AUTHENTICATION_TOKEN_REQUESTED](state) {
    return {
      ...state,
      isLoggingIn: true,
      message: undefined,
    };
  },
  [RECEIVE_AUTHENTICATION_TOKEN](state, action) {
    return {
      ...state,
      isLoggingIn: false,
      token: action.token,
      claims: action.claims,
    };
  },
  [AUTHENTICATION_FAILED](state, action) {
    return {
      ...state,
      isLoggingIn: false,
      message: action.message,
    };
  },
  [LOGOUT](state, action) {
    return {
      ...state,
      token: null,
      claims: null,
      message: action.message,
    };
  },
});

export function receiveAuthenticationToken(token) {
  const claims = decodeClaims(token);
  localStorage.setItem('token', token);

  return {
    type: RECEIVE_AUTHENTICATION_TOKEN,
    token,
    claims,
  };
}

export function authenticate(email, password) {
  return (dispatch, getState) => {
    dispatch({
      type: AUTHENTICATION_TOKEN_REQUESTED,
    });

    return api.authenticate(email, password).then(
      (response) => {
        if (response.message) {
          dispatch({
            type: AUTHENTICATION_FAILED,
            message: response.message,
          });

          return;
        }

        dispatch(receiveAuthenticationToken(response.jwt));

        const state = getState();
        const { search } = state.routing.location;
        const { redirect } = parseQueryString(search);
        const nextPath = redirect ? decodeURIComponent(redirect) : '/';

        dispatch(push(nextPath));
      },
      () => {
        // TODO: LOGGING
        dispatch({
          type: AUTHENTICATION_FAILED,
          message: 'Unknown error. Please contact support.',
        });
      },
    );
  };
}

// Checks if given path name has a valid token
function pathNameHasToken(pathname) {
  const tokenMatch = pathname.match(
    /([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_=]+)\.([a-zA-Z0-9_\-\+\/=]*)/, // eslint-disable-line
  );

  try {
    return jwtDecode(tokenMatch[0]) && true;
  } catch (InvalidTokenError) {
    return false;
  }
}

// TODO: Undo comments when fixing redirect logic after login.
// The correct way should be to keep the redirect query after login and go to that page and not just flush out url.
function getRedirectPath(location) {
  // const { pathname, search, hash } = location;
  const { pathname } = location;

  if (pathname.startsWith('/login')) return { redirect: null };
  if (pathname === '/logout') return { redirect: '/login' };
  if (pathNameHasToken(pathname)) {
    return {
      redirect: '/login',
      message:
        'Your link has expired, please contact support at support@sponsorship.teamsnap.com.',
    };
  }
  // return `/login?redirect=${encodeURIComponent(`${pathname}${search}${hash}`)}`;
  return { redirect: '/login' };
}

export const logout = () => (dispatch, getState) => {
  const state = getState();
  const { redirect, message } = getRedirectPath(state.routing.location);

  localStorage.removeItem('token');
  dispatch({ type: LOGOUT, message });
  if (redirect) dispatch(push(redirect));
};
// END

export const getToken = prop('token');
export const getMessage = prop('message');
export const getIsLoggingIn = prop('isLoggingIn');
export const getRole = path(['claims', 'role']);
export const getUserId = path(['claims', 'sub']);
