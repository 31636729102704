import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { equals, isEmpty, map, mergeAll } from 'ramda';
import gql from 'graphql-tag';
import moment from 'moment';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import {
  formatDateInput,
  ONE_YEAR_AFTER,
  ONE_YEAR_BEFORE,
} from 'utils/formatDate';
import { useSubmit } from 'utils/hooks';
import { getId } from 'utils/id';
import { findById } from 'utils/misc';
import { capitalize, conjugateString } from 'utils/string';
import { seasons } from 'types/season';
import ModalForm from 'components/ModalForm';
import NameInput from 'components/formInputs/NameInput';
import SelectInput from 'components/formInputs/SelectInput';
import Icon from 'components/Icon';
import DateRangePickerWrapper from 'components/DateRangePickerWrapper';
import MultiSelect from 'components/MultiSelect';
import { RequiredAsterisk } from 'components/RequiredLabel';
import SPONSORABLE_PROPERTY_FRAGMENT from './SponsorablePropertyFragment';

const COMPETITIVENESSES_AND_SPORTS_QUERY = gql`
  query CompetitivenessesAndSports {
    competitivenesses {
      id
      name
    }
    sports {
      id
      name
    }
  }
`;

const MUTATION = gql`
  mutation UpdateSponsorableProperty($input: UpdateSponsorablePropertyInput!) {
    updateSponsorableProperty(input: $input) {
      organization {
        id
        sponsorableProperties {
          ...ProgramDetailsSponsorablePropertyFragment
        }
      }
    }
  }
  ${SPONSORABLE_PROPERTY_FRAGMENT}
`;

const UpdateSponsorablePropertyModal = ({
  organizationPlayLocations,
  sponsorableProperty,
}) => {
  const { data: competitivenessesAndSportsData } = useQuery(
    COMPETITIVENESSES_AND_SPORTS_QUERY,
  );
  const competitivenesses =
    competitivenessesAndSportsData &&
    competitivenessesAndSportsData.competitivenesses;
  const sports =
    competitivenessesAndSportsData && competitivenessesAndSportsData.sports;

  const { id: sponsorablePropertyId, organization } = sponsorableProperty;
  const organizationId = organization ? organization.id : null;
  const organizationName = organization ? organization.name : null;

  const [mutate] = useMutation(MUTATION);
  const onSubmit = (input) =>
    mutate({
      variables: {
        input: mergeAll([
          input,
          organizationId ? { organizationId } : null,
          sponsorablePropertyId ? { id: sponsorablePropertyId } : null,
        ]),
      },
    });

  const organizationPlayLocationsModel = organizationPlayLocations.map(
    (organizationPlayLocation) => {
      // eslint-disable-next-line no-param-reassign
      organizationPlayLocation.playLocation.name =
        organizationPlayLocation.playLocation.address;
      return organizationPlayLocation.playLocation;
    },
  );

  const [name, setName] = useState(
    sponsorableProperty.name || organizationName,
  );
  const [season, setSeason] = useState(sponsorableProperty.season);
  const [sportId, setSportId] = useState(
    sponsorableProperty.sport ? sponsorableProperty.sport.id : null,
  );
  const [competitivenessId, setCompetitivenessId] = useState(
    sponsorableProperty.competitiveness
      ? sponsorableProperty.competitiveness.id
      : null,
  );
  const [numberOfPlayers, setNumberOfPlayers] = useState(
    sponsorableProperty.numberOfPlayers,
  );
  const [minAge, setMinAge] = useState(sponsorableProperty.minAge);
  const [maxAge, setMaxAge] = useState(sponsorableProperty.maxAge);
  const [female, setFemale] = useState(sponsorableProperty.female);
  const [male, setMale] = useState(sponsorableProperty.male);

  const [startDateHook, setStartDateHook] = useState(
    sponsorableProperty.startDate
      ? moment(sponsorableProperty.startDate)
      : null,
  );
  const [endDateHook, setEndDateHook] = useState(
    sponsorableProperty.endDate ? moment(sponsorableProperty.endDate) : null,
  );
  const [focusedInput, setFocusedInput] = useState(null);
  // eslint-disable-next-line max-len
  const [selectedPlayLocationsIds, setSelectedPlayLocationsIds] = useState([]);
  const [primaryPlayLocationId, setPrimaryPlayLocationId] = useState(
    sponsorableProperty.playLocation
      ? sponsorableProperty.playLocation.id
      : null,
  );

  const selectedSport = sports && findById(Number(sportId))(sports);
  const selectedCompetitiveness =
    competitivenesses && findById(Number(competitivenessId))(competitivenesses);
  const [playLocations, setPlayLocations] = useState(
    sponsorableProperty.playLocations ? sponsorableProperty.playLocations : [],
  );

  useEffect(() => {
    if (playLocations) {
      playLocations.forEach((playLocation) => {
        setSelectedPlayLocationsIds((prevArray) => [
          ...prevArray,
          playLocation.id,
        ]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSponsorablePropertyPlayLocations = (playLocationIds) => {
    const newPlayLocations = [];
    organizationPlayLocations.forEach((location) =>
      playLocationIds.forEach((id) => {
        if (location.playLocation.id === id) {
          newPlayLocations.push(location.playLocation);
        }
      }),
    );
    setPlayLocations(newPlayLocations);
  };

  const handlePlayLocations = (playLocationIds) => {
    // eslint-disable-next-line max-len
    const playLocationIdsHavePrimaryPlayLocation = playLocationIds.filter(
      (playLocationId) => playLocationId === primaryPlayLocationId,
    );

    if (!equals(playLocationIds, selectedPlayLocationsIds)) {
      if (isEmpty(playLocationIds)) {
        setPrimaryPlayLocationId(null);
      } else if (isEmpty(playLocationIdsHavePrimaryPlayLocation)) {
        setPrimaryPlayLocationId(playLocationIds[0]);
      }
      updateSponsorablePropertyPlayLocations(playLocationIds);
      setSelectedPlayLocationsIds(playLocationIds);
    }
  };

  // eslint-disable-next-line no-undef
  const showAdditionalInputs =
    season && sportId && competitivenessId && selectedPlayLocationsIds.length;
  const requiredInputsPresent = showAdditionalInputs && name;

  async function asyncSubmit() {
    await onSubmit({
      name,
      startDate: formatDateInput(startDateHook),
      endDate: formatDateInput(endDateHook),
      female,
      male,
      minAge,
      maxAge,
      numberOfPlayers,
      season,
      sportId,
      competitivenessId,
      playLocationsIds: selectedPlayLocationsIds,
      primaryPlayLocationId: Number(primaryPlayLocationId),
    });
  }

  const handleSeasonChange = ({ startDate, endDate }) => {
    setStartDateHook(startDate);
    setEndDateHook(endDate);
  };

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const label = 'update';
  const submitLabel = capitalize(label);
  const prefix = getId([label, 'sponsorable-property', sponsorablePropertyId]);

  const divStyle = {
    display: 'inline-block',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
    width: '4.5rem',
  };

  const renderAdditionalInputs = () => (
    <>
      <div className="form-group">
        <label htmlFor={`${prefix}-number-of-players-input`}>
          Number of players in
          <strong>
            {` ${season} ${selectedCompetitiveness.name.toLowerCase()} ${selectedSport.name.toLowerCase()}`}
          </strong>
        </label>
        <input
          aria-required
          className="form-control form-control-lg"
          disabled={loading}
          onChange={({ target: { value } }) =>
            setNumberOfPlayers(Number(value))
          }
          id={`${prefix}-number-of-players-input`}
          type="number"
          value={numberOfPlayers}
        />
      </div>
      <div className="row">
        <div className="col">
          <strong>Age Range</strong>
          <>
            <p className="text-nowrap">
              <input
                className="form-control mr-2"
                id="minAge"
                onChange={({ target: { value } }) => setMinAge(Number(value))}
                placeholder="Min"
                style={divStyle}
                type="number"
                value={minAge}
              />
              -
              <input
                className="form-control ml-2"
                id="maxAge"
                onChange={({ target: { value } }) => setMaxAge(Number(value))}
                placeholder="Max"
                style={divStyle}
                type="number"
                value={maxAge}
              />
            </p>
          </>
        </div>
        <div className="col">
          <strong>Genders</strong>
          <div className="form-check">
            <input
              checked={female}
              className="form-check-input"
              id="female"
              onChange={(event) => setFemale(event.target.checked)}
              type="checkbox"
            />
            <label className="form-check-label" htmlFor="female">
              Female
            </label>
          </div>
          <div className="form-check">
            <input
              checked={male}
              className="form-check-input"
              id="male"
              onChange={(event) => setMale(event.target.checked)}
              type="checkbox"
            />
            <label className="form-check-label" htmlFor="male">
              Male
            </label>
          </div>
        </div>
      </div>
      <label htmlFor={`${prefix}-dates`}>
        First and last games dates for
        <strong>
          {` ${season} ${selectedCompetitiveness.name.toLowerCase()} ${selectedSport.name.toLowerCase()}`}
        </strong>
      </label>
      <DateRangePickerWrapper
        endDate={endDateHook}
        focusedInput={focusedInput}
        onDatesChange={handleSeasonChange}
        isOutsideRange={(date) =>
          date.isBefore(ONE_YEAR_BEFORE) || date.isAfter(ONE_YEAR_AFTER)
        }
        onFocusChange={setFocusedInput}
        startDate={startDateHook}
      />
    </>
  );

  return competitivenessesAndSportsData ? (
    <ModalForm
      id={`${prefix}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: conjugateString(submitLabel, loading),
        disabled: !requiredInputsPresent || loading,
      }}
      title={`${submitLabel} Sponsorable Property`}
      toggleProps={{
        className: 'btn btn-link p-0',
        children: (
          <>
            <Icon value="pencil" className="mr-2" />
          </>
        ),
      }}
    >
      <NameInput
        isRequired
        loading={loading}
        name={name}
        onChange={({ target: { value } }) => setName(value)}
        prefix={prefix}
      />
      <SelectInput
        formGroupClassName="font-weight-bold"
        disabled={loading}
        label="Season"
        onChange={({ target: { value } }) => setSeason(value)}
        prefix={prefix}
        required
        value={season || ''}
      >
        {map(
          (type) => (
            <option key={type} value={type}>
              {capitalize(type)}
            </option>
          ),
          seasons,
        )}
      </SelectInput>
      <SelectInput
        formGroupClassName="font-weight-bold"
        disabled={loading}
        label="Sport"
        onChange={({ target: { value } }) => setSportId(Number(value))}
        prefix={prefix}
        required
        value={selectedSport ? selectedSport.id : ''}
      >
        {map(
          (sport) => (
            <option key={sport.id} value={sport.id}>
              {sport.name}
            </option>
          ),
          sports,
        )}
      </SelectInput>
      <SelectInput
        formGroupClassName="font-weight-bold"
        disabled={loading}
        label="Competitiveness"
        onChange={({ target: { value } }) =>
          setCompetitivenessId(Number(value))
        }
        prefix={prefix}
        required
        value={selectedCompetitiveness ? selectedCompetitiveness.id : ''}
      >
        {map(
          (competitiveness) => (
            <option key={competitiveness.id} value={competitiveness.id}>
              {competitiveness.name}
            </option>
          ),
          competitivenesses,
        )}
      </SelectInput>
      <label htmlFor="PlayLocations">
        <strong>Play Locations(s)</strong>
        <RequiredAsterisk />
      </label>
      <MultiSelect
        id="PlayLocations"
        onChange={handlePlayLocations}
        models={organizationPlayLocationsModel}
        selectedIds={selectedPlayLocationsIds}
        disabled={loading}
      />
      {primaryPlayLocationId ? (
        <SelectInput
          formGroupClassName="font-weight-bold"
          disabled={loading}
          label="Primary Play Location"
          onChange={({ target: { value } }) => setPrimaryPlayLocationId(value)}
          prefix={prefix}
          required
          value={primaryPlayLocationId}
        >
          {map(
            (playLocation) => (
              <option key={playLocation.id} value={playLocation.id}>
                {playLocation.address}
              </option>
            ),
            playLocations,
          )}
        </SelectInput>
      ) : null}
      {showAdditionalInputs ? renderAdditionalInputs() : null}
    </ModalForm>
  ) : null;
};

UpdateSponsorablePropertyModal.propTypes = {
  sponsorableProperty: PropTypes.shape({
    id: PropTypes.number,
    competitiveness: LeagueSidePropTypes.competitiveness,
    endDate: PropTypes.string,
    female: PropTypes.bool,
    male: PropTypes.bool,
    minAge: PropTypes.number,
    maxAge: PropTypes.number,
    name: PropTypes.string,
    numberOfPlayers: PropTypes.number,
    season: PropTypes.string,
    sport: LeagueSidePropTypes.sport,
    startDate: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    playLocation: PropTypes.shape({
      id: PropTypes.number,
      address: PropTypes.string,
    }),
    playLocations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        address: PropTypes.string,
      }),
    ),
  }).isRequired,
  organizationPlayLocations: PropTypes.arrayOf(
    LeagueSidePropTypes.organizationPlayLocation,
  ).isRequired,
};

export default UpdateSponsorablePropertyModal;
