import * as React from 'react';
import Dropddown from 'components/Dropdown';

interface Props extends React.HTMLAttributes<HTMLElement> {
  deadlineId: number;
}

const DeadlineActionsDropdown: React.FunctionComponent<Props> = ({
  children,
  deadlineId,
}: Props) => (
  <Dropddown
    menuProps={{
      className: 'dropdown-menu dropdown-menu-right m-0 py-1',
    }}
    toggleProps={{
      className: 'btn btn-link dropdown-toggle p-0',
      'data-test': `deadline-${deadlineId}-actions-dropdown`,
    }}
  >
    {children}
  </Dropddown>
);

export default DeadlineActionsDropdown;
