import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Play from './Play';

function PlaySports({ addChanges, league, sports }) {
  return (
    <Play
      addChanges={addChanges}
      league={league}
      names="sport"
      collections={sports}
      question="What sports does your league play?"
    />
  );
}

PlaySports.propTypes = {
  addChanges: PropTypes.func.isRequired,
  sports: PropTypes.arrayOf(LeagueSidePropTypes.sport).isRequired,
  league: LeagueSidePropTypes.league.isRequired,
};

export default PlaySports;
