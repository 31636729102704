import { useState } from 'react';
import Icon from 'components/Icon';
import gql from 'graphql-tag';
import { history } from 'store';
import { useMutation } from 'react-apollo';
import hideModal from 'utils/hideModal';
import { ApolloError } from 'apollo-client';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import ErrorAlert from 'components/ErrorAlert';
import type { Organization } from 'types/offer';

type OrganizationRemoveModalProps = {
  organization: Organization;
};

const elementId = 'organization-remove-modal';

export default function OrganizationRemoveModal(
  props: OrganizationRemoveModalProps,
) {
  const { organization } = props;
  const [performDeleteOrganization, { loading }] = useMutation(
    DELETE_ORGANIZATION_MUTATION,
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleConfirmClick = async () => {
    setErrorMessage(null);
    try {
      await performDeleteOrganization({ variables: { id: organization.id } });
      hideModal(`#${elementId}`);
      history.push('/');
    } catch (error) {
      if (error instanceof ApolloError) {
        setErrorMessage(error.graphQLErrors[0]?.message);
      } else {
        setErrorMessage('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <Modal id={elementId} title="Are you sure?">
      <ModalBody>
        <p>
          Are you sure you want to remove&nbsp;
          <strong className="font-weight-bold">{organization.name}</strong>?
        </p>
        {organization.users.length > 0 && (
          <p>There are {organization.users.length} user(s) associated.</p>
        )}
        {organization.engagementStatus === 'active' && (
          <p>This organization is active.</p>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-danger"
          data-test={`${elementId}-submit`}
          onClick={handleConfirmClick}
          disabled={loading}
        >
          {loading && 'Deleting...'}
          {!loading && 'Delete Organization'}
        </button>
      </ModalFooter>
      {errorMessage && (
        <div className="px-3">
          <ErrorAlert data-test="organization-remove-modal-error-message">
            {errorMessage}
          </ErrorAlert>
        </div>
      )}
    </Modal>
  );
}

// TODO: Move this button outside of this component in a future refactor
const OrganizationRemoveModalToggle = () => (
  <button
    type="button"
    className="btn btn-outline-danger"
    data-toggle="modal"
    data-target={`#${elementId}`}
    id="modal-show-button"
  >
    <Icon className="mr-1" value="trash" />
    Delete Organization
  </button>
);
OrganizationRemoveModal.Toggle = OrganizationRemoveModalToggle;

export const DELETE_ORGANIZATION_MUTATION = gql`
  mutation DeleteOrganization($id: Int!) {
    deleteOrganization(id: $id)
  }
`;
