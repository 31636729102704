import gql from 'graphql-tag';
import { REMOVED_OFFERS_QUERY_ARGS } from 'utils/queryArgs';
import LOCATION_FRAGMENT from 'fragments/LocationFragment';
import {
  PROSPECTING_OFFER_FRAGMENT,
  QUALIFIED_OFFER_FRAGMENT,
  PROPOSED_OFFER_FRAGMENT,
  CONFIRMED_OFFER_FRAGMENT,
  REMOVED_OFFER_FRAGMENT,
} from 'fragments/OfferFragment';
import { AVAILABLE_SPONSORABLE_PROPERTY_FRAGMENT } from 'fragments/SponsorablePropertyFragment';

const PROSPECT_FILTER_QUERY = gql`
  query LocationFilterPage($id: Int!, $withLatLon: Boolean = true) {
    locationFilter(id: $id) {
      id
      enabled
      prospectingReportId
      name
      radius
      strategy
      strategyCount
      ... on PerLocationStrategyLocationFilter {
        locations {
          ...LocationFragment
          geoJson
          prospectingReportId
        }
      }
      ... on TotalStrategyLocationFilter {
        locations {
          ...LocationFragment
          geoJson
        }
        availableSponsorableProperties {
          ...AvailableSponsorablePropertyFragment
        }
        confirmedOffers: offers(statuses: [confirmed]) {
          ...ConfirmedOfferFragment
        }
        proposedOffers: offers(statuses: [proposed rejected_by_league]) {
          ...ProposedOfferFragment
        }
        prospectingOffers: offers(statuses: [prospecting]) {
          ...ProspectingOfferFragment
        }
        qualifiedOffers: offers(statuses: [qualified]) {
          ...QualifiedOfferFragment
        }
        removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
          ...RemovedOfferFragment
        }
        locations {
          ...LocationFragment
        }
      }
      campaign {
        id
        name
        hasSurveyQuestions
        sponsor {
          id
          name
        }
      }
    }
  }
  ${AVAILABLE_SPONSORABLE_PROPERTY_FRAGMENT}
  ${CONFIRMED_OFFER_FRAGMENT}
  ${PROPOSED_OFFER_FRAGMENT}
  ${PROSPECTING_OFFER_FRAGMENT}
  ${QUALIFIED_OFFER_FRAGMENT}
  ${REMOVED_OFFER_FRAGMENT}
  ${LOCATION_FRAGMENT}
`;

export const ADD_ALL_LOCATION_FILTER_REFETCH_QUERY = gql`
  query AddAllLocationFilterQuery($id: Int!) {
    locationFilter(id: $id) {
      id
      prospectingReportId
      ... on PerLocationStrategyLocationFilter {
        locations {
          id
          prospectingReportId
        }
      }
    }
  }
`;

export default PROSPECT_FILTER_QUERY;
