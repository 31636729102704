import * as React from 'react';
import { equalsUS } from 'utils/country';
import {
  formatDeliverableAddressMultiLineUS,
  formatDeliverableAddressMultiLineInternational,
} from 'utils/deliverableAddress';

interface DeliverableAddress {
  city: string;
  country: string;
  line1: string;
  name: string;
  state: string;
  zip: string;
}

interface Props {
  deliverableAddress: DeliverableAddress;
}

const FormattedAddress: React.FunctionComponent<Props> = ({
  deliverableAddress,
}: Props) => {
  const { country } = deliverableAddress;

  const formattedAddress = equalsUS(country)
    ? formatDeliverableAddressMultiLineUS(deliverableAddress)
    : formatDeliverableAddressMultiLineInternational(deliverableAddress);

  return (
    <pre className="ml-2 font-inherit text-inherit">{formattedAddress}</pre>
  );
};

export default FormattedAddress;
