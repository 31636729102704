import { map } from 'ramda';
import { sortByCreatedAt } from 'utils/sort';
import type { Offer } from 'types/offer';
import Card from 'components/Card';
import ChecksProgressBar from 'components/ChecksProgressBar';
import CheckCard from './check-card';
import CreateCheckModal from './create-check-modal';

type ChecksCardProps = {
  createCheck: (input: any) => void;
  offer: Offer;
};

const ChecksCard = (props: ChecksCardProps) => {
  const { createCheck, offer } = props;
  const { checks } = offer;

  return (
    <Card data-test="checks">
      <div className="row">
        <h2 className="col">Checks</h2>
        <div className="col-auto text-right">
          <CreateCheckModal create={createCheck} offer={offer} />
        </div>
      </div>
      <div className="mb-3">
        <ChecksProgressBar offer={offer} />
      </div>
      {map(
        (check) => (
          <CheckCard check={check} key={check.id} />
        ),
        sortByCreatedAt(checks),
      )}
    </Card>
  );
};

export default ChecksCard;
