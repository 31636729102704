import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { useSubmit } from 'utils/hooks';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

export const UpdateLocationModalUnwrapped = ({ id, oldName, place, save }) => {
  const [name, setName] = useState(oldName);
  const { error, loading, handleSubmit } = useSubmit(() => save(name));

  return (
    <ModalForm
      error={error}
      id={`update-location-${id}-modal`}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{ disabled: !name || loading }}
      title={`Edit ${oldName}`}
      toggleProps={{
        children: <Icon value="pencil" />,
        className: 'btn btn-link p-0',
      }}
    >
      {place !== oldName && (
        <p>
          <strong>Location:</strong>
          <br />
          {place}
        </p>
      )}
      <div className="form-group">
        <label htmlFor="location-name">
          <strong>Name:</strong>
        </label>
        <div className="input-group">
          <input
            className="form-control"
            id="location-name"
            onChange={({ target: { value } }) => setName(value)}
            required
            value={name}
          />
        </div>
      </div>
    </ModalForm>
  );
};

UpdateLocationModalUnwrapped.propTypes = {
  id: PropTypes.number.isRequired,
  oldName: PropTypes.string.isRequired,
  place: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      location {
        id
        name
      }
    }
  }
`;

const UpdateLocationModal = ({ location }) => {
  const { id } = location;

  return (
    <Mutation mutation={MUTATION}>
      {(mutate) => (
        <UpdateLocationModalUnwrapped
          id={id}
          oldName={location.name}
          place={location.place}
          save={(name) =>
            mutate({
              variables: {
                input: { id, name },
              },
            })
          }
        />
      )}
    </Mutation>
  );
};

UpdateLocationModal.propTypes = {
  location: LeagueSidePropTypes.location.isRequired,
};

export default UpdateLocationModal;
