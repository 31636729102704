import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

export const RemoveOrganizationCapabilityPackageModalUnwrapped = ({
  organizationCapabilityPackage,
  remove,
}) => {
  const {
    id,
    organizationCapability: { name },
  } = organizationCapabilityPackage;

  const { error, loading, handleSubmit } = useSubmit(remove);

  return (
    <ModalForm
      id={`remove-organization-capability-package-${id}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Remove'),
        className: 'btn btn-danger',
        disabled: loading,
      }}
      title="Remove Opportunity From Package?"
      toggleProps={{
        children: <Icon value="trash" />,
        className: 'btn btn-link p-0 text-danger',
        title: 'Remove',
      }}
    >
      Are you sure you want to remove&nbsp;
      <strong>{name}</strong>
      &nbsp;from this package?
    </ModalForm>
  );
};

RemoveOrganizationCapabilityPackageModalUnwrapped.propTypes = {
  organizationCapabilityPackage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    organizationCapability: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation RemoveOrganizationCapabilityPackage(
    $input: RemoveOrganizationCapabilityPackageInput!
  ) {
    removeOrganizationCapabilityPackage(input: $input) {
      organizationPackage {
        id
        organization {
          id
          organizationCapabilities {
            id
            organizationCapabilityPackages {
              id
            }
          }
        }
        organizationCapabilityPackages {
          id
        }
      }
    }
  }
`;

const RemoveOrganizationCapabilityPackageModal = ({
  organizationCapabilityPackage,
}) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <RemoveOrganizationCapabilityPackageModalUnwrapped
        organizationCapabilityPackage={organizationCapabilityPackage}
        remove={() =>
          mutate({
            variables: {
              input: {
                organizationCapabilityPackageId: Number(
                  organizationCapabilityPackage.id,
                ),
              },
            },
          })
        }
      />
    )}
  </Mutation>
);

RemoveOrganizationCapabilityPackageModal.propTypes = {
  organizationCapabilityPackage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    organizationCapability: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default RemoveOrganizationCapabilityPackageModal;
