import { assoc, prop } from 'ramda';
import {
  getStateFromLocalStorageByKey,
  updateLocalStorageByKeyAndState,
} from 'utils/localStorage';
import { defaultToEmptyArray } from 'utils/misc';

const recentOrganizationIdsProp = 'recentOrganizationIds';
const LOCAL_STORAGE_KEY = 'RecentOrganizations';

export function getRecentOrganizationsStateFromLocalStorage() {
  return getStateFromLocalStorageByKey(LOCAL_STORAGE_KEY);
}

export const getRecentOrganizationIds = (state) =>
  defaultToEmptyArray(prop(recentOrganizationIdsProp, state));

const updateLocalStorageStateByState =
  updateLocalStorageByKeyAndState(LOCAL_STORAGE_KEY);
export const updateRecentOrganizationsLocalStorage = (
  recentOrganizationIds,
  state,
) =>
  updateLocalStorageStateByState(
    assoc(recentOrganizationIdsProp, recentOrganizationIds, state),
  );
