import gql from 'graphql-tag';
import { ORGANIZATION_PACKAGE_MINIMAL_FRAGMENT } from './OrganizationPackageFragment';
import ORGANIZATION_CAPABILITY_PACKAGE_FRAGMENT_V2 from './OrganizationCapabilityPackageFragment';

const ORGANIZATION_PACKAGE_FRAGMENT_V2 = gql`
  fragment OrganizationPackageFragment on OrganizationPackage {
    ...OrganizationPackageMinimalFragment
    organizationCapabilityPackages {
      ...OrganizationCapabilityPackageFragmentV2
    }
  }
  ${ORGANIZATION_PACKAGE_MINIMAL_FRAGMENT}
  ${ORGANIZATION_CAPABILITY_PACKAGE_FRAGMENT_V2}
`;

export default ORGANIZATION_PACKAGE_FRAGMENT_V2;
