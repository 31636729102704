import React from 'react';
import { Mutation } from 'react-apollo';
import moment from 'moment';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import formatDate from 'utils/formatDate';
import DefaultVerificationDeadlineModal from './DefaultVerificationDeadlineModal';
import { DEFAULT_VERIFICATION_DEADLINE_FRAGMENT } from '../getCampaign';

export const UpdateDefaultVerificationDeadlineModalUnwrapped = ({
  capabilityName,
  capabilityVerificationDeadlines,
  defaultVerificationDeadline,
  update,
}) => {
  const {
    id: defaultVerificationDeadlineId,
    deadline: initialDeadline,
    name: initialName,
    verificationReminderInstructions: initialInstructions,
    offset: initialOffset,
    offsetType: initialOffsetType,
    requiresCreatives: initialRequiresCreatives,
  } = defaultVerificationDeadline;
  return (
    <DefaultVerificationDeadlineModal
      capabilityName={capabilityName}
      capabilityVerificationDeadlines={capabilityVerificationDeadlines}
      defaultVerificationDeadlineId={defaultVerificationDeadlineId}
      initialDeadline={moment(initialDeadline)}
      initialInstructions={initialInstructions}
      initialName={initialName}
      initialOffset={String(initialOffset)}
      initialOffsetType={initialOffsetType}
      initialRequiresCreatives={initialRequiresCreatives}
      modalId={`update-verification-deadline-${defaultVerificationDeadlineId}-modal`}
      onSubmit={update}
      question={
        <p>
          {'Are you sure you want to update the default '}
          <strong>{`${formatDate(initialDeadline)} ${initialName}`}</strong>
          {' deadline?'}
        </p>
      }
      toggleProps={{
        className: 'btn btn-link py-1',
        children: 'Edit deadline, name, instructions',
      }}
    />
  );
};

UpdateDefaultVerificationDeadlineModalUnwrapped.propTypes = {
  update: PropTypes.func.isRequired,
  capabilityName: PropTypes.string.isRequired,
  capabilityVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  defaultVerificationDeadline: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    deadline: PropTypes.string.isRequired,
    verificationReminderInstructions: PropTypes.string.isRequired,
    offset: PropTypes.number,
    offsetType: PropTypes.string,
    requiresCreatives: PropTypes.bool.isRequired,
  }).isRequired,
};

const MUTATION = gql`
  mutation UpdateDefaultVerificationDeadline(
    $input: UpdateDefaultVerificationDeadlineInput!
  ) {
    updateDefaultVerificationDeadline(input: $input) {
      defaultVerificationDeadline {
        ...DefaultVerificationDeadlineFragment
      }
    }
  }
  ${DEFAULT_VERIFICATION_DEADLINE_FRAGMENT}
`;

const UpdateDefaultVerificationDeadlineModal = ({
  capabilityName,
  capabilityVerificationDeadlines,
  defaultVerificationDeadline,
}) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <UpdateDefaultVerificationDeadlineModalUnwrapped
        capabilityName={capabilityName}
        capabilityVerificationDeadlines={capabilityVerificationDeadlines}
        defaultVerificationDeadline={defaultVerificationDeadline}
        update={(input) => mutate({ variables: { input } })}
      />
    )}
  </Mutation>
);

UpdateDefaultVerificationDeadlineModal.propTypes = {
  defaultVerificationDeadline: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    deadline: PropTypes.string.isRequired,
    verificationReminderInstructions: PropTypes.string.isRequired,
    offset: PropTypes.number,
    offsetType: PropTypes.string,
    requiresCreatives: PropTypes.bool.isRequired,
  }).isRequired,
  capabilityName: PropTypes.string.isRequired,
  capabilityVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default UpdateDefaultVerificationDeadlineModal;
