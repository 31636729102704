import React, { useContext, useState } from 'react';
import { Mutation } from 'react-apollo';
import {
  __,
  any,
  concat,
  contains,
  drop,
  isEmpty,
  map,
  uniq,
  without,
} from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { parseZips } from 'utils/zipCode';
import { loadingTense } from 'utils/string';
import ErrorAlert from 'components/ErrorAlert';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import RefetchDataContext from './RefetchDataContext';

const INVALID_ZIP_GRAPHQL_MESSAGE = 'GraphQL error: Cannot add invalid zips: ';

const displayInvalidZipsError = contains(INVALID_ZIP_GRAPHQL_MESSAGE);
const invalidZipList = drop(INVALID_ZIP_GRAPHQL_MESSAGE.length);

function CreateLocationsByZipsModalUnwrapped({ create, locationFilterId }) {
  const [zips, setZips] = useState([]);
  const [newZips, setNewZips] = useState('');
  const refetchData = useContext(RefetchDataContext);

  async function onSubmit() {
    await create(zips);
    refetchData();
    setZips([]);
    setNewZips('');
  }

  const { error, loading, handleSubmit } = useSubmit(onSubmit);
  const message = error ? error.message : '';

  const displayErrorMessage = displayInvalidZipsError(message);
  const invalidZips = displayErrorMessage ? invalidZipList(message) : '';
  const isInvalidZip = contains(__, invalidZips);
  const anyInvalidZips = any(isInvalidZip, zips);

  const errorMessage = displayErrorMessage ? (
    <ErrorAlert className="mt-3 mb-0" data-test="invalid-zip-error-message">
      {`Please remove all invalid zip codes (highlighted in red) before adding to filter: ${invalidZips}`}
    </ErrorAlert>
  ) : null;

  function addZip() {
    setZips(uniq(concat(parseZips(newZips), zips)));
    setNewZips('');
  }

  function removeZip(zip) {
    setZips(without([zip], zips));
  }

  const renderZipButton = (zip) => (
    <button
      type="button"
      key={zip}
      className={`btn btn-${
        isInvalidZip(zip) ? 'danger' : 'success'
      } mr-1 mb-1`}
      onClick={() => removeZip(zip)}
      data-test={`new-zip-${zip}`}
    >
      {zip}
      <Icon className="ml-2" value="cross" />
    </button>
  );

  return (
    <ModalForm
      id={`create-locations-by-zips-${locationFilterId}`}
      error={error}
      errorMessage={errorMessage}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Add'),
        disabled: isEmpty(zips) || loading || anyInvalidZips,
      }}
      title="Add Zips"
      toggleProps={{
        className: 'dropdown-item',
        children: 'Zips',
      }}
    >
      <div className="input-group">
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder="Enter a five-digit zip code to add"
          value={newZips}
          onChange={({ target: { value } }) => setNewZips(value)}
          data-test="new-zip-input"
        />
        <div className="input-group-append">
          <button
            className="btn btn-primary btn-lg"
            onClick={addZip}
            type="button"
            disabled={newZips.length < 5}
            data-test="add-new-zip"
          >
            Add
          </button>
        </div>
      </div>
      <div className="mt-3">{map(renderZipButton, zips)}</div>
    </ModalForm>
  );
}

CreateLocationsByZipsModalUnwrapped.propTypes = {
  create: PropTypes.func.isRequired,
  locationFilterId: PropTypes.number.isRequired,
};

const MUTATION = gql`
  mutation CreateLocationsByZips($input: AddZipsToLocationFilterInput!) {
    addZipsToLocationFilter(input: $input) {
      locationFilter {
        id
      }
    }
  }
`;

const CreateLocationsByZipsModal = ({ locationFilterId }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CreateLocationsByZipsModalUnwrapped
        create={(zips) =>
          mutate({
            variables: { input: { locationFilterId, zips } },
          })
        }
        locationFilterId={locationFilterId}
      />
    )}
  </Mutation>
);

CreateLocationsByZipsModal.propTypes = {
  locationFilterId: PropTypes.number.isRequired,
};

export default CreateLocationsByZipsModal;
