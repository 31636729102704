import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { isNotEmpty } from 'utils/misc';
import { capitalize, loadingTense } from 'utils/string';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

export const ArchiveOrganizationCapabilityModalUnwrapped = ({
  archive,
  organizationCapability,
}) => {
  const { archived, id, organizationCapabilityPackages, name } =
    organizationCapability;

  const label = archived ? 'restore' : 'archive';
  const title = capitalize(label);
  const hasPackages = isNotEmpty(organizationCapabilityPackages) && !archived;

  const { error, loading, handleSubmit } = useSubmit(archive);

  return (
    <ModalForm
      id={`archive-organization-capability-${id}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, title),
        className: `btn btn-${archived ? 'primary' : 'danger'}`,
        disabled: loading || hasPackages,
      }}
      title={`${title} Sponsorship Opportunity?`}
      toggleProps={{
        children: <Icon value={archived ? 'ccw' : 'archive'} />,
        className: classNames('btn btn-link p-0', {
          'text-danger': !archived,
          'text-disabled': hasPackages,
        }),
        title,
      }}
    >
      {hasPackages ? (
        <p>
          Sorry!&nbsp;
          <strong>{capitalize(name)}</strong>
          &nbsp;cannot be archived because this opportunity is a part of at
          least one sponsorship package.
        </p>
      ) : (
        <p>
          {`Are you sure you want to ${label} `}
          <strong>{name}</strong>?
          {!archived &&
            ' You will be able to restore this opportunity later if wanted.'}
        </p>
      )}
    </ModalForm>
  );
};

ArchiveOrganizationCapabilityModalUnwrapped.propTypes = {
  archive: PropTypes.func.isRequired,
  organizationCapability: PropTypes.shape({
    id: PropTypes.number.isRequired,
    archived: PropTypes.bool.isRequired,
    organizationCapabilityPackages: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number }),
    ),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const MUTATION = gql`
  mutation ArchiveOrganizationCapability(
    $input: ArchiveOrganizationCapabilityInput!
  ) {
    archiveOrganizationCapability(input: $input) {
      organizationCapability {
        id
        archived
      }
    }
  }
`;

const ArchiveOrganizationCapabilityModal = ({ organizationCapability }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <ArchiveOrganizationCapabilityModalUnwrapped
        organizationCapability={organizationCapability}
        archive={() =>
          mutate({
            variables: {
              input: {
                archived: !organizationCapability.archived,
                organizationCapabilityId: Number(organizationCapability.id),
              },
            },
          })
        }
      />
    )}
  </Mutation>
);

ArchiveOrganizationCapabilityModal.propTypes = {
  organizationCapability: PropTypes.shape({
    id: PropTypes.number.isRequired,
    archived: PropTypes.bool.isRequired,
    organizationCapabilityPackages: PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.number }),
    ),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ArchiveOrganizationCapabilityModal;
