import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CAMPAIGN_PLANNER_PATH, CAMPAIGN_PLANNER_PREFIX } from 'utils/paths';
import AddCampaign from './pages/AddCampaign';
import AddSponsor from './pages/AddSponsor';
import CampaignOverview from './pages/CampaignOverview';
import CampaignSearch from './pages/CampaignSearch';
import Sponsor from './pages/Sponsor';
import Sponsors from './pages/Sponsors';
import AddSeasonalGoal from './pages/AddSeasonalGoal';

function CampaignPlannerRouter() {
  return (
    <Switch>
      <Redirect
        from={`${CAMPAIGN_PLANNER_PATH}/:sponsorId/campaigns/:campaignId/leagues`}
        to="/campaign-targeting/:campaignId"
      />
      <Redirect
        from={`${CAMPAIGN_PLANNER_PATH}/:sponsorId/campaigns/:campaignId/filters`}
        to="/campaign-targeting/:campaignId"
      />
      <Route
        path={`${CAMPAIGN_PLANNER_PATH}/:sponsorId/campaigns/:campaignId/campaign-search`}
        component={CampaignSearch}
      />
      <Route
        path={`${CAMPAIGN_PLANNER_PATH}/:sponsorId/campaigns/add`}
        component={AddCampaign}
      />
      <Route
        path={`${CAMPAIGN_PLANNER_PATH}/:sponsorId/campaigns/:campaignId`}
        component={CampaignOverview}
      />
      <Route path={`${CAMPAIGN_PLANNER_PATH}/add`} component={AddSponsor} />
      <Route
        path={`${CAMPAIGN_PLANNER_PATH}/:sponsorId/seasonal-goal/:season/:year`}
        component={AddSeasonalGoal}
      />
      <Route path={`${CAMPAIGN_PLANNER_PATH}/:sponsorId`} component={Sponsor} />
      <Route path={CAMPAIGN_PLANNER_PATH} component={Sponsors} />
      <Redirect from={CAMPAIGN_PLANNER_PREFIX} to={CAMPAIGN_PLANNER_PATH} />
    </Switch>
  );
}

export default CampaignPlannerRouter;
