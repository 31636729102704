import { __, divide, max, min, multiply, pipe, pluck } from 'ramda';

export const ROW_COUNT = 12;

const pluckAmounts = pluck('amount');
const divideByRowCount = divide(__, ROW_COUNT);
const getFlooredWidth = (width) => max(1, Math.floor(width));
const getAdjustedFullBarWidth = (colWidth) => ROW_COUNT - colWidth / 2;

export function getChecksBarLabelSpacing(
  approvedAmount,
  leagueDashboard,
  offerValue,
  checks,
) {
  const firstCheck = checks[0];

  const onlyNeedFirstCol = !firstCheck || !leagueDashboard;
  const firstColWidth = onlyNeedFirstCol ? ROW_COUNT : 1;
  const firstColPercentage = divideByRowCount(firstColWidth);

  if (onlyNeedFirstCol)
    return { firstColWidth, firstRightMargin: 0, isNormalized: false };

  const getAmountPercentage = divide(__, offerValue);
  const getAmountWidth = (amount) => ROW_COUNT * getAmountPercentage(amount);
  const maxColWidth = getFlooredWidth(
    (ROW_COUNT - firstColWidth) / checks.length,
  );
  const checkAmounts = pluckAmounts(checks);

  const minCheckAmount = Math.min(...checkAmounts);
  const minAmountWidth = getAmountWidth(minCheckAmount);
  const estimatedColWidth = min(getFlooredWidth(minAmountWidth), maxColWidth);

  const approvedAmountWidth =
    getAmountWidth(approvedAmount) + estimatedColWidth / 2;
  const estimatedFullBarWidth = getAdjustedFullBarWidth(estimatedColWidth);

  const isNormalized = estimatedFullBarWidth < approvedAmountWidth;
  const getNormalizationFactor = (fullBarWidth) =>
    isNormalized ? divideByRowCount(fullBarWidth) : 1;

  const estimatedNormalizationFactor = getNormalizationFactor(
    estimatedFullBarWidth,
  );
  const colWidth = getFlooredWidth(
    estimatedNormalizationFactor * minAmountWidth,
  );
  const colPercentage = divideByRowCount(colWidth);

  const addjustedFullBarWidth = getAdjustedFullBarWidth(colWidth);
  const normalizationFactor = getNormalizationFactor(addjustedFullBarWidth);
  const normalize = multiply(normalizationFactor);
  const getNormalizedAmountPercentage = pipe(getAmountPercentage, normalize);

  const normalizedFirstCheckPercentage = getNormalizedAmountPercentage(
    firstCheck.amount,
  );
  const firstRightMargin =
    100 *
    (normalizedFirstCheckPercentage - firstColPercentage - colPercentage / 2);

  const getRightMargin = (check) =>
    check
      ? 100 * (getNormalizedAmountPercentage(check.amount) - colPercentage)
      : 0;

  return {
    colWidth,
    firstColWidth,
    firstRightMargin,
    getRightMargin,
    isNormalized,
  };
}
