import gql from 'graphql-tag';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { SPONSORSHIP_APPLICATION_OPTIONS_LIST_QUERY } from 'pages/qualify/queries';
import { INTERESTS_LIST_PER_GROUP } from 'pages/qualify/utils';
import { useWizardContext } from 'pages/qualify/provider';
import { SponsorshipApplicationOptions } from 'pages/qualify/types';
import { Checkbox } from '@teamsnap/snap-ui';
import WizardInput from '../wizard-input';
import WizardStep from '../wizard-step';

export default function SponsorActivitiesStep() {
  const { token, tokenData, setValues, values } = useWizardContext();
  const [notInterested, setNotInterested] = useState(values.notInterested);
  const { data: optionsListData, loading } =
    useQuery<SponsorshipApplicationOptions>(
      SPONSORSHIP_APPLICATION_OPTIONS_LIST_QUERY,
      {
        context: { headers: { Authorization: `Bearer ${token}` } },
      },
    );
  const [performMutation] = useMutation(MUTATION);
  const groupedOptions = useMemo(() => {
    return [
      {
        title: 'Branded collateral',
        subtitle:
          'Items marked with * are provided to your organization at no cost.',
        options:
          optionsListData?.interestsList
            .filter((interest) =>
              INTERESTS_LIST_PER_GROUP.brandedCollateral.includes(interest.id),
            )
            .map((interest) => ({
              value: interest.id,
              label: interest.text,
            })) ?? [],
      },
      {
        title: 'Experiences',
        subtitle:
          'Items marked with * are provided to your organization at no cost.',
        options:
          optionsListData?.interestsList
            .filter((interest) =>
              INTERESTS_LIST_PER_GROUP.experiences.includes(interest.id),
            )
            .map((interest) => ({
              value: interest.id,
              label: interest.text,
            })) ?? [],
      },
      {
        title: 'Digital assets',
        subtitle:
          'Items marked with * are provided to your organization at no cost.',
        options:
          optionsListData?.interestsList
            .filter((interest) =>
              INTERESTS_LIST_PER_GROUP.digitalAssets.includes(interest.id),
            )
            .map((interest) => ({
              value: interest.id,
              label: interest.text,
            })) ?? [],
      },
    ];
  }, [optionsListData]);

  const onCheckboxGroupChange = (event: any) => {
    let actualInterestList = event;
    if (event.includes('not-interested')) {
      actualInterestList = event.filter(
        (interest: string) => interest !== 'not-interested',
      );
    }
    setValues({
      ...values,
      notInterested: false,
      interests: actualInterestList,
    });
    setNotInterested(false);
  };

  const onNotInterestedChange = (event: any) => {
    setValues({
      ...values,
      interests: event.target.checked ? ['not-interested'] : [],
      notInterested: event.target.checked,
    });
    setNotInterested(event.target.checked);
  };

  return (
    <WizardStep
      performMutation={performMutation}
      skipToEnd={notInterested}
      isLoading={loading}
      mutationStaticIds={{
        organizationId: tokenData.id,
        season: tokenData.season,
        year: tokenData.year,
        offerId: tokenData.offer_id,
      }}
    >
      <WizardStep.Content
        title="Select the assets or services that your organization might want to include in your sponsorship package"
        subtitle="Maximize sponsorship opportunities by selecting as many benefits as your organization could potentially offer."
      >
        <WizardInput
          type="checkbox-group"
          name="interests"
          label="Tell us about how sponsorships impact your organization"
          selectAllOption
          groupedOptions={groupedOptions ?? []}
          rules={{
            required: 'Select at least one option',
          }}
          onChange={onCheckboxGroupChange}
        />
        <Checkbox
          name="not-interested"
          label="Not interested"
          onChange={onNotInterestedChange}
          extraProps={{
            input: {
              checked: notInterested,
            },
          }}
        />
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation UpsertSponsorshipApplication(
    $input: UpsertSponsorshipApplicationInput!
  ) {
    upsertSponsorshipApplication(input: $input) {
      id
    }
  }
`;
