import React from 'react';
import Autocomplete from 'react-autocomplete';
import { ApolloConsumer } from 'react-apollo';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import gql from 'graphql-tag';
import debounce from 'debounce';
import { history as storeHistory } from 'store';
import { label } from 'utils/campaign';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';

const renderItem = (campaign, isHighlighted) => (
  <li
    data-test="campaignResult"
    key={campaign.id}
    className={classNames('list-group-item list-group-item-action', {
      active: isHighlighted,
    })}
  >
    {label(campaign)}
    {(campaign.status === 'rejected' || campaign.status === 'complete') && (
      <span className="badge badge-secondary ml-2">Inactive</span>
    )}
  </li>
);

export class CampaignAutocompleteUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = { campaigns: [], value: '' };

    this.debouncedUpdateCampaigns = debounce(this.updateCampaigns, 200);
    this.handleChange = this.handleChange.bind(this);

    const { history } = this.props;
    this.handleSelect = (_, campaign) =>
      history.push(
        `${CAMPAIGN_PLANNER_PATH}/${campaign.sponsor.id}/campaigns/${campaign.id}`,
      );
  }

  async handleChange(event) {
    const { value } = event.target;

    this.setState({ value });

    this.debouncedUpdateCampaigns();
  }

  async updateCampaigns() {
    const { state, props } = this;
    const { value } = state;

    const campaigns = value ? await props.fetchCampaigns(value) : [];

    this.setState({ campaigns });
  }

  render() {
    const { campaigns, value } = this.state;

    return (
      <Autocomplete
        getItemValue={label}
        inputProps={{
          className: 'form-control form-control-lg',
          id: 'campaignAutocomplete',
          placeholder: 'Search campaigns by name or sponsor name',
        }}
        items={campaigns}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        renderItem={renderItem}
        renderMenu={(items) => (
          <ul
            className={classNames('list-group mt-2', {
              'd-none': items.length < 1,
            })}
          >
            {items}
          </ul>
        )}
        value={value}
        wrapperProps={{ className: 'd-block mt-4' }}
      />
    );
  }
}

CampaignAutocompleteUnwrapped.propTypes = {
  fetchCampaigns: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

CampaignAutocompleteUnwrapped.defaultProps = {
  history: storeHistory,
};

const QUERY = gql`
  query SearchCampaigns($query: String!) {
    campaigns(query: $query) {
      id
      name
      sponsor {
        id
        name
      }
      season
      year
      status
    }
  }
`;

const CampaignAutocomplete = () => (
  <ApolloConsumer>
    {(client) => (
      <CampaignAutocompleteUnwrapped
        fetchCampaigns={(query) =>
          client
            .query({
              fetchPolicy: 'network-only',
              query: QUERY,
              variables: { query },
            })
            .then(({ data }) => data.campaigns)
        }
      />
    )}
  </ApolloConsumer>
);

export default CampaignAutocomplete;
