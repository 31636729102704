import React from 'react';
import { descend, sort, prop, pathOr } from 'ramda';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import COMMENT_FRAGMENT from 'fragments/CommentFragment';
import formatDate from 'utils/formatDate';
import styled from 'styled-components';
import CreateCommentForm from './comments/CreateCommentForm';

export type Comment = {
  id: number;
  content: string;
  createdAt: any;
  user: { name: string };
};

export type CommentList = Comment[];

export type Organization = {
  id: number;
  comments: CommentList;
};

export type OrganizationQueryResponse = {
  organization: Organization;
};

export type CommentsProps = {
  organizationId: number;
};

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      comments {
        ...CommentFragment
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

const DATE_FORMAT_STRING = 'MMM D YYYY, h:mma';

const CommentContent = styled.p`
  white-space: pre-line;
`;
const sortByCreatedAt = sort(descend(prop('createdAt')));
const renderUsername = pathOr('System User', ['user', 'name']);

const renderComment = (comment: Partial<Comment>) => (
  <div className="media list-group-item" data-test="comment" key={comment.id}>
    <div className="media-body">
      <div className="media-heading">
        <small className="float-right text-muted">
          {formatDate(comment.createdAt, DATE_FORMAT_STRING)}
        </small>
        <strong>{renderUsername(comment)}</strong>
      </div>
      {/* @ts-ignore */}
      <CommentContent className="mb-0">{comment.content}</CommentContent>
    </div>
  </div>
);

const emptyMessage = (
  <div className="media list-group-item">
    <div className="media-body">
      <p className="text-center">No comments have been added yet!</p>
    </div>
  </div>
);

const Comments: React.FC<CommentsProps> = ({ organizationId }) => {
  const { data, loading, refetch } = useQuery(ORGANIZATION_QUERY, {
    variables: { id: organizationId },
  });

  const organization = data && data.organization;
  const comments = organization && organization.comments;

  return !loading && organization ? (
    <div className="list-group list-group-flush">
      <div className="list-group-item">
        <CreateCommentForm
          organizationId={organizationId}
          refetchCommentList={refetch}
        />
      </div>
      {comments.length
        ? sortByCreatedAt(comments).map(renderComment)
        : emptyMessage}
    </div>
  ) : null;
};

export default Comments;
