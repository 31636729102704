import React, { useEffect, useState, useCallback } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { lensPath, prop, set } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { compact } from 'utils/array';
import { wrapWithUserId } from 'utils/authentication';
import {
  getStateFromLocalStorageByKey,
  updateLocalStorageByKeyAndState,
} from 'utils/localStorage';
import { defaultToEmptyObject, findById } from 'utils/misc';
import {
  ORGANIZATION_DASHBOARD_OVERVIEW_PATH,
  ORGANIZATION_DASHBOARD_PATH,
  ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH,
} from 'utils/paths';
import { joinWithSpace } from 'utils/string';
import BackToLink from 'components/BackToLink';
import DelightedSurvey from 'components/DelightedSurvey';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import NeedHelp from 'components/NeedHelp';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import HubSpotChat from 'components/HubSpotChat';
import OrganizationAddressModalToggle from 'components/OrganizationAddressModalToggle';
import OrganizationAddressModal from 'components/OrganizationAddressModal';
import SwitchSelectedDropdown from 'components/SwitchSelectedDropdown';
import OrganizationAddressModalQueuer from './organizationDashboardWrapper/OrganizationAddressModalQueuer';
import './OrganizationDashboardWrapper.css';

const selectedOrganizationProp = 'selectedOrganization';
const LOCAL_STORAGE_KEY = 'OrganizationDashboard';
const updateLocalStorageStateByState =
  updateLocalStorageByKeyAndState(LOCAL_STORAGE_KEY);

export const OrganizationDashboardWrapperWithoutUserId = ({
  breadcrumb,
  children,
  organizations,
  main,
  sidebar,
  title,
  userId,
}) => {
  const [state, setState] = useState(
    getStateFromLocalStorageByKey(LOCAL_STORAGE_KEY),
  );
  const stateForUser = defaultToEmptyObject(state[userId]);
  const selectedOrganizationId = prop(selectedOrganizationProp, stateForUser);
  const selectedOrganization =
    findById(selectedOrganizationId)(organizations) || organizations[0];
  const { name: organizationName } = selectedOrganization;

  const lens = lensPath([userId, selectedOrganizationProp]);
  const toggleOrganization = useCallback(
    (organization) => setState(set(lens, organization.id)),
    [lens],
  );

  useEffect(updateLocalStorageStateByState(state), [state]);

  return (
    <RestrictTo roles={['admin', 'organization']}>
      <Page
        additionalDropdownItems={compact([
          <SwitchSelectedDropdown
            options={organizations}
            selected={selectedOrganization}
            toggleSelected={toggleOrganization}
          />,
        ])}
        additionalNavItems={[
          <NavLink
            className="nav-link"
            activeClassName="nav-link active"
            exact
            to={ORGANIZATION_DASHBOARD_PATH}
          >
            Sponsorships
          </NavLink>,
          <NavLink
            className="nav-link"
            activeClassName="nav-link active"
            to={ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH}
          >
            Package Builder
          </NavLink>,
          <li
            className="nav-item dropdown"
            data-test="organization-profile-dropdown"
          >
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              role="button"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Organization Profile
            </a>
            <div className="dropdown-menu">
              <Link
                className="dropdown-item"
                to={ORGANIZATION_DASHBOARD_OVERVIEW_PATH}
              >
                Overview
              </Link>
              <OrganizationAddressModalToggle
                toggleClassName="dropdown-item"
                organizationId={selectedOrganization.id}
              />
            </div>
          </li>,
        ]}
        fullScreen={!!sidebar}
      >
        <FieldhousePageTitle
          title={joinWithSpace(compact([organizationName, title]))}
        >
          <HubSpotChat />
          {main ? (
            <>
              <OrganizationAddressModalQueuer organizations={organizations} />
              <DelightedSurvey
                options={{
                  name: `User ${userId}`,
                  properties: {
                    page: 'Organization Dashboard',
                    userId,
                  },
                }}
              />
            </>
          ) : (
            <OrganizationAddressModal organization={selectedOrganization} />
          )}
          <div
            className={classNames({
              'organization-dashboard-sidebar-and-main': sidebar,
              container: sidebar,
            })}
          >
            <div
              className={classNames({ 'organization-dashboard-main': sidebar })}
            >
              {!!breadcrumb && (
                <BackToLink label={breadcrumb.label} path={breadcrumb.path} />
              )}
              <h1 className={classNames('mb-1', { 'mt-3': !breadcrumb })}>
                {organizationName}
              </h1>
              {children({ selectedOrganization })}
              <NeedHelp />
            </div>
            {!!sidebar && (
              <div className="organization-dashboard-sidebar">{sidebar}</div>
            )}
          </div>
        </FieldhousePageTitle>
      </Page>
    </RestrictTo>
  );
};

OrganizationDashboardWrapperWithoutUserId.propTypes = {
  breadcrumb: PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }),
  children: PropTypes.func,
  organizations: LeagueSidePropTypes.organizations.isRequired,
  main: PropTypes.bool,
  sidebar: PropTypes.node,
  title: PropTypes.string,
  userId: PropTypes.number,
};

OrganizationDashboardWrapperWithoutUserId.defaultProps = {
  breadcrumb: null,
  children: () => {},
  main: false,
  sidebar: null,
  title: null,
  userId: null,
};

const OrganizationDashboardWrapper = wrapWithUserId(
  OrganizationDashboardWrapperWithoutUserId,
);

export default OrganizationDashboardWrapper;
