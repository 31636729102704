import gql from 'graphql-tag';
import { SPONSORABLE_PROPERTY_CAPABILITY_MINIMAL_FRAGMENT } from './SponsorablePropertyCapabilityFragment';

const SPONSORABLE_PROPERTY_CAPABILITY_PACKAGE_FRAGMENT = gql`
  fragment SponsorablePropertyCapabilityPackageFragment on OrganizationCapabilityPackage {
    id
    quantity
    organizationCapability {
      ...SponsorablePropertyCapabilityMinimalFragment
    }
  }
  ${SPONSORABLE_PROPERTY_CAPABILITY_MINIMAL_FRAGMENT}
`;

export default SPONSORABLE_PROPERTY_CAPABILITY_PACKAGE_FRAGMENT;
