import { PropsWithChildren, useEffect } from 'react';
import {
  ExecutionResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
  useQuery,
} from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';
// import { REACT_APP_PRODUCTION } from 'utils/envs';
import { ampli } from 'ampli';
import useErrorBoundary from 'hooks/use-error-boundary';
import { useWizardContext } from '../provider';
import WizardHeader from './wizard-header';
import WizardFooter from './wizard-footer';
import { Inputs, Organization } from '../types';
import s from './styles.module.css';
import WizardProgressBar from './wizard-progress-bar';
import { SAVE_APPLICATION_MUTATION } from '../queries';

type WizardStepProps = PropsWithChildren<{
  performMutation?: (
    options?: MutationFunctionOptions<any, OperationVariables> | undefined,
  ) => Promise<ExecutionResult<any>>;
  refetchAfterMutation?: () => void;
  skipToEnd?: boolean;
  modifyValuesAfterMutation?: (
    response: any,
    data: Partial<Inputs>,
  ) => Partial<Inputs>;
  modifyInputsBeforeMutation?: (data: Partial<Inputs>) => Partial<Inputs>;
  mutationStaticIds?: {
    id?: number;
    organizationId?: number;
    sponsorshipApplicationId?: number;
    season?: string;
    year?: number;
    offerId?: number;
  };
  isLoading?: boolean;
  nextLabel?: string;
}>;

export default function WizardStep(props: WizardStepProps) {
  const {
    children,
    performMutation,
    refetchAfterMutation,
    modifyValuesAfterMutation,
    modifyInputsBeforeMutation = (data) => data,
    skipToEnd = false,
    mutationStaticIds,
    isLoading,
    nextLabel = 'Next',
  } = props;
  const {
    token,
    tokenData,
    handleSubmit,
    handleNextStep,
    values,
    setValues,
    organizationData,
    getSponsorshipFormViewedPayload,
    getSponsorshipStepCompletedPayload,
    refetchApplicationProgressQuery,
  } = useWizardContext();
  const [performSaveAndExit] = useMutation(SAVE_APPLICATION_MUTATION);
  const { throwError } = useErrorBoundary();

  const { data: completionPercentageData, loading } = useQuery<{
    sponsorshipApplicationView: Partial<Organization>;
  }>(COMPLETION_PERCENTAGE_QUERY, {
    variables: { season: tokenData.season, year: tokenData.year },
    context: { headers: { Authorization: `Bearer ${token}` } },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading) {
      setValues({
        ...values,
        completionPercentage:
          completionPercentageData?.sponsorshipApplicationView
            ?.sponsorshipApplication?.completionPercentage,
      });
    }
  }, [loading]);

  useEffect(() => {
    if (
      (completionPercentageData && values.completionPercentage) ||
      values.completionPercentage === 0
    ) {
      ampli.track(getSponsorshipFormViewedPayload());
    }
  }, [completionPercentageData, values.completionPercentage]);

  const onSubmit = async (data: Inputs) => {
    if (performMutation) {
      try {
        const response = await performMutation({
          context: { headers: { Authorization: `Bearer ${token}` } },
          variables: {
            input: {
              ...mutationStaticIds,
              ...modifyInputsBeforeMutation(data),
            },
          },
        });
        const dataToSet = modifyValuesAfterMutation
          ? modifyValuesAfterMutation(response, data)
          : data;
        setValues({ ...values, ...dataToSet });
        if (refetchAfterMutation) await refetchAfterMutation();
        ampli.track(getSponsorshipStepCompletedPayload());
        handleNextStep(skipToEnd);
        // Refetch application progress query
        refetchApplicationProgressQuery();
        // Reset savedStepId and savedFormData
        if (organizationData.sponsorshipApplication.savedStepId) {
          await performSaveAndExit({
            context: { headers: { Authorization: `Bearer ${token}` } },
            variables: {
              input: {
                sponsorshipApplicationId:
                  organizationData.sponsorshipApplication.id,
                stepId: '',
                formData: '',
              },
            },
          });
        }
      } catch (error) {
        throwError(error as Error);
      }
    } else {
      handleNextStep();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="sui-flex-1">
      <WizardHeader />
      <WizardProgressBar />
      <div
        className={classNames(
          'sui-px-4 d:sui-px-6 sui-py-4 d:sui-py-8 d:sui-pt-4 d:sui-pb-8',
          s.wizardStepBody,
        )}
      >
        {isLoading && <p>Loading...</p>}
        {!isLoading && (
          <section className={classNames('d:sui-flex', s.fixedStylesForSnapUI)}>
            {children}
          </section>
        )}
      </div>
      <WizardFooter onSubmit={handleSubmit(onSubmit)} nextLabel={nextLabel} />
    </form>
  );
}

type PictureProps = {
  src: string | string[];
  imageSize?: 'sm' | 'md' | 'lg';
};
function Picture(props: PictureProps) {
  const { src, imageSize = 'md' } = props;
  const srcArray = Array.isArray(src) ? src : [src];
  return (
    <section
      className={classNames(
        'sui-mb-3 d:sui-mb-0 d:sui-flex-1 d:sui-mr-6 sui-mx-auto',
        s.pictureSection,
        s[`pictureSection-${imageSize}`],
      )}
    >
      {srcArray.map((srcToSet) => (
        <picture
          key={`picture-${src}`}
          className={classNames(
            'sui-flex sui-items-center sui-border sui-border-solid sui-border-gray-40 sui-mb-3',
            s.picture,
            s[`picture-${imageSize}`],
          )}
        >
          <img
            src={srcToSet}
            alt=""
            className="sui-w-full sui-h-full sui-object-cover"
          />
        </picture>
      ))}
    </section>
  );
}

type ContentProps = PropsWithChildren<{
  title: string;
  subtitle?: string;
  fullWidth?: boolean;
}>;
function Content(props: ContentProps) {
  const { title, subtitle, children, fullWidth } = props;
  return (
    <div
      className="sui-mx-auto d:sui-flex-1"
      style={fullWidth ? {} : { maxWidth: 800 }}
    >
      <header className="sui-mb-4">
        <h1 className="sui-text-mobile-8 d:sui-text-desktop-8 sui-mb-1 sui-font-bold">
          {title}
        </h1>
        {subtitle && <p className="sui-text-gray-40">{subtitle}</p>}
      </header>
      {children}
    </div>
  );
}

WizardStep.Content = Content;
WizardStep.Picture = Picture;

export const COMPLETION_PERCENTAGE_QUERY = gql`
  query SponsorshipApplicationView($season: String!, $year: Int!) {
    sponsorshipApplicationView {
      id
      sponsorshipApplication(season: $season, year: $year) {
        id
        completionPercentage
      }
    }
  }
`;
// const IMAGE_PLACEHOLDER =
//   'https://images.pexels.com/photos/45201/kitty-cat-kitten-pet-45201.jpeg';
