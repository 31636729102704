import gql from 'graphql-tag';
import { CAMPAIGN_OFFER_COUNT_REPORT_FRAGMENT } from 'fragments/CampaignFragment';
import {
  CONFIRMED_OFFER_FRAGMENT,
  PROSPECTING_OFFER_FRAGMENT,
  PROPOSED_OFFER_FRAGMENT,
  QUALIFIED_OFFER_FRAGMENT,
  REMOVED_OFFER_FRAGMENT,
} from 'fragments/OfferFragment';
import { REMOVED_OFFERS_QUERY_ARGS } from 'utils/queryArgs';

const CAMPAIGN_QUERY = gql`
  query Campaign($id: Int!, $withLatLon: Boolean = false) {
    campaign(id: $id) {
      id
      ...CampaignOfferCountsReportFragment
      confirmedOffers: offers(statuses: [confirmed]) {
        ...ConfirmedOfferFragment
      }
      hasSurveyQuestions
      name
      prospectingOffers: offers(statuses: [prospecting]) {
        ...ProspectingOfferFragment
      }
      proposedOffers: offers(statuses: [proposed rejected_by_league]) {
        ...ProposedOfferFragment
      }
      qualifiedOffers: offers(statuses: [qualified]) {
        ...QualifiedOfferFragment
      }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        ...RemovedOfferFragment
      }
      season
      sponsor { id }
      organizationCount
      year
    }
  }
  ${CAMPAIGN_OFFER_COUNT_REPORT_FRAGMENT}
  ${CONFIRMED_OFFER_FRAGMENT}
  ${PROSPECTING_OFFER_FRAGMENT}
  ${PROPOSED_OFFER_FRAGMENT}
  ${QUALIFIED_OFFER_FRAGMENT}
  ${REMOVED_OFFER_FRAGMENT}
`;

export const PROSPECTING_OFFERS_REFETCH_QUERY = gql`
  query CampaignWithProspectingOffers($id: Int!, $withLatLon: Boolean = false) {
    campaign(id: $id) {
      id
      offerCountsReport {
        prospecting
      }
      prospectingOffers: offers(statuses: [prospecting]) {
        ...ProspectingOfferFragment
      }
    }
  }
  ${PROSPECTING_OFFER_FRAGMENT}
`;

export const PROSPECTING_AND_QUALIFIED_OFFERS_REFETCH_QUERY = gql`
  query CampaignWithProspectingAndQualifiedOffers(
    $id: Int!
    $withLatLon: Boolean = false
  ) {
    campaign(id: $id) {
      id
      offerCountsReport {
        prospecting
        qualified
      }
      prospectingOffers: offers(statuses: [prospecting]) {
        id
      }
      qualifiedOffers: offers(statuses: [qualified]) {
        ...QualifiedOfferFragment
      }
      season
      year
    }
  }
  ${QUALIFIED_OFFER_FRAGMENT}
`;

export const PROSPECTING_AND_REMOVED_OFFERS_REFETCH_QUERY = gql`
  query CampaignWithProspectingAndRemovedOffers($id: Int!, $withLatLon: Boolean = false) {
    campaign(id: $id) {
      id
      offerCountsReport {
        prospecting
        removed
      }
      prospectingOffers: offers(statuses: [prospecting]) { id }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        ...RemovedOfferFragment
      }
    }
  }
  ${REMOVED_OFFER_FRAGMENT}
`;

export const QUALIFIED_AND_PROPOSED_OFFERS_REFETCH_QUERY = gql`
  query CampaignWithQualifiedAndProposedOffers(
    $id: Int!
    $withLatLon: Boolean = false
  ) {
    campaign(id: $id) {
      id
      offerCountsReport {
        qualified
        proposed
      }
      qualifiedOffers: offers(statuses: [qualified]) {
        id
      }
      proposedOffers: offers(statuses: [proposed, rejected_by_league]) {
        ...ProposedOfferFragment
      }
    }
  }
  ${PROPOSED_OFFER_FRAGMENT}
`;

export const QUALIFIED_AND_REMOVED_OFFERS_REFETCH_QUERY = gql`
  query CampaignWithQualifiedAndRemovedOffers($id: Int!, $withLatLon: Boolean = false) {
    campaign(id: $id) {
      id
      offerCountsReport {
        qualified
        removed
      }
      qualifiedOffers: offers(statuses: [qualified]) { id }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        ...RemovedOfferFragment
      }
    }
  }
  ${REMOVED_OFFER_FRAGMENT}
`;

export const PROPOSED_AND_REMOVED_OFFERS_REFETCH_QUERY = gql`
  query CampaignWithProposedAndRemovedOffers($id: Int!, $withLatLon: Boolean = false) {
    campaign(id: $id) {
      id
      offerCountsReport {
        proposed
        removed
      }
      proposedOffers: offers(statuses: [proposed rejected_by_league]) { id }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        ...RemovedOfferFragment
      }
    }
  }
  ${REMOVED_OFFER_FRAGMENT}
`;

export const SEND_SURVEY_CAMPAIGN_REFETCH_QUERY = gql`
  query SendSurveyCampaignQuery($id: Int!) {
    campaign(id: $id) {
      id
      prospectingOffers: offers(statuses: [prospecting]) {
        id
        communicatedToLeague
        numberOfSurveyViews
        surveySentAt
        sponsorshipApplicationSentAt
      }
    }
  }
`;

export const SEND_OFFER_EMAILS_CAMPAIGN_REFETCH_QUERY = gql`
  query SendOfferEmailsCampaignQuery($id: Int!) {
    campaign(id: $id) {
      id
      proposedOffers: offers(statuses: [proposed, rejected_by_league]) {
        id
        numberOfOfferViews
        offerOpenedAt
        offerSentAt
        status
      }
    }
  }
`;

export default CAMPAIGN_QUERY;
