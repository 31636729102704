import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { defaultToEmptyString } from 'utils/string';
import { track } from 'utils/tracker';
import Icon from 'components/Icon';
import ContactUsLink from 'components/ContactUsLink';
import ErrorAlert from 'components/ErrorAlert';
import CONTENT from './CONTENT';

const EnterEmailContent = ({
  email,
  fetchHasAccount,
  setContent,
  setEmail,
  setHasAccount,
}) => {
  const [hasAccountQueryFailed, setHasAccountQueryFailed] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleEnterEmailSubmit(event) {
    event.preventDefault();
    setLoading(true);

    try {
      const { hasAccount } = await fetchHasAccount(email);
      setHasAccount(hasAccount);
      setHasAccountQueryFailed(false);
      setContent(CONTENT.VALIDATE_DELIVERABLE_ADDRESS);
    } catch (e) {
      setHasAccountQueryFailed(true);
      track(e);
    }

    setLoading(false);
  }

  return (
    <form onSubmit={handleEnterEmailSubmit}>
      <div className="text-left" id="enter-email-body">
        <p>There&#39;s a sponsorship offer available for your league!</p>
        <p>To respond to the offer, we need you to follow these quick steps:</p>
        <ol>
          <li>Enter your email address</li>
          <li>Review the details of the offer</li>
          <li>
            Accept the offer by verifying your TeamSnap Sponsorship account OR
            reject the offer with a brief explanation
          </li>
        </ol>
        <hr />
        <label className="font-weight-bold" htmlFor="email">
          What&#39;s your email address?
        </label>
        <input
          className="form-control form-control-lg"
          id="email"
          onChange={(event) => setEmail(event.target.value)}
          required
          type="email"
          value={defaultToEmptyString(email)}
        />
        <small className="form-text text-muted">
          Please use the email address associated with your TeamSnap Sponsorship
          account if you already have one.
        </small>
      </div>
      <div className="mt-3 text-right">
        {hasAccountQueryFailed && (
          <ErrorAlert className="text-left">
            Oops! We had some trouble verifying your email address. If the
            problem persists, please&nbsp;
            <ContactUsLink />.
          </ErrorAlert>
        )}
        <button
          className={classNames('btn btn-lg btn-primary', {
            'font-italic': loading,
          })}
          disabled={!email || loading}
          id="view-offer-details"
          type="submit"
        >
          {loading ? 'Loading...' : 'Review Shipping Address'}
          {!loading && <Icon className="ml-2" value="arrow-right" />}
        </button>
      </div>
    </form>
  );
};

EnterEmailContent.propTypes = {
  email: PropTypes.string,
  fetchHasAccount: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
  setEmail: PropTypes.func.isRequired,
  setHasAccount: PropTypes.func.isRequired,
};

EnterEmailContent.defaultProps = {
  email: '',
};

export default EnterEmailContent;
