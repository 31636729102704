import React from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import { RiMailSendFill } from 'react-icons/ri';

const MUTATION = gql`
  mutation ResendUserInvite($input: ResendUserInviteInput!) {
    resendUserInvite(input: $input) {
      user {
        id
      }
    }
  }
`;

const ResendUserInviteModal = ({ id, email }) => {
  const [resendUserInvite] = useMutation(MUTATION, {
    variables: { input: { userId: id } },
  });

  async function asyncSubmit() {
    await resendUserInvite({ id });
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  return (
    <ModalForm
      id={`resent-user-invite-${id}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Resend'),
        className: 'btn btn-primary',
      }}
      title="Resend Invite to This User?"
      toggleProps={{
        className: 'ml-1 btn btn-link p-0',
        id: `resent-user-invite-${id}-modal-icon`,
        children: <RiMailSendFill />,
      }}
    >
      Are you sure you want to resend invite to&nbsp;
      <strong>{email}</strong>
      &nbsp;from this organization?
    </ModalForm>
  );
};

ResendUserInviteModal.propTypes = {
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
};

export default ResendUserInviteModal;
