import * as React from 'react';
import { map } from 'ramda';
import type { User } from 'types/user';
import HoverTable from 'components/HoverTable';
import UsersTableRow from './usersTable/UsersTableRow';

const noUsersMessage = (
  <p className="col mt-4 text-center font-weight-bold">
    There are no users to display for this organization
  </p>
);

interface Props extends React.HTMLAttributes<HTMLElement> {
  additionalColumns?(user: User): JSX.Element;
  additionalHeaders?: JSX.Element;
  addUserModal: JSX.Element;
  bodyClassname: 'card-body' | 'list-group-item';
  hasCardHeader?: boolean;
  users: User[];
}

const UsersTable: React.FunctionComponent<Props> = ({
  additionalColumns,
  additionalHeaders,
  addUserModal,
  bodyClassname,
  children,
  hasCardHeader,
  users,
}: Props) => {
  const headerRow = (
    <div className="row align-items-center">
      <h2 id="usersCardTitle" className="col mb-0">
        Users
      </h2>
      <div className="col-auto text-right">{addUserModal}</div>
    </div>
  );

  const bodyContent = (
    <>
      {children}
      {users.length ? (
        <HoverTable>
          <thead>
            <tr>
              <th scope="col">Email</th>
              <th scope="col">Name</th>
              <th scope="col">Title</th>
              <th scope="col">Cell Phone</th>
              <th scope="col">Status</th>
              {additionalHeaders}
            </tr>
          </thead>
          <tbody>
            {map(
              (user) => (
                <UsersTableRow
                  additionalColumns={additionalColumns}
                  key={user.id}
                  user={user}
                />
              ),
              users,
            )}
          </tbody>
        </HoverTable>
      ) : (
        noUsersMessage
      )}
    </>
  );

  return hasCardHeader ? (
    <>
      <div className="card-header">{headerRow}</div>
      <div className={bodyClassname}>{bodyContent}</div>
    </>
  ) : (
    <div className={bodyClassname}>
      {headerRow}
      {bodyContent}
    </div>
  );
};

UsersTable.defaultProps = {
  additionalColumns: undefined,
  additionalHeaders: undefined,
  hasCardHeader: false,
};

export default UsersTable;
