import * as React from 'react';

const QuantityGreaterThanVerificationDeadlineWarning: React.FunctionComponent =
  () => (
    <div
      className="form-text alert alert-warning"
      data-test="quantity-greater-than-verification-deadline-message"
    >
      Your quantity is greater than the total number of verification deadlines
      available to create. Please contact a superadmin or dev@leagueside.com
      before proceeding
    </div>
  );

export default QuantityGreaterThanVerificationDeadlineWarning;
