import gql from 'graphql-tag';

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    email
    name
    status
    title
    cellPhone
  }
`;

export default USER_FRAGMENT;
