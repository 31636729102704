import React from 'react';
import { Link } from 'react-router-dom';
import { startsWith } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { getHref } from 'utils/urls';
import Icon from 'components/Icon';

const isLinkPath = startsWith('/');

const NewWindowLink = (props) => {
  const { children, hideIcon, to, ...restProps } = props;
  const isLink = isLinkPath(to);
  const LinkComponent = isLink ? Link : 'a';
  const linkProps = isLink ? { to } : { href: getHref(to) };

  return (
    <LinkComponent
      rel="noopener noreferrer"
      target="_blank"
      {...linkProps}
      {...restProps}
    >
      {children}
      {!hideIcon && (
        <Icon className={classNames({ 'ml-2': !!children })} value="export" />
      )}
    </LinkComponent>
  );
};

NewWindowLink.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  hideIcon: PropTypes.bool,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

NewWindowLink.defaultProps = {
  children: null,
  hideIcon: false,
};

export default NewWindowLink;
