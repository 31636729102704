import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  COUNTRIES_LIST,
  COUNTRIES_SHORT_NAME_LIST,
  SHIPMENTS_ARRIVALS_LIST,
  STATES_LIST,
} from 'pages/qualify/utils';
import { useEffect } from 'react';
import { useWizardContext } from 'pages/qualify/provider';
import { Inputs } from 'pages/qualify/types';
import { geocodeByAddress } from 'react-places-autocomplete';
import { getAddressComponents } from 'components/PlacesAutocomplete';
import moment from 'moment';
import WizardStep from '../wizard-step';
import WizardInput from '../wizard-input';

export default function TournamentInformationStep() {
  const {
    organizationData,
    watch,
    values,
    setValues,
    setValue,
    setError,
    trigger,
    tokenData,
  } = useWizardContext();
  const [performMutation] = useMutation(MUTATION);
  const hostTournament = watch('hostTournament');

  const modifyInputsBeforeMutation = (
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    ...data,
    numberOfPlayers: Number(data.numberOfPlayers),
  });

  const geocodeAndSetLocation = async (addressToGeocode: string) => {
    const geocodedAddress = await geocodeByAddress(addressToGeocode);
    const { country, route, streetNumber, state, city, zipcode } =
      getAddressComponents(geocodedAddress[0]);
    if (['US', 'CA'].includes(country)) {
      // Set values according to the geocoded address
      setValue('address', [streetNumber, route].join(' ').trim());
      setValue(
        'country',
        COUNTRIES_SHORT_NAME_LIST[
          country as keyof typeof COUNTRIES_SHORT_NAME_LIST
        ] ?? '',
      );
      setValue('state', state ?? '');
      setValue('city', city ?? '');
      setValue('postalCode', zipcode ?? '');
      // Trigger validation for the fields that were set
      trigger('country');
      trigger('state');
      trigger('city');
      trigger('postalCode');
    } else {
      setError('address', {
        type: 'manual',
        message: 'Address must be in the US or Canada',
      });
    }
  };

  useEffect(() => {
    /*
      Necessary to set the hostTournament value to true if the tournament is not present
      because this data is not being fetched again at the first walkthrough,
      later we can just rely again on the BE data
    */
    if (
      organizationData.id &&
      !organizationData.sponsorshipApplication?.tournament
    ) {
      setValues({
        ...values,
        hostTournament: true,
      });
    }
  }, [organizationData]);

  return (
    <WizardStep
      performMutation={performMutation}
      mutationStaticIds={{
        sponsorshipApplicationId: organizationData?.sponsorshipApplication?.id,
        offerId: tokenData.offer_id,
      }}
      modifyInputsBeforeMutation={modifyInputsBeforeMutation}
    >
      <WizardStep.Content title="Tournament information">
        <div className="sui-mb-6">
          <WizardInput
            type="boolean-question"
            name="hostTournament"
            label="My organization organizes one or more tournaments in the upcoming season."
          />
        </div>

        {hostTournament && (
          <>
            <h2 className="sui-text-desktop-6 sui-font-bold sui-mb-3">
              Tournament details
            </h2>
            <div className="sui-grid sui-mb-8" style={{ gap: 24 }}>
              <WizardInput
                type="text"
                name="name"
                label="Name"
                rules={{ required: 'Name is required' }}
              />
              <div
                className="sui-grid"
                style={{ gap: 16, gridAutoFlow: 'column' }}
              >
                <WizardInput
                  type="date"
                  name="firstDate"
                  label="First Date"
                  triggerAnotherInput="lastDate"
                  rules={{
                    required: 'First date is required',
                    validate: {
                      todayOrAfterToday: (value) => {
                        const pickedDate = moment(value);
                        const today = moment().startOf('day');
                        if (pickedDate.isBefore(today)) {
                          return 'First Date must be today or later';
                        }
                        return true;
                      },
                      beforeLastDate: (value, formValues) => {
                        if (new Date(value) > new Date(formValues.lastDate)) {
                          return 'First Date must be before Last Date';
                        }
                        return true;
                      },
                    },
                  }}
                />
                <WizardInput
                  type="date"
                  name="lastDate"
                  label="Last Date"
                  triggerAnotherInput="firstDate"
                  rules={{
                    required: 'Last date is required',
                    validate: {
                      todayOrAfterToday: (value) => {
                        const pickedDate = moment(value);
                        const today = moment().startOf('day');
                        if (pickedDate.isBefore(today)) {
                          return 'Last Date must be today or later';
                        }
                        return true;
                      },
                      afterFirstDate: (value, formValues) => {
                        if (new Date(value) < new Date(formValues.firstDate)) {
                          return 'Last date must be after First Date';
                        }
                        return true;
                      },
                    },
                  }}
                />
              </div>
              <WizardInput
                type="number"
                name="numberOfPlayers"
                label="Estimated number of player participants"
                rules={{
                  required:
                    'Estimated number of player participants is required',
                }}
              />
            </div>

            <h2 className="sui-text-desktop-6 sui-font-bold sui-mb-3">
              Primary address of tournament venue
            </h2>
            <div className="sui-grid sui-mb-8" style={{ gap: 24 }}>
              <WizardInput
                type="address"
                name="address"
                label="Address"
                rules={{ required: 'Address is required' }}
                onSelectAddressLocation={(placesLocation: string) =>
                  geocodeAndSetLocation(placesLocation)
                }
              />
              <div
                className="sui-grid"
                style={{
                  gridTemplateColumns: '1fr 180px',
                  gap: 16,
                }}
              >
                <WizardInput
                  type="text"
                  name="city"
                  label="City"
                  rules={{ required: 'City is required' }}
                />
                <WizardInput
                  type="select"
                  name="state"
                  label="State/Province"
                  rules={{ required: 'State/Province is required' }}
                  options={STATES_LIST}
                />
              </div>
              <div
                className="sui-grid sui-mb-2"
                style={{
                  gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                  gap: 16,
                }}
              >
                <WizardInput
                  type="text"
                  name="postalCode"
                  label="Zip/Postal Code"
                  rules={{ required: 'Zip/Postal Code is required' }}
                />
                <WizardInput
                  type="select"
                  name="country"
                  label="Country"
                  rules={{ required: 'Country is required' }}
                  options={COUNTRIES_LIST}
                />
              </div>
            </div>

            <h2 className="sui-text-desktop-6 sui-font-bold sui-mb-3">
              Logistics
            </h2>
            <div className="sui-grid sui-mb-8" style={{ gap: 24 }}>
              <WizardInput
                type="boolean-question"
                name="freeParking"
                label="Complimentary parking for sponsors available at venue"
              />
              <WizardInput
                type="boolean-question"
                name="canShipInAdvance"
                label="Venue accepts shipments from sponsors prior to tournament"
              />
              <WizardInput
                type="select"
                name="shipmentsArrival"
                label="How soon before the tournament can sponsors’ shipments arrive"
                options={SHIPMENTS_ARRIVALS_LIST}
              />
              <WizardInput
                type="boolean-question"
                name="requireCoi"
                label="Sponsors are required to provide a Certificate of Insurance (COI)"
              />
              <WizardInput
                type="boolean-question"
                name="hasContingencyPlan"
                label="Contingency plan in place to handle unexpected disruptions, such as inclement weather"
              />
            </div>
          </>
        )}
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation upsertTournament($input: UpsertTournamentInput!) {
    upsertTournament(input: $input) {
      __typename
      id
    }
  }
`;
