import { map, path } from 'ramda';
import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import Page from 'components/Page';
import Card from 'components/Card';
import VerificationsReportLink from 'components/VerificationsReportLink';

const getSeasonToEnumeration = path(['data', 'seasons']);
const getSeasonYears = path(['data', 'seasonYears']);

const QUERY = gql`
  query VerificationsIndex {
    seasons {
      winter
      spring
      summer
      fall
    }
    seasonYears {
      primaryStrategistContacts {
        id
        name
      }
      season
      year
    }
  }
`;

const VerificationsIndex = () => (
  <Query query={QUERY} fetchPolicy="network-only">
    {(result) => {
      const seasonYears = getSeasonYears(result);
      const seasonToEnumeration = getSeasonToEnumeration(result);

      function renderSeasonYear(seasonYear) {
        const season = seasonToEnumeration[seasonYear.season];
        const { year } = seasonYear;

        const renderUserListItem = (user) => (
          <li key={user.id}>
            <VerificationsReportLink
              params={{ season, year, strategist: user.name }}
            >
              {user.name}
            </VerificationsReportLink>
          </li>
        );

        return (
          <div key={`${seasonYear.season} ${seasonYear.year}`} className="">
            <h2>
              <VerificationsReportLink params={{ season, year }}>
                {seasonYear.season} {seasonYear.year}
              </VerificationsReportLink>
            </h2>
            <ul>
              {map(renderUserListItem, seasonYear.primaryStrategistContacts)}
            </ul>
          </div>
        );
      }

      return (
        <Page loaded={!!seasonYears}>
          {seasonYears && (
            <>
              <h1 className="mt-4" id="title">
                Verifications
              </h1>
              <Card>{map(renderSeasonYear, seasonYears)}</Card>
            </>
          )}
        </Page>
      );
    }}
  </Query>
);

export default VerificationsIndex;
