import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const dataConfidenceProps = {
  verified: {
    className: 'text-primary',
    title: 'We have all the information we need!',
  },
  complete: {
    className: 'text-primary',
    title: 'We have all the information we need!',
  },
  ended: {
    className: 'text-primary',
    title: 'Dates for this season have passed',
  },
  created_from_other_sp: {
    className: 'text-warning',
    title: 'We need more information',
  },
  stale: { className: 'text-warning', title: 'We need more information' },
  incomplete: { className: 'text-warning', title: 'We need more information' },
  speculative: { className: 'text-warning', title: 'We need more information' },
  added_from_prospecting: {
    className: 'text-warning',
    title: 'We need more information',
  },
  added_by_organization: {
    className: 'text-warning',
    title: 'We need more information',
  },
};

function DataConfidenceIndicatorForOrgs({ dataConfidence }) {
  const { className, title } = dataConfidenceProps[dataConfidence];

  return (
    <Icon
      className={`${className} mr-1`}
      data-test={dataConfidence}
      title={title}
      value="controller-record"
    />
  );
}

DataConfidenceIndicatorForOrgs.propTypes = {
  dataConfidence: PropTypes.string.isRequired,
};

export default DataConfidenceIndicatorForOrgs;
