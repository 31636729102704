import { FormEvent, useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import type { Offer } from 'types/offer';
import AddButton from 'components/AddButton';
import SPONSORSHIP_EVENT_FRAGMENT from 'fragments/SponsorshipEventFragment';

const INITIAL_STATE = { description: '', adding: false };

type CreateLogCommentFormProps = {
  offer: Offer;
};

const CreateLogCommentForm = (props: CreateLogCommentFormProps) => {
  const { offer } = props;
  const [state, setState] = useState(INITIAL_STATE);
  const [mutate] = useMutation(MUTATION);

  const setDescription = (event: any) =>
    setState({ ...state, description: event.target.value });

  const handleCreate = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const timeoutID = setTimeout(
      () => setState({ ...state, adding: true }),
      200,
    );
    await mutate({
      variables: { input: { offerId: offer.id, description } },
    });
    clearTimeout(timeoutID);
    setState(INITIAL_STATE);
  };

  const { adding, description } = state;

  return (
    <form onSubmit={handleCreate}>
      <textarea
        className="form-control mb-2"
        data-test="new-comment-input"
        onChange={setDescription}
        required
        value={description}
      />
      <AddButton
        disabled={!description.length}
        adding={adding}
        subject="Comment"
      />
    </form>
  );
};

export const MUTATION = gql`
  mutation CreateLogComment($input: CreateLogCommentInput!) {
    createLogComment(input: $input) {
      offer {
        id
        sponsorshipEvents {
          ...SponsorshipEventFragment
        }
      }
    }
  }
  ${SPONSORSHIP_EVENT_FRAGMENT}
`;

export default CreateLogCommentForm;
