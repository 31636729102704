import React, { useEffect, useReducer, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { Prompt } from 'react-router-dom';
import { always, isEmpty, map, merge, omit, pickBy } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { unsavedChangesPrompt } from 'utils/misc';
import { hubspotUrl, salesforceUrl } from 'utils/urls';
import EventsSurveyResponsesLinkDropdown from 'components/EventsSurveyResponsesLinkDropdown';
import Icon from 'components/Icon';
import NewWindowLink from 'components/NewWindowLink';
import RestrictTo from 'components/RestrictTo';
import classNames from 'classnames';
import SaveFooter from 'components/SaveFooter';
import { reducer } from 'utils/reducers/overview.reducer';
import DataConfidence from './overview/DataConfidence';
import DataConfidenceReason from './overview/DataConfidenceReason';
import OrganizationType from './overview/OrganizationType';
import OrganizationLocation from './overview/OrganizationLocation';
import SocialMedia from './overview/SocialMedia';
import AgeRange from './overview/AgeRange';

const ORGANIZATION_OVERVIEW_FRAGMENT = gql`
  fragment OrganizationOverviewFragment on Organization {
    id
    city
    contactEmail
    contactPhone
    dataConfidence
    female
    male
    maxAge
    minAge
    name
    numberOfUniqueParticipantsPerYear
    organizationTypeId
    socialMediaFollowingSize
    emailListSize
    salesforceId
    sportsManagementSoftwareId
    socialMedia {
      id
      canPost
      channel {
        id
      }
      url
    }
    state
    websiteUrl
  }
`;

const ORGANIZATION_QUERY = gql`
  query OrganizationOverview($id: Int!) {
    organization(id: $id) {
      ...OrganizationOverviewFragment
      eventsSurveyResponses {
        submittedAt
        surveyGizmoResponseId
      }
      hubspotCompanyId
    }
    sportsManagementSoftwares {
      id
      name
    }
  }
  ${ORGANIZATION_OVERVIEW_FRAGMENT}
`;

const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        ...OrganizationOverviewFragment
      }
    }
  }
  ${ORGANIZATION_OVERVIEW_FRAGMENT}
`;

const toSocialMediaInput = (socialMedia) => ({
  id: socialMedia.id,
  canPost: socialMedia.canPost,
  channel: { id: socialMedia.channel.id },
  url: socialMedia.url,
});

const renderSportsAppProviderOption = (sportsAppProvider) => (
  <option key={sportsAppProvider.id} value={sportsAppProvider.id}>
    {sportsAppProvider.name}
  </option>
);

const Overview = ({ organizationId }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const [mutate] = useMutation(UPDATE_ORGANIZATION_MUTATION);
  const { data, loading } = useQuery(ORGANIZATION_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: organizationId },
    onCompleted: (response) => {
      dispatch({
        type: 'initial data',
        payload: response.organization || null,
      });
    },
  });
  const [errors, setErrors] = useState({});

  const mutationOnSave = (changes) => {
    const input = omit(['socialMedia'], changes);
    if ('socialMedia' in changes)
      input.socialMedia = changes.socialMedia.map(toSocialMediaInput);
    input.organizationId = organizationId;
    mutate({ variables: { input } });
  };

  const organization = data && data.organization;
  const sportsAppProviders = data && data.sportsManagementSoftwares;

  const [changes, setChanges] = useState({});
  const [saved, setSaved] = useState(null);
  const [saving, setSaving] = useState(false);
  const disabled = () =>
    (!state.dataConfidenceReason &&
      organization.dataConfidence !== state.dataConfidence) ||
    Object.keys(errors).length > 0;

  const addChanges = (newChanges) => {
    const isChanged = (value, key) => organization[key] !== value;

    setChanges((prevState) => ({
      ...prevState,
      ...pickBy(isChanged, merge(prevState, newChanges)),
    }));
  };

  const socialMediaExistsOnObject = (obj) => 'socialMedia' in obj;

  const createSocialMediaAndAddToChanges = (newChanges) => {
    setChanges((previousState) => {
      if (socialMediaExistsOnObject(previousState)) {
        // eslint-disable-next-line no-param-reassign
        previousState.socialMedia.push(newChanges.socialMedia);
      } else {
        // eslint-disable-next-line no-param-reassign
        previousState.socialMedia = [newChanges.socialMedia];
      }

      return { ...previousState };
    });
  };

  const updateSocialMediaChanges = (newChanges) => {
    const socialMediaWithChanges = newChanges;
    // eslint-disable-next-line max-len
    const currentSocialMedia = state.socialMedia.find(
      (obj) => obj.id || obj.temporaryId === socialMediaWithChanges.id,
    );

    setChanges((previousState) => {
      const { socialMedia } = previousState;

      if (!socialMediaExistsOnObject(changes)) {
        // eslint-disable-next-line no-param-reassign
        previousState.socialMedia = [currentSocialMedia];
      } else {
        const newSocialMediaState = socialMedia.map((obj) => {
          if (
            (obj.id || obj.temporaryId) ===
            (currentSocialMedia.id || currentSocialMedia.temporaryId)
          ) {
            // eslint-disable-next-line no-param-reassign
            obj = currentSocialMedia;
          }

          return obj;
        });

        // eslint-disable-next-line no-param-reassign
        previousState.socialMedia = newSocialMediaState;
      }

      return { ...previousState };
    });
  };

  const removeSocialMediaFromChanges = (newChanges) => {
    setChanges((previousState) => {
      const { socialMedia } = previousState;

      const findIndex = socialMedia.findIndex(
        (obj) => (obj.id || obj.temporaryId) === newChanges.id,
      );

      socialMedia.splice(findIndex, 1);

      return { ...previousState };
    });
  };

  const resetState = () => {
    setChanges({});
    setSaving(false);
  };

  useEffect(() => {
    if (organization && organization.socialMedia.length) {
      setChanges((previousState) => {
        // eslint-disable-next-line no-undef, no-param-reassign
        previousState.socialMedia = JSON.parse(
          JSON.stringify(organization.socialMedia),
        );

        return { ...previousState };
      });
    }
  }, [data, organization]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // eslint-disable-next-line no-param-reassign
      if (!saved) event.returnValue = unsavedChangesPrompt;

      return event.returnValue;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return function cleanup() {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [saved]);

  useEffect(() => {
    setSaved(isEmpty(changes));
  }, [changes]);

  const save = async () => {
    const timeoutID = setTimeout(() => setSaving(true), 500);
    try {
      await mutationOnSave(changes);
      resetState();
    } catch (catchError) {
      setSaving(false);
    }
    clearTimeout(timeoutID);
  };

  const handleChange = (type, event) => {
    dispatch({ type, payload: event || null });

    // TODO: Migrate this to be handled with react-hooks-form
    if (type === 'update contactEmail') {
      const emails = event.contactEmail.split(',');
      const thereIsAnError = emails.some(
        (email) =>
          email &&
          !email.trim().match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i),
      );
      if (thereIsAnError) {
        setErrors({ contactEmail: 'One or more emails are invalid' });
      } else {
        setErrors({});
      }
    }
    addChanges(event);
  };

  const handleSocialMediaChange = (type, action, event) => {
    dispatch({ type, payload: event || null });

    if (action === 'create') {
      createSocialMediaAndAddToChanges(event);
    } else if (action === 'update') {
      updateSocialMediaChanges(event);
    } else if (action === 'remove') {
      removeSocialMediaFromChanges(event);
    }
  };

  const renderInputs = () => (
    <div className="list-group list-group-flush">
      <div className="list-group-item">
        <label
          className="font-weight-bold float-left"
          htmlFor="organization-name"
        >
          Organization Name
        </label>
        <RestrictTo roles={['admin']}>
          <span className="float-right">
            <EventsSurveyResponsesLinkDropdown
              eventsSurveyResponses={state.eventsSurveyResponses}
            />
          </span>
        </RestrictTo>
        <input
          className="form-control form-control-lg mb-2"
          id="organization-name"
          onChange={(event) =>
            handleChange('update name', { name: event.target.value })
          }
          placeholder="Hometown Organization"
          type="text"
          value={state.name}
          disabled={saving}
        />
      </div>
      <OrganizationType
        addChanges={handleChange}
        disabled={saving}
        organizationTypeId={state.organizationTypeId}
      />
      <RestrictTo roles={['admin']}>
        <div className="list-group-item">
          <label
            className="font-weight-bold"
            htmlFor="sportsManagementSoftware"
          >
            Sports Management Software
          </label>
          <select
            className="form-control"
            id="sports-managment-software"
            onChange={(event) =>
              handleChange('update sportsManagementSoftwareId', {
                sportsManagementSoftwareId: event.target.value
                  ? Number(event.target.value)
                  : null,
              })
            }
            value={state.sportsManagementSoftwareId || ''}
          >
            <option value="">
              -- Please choose a Sports Management Software --
            </option>
            {map(renderSportsAppProviderOption, sportsAppProviders)}
          </select>
        </div>
      </RestrictTo>

      <div className="list-group-item">
        <label className="font-weight-bold" htmlFor="contactInformation">
          Contact Information
        </label>
        <RestrictTo roles={['admin']}>
          <span className="float-right">
            {!!state.hubspotCompanyId && (
              <NewWindowLink to={hubspotUrl(state.hubspotCompanyId)}>
                View HubSpot Company
              </NewWindowLink>
            )}
          </span>
          <div className="mt-2">
            <label className="font-weight-bold" htmlFor="salesforceId">
              SalesForce ID
            </label>
            <span className="float-right">
              {!!data.organization.salesforceId && (
                <NewWindowLink
                  to={salesforceUrl(data.organization.salesforceId)}
                >
                  View SalesForce Account
                </NewWindowLink>
              )}
            </span>
            <div className="input-group mb-2">
              <input
                className={classNames('form-control')}
                id="salesforceId"
                onChange={(event) =>
                  handleChange('update salesforceId', {
                    salesforceId: event.target.value,
                  })
                }
                placeholder="0011K..."
                type="text"
                value={state.salesforceId || ''}
                disabled={saving}
                data-testid="salesforceId"
              />
            </div>
          </div>
        </RestrictTo>
        <div className="mt-2">
          <label className="font-weight-bold" htmlFor="contactEmail">
            General Email Address
          </label>
          <div className="input-group mb-2">
            <input
              className={classNames('form-control', {
                'is-invalid': errors.contactEmail,
              })}
              id="contactEmail"
              onChange={(event) =>
                handleChange('update contactEmail', {
                  contactEmail: event.target.value,
                })
              }
              placeholder="hometownorganization@example.com"
              type="email"
              value={state.contactEmail || ''}
              disabled={saving}
              data-testid="contactEmail"
            />
          </div>
          {errors.contactEmail && (
            <div
              className="sui-text-red-6 sui-text-desktop-3"
              data-testid="contactEmailError"
            >
              {errors.contactEmail}
            </div>
          )}
        </div>
        <div className="mt-3">
          <label className="font-weight-bold" htmlFor="contactPhone">
            General Phone Number
          </label>
          <div className="input-group mb-2">
            <input
              className="form-control"
              id="contactPhone"
              onChange={(event) =>
                handleChange('update contactPhone', {
                  contactPhone: event.target.value,
                })
              }
              placeholder="555-555-5555"
              type="tel"
              value={state.contactPhone || ''}
              disabled={saving}
            />
          </div>
        </div>
      </div>
      <div className="list-group-item">
        <label className="font-weight-bold" htmlFor="websiteUrl">
          Website (format: http://example.com)
        </label>
        <div className="input-group mb-2">
          <input
            className="form-control"
            id="websiteUrl"
            onChange={(event) =>
              handleChange('update websiteUrl', {
                websiteUrl: event.target.value,
              })
            }
            placeholder="http://hometownorganization.example.com"
            type="text"
            value={state.websiteUrl || ''}
            disabled={saving}
          />
          <div className="input-group-append">
            <a
              className="btn btn-primary"
              href={state.websiteUrl || ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon value="export" />
            </a>
          </div>
        </div>
      </div>
      <div className="list-group-item">
        <div className="row">
          <div className="col">
            <label
              className="font-weight-bold"
              htmlFor="numberOfUniqueParticipantsPerYear"
            >
              Number of Unique Participants Per Year
            </label>
            <input
              className="form-control mb-2"
              id="numberOfUniqueParticipantsPerYear"
              onChange={(event) =>
                handleChange('update numberOfUniqueParticipantsPerYear', {
                  numberOfUniqueParticipantsPerYear:
                    Number(event.target.value) || null,
                })
              }
              placeholder="500"
              type="number"
              value={state.numberOfUniqueParticipantsPerYear || ''}
            />
          </div>
          <div className="col">
            <strong>Age Range</strong>
            <AgeRange maxAge={state.maxAge} minAge={state.minAge} />
          </div>
          <div className="col">
            <strong>Genders</strong>
            <div className="form-check">
              <input
                checked={state.female}
                className="form-check-input"
                id="female"
                onChange={(event) =>
                  handleChange('update female', { female: event.target.value })
                }
                type="checkbox"
                disabled
              />
              <label className="form-check-label" htmlFor="female">
                Female
              </label>
            </div>
            <div className="form-check">
              <input
                checked={state.male}
                className="form-check-input"
                id="male"
                onChange={(event) =>
                  handleChange('update male', { male: event.target.value })
                }
                type="checkbox"
                disabled
              />
              <label className="form-check-label" htmlFor="male">
                Male
              </label>
            </div>
          </div>
        </div>
      </div>
      <RestrictTo roles={['admin']}>
        <div className="list-group-item">
          <SocialMedia
            handleSocialMediaChange={handleSocialMediaChange}
            addChanges={handleChange}
            socialMedia={state.socialMedia}
            socialMediaFollowingSize={state.socialMediaFollowingSize}
            emailListSize={state.emailListSize}
          />
        </div>
        <OrganizationLocation
          addChanges={handleChange}
          city={state.city}
          disabled={saving}
          state={state.state}
        />
        <div className="list-group-item">
          <DataConfidence
            addChanges={handleChange}
            dataConfidence={state.dataConfidence}
            disabled={saving}
          />
          {organization.dataConfidence === state.dataConfidence ? null : (
            <DataConfidenceReason
              addChanges={handleChange}
              dataConfidenceReason={state.dataConfidenceReason}
              disabled={saving}
            />
          )}
        </div>
      </RestrictTo>
    </div>
  );

  return !loading && organization ? (
    <>
      <Prompt message={always(unsavedChangesPrompt)} when={!saved} />
      {renderInputs()}
      <SaveFooter
        disabled={disabled()}
        save={save}
        saving={saving}
        saved={saved}
      />
    </>
  ) : null;
};

Overview.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default Overview;
