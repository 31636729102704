import * as React from 'react';
import type { User as UserType } from 'types/user';
import UsersTable from 'components/UsersTable';
import AddSponsorUserModal from './AddSponsorUserModal';
import UsersTableAdditionalColumns from './UsersTableAdditionalColumns';

interface User extends UserType {
  lastLogin?: Date;
}
interface Sponsor {
  id: number;
  users: User[];
}

interface Props {
  sponsor: Sponsor;
}

const SponsorUsersTab: React.FunctionComponent<Props> = ({
  sponsor,
}: Props) => {
  const { id: sponsorId, users } = sponsor;

  return (
    <div className="card">
      <UsersTable
        additionalColumns={(user) => (
          <UsersTableAdditionalColumns user={user} />
        )}
        additionalHeaders={
          <>
            <th scope="col">Last Login</th>
            <th scope="col" className="text-center">
              Dashboard
            </th>
          </>
        }
        addUserModal={<AddSponsorUserModal sponsorId={sponsorId} />}
        bodyClassname="card-body"
        users={users}
      />
    </div>
  );
};

export default SponsorUsersTab;
