import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ORGANIZATION_DASHBOARD_PATH } from 'utils/paths';
import ContactUsLink from 'components/ContactUsLink';

const RejectConfirmationContent = ({ hasAccount, loggedIn }) => (
  <div className="text-left" id="reject-confirmation-body">
    <h4>You have successfully rejected the offer!</h4>
    <p>
      All active users associated with your organization have been sent
      notification emails.
    </p>
    {hasAccount ? (
      <p>
        To manage your organization further, please&nbsp;
        {loggedIn ? (
          <>
            return to&nbsp;
            <Link to={ORGANIZATION_DASHBOARD_PATH}>your dashboard</Link>.
          </>
        ) : (
          <>
            <Link to="/login">login</Link>
            &nbsp;with your account.
          </>
        )}
      </p>
    ) : (
      <p>
        If you are interested in creating your own TeamSnap Sponsorship account,
        please either reach out to us or ask a member of your organization with
        an active account to add you from their organization dashboard.
      </p>
    )}
    <p>
      For any further questions, please&nbsp;
      <ContactUsLink />.
    </p>
  </div>
);

RejectConfirmationContent.propTypes = {
  hasAccount: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
};

export default RejectConfirmationContent;
