import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import hideModal from 'utils/hideModal';
import {
  addressOnly,
  addressesEqual,
  getInitialDeliverableAddress,
  isAddressIncomplete,
  validatedAddressMessage,
  validatedAddressErrorMessage,
} from 'utils/deliverableAddress';
import { capitalize, conjugateString } from 'utils/string';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import Icon from 'components/Icon';
import Alert from 'components/Alert';
import ErrorAlert from 'components/ErrorAlert';
import DeliverableAddressFormInputs from 'components/DeliverableAddressFormInputs';

const VERIFY_ADDRESS = gql`
  query VerifyAddress($input: VerifyAddressInput!) {
    verifyAddress(input: $input) {
      name
      line1
      line2
      city
      state
      zip
      deliverability
      country
    }
  }
`;

const DeliverableAddressModal = ({
  addressType,
  organization,
  upsertAddress,
}) => {
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState(
    getInitialDeliverableAddress(organization, addressType),
  );
  const [addressVerified, setAddressVerified] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const { refetch } = useQuery(VERIFY_ADDRESS, {
    skip: !loading,
    fetchPolicy: 'no-cache',
    variables: { input: addressOnly(address) },
  });

  const modalId = `${addressType}-address-modal`;

  const shouldDisableSave = loading || isAddressIncomplete(address);

  async function handleSubmit(event) {
    event.preventDefault();

    setLoading(true);
    setAddressVerified(false);
    setAddressError(false);

    let verifiedAddress = {};

    try {
      const newData = await refetch();
      verifiedAddress = newData.data.verifyAddress;
    } catch (exception) {
      setAddressError(true);
      setLoading(false);
      return;
    }

    setAddress(verifiedAddress);

    if (addressesEqual(verifiedAddress, address)) {
      await upsertAddress({
        variables: {
          input: {
            ...addressOnly(verifiedAddress),
            deliverability: verifiedAddress.deliverability,
            addressType,
            organizationId: organization.id,
          },
        },
      });
      hideModal(`#${modalId}`);
    } else {
      setAddressVerified(true);
    }

    setLoading(false);
  }

  const addressVerifiedAlert = (
    <Alert alertStyle="success" role="alert" data-test="address-verified-alert">
      {validatedAddressMessage}
    </Alert>
  );
  const addressErrorAlert = (
    <ErrorAlert role="alert" data-test="address-error-alert">
      {validatedAddressErrorMessage}
    </ErrorAlert>
  );

  return (
    <>
      <button
        className="btn btn-link"
        data-target={`#${modalId}`}
        data-toggle="modal"
        type="button"
      >
        <Icon value="pencil" className="mr-1" />
        Edit
      </button>
      <Modal id={modalId} title={`Update ${capitalize(addressType)} Address`}>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            {addressVerified && addressVerifiedAlert}
            {addressError && addressErrorAlert}
            <DeliverableAddressFormInputs
              deliverableAddress={address}
              disabled={loading}
              inputLabel={`${addressType}-address`}
              setDeliverableAddress={setAddress}
            />
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary"
              data-test={`${modalId}-submit`}
              disabled={shouldDisableSave}
              type="submit"
            >
              {conjugateString('Save', loading)}
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

DeliverableAddressModal.propTypes = {
  addressType: PropTypes.oneOf(['mailing', 'shipping']).isRequired,
  organization: PropTypes.shape({
    mailingAddress: LeagueSidePropTypes.deliverableAddress,
    name: PropTypes.string.isRequired,
    shippingAddress: LeagueSidePropTypes.deliverableAddress,
    id: PropTypes.number.isRequired,
  }).isRequired,
  upsertAddress: PropTypes.func.isRequired,
};

export default DeliverableAddressModal;
