import { createReducer } from 'redux-create-reducer';

function populate(state, { payload }) {
  return payload.reduce(
    (byId, record) => ({ ...byId, [record.id]: record }),
    {},
  );
}

const createByIdReducer = (populateActionType) =>
  createReducer(
    {},
    {
      [populateActionType]: populate,
    },
  );

export default createByIdReducer;
