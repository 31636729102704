import { equals, length, pick, prop } from 'ramda';
import { COUNTRY_NAMES_BY_ALPHA2_CODE, equalsUS } from 'utils/country';

export const addressOnly = pick([
  'name',
  'line1',
  'line2',
  'city',
  'state',
  'zip',
  'country',
]);
export const addressesEqual = (a, b) => equals(addressOnly(a), addressOnly(b));

export const MAX_NAME_LENGTH = 40;
export const nameIsTooLong = ({ name }) => length(name) > MAX_NAME_LENGTH;

export const validatedAddressMessage =
  'Your address has been validated. Check to make sure it looks correct, then review offer details.';
export const validatedAddressErrorMessage =
  "There was a problem validating your address, and we could not locate it with the mailing validation service. In order to validate your address, you'll need to provide either a city and state or a zip code. If your mailing address has a unit number, please make sure you have included that as well.";

export const STATES = [
  'AL',
  'AK',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const formatDeliverableAddressSingleLine = (deliverableAddress) => {
  const { line1, city, state, zip, country } = deliverableAddress;
  const formattedAddress = `${line1}, ${city}, ${state} ${zip}`;
  return equalsUS(country)
    ? formattedAddress
    : `${formattedAddress}, ${COUNTRY_NAMES_BY_ALPHA2_CODE[country]}`;
};

export const formatDeliverableAddressMultiLineUS = ({
  name,
  city,
  line1,
  state,
  zip,
}) => `${name}
${line1}
${city}, ${state} ${zip}`;

export const formatDeliverableAddressMultiLineInternational = (
  deliverableAddress,
) => {
  const { country } = deliverableAddress;
  return `${formatDeliverableAddressMultiLineUS(deliverableAddress)}
${COUNTRY_NAMES_BY_ALPHA2_CODE[country]}`;
};

const getDefaultDeliverableAddress = (name) => ({
  name: length(name) > MAX_NAME_LENGTH ? '' : name,
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: '',
  country: 'US',
});
export const getInitialDeliverableAddress = (organization, addressType) =>
  prop(`${addressType}Address`, organization) ||
  getDefaultDeliverableAddress(organization.name);

export const isAddressIncomplete = (deliverableAddress) => {
  const { name, line1, city, state, zip } = deliverableAddress;
  return (
    !name ||
    !line1 ||
    !((city && state) || zip) ||
    nameIsTooLong(deliverableAddress)
  );
};
