import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';

const MUTATION = gql`
  mutation DeleteTestimonial($input: DeleteTestimonialInput!) {
    deleteTestimonial(input: $input) {
      campaign {
        id
        testimonials {
          id
        }
      }
    }
  }
`;

const TestimonialDestroynModal = ({ $, testimonialId }) => {
  const [mutate] = useMutation(MUTATION, {
    fetchPolicy: 'no-cache',
  });

  const onSubmit = async (event) => {
    event.preventDefault();

    await mutate({
      variables: {
        input: {
          id: testimonialId,
        },
      },
    });

    $(`#testimonial-${testimonialId}-delete-modal`).modal('hide');
  };

  return (
    <>
      <button
        className="btn btn-link text-danger bl-2"
        data-target={`#testimonial-${testimonialId}-delete-modal`}
        data-test="testimonial-delete-modal"
        data-toggle="modal"
        type="button"
      >
        <span className="icon icon-trash" />
      </button>
      <Modal
        id={`testimonial-${testimonialId}-delete-modal`}
        title="Delete a Testimonial"
      >
        <form onSubmit={onSubmit}>
          <ModalBody>
            Are you sure you want to delete this testmonial? This is permanent.
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              data-test={`delete-testimonial-${testimonialId}-modal-cancel`}
              data-dismiss="modal"
              type="button"
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              data-test={`delete-testimonial-${testimonialId}-modal-confirm`}
              type="submit"
            >
              Delete
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

TestimonialDestroynModal.defaultProps = {
  $: global.$,
};

TestimonialDestroynModal.propTypes = {
  $: PropTypes.func,
  testimonialId: PropTypes.number.isRequired,
};

export default TestimonialDestroynModal;
