import React from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatExactCurrency } from 'utils/formatNumber';
import formatDate from 'utils/formatDate';
import { formatDeliverableAddressSingleLine } from 'utils/deliverableAddress';

const CheckModalTable = ({ check }) => {
  const { offer } = check;
  const { sponsorableProperty } = offer;
  const { organization } = sponsorableProperty;
  const { mailingAddress } = organization;

  return (
    <table className="table">
      <tbody>
        <tr>
          <td className="font-weight-bold">Amount</td>
          <td>{formatExactCurrency(check.amount)}</td>
        </tr>
        <tr>
          <td className="font-weight-bold">Organization</td>
          <td>{organization.name}</td>
        </tr>
        {!!mailingAddress && organization.name !== mailingAddress.name && (
          <tr>
            <td className="font-weight-bold">Recipient</td>
            <td>{mailingAddress.name}</td>
          </tr>
        )}
        {!!mailingAddress && (
          <tr>
            <td className="font-weight-bold">Address</td>
            <td>{formatDeliverableAddressSingleLine(mailingAddress)}</td>
          </tr>
        )}
        <tr>
          <td className="font-weight-bold">Campaign</td>
          <td>{`${offer.campaign.sponsor.name} - ${offer.campaign.name}`}</td>
        </tr>
        <tr>
          <td className="font-weight-bold">Memo</td>
          <td>{check.memo}</td>
        </tr>
        <tr>
          <td className="font-weight-bold">Created</td>
          <td>
            {`By ${check.createdBy.name} on ${formatDate(check.createdAt)}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

CheckModalTable.propTypes = {
  check: LeagueSidePropTypes.check.isRequired,
};

export default CheckModalTable;
