import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { isConfirmed } from 'utils/offer';
import { ORGANIZATION_DASHBOARD_PATH } from 'utils/paths';
import Page from 'components/Page';
import OrganizationDashboardWrapper from 'components/OrganizationDashboardWrapper';
import ORGANIZATION_ADDRESSES_FRAGMENT, {
  LEAGUE_ADDRESSES_ASSET_FRAGMENT,
} from 'components/organizationDashboardWrapper/LeagueAddressesFragment';
import ConfirmedOffer from './leagueOffer/ConfirmedOffer';
import OfferErrorAlert from './leagueOffer/OfferErrorAlert';

export const ConfirmedOfferOverviewUnwrapped = ({
  offer,
  refetch,
  presenterFactory,
}) =>
  isConfirmed(offer) ? (
    <ConfirmedOffer
      offer={offer}
      refetch={refetch}
      presenterFactory={presenterFactory}
    />
  ) : (
    <OfferErrorAlert />
  );

ConfirmedOfferOverviewUnwrapped.propTypes = {
  offer: LeagueSidePropTypes.offer.isRequired,
  refetch: PropTypes.func.isRequired,
  presenterFactory: PropTypes.instanceOf(Array).isRequired,
};

const QUERY = gql`
  query Offer($id: Int!) {
    offer(id: $id) {
      id
      additionalRequirements
      assets {
        ...LeagueAddressesAssetFragment
      }
      campaign {
        id
        additionalRequirements
        season
        sponsor {
          id
          name
        }
        year
      }
      checks(status: approved) {
        id
        amount
        estimatedArrivalDate
        number
        status
      }
      sponsorableProperty {
        id
        name
        organization {
          id
          ...OrganizationAddressesFragment
        }
      }
      status
      value
      verificationDeadlines {
        id
        anyPendingVerificationImages
        creativeContents {
          id
          name
          renderedContent
        }
        creativeFiles {
          id
          fullSizeUrl
          name
        }
        deadline
        didNotComplete
        missingCreatives
        name
        overdue
        renderedVerificationReminderInstructions
        verified
      }
    }
  }
  ${LEAGUE_ADDRESSES_ASSET_FRAGMENT}
  ${ORGANIZATION_ADDRESSES_FRAGMENT}
`;

const ConfirmedOfferOverview = ({ match, presenterFactory }) => (
  <Query
    fetchPolicy="network-only"
    query={QUERY}
    variables={{ id: Number(match.params.offerId) }}
  >
    {({ data, error, refetch }) => {
      if (error) throw error;

      const offer = data && data.offer;
      const organization = offer && offer.sponsorableProperty.organization;

      return offer ? (
        <OrganizationDashboardWrapper
          breadcrumb={{
            label: 'Sponsorships',
            path: ORGANIZATION_DASHBOARD_PATH,
          }}
          organizations={[organization]}
        >
          {() => (
            <ConfirmedOfferOverviewUnwrapped
              offer={offer}
              refetch={refetch}
              presenterFactory={presenterFactory.presenterFactory}
            />
          )}
        </OrganizationDashboardWrapper>
      ) : (
        <Page loaded={false} />
      );
    }}
  </Query>
);

ConfirmedOfferOverview.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      offerId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  presenterFactory: PropTypes.instanceOf(Array).isRequired,
};

export default ConfirmedOfferOverview;
