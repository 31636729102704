import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ORGANIZATIONS_PATH } from 'utils/paths';

import Icon from 'components/Icon';
import TeamSnapIndicator from 'components/TeamSnapIndicator';

const EngagementStatusProps = {
  active: {
    className: 'text-primary',
    title:
      'Active: A user from this organization has either logged in, filled out prequal, or accepted an offer in the last 6 month.',
  },
  inactive: {
    className: 'text-muted',
    title:
      'Inactive: The organization has not engaged with the platform in 6+ months.',
  },
};

const OrganizationCol = ({ organization }) => {
  const {
    id: organizationId,
    name: OrganizationName,
    engagementStatus,
    sportsManagementSoftwareId,
  } = organization;
  const { className, title } = EngagementStatusProps[engagementStatus];

  return (
    <td
      className="align-middle d-none d-lg-table-cell"
      data-test="organization"
    >
      <TeamSnapIndicator
        sportsManagementSoftwareId={sportsManagementSoftwareId}
        className="height-width-20px icon mr-2"
      />
      <Icon
        className={`${className} mr-1`}
        data-test={engagementStatus}
        title={title}
        value="controller-record"
      />
      <Link to={`${ORGANIZATIONS_PATH}/${organizationId}`}>
        {OrganizationName}
      </Link>
    </td>
  );
};

OrganizationCol.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    engagementStatus: PropTypes.string,
    sportsManagementSoftwareId: PropTypes.number,
  }).isRequired,
};

export default OrganizationCol;
