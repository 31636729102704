import gql from 'graphql-tag';

export const SPONSORABLE_PROPERTY_CAPABILITY_MINIMAL_FRAGMENT = gql`
  fragment SponsorablePropertyCapabilityMinimalFragment on OrganizationCapability {
    id
    description
    name
  }
`;

export default SPONSORABLE_PROPERTY_CAPABILITY_MINIMAL_FRAGMENT;
