import React from 'react';
import { contains, prop } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { joinWithComma } from 'utils/string';
import FilterCard from './FilterCard';
import CapabilityFilterForm from './CapabilityFilterForm';

const CapabilityFilterCard = ({
  filterId,
  campaignCapabilities,
  capabilityIds,
  capabilities,
}) => (
  <FilterCard
    filterId={filterId}
    title="Target Capabilities"
    description={joinWithComma(
      capabilities
        .filter((capability) => contains(capability.id, capabilityIds))
        .map(prop('name')),
    )}
    iconValue="flag"
    formRenderer={({ save, cancel }) => (
      <CapabilityFilterForm
        campaignCapabilities={campaignCapabilities}
        capabilityIds={capabilityIds}
        capabilities={capabilities}
        onCancel={cancel}
        onSave={save}
      />
    )}
  />
);

CapabilityFilterCard.propTypes = {
  filterId: PropTypes.number.isRequired,
  campaignCapabilities: LeagueSidePropTypes.campaignCapabilities.isRequired,
  capabilityIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  capabilities: LeagueSidePropTypes.capabilities.isRequired,
};

export default CapabilityFilterCard;
