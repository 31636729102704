import * as React from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import HoverTable from 'components/HoverTable';
import Card from 'components/Card';
import RemoveOrganizationCapabilityModal from 'components/RemoveOrganizationCapability';

const DedicatedEmailTable = ({ dedicatedEmailCapabilites }) => {
  const row = (dedicatedEmailCapability) => {
    const { capabilityDetail } = dedicatedEmailCapability;

    return (
      <>
        {capabilityDetail ? (
          <tr
            key={capabilityDetail.id}
            data-test={`dedicatedEmailCapability-${capabilityDetail.id}`}
          >
            {capabilityDetail.capabilityDetailable ? (
              <td>{`${capabilityDetail.capabilityDetailable.emailListSize}`}</td>
            ) : null}
            <td>{`${capabilityDetail.price}`}</td>
            <td>{capabilityDetail.maxForSale}</td>
            <td className="d-flex justify-content-end">
              <RemoveOrganizationCapabilityModal
                organizationCapability={dedicatedEmailCapability}
              />
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Card>
        <h3 data-test="dedicated-email-table-header">Dedicated Email</h3>
        <HoverTable>
          <thead>
            <tr className="th-font-family-11">
              <th scope="col">Email List Size</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col" className="d-flex justify-content-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{map(row, dedicatedEmailCapabilites)}</tbody>
        </HoverTable>
      </Card>
    </>
  );
};

DedicatedEmailTable.propTypes = {
  dedicatedEmailCapabilites: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.object),
  ).isRequired,
};

export default DedicatedEmailTable;
