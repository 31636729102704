import React from 'react';
import { Tooltip } from 'react-leaflet';
import PropTypes from 'prop-types';

const LabeledTooltip = ({ text }) => (
  <Tooltip>
    <span>{text}</span>
  </Tooltip>
);

LabeledTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};

export default LabeledTooltip;
