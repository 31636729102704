import * as React from 'react';
import { isNil } from 'ramda';
import classNames from 'classnames';
import { getInputId } from 'utils/id';
import { capitalizeEachWord, joinTruthyWithSpace } from 'utils/string';
import { RequiredAsterisk } from 'components/RequiredLabel';

interface Props extends React.HTMLAttributes<HTMLElement> {
  append?: string;
  disabled?: boolean;
  formGroupClassName?: string;
  inputSize?: string;
  label: string;
  labelClassName?: string;
  max?: number;
  min?: number;
  prefix?: string;
  prepend?: string;
  required?: boolean;
  step?: number;
  value?: number;
  placeholder?: string;
  // eslint-disable-next-line react/require-default-props
  customId?: string;
}

const NumberInput: React.FunctionComponent<Props> = ({
  append,
  disabled,
  formGroupClassName,
  inputSize,
  label,
  labelClassName,
  max,
  min,
  onChange,
  prefix,
  prepend,
  required,
  step,
  value,
  placeholder,
  customId,
}: Props) => {
  const id = getInputId([prefix, label]);

  return (
    <div className={joinTruthyWithSpace(['form-group', formGroupClassName])}>
      <label className={labelClassName} htmlFor={id}>
        {capitalizeEachWord(label)}
        {!!required && <RequiredAsterisk />}
      </label>
      <div className="input-group">
        {!!prepend && (
          <div className="input-group-prepend">
            <span className="input-group-text">{prepend}</span>
          </div>
        )}
        <input
          aria-required={required ? 'true' : undefined}
          className={classNames('form-control', {
            [`form-control-${inputSize}`]: !!inputSize,
          })}
          disabled={disabled}
          id={customId || id}
          max={max}
          min={min}
          onChange={onChange}
          required={required}
          step={step}
          type="number"
          value={isNil(value) ? '' : value}
          placeholder={placeholder}
        />
        {!!append && (
          <div className="input-group-append">
            <span className="input-group-text">{append}</span>
          </div>
        )}
      </div>
    </div>
  );
};

NumberInput.defaultProps = {
  append: undefined,
  disabled: false,
  formGroupClassName: undefined,
  inputSize: undefined,
  labelClassName: undefined,
  max: undefined,
  min: undefined,
  prefix: undefined,
  prepend: undefined,
  required: false,
  step: undefined,
  value: undefined,
  placeholder: undefined,
};

export default NumberInput;
