// Deprecated Page component
import { PropsWithChildren, useEffect } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import Nav from 'components/Nav';
import ModalSpinner from 'components/ModalSpinner';
import StagingBanner from 'components/StagingBanner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Main = styled.main<{
  fullScreen: boolean;
}>`
  display: ${({ fullScreen }) => (fullScreen ? 'flex' : 'block')};
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

type PageProps = PropsWithChildren<{
  additionalDropdownItems?: JSX.Element[];
  additionalNavItems?: JSX.Element[];
  fullScreen?: boolean;
  loaded?: boolean;
  load?: () => void;
  mainClassName?: string;
}>;

export default function Page(props: PageProps) {
  const {
    children,
    additionalDropdownItems,
    additionalNavItems,
    fullScreen = false,
    loaded = true,
    load,
    mainClassName = '',
  } = props;

  useEffect(() => {
    if (!loaded && load) {
      load();
    }
  }, [loaded]);

  return (
    <Wrapper className="deprecated-styles">
      <StagingBanner />
      <ModalSpinner show={!loaded} />
      <Nav
        additionalDropdownItems={additionalDropdownItems}
        additionalNavItems={additionalNavItems}
      />
      <Main
        className={classNames(mainClassName, { container: !fullScreen })}
        fullScreen={fullScreen}
      >
        {children}
      </Main>
    </Wrapper>
  );
}
