import React, { useState } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import SingleCardPage from 'components/SingleCardPage';
import DELIVERABLE_ADDRESS_FRAGMENT from 'fragments/DeliverableAddressFragment';
import { getInitialDeliverableAddress } from 'utils/deliverableAddress';
import CONTENT from './reviewOffer/CONTENT';
import AcceptConfirmationContent from './reviewOffer/AcceptConfirmationContent';
import RejectConfirmationContent from './reviewOffer/RejectConfirmationContent';
import ReviewDetailsContent from './reviewOffer/ReviewDetailsContent';
import EnterEmailContent from './reviewOffer/EnterEmailContent';
import ValidateDeliverableAddressContent from './reviewOffer/ValidateDeliverableAddressContent';

export const ACCEPT_OFFER_MUTATION = gql`
  mutation AcceptOffer($input: AcceptOfferInput!) {
    acceptOffer(input: $input) {
      jwt
    }
  }
`;

export const LEAGUE_REJECT_OFFER_MUTATION = gql`
  mutation LeagueRejectOffer($input: LeagueRejectOfferInput!) {
    leagueRejectOffer(input: $input) {
      offer {
        id
      }
    }
  }
`;

export const REVIEW_OFFER_OFFER_FRAGMENT = gql`
  fragment ReviewOfferOfferFragment on Offer {
    id
    additionalRequirements
    sponsorableProperty {
      id
      name
      organization {
        id
        name
        mailingAddress {
          ...DeliverableAddressFragment
        }
        shippingAddress {
          ...DeliverableAddressFragment
        }
      }
    }
    assets {
      id
      campaignCapability {
        id
        capability {
          id
          name
        }
        executionExpectations
      }
      quantity
    }
    campaign {
      id
      additionalRequirements
      season
      sponsor {
        id
        name
      }
      sponsorOverview
      year
    }
    status
    value
    seasonStart
    seasonEnd
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

const ReviewOffer = ({
  acceptOffer,
  fetchHasAccount,
  verifyAddress,
  upsertAddress,
  initialContent,
  initialEmail,
  loggedIn,
  offer,
  rejectOffer,
}) => {
  const [content, setContent] = useState(initialContent);
  const [email, setEmail] = useState(initialEmail);
  const [hasAccount, setHasAccount] = useState(loggedIn);
  const [address, setAddress] = useState(
    getInitialDeliverableAddress(
      offer.sponsorableProperty.organization,
      'shipping',
    ),
  );

  function cardContent() {
    switch (content) {
      case CONTENT.ACCEPT_CONFIRMATION:
        return (
          <AcceptConfirmationContent
            email={email}
            sponsorName={offer.campaign.sponsor.name}
          />
        );
      case CONTENT.REJECT_CONFIRMATION:
        return (
          <RejectConfirmationContent
            hasAccount={hasAccount}
            loggedIn={loggedIn}
          />
        );
      case CONTENT.VALIDATE_DELIVERABLE_ADDRESS:
        return (
          <ValidateDeliverableAddressContent
            addressType="shipping"
            organization={offer.sponsorableProperty.organization}
            setContent={setContent}
            address={address}
            setAddress={setAddress}
            verifyAddress={verifyAddress}
            upsertAddress={upsertAddress}
          />
        );
      case CONTENT.REVIEW_DETAILS:
        return (
          <ReviewDetailsContent
            acceptOffer={acceptOffer}
            email={email}
            hasAccount={hasAccount}
            loggedIn={loggedIn}
            offer={offer}
            rejectOffer={(leagueRejectionReason) =>
              rejectOffer({ email, leagueRejectionReason })
            }
            setContent={setContent}
          />
        );
      default:
        return (
          <EnterEmailContent
            email={email}
            fetchHasAccount={fetchHasAccount}
            setContent={setContent}
            setEmail={setEmail}
            setHasAccount={setHasAccount}
          />
        );
    }
  }

  return (
    <SingleCardPage>
      <div id="review-offer-card">
        <div className="heading">Review Sponsorship Offer</div>
        {cardContent()}
      </div>
    </SingleCardPage>
  );
};

ReviewOffer.propTypes = {
  acceptOffer: PropTypes.func.isRequired,
  fetchHasAccount: PropTypes.func,
  initialContent: PropTypes.number.isRequired,
  initialEmail: PropTypes.string,
  loggedIn: PropTypes.bool,
  offer: LeagueSidePropTypes.offer.isRequired,
  rejectOffer: PropTypes.func.isRequired,
  upsertAddress: PropTypes.func.isRequired,
  verifyAddress: PropTypes.func.isRequired,
};

ReviewOffer.defaultProps = {
  fetchHasAccount: () => {},
  initialEmail: null,
  loggedIn: false,
};

export default ReviewOffer;
