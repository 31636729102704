import * as React from 'react';
import ConfirmedOfferOverviewSection from './ConfirmedOfferOverviewSection';

interface Campaign {
  additionalRequirements: string;
}

interface Offer {
  additionalRequirements: string;
  campaign: Campaign;
}

interface Props {
  offer: Offer;
}

const AdditionalRequirements: React.FunctionComponent<Props> = ({
  offer,
}: Props) => {
  const { campaign } = offer;

  return campaign.additionalRequirements || offer.additionalRequirements ? (
    <ConfirmedOfferOverviewSection header="Additional Requirements">
      {offer.additionalRequirements && (
        <p className="style-string-with-new-lines">
          {offer.additionalRequirements}
        </p>
      )}
      {campaign.additionalRequirements && (
        <p className="style-string-with-new-lines">
          {campaign.additionalRequirements}
        </p>
      )}
    </ConfirmedOfferOverviewSection>
  ) : null;
};

export default AdditionalRequirements;
