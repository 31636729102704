import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { CAMPAIGN_TARGETING_OFFER_FRAGMENT } from 'fragments/OfferFragment';
import Icon from 'components/Icon';
import useUserPilotTracking from 'hooks/useUserPilotTracking';

const MUTATION = gql`
  mutation AddSponsorablePropertyToCampaign(
    $input: AddSponsorablePropertiesToCampaignInput!
  ) {
    addSponsorablePropertiesToCampaign(input: $input) {
      campaign {
        id
        sponsor {
          id
          name
        }
        offers {
          ...CampaignTargetingOfferFragment
        }
      }
    }
  }
  ${CAMPAIGN_TARGETING_OFFER_FRAGMENT}
`;

const AddSponsorablePropertyToCampaignButton = ({
  campaignId,
  sponsorablePropertyId,
}) => {
  const { sendUserPilotTrackingEvent } = useUserPilotTracking();

  const [mutate] = useMutation(MUTATION, {
    variables: {
      input: {
        campaignId,
        sponsorablePropertyIds: [sponsorablePropertyId],
      },
    },
    onCompleted: (data) => {
      const { campaign } = data.addSponsorablePropertiesToCampaign;
      const { offers } = campaign;

      const indexOfOffer = offers.findIndex(
        (offer) => offer.sponsorableProperty.id === sponsorablePropertyId,
      );
      const offer = offers.splice(indexOfOffer, 1)[0];

      sendUserPilotTrackingEvent('PreQual Offer Sent', {
        campaign_id: campaign.id,
        sponsor_name: campaign.sponsor.name,
        offer_id: offer.id,
        sponsorable_property_name: offer.sponsorableProperty.name,
      });
    },
  });

  return (
    <button
      className="btn btn-sm btn-outline-success ml-1"
      data-test="add-to-campaign"
      onClick={() => mutate()}
      type="button"
    >
      <Icon className="mr-1" value="plus" />
      Add
    </button>
  );
};

AddSponsorablePropertyToCampaignButton.propTypes = {
  sponsorablePropertyId: PropTypes.number.isRequired,
  campaignId: PropTypes.number.isRequired,
};

export default AddSponsorablePropertyToCampaignButton;
