import React from 'react';
import PropTypes from 'prop-types';
import Modal, { ModalBody } from 'components/Modal';
import './PreviewGenericCreativeContentModal.css';
import DangerousInnerHtml from './dangerous-inner-html/dangerous-inner-html';

const PreviewGenericCreativeContentModal = ({
  genericCreativeContent,
  modalTargetLabel,
  toggleClassName,
}) => {
  const { renderedContent, id, name } = genericCreativeContent;
  const modalId = `preview-generic-creative-content-${id}-modal`;

  return (
    <>
      <button
        className={toggleClassName}
        data-target={`#${modalId}`}
        data-toggle="modal"
        title="Preview"
        type="button"
      >
        {modalTargetLabel || name}
      </button>
      <Modal id={modalId} size="large" title={name}>
        <ModalBody>
          <div className="preview-generic-creative-content-modal border rounded p-3">
            <DangerousInnerHtml html={renderedContent} />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

PreviewGenericCreativeContentModal.propTypes = {
  genericCreativeContent: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    renderedContent: PropTypes.string,
  }).isRequired,
  modalTargetLabel: PropTypes.string,
  toggleClassName: PropTypes.string,
};

PreviewGenericCreativeContentModal.defaultProps = {
  modalTargetLabel: null,
  toggleClassName: 'btn btn-link p-0',
};

export default PreviewGenericCreativeContentModal;
