import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatToDollar } from 'utils/formatNumber';
import { ORGANIZATION_DASHBOARD_PATH } from 'utils/paths';
import { receiveAuthenticationToken } from 'modules/authentication';
import Icon from 'components/Icon';
import DateRangePickerWrapper from 'components/DateRangePickerWrapper';
import { map } from 'ramda';
import { getSeasonsForValidation } from 'utils/seasonDeadlineValidation';
import Alert from 'components/Alert';
import CONTENT from './CONTENT';
import AcceptOfferModal from './AcceptOfferModal';
import RejectOfferModal from './RejectOfferModal';

const renderAsset = (asset) => (
  <React.Fragment key={asset.id}>
    <dt data-test="asset" className="mt-3">
      {`${asset.quantity} ${asset.campaignCapability.capability.name}`}
    </dt>
    {asset.campaignCapability.executionExpectations && (
      <dd data-test="asset">
        {asset.campaignCapability.executionExpectations}
      </dd>
    )}
  </React.Fragment>
);

const backButtonLabel = (
  <>
    <Icon className="mr-1" value="arrow-left" />
    Back
  </>
);
const backButtonClassName = 'btn btn-link p-0';

const seasonDateErrorAlert = (message) => (
  <Alert
    className="alert-warning mt-4"
    role="alert"
    data-test="address-error-alert"
  >
    {message}
  </Alert>
);

const renderSeasonDateErrorAlerts = map(seasonDateErrorAlert);

export const ReviewDetailsContentUnwrapped = ({
  acceptOffer,
  email,
  hasAccount,
  loggedIn,
  offer,
  pushToSponsorship,
  receiveToken,
  rejectOffer,
  setContent,
}) => {
  const {
    additionalRequirements: offerRequirements,
    campaign,
    seasonStart: initialSeasonStart,
    seasonEnd: initialSeasonEnd,
  } = offer;
  const {
    additionalRequirements: campaignRequirements,
    sponsor: { name: sponsorName },
  } = campaign;

  const [focusedInput, setFocusedInput] = useState(null);
  const [seasonStart, setSeasonStart] = useState(
    initialSeasonStart && moment(initialSeasonStart),
  );
  const [seasonEnd, setSeasonEnd] = useState(
    initialSeasonEnd && moment(initialSeasonEnd),
  );

  const [seasonDateErrors, setSeasonDateErrors] = useState(false);
  const [seasonDateErrorMessages, setSeasonDateErrorsMessages] = useState(null);

  const LOWER_FIRST_GAME_BOUND = moment().subtract(14, 'days');
  const UPPER_FIRST_GAME_BOUND = moment().add(365, 'days');

  const selectedSeason = getSeasonsForValidation(offer.campaign.season);

  function onAccept(jwt) {
    if (jwt) receiveToken(jwt);
    if (loggedIn || jwt) pushToSponsorship(offer.id);
    setContent(CONTENT.ACCEPT_CONFIRMATION);
  }

  const onDatesChange = ({ startDate, endDate }) => {
    const validationErrors = selectedSeason.validate(startDate, endDate);

    if (validationErrors) {
      setSeasonDateErrors(true);
      setSeasonDateErrorsMessages(validationErrors);
    } else {
      setSeasonDateErrors(false);
    }

    setSeasonStart(startDate);
    setSeasonEnd(endDate);
  };

  return (
    <>
      <div className="text-left" id="review-details-body">
        <p className="text-center my-5">
          <strong id="sponsor-name">{sponsorName}</strong>
          &nbsp;is offering&nbsp;
          <strong id="value">{formatToDollar(offer.value)}</strong>
        </p>
        <p className="style-string-with-new-lines" id="sponsor-overview">
          {campaign.sponsorOverview}
        </p>
        <p>
          To receive this sponsorship, your league will have to complete the
          following:
        </p>
        <dl>
          {offer.assets.map(renderAsset)}
          {(campaignRequirements || offerRequirements) && (
            <dt className="mt-3">Additional Requirements:</dt>
          )}
          {offerRequirements && (
            <dd
              className="style-string-with-new-lines"
              id="offer-additional-requirements"
            >
              {offerRequirements}
            </dd>
          )}
          {campaignRequirements && (
            <dd
              className="style-string-with-new-lines"
              id="additional-requirements"
            >
              {campaignRequirements}
            </dd>
          )}
        </dl>
        <p className="mt-3 mb-0 font-weight-bold"> Terms and Conditions:</p>
        <p>
          As a TeamSnap sponsorship participant, you and/or your Organization
          agree to be bound by these terms and conditions. TeamSnap reserves the
          right to modify these terms and conditions at any time and will
          provide notice to you of any such changes.
        </p>
        <p>
          You and/or your Organization agree to submit verifications for each
          asset by their associated deadlines in the manner in which they are
          described. TeamSnap may modify deadlines at their reasonable
          discretion and will communicate the same to you. Sponsorship
          verifications include but are not limited to sending photos of
          physical assets, submitting information, completing tasks, and
          forwarding copies of completed emails/social media posts.
        </p>
        <p>
          After the first and last verifications of the sponsored assets, the
          first and second sponsorship payments will be released, respectively.
          To receive the full sponsorship payment amount, all verifications must
          be finalized by the Season End Date specified below.
        </p>
        <p>
          The Season End Date will be the earlier of the organization&apos;s
          season end date or the following dates for each season: Spring - June
          30th; Summer - September 30th; Fall - November 30th; Winter - March
          31st.
        </p>
        <p>
          Acceptance of this sponsorship does not require TeamSnap membership.
        </p>
        <p>
          Failure to timely comply with the verification requirements of the
          sponsorship by the Season End Date may result in TeamSnap requesting a
          full or partial refund, or withholding future sponsorship payments, as
          determined by TeamSnap in its sole and reasonable discretion, and
          could further lead to suspension or removal of your Organization from
          future sponsorships.
        </p>
        <p>
          Organizations are expected to provide verification of requirements by
          the provided deadlines. If an organization verifies their requirements
          late by an average of 20 or more days, TeamSnap may remove the
          organization from future sponsorship opportunities.
        </p>
        <p>
          In case of season cancellation by your Organization, the sponsorship
          will be terminated and any paid funds must be returned to TeamSnap. If
          jerseys featuring a TeamSnap sponsor have already been purchased, the
          cost of the logo application will be paid by the sponsorship funds,
          with the remaining balance being returned to TeamSnap.
        </p>
        <p>
          Sponsorship payments will be dispatched within 30 days following the
          Season End Date. Any necessary refunds to TeamSnap must also be made
          within 30 days of receiving a refund notice. Payments to individual
          names via checks are not permitted. If an organization requests
          payment under a different entity name, valid legal documentation
          verifying a name change or a &quot;doing business as&quot; (DBA)
          status must be provided.
        </p>
        <p>
          You and your Organization will indemnify and hold harmless TeamSnap
          and any brand sponsor, and its respective officers, employees and
          agents, from and against any claims, actions, or demands, including
          without limitation, reasonable attorneys’ fees, due to or resulting
          from your or your Organization&apos;s breach of these terms and
          conditions, the sponsorship, or you or your Organization&apos;s
          willful misconduct, fraud, negligence or gross negligence.
        </p>
        <p>
          TeamSnap will not be liable for any special, incidental, indirect,
          remote, or other consequential damages arising from performance or
          failure to perform under this agreement. TeamSnap will not be liable
          for any acts or omissions of any third parties associated with the
          performance of this agreement.
        </p>
        <p>
          Nothing contained in these terms and conditions should be construed as
          creating an employment relationship, partnership, or joint venture
          between TeamSnap and you and/or your Organization.
        </p>
        <p>
          Any information listed in the Additional Requirements section
          supersedes the original agreement terms.
        </p>
        <p>
          By accepting this offer, the accepting user hereby consents to receive
          calls, emails and text messages from TeamSnap, in accordance with our
          privacy policy and terms of service that can be found at{' '}
          <a href="https://www.teamsnap.com/" target="_blank" rel="noreferrer">
            teamsnap.com
          </a>
          . Message and data rates may apply. Message frequency varies.
        </p>
        <hr />
        <p className="text-center">
          Please enter your estimated first and last game dates and click
          either&nbsp;
          <strong>Accept</strong>
          &nbsp;or&nbsp;
          <strong>Reject</strong>
        </p>
        {!!seasonDateErrors &&
          renderSeasonDateErrorAlerts(seasonDateErrorMessages)}
        <div className="text-center">
          <DateRangePickerWrapper
            endDate={seasonEnd}
            focusedInput={focusedInput}
            isOutsideRange={(date) =>
              date.isBefore(LOWER_FIRST_GAME_BOUND) ||
              date.isAfter(UPPER_FIRST_GAME_BOUND)
            }
            onDatesChange={onDatesChange}
            onFocusChange={setFocusedInput}
            startDate={seasonStart}
          />
        </div>
        <hr />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="flex-grow">
          {loggedIn ? (
            <Link
              className={backButtonClassName}
              id="back"
              to={ORGANIZATION_DASHBOARD_PATH}
            >
              {backButtonLabel}
            </Link>
          ) : (
            <button
              className={backButtonClassName}
              id="back"
              onClick={() => setContent(CONTENT.ENTER_EMAIL)}
              type="button"
            >
              {backButtonLabel}
            </button>
          )}
        </div>
        <div className="text-right text-nowrap">
          <RejectOfferModal
            onReject={() => setContent(CONTENT.REJECT_CONFIRMATION)}
            rejectOffer={rejectOffer}
          />
          <AcceptOfferModal
            acceptOffer={(rest) =>
              acceptOffer({
                endDate: seasonEnd && seasonEnd.format('YYYY-MM-DD'),
                startDate: seasonStart && seasonStart.format('YYYY-MM-DD'),
                ...rest,
              })
            }
            email={email}
            hasAccount={hasAccount}
            loggedIn={loggedIn}
            onAccept={onAccept} // eslint-disable-line react/jsx-no-bind
            toggleProps={{ disabled: !(seasonEnd && seasonStart) }}
          />
        </div>
      </div>
    </>
  );
};

ReviewDetailsContentUnwrapped.propTypes = {
  acceptOffer: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  hasAccount: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  offer: LeagueSidePropTypes.offer.isRequired,
  pushToSponsorship: PropTypes.func.isRequired,
  receiveToken: PropTypes.func.isRequired,
  rejectOffer: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  receiveToken(token) {
    dispatch(receiveAuthenticationToken(token));
  },
  pushToSponsorship(offerId) {
    dispatch(push(`${ORGANIZATION_DASHBOARD_PATH}/sponsorship/${offerId}`));
  },
});

const ReviewDetailsContent = connect(
  () => ({}),
  mapDispatchToProps,
)(ReviewDetailsContentUnwrapped);
export default ReviewDetailsContent;
