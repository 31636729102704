import PropTypes from 'prop-types';
import hideModal from 'utils/hideModal';
import { loadingTense } from 'utils/string';
import ErrorAlert from 'components/ErrorAlert';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import RequiredLabel from 'components/RequiredLabel';

function ModalForm(props) {
  const {
    children,
    error,
    errorMessage,
    handleSubmit,
    hasRequiredFields,
    id,
    loading,
    onSubmit,
    submitProps,
    toggleProps,
    ...modalProps
  } = props;
  const selector = `#${id}`;
  const { onClose } = modalProps;

  async function defaultHandleSubmit(event) {
    event.preventDefault();
    await onSubmit(event);
    hideModal(selector);
  }

  return (
    <>
      <button
        data-target={selector}
        data-toggle="modal"
        data-test={`${id}-toggle-button`}
        type="button"
        {...toggleProps}
      />
      <Modal id={id} {...modalProps}>
        <form onSubmit={handleSubmit || defaultHandleSubmit}>
          <ModalBody>
            {children}
            {hasRequiredFields && <RequiredLabel />}
            {!!error && errorMessage}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              data-test={`${id}-cancel`}
              data-dismiss="modal"
              type="button"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              /* eslint-disable-next-line react/no-children-prop */
              children={loadingTense(loading, 'Save')}
              data-test={`${id}-submit`}
              disabled={loading}
              type="submit"
              {...submitProps}
            />
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}

ModalForm.propTypes = {
  children: PropTypes.node,
  error: PropTypes.any /* eslint-disable-line */,
  errorMessage: PropTypes.node,
  handleSubmit: PropTypes.func,
  hasRequiredFields: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  submitProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
  }),
  title: PropTypes.string,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool,
  }),
};

ModalForm.defaultProps = {
  children: null,
  error: null,
  errorMessage: <ErrorAlert className="mt-3 mb-0" />,
  handleSubmit: null,
  hasRequiredFields: false,
  loading: false,
  onClose: () => {},
  onSubmit: () => {},
  submitProps: {},
  toggleProps: {},
};

export default ModalForm;
