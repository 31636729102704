import {
  always,
  any,
  anyPass,
  append,
  ascend,
  concat,
  cond,
  descend,
  either,
  equals,
  filter,
  partition,
  path,
  pathEq,
  pipe,
  prop,
  propEq,
  sortBy,
  sortWith,
} from 'ramda';
import { hasPhysicalQuantity } from './asset';
import { seasonOrdinal } from './campaign';
import formatDate from './formatDate';
import { statusEq } from './misc';
import { getPoint as getGeoPoint } from './geo';

export const REMOVABLE = [
  'prospecting',
  'qualified',
  'proposed',
  'rejected_by_league',
];
export const ADDED = append('confirmed', REMOVABLE);
export const REMOVED = ['unqualified', 'rejected', 'canceled'];
export const OFFER_STATUSES = append('unadded', concat(ADDED, REMOVED));

export const isProspecting = statusEq('prospecting');
export const isConfirmed = statusEq('confirmed');
export const isQualified = statusEq('qualified');
export const isProposed = statusEq('proposed');
export const isRejectedByLeague = statusEq('rejected_by_league');
export const isRejectedByOrganization = statusEq('rejected_by_organization');
export const isProposedOrRejectedByOrganization = either(
  isProposed,
  isRejectedByOrganization,
);
export const isProposedOrRejectedByLeague = either(
  isProposed,
  isRejectedByLeague,
);
export const isUnqualified = statusEq('unqualified');
export const isRejected = statusEq('rejected');
export const isCanceled = statusEq('canceled');
export const isRemoved = anyPass([isUnqualified, isRejected, isCanceled]);

const partitionQualified = partition(isQualified);
const partitionProposedOrRejectedByLeague = partition(
  isProposedOrRejectedByLeague,
);
const partitionConfirmed = partition(isConfirmed);
export const partitionOffersByStatus = (offers) => {
  const [qualifiedOffers, remainingOffers1] = partitionQualified(offers);
  const [proposedOrRejectedByLeagueOffers, remainingOffers2] =
    partitionProposedOrRejectedByLeague(remainingOffers1);
  const [confirmedOffers, remainingOffers3] =
    partitionConfirmed(remainingOffers2);
  const prospectingOffers = filter(isProspecting, remainingOffers3);

  return {
    qualifiedOffers,
    proposedOrRejectedByLeagueOffers,
    confirmedOffers,
    prospectingOffers,
  };
};

const hasCampaignStatus = (status) => pathEq(['campaign', 'status'], status);
export const hasCompleteCampaign = hasCampaignStatus('complete');
export const hasActiveCampaign = hasCampaignStatus('active');

export const sponsorablePropertyName = path(['sponsorableProperty', 'name']);
export const organizationName = path([
  'sponsorableProperty',
  'organization',
  'name',
]);
export const findLeagueId = path(['league', 'id']);
export const findSponsorablePropertyId = path(['sponsorableProperty', 'id']);
export const sortByLeagueName = sortBy(sponsorablePropertyName);
export const sortByOrganizationName = sortBy(organizationName);
export const sortBySponsorablePropertyName = sortBy(sponsorablePropertyName);
const hasPhysicalAssets = pipe(prop('assets'), any(hasPhysicalQuantity));
export const hasAnyPhysicalAssets = any(hasPhysicalAssets);

const mostRecentSurveyDate = (offer) => {
  const { surveyOpenedAt, surveySentAt } = offer;

  if (!surveySentAt) {
    return surveyOpenedAt;
  }

  return surveyOpenedAt > surveySentAt ? surveyOpenedAt : surveySentAt;
};

const mostRecentOfferDate = (offer) => {
  const { offerOpenedAt, offerSentAt } = offer;

  if (!offerSentAt) {
    return offerOpenedAt;
  }

  return offerOpenedAt > offerSentAt ? offerOpenedAt : offerSentAt;
};

export const sortOffersBySurveyCol = sortWith([
  ascend(propEq('sponsorshipApplicationSentAt', null)),
  ascend(sponsorablePropertyName),
]);
export const sortOffersBySurveyColV2 = sortWith([
  ascend(propEq('sponsorshipApplicationSentAt', null)),
  descend(prop('sponsorshipApplicationSentAt')),
  ascend(sponsorablePropertyName),
  ascend(organizationName),
]);
export const sortOffersBySPName = sortWith([
  ascend(sponsorablePropertyName),
  ascend(propEq('sponsorshipApplicationSentAt', null)),
  ascend(organizationName),
]);

export const sortOffersByOrganization = sortWith([
  ascend(organizationName),
  ascend(propEq('sponsorshipApplicationSentAt', null)),
  ascend(sponsorablePropertyName),
]);

export const sortAvailableSPsByOrganization = sortWith([
  ascend(path(['organization', 'name'])),
  ascend(path(['name'])),
]);

export const oldSortOffersByOfferCol = sortWith([
  descend(isRejectedByLeague),
  ascend(propEq('offerSentAt', null)),
  descend(mostRecentOfferDate),
  ascend(sponsorablePropertyName),
]);
export const sortOffersByOfferCol = sortWith([
  descend(isRejectedByLeague),
  ascend(propEq('offerSentAt', null)),
  descend(mostRecentOfferDate),
  ascend(sponsorablePropertyName),
]);

export const surveyStatus = (offer) => {
  const { surveyOpenedAt, surveySentAt } = offer;

  return cond([
    [equals(null), always('Not Sent')],
    [
      equals(surveySentAt),
      always(`Sent ${formatDate(surveySentAt, 'M/D/YY')}`),
    ],
    [
      equals(surveyOpenedAt),
      always(`Opened ${formatDate(surveyOpenedAt, 'M/D/YY')}`),
    ],
  ])(mostRecentSurveyDate(offer));
};
export const offerStatus = (offer) => {
  const { offerOpenedAt, offerSentAt } = offer;

  return cond([
    [equals(null), always('Email Not Logged')],
    [equals(offerSentAt), always(`Sent ${formatDate(offerSentAt)}`)],
    [equals(offerOpenedAt), always(`Opened ${formatDate(offerOpenedAt)}`)],
  ])(mostRecentOfferDate(offer));
};

export const oldGetPoint = pipe(path(['league', 'playLocation']), getGeoPoint);

export const getPoint = pipe(
  path(['sponsorableProperty', 'playLocation']),
  getGeoPoint,
);

export const campaignSeasonOrdinal = pipe(path(['campaign']), seasonOrdinal);
