import React, { useCallback } from 'react';
import { equals } from 'ramda';
import PropTypes from 'prop-types';
import hideModal from 'utils/hideModal';
import {
  hasInvalidMustacheTagErrors,
  invalidMustacheTagError,
} from 'utils/mustacheTagErrors';
import { loadingTense } from 'utils/string';
import ErrorAlert from 'components/ErrorAlert';
import ModalForm from 'components/ModalForm';

const invalidMustacheTagAlert = (
  <ErrorAlert className="mt-3 mb-0" data-test="invalid-mustache-tag-alert">
    Uh oh! Something looks off with your mustache tags.&nbsp; Please make sure
    you didn&#39;t miss any curly braces (e.g.&nbsp;
    <code>{'{{mustache_tag}'}</code>) or misspell anything (e.g.&nbsp;
    <code>{'{{mustache_ta}}'}</code>
    ).
  </ErrorAlert>
);

function ModalFormWithMustacheTagHandling({
  children,
  error,
  hasRequiredFields,
  id,
  loading,
  onSubmit,
  requiredDataPresent,
  submitLabel,
  toggleProps,
  ...modalProps
}) {
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      await onSubmit(event);
      hideModal(`#${id}`);
    } catch (e) {
      if (!hasInvalidMustacheTagErrors(e)) {
        throw e;
      }
    }
  }

  const RenderErrorMsg = () =>
    useCallback(
      error && hasInvalidMustacheTagErrors(error) ? (
        <ErrorAlert className="mt-3 mb-0">
          {error.message.substring(15)}
        </ErrorAlert>
      ) : (
        <ErrorAlert className="mt-3 mb-0" />
      ),
      [error],
    );

  return (
    <ModalForm
      id={id}
      error={error}
      /* eslint-disable react/jsx-no-bind */
      errorMessage={
        !!error &&
        ((equals(error.message, invalidMustacheTagError) &&
          invalidMustacheTagAlert) ||
          RenderErrorMsg())
      }
      handleSubmit={handleSubmit}
      hasRequiredFields={hasRequiredFields}
      loading={loading}
      submitProps={{
        children: loadingTense(loading, submitLabel),
        disabled: !requiredDataPresent || loading,
      }}
      toggleProps={toggleProps}
      {...modalProps}
    >
      {children}
    </ModalForm>
  );
}

ModalFormWithMustacheTagHandling.propTypes = {
  children: PropTypes.node,
  error: PropTypes.any /* eslint-disable-line */,
  hasRequiredFields: PropTypes.bool,
  loading: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  requiredDataPresent: PropTypes.bool.isRequired,
  submitLabel: PropTypes.string,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
  }),
};

ModalFormWithMustacheTagHandling.defaultProps = {
  children: null,
  error: null,
  hasRequiredFields: false,
  loading: false,
  onSubmit: () => {},
  submitLabel: 'Save',
  toggleProps: {},
};

export default ModalFormWithMustacheTagHandling;
