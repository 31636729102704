import React from 'react';
import { lensPath, merge, prop, set, without } from 'ramda';
import classNames from 'classnames';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import leaguesideAPI from 'utils/leagueside-api';
import { REMOVED } from 'utils/offer';
import { pluralizedTense, loadingTense } from 'utils/string';
import {
  inferAndReturnStatuses,
  LOCAL_STORAGE_KEY,
  DEFAULT_STATE,
  getStateFromLocalStorage,
} from 'utils/statusVisibility';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';

class SponsorablePropertyListModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = getStateFromLocalStorage();

    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  get stateForCampaign() {
    const { props, state } = this;
    return merge(DEFAULT_STATE, state[props.campaign.id]);
  }

  get statuses() {
    return without(REMOVED, inferAndReturnStatuses(this.stateForCampaign));
  }

  async handleSubmit(event) {
    const { props } = this;
    event.preventDefault();

    try {
      this.setState({ loading: true, error: null });
      await leaguesideAPI.downloadOfferSponsorablePropertyList(
        props.campaign.id,
        this.statuses,
      );
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false, error: e });
    }
  }

  toggle(statusProp) {
    const { props } = this;
    const lens = lensPath([props.campaign.id, statusProp]);

    return (event) =>
      this.setState(
        set(lens, event.target.checked),
        this.updateLocalStorageState,
      );
  }

  updateLocalStorageState() {
    const value = JSON.stringify(this.state);

    localStorage.setItem(LOCAL_STORAGE_KEY, value);
  }

  renderCheckbox(status, label) {
    const checked = prop(`${status}Visible`, this.stateForCampaign);

    return (
      <div className="form-check ml-4 my-1">
        <input
          checked={checked}
          className="form-check-input"
          disabled={this.loading}
          id={`${status}-toggle`}
          onChange={this.toggle(`${status}Visible`)}
          type="checkbox"
        />
        <label
          className="form-check-label align-middle"
          htmlFor={`${status}-toggle`}
        >
          {label}
        </label>
      </div>
    );
  }

  render() {
    const { error, loading } = this.state;
    const { campaign } = this.props;
    const { offerCountsReport } = campaign;
    const { confirmed, proposed, prospecting, qualified } = offerCountsReport;

    return (
      <ModalForm
        id={`sponsorable-properties-list-${campaign.id}`}
        error={error}
        loading={loading}
        onSubmit={this.handleSubmit}
        submitProps={{
          className: classNames('btn btn-primary', { 'font-italic': loading }),
          children: (
            <>
              {loading ? null : <Icon className="mr-2" value="download" />}
              {loadingTense(loading, 'Download')}
            </>
          ),
          disabled: !this.statuses.length || loading,
        }}
        toggleProps={{
          className: 'btn btn-primary fs-12',
          style: { width: '100%' },
          children: (
            <div
              style={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
              <Icon className="mr-2" value="download" />
              <div style={{ whiteSpace: 'break-spaces' }}>
                Download Sponsorable Properties List
              </div>
            </div>
          ),
        }}
        title={`Download ${campaign.name} Sponsorable Properties List`}
      >
        <strong>
          {this.renderCheckbox(
            'prospects',
            `${prospecting} ${pluralizedTense(prospecting, 'Prospect')}`,
          )}
          {this.renderCheckbox('qualified', `${qualified} Qualified`)}
          {this.renderCheckbox('proposed', `${proposed} Proposed`)}
          {this.renderCheckbox('confirmed', `${confirmed} Confirmed`)}
        </strong>
      </ModalForm>
    );
  }
}

SponsorablePropertyListModal.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default SponsorablePropertyListModal;
