import * as React from 'react';
import { map } from 'ramda';
import GenericCreativeContent from 'types/genericCreativeContent';
import GenericCreativeFile from 'types/genericCreativeFile';
import { VerificationDeadlineWithAdditionalStatuses } from 'types/verificationDeadline';
import ConfirmedOfferOverviewSection from './ConfirmedOfferOverviewSection';
import VerificationDeadlineDetails from './VerificationDeadlineDetails';

interface VerificationDeadline
  extends VerificationDeadlineWithAdditionalStatuses {
  creativeContents: GenericCreativeContent[];
  creativeFiles: GenericCreativeFile[];
  renderedVerificationReminderInstructions: string;
}

interface Props {
  header: string;
  verificationDeadlines: VerificationDeadline[];
  refetch: Function;
  presenterFactory: any;
}

const VerificationDeadlinesSection: React.FunctionComponent<Props> = ({
  header,
  verificationDeadlines,
  refetch,
  presenterFactory,
}: Props) =>
  verificationDeadlines.length ? (
    <ConfirmedOfferOverviewSection header={header}>
      {map(
        (verificationDeadline) => (
          <VerificationDeadlineDetails
            key={verificationDeadline.id}
            verificationDeadline={verificationDeadline}
            refetch={refetch}
            presenterFactory={presenterFactory}
          />
        ),
        verificationDeadlines,
      )}
    </ConfirmedOfferOverviewSection>
  ) : null;

export default VerificationDeadlinesSection;
