import React from 'react';
import PropTypes from 'prop-types';
import { getInputId } from 'utils/id';
import RichTextInput from 'components/RichTextInput';
import { RequiredAsterisk } from 'components/RequiredLabel';

const ContentInput = ({ content, prefix, setContent }) => {
  const inputId = getInputId([prefix, 'content']);

  return (
    <div className="form-group">
      <label className="font-weight-bold mb-0" htmlFor={inputId}>
        Content
        <RequiredAsterisk />
      </label>
      <div className="d-flex">
        <ul className="mb-1">
          <li>
            <small>
              To insert the league&#39;s name, use&nbsp;
              <code className="text-inherit">{'{{league_name}}'}</code>
            </small>
          </li>
          <li>
            <small>
              To insert the league&#39;s main city, use&nbsp;
              <code className="text-inherit">{'{{league_city}}'}</code>
            </small>
          </li>
          <li>
            <small>
              To insert the league&#39;s main state, use&nbsp;
              <code className="text-inherit">{'{{league_state}}'}</code>
            </small>
          </li>
          <li>
            <small>
              To insert the league&#39;s custom field, use&nbsp;
              <code className="text-inherit">{'{{custom_field}}'}</code>
            </small>
          </li>
        </ul>
        <ul className="mb-1">
          <li>
            <small>
              To insert the league&#39;s sport, use&nbsp;
              <code className="text-inherit">{'{{sport_name}}'}</code>
            </small>
          </li>
          <li>
            <small>
              To insert the league&#39;s target location, use&nbsp;
              <code className="text-inherit">{'{{target_location}}'}</code>
            </small>
          </li>
          <li>
            <small>
              To insert the league&#39;s season, use&nbsp;
              <code className="text-inherit">{'{{season_name}}'}</code>
            </small>
          </li>
        </ul>
      </div>
      <RichTextInput
        className="form-control"
        data-test={inputId}
        onChange={(value) => setContent(value)}
        initialValue={content}
      />
    </div>
  );
};

ContentInput.propTypes = {
  content: PropTypes.string,
  prefix: PropTypes.string,
  setContent: PropTypes.func.isRequired,
};

ContentInput.defaultProps = {
  content: '',
  prefix: null,
};

export default ContentInput;
