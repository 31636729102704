import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props extends React.HTMLAttributes<HTMLElement> {
  label: string;
  path: string;
}

const BackToLink: React.FunctionComponent<Props> = ({ label, path }: Props) => (
  <Link to={path} className="d-inline-block mt-3">
    &laquo; Back to&nbsp;
    {label}
  </Link>
);

export default BackToLink;
