import * as React from 'react';
import { getInputId } from 'utils/id';
import { RequiredAsterisk } from 'components/RequiredLabel';
import ErrorAlert from 'components/ErrorAlert';
import { useState } from 'react';

interface Image {
  name: string;
  size: number;
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  image?: Image;
  isRequired?: boolean;
  loading?: boolean;
  prefix?: string;
}

const ImageInput: React.FunctionComponent<Props> = ({
  image,
  isRequired,
  loading,
  onChange,
  prefix,
}: Props) => {
  const inputId = getInputId([prefix, 'image']);
  const [imageSuccess, setImageSuccess] = useState(true);
  const CLOUDINARY_SIZE_LIMIT = 20000000;

  function imageSizeIsValidForCloudinary(inputImage: Image) {
    if (inputImage.size <= CLOUDINARY_SIZE_LIMIT) {
      setImageSuccess(true);
      return true;
    }
    return false;
  }

  function onImageChange(event: any) {
    const inputImage = event.target.files[0];
    if (inputImage) {
      if (imageSizeIsValidForCloudinary(inputImage) && onChange) {
        onChange(event);
      } else {
        setImageSuccess(false);
      }
    }
  }
  function renderImageResult() {
    if (!imageSuccess) {
      return (
        <ErrorAlert>
          File cannot exceed {CLOUDINARY_SIZE_LIMIT / 1000000}
          MB!
        </ErrorAlert>
      );
    }

    return null;
  }

  return (
    <div className="form-group">
      <label className="font-weight-bold" htmlFor={inputId}>
        Image
        {!!isRequired && <RequiredAsterisk />}
      </label>
      <div className="custom-file">
        <input
          aria-required={isRequired ? 'true' : 'false'}
          className="custom-file-input"
          disabled={loading}
          id={inputId}
          onChange={onImageChange}
          type="file"
        />
        <label className="custom-file-label" htmlFor={inputId}>
          {image ? image.name : 'Choose image'}
        </label>
      </div>
      {renderImageResult()}
    </div>
  );
};

ImageInput.defaultProps = {
  image: undefined,
  isRequired: false,
  loading: false,
  prefix: '',
};
export default ImageInput;
