import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { prepend } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { takeFive } from 'utils/array';
import {
  getRecentOrganizationIds,
  getRecentOrganizationsStateFromLocalStorage,
  updateRecentOrganizationsLocalStorage,
} from 'utils/localStorage/recentOrganizations';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import SPONSORSHIP_INSIGHT_REPORT_FRAGMENT from 'fragments/SponsorshipInsightReportFragment';
import BackToLink from 'components/BackToLink';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import SponsorshipInsightReport from 'components/SponsorshipInsightReport';
import OrganizationSections from 'components/OrganizationSections';
import EngagementStatusIcon from 'components/EngagementStatusIcon';
import TeamSnapIndicator from 'components/TeamSnapIndicator';
import OrganizationRemoveModal from './organization/organization-remove-modal';

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      name
      engagementStatus
      sponsorshipInsightReport {
        ...SponsorshipInsightReportFragment
      }
      sportsManagementSoftwareId
      users {
        id
      }
    }
  }
  ${SPONSORSHIP_INSIGHT_REPORT_FRAGMENT}
`;

const Organization = ({ match }) => {
  const { data, loading } = useQuery(ORGANIZATION_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: Number(match.params.id) },
  });

  const organization = data && data.organization;

  const state = getRecentOrganizationsStateFromLocalStorage();
  const recentOrganizationIds = takeFive(
    prepend(organization && organization.id, getRecentOrganizationIds(state)),
  );

  useEffect(
    updateRecentOrganizationsLocalStorage(recentOrganizationIds, state),
  );

  return (
    <RestrictTo roles={['admin']}>
      <FieldhousePageTitle title={loading ? null : organization.name}>
        <Page loaded={!loading}>
          {!loading && (
            <>
              <div className="justified-content mt-1">
                <BackToLink label="Organizations" path={ORGANIZATIONS_PATH} />
                <OrganizationRemoveModal.Toggle />
                <OrganizationRemoveModal organization={organization} />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EngagementStatusIcon organization={organization} />
                <h1 data-test="organization-name-header">
                  {organization.name}
                  <span className="align-text-bottom ml-3">
                    <SponsorshipInsightReport
                      sponsorshipInsightReport={
                        organization.sponsorshipInsightReport
                      }
                    />
                  </span>
                  <TeamSnapIndicator
                    sportsManagementSoftwareId={
                      organization.sportsManagementSoftwareId
                    }
                    className="sports-management-software-image"
                  />
                </h1>
              </div>
              <OrganizationSections organizationId={organization.id} />
            </>
          )}
        </Page>
      </FieldhousePageTitle>
    </RestrictTo>
  );
};

Organization.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Organization;
