import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { Prompt } from 'react-router-dom';
import { always, difference, isEmpty, mergeAll, pluck } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { unsavedChangesPrompt } from 'utils/misc';
import { loadingTense } from 'utils/string';
import AddedOrganizationCapabilityPackages from 'components/AddedOrganizationCapabilityPackages';
import ErrorAlert from 'components/ErrorAlert';
import Icon from 'components/Icon';
import PackageNameInput from 'components/formInputs/PackageNameInput';
import PriceInput from 'components/formInputs/PriceInput';
import RequiredLabel from 'components/RequiredLabel';
import PackageDescriptionInput from 'components/formInputs/PackageDescriptionInput';
import SponsorablePropertyMultiSelect from 'components/formInputs/SponsorablePropertyMultiSelect';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';

const getChanges = (updated, initial) =>
  mergeAll(difference([updated], [initial]));

const MUTATION = gql`
  mutation UpdateOrganizationPackage($input: UpdateOrganizationPackageInput!) {
    updateOrganizationPackage(input: $input) {
      organizationPackage {
        id
        name
        sponsorableProperties {
          id
        }
        packageDescription
        price
      }
    }
  }
`;

const UpdatePackageCard = ({ organizationPackage }) => {
  const {
    name: initialName,
    price: initialPrice,
    sponsorableProperties: initialSponsorableProperties,
    organizationCapabilityPackages,
    packageDescription: initialPackageDescription,
    organization,
  } = organizationPackage;

  const { sponsorableProperties } = organization;

  const [mutate] = useMutation(MUTATION);
  const update = ({
    name,
    price,
    sponsorablePropertyIds,
    packageDescription,
  }) =>
    mutate({
      variables: {
        input: {
          id: Number(organizationPackage.id),
          name,
          price,
          sponsorablePropertyIds,
          packageDescription,
        },
      },
    });

  const initialSponsorablePropertyIds = pluck(
    'id',
    initialSponsorableProperties,
  );
  const [name, setName] = useState(initialName);
  const [price, setPrice] = useState(initialPrice);
  const [sponsorablePropertyIds, setSponsorablePropertyIds] = useState(
    initialSponsorablePropertyIds,
  );
  const [packageDescription, setPackageDescription] = useState(
    initialPackageDescription,
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const sponsorablePropertyIdsEmpty = sponsorablePropertyIds.length === 0;
  const requiredDataPresent = !!(name && price && !sponsorablePropertyIdsEmpty);

  const changes = getChanges(
    { name, price: Number(price), sponsorablePropertyIds, packageDescription },
    {
      name: initialName,
      price: initialPrice,
      sponsorablePropertyIds: initialSponsorablePropertyIds,
      packageDescription: initialPackageDescription,
    },
  );
  const saved = isEmpty(changes);

  async function save(event) {
    event.preventDefault();
    const timeoutID = setTimeout(() => setLoading(true), 200);
    try {
      setError(null);
      await update(changes);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
    clearTimeout(timeoutID);
  }

  return (
    <div className="card mb-4">
      <form onSubmit={save}>
        <Prompt message={always(unsavedChangesPrompt)} when={!saved} />
        <div className="card-body">
          <PackageNameInput disabled={loading} name={name} setName={setName} />
          <PriceInput
            disabled={loading}
            onChange={({ target: { value } }) => setPrice(value)}
            required
            value={price}
          />
          <SponsorablePropertyMultiSelect
            setSponsorablePropertyIds={setSponsorablePropertyIds}
            sponsorableProperties={sponsorableProperties}
            sponsorablePropertyIds={sponsorablePropertyIds}
            sponsorablePropertyIdsEmpty={sponsorablePropertyIdsEmpty}
            loading={loading}
          />
          <PackageDescriptionInput
            description={packageDescription}
            onChange={({ target: { value } }) => setPackageDescription(value)}
          />
          {!!error && (
            <ErrorAlert className="mt-4 mb-0" data-test="error-alert" />
          )}
          <div className="d-flex justify-content-between align-items-center mt-1">
            <RequiredLabel />
            <button
              className={`btn btn-${saved ? 'success' : 'primary'}`}
              data-test="save-button"
              disabled={!requiredDataPresent || loading || saved}
              type="submit"
            >
              {saved ? (
                <>
                  <Icon className="mr-2" value="check" />
                  Saved
                </>
              ) : (
                loadingTense(loading, 'Save')
              )}
            </button>
          </div>
        </div>
      </form>
      {!isEmpty(organizationCapabilityPackages) && (
        <div className="card-footer">
          <AddedOrganizationCapabilityPackages
            displayModals
            organizationCapabilityPackages={organizationCapabilityPackages}
          />
        </div>
      )}
    </div>
  );
};

UpdatePackageCard.propTypes = {
  organizationPackage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    organization: LeagueSidePropTypes.organization.isRequired,
    sponsorableProperties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
    organizationCapabilityPackages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        organizationCapability: PropTypes.shape({
          id: PropTypes.number.isRequired,
          description: PropTypes.string,
          name: PropTypes.string.isRequired,
        }).isRequired,
        quantity: PropTypes.number.isRequired,
      }),
    ),
    packageDescription: PropTypes.string,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

export default UpdatePackageCard;
