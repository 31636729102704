import fumbledBall from './errorBoundary/fumbled-ball.jpg';
import SingleCardPage from './SingleCardPage';
import ContactUsLink from './ContactUsLink';

const ErrorFallback = () => {
  return (
    <SingleCardPage>
      <div className="heading">Uh oh! Something went wrong.</div>
      <img
        className="mt-4 mb-4 mw-100"
        src={fumbledBall}
        alt="Fumbled ball. By Ben Hershey on Unsplash."
      />
      <p>Sorry, it looks like we fumbled the ball.</p>
      <p>Try checking your internet connection and refreshing the page.</p>
      <p>
        If the problem continues, please
        <ContactUsLink />.
      </p>
    </SingleCardPage>
  );
};

export default ErrorFallback;
