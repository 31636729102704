import * as React from 'react';
import { ascend, map, path, sortWith } from 'ramda';
import AddedSponsorablePropertyCapabilityPackage from './AddedSponsorablePropertyCapabilityPackage';

interface Props {
  sponsorablePropertyCapabilityPackages: any[];
}

const sponsorablePropertyCapabilityNamePath = path([
  'sponsorablePropertyCapability',
  'name',
]);
const sortBySponsorablePropertyCapabilityName = sortWith([
  ascend(sponsorablePropertyCapabilityNamePath),
]);

const AddedSponsorablePropertyCapabilityPackages: React.FunctionComponent<
  Props
> = ({ sponsorablePropertyCapabilityPackages }: Props) => (
  <ul className="list-group list-group-flush">
    {map(
      (sponsorablePropertyCapabilityPackage) => (
        <li
          key={sponsorablePropertyCapabilityPackage.id}
          className="list-group-item bg-lighter border-0 py-3"
        >
          <AddedSponsorablePropertyCapabilityPackage
            sponsorablePropertyCapabilityPackage={
              sponsorablePropertyCapabilityPackage
            }
          />
        </li>
      ),
      sortBySponsorablePropertyCapabilityName(
        sponsorablePropertyCapabilityPackages,
      ),
    )}
  </ul>
);

export default AddedSponsorablePropertyCapabilityPackages;
