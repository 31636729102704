import { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import gql from 'graphql-tag';
import type { Offer } from 'types/offer';
import ModalEmailBodyPreview from 'components/ModalEmailBodyPreviewNoRefetching';
import Alert from 'components/Alert';
import Loader from 'components/Loader';
import ErrorAlert from 'components/ErrorAlert';

const ID = 'send-request-additional-offer-email-modal';

const EMAIL_QUERY = gql`
  query RequestAdditionalVerificationEmail($offerId: Int!, $message: String!) {
    requestAdditionalVerificationEmail(offerId: $offerId, message: $message) {
      htmlBody
      to
    }
  }
`;

const SEND_MUTATION = gql`
  mutation SendRequestAdditionalVerificationEmail(
    $offerId: Int!
    $message: String!
  ) {
    sendRequestAdditionalVerificationEmail(offerId: $offerId, message: $message)
  }
`;

type SendRequestAdditionalVerificationEmailModalProps = {
  offer: Offer;
};

function SendRequestAdditionalVerificationEmailModal(
  props: SendRequestAdditionalVerificationEmailModalProps,
) {
  const [message, setMessage] = useState('');
  const { offer } = props;

  const [send] = useMutation(SEND_MUTATION, {
    variables: { offerId: offer?.id, message },
  });

  const { data, loading, error } = useQuery(EMAIL_QUERY, {
    variables: { offerId: offer?.id, message: '' },
  });

  async function sendEmail() {
    await send(message as any);
    // @ts-ignore
    global.$(`#${ID}`).modal('hide');
  }

  if (loading) return <Loader />;
  if (error) return <ErrorAlert />;

  const { requestAdditionalVerificationEmail } = data;
  const { to, htmlBody } = requestAdditionalVerificationEmail;
  const canSendEmail = offer.sponsorableProperty.organization.canReceiveEmails;
  const handleMessageChange = (event: any) => setMessage(event.target.value);

  return (
    <Modal id={ID} title="Request additional verification?">
      {canSendEmail ? (
        <ModalBody>
          <p>
            {
              'Your changes have been saved! Since you rejected some of the images, would you like to send an email to '
            }
            <strong>{offer.sponsorableProperty.name}</strong>
            {
              ' requesting additional verification? If so, please provide a message to help explain what else we need for the verification to send back to the organization:'
            }
          </p>
          <textarea
            className="form-control mb-3"
            data-test="message"
            onChange={handleMessageChange}
            placeholder="Message to include in email to the organization"
            value={message}
          />
          <ModalEmailBodyPreview
            to={to}
            email={htmlBody}
            title="Request for Additional Verification Email Preview"
          />
        </ModalBody>
      ) : (
        <ModalBody>
          <p>Your changes have been saved!</p>
          <Alert alertStyle="warning">
            Since you rejected some of the images, you may want to send an
            additonal verification request to the organization but we do not
            have a contact email for that organization saved.
          </Alert>
        </ModalBody>
      )}
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          data-test="cancel"
        >
          Cancel
        </button>
        {canSendEmail && (
          <button
            className="btn btn-primary"
            data-test="send"
            disabled={!message}
            onClick={sendEmail}
            type="submit"
          >
            Send Email
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
}

SendRequestAdditionalVerificationEmailModal.ID = ID;
export default SendRequestAdditionalVerificationEmailModal;
