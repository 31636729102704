import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OrganizationDashboardWrapper from 'components/OrganizationDashboardWrapper';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import Loader from 'components/Loader';
import ORGANIZATION_ADDRESSES_FRAGMENT from 'components/organizationDashboardWrapper/LeagueAddressesFragment';
import Card from 'components/Card';
import CAPABILITY_FRAGMENT from 'fragments/CapabilityFragment';
import SelectInput from 'components/formInputs/SelectInput';
import { map } from 'ramda';
import { camelize } from 'utils/string';
import { history } from 'store';
import jwtDecode from 'jwt-decode';
import {
  ORGANIZATIONS_PATH,
  ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH,
} from 'utils/paths';
import GameSignageCapabilityForm from './GameSignageCapabilityForm';
import DedicatedEmailCapabilityForm from './DedicatedEmailCapabilityForm';
import SocialMediaPostCapabilityForm from './SocialMediaPostCapabilityForm';
import DefaultCapabilityForm from './DefaultCapabilityForm';
import GameJerseyCapabilityForm from './GameJerseyCapabilityForm';

const QUERY = gql`
  query Organization($id: Int!, $organizationScope: Boolean) {
    organization(id: $id) {
      id
      name
      organizationPlayLocations {
        id
        primary
        gameSignage {
          id
        }
        playLocation {
          id
          nickname
          address
        }
      }
      ...OrganizationAddressesFragment
    }
    capabilities(organizationScope: $organizationScope) {
      ...CapabilityFragment
      organizationFacingDescription
    }
    socialMediaChannels {
      id
      name
    }
  }
  ${ORGANIZATION_ADDRESSES_FRAGMENT}
  ${CAPABILITY_FRAGMENT}
`;

const renderCapability = (capability) => (
  <option key={capability.id} value={capability.name}>
    {capability.name}
  </option>
);

const renderCapabilities = map(renderCapability);

const AddCapability = ({ match }) => {
  const { params } = match;
  const { organizationId } = params;
  const [capabilityName, setCapabilityName] = useState(null);
  const [capabilityId, setCapabilityId] = useState(null);
  const [capabilityDescription, setCapabilityDescription] = useState(null);

  const userRole = () => {
    const token = localStorage.getItem('token');
    const claims = jwtDecode(token);
    return claims.role;
  };

  const navigate = () => {
    const fallBackUrl =
      userRole() === 'organization'
        ? `${ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH}`
        : `${ORGANIZATIONS_PATH}/${organizationId}/capabilities`;
    const currentUrl = history.location;
    history.goBack();

    setTimeout(() => {
      if (window.location.href === currentUrl) {
        history.push(fallBackUrl);
      }
    }, 500);
  };
  const { data, loading } = useQuery(QUERY, {
    variables: {
      id: Number(organizationId),
      organizationScope: true,
    },
  });

  if (loading) return <Loader />;

  const organization = data && data.organization;
  const capabilities = data && data.capabilities;
  const socialMediaChannels = data && data.socialMediaChannels;

  const mapCapabilities = {
    gameSignage: (
      <GameSignageCapabilityForm
        organization={organization}
        capabilityId={capabilityId}
        redirect={navigate}
      />
    ),
    dedicatedEmails: (
      <DedicatedEmailCapabilityForm
        organization={organization}
        capabilityId={capabilityId}
        redirect={navigate}
      />
    ),
    // eslint-disable-next-line max-len
    socialMediaPosts: (
      <SocialMediaPostCapabilityForm
        organization={organization}
        capabilityId={capabilityId}
        socialMediaChannels={socialMediaChannels}
        redirect={navigate}
      />
    ),
    teamsOfGameJerseys: (
      <GameJerseyCapabilityForm
        organization={organization}
        capabilityId={capabilityId}
        redirect={navigate}
      />
    ),
  };

  return (
    <OrganizationDashboardWrapper organizations={[organization]}>
      {() => (
        <>
          <button
            type="button"
            onClick={navigate}
            className="btn btn-primary mt-3"
          >
            &laquo; Back to Previous
          </button>
          <Card>
            <h2 style={{ marginBottom: '15px' }}>Add Capability</h2>
            <SelectInput
              data-test="name-input"
              label="Name"
              labelClassName="font-weight-bold"
              formGroupClassName="m-1"
              noDefault
              onChange={({ target: { value } }) => {
                const capability = capabilities.find((c) => c.name === value);
                setCapabilityName(value);
                setCapabilityId(capability.id);
                setCapabilityDescription(
                  capability.organizationFacingDescription,
                );
              }}
              required
              value={capabilityName}
            >
              <option value="">-- Please choose capability --</option>
              {renderCapabilities(capabilities)}
            </SelectInput>
            {capabilityName ? (
              <>
                <small className="mb-3">{`Description: ${capabilityDescription}`}</small>
                {mapCapabilities[camelize(capabilityName)] || (
                  <DefaultCapabilityForm
                    organization={organization}
                    capabilityId={capabilityId}
                    capabilityName={capabilityName}
                    redirect={navigate}
                  />
                )}
              </>
            ) : null}
          </Card>
        </>
      )}
    </OrganizationDashboardWrapper>
  );
};

AddCapability.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AddCapability;
