import React from 'react';
import { Query, useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import RestrictTo from 'components/RestrictTo';
import ProspectLocations from 'components/ProspectLocations';
import ProspectingReportTotalProgressBar from 'components/ProspectingReportTotalProgressBar';
import SponsorAndCampaignLinksHeader from 'components/SponsorAndCampaignLinksHeader';
import PROSPECT_LOCATION_QUERY, {
  AVAILABLE_AND_PROSPECTING_OFFERS_REFETCH_QUERY,
  PROSPECTING_AND_QUALIFIED_OFFERS_REFETCH_QUERY,
  PROSPECTING_AND_REMOVED_OFFERS_REFETCH_QUERY,
  SEND_SURVEY_LOCATION_REFETCH_QUERY,
  QUALIFIED_AND_PROPOSED_OFFERS_REFETCH_QUERY,
  QUALIFIED_AND_REMOVED_OFFERS_REFETCH_QUERY,
  SEND_OFFER_EMAILS_LOCATION_REFETCH_QUERY,
  PROPOSED_AND_REMOVED_OFFERS_REFETCH_QUERY,
} from './prospectLocation/prospectLocationQueries';

const ProspectLocation = ({ match, cable }) => {
  const locationId = Number(match.params.locationId);

  const { refetch: refetchAvailableAndProspectingOffers } = useQuery(
    AVAILABLE_AND_PROSPECTING_OFFERS_REFETCH_QUERY,
    {
      variables: { id: locationId },
      skip: true,
    },
  );

  const { refetch: refetchProspectingAndQualifiedOffers } = useQuery(
    PROSPECTING_AND_QUALIFIED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: locationId },
      skip: true,
    },
  );

  const { refetch: refetchProspectingAndRemovedOffers } = useQuery(
    PROSPECTING_AND_REMOVED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: locationId },
      skip: true,
    },
  );

  const { refetch: refetchSendSurveyLocation } = useQuery(
    SEND_SURVEY_LOCATION_REFETCH_QUERY,
    {
      variables: { id: locationId },
      skip: true,
    },
  );

  const { refetch: refetchQualifiedAndProposedOffers } = useQuery(
    QUALIFIED_AND_PROPOSED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: locationId },
      skip: true,
    },
  );

  const { refetch: refetchQualifiedAndRemovedOffers } = useQuery(
    QUALIFIED_AND_REMOVED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: locationId },
      skip: true,
    },
  );

  const { refetch: refetchSendOfferEmailsLocation } = useQuery(
    SEND_OFFER_EMAILS_LOCATION_REFETCH_QUERY,
    {
      variables: { id: locationId },
      skip: true,
    },
  );

  const { refetch: refetchProposedAndRemovedOffers } = useQuery(
    PROPOSED_AND_REMOVED_OFFERS_REFETCH_QUERY,
    {
      variables: { id: locationId },
      skip: true,
    },
  );

  return (
    <Query
      fetchPolicy="network-only"
      query={PROSPECT_LOCATION_QUERY}
      variables={{ id: locationId }}
    >
      {({ data, refetch: refetchLocation }) => {
        const location = data && data.location;
        const locationFilter = location && location.locationFilter;
        const availableSponsorableProperties =
          location && location.availableSponsorableProperties;
        const confirmedOffers = location && location.confirmedOffers;
        const proposedOffers = location && location.proposedOffers;
        const prospectingOffers = location && location.prospectingOffers;
        const qualifiedOffers = location && location.qualifiedOffers;
        const removedOffers = location && location.removedOffers;
        const campaign = locationFilter && locationFilter.campaign;

        return (
          <Page
            fullScreen
            loaded={!!location && !!refetchLocation && !!locationFilter}
            mainClassName="sui-flex"
          >
            {!!location && !!refetchLocation && !!locationFilter && (
              <RestrictTo roles={['admin']}>
                <FieldhousePageTitle title={location.name} />
                <div className="row mx-1 mt-3">
                  <div className="col-auto">
                    <SponsorAndCampaignLinksHeader campaign={campaign} />
                    <h1>
                      <Link to={`/prospect-campaign/${campaign.id}`}>
                        Prospecting
                      </Link>
                      &nbsp;/&nbsp;
                      <Link to={`/prospect-filter/${locationFilter.id}`}>
                        {locationFilter.name}
                      </Link>
                      &nbsp;/&nbsp;
                      {location.name}
                    </h1>
                  </div>
                  <div className="col-2 p-0 text-center align-self-center">
                    <ProspectingReportTotalProgressBar
                      prospectingReportId={location.prospectingReportId}
                      displayDetails={false}
                      cable={cable}
                    />
                  </div>
                </div>
                <ProspectLocations
                  locations={[location]}
                  locationFilter={locationFilter}
                  campaign={campaign}
                  availableSponsorableProperties={
                    availableSponsorableProperties
                  }
                  confirmedOffers={confirmedOffers}
                  proposedOffers={proposedOffers}
                  prospectingOffers={prospectingOffers}
                  qualifiedOffers={qualifiedOffers}
                  removedOffers={removedOffers}
                  refetchLocation={refetchLocation}
                  onAddProspects={refetchAvailableAndProspectingOffers}
                  onMarkAsQualified={refetchProspectingAndQualifiedOffers}
                  onProposedSendOfferEmails={refetchSendOfferEmailsLocation}
                  onQualifiedSendOfferEmails={refetchQualifiedAndProposedOffers}
                  onRemoveProposedFromCampaign={refetchProposedAndRemovedOffers}
                  onRemoveProspectingFromCampaign={
                    refetchProspectingAndRemovedOffers
                  }
                  onRemoveQualifedFromCampaign={
                    refetchQualifiedAndRemovedOffers
                  }
                  onSendPreQualSurvey={refetchSendSurveyLocation}
                />
              </RestrictTo>
            )}
          </Page>
        );
      }}
    </Query>
  );
};

ProspectLocation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      locationId: PropTypes.string,
    }),
  }).isRequired,
  cable: PropTypes.shape.isRequired,
};

export default ProspectLocation;
