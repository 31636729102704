import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getAuthentication } from 'modules';
import { getRole } from 'modules/authentication';

const userHasAccess = (roles, userRole) =>
  roles.includes(userRole) || userRole === 'superadmin';
const RestrictToUnwrapped = ({ roles, userRole, children }) =>
  userHasAccess(roles, userRole) ? children : null;

RestrictToUnwrapped.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  userRole: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const mapStateToProps = (state) => ({
  userRole: getRole(getAuthentication(state)),
});

const RestrictTo = connect(mapStateToProps)(RestrictToUnwrapped);

RestrictTo.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

export default RestrictTo;
