import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ORGANIZATION_DASHBOARD_PATH } from 'utils/paths';
import ContactUsLink from 'components/ContactUsLink';
import SingleCardPage from 'components/SingleCardPage';

const errorPageMessage = (loggedIn, offerId, status) => {
  const loginLink = <Link to="/login">Login</Link>;

  switch (status) {
    case '':
      return (
        <p>Try checking your internet connection and refreshing the page.</p>
      );
    case 'confirmed':
      return (
        <p>
          The offer has already been accepted by someone in your
          organization.&nbsp;
          {loggedIn ? (
            <Link to={`${ORGANIZATION_DASHBOARD_PATH}/sponsorship/${offerId}`}>
              Click here
            </Link>
          ) : (
            loginLink
          )}
          &nbsp;to view details of this previously accepted offer.
        </p>
      );
    case 'rejected_by_league':
      return (
        <p>
          The offer has already been rejected by someone in your
          organization.&nbsp;
          {loggedIn ? (
            <Link to={ORGANIZATION_DASHBOARD_PATH}>
              Return to your dashboard
            </Link>
          ) : (
            loginLink
          )}
          &nbsp;to see who receives sponsorship offers for your league.
        </p>
      );
    case 'expired link':
      return 'This link has expired, please contact support at support@sponsorship.teamsnap.com.';
    default:
      return <p>We couldn&#39;t find a sponsorship offer at this link.</p>;
  }
};

const errorHeader = (status) => {
  switch (status) {
    case '':
      return 'Uh oh! Something went wrong.';
    case 'confirmed':
      return 'Sponsorship Offer Already Accepted';
    case 'rejected_by_league':
      return 'Sponsorship Offer Already Rejected By Your League';
    case 'expired link':
      return 'Link has expired';
    default:
      return 'Sponsorship Offer Not Found';
  }
};

const ErrorPage = ({ loggedIn, offerId, status }) => (
  <SingleCardPage>
    <div className="heading" data-test="error-page-card">
      {errorHeader(status)}
    </div>
    {errorPageMessage(loggedIn, offerId, status)}
    <p>
      If you believe this is an error, please&nbsp;
      <ContactUsLink />.
    </p>
  </SingleCardPage>
);

ErrorPage.propTypes = {
  loggedIn: PropTypes.bool,
  offerId: PropTypes.string,
  status: PropTypes.string,
};

ErrorPage.defaultProps = {
  loggedIn: false,
  offerId: '',
  status: '',
};

export default ErrorPage;
