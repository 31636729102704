import React from 'react';
import gql from 'graphql-tag';
import COMMENT_FRAGMENT from 'fragments/CommentFragment';
import AddButton from 'components/AddButton';
import { ApolloQueryResult } from 'apollo-client';
import { useMutation } from 'react-apollo';

export type CreateCommentFormProps = {
  refetchCommentList: (variables?: {
    id: number;
  }) => Promise<ApolloQueryResult<any>>;
  organizationId: number;
};

const MUTATION = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      organization {
        id
        comments {
          ...CommentFragment
        }
      }
    }
  }
  ${COMMENT_FRAGMENT}
`;

const CreateCommentForm: React.FC<CreateCommentFormProps> = ({
  organizationId,
  refetchCommentList,
}) => {
  const [content, setContent] = React.useState('');

  const [createComment, { loading: isCreatingComment }] = useMutation(MUTATION);

  const handleCreateComment = React.useCallback(
    async () =>
      createComment({
        variables: {
          input: {
            content,
            organizationId,
          },
        },
      }),
    [content, organizationId],
  );

  const handleChangeContent = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setContent(event.target.value);
  };

  const handleSubmitComment = React.useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();
      await handleCreateComment();
      setContent('');
      refetchCommentList({
        id: organizationId,
      });
    },
    [handleCreateComment, organizationId, refetchCommentList],
  );

  return (
    <form onSubmit={handleSubmitComment}>
      <textarea
        className="form-control mb-2"
        data-test="new-comment-input"
        onChange={handleChangeContent}
        required
        value={content}
        disabled={isCreatingComment}
      />
      <AddButton
        disabled={!content.length}
        adding={isCreatingComment}
        subject="Comment"
      />
    </form>
  );
};

export default CreateCommentForm;
