import * as React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { filter, map } from 'ramda';
import gql from 'graphql-tag';
import { label } from 'utils/campaign';
import formatDate from 'utils/formatDate';
import { OFFER_PREFIX } from 'utils/paths';
import { Season } from 'types/season';
import HoverTable from 'components/HoverTable';
import Icon from 'components/Icon';

interface PreQualSurveyResponse {
  id: number;
  createdAt: Date;
}

interface Sponsor {
  id: number;
  name: string;
}
interface Campaign {
  id: number;
  name: string;
  season: Season;
  sponsor: Sponsor;
  year: string;
}
interface Offer {
  id: number;
  campaign: Campaign;
  preQualSurveyResponses: PreQualSurveyResponse[];
}
interface PreQualsTabProps {
  organizationId: number;
}

interface OrganizationData {
  organization: Organization;
}

interface Organization {
  id: number;
  offers: Offer[];
}

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      offers {
        id
        campaign {
          id
          name
          season
          sponsor {
            id
            name
          }
          year
        }
        preQualSurveyResponses {
          id
          createdAt
        }
      }
    }
  }
`;

const PreQualsTab: React.FunctionComponent<PreQualsTabProps> = ({
  organizationId,
}: PreQualsTabProps) => {
  const { data, loading } = useQuery<OrganizationData>(ORGANIZATION_QUERY, {
    variables: { id: organizationId },
  });

  const prefix = 'prequal-table';
  const offers = data && data.organization && data.organization.offers;
  const offerWithPreQualResponses = filter(
    (offer: Offer) => Boolean(offer.preQualSurveyResponses.length),
    offers || [],
  );
  const renderPreQualRow = (offer: Offer) => (
    <tr key={offer.id}>
      <td data-test={`${prefix}-campaign-name-${offer.id}`}>
        {label(offer.campaign)}
      </td>
      <td data-test={`${prefix}-offer-url-${offer.id}`}>
        <Link to={`${OFFER_PREFIX}/${offer.id}`}>
          <Icon value="export" />
        </Link>
      </td>
      <td data-test={`${prefix}-prequal-survey-response-date-${offer.id}`}>
        {formatDate(offer.preQualSurveyResponses[0].createdAt)}
      </td>
    </tr>
  );

  return !loading && offers ? (
    <div className="card-body">
      <strong>PreQual Responses</strong>
      <HoverTable>
        <thead>
          <tr>
            <th scope="col">Campaign</th>
            <th scope="col">Offer Url</th>
            <th scope="col">Date Responded</th>
          </tr>
        </thead>
        <tbody>{map(renderPreQualRow, offerWithPreQualResponses)}</tbody>
      </HoverTable>
    </div>
  ) : null;
};

export default PreQualsTab;
