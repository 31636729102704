import React, { useState } from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import { STATES } from 'utils/deliverableAddress';

const renderOption = (option) => (
  <option key={option} value={option}>
    {option}
  </option>
);

const renderOptions = map(renderOption);

const StateFilterForm = ({ onCancel, onSave, initialState }) => {
  const [state, setState] = useState(initialState);

  function handleSubmit(event) {
    event.preventDefault();
    onSave({ state });
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <select
          className="form-control"
          id="state"
          onChange={(event) =>
            setState(event.target.value ? event.target.value : null)
          }
          value={state || ''}
        >
          <option value="">-- State --</option>
          {renderOptions(STATES)}
        </select>
      </div>
      <div className="text-right">
        <button type="button" className="btn btn-link" onClick={onCancel}>
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          data-test="submit-state-filter"
          disabled={!state}
        >
          Save
        </button>
      </div>
    </form>
  );
};

StateFilterForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  initialState: PropTypes.string,
};

StateFilterForm.defaultProps = {
  initialState: null,
};

export default StateFilterForm;
