import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const { REACT_APP_PLACES_API_KEY } = runtimeEnv();

const Iframe = styled.iframe`
  height: 25vh;
`;

const GoogleMap = ({ address, apiKey }) =>
  address ? (
    <Iframe
      allowFullScreen
      className="w-100"
      frameBorder="0"
      src={`https://www.google.com/maps/embed/v1/place?key=${
        apiKey || REACT_APP_PLACES_API_KEY
      }&q=${encodeURIComponent(address)}`}
      tabIndex="-1"
    />
  ) : null;

GoogleMap.propTypes = {
  address: PropTypes.string,
  apiKey: PropTypes.string,
};

GoogleMap.defaultProps = {
  address: null,
  apiKey: null,
};

export default GoogleMap;
