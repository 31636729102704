import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import type { Offer } from 'types/offer';
import { useSubmit } from 'utils/hooks';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';

type MarkAsCanceledModalProps = {
  id: string;
  offer: Offer;
  refetchSponsorship: () => void;
  toggleClassName: string;
};

function MarkAsCanceledModal(props: MarkAsCanceledModalProps) {
  const { id, offer, refetchSponsorship, toggleClassName } = props;
  const { error, loading, handleSubmit } = useSubmit(() => markAsCanceled());
  const [mutate] = useMutation(MUTATION);

  const markAsCanceled = () =>
    mutate({
      variables: {
        input: {
          id: offer.id,
        },
      },
    }).then(refetchSponsorship);

  return (
    <ModalForm
      id={id}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: 'Cancel Offer',
        disabled: loading,
        className: 'btn btn-danger',
      }}
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon className="mr-1" value="block" />
            Cancel offer
          </>
        ),
      }}
      title="Are you sure?"
    >
      <p>Are you sure you want to cancel this confirmed offer?</p>
    </ModalForm>
  );
}

const MUTATION = gql`
  mutation CancelOffer($input: CancelOfferInput!) {
    cancelOffer(input: $input) {
      id
      status
    }
  }
`;

export default MarkAsCanceledModal;
