/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useState } from 'react';
import gql from 'graphql-tag';
import Loader from 'components/Loader';
import { useApolloClient } from 'react-apollo';
import { useSeasonalDashboardContext } from '../../context/SeasonalDashboardContext';
import PhotosGrid from './PhotosGrid';

const QUERY = gql`
  query FeaturedPhotos($season: String!, $year: Int!, $cursor: String) {
    seasonalDashboard {
      featuredPhotos(season: $season, year: $year, first: 10, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            thumbnailUrl
            fullSizeUrl
          }
        }
      }
    }
  }
`;

const buildVerificationImages = (edges) => {
  const verifcationImages = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < edges.length; i++) {
    verifcationImages.push(edges[i].node);
  }
  return verifcationImages;
};

function FeaturedPhoto() {
  const { token, season, year } = useSeasonalDashboardContext();

  const client = useApolloClient();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const fetchAll = useCallback(async () => {
    let hasNextPage = true;
    let cursor = null;
    let allResults = null;

    while (hasNextPage) {
      // eslint-disable-next-line no-await-in-loop
      const { data: fetchedData } = await client.query({
        query: QUERY,
        variables: { season, year, cursor },
        context: { headers: { Authorization: `Bearer ${token}` } },
      });
      const fetchedDataPhotos = fetchedData.seasonalDashboard.featuredPhotos;

      if (!allResults) {
        allResults = fetchedData;
      } else {
        const { featuredPhotos } = allResults.seasonalDashboard;

        featuredPhotos.edges = featuredPhotos.edges.concat(
          fetchedDataPhotos.edges,
        );
        featuredPhotos.pageInfo = fetchedDataPhotos.pageInfo;
      }
      hasNextPage = fetchedDataPhotos.pageInfo.hasNextPage;
      cursor = fetchedDataPhotos.pageInfo.endCursor;
    }
    setLoading(false);
    setData(allResults);
  }, [client, season, token, year]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  if (loading) return <Loader />;

  const seasonalDashboard = data && data.seasonalDashboard;
  const edges = seasonalDashboard
    ? data.seasonalDashboard.featuredPhotos.edges
    : [];
  const verificationImages = buildVerificationImages(edges);

  return (!loading, edges.length) ? (
    <>
      <h1 style={{ marginBottom: '19px' }}>FEATURED PHOTOS</h1>
      <PhotosGrid images={verificationImages} />
    </>
  ) : (
    <h1
      className="d-flex justify-content-center"
      data-test="no-results-message"
    >
      {' '}
      Sorry! You have no photos yet!{' '}
    </h1>
  );
}

export default FeaturedPhoto;
