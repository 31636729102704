import * as React from 'react';
import GenericCreativeContent from 'types/genericCreativeContent';
import PreviewCard from 'components/PreviewCard';
import './GenericCreativeContentCard.css';
import DangerousInnerHtml from './dangerous-inner-html/dangerous-inner-html';

interface Props extends React.HTMLAttributes<HTMLElement> {
  genericCreativeContent: GenericCreativeContent;
}

const GenericCreativeContentCard: React.FunctionComponent<Props> = ({
  children,
  genericCreativeContent,
}: Props) => (
  <PreviewCard
    cardId={`generic-creative-content-card-${genericCreativeContent.id}`}
    footer={children}
    header={<small>{genericCreativeContent.name}</small>}
  >
    <div className="generic-creative-content-card border rounded p-2">
      <DangerousInnerHtml html={genericCreativeContent.renderedContent} />
    </div>
  </PreviewCard>
);

export default GenericCreativeContentCard;
