import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { isConfirmed } from 'utils/offer';
import SPONSORSHIP_EVENT_FRAGMENT from 'fragments/SponsorshipEventFragment';
import type { Offer } from 'types/offer';
import Card from 'components/Card';
import CreateVerificationDeadlineModal from './create-verification-deadline-modal';
import SendVerificationReminderEmailModal from './send-verification-reminder-email-modal';
import UploadVerificationImageModal from './upload-verification-image-modal';
import VerificationDeadlinesTable from './verification-deadlines-table';
import CreateCreativeFileModal from './create-creative-file-modal';
import CreateCreativeContentModal from './create-creative-content-modal';
import { VERIFICATION_DEADLINE_FRAGMENT } from '../../fragments';

const VERIFICATION_QUERY = gql`
  query Sponsorship($id: Int!) {
    offer(id: $id) {
      id
      anyVerificationDeadlinesToIncludeInEmails
      sponsorshipEvents {
        ...SponsorshipEventFragment
      }
      verificationDeadlines {
        ...VerificationDeadlineFragment
      }
    }
  }
  ${SPONSORSHIP_EVENT_FRAGMENT}
  ${VERIFICATION_DEADLINE_FRAGMENT}
`;

type VerificationsCardProps = {
  offer: Offer;
};

export default function VerificationsCard(props: VerificationsCardProps) {
  const { offer } = props;
  const {
    anyVerificationDeadlinesToIncludeInEmails,
    assets,
    verificationDeadlines,
  } = offer;
  const confirmed = isConfirmed(offer);
  const showSendReminderButton =
    anyVerificationDeadlinesToIncludeInEmails && confirmed;
  const firstButtonClassName = 'btn btn-secondary btn-sm mb-2';
  const buttonClassName = `${firstButtonClassName} ml-2`;

  return (
    <Query
      fetchPolicy="network-only"
      variables={{ id: Number(offer.id) }}
      query={VERIFICATION_QUERY}
    >
      {({ refetch }: any) => (
        <Card data-test="verifications" bottomMargin={0}>
          <div className="row">
            <h2 className="col">Verification Deadlines</h2>
          </div>
          {assets.length ? (
            <>
              <CreateVerificationDeadlineModal
                disabled={!confirmed}
                offer={offer}
                toggleClassName={firstButtonClassName}
              />
              {verificationDeadlines.length ? (
                <>
                  <CreateCreativeContentModal
                    disabled={!confirmed}
                    toggleClassName={buttonClassName}
                    verificationDeadlines={verificationDeadlines}
                  />
                  <CreateCreativeFileModal
                    disabled={!confirmed}
                    toggleClassName={buttonClassName}
                    onUploadComplete={refetch}
                    verificationDeadlines={verificationDeadlines}
                  />
                  <UploadVerificationImageModal
                    onUploadComplete={refetch}
                    toggleClassName={buttonClassName}
                    verificationDeadlines={verificationDeadlines}
                  />
                  {showSendReminderButton && (
                    <SendVerificationReminderEmailModal
                      offer={offer}
                      toggleClassName={buttonClassName}
                    />
                  )}
                  <VerificationDeadlinesTable offer={offer} />
                </>
              ) : (
                <p className="text-center mt-3">
                  No deadlines exist for this campaign!
                </p>
              )}
            </>
          ) : (
            <p className="text-center mt-4">
              No capabilities are required for this campaign!
            </p>
          )}
        </Card>
      )}
    </Query>
  );
}
