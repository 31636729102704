import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import USER_FRAGMENT from 'fragments/UserFragment';
import AddExternalUserModal from 'components/AddExternalUserModal';
import ErrorAlert from 'components/ErrorAlert';

const MUTATION = gql`
  mutation InviteSponsorUser($input: InviteSponsorUserInput!) {
    inviteSponsorUser(input: $input) {
      id
      users {
        ...UserFragment
        lastLogin
      }
    }
  }
  ${USER_FRAGMENT}
`;

const AddSponsorUserModal = ({ sponsorId }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <AddExternalUserModal
        addUser={(email) =>
          mutate({
            variables: { input: { sponsorId: Number(sponsorId), email } },
          })
        }
        internal
      >
        <ErrorAlert>
          <strong>WARNING!</strong>
          &nbsp;The sponsor users feature is in a beta testing phase.&nbsp; Do
          NOT use this to add users unless you have been given explicit
          permission to do so.
        </ErrorAlert>
      </AddExternalUserModal>
    )}
  </Mutation>
);

AddSponsorUserModal.propTypes = {
  sponsorId: PropTypes.number.isRequired,
};

export default AddSponsorUserModal;
