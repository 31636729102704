import React from 'react';
import { filter, pipe } from 'ramda';
import styled from 'styled-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatCurrency } from 'utils/formatNumber';
import { isApproved, sumApprovedAmounts, sumPendingAmounts } from 'utils/check';
import { getChecksBarLabelSpacing } from 'utils/checksProgressBar';
import { mapWithIndex } from 'utils/array';
import { sortByProp } from 'utils/sort';
import NestedProgressBar from 'components/NestedProgressBar';
import CheckDetails from './checksProgressBar/CheckDetails';

const sortApprovedChecksByNumber = pipe(
  filter(isApproved),
  sortByProp('number'),
);

const CheckBarLabels = styled.div`
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0 15px;
  div:first-child {
    text-align: left;
    white-space: nowrap;
  }
  & > div {
    padding: 0;
    text-align: center;
  }
`;

const ChecksProgressBar = ({ organizationDashboard, offer }) => {
  const { checks, value: offerValue } = offer;

  const sortedApprovedChecks = sortApprovedChecksByNumber(checks);

  const approvedAmount = sumApprovedAmounts(checks);
  const pendingAmount = sumPendingAmounts(checks);

  const {
    colWidth,
    firstColWidth,
    firstRightMargin,
    getRightMargin,
    isNormalized,
  } = getChecksBarLabelSpacing(
    approvedAmount,
    organizationDashboard,
    offerValue,
    sortedApprovedChecks,
  );

  const renderCheckDetail = (check, index) => {
    const nextCheck = sortedApprovedChecks[index + 1];

    const alignRight = !nextCheck && isNormalized;

    return (
      <div
        className={classNames(`col-${colWidth}`, { 'text-right': alignRight })}
        style={{ marginRight: `${getRightMargin(nextCheck)}%` }}
        key={check.id}
      >
        <CheckDetails check={check} />
      </div>
    );
  };

  return (
    <>
      <CheckBarLabels className="row">
        <div
          className={`col-${firstColWidth}`}
          style={{ marginRight: `${firstRightMargin}%` }}
        >
          {`${formatCurrency(approvedAmount)} of ${formatCurrency(offerValue)}`}
          {!!pendingAmount && `, ${formatCurrency(pendingAmount)} Pending`}
        </div>
        {!!organizationDashboard &&
          mapWithIndex(renderCheckDetail, sortedApprovedChecks)}
      </CheckBarLabels>
      <NestedProgressBar
        confirmed={approvedAmount}
        maximum={offerValue}
        pending={pendingAmount}
      />
    </>
  );
};

ChecksProgressBar.propTypes = {
  organizationDashboard: PropTypes.bool,
  offer: LeagueSidePropTypes.offer.isRequired,
};

ChecksProgressBar.defaultProps = {
  organizationDashboard: false,
};

export default ChecksProgressBar;
