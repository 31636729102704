import React from 'react';
import PropTypes from 'prop-types';
import { pickBy, pipe, keys, identity } from 'ramda';
import { joinWithComma } from 'utils/string';
import FilterCard from './FilterCard';
import SeasonFilterForm from './SeasonFilterForm';

const selectSeasonsByFlag = pickBy(identity);
const selectSeasons = pipe(selectSeasonsByFlag, keys);
function descriptionText(winter, spring, summer, fall) {
  const seasons = {
    Winter: winter,
    Spring: spring,
    Summer: summer,
    Fall: fall,
  };
  const selectedSeasons = selectSeasons(seasons);

  if (selectedSeasons.length === 0) return 'No seasons selected.';

  const formattedSeasons = joinWithComma(selectedSeasons);
  return (
    <>
      Sponsorable properties that play in&nbsp;
      <strong className="font-weight-bold">{formattedSeasons}</strong>
    </>
  );
}

const SeasonFilterCard = ({ filterId, winter, spring, summer, fall }) => (
  <FilterCard
    description={descriptionText(winter, spring, summer, fall)}
    filterId={filterId}
    formRenderer={({ save, cancel }) => (
      <SeasonFilterForm
        onSave={save}
        onCancel={cancel}
        spring={spring}
        summer={summer}
        fall={fall}
        winter={winter}
      />
    )}
    iconValue="calendar"
    title="Seasons"
  />
);

SeasonFilterCard.propTypes = {
  filterId: PropTypes.number.isRequired,
  winter: PropTypes.bool,
  spring: PropTypes.bool,
  summer: PropTypes.bool,
  fall: PropTypes.bool,
};

SeasonFilterCard.defaultProps = {
  winter: false,
  spring: false,
  summer: false,
  fall: false,
};

export default SeasonFilterCard;
