import * as React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import type { User as UserType } from 'types/user';
import USER_FRAGMENT from 'fragments/UserFragment';
import UsersTable from 'components/UsersTable';
import RestrictTo from 'components/RestrictTo';
import ContactUsLink from 'components/ContactUsLink';
import UsersTableAdditionalColumns from './usersTab/UsersTableAdditionalColumns';
import AddOrganizationUserModal from './usersTab/AddOrganizationUserModal';

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      users {
        ...UserFragment
        lastLogin
      }
    }
  }
  ${USER_FRAGMENT}
`;

interface User extends UserType {
  lastLogin?: Date;
}
interface Organization {
  id: number;
  users: User[];
}

interface OrganizationData {
  organization: Organization;
}

interface Props {
  organizationId: number;
}

const UsersTab: React.FunctionComponent<Props> = ({
  organizationId,
}: Props) => {
  const { data, loading } = useQuery<OrganizationData>(ORGANIZATION_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: organizationId },
  });

  const organization = data && data.organization;

  return !loading && organization ? (
    <div className="list-group list-group-flush">
      <UsersTable
        additionalColumns={(user) => (
          <RestrictTo roles={['admin']}>
            <UsersTableAdditionalColumns
              user={user}
              organizationId={organization.id}
            />
          </RestrictTo>
        )}
        additionalHeaders={
          <RestrictTo roles={['admin']}>
            <th scope="col">Last Login</th>
            <th scope="col" className="text-center">
              Dashboard
            </th>
            <th scope="col" aria-label="action-column" />
          </RestrictTo>
        }
        addUserModal={
          <AddOrganizationUserModal organizationId={organization.id} />
        }
        bodyClassname="list-group-item"
        users={organization.users}
      >
        <RestrictTo roles={['organization']}>
          <p>
            {
              "Any members of your organization that you add as users will gain the ability to make choices on your organization's behalf. Users will be able to accept offers, view sponsorships, send verifications, and add other users. All users will be notified via email when an action is taken. Please keep this in mind as you choose who to invite to be a user for your organization. We recommend limiting the number of users to 3 or less. "
            }
            To remove a user, please&nbsp;
            <ContactUsLink />!
          </p>
        </RestrictTo>
      </UsersTable>
    </div>
  ) : null;
};

export default UsersTab;
