import * as React from 'react';
import { ascend, map, path, sortWith } from 'ramda';
import OrganizationCapabilityPackage from 'types/organizationCapabilityPackage';
import AddedOrganizationCapabilityPackage from './AddedOrganizationCapabilityPackage';

interface Props {
  displayModals?: boolean;
  organizationCapabilityPackages: OrganizationCapabilityPackage[];
}

const organizationCapabilityNamePath = path(['organizationCapability', 'name']);
const sortByOrganizationCapabilityName = sortWith([
  ascend(organizationCapabilityNamePath),
]);

const AddedOrganizationCapabilityPackages: React.FunctionComponent<Props> = ({
  displayModals,
  organizationCapabilityPackages,
}: Props) => (
  <ul className="list-group list-group-flush">
    {map(
      (organizationCapabilityPackage) => (
        <li
          key={organizationCapabilityPackage.id}
          className="list-group-item bg-lighter border-0 py-3"
        >
          <AddedOrganizationCapabilityPackage
            displayModals={displayModals}
            organizationCapabilityPackage={organizationCapabilityPackage}
          />
        </li>
      ),
      sortByOrganizationCapabilityName(organizationCapabilityPackages),
    )}
  </ul>
);

AddedOrganizationCapabilityPackages.defaultProps = {
  displayModals: false,
};

export default AddedOrganizationCapabilityPackages;
