import gql from 'graphql-tag';

const ASSET_FRAGMENT = gql`
  fragment AssetFragment on Asset {
    id
    campaignCapability {
      id
      defaultVerificationDeadlines {
        id
      }
      executionExpectations
    }
  }
`;

export default ASSET_FRAGMENT;
