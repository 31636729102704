import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCampaignSearchFilters } from 'modules';
import Card from 'components/Card';
import {
  getSearchFilter,
  save,
  deleteFilter,
} from 'modules/campaign/searchFilters';
import RefetchDataContext from '../RefetchDataContext';

class FilterCardUnwrapped extends React.Component {
  constructor(props) {
    super(props);

    this.state = { editing: false };

    this.edit = () => this.setState({ editing: true });
    this.stopEditing = () => this.setState({ editing: false });
    this.save = this.save.bind(this);
    this.toggleEnabled = this.toggleEnabled.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  get paragraph() {
    const {
      filterId,
      deleteFilter: deleteFilterFunc,
      description,
    } = this.props;
    return (
      <>
        <div className="float-right">
          <button
            className="btn btn-link"
            data-test={`edit-filter-${filterId}`}
            onClick={this.edit}
            type="button"
          >
            Edit
          </button>
          <button
            type="button"
            className="btn btn-link text-danger"
            data-test={`remove-filter-${filterId}`}
            onClick={async () => {
              await deleteFilterFunc();
              const refetchData = this.context;
              refetchData();
            }}
          >
            Remove
          </button>
        </div>
        {description}
      </>
    );
  }

  get form() {
    const { formRenderer } = this.props;
    const saveForm = this.save.bind(this);
    const cancel = this.cancel.bind(this);

    return formRenderer({ save: saveForm, cancel });
  }

  cancel() {
    this.stopEditing();

    const { temporaryId, deleteFilter: deleteFilterFunc } = this.props;
    if (temporaryId) {
      deleteFilterFunc();
    }
  }

  async save(parameters) {
    const { saveFilter } = this.props;
    this.stopEditing();
    await saveFilter({ parameters });
    const refetchData = this.context;
    refetchData();
  }

  async toggleEnabled(event) {
    const { saveFilter } = this.props;
    await saveFilter({ enabled: event.target.checked });
    const refetchData = this.context;
    refetchData();
  }

  render() {
    const { enabled, filterId, iconValue, title, temporaryId } = this.props;
    const { editing } = this.state;
    return (
      <Card verticalMargins={3}>
        <strong className="d-block">
          <input
            checked={enabled}
            className="float-right"
            data-test={`filter-${filterId}-toggle`}
            onChange={this.toggleEnabled}
            type="checkbox"
          />
          <span className={`icon icon-${iconValue} text-muted mr-2`} />
          {title}
        </strong>
        {editing || temporaryId ? this.form : this.paragraph}
      </Card>
    );
  }
}

FilterCardUnwrapped.propTypes = {
  enabled: PropTypes.bool.isRequired,
  filterId: PropTypes.number.isRequired,
  title: PropTypes.node.isRequired,
  iconValue: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  formRenderer: PropTypes.func.isRequired,
  saveFilter: PropTypes.func.isRequired,
  deleteFilter: PropTypes.func.isRequired,
  temporaryId: PropTypes.number,
};

FilterCardUnwrapped.defaultProps = {
  temporaryId: null,
};

function mapStateToProps(state, { filterId }) {
  const filter = getSearchFilter(getCampaignSearchFilters(state), filterId);

  return { enabled: filter.enabled, temporaryId: filter.temporaryId };
}

const mapDispatchToProps = (dispatch, { filterId }) => ({
  saveFilter: (props) => dispatch(save(filterId, props)),
  deleteFilter: () => dispatch(deleteFilter(filterId)),
});

const FilterCard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(FilterCardUnwrapped);

FilterCard.propTypes = {
  filterId: PropTypes.number.isRequired,
  title: PropTypes.node.isRequired,
  iconValue: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  formRenderer: PropTypes.func.isRequired,
};

FilterCardUnwrapped.contextType = RefetchDataContext;

export { FilterCardUnwrapped };

export default FilterCard;
