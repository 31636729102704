export const prop =
  <T, K extends keyof T>(property: K) =>
  (obj: T) =>
    obj[property];

/*
  SnapUI allows '[key: string]: any' in some of their types, which is causing
  issues when we try to manipulate them. This workaround removes the
  index signature, leaving only the properties of the object.
*/
export type RemoveIndexSignature<T> = {
  [K in keyof T as {} extends Record<K, 1> ? never : K]: T[K];
};
