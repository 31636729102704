import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';

const documentTitle = (title) =>
  title ? `${title} - TeamSnap Fieldhouse` : 'TeamSnap Fieldhouse';

const FieldhousePageTitle = ({ children, title }) => (
  <DocumentTitle title={documentTitle(title)}>
    <>{children}</>
  </DocumentTitle>
);

FieldhousePageTitle.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
};

FieldhousePageTitle.defaultProps = {
  children: null,
  title: null,
};

export default FieldhousePageTitle;
