import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { pluralizedTense, replaceUnderscoresWithSpaces } from 'utils/string';
import { isConfirmed, isRemoved } from 'utils/offer';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import Card from 'components/Card';
import Badge from 'components/Badge';
import Icon from 'components/Icon';
import DataConfidenceIndicator from 'components/DataConfidenceIndicator';
import RemoveFromCampaignModal from 'components/RemoveFromCampaignModal';
import SponsorshipInsightReport from 'components/SponsorshipInsightReport';
import EventsSurveyResponsesLinkDropdown from 'components/EventsSurveyResponsesLinkDropdown';
import NewWindowLink from 'components/NewWindowLink';
import TeamSnapIndicator from 'components/TeamSnapIndicator';
import AddSponsorablePropertyToCampaignButton from './AddSponsorablePropertyToCampaignButton';
import DisplaySponsorablePropertyCapabilitiesModal from './DisplaySponsorablePropertyCapabilitiesModal';
import DisplaySponsorablePropertyPackagesModal from './DisplaySponsorablePropertyPackagesModal';

function getBorder(isAdded, isHovered) {
  if (isAdded) return 'success';
  if (isHovered) return 'warning';

  return undefined;
}

const SponsorablePropertyDetail = ({ iconValue, label, title }) => (
  <p className="text-truncate mb-0 mt-2">
    <Icon className="mr-2 text-muted" value={iconValue} title={title} />
    <small>{label}</small>
  </p>
);

const getAgeLabel = (maxAge, minAge) => {
  if (maxAge) {
    if (minAge) {
      return `Ages: ${minAge} - ${maxAge}`;
    }

    return `Max Age: ${maxAge}`;
  }
  if (minAge) {
    return `Min Age: ${minAge}`;
  }

  return '--';
};

SponsorablePropertyDetail.propTypes = {
  iconValue: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const SponsorablePropertyCard = ({
  campaignId,
  offer,
  onRemove,
  onMouseEnter,
  onMouseLeave,
  isHovered,
  sponsorableProperty,
}) => {
  const {
    id: sponsorablePropertyId,
    competitiveness,
    sponsorablePropertyCapabilitiesCount,
    sponsorablePropertyPackagesCount,
    maxAge,
    minAge,
    name,
    numberOfPlayers,
    organization,
    sport,
    dataConfidence,
  } = sponsorableProperty;
  const {
    id: organizationId,
    eventsSurveyResponses,
    sponsorshipInsightReport,
    websiteUrl,
    sportsManagementSoftwareId,
  } = organization;
  const sponsorablePropertyCapabilitiesLabel = `${sponsorablePropertyCapabilitiesCount} ${pluralizedTense(
    sponsorablePropertyCapabilitiesCount,
    'Capability',
  )}`;
  const sponsorablePropertyPackagesLabel = `${sponsorablePropertyPackagesCount} ${pluralizedTense(
    sponsorablePropertyPackagesCount,
    'Package',
  )}`;

  const isAdded = !!offer && !isRemoved(offer);

  return (
    <Card
      id={`card${sponsorablePropertyId}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      border={getBorder(isAdded, isHovered)}
      verticalMargins={0}
    >
      <div className="row" style={{ height: '27px' }}>
        <div
          className={classNames('text-truncate', {
            'pr-1 col-auto': !sponsorshipInsightReport,
            'pr-0 col': sponsorshipInsightReport,
          })}
        >
          <TeamSnapIndicator
            sportsManagementSoftwareId={sportsManagementSoftwareId}
            className="height-width-20px icon mr-2"
          />
          <DataConfidenceIndicator dataConfidence={dataConfidence} />
          <strong>
            <NewWindowLink
              hideIcon
              to={`${ORGANIZATIONS_PATH}/${organizationId}`}
              title={name}
            >
              {name}
            </NewWindowLink>
          </strong>
        </div>
        {!!websiteUrl && (
          <div className="col-auto pl-0">
            <small className="ml-1">
              <NewWindowLink
                to={websiteUrl}
                title="Visit organization website"
              />
            </small>
          </div>
        )}
        {!!sponsorshipInsightReport && (
          <div className="col-auto px-1">
            <SponsorshipInsightReport
              sponsorshipInsightReport={sponsorshipInsightReport}
            />
          </div>
        )}
        <div
          className={classNames('text-right text-nowrap pl-1', {
            'col-3': sponsorshipInsightReport,
            col: !sponsorshipInsightReport,
          })}
        >
          {offer ? (
            <>
              <Badge
                badgeType={isAdded ? 'success' : 'danger'}
                className="text-capitalize"
                data-test="status-badge"
              >
                {replaceUnderscoresWithSpaces(offer.status)}
              </Badge>
              {isAdded && !isConfirmed(offer) && (
                <RemoveFromCampaignModal
                  offers={[offer]}
                  onRemove={onRemove}
                  prefix={String(offer.id)}
                  toggleProps={{
                    className: 'btn btn-link text-danger p-0 ml-1',
                    children: <Icon value="trash" />,
                  }}
                />
              )}
            </>
          ) : (
            <AddSponsorablePropertyToCampaignButton
              campaignId={campaignId}
              sponsorablePropertyId={sponsorablePropertyId}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-3 pr-0">
          <SponsorablePropertyDetail
            label={numberOfPlayers ? `${numberOfPlayers} players` : '--'}
            iconValue="users"
            title="Number of Players"
          />
          <SponsorablePropertyDetail
            label={sport || '--'}
            iconValue="trophy"
            title="Sport"
          />
        </div>
        <div className="col-3 pr-0">
          <SponsorablePropertyDetail
            label={competitiveness || '--'}
            iconValue="medal"
            title="Competitiveness Level"
          />
          <SponsorablePropertyDetail
            label={getAgeLabel(maxAge, minAge)}
            iconValue="graduation-cap"
            title="Age Range"
          />
        </div>
        <div className="col pr-0">
          <SponsorablePropertyDetail
            label={
              sponsorablePropertyCapabilitiesCount ? (
                <DisplaySponsorablePropertyCapabilitiesModal
                  sponsorablePropertyId={sponsorablePropertyId}
                  sponsorablePropertyCapabilitiesLabel={
                    sponsorablePropertyCapabilitiesLabel
                  }
                />
              ) : (
                sponsorablePropertyCapabilitiesLabel
              )
            }
            iconValue="database"
            title="Capabilities"
          />
          <SponsorablePropertyDetail
            label={
              sponsorablePropertyPackagesCount ? (
                <DisplaySponsorablePropertyPackagesModal
                  sponsorablePropertyId={sponsorablePropertyId}
                  sponsorablePropertyPackagesLabel={
                    sponsorablePropertyPackagesLabel
                  }
                />
              ) : (
                sponsorablePropertyPackagesLabel
              )
            }
            iconValue="v-card"
            title="Sponsorable Property Packages"
          />
        </div>
        <div
          className="col-auto align-self-end text-right text-nowrap pl-1"
          data-test="events-survey-response"
        >
          <EventsSurveyResponsesLinkDropdown
            eventsSurveyResponses={eventsSurveyResponses}
            small
          />
        </div>
      </div>
    </Card>
  );
};

SponsorablePropertyCard.propTypes = {
  campaignId: PropTypes.number.isRequired,
  isHovered: PropTypes.bool.isRequired,
  offer: LeagueSidePropTypes.offer,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  sponsorableProperty: LeagueSidePropTypes.sponsorableProperty.isRequired,
};

SponsorablePropertyCard.defaultProps = {
  offer: undefined,
};

export default SponsorablePropertyCard;
