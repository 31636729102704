import React from 'react';
import PropTypes from 'prop-types';

import FilterCard from './FilterCard';
import StateFilterForm from './StateFilterForm';

const StateFilterCard = ({ filterId, state }) => (
  <FilterCard
    filterId={filterId}
    title="State"
    iconValue="address"
    description={<>{`Showing all sponsorable properties in ${state}`}</>}
    formRenderer={({ save, cancel }) => (
      <StateFilterForm initialState={state} onCancel={cancel} onSave={save} />
    )}
  />
);

StateFilterCard.propTypes = {
  filterId: PropTypes.number.isRequired,
  state: PropTypes.string.isRequired,
};

export default StateFilterCard;
