import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { pluralizedTense } from 'utils/string';
import formatDate from 'utils/formatDate';
import Card from 'components/Card';
import NestedProgressBar from 'components/NestedProgressBar';

const ProspectingCard = ({ id, prospectingReportId, link, name, cable }) => {
  const INITIAL_STATE = {
    available: 0,
    prospecting: 0,
    qualified: 0,
    proposed: 0,
    confirmed: 0,
    target: 0,
    lastTouchpoint: null,
  };

  const [prospectingReport, setProspectingReport] = useState(INITIAL_STATE);

  useEffect(() => {
    const subscription = cable.subscriptions.create(
      { channel: 'ProspectingReportChannel', id: prospectingReportId },
      {
        received(subscriptionData) {
          setProspectingReport(subscriptionData);
        },
      },
    );

    return function cleanup() {
      cable.subscriptions.remove(subscription);
    }; // eslint-disable-next-line
  }, []);

  const {
    available,
    prospecting,
    qualified,
    proposed,
    confirmed,
    target,
    lastTouchpoint,
  } = prospectingReport;

  return (
    <Card data-test={`prospecting-card-${id}`} key={id}>
      <NestedProgressBar
        confirmed={confirmed}
        maximum={target}
        pending={prospecting + qualified + proposed}
      />
      <div className="row mt-2">
        <h3 className="col text-truncate">
          <Link
            to={`/${link}/${id}`}
            title={name}
            data-test={`name-link-${id}`}
          >
            {name}
          </Link>
        </h3>
        <div className="col-auto text-right align-self-center">
          <strong>{`${confirmed} of ${target} Sponsorable ${pluralizedTense(
            target,
            'Property',
          )} Confirmed`}</strong>
        </div>
      </div>
      <div className="row">
        <div className="col-2 text-center text-uppercase text-nowrap">
          <small>
            <strong>{available}</strong>
            <div>Available</div>
          </small>
        </div>
        <div className="col-3 text-center text-uppercase text-nowrap">
          <small>
            <strong>{prospecting}</strong>
            <div>{pluralizedTense(prospecting, 'Prospect')}</div>
          </small>
        </div>
        <div className="col-2 text-center text-uppercase text-nowrap">
          <small>
            <strong>{qualified}</strong>
            <div>Qualified</div>
          </small>
        </div>
        <div className="col-3 text-center text-uppercase text-nowrap">
          <small>
            <strong>{proposed}</strong>
            <div>Proposed</div>
          </small>
        </div>
        <div className="col-2 text-center text-uppercase text-nowrap">
          {!!lastTouchpoint && (
            <small>
              <strong>{formatDate(lastTouchpoint, 'M/D')}</strong>
              <div>Last Event</div>
            </small>
          )}
        </div>
      </div>
    </Card>
  );
};

ProspectingCard.propTypes = {
  id: PropTypes.number.isRequired,
  prospectingReportId: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  cable: PropTypes.shape.isRequired,
};

export default ProspectingCard;
