import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import HoverTable from 'components/HoverTable';
import { map, isEmpty } from 'ramda';
import Loader from 'components/Loader';
import Card from 'components/Card';
import { useSeasonalDashboardContext } from '../../context/SeasonalDashboardContext';

export const query = gql`
  query SeasonalDashboard($season: String!, $year: Int!) {
    seasonalDashboard {
      sponsor {
        id
        name
        campaigns(season: $season, year: $year) {
          id
          name
          campaignStatistics {
            id
            name
            number
          }
        }
      }
    }
  }
`;

function ResultsTab() {
  const { token, season, year } = useSeasonalDashboardContext();
  const { data, loading } = useQuery(query, {
    variables: { season, year },
    context: {
      headers: {
        Authorization: token,
      },
    },
  });

  if (loading) return <Loader />;

  const { campaigns } = data.seasonalDashboard.sponsor;
  const campaignStatisticsExist = !isEmpty(
    campaigns.filter((campaign) => !isEmpty(campaign.campaignStatistics)),
  );

  return campaignStatisticsExist ? (
    map(
      (campaign) =>
        campaign.campaignStatistics.length > 0 ? (
          <div className="col-md-12 pl-0">
            <Card verticalMargins={0} id="mb-40-important">
              <div
                className="d-inline-flex align-self-center my-1"
                data-test={`result-table-${campaign.id}-title`}
              />
              <strong>{campaign.name}</strong>
              <HoverTable>
                <thead>
                  <tr>
                    <th scope="col" className="col-2">
                      Statistic
                    </th>
                    <th scope="col" className="col-1">
                      Result
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {map(
                    (statistic) => (
                      <tr>
                        <td className="align-middle col-2">{statistic.name}</td>
                        <td className="align-middle col-1">
                          {statistic.number}
                        </td>
                      </tr>
                    ),
                    campaign.campaignStatistics,
                  )}
                </tbody>
              </HoverTable>
            </Card>
          </div>
        ) : null,
      campaigns,
    )
  ) : (
    <h1
      className="d-flex justify-content-center"
      data-test="no-results-message"
    >
      {' '}
      Sorry! Results have yet to be collected!{' '}
    </h1>
  );
}

export default ResultsTab;
