import gql from 'graphql-tag';
import { ORGANIZATION_CAPABILITY_MINIMAL_FRAGMENT } from './OrganizationCapabilityFragment';

const ORGANIZATION_CAPABILITY_PACKAGE_FRAGMENT_V2 = gql`
  fragment OrganizationCapabilityPackageFragmentV2 on OrganizationCapabilityPackage {
    id
    quantity
    organizationCapability {
      ...OrganizationCapabilityMinimalFragment
    }
  }
  ${ORGANIZATION_CAPABILITY_MINIMAL_FRAGMENT}
`;

export default ORGANIZATION_CAPABILITY_PACKAGE_FRAGMENT_V2;
