import { captureException } from '@sentry/browser';
import { setUser } from '@sentry/react';
import { ampli } from 'ampli';
// eslint-disable-next-line import/prefer-default-export
export const track = (error) => {
  if (!window.Cypress) {
    captureException(error);
  }
};

export const setUserContext = (userId, userEmail) => {
  if (!window.Cypress) {
    setUser({ id: userId, email: userEmail });
    ampli.identify(`FH:${userId}`);
  }
};
