import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const saveButton = (disabled, onClick, large) => (
  <button
    className={classNames('btn btn-primary', { 'btn-lg': large })}
    data-test="save-button"
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    <span className="icon icon-cloud mr-2" />
    Save
  </button>
);

const savingButton = (large) => (
  <button
    className={classNames('btn btn-primary', { 'btn-lg': large })}
    data-test="saving-button"
    disabled
    type="button"
  >
    Saving...
  </button>
);

const savedButton = (large) => (
  <button
    className={classNames('btn btn-success', { 'btn-lg': large })}
    data-test="saved-button"
    disabled
    type="button"
  >
    <span className="icon icon-check mr-2" />
    Saved
  </button>
);

const SaveButton = ({ disabled, save, saved, saving, large }) => {
  if (saving) {
    return savingButton(large);
  }

  return saved ? savedButton(large) : saveButton(disabled, save, large);
};

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  save: PropTypes.func,
  saved: PropTypes.bool,
  saving: PropTypes.bool,
  large: PropTypes.bool,
};

SaveButton.defaultProps = {
  disabled: false,
  save: () => {},
  saved: false,
  saving: false,
  large: true,
};

export default SaveButton;
