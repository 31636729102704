import CloudinaryVideo from 'components/CloudinaryVideo';
import WizardStep from '../wizard-step';
import s from '../styles.module.css';

export default function IntroductionStep() {
  return (
    <WizardStep nextLabel="Get Started">
      <WizardStep.Content
        title="Welcome to TeamSnap Sponsorship!"
        subtitle="Please note that expressing interest in the options presented does not imply a commitment, and we recommend selecting all items if possible."
        fullWidth
      >
        <div className={s.videoWrapper}>
          <CloudinaryVideo publicId="sponsorship_application/sponsorships-overview-video" />
        </div>

        <section
          className="sui-flex sui-flex-col t:sui-flex-row sui-items-start"
          style={{ gap: 32 }}
        >
          <div
            className="sui-flex-1 sui-grid"
            style={{ maxWidth: 260, gap: 16 }}
          >
            <p
              className="sui-text-mobile-4 d:sui-text-desktop-5 sui-flex sui-items-center"
              style={{ gap: 8 }}
            >
              <ListStyle /> No additional cost
            </p>
            <p
              className="sui-text-mobile-4 d:sui-text-desktop-5 sui-flex sui-items-center"
              style={{ gap: 8 }}
            >
              <ListStyle /> Quick to sign up, easy to execute
            </p>
            <p
              className="sui-text-mobile-4 d:sui-text-desktop-5 sui-flex sui-items-center"
              style={{ gap: 8 }}
            >
              <ListStyle /> Average sponsorship value of $1,500 per season
            </p>
          </div>
          <div className="sui-flex-1 sui-text-gray-40">
            <p className="sui-text-mobile-4 d:sui-text-desktop-5 sui-mb-3">
              Joining our Sponsorship network means gaining access to valuable
              financial support, with average sponsorships reaching $1,500 per
              season in cash! The application process is quick and easy-become
              eligible in just 5-10 minutes and we&apos;ll deliver incredible
              opportunities straight to your inbox.
            </p>
            <p className="sui-text-mobile-4 d:sui-text-desktop-5">
              Please provide as much of the requested information as possible to
              help us secure high-quality and well-suited matches for your
              organization.
            </p>
          </div>
        </section>
      </WizardStep.Content>
    </WizardStep>
  );
}

const ListStyle = () => (
  <span
    className="sui-block sui-border-2 sui-border-solid sui-border-gray-40 sui-mr-1 sui-relative sui-h-2 sui-border-green-40"
    style={{ borderRadius: '100%', minWidth: 16 }}
  >
    <span
      className="sui-absolute sui-border-b-2 sui-border-solid sui-border-green-40 sui-border-l-2 sui-block"
      style={{
        width: 8,
        height: 5,
        top: 3,
        left: 2,
        transform: 'rotate(-45deg)',
      }}
    />
  </span>
);
