import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import type { Offer } from 'types/offer';
import Icon from 'components/Icon';
import GenericCreativeContentModal from 'components/GenericCreativeContentModal';
import { CREATIVE_CONTENT_FRAGMENT } from '../../fragments';

const MUTATION = gql`
  mutation CreateCreativeContent($input: CreativeContentInput!) {
    createCreativeContent(input: $input) {
      verificationDeadline {
        id
        creativeContents {
          ...CreativeContentFragment
        }
        deadline
        missingCreatives
      }
    }
  }
  ${CREATIVE_CONTENT_FRAGMENT}
`;

export type CreateCreativeContentModalProps = {
  disabled: boolean;
  toggleClassName: string;
  verificationDeadlines: Offer['verificationDeadlines'];
};

function CreateCreativeContentModal(props: CreateCreativeContentModalProps) {
  const { disabled, toggleClassName, verificationDeadlines } = props;

  return (
    <Mutation mutation={MUTATION}>
      {(mutate: any) => (
        <GenericCreativeContentModal
          deadlines={verificationDeadlines}
          deadlineType="verificationDeadline"
          onSubmit={({ content, name, selectedDeadlineId }) =>
            mutate({
              variables: {
                input: {
                  content,
                  name,
                  verificationDeadlineId: Number(selectedDeadlineId),
                },
              },
            })
          }
          toggleProps={{
            className: toggleClassName,
            children: (
              <>
                <Icon value="plus" />
                Add Creative Content
              </>
            ),
            disabled,
          }}
        />
      )}
    </Mutation>
  );
}

export default CreateCreativeContentModal;
