import { map } from 'ramda';
import React from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import styled from 'styled-components';
import Icon from 'components/Icon';

const Rotate180 = styled.span`
  display: block;
  transform: rotate(180deg);
`;

const QuoteCol = styled.div`
  background-color: #fafafa;
  padding: 1rem;
`;

const renderTestimonial = (testimonial) => (
  <div className="col-md-4 my-2 text-center" key={testimonial.id}>
    <QuoteCol>
      <blockquote className="blockquote m-0">
        <div className="row no-gutters">
          <div className="col-auto mr-3">
            <Rotate180>
              <Icon className="h2 text-muted" value="quote" />
            </Rotate180>
          </div>
          <div className="col">
            <p className="mb-0" data-test="quote">
              {testimonial.quote}
            </p>
            {testimonial.attribution && (
              <footer className="blockquote-footer" data-test="attribution">
                {testimonial.attribution}
              </footer>
            )}
          </div>
        </div>
      </blockquote>
    </QuoteCol>
  </div>
);

const renderTestimonials = map(renderTestimonial);

const CampaignDashboardTestimonials = ({ campaign }) => (
  <>
    <h2 className="text-center mb-4">Testimonials</h2>
    <div className="row justify-content-around mb-4">
      {renderTestimonials(campaign.testimonials)}
    </div>
  </>
);

CampaignDashboardTestimonials.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default CampaignDashboardTestimonials;
