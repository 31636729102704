import { __, prop } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import styled from 'styled-components';
import CheckboxButton from 'components/CheckboxButton';
import Paragraph from './Paragraph';

const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
`;

const displayNameByPropName = {
  fallGames: 'Fall',
  springGames: 'Spring',
  summerGames: 'Summer',
  winterGames: 'Winter',
};

const displayName = prop(__, displayNameByPropName);

function Seasons({ addChanges, league }) {
  const renderSeason = (propName) => (
    <CheckboxButton
      checked={league[propName]}
      id={propName}
      onChange={(event) => addChanges({ [propName]: event.target.checked })}
    >
      <h3 className="m-0">{displayName(propName)}</h3>
    </CheckboxButton>
  );

  return (
    <div className="container">
      <Paragraph bold>What seasons do you play?</Paragraph>
      <Paragraph className="mt-3 mb-4">Select all that apply.</Paragraph>
      <Inputs>
        {renderSeason('fallGames')}
        {renderSeason('springGames')}
        {renderSeason('winterGames')}
        {renderSeason('summerGames')}
      </Inputs>
    </div>
  );
}

Seasons.propTypes = {
  addChanges: PropTypes.func.isRequired,
  league: LeagueSidePropTypes.league.isRequired,
};

export default Seasons;
