import React from 'react';
import PropTypes from 'prop-types';
import Sections, { Section } from 'components/Sections';
import OrganizationCapabilitiesTab from 'pages/OrganizationCapabilitiesTab';
import Campaigns from './organizationSections/Campaigns';
import Comments from './organizationSections/Comments';
import PlayLocationsTab from './organizationSections/PlayLocationsTab';
import Overview from './organizationSections/Overview';
import PackagesTab from './organizationSections/PackagesTab';
import ProgramDetails from './organizationSections/ProgramDetails';
import ShippingInfo from './organizationSections/ShippingInfo';
import UsersTab from './organizationSections/UsersTab';
import PreQualsTab from './organizationSections/PreQualsTab';

const OrganizationSections = ({ organizationId }) => (
  <Sections>
    <Section name="Overview" restrictToRoles={['admin', 'organization']}>
      <Overview organizationId={organizationId} />
    </Section>
    <Section
      name="Play Locations"
      path="locations"
      restrictToRoles={['admin', 'organization']}
    >
      <PlayLocationsTab organizationId={organizationId} />
    </Section>
    <Section
      name="Program Details"
      path="program"
      restrictToRoles={['admin', 'organization']}
    >
      <ProgramDetails organizationId={organizationId} />
    </Section>
    <Section
      name="Capabilities"
      path="capabilities"
      restrictToRoles={['admin']}
    >
      <OrganizationCapabilitiesTab organizationId={organizationId} />
    </Section>
    <Section name="Packages" path="packages" restrictToRoles={['admin']}>
      <PackagesTab organizationId={organizationId} />
    </Section>
    <Section
      name="Shipping Info"
      path="shippinginfo"
      restrictToRoles={['admin']}
    >
      <ShippingInfo organizationId={organizationId} />
    </Section>
    <Section name="Campaigns" path="campaigns" restrictToRoles={['admin']}>
      <Campaigns organizationId={organizationId} />
    </Section>
    <Section name="Pre Quals" path="preQuals" restrictToRoles={['admin']}>
      <PreQualsTab organizationId={organizationId} />
    </Section>
    <Section name="Comments" path="comments" restrictToRoles={['admin']}>
      <Comments organizationId={organizationId} />
    </Section>
    <Section
      name="Users"
      path="users"
      restrictToRoles={['admin', 'organization']}
    >
      <UsersTab organizationId={organizationId} />
    </Section>
  </Sections>
);

OrganizationSections.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export default OrganizationSections;
