import React from 'react';
import { divIcon } from 'leaflet';
import { Circle, Marker } from 'react-leaflet';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { METERS_PER_MILE } from 'utils/geo';
import { SUCCESS } from './colors';
import LabeledTooltip from './LabeledTooltip';
import './MapCircle.css';

const MapCircle = (props) => {
  const { radius } = props;
  return (
    <Circle
      center={[radius.lat, radius.lon]}
      color={SUCCESS}
      fillOpacity={1}
      onFocus={(event) => event.target.bringToFront()}
      onMouseOver={(event) => event.target.bringToFront()}
      radius={radius.miles * METERS_PER_MILE}
      stroke={false}
    >
      <Marker
        position={[radius.lat, radius.lon]}
        icon={divIcon({
          html: '<span class="map-circle__dot bg-success" />',
          iconSize: [6, 6],
        })}
      >
        <LabeledTooltip text={radius.label} />
      </Marker>
      <LabeledTooltip text={radius.label} />
    </Circle>
  );
};

MapCircle.propTypes = {
  radius: LeagueSidePropTypes.radius.isRequired,
};

export default MapCircle;
