import React from 'react';
import { contains, evolve, without, concat, map, path } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { sortByName } from 'utils/sort';

const capabilityIds = map(path(['capability', 'id']));

class CapabilityFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { capabilityIds: props.capabilityIds };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderCapability = this.renderCapability.bind(this);
  }

  handleSubmit(event) {
    const { props, state } = this;
    event.preventDefault();

    props.onSave({ capabilityIds: state.capabilityIds });
  }

  renderCapability(capability) {
    const { props, state } = this;

    const id = `capability-${capability.id}`;
    const checked = contains(capability.id, state.capabilityIds);
    const partOfCampaign = contains(
      capability.id,
      capabilityIds(props.campaignCapabilities),
    );

    return (
      <div className="form-check" key={id}>
        <input
          checked={checked}
          className="form-check-input"
          id={id}
          onChange={(event) =>
            this.setState(
              evolve({
                capabilityIds: event.target.checked
                  ? concat([capability.id])
                  : without([capability.id]),
              }),
            )
          }
          type="checkbox"
        />
        <label
          className={classNames('form-check-label', {
            'font-weight-bold': partOfCampaign,
          })}
          htmlFor={id}
        >
          {capability.name}
        </label>
      </div>
    );
  }

  render() {
    const { props } = this;

    return (
      <form onSubmit={this.handleSubmit} className="form-group">
        <p className="text-muted">
          <strong className="font-weight-bold">Bold</strong>
          &nbsp;capabilities have been added as campaign requirements.
        </p>
        {map(this.renderCapability, sortByName(props.capabilities))}
        <div className="text-right">
          <button
            type="button"
            className="btn btn-link"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        </div>
      </form>
    );
  }
}

CapabilityFilterForm.propTypes = {
  campaignCapabilities: LeagueSidePropTypes.campaignCapabilities.isRequired,
  capabilities: LeagueSidePropTypes.capabilities,
  capabilityIds: PropTypes.arrayOf(PropTypes.number),
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

CapabilityFilterForm.defaultProps = {
  capabilities: [],
  capabilityIds: [],
};

export default CapabilityFilterForm;
