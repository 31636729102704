import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { complement, filter, map } from 'ramda';
import gql from 'graphql-tag';
import { label } from 'utils/campaign';
import { useSubmit } from 'utils/hooks';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import SelectInput from 'components/formInputs/SelectInput';
import type { Offer } from 'types/offer';
import type { Campaign } from 'types/campaign';

type MoveOfferToCampaignModalProps = {
  id: string;
  offer: Offer;
  refetchSponsorship: () => void;
  toggleClassName: string;
};

function MoveOfferToCampaignModal(props: MoveOfferToCampaignModalProps) {
  const { id, offer, refetchSponsorship, toggleClassName } = props;
  const [mutate] = useMutation(MUTATION);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const {
    campaign: {
      id: currentCampaignId,
      sponsor: { campaigns: availableSponsoredCampaigns },
    },
  } = offer;
  const isNotCurrentCampaign = complement(
    (campaign) => campaign.id === currentCampaignId,
  );
  const destinationCampaigns = filter(
    isNotCurrentCampaign,
    availableSponsoredCampaigns,
  );
  const title = 'Move offer to different campaign';
  const { error, loading, handleSubmit } = useSubmit(() =>
    onSubmit({ offerId: offer.id, campaignId: Number(selectedCampaignId) }),
  );

  const onSubmit = (input: any) =>
    mutate({
      variables: { input },
    }).then(refetchSponsorship);

  return (
    <ModalForm
      id={id}
      error={error}
      onSubmit={handleSubmit}
      submitProps={{
        children: 'Save Change',
        disabled: !selectedCampaignId || loading,
      }}
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon className="mr-1" value="copy" />
            {title}
          </>
        ),
      }}
      title={title}
    >
      <SelectInput
        disabled={loading}
        inputId="move-offer-to-campaign"
        label="Choose a destination campaign:"
        onChange={(e) =>
          setSelectedCampaignId((e.target as HTMLSelectElement).value)
        }
        value={selectedCampaignId}
      >
        {map(renderOptionFor, destinationCampaigns)}
      </SelectInput>
    </ModalForm>
  );
}

const renderOptionFor = (campaign: Campaign) => (
  <option key={campaign.id} value={campaign.id}>
    {label(campaign)}
  </option>
);

const MUTATION = gql`
  mutation MoveConfirmedOfferToCampaign(
    $input: MoveConfirmedOfferToCampaignInput!
  ) {
    moveConfirmedOfferToCampaign(input: $input) {
      offer {
        id
        campaign {
          id
        }
      }
    }
  }
`;

export default MoveOfferToCampaignModal;
