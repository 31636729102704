import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Card from 'components/Card';
import WithSaveFooter from 'components/WithSaveFooter';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import LeagueSideAPI from 'utils/leagueside-api';
import Dropzone from 'react-dropzone';
import PLACEHOLDER from 'images/sponsor-logo-placeholder.png';
import './SponsorFormCard.css';

export const SponsorFormCardUnwrapped = ({ refetch, save, savedSponsor }) => (
  <Card verticalMargins={0}>
    <div className="row">
      <div className="col">
        <WithSaveFooter handleMutation={save} savedValue={savedSponsor}>
          {({ addChanges, value: sponsor }) => (
            <div className="form-group">
              <label htmlFor="name" className="font-weight-bold">
                Sponsor Name
              </label>
              <input
                className="form-control form-control-lg mb-2"
                id="name"
                onChange={(event) => addChanges({ name: event.target.value })}
                placeholder="Hometown Sponsor"
                type="text"
                value={sponsor.name}
              />
            </div>
          )}
        </WithSaveFooter>
      </div>
      <div className="col-auto">
        <strong className="d-block">Sponsor Logo</strong>
        <Dropzone
          activeClassName="border-primary"
          className="sponsor-logo-dropzone"
          onDrop={async ([file]) => {
            await LeagueSideAPI.createSponsorLogo(savedSponsor.id, file);
            refetch({ id: savedSponsor.id });
          }}
        >
          <img
            src={savedSponsor.logoThumbnailUrl || PLACEHOLDER}
            alt="sponsor logo"
          />
        </Dropzone>
      </div>
    </div>
  </Card>
);

SponsorFormCardUnwrapped.propTypes = {
  refetch: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  savedSponsor: LeagueSidePropTypes.sponsor.isRequired,
};

const MUTATION = gql`
  mutation UpdateSponsor($input: SponsorInput!) {
    updateSponsor(input: $input) {
      id
      name
      logoThumbnailUrl
    }
  }
`;

const SponsorFormCard = ({ refetch, sponsor }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <SponsorFormCardUnwrapped
        refetch={refetch}
        save={(changes) =>
          mutate({ variables: { input: { id: sponsor.id, ...changes } } })
        }
        savedSponsor={sponsor}
      />
    )}
  </Mutation>
);

SponsorFormCard.propTypes = {
  refetch: PropTypes.func.isRequired,
  sponsor: LeagueSidePropTypes.sponsor.isRequired,
};

export default SponsorFormCard;
