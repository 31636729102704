import React from 'react';
import VisibleToSponsorIndicator from 'components/VisibleToSponsorIndicator';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import styled from 'styled-components';
import Icon from 'components/Icon';
import TestimonialDestroyModal from './TestimonialDestroyModal';
import TestimonialUpdateModal from './TestimonialUpdateModal';

const Rotate180 = styled.span`
  display: block;
  transform: rotate(180deg);
`;

const QuoteCol = styled.div`
  background-color: #fafafa;
`;

const TestimonialCard = ({ testimonial }) => (
  <VisibleToSponsorIndicator>
    <QuoteCol key={testimonial.id}>
      <div className="row">
        <div className="col">
          <TestimonialUpdateModal testimonial={testimonial} />
        </div>
        <div className="col-auto">
          <TestimonialDestroyModal testimonialId={testimonial.id} />
        </div>
      </div>
      <div className="p-2 my-2 text-center">
        <blockquote className="blockquote">
          <div className="row no-gutters">
            <div className="col-auto mr-3">
              <Rotate180>
                <Icon className="h2 text-muted" value="quote" />
              </Rotate180>
            </div>
            <div className="col">
              <p className="mb-0" data-test="quote">
                {testimonial.quote}
              </p>
              {testimonial.attribution && (
                <footer className="blockquote-footer" data-test="attribution">
                  {testimonial.attribution}
                </footer>
              )}
            </div>
          </div>
        </blockquote>
      </div>
    </QuoteCol>
  </VisibleToSponsorIndicator>
);

TestimonialCard.propTypes = {
  testimonial: LeagueSidePropTypes.testimonial.isRequired,
};

export default TestimonialCard;
