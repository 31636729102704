import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { renderLastLogin } from 'utils/formatDate';
import { ORGANIZATION_DASHBOARD_PATH } from 'utils/paths';
import NewWindowLink from 'components/NewWindowLink';
import DeleteUserModal from './DeleteUserModal';

const UsersTableAdditionalColumns = ({ organizationId, user }) => {
  const { id: userId, email, lastLogin } = user;

  return (
    <>
      <td>{renderLastLogin(lastLogin)}</td>
      <td className="text-center">
        <NewWindowLink to={`${ORGANIZATION_DASHBOARD_PATH}?userId=${userId}`} />
      </td>
      <td className="text-center">
        <DeleteUserModal
          id={userId}
          email={email}
          organizationId={organizationId}
        />
      </td>
    </>
  );
};

UsersTableAdditionalColumns.propTypes = {
  organizationId: PropTypes.number.isRequired,
  user: LeagueSidePropTypes.user.isRequired,
};

export default UsersTableAdditionalColumns;
