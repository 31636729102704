import { prop, T } from 'ramda';
import { createReducer } from 'redux-create-reducer';
import createByIdReducer from 'utils/createByIdReducer';
import { combineReducers } from 'redux';
import LeaguesideAPI from '../utils/leagueside-api';

const LOAD_SUCCESS = 'sports/loadSuccess';

export const loadSuccess = (payload) => ({ type: LOAD_SUCCESS, payload });

export const load = () => (dispatch) =>
  LeaguesideAPI.getSports().then((sports) => dispatch(loadSuccess(sports)));

function sortSport(s1, s2) {
  return s2.name > s1.name ? -1 : 1;
}

export const allIds = createReducer([], {
  [LOAD_SUCCESS]: (state, action) =>
    action.payload.sort(sortSport).map(prop('id')),
});

export const byId = createByIdReducer(LOAD_SUCCESS);

export const loaded = createReducer(false, { [LOAD_SUCCESS]: T });

export const getLoaded = prop('loaded');

export default combineReducers({
  allIds,
  byId,
  loaded,
});

const getSport = (id, sportsState) => sportsState.byId[id];

export const getSports = (sportsState) =>
  sportsState.allIds.map((id) => getSport(id, sportsState));
