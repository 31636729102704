import React from 'react';
import { map } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import AddOrganizationPackageModal from 'components/AddOrganizationPackageModal';
import SponsorablePropertyPackageCard from 'components/SponsorablePropertyPackageCard';
import MustCreateSponsorablePropertyAlertModal from 'components/MustCreateSponsorablePropertyAlertModal';

const MUTATION = gql`
  mutation RemoveOrganizationPackage($input: RemoveOrganizationPackageInput!) {
    removeOrganizationPackage(input: $input) {
      organization {
        id
        organizationPackages {
          id
        }
      }
    }
  }
`;

export const OrganizationPackages = ({ selectedOrganization }) => {
  const { organizationPackages } = selectedOrganization;
  const hasSponsorableProperties =
    selectedOrganization.sponsorableProperties.length;

  const renderOrganizationPackageCard = (organizationPackage) => (
    <div
      key={organizationPackage.id}
      className="col col-12 col-sm-6 col-md-4 col-lg-4"
    >
      <SponsorablePropertyPackageCard
        sponsorablePropertyPackage={organizationPackage}
        mutation={MUTATION}
      />
    </div>
  );

  return (
    <>
      <strong className="mb-1 mr-3">SPONSORSHIP PACKAGES</strong>
      {hasSponsorableProperties ? (
        <AddOrganizationPackageModal league={selectedOrganization} />
      ) : (
        <MustCreateSponsorablePropertyAlertModal />
      )}
      <p>
        Create, edit, and manage your sponsorship packages here.&nbsp; For
        TeamSnap to start selling on your behalf, please create at least 1
        package.
      </p>
      <div className="row mt-4">
        {map(renderOrganizationPackageCard, organizationPackages)}
      </div>
    </>
  );
};

OrganizationPackages.propTypes = {
  selectedOrganization: LeagueSidePropTypes.league.isRequired,
};

OrganizationPackages.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrganizationPackages;
