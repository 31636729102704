import { SponsorshipFormViewed, SponsorshipFormStepCompleted } from 'ampli';
import type { UseFormReturn } from 'react-hook-form';

export enum StepTypeEnum {
  INTEREST = 'interest',
  SEASON_INFORMATION = 'season-information',
  TOURNAMENT_INFORMATION = 'tournament-information',
  BRANDED_COLLATERAL = 'branded-collateral',
  EXPERIENCES = 'experiences',
  DIGITAL_ASSETS = 'digital-assets',
  WRAP_UP = 'wrap-up',
}

export enum StepIdsEnum {
  INTRODUCTION = 'introduction',
  INTERESTS = 'interests',
  COMMUNITY_IMPACT = 'community-impact',
  PRIMARY_CONTACT = 'primary-contact',
  PROGRAMS = 'programs',
  PLAY_LOCATIONS = 'play-locations',
  SOCIAL_AND_EMAIL = 'social-and-email',
  NON_PROFIT_STATUS = 'non-profit-status',
  CUSTOM_QUESTIONS = 'custom-questions',
  TOURNAMENT_INFORMATION = 'tournament-information',
  GAME_JERSEYS = 'game-jerseys',
  WARM_UPS = 'warm-ups',
  GAME_SIGNAGE = 'game-signage',
  DEDICATED_EMAIL = 'dedicated-email',
  WEBSITE_BANNER = 'website-banner',
  SEMINAR = 'seminar',
  SURVEYS = 'surveys',
  COUPONS = 'coupons',
  SAMPLING = 'sampling',
  PHOTOS = 'photos',
  REWARDED_ADD_ONS = 'rewarded-add-ons',
  THANK_YOU = 'thank-you',
}

export type WizardMapType = Record<
  StepTypeEnum,
  {
    title: string;
    steps: {
      id: StepIdsEnum;
      name: string;
      component: JSX.Element;
    }[];
  }
>;

export type Inputs = {
  organizationId: number;
  sponsorshipApplicationId: number;
  completionPercentage: number;
  season: string;
  year: number;
  interests: string[];
  notInterested: boolean;
  primaryContactFirstName: string;
  primaryContactLastName: string;
  primaryContactPhone: string;
  primaryContactEmail: string;
  emailOpenRate: number;
  socialMediaFollowingSize: number;
  emailListSize: number;
  organizationTypeId: number;
  sponsorableProperties: {
    id: number;
    name: string;
    competitivenessId: number | null;
    sportId: number | null;
    numberOfPlayers: number | null;
    gender: 'coed' | 'girls' | 'boys' | null;
    minAge: number | null;
    maxAge: number | null;
    startDate: string;
    endDate: string;
    season: string;
  }[];
  playLocations: {
    id: number | null;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    primaryProgramIds: (string | number)[];
    otherProgramIds: (string | number)[];
  }[];
  customResponses: {
    id: number | null;
    customQuestionId: number;
    response: string;
  }[];
  additionalInformation: string;
  interestedInHundredX: boolean;

  // Tournament
  hostTournament: boolean;
  name: string;
  firstDate: string;
  lastDate: string;
  numberOfPlayers: number;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  freeParking: boolean;
  canShipInAdvance: boolean;
  shipmentsArrival: string;
  requireCoi: boolean;
  hasContingencyPlan: boolean;

  // Assets
  accepted: boolean;
  maxForSale: number;
  price: number;
  sponsorablePropertyIds: (string | number)[];
  refusalReason: string;
  canUseLogo: boolean;
  otherBrands: boolean;
  orderingDeadline: string;
  canHang24Hours: boolean;
  bannerSizes: (string | number)[];
  fourBySixBanner: boolean;
  popUpBanner: boolean;
  bannerPlacements: (string | number)[];
  description: string;
  eventDate: string;
  possibleAttendance: number;
  quantity: number;
  fundraiserInPast: boolean;
  averageRaised: number;
  attendance: number;
  canDistributeFood: boolean;
  canAllowOutsideFood: boolean;
  hasElectricity: boolean;
};

export type PlayLocationsFieldNames =
  `playLocations.${number}.${keyof Inputs['playLocations'][number]}`;
export type ProgramsFieldNames =
  `sponsorableProperties.${number}.${keyof Inputs['sponsorableProperties'][number]}`;
export type CustomResponsesFieldNames =
  `customResponses.${number}.${keyof Inputs['customResponses'][number]}`;

export type WizardContextParams = UseFormReturn<Inputs> & {
  activeSectionIndex: number;
  activeStepIndex: number;
  handleNextStep: (skipToEnd?: boolean) => void;
  handlePreviousStep: () => void;
  organizationData: Organization;
  getActiveStepComponent: () => JSX.Element | null;
  getActiveStepStruct: () => any;
  setValues: (values: Partial<Inputs>) => void;
  token: string;
  tokenData: TokenData;
  values: Partial<Inputs>;
  wizardMap: WizardMapType | null;
  handleOnSaveAndExit: (sectionIndex: number, stepIndex: number) => void;
  getSponsorshipFormViewedPayload: () => SponsorshipFormViewed;
  getSponsorshipStepCompletedPayload: () => SponsorshipFormStepCompleted;
  applicationProgressData: {
    completionPercentage: Organization['sponsorshipApplication']['completionPercentage'];
    applicationProgress: Organization['sponsorshipApplication']['applicationProgress'];
  };
  refetchApplicationProgressQuery: () => void;
};

export type TokenData = {
  id: number;
  season: string;
  year: number;
  campaign_id: number;
  campaign_name: string;
  offer_id: number;
};

export type Organization = {
  id: number;
  name: string;
  organizationTypeId: number;
  socialMediaFollowingSize: number;
  emailListSize: number;
  emailOpenRate: number;
  currentSponsorableProperties: SponsorableProperty[];
  sponsorshipApplication: {
    id: number;
    season: string;
    year: number;
    chosenInterests: string[];
    notInterested: boolean;
    primaryContactFirstName: string;
    primaryContactLastName: string;
    primaryContactEmail: string;
    primaryContactPhone: string;
    customQuestions: {
      id: number;
      question: string;
    }[];
    customResponses: {
      id: number;
      customQuestionId: number;
      response: string;
    }[];
    additionalInformation: string;
    interestedInHundredx: boolean;
    tournament: {
      id: number;
      hostTournament: boolean;
      name: string;
      firstDate: string;
      lastDate: string;
      numberOfPlayers: number;
      address: string;
      city: string;
      state: string;
      postalCode: string;
      country: string;
      freeParking: boolean;
      canShipInAdvance: boolean;
      shipmentsArrival: string;
      requireCoi: boolean;
      hasContingencyPlan: boolean;
    };
    teamGameJerseyAccepted: boolean;
    teamGameJerseyRefusalReason: string;
    teamGameJerseyDetail: AssetDetail;
    gameSignageAccepted: boolean;
    gameSignageRefusalReason: string;
    gameSignageDetail: AssetDetail;
    surveysAccepted: boolean;
    surveysRefusalReason: string;
    surveysDetail: AssetDetail;
    teamWarmupAccepted: boolean;
    teamWarmupRefusalReason: string;
    teamWarmupDetail: AssetDetail;
    websiteBannerAccepted: boolean;
    websiteBannerRefusalReason: string;
    websiteBannerDetail: AssetDetail;
    emailOrSocialMediaAccepted: boolean;
    emailOrSocialMediaRefusalReason: string;
    emailOrSocialMediaDetail: AssetDetail;
    seminarAccepted: boolean;
    seminarRefusalReason: string;
    seminarDetail: AssetDetail;
    couponAccepted: boolean;
    couponRefusalReason: string;
    couponDetail: AssetDetail;
    fundraiserAccepted: boolean;
    fundraiserRefusalReason: string;
    fundraiserDetail: AssetDetail;
    samplingAccepted: boolean;
    samplingRefusalReason: string;
    samplingDetail: AssetDetail;
    photosAccepted: boolean;
    photosRefusalReason: string;
    photosDetail: AssetDetail;
    completionPercentage: number;
    applicationProgress: Partial<{ [key in StepIdsEnum]: boolean }>;
    savedFormData: Partial<Inputs>;
    savedStepId: StepIdsEnum;
  };
  playLocations: {
    streetAddress: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    id: number;
    address: string;
    primary: boolean;
    primaryPrograms: Pick<SponsorableProperty, 'id' | 'name'>[];
    otherPrograms: Pick<SponsorableProperty, 'id' | 'name'>[];
  }[];
};

export type SponsorshipApplicationOptions = {
  interestsList: { id: StepIdsEnum; text: string }[];
  bannerSizesList: string[];
  bannerPlacementsList: string[];
};

type SponsorableProperty = {
  id: number;
  name: string;
  competitiveness: { id: number };
  sport: { id: number };
  numberOfPlayers: number;
  minAge: number;
  maxAge: number;
  startDate: string;
  endDate: string;
  male: boolean;
  female: boolean;
  season: string;
};

type AssetDetail = {
  id: number;
  price: number;
  maxForSale: number;
  associatedSponsorableProperties: Pick<SponsorableProperty, 'id' | 'name'>[];
  capabilityDetailable: {
    id: number;
    minTeamsRequired: number;
    otherBrands: boolean;
    logoPlacements: string[];
    orderingDeadline: string;
    canHang24Hours: boolean;
    bannerSizes: string[];
    bannerPlacements: string[];
    description: string;
    eventDate: string;
    possibleAttendance: number;
    quantity: number;
    fundraiserInPast: boolean;
    averageRaised: number;
    attendance: number;
    canDistributeFood: boolean;
    canAllowOutsideFood: boolean;
    hasElectricity: boolean;
  };
};
