import * as React from 'react';
import { map } from 'ramda';
import type { CreativeContent, CreativeFile } from 'types/offer';
import GenericCreativeContentCard from 'components/GenericCreativeContentCard';
import GenericCreativeFileCard from 'components/GenericCreativeFileCard';
import PreviewGenericCreativeContentModal from 'components/PreviewGenericCreativeContentModal';
import RemoveCreativeContentModal from './remove-creative-content-modal';
import RemoveCreativeFileModal from './remove-creative-file-modal';
import UpdateCreativeContentModal from './update-creative-content-modal';

interface Props {
  colClassName: string;
  creativeContents: CreativeContent[];
  creativeFiles: CreativeFile[];
  tableColCount: number;
  verificationDeadlineId: number;
}

const ExpandedCreativesRow: React.FunctionComponent<Props> = ({
  colClassName,
  creativeContents,
  creativeFiles,
  tableColCount,
  verificationDeadlineId,
}: Props) => (
  <tr data-test={`expanded-creatives-row-${verificationDeadlineId}`}>
    <td colSpan={tableColCount} className="border-0">
      <div className="row">
        {map(
          (creativeFile) => (
            <div
              className={colClassName}
              key={`creative-file-${creativeFile.id}`}
            >
              <GenericCreativeFileCard genericCreativeFile={creativeFile}>
                <RemoveCreativeFileModal creativeFile={creativeFile} />
              </GenericCreativeFileCard>
            </div>
          ),
          creativeFiles,
        )}
        {map(
          (creativeContent) => (
            <div
              className={colClassName}
              key={`creative-content-${creativeContent.id}`}
            >
              <GenericCreativeContentCard
                genericCreativeContent={creativeContent}
              >
                <UpdateCreativeContentModal creativeContent={creativeContent} />
                <PreviewGenericCreativeContentModal
                  genericCreativeContent={creativeContent}
                  modalTargetLabel="Preview"
                />
                <RemoveCreativeContentModal creativeContent={creativeContent} />
              </GenericCreativeContentCard>
            </div>
          ),
          creativeContents,
        )}
      </div>
    </td>
  </tr>
);

export default ExpandedCreativesRow;
