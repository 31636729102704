import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const dataConfidenceProps = {
  verified: {
    className: 'text-primary',
    title:
      'Verified: This data has been reviewed for completeness and verified by a team member.',
  },
  created_from_other_sp: {
    className: 'text-success',
    title: 'Created From Other SP: Generated from ended SP',
  },
  complete: {
    className: 'text-primary',
    title: 'Complete: We have all the information we need for this SP.',
  },
  stale: {
    className: 'text-muted',
    title:
      'Stale: Formerly verified or complete, but data has not been updated in 6+ months.',
  },
  ended: { className: 'text-muted', title: 'Ended: end date passed' },
  incomplete: {
    className: 'text-warning',
    title:
      'Incomplete: This data is in the process of being developed and completed.',
  },
  speculative: {
    className: 'text-light',
    title: 'Speculative: This data could be incomplete or inaccurate.',
  },
  added_from_prospecting: {
    className: 'text-light',
    title: 'Added from Prospecting: Added by LOpser.',
  },
  added_by_organization: {
    className: 'text-warning',
    title: 'Added by Organization: Added by OrgUser.',
  },
  not_a_good_fit: {
    className: 'text-danger',
    title:
      'Not A Good Fit: This league is not a good fit for the TeamSnap network.',
  },
};

function DataConfidenceIndicator({ dataConfidence }) {
  const { className, title } = dataConfidenceProps[dataConfidence];

  return (
    <Icon
      className={`${className} mr-1`}
      data-test={dataConfidence}
      title={title}
      value="controller-record"
    />
  );
}

DataConfidenceIndicator.propTypes = {
  dataConfidence: PropTypes.string.isRequired,
};

export default DataConfidenceIndicator;
