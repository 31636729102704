import * as React from 'react';

interface InputProps {
  className: string;
  'data-test'?: string;
  children?: React.ReactNode;
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  menuProps: InputProps;
  toggleProps: InputProps;
}

const Dropddown: React.FunctionComponent<Props> = ({
  children,
  menuProps,
  toggleProps,
}: Props) => (
  <div className="dropdown d-inline-block">
    <button
      aria-label="actions-dropdown"
      data-toggle="dropdown"
      id="actions-dropdown-menu-button"
      type="button"
      {...toggleProps}
    />
    <div aria-labelledby="actions-dropdown-menu-button" {...menuProps}>
      {children}
    </div>
  </div>
);

export default Dropddown;
