import * as React from 'react';
import { map } from 'ramda';
import formatDate from 'utils/formatDate';
import { sortWithDeadlineAndName } from 'utils/ts/sort';
import GenericVerificationDeadline from 'types/genericVerificationDeadline';
import SelectInput from './SelectInput';

interface GenericDeadline extends GenericVerificationDeadline {
  didNotComplete?: boolean;
}

interface Props extends React.HTMLAttributes<HTMLElement> {
  deadlineId?: string;
  deadlines: GenericDeadline[];
  isRequired?: boolean;
  loading?: boolean;
  prefix?: string;
}

const DeadlineSelectInput: React.FunctionComponent<Props> = ({
  deadlineId,
  deadlines,
  isRequired,
  loading,
  onChange,
  prefix,
}: Props) => (
  <SelectInput
    disabled={loading}
    label="Deadline"
    labelClassName="font-weight-bold"
    onChange={onChange}
    prefix={prefix}
    required={isRequired}
    value={deadlineId || ''}
  >
    {map(
      ({ id, deadline, didNotComplete, name }) => (
        <option key={id} value={id}>
          {`${formatDate(deadline)} - ${name}${
            didNotComplete ? ' (Did Not Complete)' : ''
          }`}
        </option>
      ),
      sortWithDeadlineAndName(deadlines),
    )}
  </SelectInput>
);

DeadlineSelectInput.defaultProps = {
  deadlineId: '',
  isRequired: undefined,
  loading: false,
  prefix: undefined,
};

export default DeadlineSelectInput;
