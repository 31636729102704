import React from 'react';
import PropTypes from 'prop-types';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

const StagingBanner = ({ production }) =>
  production !== 'true' ? (
    <div className="bg-warning text-white text-center m-0 pt-1 pb-1">
      <h3>NON-PRODUCTION ENVIRONMENT</h3>
    </div>
  ) : null;

StagingBanner.propTypes = {
  production: PropTypes.string,
};

StagingBanner.defaultProps = {
  production: env.REACT_APP_PRODUCTION,
};

export default StagingBanner;
