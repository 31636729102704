/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-webpack-loader-syntax */

import React from 'react';
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';
import { map, propEq } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { getMapBounds, getPoints } from 'utils/geo';
import 'leaflet/dist/leaflet.css';
import {
  PruneCluster,
  PruneClusterForLeaflet,
} from 'exports-loader?PruneCluster,PruneClusterForLeaflet!prunecluster/dist/PruneCluster';
import MapCircle from './MapCircle';
import MapGeoJSON from './MapGeoJSON';
import {
  buildClusterIcon,
  buildSponsorablePropertyIcon,
  buildSponsorablePropertyTooltip,
} from './map/Map.utilities';
import RecenterButton from './map/RecenterButton';
import './map/Map.css';

const MIN_ZOOM = 2;
const MAX_ZOOM = 13;
const ZOOM_RANGE = MAX_ZOOM - MIN_ZOOM;
const MAX_CLUSTER_SIZE = 245;

const renderRadii = map((radius) => (
  <MapCircle radius={radius} key={radius.label} />
));
const renderGeometries = map((geometry) => (
  <MapGeoJSON geometry={geometry} key={geometry.id} />
));

function computeClusterSize(zoom) {
  if (!zoom) return MAX_CLUSTER_SIZE;

  const zoomRatio = (MAX_ZOOM - zoom) / ZOOM_RANGE;

  return MAX_CLUSTER_SIZE * zoomRatio + 1;
}

function prepareLeafletMarker(leafletMarker, { name, modifiers, onClick }) {
  leafletMarker.setIcon(buildSponsorablePropertyIcon({ modifiers }));
  leafletMarker.on('click', onClick);
  leafletMarker.bindTooltip(buildSponsorablePropertyTooltip({ name }));
}

function iconModifiers(
  addedSponsorablePropertyIds,
  removedSponsorablePropertyIds,
  sponsorablePropertyId,
) {
  if (addedSponsorablePropertyIds.has(sponsorablePropertyId)) return ['added'];
  if (removedSponsorablePropertyIds.has(sponsorablePropertyId))
    return ['removed'];
  return [];
}

function createPruneClusterLayer(
  addedSponsorablePropertyIds,
  removedSponsorablePropertyIds,
  sponsorableProperties,
  zoom,
  onSponsorablePropertyMarkerClick,
) {
  const pruneClusterLayer = new PruneClusterForLeaflet();

  pruneClusterLayer.BuildLeafletClusterIcon = buildClusterIcon;
  pruneClusterLayer.PrepareLeafletMarker = prepareLeafletMarker;
  pruneClusterLayer.Cluster.Size = computeClusterSize(zoom);

  sponsorableProperties.forEach((sponsorableProperty, index) => {
    const marker = new PruneCluster.Marker(
      sponsorableProperty.lat,
      sponsorableProperty.lon,
    );
    marker.data.id = sponsorableProperty.id;
    marker.data.name = sponsorableProperty.name;
    marker.data.modifiers = iconModifiers(
      addedSponsorablePropertyIds,
      removedSponsorablePropertyIds,
      sponsorableProperty.id,
    );
    marker.data.onClick = () =>
      onSponsorablePropertyMarkerClick({ id: sponsorableProperty.id, index });

    pruneClusterLayer.RegisterMarker(marker);
  });

  return pruneClusterLayer;
}

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = { centered: true };

    this.recenter = this.recenter.bind(this);
    this.handleMouseDown = () => this.setState({ centered: false });
    this.handleViewportChanged = ({ zoom }) => this.setState({ zoom });
  }

  componentDidMount() {
    const {
      addedSponsorablePropertyIds,
      removedSponsorablePropertyIds,
      sponsorableProperties,
      onSponsorablePropertyMarkerClick: onClick,
    } = this.props;
    const { zoom } = this.state;

    this.pruneClusterLayer = createPruneClusterLayer(
      addedSponsorablePropertyIds,
      removedSponsorablePropertyIds,
      sponsorableProperties,
      zoom,
      onClick,
    );
    this.map.leafletElement.addLayer(this.pruneClusterLayer);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      addedSponsorablePropertyIds,
      removedSponsorablePropertyIds,
      sponsorableProperties,
      onSponsorablePropertyMarkerClick: onClick,
    } = this.props;
    const { centered, zoom } = this.state;

    if (centered && prevProps.sponsorableProperties !== sponsorableProperties) {
      this.recenter();
    }

    const sponsorablePropertiesOutOfDate =
      prevProps.sponsorableProperties !== sponsorableProperties ||
      prevState.zoom !== zoom ||
      prevProps.addedSponsorablePropertyIds !== addedSponsorablePropertyIds;

    if (sponsorablePropertiesOutOfDate) {
      this.map.leafletElement.removeLayer(this.pruneClusterLayer);
      this.pruneClusterLayer = createPruneClusterLayer(
        addedSponsorablePropertyIds,
        removedSponsorablePropertyIds,
        sponsorableProperties,
        zoom,
        onClick,
      );
      this.map.leafletElement.addLayer(this.pruneClusterLayer);
    }
  }

  get bounds() {
    const { sponsorableProperties, radii, campaignGeometries } = this.props;
    const sponsorablePropertyPoints = getPoints(sponsorableProperties);
    return getMapBounds(sponsorablePropertyPoints, radii, campaignGeometries);
  }

  get sponsorablePropertyHoverMarker() {
    const {
      addedSponsorablePropertyIds,
      removedSponsorablePropertyIds,
      sponsorableProperties,
      hoveredSponsorablePropertyId,
    } = this.props;

    if (!hoveredSponsorablePropertyId) return null;
    const hoveredSponsorableProperty = sponsorableProperties.find(
      propEq('id', hoveredSponsorablePropertyId),
    );

    return (
      <Marker
        position={[
          hoveredSponsorableProperty.lat,
          hoveredSponsorableProperty.lon,
        ]}
        icon={buildSponsorablePropertyIcon({
          modifiers: iconModifiers(
            addedSponsorablePropertyIds,
            removedSponsorablePropertyIds,
            hoveredSponsorableProperty.id,
          ).concat(['hover']),
        })}
        zIndexOffset={100}
      />
    );
  }

  get recenterButton() {
    const { centered } = this.state;
    return centered ? null : <RecenterButton onClick={this.recenter} />;
  }

  recenter() {
    this.setState({ centered: true });
    this.map.leafletElement.fitBounds(this.bounds);
  }

  render() {
    const { radii, campaignGeometries } = this.props;

    return (
      <div className="map">
        <LeafletMap
          ref={(node) => {
            this.map = node;
          }}
          bounds={this.bounds}
          minZoom={MIN_ZOOM}
          maxZoom={MAX_ZOOM}
          onMouseDown={this.handleMouseDown}
          onViewportChanged={this.handleViewportChanged}
        >
          <TileLayer
            attribution='&copy; <a href="http:// www.openstreetmap.org/copyright\">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
            url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
          />
          {renderRadii(radii)}
          {renderGeometries(campaignGeometries)}
          {this.sponsorablePropertyHoverMarker}
        </LeafletMap>
        {this.recenterButton}
      </div>
    );
  }
}

Map.propTypes = {
  addedSponsorablePropertyIds: PropTypes.instanceOf(Set),
  removedSponsorablePropertyIds: PropTypes.instanceOf(Set),
  hoveredSponsorablePropertyId: PropTypes.number,
  sponsorableProperties: PropTypes.arrayOf(PropTypes.shape).isRequired,
  onSponsorablePropertyMarkerClick: PropTypes.func,
  campaignGeometries: LeagueSidePropTypes.campaignGeometries,
  radii: PropTypes.arrayOf(LeagueSidePropTypes.radius),
};

Map.defaultProps = {
  addedSponsorablePropertyIds: new Set(),
  removedSponsorablePropertyIds: new Set(),
  hoveredSponsorablePropertyId: null,
  campaignGeometries: [],
  radii: [],
  onSponsorablePropertyMarkerClick: () => {},
};

export default Map;
