import { useEffect, useRef } from 'react';
import { CLOUDINARY_CLOUD_NAME } from 'utils/envs';

type CloudinaryVideoProps = {
  publicId: string;
};

export default function CloudinaryVideo(props: CloudinaryVideoProps) {
  const { publicId } = props;
  const cloudinaryRef = useRef<any>();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (cloudinaryRef.current) return;
    const windowWithCloudinary = window as any;
    cloudinaryRef.current = windowWithCloudinary.cloudinary;
    cloudinaryRef.current?.videoPlayer(videoRef.current, {
      cloud_name: CLOUDINARY_CLOUD_NAME,
      transformation: { quality: 'auto', fetch_format: 'auto' },
    });
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video
      ref={videoRef}
      data-cld-public-id={publicId}
      controls
      className="sui-block sui-w-full sui-h-full"
    />
  );
}
