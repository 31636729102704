import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { anyPass } from 'ramda';
import type { Offer } from 'types/offer';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import { replaceUnderscoresWithSpaces } from 'utils/string';
import { isCanceled, isConfirmed } from 'utils/offer';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Badge from 'components/Badge';
import ActiveOffersBadges from 'components/OldActiveOffersBadges';
import ApplicationCompletionStatus from 'components/ApplicationCompletionStatus';
import ActionsDropdown from './_sections/actions-dropdown';
import VerificationsCard from './_sections/verification-cards/verification-cards';
import CardsColumns from './_sections/cards-columns';
import AuditLogCard from './_sections/audit-log-card';
import { SPONSORSHIP_QUERY } from './queries';

const isConfirmedOrCanceled = anyPass([isConfirmed, isCanceled]);

export type OfferDetailsPageProps = {
  match: {
    params: { id: string };
  };
};

export default function OfferDetailsPage(props: OfferDetailsPageProps) {
  const { match } = props;
  const { data, refetch: refetchSponsorship } = useQuery<{ offer: Offer }>(
    SPONSORSHIP_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: { id: Number(match.params.id) },
    },
  );

  const offer = data && data.offer;
  const { campaign, sponsorableProperty } = offer ?? {};
  const { sponsor, season, year } = campaign ?? {};
  const { organization } = sponsorableProperty ?? {};

  const campaignName = sponsor ? `${sponsor.name} - ${campaign?.name}` : '';

  return (
    <RestrictTo roles={['admin']}>
      <Page loaded={!!offer} fullScreen>
        {offer && (
          <FieldhousePageTitle
            title={`${sponsorableProperty?.name} - ${campaignName}`}
          >
            <section className="sui-border-b sui-border-gray-70 sui-border-solid sui-py-6 sui-mb-2">
              <div className="container">
                <Link
                  to={`${CAMPAIGN_PLANNER_PATH}/${sponsor?.id}/campaigns/${campaign?.id}/included-leagues`}
                >
                  {campaignName}
                </Link>

                <div className="row align-items-center sui-mt-3">
                  <div className="col-auto sui-pr-2">
                    <p
                      className="sui-text-desktop-10 sui-font-bold"
                      data-test="league-name"
                      style={{ maxWidth: 600 }}
                    >
                      {sponsorableProperty?.name}
                    </p>
                    <ApplicationCompletionStatus
                      organizationId={organization?.id ?? 0}
                      season={season ?? ''}
                      year={year ?? 0}
                      campaignId={campaign?.id ?? 0}
                    />
                  </div>
                  <div
                    className="sui-flex sui-flex-col sui-items-center"
                    style={{ gap: '0.5rem' }}
                  >
                    <Badge className="text-capitalize" data-test="status">
                      {replaceUnderscoresWithSpaces(offer.status)}
                    </Badge>

                    {sponsorableProperty && (
                      <ActiveOffersBadges
                        sponsorableProperty={sponsorableProperty}
                        offerIdToExclude={offer.id}
                      />
                    )}
                  </div>
                  <div className="col text-right">
                    <ActionsDropdown
                      offer={offer}
                      refetchSponsorship={refetchSponsorship}
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="container sui-pb-6">
              {!!offer.removalReason && (
                <div className="alert alert-warning">
                  <p>Sponsorable Property was removed for this reason:</p>
                  <p>{offer.removalReason}</p>
                </div>
              )}
              {isConfirmedOrCanceled(offer) && (
                <VerificationsCard offer={offer} />
              )}
              <CardsColumns
                offer={offer}
                refetchSponsorship={refetchSponsorship}
              />
              <AuditLogCard offer={offer} />
            </section>
          </FieldhousePageTitle>
        )}
      </Page>
    </RestrictTo>
  );
}
