import * as React from 'react';

interface Props extends React.HTMLAttributes<HTMLElement> {
  /** Disables component while loading */
  disabled?: boolean;
  /** Adds required for HTML form validation */
  required?: boolean;
}

const CsvInput: React.FC<Props> = ({ disabled, onChange, required }: Props) => (
  <input
    accept="text/csv"
    aria-required={required ? 'true' : 'false'}
    data-test="csv-upload-input"
    disabled={disabled}
    onChange={onChange}
    type="file"
    required={required}
    className="form-control-file"
  />
);

CsvInput.defaultProps = {
  disabled: false,
  required: false,
};

export default CsvInput;
