import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import CAMPAIGN_STATISTIC_FRAGMENT from 'fragments/CampaignStatisticFragment';
import Icon from 'components/Icon';
import CampaignStatisticModal from './CampaignStatisticModal';

const CampaignStatisticCreateModalUnwrapped = ({ onSubmit }) => (
  <CampaignStatisticModal
    onSubmit={onSubmit}
    label="create"
    shouldResetOnSubmit
    toggleProps={{
      className: 'btn btn-secondary mr-3',
      children: (
        <>
          <Icon className="mr-2" value="plus" />
          Add A Statistic
        </>
      ),
    }}
  />
);

CampaignStatisticCreateModalUnwrapped.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation CreateCampaignStatistic($input: CampaignStatisticInput!) {
    createCampaignStatistic(input: $input) {
      id
      campaignStatistics {
        ...CampaignStatisticFragment
      }
    }
  }
  ${CAMPAIGN_STATISTIC_FRAGMENT}
`;

const CampaignStatisticCreateModal = ({ campaignId }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CampaignStatisticCreateModalUnwrapped
        onSubmit={({ description, iconName, name, number }) =>
          mutate({
            variables: {
              input: {
                campaignId,
                description,
                iconName,
                name,
                number,
              },
            },
          })
        }
      />
    )}
  </Mutation>
);

CampaignStatisticCreateModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
};

export { CampaignStatisticCreateModalUnwrapped };
export default CampaignStatisticCreateModal;
