import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import MultiSelect from 'components/MultiSelect';
import { RequiredAsterisk } from 'components/RequiredLabel';
import { sortByName } from 'utils/sort';

const SponsorablePropertyMultiSelect = ({
  setSponsorablePropertyIds,
  sponsorableProperties,
  sponsorablePropertyIds,
  sponsorablePropertyIdsEmpty,
  loading,
}) => (
  <div className="form-group">
    <label htmlFor="sponsorable-properties-select">
      What season, program, or event is this package for?
      <RequiredAsterisk />
    </label>
    <MultiSelect
      id="sponsorable-properties-select"
      onChange={setSponsorablePropertyIds}
      models={sortByName(sponsorableProperties)}
      selectedIds={sponsorablePropertyIds}
      disabled={loading}
    />
    {sponsorablePropertyIdsEmpty && (
      <span className="text-danger" data-test="sponsorable-property-error-text">
        Your package must be associated with at least one season, program, or
        event
      </span>
    )}
  </div>
);

SponsorablePropertyMultiSelect.propTypes = {
  setSponsorablePropertyIds: PropTypes.func.isRequired,
  sponsorableProperties: LeagueSidePropTypes.sponsorableProperties.isRequired,
  sponsorablePropertyIds: PropTypes.arrayOf(PropTypes.Int).isRequired,
  sponsorablePropertyIdsEmpty: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SponsorablePropertyMultiSelect;
