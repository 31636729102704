import React from 'react';
import { ascend, map, prop, sortWith } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import CampaignsTableRow from './CampaignsTableRow';

function statusOrder(campaign) {
  const order = {
    active: 0,
    proposed: 1,
    complete: 2,
    rejected: 3,
  };
  return order[campaign.status];
}

const sortWithStatusAndName = sortWith([
  ascend(statusOrder),
  ascend(prop('name')),
]);

const CampaignsTable = ({ sponsorId, campaigns }) => {
  const renderCampaignRow = (campaign) => (
    <CampaignsTableRow
      key={campaign.id}
      sponsorId={sponsorId}
      campaign={campaign}
    />
  );

  return (
    <table className="mt-2 mb-0 table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Season</th>
          <th>Status</th>
          <th>Budget</th>
          <th aria-label="duplicate-campaign" />
        </tr>
      </thead>
      <tbody>{map(renderCampaignRow, sortWithStatusAndName(campaigns))}</tbody>
    </table>
  );
};

CampaignsTable.propTypes = {
  sponsorId: PropTypes.number.isRequired,
  campaigns: PropTypes.arrayOf(LeagueSidePropTypes.campaign).isRequired,
};

export default CampaignsTable;
