// based on https://github.com/socialtables/react-image-fallback

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ImageFallback = ({ alt, imgClassName, fallback, src }) => {
  const [imageSource, setImageSource] = useState(null);

  useEffect(() => {
    const displayImage = new window.Image();

    function setDisplayImage({ image }) {
      displayImage.onerror = () => {
        setImageSource(null);
      };
      displayImage.onload = () => {
        setImageSource(image);
      };
      if (typeof image === 'string') {
        displayImage.src = image;
      } else {
        setImageSource(image);
      }
    }

    setDisplayImage({ image: src });

    return function cleanup() {
      if (displayImage) {
        displayImage.onerror = null;
        displayImage.onload = null;
      }
    };
  });

  return imageSource ? (
    <img
      alt={alt}
      className={imgClassName}
      data-test="image-fallback"
      src={imageSource}
    />
  ) : (
    fallback
  );
};

ImageFallback.propTypes = {
  alt: PropTypes.string.isRequired,
  imgClassName: PropTypes.string,
  fallback: PropTypes.element.isRequired,
  src: PropTypes.string,
};

ImageFallback.defaultProps = {
  imgClassName: '',
  src: '',
};

export default ImageFallback;
