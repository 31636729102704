import * as React from 'react';
import { map } from 'ramda';
import { VerificationImageWithAllFields } from 'types/verificationImage';
import { isNotEmpty } from 'utils/misc';
import { sortByReversedCreatedAt } from 'utils/ts/sort';
import type { VerificationDeadline } from 'types/offer';
import UnaddressedVerificationDeadlineResponse from './unaddressed-verification-deadline-response';
import VerificationImageCardWithReviewFooter from '../verification-image-card-with-review-footer';

interface VerificationDeadlineResponse {
  id: number;
  cannedResponse: string;
  comment: string;
  createdAt: Date;
}

interface Props {
  colClassName: string;
  tableColCount: number;
  verificationDeadlineId: number;
  verificationDeadlines: VerificationDeadline[];
  verificationDeadlineResponses: VerificationDeadlineResponse[];
  verificationImages: VerificationImageWithAllFields[];
}

const ExpandedVerificationsRow: React.FunctionComponent<Props> = ({
  colClassName,
  tableColCount,
  verificationDeadlineId,
  verificationDeadlines,
  verificationDeadlineResponses,
  verificationImages,
}: Props) => (
  <tr data-test={`expanded-verifications-row-${verificationDeadlineId}`}>
    <td colSpan={tableColCount} className="border-0">
      {isNotEmpty(verificationImages) && (
        <div className="row">
          {map(
            (verificationImage) => (
              <div className={colClassName} key={verificationImage.id}>
                <VerificationImageCardWithReviewFooter
                  initialVerificationDeadlineId={verificationDeadlineId}
                  verificationDeadlines={verificationDeadlines}
                  verificationImage={verificationImage}
                />
              </div>
            ),
            sortByReversedCreatedAt(verificationImages),
          )}
        </div>
      )}
      {map(
        (verificationDeadlineResponse) => (
          <UnaddressedVerificationDeadlineResponse
            key={verificationDeadlineResponse.id}
            verificationDeadlineResponse={verificationDeadlineResponse}
          />
        ),
        sortByReversedCreatedAt(verificationDeadlineResponses),
      )}
    </td>
  </tr>
);

export default ExpandedVerificationsRow;
