import jwtDecode from 'jwt-decode';
import { useLayoutEffect, useState } from 'react';
import defaultLogo from 'images/teamsnap_logo_white.png';
import orgLogo from 'images/teamsnap_for_business_logo_white.png';
import brandLogo from 'images/teamsnap_for_brands_logo_white.png';

export default function useGetTeamSnapLogoByRole() {
  const [userToken] = useState(localStorage.getItem('token'));
  const [logo, setLogo] = useState(null);

  useLayoutEffect(() => {
    const userLogoMap = {
      organization: orgLogo,
      sponsor: brandLogo,
      offer: orgLogo,
      campaign: brandLogo,
      admin: defaultLogo,
      superadmin: defaultLogo,
    };

    const userRole = () => {
      const claims = jwtDecode(userToken);
      return claims.role;
    };

    setLogo(userToken ? userLogoMap[userRole()] : defaultLogo);
  }, [userToken]);

  return [logo];
}
