import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { either, map } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatExactCurrency } from 'utils/formatNumber';
import formatDate from 'utils/formatDate';
import { isApproved, isRejected } from 'utils/check';
import { OFFER_PREFIX } from 'utils/paths';
import { sortByCreatedAt } from 'utils/sort';
import DELIVERABLE_ADDRESS_FRAGMENT from 'fragments/DeliverableAddressFragment';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Card from 'components/Card';
import ApproveCheckModal from './checks/ApproveCheckModal';
import RejectCheckModal from './checks/RejectCheckModal';

const reviewed = either(isApproved, isRejected);

const renderStatusCell = (check) => (
  <td className="text-center text-nowrap" data-test="status">
    {isApproved(check) && (
      <>
        <span className="text-success">Approved</span>
        {`: #${check.number}`}
      </>
    )}
    {isRejected(check) && <span className="text-danger">Rejected</span>}
    <span className={reviewed(check) ? 'd-none' : ''}>
      <ApproveCheckModal check={check} />
      <RejectCheckModal check={check} />
    </span>
  </td>
);

const renderCheckRow = (check) => (
  <tr key={check.id} data-test={`check-${check.id}-row`}>
    <td data-test="date" className="text-nowrap">
      {formatDate(check.createdAt)}
    </td>
    <td data-test="amount" className="text-nowrap">
      {formatExactCurrency(check.amount)}
    </td>
    <td data-test="offer">
      <Link to={`${OFFER_PREFIX}/${check.offer.id}`}>
        {`${check.offer.campaign.sponsor.name} - ${check.offer.campaign.name} - ${check.offer.sponsorableProperty.name}`}
      </Link>
    </td>
    <td data-test="memo">{check.memo}</td>
    <td data-test="creator" className="text-nowrap">
      {check.createdBy.name}
    </td>
    {renderStatusCell(check)}
  </tr>
);

function renderContent(pendingChecks) {
  return (
    <RestrictTo roles={['superadmin']}>
      <FieldhousePageTitle title="Pending Checks">
        <Card>
          <h3 className="mb-3">Pending Checks</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Created</th>
                <th>Amount</th>
                <th>Offer</th>
                <th>Memo</th>
                <th>Created By</th>
                <th aria-label="status" />
              </tr>
            </thead>
            <tbody>{map(renderCheckRow, sortByCreatedAt(pendingChecks))}</tbody>
          </table>
        </Card>
      </FieldhousePageTitle>
    </RestrictTo>
  );
}

export const ChecksUnwrapped = ({ pendingChecks }) => (
  <Page loaded={!!pendingChecks}>
    {pendingChecks && renderContent(pendingChecks)}
  </Page>
);

ChecksUnwrapped.propTypes = {
  pendingChecks: PropTypes.arrayOf(LeagueSidePropTypes.check),
};

ChecksUnwrapped.defaultProps = {
  pendingChecks: null,
};

const QUERY = gql`
  query Checks {
    pendingChecks {
      id
      amount
      createdAt
      createdBy {
        id
        name
      }
      memo
      number
      offer {
        id
        campaign {
          id
          name
          sponsor {
            id
            name
          }
        }
        sponsorableProperty {
          id
          organization {
            id
            name
            mailingAddress {
              ...DeliverableAddressFragment
            }
          }
          name
        }
      }
      status
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

const Checks = () => (
  <Query fetchPolicy="network-only" query={QUERY}>
    {({ data }) => (
      <ChecksUnwrapped pendingChecks={data && data.pendingChecks} />
    )}
  </Query>
);

export default Checks;
