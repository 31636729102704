import * as React from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import HoverTable from 'components/HoverTable';
import Card from 'components/Card';
import RemoveOrganizationCapabilityModal from 'components/RemoveOrganizationCapability';

const GameSignageTable = ({ gameSignageCapabilites }) => {
  const row = (gameSignageCapability) => {
    const addresses = () => {
      const addressesArray = [];
      const { capabilityDetail } = gameSignageCapability;
      const { capabilityDetailable } = capabilityDetail;
      const { organizationPlayLocations } = capabilityDetailable;

      for (let i = 0; i < organizationPlayLocations.length; i += 1) {
        addressesArray.push(organizationPlayLocations[i].playLocation.address);
      }
      return addressesArray;
    };

    const sizes = () => {
      const sizesArray = [];
      const { capabilityDetail } = gameSignageCapability;
      const { capabilityDetailable } = capabilityDetail;
      const { bannerSizes } = capabilityDetailable;

      for (let i = 0; i < bannerSizes.length; i += 1) {
        sizesArray.push(bannerSizes[i]);
      }
      return sizesArray;
    };

    const bannerPlacements = () => {
      const bannerPlacementsArray = [];
      const { capabilityDetail } = gameSignageCapability;
      const { capabilityDetailable } = capabilityDetail;
      const { bannerPlacements: allBannerPlacements } = capabilityDetailable;

      for (let i = 0; i < allBannerPlacements.length; i += 1) {
        bannerPlacementsArray.push(allBannerPlacements[i]);
      }
      return bannerPlacementsArray;
    };

    const renderSpanTag = (string) => (
      <>
        <span>{string}</span>
        <br />
      </>
    );

    const { capabilityDetail } = gameSignageCapability;

    return (
      <>
        {capabilityDetail ? (
          <tr
            key={gameSignageCapability.id}
            data-test={`gameSignageCapability-${gameSignageCapability.id}`}
          >
            <td>{`${
              capabilityDetail.capabilityDetailable.canHang24Hours
                ? 'Yes'
                : 'No'
            }`}</td>
            <td>{map(renderSpanTag, addresses())}</td>
            <td>{map(renderSpanTag, sizes())}</td>
            <td>{`$${capabilityDetail.price}`}</td>
            <td>{capabilityDetail.maxForSale}</td>
            <td>{map(renderSpanTag, bannerPlacements())}</td>
            <td className="d-flex justify-content-end">
              <RemoveOrganizationCapabilityModal
                organizationCapability={gameSignageCapability}
              />
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Card>
        <h3 data-test="game-signage-table-header">Game Signage</h3>
        <HoverTable>
          <thead>
            <tr className="th-font-family-11">
              <th scope="col">Hang 24/7?</th>
              <th scope="col">Locations</th>
              <th scope="col">Sizes</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">Banner Placement</th>
              <th scope="col" className="d-flex justify-content-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{map(row, gameSignageCapabilites)}</tbody>
        </HoverTable>
      </Card>
    </>
  );
};

GameSignageTable.propTypes = {
  gameSignageCapabilites: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.object),
  ).isRequired,
};

export default GameSignageTable;
