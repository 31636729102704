import * as React from 'react';
import { Link } from 'react-router-dom';
import { map } from 'ramda';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import SponsorshipInsightReportType from 'types/sponsorshipInsightReport';
import SponsorshipInsightReport from 'components/SponsorshipInsightReport';
import EngagementStatusIcon from 'components/EngagementStatusIcon';

interface PlayLocation {
  address: string;
}

interface Organization {
  id: number;
  name: string;
  primaryPlayLocation?: PlayLocation;
  sponsorshipInsightReport?: SponsorshipInsightReportType;
  websiteUrl?: string;
}

interface Props {
  organizations: Organization[];
}

const OrganizationsTable: React.FunctionComponent<Props> = ({
  organizations,
}: Props) => {
  const row = (organization: Organization) => {
    const {
      id: organizationId,
      name,
      primaryPlayLocation,
      websiteUrl,
      sponsorshipInsightReport,
    } = organization;

    return (
      <li className="list-group-item" key={organizationId}>
        <div className="media w-100">
          <div className="gavatar mr-3">{name.substring(0, 1)}</div>
          <div className="media-body align-self-center">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <EngagementStatusIcon organization={organization} />
              <h3 className="m-0">
                <Link to={`${ORGANIZATIONS_PATH}/${organizationId}`}>
                  {name}
                </Link>
              </h3>
            </div>
            <div>
              <span className="text-muted">
                {primaryPlayLocation ? primaryPlayLocation.address : null}
              </span>
            </div>
            <div>
              <span className="text-muted">{websiteUrl}</span>
            </div>
            <div>
              <SponsorshipInsightReport
                sponsorshipInsightReport={sponsorshipInsightReport}
              />
            </div>
          </div>
        </div>
      </li>
    );
  };

  return organizations.length ? (
    <ul
      className="media-list media-list-users list-group mb-3"
      data-test="organization-list"
    >
      {map(row, organizations)}
    </ul>
  ) : null;
};

export default OrganizationsTable;
