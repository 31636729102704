import gql from 'graphql-tag';

const DELIVERABLE_ADDRESS_FRAGMENT = gql`
  fragment DeliverableAddressFragment on DeliverableAddress {
    id
    name
    line1
    line2
    city
    state
    zip
    country
  }
`;

export default DELIVERABLE_ADDRESS_FRAGMENT;
