import * as React from 'react';
import Alert from './Alert';

const defaultCopy =
  'Oops! Something went wrong. Please refresh the page and try again.';

interface Props extends React.HTMLAttributes<HTMLElement> {}

const ErrorAlert: React.FunctionComponent<Props> = ({
  children,
  ...rest
}: Props) => (
  <Alert alertStyle="danger" {...rest}>
    {children || defaultCopy}
  </Alert>
);

export default ErrorAlert;
