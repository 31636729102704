import React from 'react';

import leaguesideAPI from 'utils/leagueside-api';

import ErrorBoundary from 'components/ErrorBoundary';
import ContactUsLink from 'components/ContactUsLink';
import SingleCardPage from 'components/SingleCardPage';
import { RequiredAsterisk } from 'components/RequiredLabel';

import orgLogo from 'images/teamsnap_for_business_logo_white.png';

const INITIAL_STATE = {
  contactEmail: null,
  name: null,
  submitted: false,
};

const submittedMessage = (contactEmail, name) => (
  <>
    <h1>Thank you!</h1>
    <p>
      {"We'll send an email to "}
      <strong>{contactEmail}</strong>
      {' with a link to gather more information about '}
      <strong>{name}</strong>
      {' so we can begin matching you up with sponsorship offers!'}
    </p>
  </>
);

class OrganizationSelfSignup extends React.Component {
  constructor() {
    super();
    this.state = INITIAL_STATE;
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(event) {
    event.preventDefault();

    const { contactEmail, name } = this.state;

    await leaguesideAPI.organizationSelfSignup(contactEmail, name);

    this.setState({ submitted: true });
  }

  render() {
    const { contactEmail, name, submitted } = this.state;

    const form = (
      <div id="organizationSelfSignup">
        <div className="heading">Join our sponsorship network</div>
        <p>
          {/* eslint-disable-next-line max-len */}
          Thank you for your interest! Please submit your organization’s
          information and join our network of organizations seeking sponsorship.
        </p>
        <p>
          {'If you have any questions, please '}
          <ContactUsLink />.
        </p>
        <form className="ts-form" onSubmit={this.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">
              Organization name
              <RequiredAsterisk />
            </label>
            <input
              className="form-control"
              id="name"
              onChange={({ target: { value } }) =>
                this.setState({ name: value })
              }
              required
              type="text"
              value={name || ''}
              placeholder="Organization name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="contact-email">
              Contact email
              <RequiredAsterisk />
            </label>
            <input
              className="form-control"
              id="contact-email"
              onChange={({ target: { value } }) =>
                this.setState({ contactEmail: value })
              }
              required
              type="email"
              value={contactEmail || ''}
              placeholder="Email"
            />
          </div>
          <div className="text-right">
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    );

    return (
      <ErrorBoundary>
        <SingleCardPage logo={orgLogo}>
          {submitted ? submittedMessage(contactEmail, name) : form}
        </SingleCardPage>
      </ErrorBoundary>
    );
  }
}

export default OrganizationSelfSignup;
