import React from 'react';
import AssetConflict from 'types/assetConflict';
import Badge, { BadgeProps } from 'components/Badge';
import NewWindowLink from './NewWindowLink';

interface Props {
  conflict: AssetConflict;
  assetName: string;
}

const ConflictingAssetBadge: React.FunctionComponent<Props> = ({
  conflict,
  assetName,
}: Props) => {
  const {
    conflict: conflicted,
    conflictingOfferStatus,
    conflictingOfferId,
  } = conflict;
  return (
    <>
      {conflicted ? (
        <NewWindowLink hideIcon to={`/offers/${conflictingOfferId}`}>
          <Badge
            badgeType={conflictingOfferStatus as BadgeProps['badgeType']}
            title={`${assetName} Conflict`}
            data-test={`asset-conflict-badge-${assetName}`}
            size="sm"
          >
            {assetName.charAt(0).toUpperCase()}
          </Badge>
        </NewWindowLink>
      ) : null}
    </>
  );
};

export default ConflictingAssetBadge;
