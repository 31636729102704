import NumberInput from 'components/formInputs/NumberInput';
import SelectInput from 'components/formInputs/SelectInput';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { map } from 'ramda';

const MUTATION = gql`
  mutation CreateSocialMediaPost($input: CreateSocialMediaPostInput!) {
    createSocialMediaPost(input: $input) {
      organization {
        id
        name
        organizationCapabilities {
          id
          capabilityDetail {
            id
            maxForSale
            price
            __typename
            capabilityDetailable {
              ... on SocialMediaPost {
                id
                socialMediaFollowingSize
                socialMediaChannel {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SocialMediaPostCapabilityForm = ({
  organization,
  capabilityId,
  socialMediaChannels,
  redirect,
}) => {
  const [mutate] = useMutation(MUTATION, {
    fetchPolicy: 'no-cache',
  });

  const [socialMediaFollowingSize, setSocialMediaFollowingSize] =
    useState(null);
  const [
    estimatedPriceToSponsorSocialMediaPost,
    setEstimatedPriceToSponsorSocialMediaPost,
  ] = useState(null);
  const [maximumNumberOfSocialMediaPosts, setMaximumNumberOfSocialMediaPosts] =
    useState(null);
  const [socialMediaChannelId, setSocialMediaChannelId] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    await mutate({
      variables: {
        input: {
          organizationId: organization.id,
          capabilityId,
          socialMediaChannelId: Number(socialMediaChannelId),
          price: estimatedPriceToSponsorSocialMediaPost,
          maxForSale: maximumNumberOfSocialMediaPosts,
          socialMediaFollowingSize,
        },
      },
    });

    redirect();
  };

  return (
    <form data-target="game-signage-form" onSubmit={onSubmit}>
      <SelectInput
        inputId="what-social-media-channel-is-used-most"
        label="What social media channel is most used by the organization?"
        labelClassName="font-weight-bold"
        noDefault
        onChange={({ target: { value } }) => {
          setSocialMediaChannelId(value);
        }}
        required
        value={socialMediaChannelId}
      >
        <option value="">-- Please choose one --</option>
        {map(
          (socialMediaChannel) => (
            // eslint-disable-next-line max-len
            <option key={socialMediaChannel.id} value={socialMediaChannel.id}>
              {socialMediaChannel.name}
            </option>
          ),
          socialMediaChannels,
        )}
      </SelectInput>
      <NumberInput
        label="What is the approximate following size for your most used social media channel?"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) =>
          setSocialMediaFollowingSize(Number(value))
        }
        customId="social-media-following-size-input"
        required
        step={1}
        value={socialMediaFollowingSize}
        placeholder="Please enter the following size"
      />
      <NumberInput
        label="What is the estimated price to sponsor 1 social media post??"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) =>
          setEstimatedPriceToSponsorSocialMediaPost(Number(value))
        }
        customId="estimated-price-to-sponsor-social-media-post-input"
        required
        step={1}
        value={estimatedPriceToSponsorSocialMediaPost}
        placeholder="Please enter the price"
      />
      <NumberInput
        label="What is the maximum number of social media posts we can sell?"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) =>
          setMaximumNumberOfSocialMediaPosts(Number(value))
        }
        customId="maximum-number-of-social-media-posts-we-could-sell-input"
        required
        step={1}
        value={maximumNumberOfSocialMediaPosts}
        placeholder="Please enter the maximum number of social media posts"
      />
      <button
        type="submit"
        className="btn btn-primary"
        data-test="social-media-post-capability-form-submit-button"
      >
        Submit
      </button>
    </form>
  );
};

SocialMediaPostCapabilityForm.propTypes = {
  organization: LeagueSidePropTypes.organization.isRequired,
  capabilityId: PropTypes.number.isRequired,
  socialMediaChannels: PropTypes.arrayOf(
    LeagueSidePropTypes.organizationSocialMediaChannels,
  ).isRequired,
  redirect: PropTypes.func.isRequired,
};

export default SocialMediaPostCapabilityForm;
