import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import Icon from 'components/Icon';

const VisibleToSponsorText = styled.div`
  text-align: right;
  color: #401780;
`;

const classNameForChild = (child) =>
  classNames('visible-to-sponsor', child.props.className);
const cloneChild = (child) =>
  cloneElement(child, { className: classNameForChild(child) });
const renderChildren = (children) => React.Children.map(children, cloneChild);

const VisibleToSponsorIndicator = ({ children }) => (
  <div>
    {renderChildren(children)}
    <VisibleToSponsorText>
      <Icon value="eye" />
      &nbsp;Visible To Sponsor
    </VisibleToSponsorText>
  </div>
);

VisibleToSponsorIndicator.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VisibleToSponsorIndicator;
