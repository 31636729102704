import * as React from 'react';
import StringInput from 'components/formInputs/StringInput';

interface Props extends React.HTMLAttributes<HTMLElement> {
  isRequired?: boolean;
  loading?: boolean;
  name?: string;
  prefix?: string;
}

const NameInput: React.FunctionComponent<Props> = ({
  isRequired,
  loading,
  name,
  onChange,
  prefix,
}: Props) => (
  <StringInput
    disabled={loading}
    label="name"
    labelClassName="font-weight-bold"
    onChange={onChange}
    prefix={prefix}
    required={isRequired}
    type="text"
    value={name || ''}
  />
);

export default NameInput;
