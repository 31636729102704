import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { capitalize, conjugateString } from 'utils/string';
import { useSubmit } from 'utils/hooks';
import ErrorAlert from 'components/ErrorAlert';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';
import { contains } from 'ramda';

const cannotRemoveCampaignCapability = contains(
  'GraphQL error: Cannot destroy campaign capabilities that are associated with an asset',
);

export const RemoveCampaignCapabilityModalUnwrapped = ({
  campaignCapabilityId,
  capabilityName,
  remove,
}) => {
  const { error, loading, handleSubmit } = useSubmit(remove);
  const cannotRemove = !!error && cannotRemoveCampaignCapability(error.message);

  return (
    <ModalForm
      id={`remove-campaign-capability-${campaignCapabilityId}-modal`}
      error={error}
      errorMessage={
        <ErrorAlert className="mt-3 mb-0">
          {!!cannotRemove &&
            'This campaign capability cannot be deleted because it is associated with at least one asset that is a part of an existing offer.'}
        </ErrorAlert>
      }
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: conjugateString('Delete', loading),
        className: 'btn btn-danger',
        disabled: loading || cannotRemove,
      }}
      title={`Remove ${capitalize(capabilityName)}?`}
      toggleProps={{
        className: 'btn btn-link text-danger',
        children: <Icon value="trash" />,
        disabled: cannotRemove,
        title: cannotRemove ? 'Cannot be deleted' : 'Delete',
      }}
    >
      <p>
        Are you sure you want to remove&nbsp;
        <strong>{capabilityName}</strong>?
      </p>
    </ModalForm>
  );
};

RemoveCampaignCapabilityModalUnwrapped.propTypes = {
  campaignCapabilityId: PropTypes.number.isRequired,
  capabilityName: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation DeleteCampaignCapability($input: DeleteCampaignCapabilityInput!) {
    deleteCampaignCapability(input: $input) {
      campaign {
        id
        campaignCapabilities {
          id
        }
      }
    }
  }
`;

const RemoveCampaignCapabilityModal = ({
  campaignCapabilityId,
  capabilityName,
}) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <RemoveCampaignCapabilityModalUnwrapped
        campaignCapabilityId={campaignCapabilityId}
        capabilityName={capabilityName}
        remove={() =>
          mutate({ variables: { input: { id: campaignCapabilityId } } })
        }
      />
    )}
  </Mutation>
);

RemoveCampaignCapabilityModal.propTypes = {
  campaignCapabilityId: PropTypes.number.isRequired,
  capabilityName: PropTypes.string.isRequired,
};

export default RemoveCampaignCapabilityModal;
