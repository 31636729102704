import { debounce } from 'debounce';
import { useCallback, useEffect, useState } from 'react';

export default function useFetchAndSetEmailPreview(handleFetchEmailFn) {
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);

  const fetchAndSetEmail = async () => {
    try {
      const fetchedEmail = await handleFetchEmailFn();
      setEmail(fetchedEmail);
    } catch (err) {
      setError(err);
    }
  };

  const debouncedFetchEmail = useCallback(debounce(fetchAndSetEmail, 200), []);

  useEffect(() => {
    debouncedFetchEmail();
  }, [handleFetchEmailFn, debouncedFetchEmail]);

  return [email, error];
}
