import React, { useState } from 'react';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import CsvInput from 'components/formInputs/CsvInput';
import GenericButton from 'components/GenericButton';
import leaguesideApi from 'utils/leagueside-api';
import Alert from 'components/Alert';
import { useSubmit } from 'utils/hooks';

const leagueAttributes = {
  name: 'string',
  data_confidence: 'integer',
  website_url: 'string',
  website_sponsorships_url: 'string',
  contact_phone: 'string',
  contact_email: 'string',
  female: 'boolean',
  male: 'boolean',
  number_of_players: 'integer',
  max_age: 'integer',
  min_age: 'integer',
  spring_games: 'boolean',
  summer_games: 'boolean',
  winter_games: 'boolean',
  fall_games: 'boolean',
  old_shipping_address: 'string',
  city: 'string',
  state: 'string',
  hubspot_company_id: 'integer',
};

const renderList = (hash) => (
  <>
    <ul>
      {Object.keys(hash).map((key) => (
        <li key={`${key.toString()}-${hash[key].toString()}`}>
          <strong>{key}: </strong>
          {hash[key]}
        </li>
      ))}
    </ul>
  </>
);

const LeagueImport = () => {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState([]);

  const handleChange = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
  };

  async function asyncSubmit(event) {
    event.preventDefault();

    setResponse([]);

    await leaguesideApi.uploadCsvToUpdateLeague(file).then((res) => {
      setResponse(res);
      setFile(null);
    });
  }

  const { loading, handleSubmit, submitted, error } = useSubmit(asyncSubmit);

  return (
    <Page>
      <RestrictTo roles={['superadmin']}>
        <FieldhousePageTitle title="League Import">
          <div className="mt-4 mb-4">
            <h1 className="mb-3">Update League info with CSV</h1>
          </div>
          <div className="row">
            <div className="col-md-8">
              {!!loading && (
                <Alert alertStyle="info">Uploading and processing CSV</Alert>
              )}
              {!!error && (
                <Alert data-test="danger-alert" alertStyle="danger">
                  {!!error && error.map((e) => <li key={e}>{e}</li>)}
                </Alert>
              )}
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <CsvInput
                    onChange={handleChange}
                    required
                    disabled={loading}
                  />
                </div>
                <GenericButton
                  label="Import"
                  disabled={!file}
                  submitted={submitted}
                  loading={loading}
                />
              </form>
              {!!response && (
                <div className="mt-4" data-test="csv-response">
                  {!!response && response.map((res) => renderList(res))}
                </div>
              )}
            </div>
            <div className="col-md-4">
              <h2>Requirements</h2>
              <ul>
                <li>Must have column &quot;id&quot; for league.id</li>
              </ul>
              <h2>Available attributes for update</h2>
              {renderList(leagueAttributes)}
            </div>
          </div>
        </FieldhousePageTitle>
      </RestrictTo>
    </Page>
  );
};

export default LeagueImport;
