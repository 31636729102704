import React from 'react';
import { ApolloConsumer, Query } from 'react-apollo';
import { path, prop } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { parseQueryString } from 'utils/string';
import { isProposed } from 'utils/offer';
import Loader from 'components/Loader';
import ReviewOffer, {
  ACCEPT_OFFER_MUTATION,
  LEAGUE_REJECT_OFFER_MUTATION,
  REVIEW_OFFER_OFFER_FRAGMENT,
} from 'components/ReviewOffer';
import CONTENT from 'components/reviewOffer/CONTENT';
import ErrorPage from 'components/reviewOffer/ErrorPage';
import DELIVERABLE_ADDRESS_FRAGMENT from 'fragments/DeliverableAddressFragment';

const getJWT = path(['data', 'acceptOffer', 'jwt']);

const OFFER_QUERY = gql`
  query OfferToAccept {
    offer: offerToAccept {
      ...ReviewOfferOfferFragment
    }
  }
  ${REVIEW_OFFER_OFFER_FRAGMENT}
`;

const HAS_ACCOUNT_QUERY = gql`
  query HasAccount($email: String!) {
    hasAccount(email: $email)
  }
`;

const VERIFY_ADDRESS = gql`
  query VerifyAddressFromOffer($input: VerifyAddressInput!) {
    verifyAddressFromOffer(input: $input) {
      name
      line1
      line2
      city
      state
      zip
      deliverability
      country
    }
  }
`;

const UPSERT_SHIPPING_ADDRESS = gql`
  mutation UpsertShippingAddress($input: UpsertAddressInput!) {
    upsertAddress(input: $input) {
      id
      shippingAddress {
        ...DeliverableAddressFragment
      }
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

function ReviewOfferFromEmail({ location, match }) {
  const context = {
    headers: { Authorization: `Bearer ${match.params.token}` },
  };
  const { sentTo } = parseQueryString(location.search);

  return (
    <Query fetchPolicy="network-only" context={context} query={OFFER_QUERY}>
      {({ data, error }) => {
        if (error) return <ErrorPage status="expired link" />;

        const offer = data && data.offer;

        if (!offer) return <Loader />;
        if (!isProposed(offer)) return <ErrorPage status={offer.status} />;

        return (
          <ApolloConsumer>
            {(client) => (
              <ReviewOffer
                acceptOffer={(input) =>
                  client
                    .mutate({
                      context,
                      mutation: ACCEPT_OFFER_MUTATION,
                      variables: { input },
                    })
                    .then(getJWT)
                }
                fetchHasAccount={(email) =>
                  client
                    .query({
                      context,
                      fetchPolicy: 'network-only',
                      query: HAS_ACCOUNT_QUERY,
                      variables: { email },
                    })
                    .then(prop('data'))
                }
                verifyAddress={(input) =>
                  client
                    .query({
                      context,
                      fetchPolicy: 'network-only',
                      query: VERIFY_ADDRESS,
                      variables: { input },
                    })
                    .then(prop('data'))
                }
                upsertAddress={(input) =>
                  client.mutate({
                    context,
                    mutation: UPSERT_SHIPPING_ADDRESS,
                    variables: { input },
                  })
                }
                initialContent={CONTENT.ENTER_EMAIL}
                initialEmail={sentTo}
                offer={offer}
                rejectOffer={async (input) => {
                  await client.mutate({
                    context,
                    mutation: LEAGUE_REJECT_OFFER_MUTATION,
                    variables: { input },
                  });
                }}
              />
            )}
          </ApolloConsumer>
        );
      }}
    </Query>
  );
}

ReviewOfferFromEmail.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

export default ReviewOfferFromEmail;
