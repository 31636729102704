import * as React from 'react';
import classNames from 'classnames';
import { getInputId } from 'utils/id';
import { capitalizeEachWord, joinTruthyWithSpace } from 'utils/string';
import { RequiredAsterisk } from 'components/RequiredLabel';

const defaultOption = (
  <option value="" disabled>
    -- Please choose one --
  </option>
);

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  formGroupClassName?: string;
  inputId?: string;
  inputSize?: string;
  label: string;
  labelClassName?: string;
  noDefault?: boolean;
  prefix?: string;
  required?: boolean;
  value: string | number;
}

const SelectInput: React.FunctionComponent<Props> = ({
  children,
  disabled,
  formGroupClassName,
  inputId,
  inputSize,
  label,
  labelClassName,
  noDefault,
  onChange,
  prefix,
  required,
  value,
}: Props) => {
  const id = getInputId([prefix, inputId || label]);

  return (
    <div className={joinTruthyWithSpace(['form-group', formGroupClassName])}>
      <label className={labelClassName} htmlFor={id}>
        {capitalizeEachWord(label)}
        {!!required && <RequiredAsterisk />}
      </label>
      <select
        aria-required={required ? 'true' : undefined}
        className={classNames('form-control', {
          [`form-control-${inputSize}`]: !!inputSize,
        })}
        disabled={disabled}
        id={id}
        onChange={onChange}
        required={required}
        value={value}
      >
        {!noDefault && defaultOption}
        {children}
      </select>
    </div>
  );
};

SelectInput.defaultProps = {
  disabled: false,
  formGroupClassName: undefined,
  inputId: undefined,
  inputSize: undefined,
  labelClassName: undefined,
  noDefault: false,
  prefix: undefined,
  required: false,
};

export default SelectInput;
