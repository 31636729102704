import * as React from 'react';
import formatDate from 'utils/formatDate';

interface RecentComment {
  createdAt: Date;
  description: string;
}
interface Offer {
  id: number;
  recentComment: RecentComment;
}

interface Props {
  colLength: number;
  offer: Offer;
}

const RecentCommentRow: React.FunctionComponent<Props> = ({
  colLength,
  offer,
}: Props) => {
  const { id: offerId, recentComment } = offer;
  const { createdAt, description } = recentComment;

  return (
    <tr data-test={`offer-${offerId}-comment`}>
      <td className="border-0" aria-label="Empty cell for formatting" />
      <td colSpan={colLength - 1} className="border-0 pb-2 pt-0 text-muted">
        {`${formatDate(createdAt)} - ${description}`}
      </td>
    </tr>
  );
};

export default RecentCommentRow;
