import React from 'react';
import { Mutation, Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { getUserIdFrom } from 'utils/authentication';
import ORGANIZATION_ADDRESSES_FRAGMENT from 'components/organizationDashboardWrapper/LeagueAddressesFragment';
import OrganizationDashboardWrapper from 'components/OrganizationDashboardWrapper';
import OrganizationSections from 'components/OrganizationSections';
import Page from 'components/Page';
import USER_FRAGMENT from 'fragments/UserFragment';

const ORGANIZATION_DASHBOARD_OVERVIEW_FRAGMENT = gql`
  fragment OrganizationDashboardOverviewFragment on Organization {
    contactEmail
    contactPhone
    id
    maxAge
    minAge
    name
    organizationTypeId
    users {
      ...UserFragment
    }
    numberOfUniqueParticipantsPerYear
    websiteUrl
    dataConfidence
  }
  ${USER_FRAGMENT}
`;

const MUTATION = gql`
  mutation updateOrganization($id: Int!, $input: OrganizationInput!) {
    updateOrganization(id: $id, input: $input) {
      ...OrganizationDashboardOverviewFragment
    }
  }
  ${ORGANIZATION_DASHBOARD_OVERVIEW_FRAGMENT}
`;

const OrganizationDashboardOverviewWrappedWithView = ({ organizations }) => (
  <OrganizationDashboardWrapper organizations={organizations}>
    {({ selectedOrganization }) => (
      <Mutation mutation={MUTATION}>
        {() => (
          <OrganizationSections organizationId={selectedOrganization.id} />
        )}
      </Mutation>
    )}
  </OrganizationDashboardWrapper>
);

OrganizationDashboardOverviewWrappedWithView.propTypes = {
  organizations: LeagueSidePropTypes.organizations.isRequired,
};

const QUERY = gql`
  query OrganizationDashboard($userId: Int) {
    myOrganizations(userId: $userId) {
      ...OrganizationAddressesFragment
      ...OrganizationDashboardOverviewFragment
    }
  }
  ${ORGANIZATION_ADDRESSES_FRAGMENT}
  ${ORGANIZATION_DASHBOARD_OVERVIEW_FRAGMENT}
`;

const OrganizationDashboardOverview = ({ location }) => (
  <Query
    fetchPolicy="network-only"
    query={QUERY}
    variables={{ userId: getUserIdFrom(location.search) }}
  >
    {({ data, error }) => {
      if (error) throw error;

      const organizations = data && data.myOrganizations;

      return organizations ? (
        <OrganizationDashboardOverviewWrappedWithView
          organizations={organizations}
        />
      ) : (
        <Page loaded={false} />
      );
    }}
  </Query>
);

OrganizationDashboardOverview.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrganizationDashboardOverview;
