import gql from 'graphql-tag';
import DELIVERABLE_ADDRESS_FRAGMENT from 'fragments/DeliverableAddressFragment';

export const LEAGUE_ADDRESSES_ASSET_FRAGMENT = gql`
  fragment LeagueAddressesAssetFragment on Asset {
    id
    campaignCapability {
      id
      capability {
        id
        digital
      }
    }
    quantity
  }
`;

export const LEAGUE_ADDRESSES_OFFER_FRAGMENT = gql`
  fragment LeagueAddressesOfferFragment on Offer {
    id
    assets {
      ...LeagueAddressesAssetFragment
    }
  }
  ${LEAGUE_ADDRESSES_ASSET_FRAGMENT}
`;

export const ORGANIZATION_ADDRESSES_MAILING_AND_SHIPPING_FRAGMENT = gql`
  fragment OrganizationAddressesMailingAndShippingFragment on Organization {
    mailingAddress {
      ...DeliverableAddressFragment
    }
    shippingAddress {
      ...DeliverableAddressFragment
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

const ORGANIZATION_ADDRESSES_FRAGMENT = gql`
  fragment OrganizationAddressesFragment on Organization {
    confirmedOffers: offers(statuses: [confirmed]) {
      ...LeagueAddressesOfferFragment
    }
    ...OrganizationAddressesMailingAndShippingFragment
  }
  ${LEAGUE_ADDRESSES_OFFER_FRAGMENT}
  ${ORGANIZATION_ADDRESSES_MAILING_AND_SHIPPING_FRAGMENT}
`;

export const LEAGUE_ADDRESSES_MAILING_AND_SHIPPING_FRAGMENT = gql`
  fragment LeagueAddressesMailingAndShippingFragment on League {
    mailingAddress {
      ...DeliverableAddressFragment
    }
    shippingAddress {
      ...DeliverableAddressFragment
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

export const LEAGUE_ADDRESSES_FRAGMENT = gql`
  fragment LeagueAddressesFragment on League {
    confirmedOffers: offers(statuses: [confirmed]) {
      ...LeagueAddressesOfferFragment
    }
    ...LeagueAddressesMailingAndShippingFragment
  }
  ${LEAGUE_ADDRESSES_OFFER_FRAGMENT}
  ${LEAGUE_ADDRESSES_MAILING_AND_SHIPPING_FRAGMENT}
`;

export default ORGANIZATION_ADDRESSES_FRAGMENT;
