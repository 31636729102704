import { useEffect, useReducer, useState } from 'react';
import { useMutation } from 'react-apollo';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import {
  adjust,
  anyPass,
  always,
  assoc,
  complement,
  concat,
  equals,
  findIndex,
  isEmpty,
  map,
  merge,
  path,
  pick,
  pickBy,
  propEq,
  sortBy,
  lt,
  contains,
} from 'ramda';
import gql from 'graphql-tag';
import styled from 'styled-components';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { findById, unsavedChangesPrompt } from 'utils/misc';
import {
  isCanceled,
  isConfirmed,
  isProposed,
  isQualified,
  isRejected,
  isRejectedByLeague,
} from 'utils/offer';
import { equalsSuperadmin } from 'utils/user';
import { getAuthentication } from 'modules';
import { getRole } from 'modules/authentication';
import ReactDates from 'react-dates';
import SaveButton from 'components/SaveButton';
import SendOfferEmailsModal from 'components/SendOfferEmailsModal';
import ErrorAlert from 'components/ErrorAlert';
import DateRangePickerWrapper from 'components/DateRangePickerWrapper';
import CopyButton from 'components/CopyButton';
import QuantityGreaterThanVerificationDeadlineWarning from 'components/QuanitityGreaterThanVerificationDeadlineWarning';
import { rejectFalsy } from 'utils/array';
import { getSeasonsForValidation } from 'utils/seasonDeadlineValidation';
import { ONE_YEAR_BEFORE, TWO_YEARS_AFTER } from 'utils/formatDate';
import type { Offer } from 'types/offer';
import Alert from 'components/Alert';

type OfferFormProps = {
  offer: Offer;
  refetchSponsorship: () => void;
  userRole: string;
};

const OfferForm = (props: OfferFormProps) => {
  const { offer, refetchSponsorship, userRole } = props;
  const [mutate] = useMutation(MUTATION);

  const onSave = ({
    additionalRequirements,
    assets,
    customField,
    status,
    value,
    seasonStart,
    seasonEnd,
  }: any) =>
    mutate({
      variables: {
        input: {
          id: Number(offer.id),
          additionalRequirements,
          assets: assets && pickAssetsInputs(assets),
          customField,
          status,
          value,
          seasonStart,
          seasonEnd,
        },
      },
    });
  const [state, dispatch] = useReducer(reducer, stateFromOffer(offer));

  const [viewOnly] = useState(
    isCanceled(offer) ||
      isRejected(offer) ||
      (isConfirmed(offer) && notSuperadmin(userRole)),
  );
  const [changesToOfferForm, setChangesToOfferForm] = useState({});
  const [saved, setSaved] = useState(false);
  const [saving, setSaving] = useState(false);
  const [focusedInput, setFocusedInput] =
    useState<ReactDates.FocusedInputShape | null>(null);
  const [seasonDateErrors, setSeasonDateErrors] = useState(false);
  const [seasonDateErrorMessages, setSeasonDateErrorsMessages] = useState(null);
  const [error, setError] = useState<{
    message: string;
  } | null>(null);

  const selectedSeason = getSeasonsForValidation(offer.campaign.season);
  const addChanges = (newChanges: any) => {
    const isChanged = (value: string, key: string) =>
      // @ts-ignore-next-line
      stateFromOffer(offer)[key] !== value;

    setChangesToOfferForm((prevState) => ({
      ...prevState,
      ...pickBy(isChanged, merge(prevState, newChanges)),
    }));
  };

  const resetState = () => {
    setChangesToOfferForm({});
    setSaving(false);
    setFocusedInput(null);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: { returnValue: string }) => {
      // eslint-disable-next-line no-param-reassign
      if (!saved) event.returnValue = unsavedChangesPrompt;

      return event.returnValue;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return function cleanup() {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      selectedSeason.reset();
    };
  }, [saved, selectedSeason]);

  useEffect(() => {
    setSaved(isEmpty(changesToOfferForm));
  }, [changesToOfferForm]);

  const save = async () => {
    const timeoutID = setTimeout(() => setSaving(true), 200);
    try {
      setError(null);
      await onSave(changesToOfferForm);
      resetState();
    } catch (catchError) {
      setError({
        message: catchError as string,
      });
      setSaving(false);
    }
    clearTimeout(timeoutID);
  };

  const handleValueChange = (event: any) => {
    dispatch({
      type: 'update value',
      payload: Number(event.target.value) || null,
    });
    addChanges({ value: Number(event.target.value) || null });
  };

  const handleCustomFieldChange = (event: any) => {
    dispatch({ type: 'update customField', payload: event.target.value });
    addChanges({ customField: event.target.value });
  };

  const handleAdditionalRequirementsChange = (event: any) => {
    dispatch({
      type: 'update additionalRequirements',
      payload: event.target.value,
    });
    addChanges({ additionalRequirements: event.target.value });
  };

  const handleSeasonChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    const validationErrors = selectedSeason.validate(startDate, endDate);

    if (validationErrors) {
      setSeasonDateErrors(true);
      setSeasonDateErrorsMessages(validationErrors);
    } else {
      setSeasonDateErrors(false);
    }

    dispatch({ type: 'update seasonStart', payload: startDate });
    dispatch({ type: 'update seasonEnd', payload: endDate });
    addChanges({ seasonStart: startDate, seasonEnd: endDate });
  };

  const campaignCapabilityIdsIfError = () => {
    function shouldShowError(asset: any) {
      const offerFound = findById(asset.campaignCapabilityId)(
        offer.campaign.campaignCapabilities,
      );
      if (!offerFound) return false;
      const {
        // @ts-ignore-next-line
        capability,
        // @ts-ignore-next-line
        defaultVerificationDeadlines,
        // @ts-ignore-next-line
        id: campaignCapabilityId,
      } = offerFound;
      const defaultVerificationDeadlinesCount =
        defaultVerificationDeadlines.length;
      const { createDeadlineBasedOnQuantity } = capability;
      const { quantity } = asset;

      return (
        lt(defaultVerificationDeadlinesCount, quantity) &&
        createDeadlineBasedOnQuantity &&
        campaignCapabilityId
      );
    }

    return rejectFalsy(map(shouldShowError, state.assets));
  };

  const assetProp = (campaignCapabilityId: number, propName: string) => {
    const asset = state.assets.find(
      withCampaignCapabilityId(campaignCapabilityId),
    );

    return asset && asset[propName] ? asset[propName] : '';
  };

  const handleAssetPropChange = (
    campaignCapabilityId: number,
    propName: string,
    newValue: number,
  ) => {
    const existingAssets = state.assets;
    const findIndexofAssetWithCampaignCapabilityId = findIndex(
      withCampaignCapabilityId(campaignCapabilityId),
      existingAssets,
    );

    const update =
      findIndexofAssetWithCampaignCapabilityId === -1
        ? concat([{ campaignCapabilityId, [propName]: newValue }])
        : adjust(
            // @ts-ignore-next-line
            assoc(propName, newValue),
            findIndexofAssetWithCampaignCapabilityId,
          );
    // @ts-ignore-next-line
    dispatch({ type: 'update assets', payload: update(state.assets) });
    // @ts-ignore-next-line
    addChanges({ assets: update(state.assets) });
  };

  const handleQuantityChange = (event: any) => {
    const campaignCapabilityId = Number(event.target.dataset.id);
    const newValue =
      Number(event.target.value) > 0 ? Number(event.target.value) : null;

    handleAssetPropChange(campaignCapabilityId, 'quantity', Number(newValue));
  };

  const handleReachChange = (event: any) => {
    const campaignCapabilityId = Number(event.target.dataset.id);
    const newValue =
      Number(event.target.value) > 0 ? Number(event.target.value) : null;

    handleAssetPropChange(campaignCapabilityId, 'reach', Number(newValue));
  };

  const renderAssetWithCampaignCapabilityId = (campaignCapability: any) => {
    const { id: campaignCapabilityId } = campaignCapability;
    const quantity = assetProp(campaignCapabilityId, 'quantity');
    const reach = assetProp(campaignCapabilityId, 'reach');
    const verified = assetProp(campaignCapabilityId, 'anyVerifiedDeadlines');

    return (
      <div key={`campaign-capability-${campaignCapabilityId}`}>
        <div className="form-inline mb-3">
          <Input
            className={classNames('form-control', {
              'border-danger':
                (verified && !quantity) ||
                contains(campaignCapabilityId, campaignCapabilityIdsIfError()),
            })}
            data-id={campaignCapabilityId}
            data-test={`asset-${campaignCapabilityId}-quantity`}
            onChange={handleQuantityChange} // eslint-disable-line react/jsx-no-bind
            type="number"
            value={quantity}
            disabled={viewOnly}
          />
          <div
            className="ml-2"
            data-test={`asset-${campaignCapabilityId}-label`}
          >
            <strong className="mr-1">
              {campaignCapability.capability.name}
            </strong>
            {campaignCapability.minimumQuantity
              ? `(Default: ${campaignCapability.minimumQuantity})`
              : null}
          </div>
          <div className="row ml-auto pr-2 pl-1 align-items-center">
            <strong className="pr-2 ">Reach</strong>
            <Input
              data-id={campaignCapabilityId}
              data-test={`asset-${campaignCapabilityId}-reach`}
              onChange={handleReachChange} // eslint-disable-line react/jsx-no-bind
              type="number"
              min="0"
              value={reach}
              disabled={viewOnly}
            />
          </div>
        </div>
        {!!reach && !quantity && (
          <ErrorAlert className="mt-4">
            Oops! Assets with reach is missing quantity!
          </ErrorAlert>
        )}
      </div>
    );
  };

  return (
    <>
      <Prompt message={always(unsavedChangesPrompt)} when={!saved} />
      <div className="form-group">
        <h5>Sponsorship Value</h5>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">$</span>
          </div>
          <input
            className="form-control form-control-lg"
            data-test="value"
            onChange={handleValueChange}
            type="number"
            value={state.value || ''}
            disabled={viewOnly}
            onWheel={(e) => (e.target as HTMLElement).blur()}
          />
        </div>
      </div>
      <div className="form-group mt-4">
        <h5>Assets</h5>
        {!!(
          offer.campaign.campaignCapabilities.length &&
          !isEmpty(campaignCapabilityIdsIfError())
        ) && <QuantityGreaterThanVerificationDeadlineWarning />}
        {offer.campaign.campaignCapabilities.length ? (
          map(
            renderAssetWithCampaignCapabilityId,
            // @ts-ignore-next-line
            sortByCapabilityName(offer.campaign.campaignCapabilities),
          )
        ) : (
          <p className="text-center">
            No capabilities required for this campaign!
          </p>
        )}
      </div>
      <div className="form-group mt-4">
        <h5>Additional Requirements</h5>
        <textarea
          className="form-control"
          id={`additional-requirements-${offer.id}`}
          data-test="additional-requirements"
          onChange={handleAdditionalRequirementsChange}
          value={state.additionalRequirements || ''}
          disabled={viewOnly}
        />
      </div>
      <div className="form-group mt-4">
        <h5>Custom Field</h5>
        <div className="input-group mb-3">
          <input
            className="form-control"
            data-test="custom-field"
            onChange={handleCustomFieldChange}
            value={state.customField || ''}
            disabled={viewOnly}
          />
        </div>
      </div>
      <div className="form-group mt-4">
        <h5>Start and End Date</h5>
        {!!seasonDateErrors &&
          renderSeasonDateErrorAlerts(seasonDateErrorMessages ?? [])}
        <div className="input-group mb-3">
          <DateRangePickerWrapper
            disabled={viewOnly}
            endDate={state.seasonEnd}
            focusedInput={focusedInput}
            onDatesChange={handleSeasonChange}
            onFocusChange={(input) => setFocusedInput(input)}
            isOutsideRange={(date) =>
              date.isBefore(ONE_YEAR_BEFORE) || date.isAfter(TWO_YEARS_AFTER)
            }
            startDate={state.seasonStart}
            startDateId="date-range-picker-start-date"
            endDateId="date-range-picker-end-date"
          />
        </div>
      </div>
      {!viewOnly && (
        <>
          <hr />
          {error && errorAlert(error.message)}
          <div className="d-flex justify-content-between align-items-center mt-4 mb-2">
            <div>
              {isProposed(offer) && (
                <CopyButton
                  content={url(offer.magicToken)}
                  disabled={!saved}
                  label="Offer Link"
                />
              )}
            </div>
            <div>
              <SaveButton
                save={save}
                saved={saved}
                saving={saving}
                large={false}
              />
              {isQualifedOrProposedOrRejectedByLeague(offer) && (
                <SendOfferEmailsModal
                  id={`offer-email-modal-${offer.id}`}
                  offers={[offer]}
                  toggleClassName="btn btn-primary ml-2"
                  disabled={!saved}
                  onSend={refetchSponsorship}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

const wrapWithUserRole = connect((state) => ({
  userRole: getRole(getAuthentication(state)),
}));

export default wrapWithUserRole(OfferForm);

const MUTATION = gql`
  mutation UpdateOffer($input: OfferInput!) {
    updateOffer(input: $input) {
      id
      additionalRequirements
      assets {
        id
        anyVerifiedDeadlines
        campaignCapability {
          id
          capability {
            id
            name
          }
          defaultVerificationDeadlines {
            id
            deadline
          }
          executionExpectations
        }
        quantity
        reach
      }
      customField
      sponsorableProperty {
        id
        confirmedOffers: offers(statuses: [confirmed]) {
          id
          updatedAt
          value
        }
      }
      seasonStart
      seasonEnd
      value
      verificationDeadlines {
        id
      }
    }
  }
`;

const Input = styled.input`
  max-width: 70px;
`;

const notSuperadmin = complement(equalsSuperadmin);
// @ts-ignore-next-line
const sortByCapabilityName = sortBy(path(['capability', 'name']));
const withCampaignCapabilityId = propEq('campaignCapabilityId');
const pickAssetsInputs = map(
  pick(['campaignCapabilityId', 'id', 'quantity', 'reach']),
);
const url = (magicToken: string) =>
  `${global.location.origin}/accept-offer/${magicToken}`;
const isQualifedOrProposedOrRejectedByLeague = anyPass([
  isProposed,
  isQualified,
  isRejectedByLeague,
]);

const removedVerifiedAsset = equals(
  'GraphQL error: Assets with verified deadlines cannot be removed from offer',
);
const errorAlert = (errorMessage: string) =>
  removedVerifiedAsset(errorMessage) ? (
    <ErrorAlert className="mt-4">
      Oops! Assets with verified deadlines cannot be removed from offer.
    </ErrorAlert>
  ) : (
    <ErrorAlert className="mt-4" data-test="error-alert" />
  );

const seasonDateErrorAlert = (message: string) => (
  <Alert
    className="alert-warning mt-4"
    role="alert"
    data-test="address-error-alert"
  >
    {message}
  </Alert>
);

const renderSeasonDateErrorAlerts = map(seasonDateErrorAlert);

export const stateFromOffer = (offer: Offer) => ({
  additionalRequirements: offer.additionalRequirements,
  assets: offer.assets.map(
    ({ anyVerifiedDeadlines, campaignCapability, id, quantity, reach }) => ({
      anyVerifiedDeadlines,
      campaignCapabilityId: campaignCapability.id,
      createDeadlineBasedOnQuantity:
        campaignCapability.capability.createDeadlineBasedOnQuantity,
      minimumQuantity: campaignCapability.minimumQuantity,
      id,
      quantity,
      reach,
    }),
  ),
  value: offer.value,
  customField: offer.customField,
  seasonStart: offer.seasonStart && moment(offer.seasonStart),
  seasonEnd: offer.seasonEnd && moment(offer.seasonEnd),
});

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'update additionalRequirements': {
      return { ...state, additionalRequirements: action.payload };
    }
    case 'update assets': {
      return {
        ...state,
        assets: [...action.payload],
      };
    }
    case 'update value': {
      return { ...state, value: action.payload };
    }
    case 'update customField': {
      return { ...state, customField: action.payload };
    }
    case 'update seasonStart': {
      return { ...state, seasonStart: action.payload };
    }
    case 'update seasonEnd': {
      return { ...state, seasonEnd: action.payload };
    }
    default:
      return state;
  }
};
