/* eslint-disable react/no-danger */

import React from 'react';
import CircledIcon from 'components/CircledIcon';
import VisibleToSponsorIndicator from 'components/VisibleToSponsorIndicator';
import DangerousInnerHtml from 'components/dangerous-inner-html/dangerous-inner-html';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import CampaignStatisticDestroyModal from './CampaignStatisticDestroyModal';
import CampaignStatisticUpdateModal from './CampaignStatisticUpdateModal';

const CampaignStatisticCard = ({ campaignStatistic }) => (
  <VisibleToSponsorIndicator>
    <div className="card my-2">
      <div className="row">
        <div className="col">
          <CampaignStatisticUpdateModal campaignStatistic={campaignStatistic} />
        </div>
        <div className="col-auto">
          <CampaignStatisticDestroyModal
            campaignStatisticId={campaignStatistic.id}
          />
        </div>
      </div>
      <div className="row card-body text-center">
        <div className="col">
          <CircledIcon
            backgroundColor="dark"
            className="mb-2"
            data-test="statistic-icon"
            size={28}
            value={campaignStatistic.iconName}
          />
          <h2 data-test="statistic-number">{campaignStatistic.number}</h2>
          <h5 data-test="statistic-name">{campaignStatistic.name}</h5>
          <div className="text-muted" data-test="statistic-description">
            <DangerousInnerHtml html={campaignStatistic.description} />
          </div>
        </div>
      </div>
    </div>
  </VisibleToSponsorIndicator>
);

CampaignStatisticCard.propTypes = {
  campaignStatistic: LeagueSidePropTypes.campaignStatistic.isRequired,
};

export default CampaignStatisticCard;
