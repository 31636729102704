import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { sortByName } from 'utils/sort';
import Icon from 'components/Icon';

export const SocialMediaUnwrapped = ({
  handleSocialMediaChange,
  addChanges,
  socialMedia,
  socialMediaChannels,
  socialMediaFollowingSize,
  emailListSize,
}) => {
  const sortedSocialMediaChannels = sortByName(socialMediaChannels);
  function renderRow(leagueSocialMedia) {
    return (
      <tr
        key={leagueSocialMedia.id || leagueSocialMedia.temporaryId}
        data-test="social-media"
      >
        <td>
          <select
            className="form-control"
            data-test="social-media-channel"
            onChange={(event) =>
              handleSocialMediaChange('update socialMedia channel', 'update', {
                id: leagueSocialMedia.id || leagueSocialMedia.temporaryId,
                channelId: Number(event.target.value),
              })
            }
            value={leagueSocialMedia.channel.id}
          >
            {sortedSocialMediaChannels.map((channel) => (
              <option key={channel.id} value={channel.id}>
                {channel.name}
              </option>
            ))}
          </select>
        </td>
        <td>
          <input
            className="form-control"
            data-test="social-media-url"
            onChange={(event) =>
              handleSocialMediaChange('update socialMedia url', 'update', {
                id: leagueSocialMedia.id || leagueSocialMedia.temporaryId,
                url: event.target.value,
              })
            }
            value={leagueSocialMedia.url}
          />
        </td>
        <td>
          <select
            className="form-control"
            data-test="social-media-can-post"
            onChange={(event) =>
              handleSocialMediaChange('update socialMedia canPost', 'update', {
                id: leagueSocialMedia.id || leagueSocialMedia.temporaryId,
                canPost: event.target.value === 'Yes',
              })
            }
            value={leagueSocialMedia.canPost ? 'Yes' : 'No'}
          >
            <option>Yes</option>
            <option>No</option>
          </select>
        </td>
        <td>
          <button
            className="btn btn-link text-danger px-0"
            data-test="remove-social-media"
            onClick={() =>
              handleSocialMediaChange('remove socialMedia', 'remove', {
                id: leagueSocialMedia.id || leagueSocialMedia.temporaryId,
              })
            }
            type="button"
          >
            Remove
          </button>
        </td>
      </tr>
    );
  }

  return (
    <>
      <div className="row align-items-center">
        <div className="col font-weight-bold">Digital Reach</div>
        <div className="col-auto text-right">
          <button
            className="btn btn-secondary btn-sm"
            data-test="add-social-media"
            onClick={() =>
              handleSocialMediaChange('create socialMedia', 'create', {
                socialMedia: {
                  canPost: false,
                  channel: { id: sortedSocialMediaChannels[0].id },
                  temporaryId: Math.random(),
                  url: '',
                },
              })
            }
            type="button"
          >
            <Icon value="plus" />
            Add Social Media
          </button>
        </div>
      </div>
      <table className="table table-borderless mb-2">
        <thead>
          <tr>
            <th className="pb-0">Social Media Following Size</th>
            <th className="pb-0">Email List Size</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                className="form-control"
                data-test="social-media-following-size"
                type="number"
                onChange={(event) =>
                  addChanges('update followingSize', {
                    socialMediaFollowingSize: Number(event.target.value),
                  })
                }
                value={socialMediaFollowingSize}
              />
            </td>
            <td>
              <input
                className="form-control"
                data-test="email-list-size"
                type="number"
                onChange={(event) =>
                  addChanges('update emailListSize', {
                    emailListSize: Number(event.target.value),
                  })
                }
                value={emailListSize}
              />
            </td>
          </tr>
        </tbody>
      </table>
      {!socialMedia.length ? null : (
        <table className="table table-borderless mb-2">
          <thead>
            <tr>
              <th className="pb-0">Platform</th>
              <th className="pb-0">Organization URL</th>
              <th className="pb-0">Sponsor may post?</th>
              <th aria-label="remove" />
            </tr>
          </thead>
          <tbody>{socialMedia.map(renderRow)}</tbody>
        </table>
      )}
    </>
  );
};

SocialMediaUnwrapped.propTypes = {
  handleSocialMediaChange: PropTypes.func.isRequired,
  addChanges: PropTypes.func.isRequired,
  socialMedia: LeagueSidePropTypes.organizationSocialMediaChannels.isRequired,
  socialMediaChannels: PropTypes.arrayOf(
    LeagueSidePropTypes.organizationSocialMediaChannels,
  ).isRequired,
  socialMediaFollowingSize: PropTypes.number.isRequired,
  emailListSize: PropTypes.number.isRequired,
};

const QUERY = gql`
  query SocialMediaChannels {
    socialMediaChannels {
      id
      name
    }
  }
`;

const SocialMedia = ({
  handleSocialMediaChange,
  addChanges,
  socialMedia,
  socialMediaFollowingSize,
  emailListSize,
}) => (
  <Query fetchPolicy="network-only" query={QUERY}>
    {({ data }) => (
      <SocialMediaUnwrapped
        handleSocialMediaChange={handleSocialMediaChange}
        addChanges={addChanges}
        socialMedia={socialMedia}
        socialMediaChannels={(data && data.socialMediaChannels) || []}
        socialMediaFollowingSize={socialMediaFollowingSize}
        emailListSize={emailListSize}
      />
    )}
  </Query>
);

SocialMedia.propTypes = {
  handleSocialMediaChange: PropTypes.func.isRequired,
  addChanges: PropTypes.func.isRequired,
  socialMedia: LeagueSidePropTypes.organizationSocialMediaChannels,
  socialMediaFollowingSize:
    LeagueSidePropTypes.organization.socialMediaFollowingSize,
  emailListSize: LeagueSidePropTypes.organization.emailListSize,
};

SocialMedia.defaultProps = {
  socialMedia: [],
  socialMediaFollowingSize: 0,
  emailListSize: 0,
};

export default SocialMedia;
