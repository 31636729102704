import { equals, map, toPairs } from 'ramda';
import en from 'i18n-iso-countries/langs/en.json';
import countries from 'i18n-iso-countries';

countries.registerLocale(en);

export const COUNTRY_NAMES_BY_ALPHA2_CODE = countries.getNames('en');

export const COUNTRIES = map(
  ([alpha2Code, name]) => ({ alpha2Code, name }),
  toPairs(COUNTRY_NAMES_BY_ALPHA2_CODE),
);

export const equalsUS = equals('US');
