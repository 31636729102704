import React from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { renderLastLogin } from 'utils/formatDate';
import { SPONSOR_DASHBOARD_PATH } from 'utils/paths';
import NewWindowLink from 'components/NewWindowLink';

const UsersTableAdditionalColumns = ({ user }) => {
  const { id: userId, lastLogin } = user;

  return (
    <>
      <td>{renderLastLogin(lastLogin)}</td>
      <td className="text-center">
        <NewWindowLink to={`${SPONSOR_DASHBOARD_PATH}?userId=${userId}`} />
      </td>
    </>
  );
};

UsersTableAdditionalColumns.propTypes = {
  user: LeagueSidePropTypes.user.isRequired,
};

export default UsersTableAdditionalColumns;
