import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Paragraph = styled.p`
  font-size: 1.5rem
    ${({ bold }) =>
      bold &&
      css`
        font-size: 1.6rem;
        font-weight: bold;
      `};
`;

Paragraph.propTypes = {
  bold: PropTypes.bool,
};

Paragraph.defaultProps = {
  bold: false,
};

export default Paragraph;
