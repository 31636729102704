import * as React from 'react';
import { formatToDollar } from 'utils/formatNumber';
import SponsorshipInsightReportType from 'types/sponsorshipInsightReport';
import RestrictTo from 'components/RestrictTo';
import Badge from 'components/Badge';

interface Props {
  sponsorshipInsightReport?: SponsorshipInsightReportType;
}

const SponsorshipInsightReport: React.FunctionComponent<Props> = ({
  sponsorshipInsightReport,
}: Props) => {
  if (!sponsorshipInsightReport) return null;

  const { averageConfirmedValue, confirmedCount } = sponsorshipInsightReport;

  return (
    <RestrictTo roles={['admin']}>
      <span data-test="sponsorship-insight-report" className="text-nowrap">
        <Badge
          badgeType="average-sponsorship-value"
          title="Average Sponsorship Value"
          size="sm"
        >
          ASV&nbsp;
          {formatToDollar(averageConfirmedValue)}
        </Badge>
        <Badge
          badgeType="confirmed-sponsorships"
          title="Confirmed Sponsorships"
          size="sm"
        >
          CS&nbsp;
          {confirmedCount}
        </Badge>
      </span>
    </RestrictTo>
  );
};

SponsorshipInsightReport.defaultProps = {
  sponsorshipInsightReport: undefined,
};

export default SponsorshipInsightReport;
