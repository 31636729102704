import { without } from 'ramda';

export enum DataConfidence {
  Speculative = 'speculative',
  Incomplete = 'incomplete',
  Stale = 'stale',
  Complete = 'complete',
  Verified = 'verified',
  NotAGoodFit = 'not_a_good_fit',
  AddedFromProspecting = 'added_from_prospecting',
  AddedByorganization = 'added_by_organization',
  Ended = 'ended',
  CreatedFromOtherSp = 'created_from_other_sp',
}

const dataConfidences = Object.values(DataConfidence);
export const filterableDataConfidences = without(
  ['not_a_good_fit', 'ended'],
  dataConfidences,
);
