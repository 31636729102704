/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20Sponsorships/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/mojosports/Teamsnap%20Sponsorships/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'sponsprod' | 'sponsdev';

export const ApiKey: Record<Environment, string> = {
  sponsprod: 'd1701c31b47fb9df213c58017a7db808',
  sponsdev: 'e7e1b752ca7e4703c716cdc0c7522d94'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: '082e4cb5-f70c-4fa9-9261-3934abf40610'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * The email address of the user who is filling out the form
   */
  email?: string;
  last_browser_name?: string;
}

export interface DeepLinkOpenedProperties {
  url: string;
  utm_campaign: string;
  utm_medium: string;
  utm_source: string;
}

export interface SponsorshipFormAbandonedProperties {
  fieldhouse_campaign_id: string;
  fieldhouse_campaign_name: string;
  fieldhouse_form_chapter_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Introduction, Sponsor Benefits, Community Impact, Primary Contact, Programs, Play Locations, Social & Email, Non-Profit Status, Additional Information, Tournament Information, Jerseys, Warmups, Banners, Product Distribution, Seminars, Table & Product, Professional Photography, Logo on Website, Surveys, Email & Social Media, Rewarded Add-Ons |
   */
  fieldhouse_form_chapter_name:
    | "Introduction"
    | "Sponsor Benefits"
    | "Community Impact"
    | "Primary Contact"
    | "Programs"
    | "Play Locations"
    | "Social & Email"
    | "Non-Profit Status"
    | "Additional Information"
    | "Tournament Information"
    | "Jerseys"
    | "Warmups"
    | "Banners"
    | "Product Distribution"
    | "Seminars"
    | "Table & Product"
    | "Professional Photography"
    | "Logo on Website"
    | "Surveys"
    | "Email & Social Media"
    | "Rewarded Add-Ons";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Interest, Season, Tournament Information, Branded Collateral, Experiences, Digital Assets, Rewarded Add-Ons |
   */
  fieldhouse_form_chapter_section:
    | "Interest"
    | "Season"
    | "Tournament Information"
    | "Branded Collateral"
    | "Experiences"
    | "Digital Assets"
    | "Rewarded Add-Ons";
  fieldhouse_form_url: string;
  fieldhouse_offer_id: string;
  fieldhouse_organization_id: string;
  sponsorship_application_id: string;
}

export interface SponsorshipFormStepCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  count_play_locations_entered: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  count_programs_entered: number;
  fieldhouse_campaign_id: string;
  fieldhouse_campaign_name: string;
  fieldhouse_form_chapter_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Introduction, Sponsor Benefits, Community Impact, Primary Contact, Programs, Play Locations, Social & Email, Non-Profit Status, Additional Information, Tournament Information, Jerseys, Warmups, Banners, Product Distribution, Seminars, Table & Product, Professional Photography, Logo on Website, Surveys, Email & Social Media, Rewarded Add-Ons |
   */
  fieldhouse_form_chapter_name:
    | "Introduction"
    | "Sponsor Benefits"
    | "Community Impact"
    | "Primary Contact"
    | "Programs"
    | "Play Locations"
    | "Social & Email"
    | "Non-Profit Status"
    | "Additional Information"
    | "Tournament Information"
    | "Jerseys"
    | "Warmups"
    | "Banners"
    | "Product Distribution"
    | "Seminars"
    | "Table & Product"
    | "Professional Photography"
    | "Logo on Website"
    | "Surveys"
    | "Email & Social Media"
    | "Rewarded Add-Ons";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Interest, Season, Tournament Information, Branded Collateral, Experiences, Digital Assets, Rewarded Add-Ons |
   */
  fieldhouse_form_chapter_section:
    | "Interest"
    | "Season"
    | "Tournament Information"
    | "Branded Collateral"
    | "Experiences"
    | "Digital Assets"
    | "Rewarded Add-Ons";
  fieldhouse_form_url: string;
  fieldhouse_offer_id: string;
  fieldhouse_organization_id: string;
  sponsorship_application_id: string;
}

export interface SponsorshipFormViewedProperties {
  fieldhouse_campaign_id: string;
  fieldhouse_campaign_name: string;
  fieldhouse_form_chapter_id: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Introduction, Sponsor Benefits, Community Impact, Primary Contact, Programs, Play Locations, Social & Email, Non-Profit Status, Additional Information, Tournament Information, Jerseys, Warmups, Banners, Product Distribution, Seminars, Table & Product, Professional Photography, Logo on Website, Surveys, Email & Social Media, Rewarded Add-Ons |
   */
  fieldhouse_form_chapter_name:
    | "Introduction"
    | "Sponsor Benefits"
    | "Community Impact"
    | "Primary Contact"
    | "Programs"
    | "Play Locations"
    | "Social & Email"
    | "Non-Profit Status"
    | "Additional Information"
    | "Tournament Information"
    | "Jerseys"
    | "Warmups"
    | "Banners"
    | "Product Distribution"
    | "Seminars"
    | "Table & Product"
    | "Professional Photography"
    | "Logo on Website"
    | "Surveys"
    | "Email & Social Media"
    | "Rewarded Add-Ons";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Interest, Season, Tournament Information, Branded Collateral, Experiences, Digital Assets, Rewarded Add-Ons |
   */
  fieldhouse_form_chapter_section:
    | "Interest"
    | "Season"
    | "Tournament Information"
    | "Branded Collateral"
    | "Experiences"
    | "Digital Assets"
    | "Rewarded Add-Ons";
  /**
   * set TRUE if the form includes custom questions
   *
   * set FALSE if the form does not include custom questions
   */
  fieldhouse_form_contains_custom_questions: boolean;
  fieldhouse_form_url: string;
  fieldhouse_offer_id: string;
  fieldhouse_organization_id: string;
  /**
   * The percentage complete of the form at the time it is viewed by the user.
   */
  form_percentage_complete: string;
  sponsorship_application_id: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeepLinkOpened implements BaseEvent {
  event_type = 'Deep Link Opened';

  constructor(
    public event_properties: DeepLinkOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SponsorshipFormAbandoned implements BaseEvent {
  event_type = 'Sponsorship Form Abandoned';

  constructor(
    public event_properties: SponsorshipFormAbandonedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SponsorshipFormStepCompleted implements BaseEvent {
  event_type = 'Sponsorship Form Step Completed';

  constructor(
    public event_properties: SponsorshipFormStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SponsorshipFormViewed implements BaseEvent {
  event_type = 'Sponsorship Form Viewed';

  constructor(
    public event_properties: SponsorshipFormViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Deep Link Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20Sponsorships/events/main/latest/Deep%20Link%20Opened)
   *
   * Event to track when a deep link is the cause of a user opening a screen within the app
   *
   * Owner: Jessica Irvin
   *
   * @param properties The event's properties (e.g. url)
   * @param options Amplitude event options.
   */
  deepLinkOpened(
    properties: DeepLinkOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeepLinkOpened(properties), options);
  }

  /**
   * Sponsorship Form Abandoned
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20Sponsorships/events/main/latest/Sponsorship%20Form%20Abandoned)
   *
   * Event to track when a user abandons the sponsorship form before completion of all steps
   *
   * Owner: Jessica Irvin
   *
   * @param properties The event's properties (e.g. fieldhouse_campaign_id)
   * @param options Amplitude event options.
   */
  sponsorshipFormAbandoned(
    properties: SponsorshipFormAbandonedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SponsorshipFormAbandoned(properties), options);
  }

  /**
   * Sponsorship Form Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20Sponsorships/events/main/latest/Sponsorship%20Form%20Step%20Completed)
   *
   * A user completes a form chapter and clicks Next
   *
   * Owner: Jessica Irvin
   *
   * @param properties The event's properties (e.g. count_play_locations_entered)
   * @param options Amplitude event options.
   */
  sponsorshipFormStepCompleted(
    properties: SponsorshipFormStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SponsorshipFormStepCompleted(properties), options);
  }

  /**
   * Sponsorship Form Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/mojosports/Teamsnap%20Sponsorships/events/main/latest/Sponsorship%20Form%20Viewed)
   *
   * Event indicating that a user has viewed a sponsorship form
   *
   * Owner: Jessica Irvin
   *
   * @param properties The event's properties (e.g. fieldhouse_campaign_id)
   * @param options Amplitude event options.
   */
  sponsorshipFormViewed(
    properties: SponsorshipFormViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SponsorshipFormViewed(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
