import React from 'react';
import { descend, path, sortWith, isEmpty } from 'ramda';
import { seasonOrdinal } from 'utils/campaign';
import { Query } from 'react-apollo';

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { getUserIdFrom, wrapWithUserId } from 'utils/authentication';
import Card from 'components/Card';
import Page from 'components/Page';
import SponsorDashboardWrapper from 'components/SponsorDashboardWrapper';
import SponsorCampaignsTable from './sponsorDashboardMain/SponsorCampaignsTable';

const emptyMessage = (
  <p className="text-center m-4" data-test="empty-message">
    This sponsor doesn&#39;t have any campaigns right now.
  </p>
);

const sortWithYearSeason = sortWith([
  descend(path(['year'])),
  descend(seasonOrdinal),
]);

export const SponsorDashboardMainUnwrapped = ({
  selectedSponsor,
  shouldRenderEditSeasonalDashboardButton,
}) => {
  const { campaigns } = selectedSponsor;
  const sortedCampaigns = sortWithYearSeason(campaigns);
  const allCampaigns = {};

  const formatSeasonYearTitle = (title) =>
    title.charAt(0).toUpperCase() + title.slice(1).replace('-', ' ');

  for (let i = 0; i < sortedCampaigns.length; i += 1) {
    const seasonYearKey = `${sortedCampaigns[i].season}-${sortedCampaigns[i].year}`;
    const seasonYearKeyExists = seasonYearKey in allCampaigns;

    if (seasonYearKeyExists) {
      allCampaigns[seasonYearKey].push(sortedCampaigns[i]);
    } else {
      allCampaigns[seasonYearKey] = [];
      allCampaigns[seasonYearKey].push(sortedCampaigns[i]);
    }
  }

  return (
    <>
      {isEmpty(campaigns)
        ? emptyMessage
        : Object.keys(allCampaigns).map((key) => (
            <Card verticalMargins={0} id="mb-40-important">
              <SponsorCampaignsTable
                campaigns={allCampaigns[key]}
                title={formatSeasonYearTitle(key)}
                shouldRenderEditSeasonalDashboardButton={
                  shouldRenderEditSeasonalDashboardButton
                }
                sponsorId={selectedSponsor.id}
              />
            </Card>
          ))}
    </>
  );
};

SponsorDashboardMainUnwrapped.propTypes = {
  selectedSponsor: LeagueSidePropTypes.sponsor.isRequired,
  shouldRenderEditSeasonalDashboardButton: PropTypes.bool,
};

SponsorDashboardMainUnwrapped.defaultProps = {
  shouldRenderEditSeasonalDashboardButton: false,
};

const SponsorDashboardMainWrappedWithView = ({ sponsors, userId }) => (
  <SponsorDashboardWrapper sponsors={sponsors} userId={userId}>
    {({ selectedSponsor }) => (
      <SponsorDashboardMainUnwrapped selectedSponsor={selectedSponsor} />
    )}
  </SponsorDashboardWrapper>
);

SponsorDashboardMainWrappedWithView.propTypes = {
  sponsors: LeagueSidePropTypes.sponsors.isRequired,
  userId: PropTypes.number,
};

SponsorDashboardMainWrappedWithView.defaultProps = {
  userId: null,
};

const SponsorDashboardMainWrappedWithUserId = wrapWithUserId(
  SponsorDashboardMainWrappedWithView,
);

const QUERY = gql`
  query SponsorDashboard($userId: Int) {
    mySponsors(userId: $userId) {
      id
      campaigns(statuses: [active, complete]) {
        id
        name
        magicToken
        season
        status
        year
      }
      name
    }
  }
`;

const SponsorDashboardMain = ({ location }) => (
  <Query
    fetchPolicy="network-only"
    query={QUERY}
    variables={{ userId: getUserIdFrom(location.search) }}
  >
    {({ data, error }) => {
      if (error) throw error;

      const sponsors = data && data.mySponsors;

      return sponsors ? (
        <SponsorDashboardMainWrappedWithUserId sponsors={sponsors} />
      ) : (
        <Page loaded={false} />
      );
    }}
  </Query>
);

SponsorDashboardMain.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default SponsorDashboardMain;
