import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { isEmpty, isNil, map } from 'ramda';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import Loader from 'components/Loader';
import { useSeasonalDashboardContext } from '../../context/SeasonalDashboardContext';
import Testimonials from './Testimonials';

const query = gql`
  query SeasonalDashboard($season: String!, $year: Int!) {
    seasonalDashboard {
      sponsor {
        id
        campaigns(season: $season, year: $year) {
          id
          name
          testimonials {
            id
            attribution
            quote
          }
        }
      }
    }
  }
`;

export function TestimonialSectionUnwrapped({ campaigns }) {
  const row = (campaign) => (
    <>
      {!isEmpty(campaign.testimonials) && (
        <div
          key={campaign.id}
          className="mb-4 pb-4 border-bottom"
          data-test={`testimonials-${campaign.id}`}
        >
          <h2 className="mb-2">{campaign.name}</h2>
          <Testimonials testimonials={campaign.testimonials.slice(0, 3)} />
        </div>
      )}
    </>
  );

  return <div>{map(row, campaigns)}</div>;
}

TestimonialSectionUnwrapped.propTypes = {
  campaigns: PropTypes.arrayOf(LeagueSidePropTypes.campaign),
};

TestimonialSectionUnwrapped.defaultProps = {
  campaigns: [],
};

const TestimonialSection = () => {
  const { token, season, year } = useSeasonalDashboardContext();
  const { data, loading } = useQuery(query, {
    variables: { season, year },
    context: {
      headers: {
        Authorization: token,
      },
    },
  });

  if (loading) return <Loader />;

  const campaigns = data && data.seasonalDashboard.sponsor.campaigns;
  const noCampaigns = isEmpty(campaigns) || isNil(campaigns);
  const campaignTestimonialsExist = !isEmpty(
    campaigns.filter((campaign) => !isEmpty(campaign.testimonials)),
  );

  if (noCampaigns)
    return (
      <h1
        className="d-flex justify-content-center"
        data-test="no-results-message"
      >
        {' '}
        Sorry! There are no campaigns yet.
      </h1>
    );

  return campaignTestimonialsExist ? (
    <>
      <h1 className="mb-4">Testimonials</h1>
      <TestimonialSectionUnwrapped campaigns={campaigns} />
    </>
  ) : (
    <h1
      className="d-flex justify-content-center"
      data-test="no-results-message"
    >
      {' '}
      Sorry! Testimonials have yet to be collected!{' '}
    </h1>
  );
};

export default TestimonialSection;
