import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  filter,
  identity,
  flatten,
  lensPath,
  merge,
  pluck,
  prop,
  set,
} from 'ramda';
import React, { useState, useEffect } from 'react';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { findSponsorablePropertyId } from 'utils/offer';
import { pluralizedTense } from 'utils/string';
import { getGeometries, getRadii } from 'utils/location';
import {
  LOCAL_STORAGE_KEY,
  DEFAULT_STATE,
  getStateFromLocalStorage,
  statusProp,
} from 'utils/statusVisibility';
import Map from 'components/Map';
import ProspectsTable from 'components/sponsorablePropertyTables/ProspectsTable';
import QualifiedTable from 'components/sponsorablePropertyTables/QualifiedTable';
import ConfirmedTable from 'components/sponsorablePropertyTables/ConfirmedTable';
import ProposedTable from 'components/sponsorablePropertyTables/ProposedTable';
import RemovedTable from 'components/sponsorablePropertyTables/RemovedTable';
import AvailableTable from 'components/sponsorablePropertyTables/AvailableTable';

const compact = filter(identity);
const pluckSponsorableProperties = pluck('sponsorableProperty');

const ProspectLocations = ({
  locations,
  refetchLocation,
  campaign,
  locationFilter,
  availableSponsorableProperties,
  confirmedOffers,
  proposedOffers,
  prospectingOffers,
  qualifiedOffers,
  removedOffers,
  onAddProspects,
  onMarkAsQualified,
  onProposedSendOfferEmails,
  onQualifiedSendOfferEmails,
  onRemoveProposedFromCampaign,
  onRemoveProspectingFromCampaign,
  onRemoveQualifedFromCampaign,
  onSendPreQualSurvey,
}) => {
  const { radius } = locationFilter;
  const { id: campaignId } = campaign;
  const [state, setState] = useState(getStateFromLocalStorage());

  const stateForCampaign = merge(DEFAULT_STATE, state[campaignId]);
  const visibility = (status) => lensPath([campaignId, statusProp(status)]);
  const isVisible = (status) => prop(statusProp(status), stateForCampaign);

  const updateLocalStorageState = () => {
    const value = JSON.stringify(state);

    localStorage.setItem(LOCAL_STORAGE_KEY, value);
  };
  useEffect(updateLocalStorageState);

  const renderSidebar = () => {
    const renderCheckbox = (status, label, length) => (
      <div
        className={classNames('form-check d-inline-block mr-2', {
          'text-muted': !length,
        })}
      >
        <input
          checked={isVisible(status)}
          className="form-check-input"
          id={`${status}-toggle`}
          onChange={(event) =>
            setState(set(visibility(status), event.target.checked))
          }
          type="checkbox"
        />
        <label
          className="form-check-label align-middle"
          htmlFor={`${status}-toggle`}
        >
          {`${length} ${label}`}
        </label>
      </div>
    );

    return (
      <div className="prospect-page__sidebar">
        <strong className="d-block mb-4">
          {renderCheckbox(
            'available',
            'Available',
            availableSponsorableProperties.length,
          )}
          {renderCheckbox(
            'prospects',
            pluralizedTense(prospectingOffers.length, 'Prospect'),
            prospectingOffers.length,
          )}
          {renderCheckbox('qualified', 'Qualified', qualifiedOffers.length)}
          {renderCheckbox('proposed', 'Proposed', proposedOffers.length)}
          {renderCheckbox('confirmed', 'Confirmed', confirmedOffers.length)}
        </strong>
        {stateForCampaign.availableVisible && (
          <AvailableTable
            campaignId={campaignId}
            onAddProspects={onAddProspects}
            sponsorableProperties={availableSponsorableProperties}
          />
        )}
        {stateForCampaign.prospectsVisible && (
          <ProspectsTable
            campaignId={campaignId}
            campaignName={campaign.name}
            hasSurveyQuestions={campaign.hasSurveyQuestions}
            offers={prospectingOffers}
            onAddProspects={onAddProspects || refetchLocation}
            onMarkAsQualified={onMarkAsQualified || refetchLocation}
            onRemoveFromCampaign={
              onRemoveProspectingFromCampaign || refetchLocation
            }
            onSendPreQualSurvey={onSendPreQualSurvey || refetchLocation}
            sponsorId={campaign.sponsor.id}
          />
        )}
        {stateForCampaign.qualifiedVisible && (
          <QualifiedTable
            offers={qualifiedOffers}
            onRemoveFromCampaign={
              onRemoveQualifedFromCampaign || refetchLocation
            }
            onSendOfferEmails={onQualifiedSendOfferEmails || refetchLocation}
          />
        )}
        {stateForCampaign.proposedVisible && (
          <ProposedTable
            offers={proposedOffers}
            onRemoveFromCampaign={
              onRemoveProposedFromCampaign || refetchLocation
            }
            onSendOfferEmails={onProposedSendOfferEmails || refetchLocation}
          />
        )}
        {stateForCampaign.confirmedVisible && (
          <ConfirmedTable offers={confirmedOffers} />
        )}
        {stateForCampaign.removedVisible && !!removedOffers.length && (
          <RemovedTable offers={removedOffers} />
        )}
        {!!removedOffers.length && (
          <p className="text-center">
            <button
              className="btn btn-link text-muted"
              id="removed-toggle"
              onClick={() =>
                setState(set(visibility('removed'), !isVisible('removed')))
              }
              type="button"
            >
              {`${isVisible('removed') ? 'Hide' : 'Show'} ${
                removedOffers.length
              } Removed`}
            </button>
          </p>
        )}
      </div>
    );
  };

  const renderSummary = () => {
    const radii = getRadii(locations, radius);
    const campaignGeometries = getGeometries(locations);
    const addedOffers = flatten([
      confirmedOffers,
      proposedOffers,
      prospectingOffers,
      qualifiedOffers,
    ]);
    const addedSponsorablePropertyIds = new Set(
      addedOffers.map(findSponsorablePropertyId),
    );
    const removedSponsorablePropertyIds = new Set(
      removedOffers.map(findSponsorablePropertyId),
    );
    const sponsorableProperties = flatten(
      compact([
        stateForCampaign.availableVisible && availableSponsorableProperties,
        stateForCampaign.prospectsVisible &&
          pluckSponsorableProperties(prospectingOffers),
        stateForCampaign.qualifiedVisible &&
          pluckSponsorableProperties(qualifiedOffers),
        stateForCampaign.proposedVisible &&
          pluckSponsorableProperties(proposedOffers),
        stateForCampaign.confirmedVisible &&
          pluckSponsorableProperties(confirmedOffers),
        stateForCampaign.removedVisible &&
          pluckSponsorableProperties(removedOffers),
      ]),
    );

    return (
      <div className="summary">
        <Map
          addedSponsorablePropertyIds={addedSponsorablePropertyIds}
          campaignGeometries={campaignGeometries}
          removedSponsorablePropertyIds={removedSponsorablePropertyIds}
          sponsorableProperties={sponsorableProperties}
          radii={radii}
        />
      </div>
    );
  };

  return (
    <div className="prospect-page__sidebar-and-summary">
      {renderSidebar()}
      {renderSummary()}
    </div>
  );
};

ProspectLocations.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    hasSurveyQuestions: PropTypes.bool.isRequired,
    name: PropTypes.number.isRequired,
    sponsor: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  locationFilter: LeagueSidePropTypes.locationFilter,
  locations: LeagueSidePropTypes.locations,
  availableSponsorableProperties: LeagueSidePropTypes.leagues,
  confirmedOffers: LeagueSidePropTypes.offers,
  onAddProspects: PropTypes.func,
  onMarkAsQualified: PropTypes.func,
  onProposedSendOfferEmails: PropTypes.func,
  onQualifiedSendOfferEmails: PropTypes.func,
  onRemoveProposedFromCampaign: PropTypes.func,
  onRemoveProspectingFromCampaign: PropTypes.func,
  onRemoveQualifedFromCampaign: PropTypes.func,
  onSendPreQualSurvey: PropTypes.func,
  proposedOffers: LeagueSidePropTypes.offers,
  prospectingOffers: LeagueSidePropTypes.offers,
  qualifiedOffers: LeagueSidePropTypes.offers,
  removedOffers: LeagueSidePropTypes.offers,
  refetchLocation: PropTypes.func,
};

ProspectLocations.defaultProps = {
  locations: [],
  locationFilter: null,
  availableSponsorableProperties: [],
  confirmedOffers: [],
  onAddProspects: undefined,
  onMarkAsQualified: undefined,
  onProposedSendOfferEmails: undefined,
  onQualifiedSendOfferEmails: undefined,
  onRemoveProposedFromCampaign: undefined,
  onRemoveProspectingFromCampaign: undefined,
  onRemoveQualifedFromCampaign: undefined,
  onSendPreQualSurvey: undefined,
  proposedOffers: [],
  prospectingOffers: [],
  qualifiedOffers: [],
  removedOffers: [],
  refetchLocation: () => {},
};

export default ProspectLocations;
