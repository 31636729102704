import {
  addIndex,
  complement,
  isNil,
  map,
  pipe,
  reject,
  take,
  uniq,
} from 'ramda';

export const compact = reject(isNil);

const isFalsy = complement(Boolean);
export const rejectFalsy = reject(isFalsy);

export const mapWithIndex = addIndex(map);

export const takeFive = pipe(uniq, compact, take(5));
