import * as React from 'react';
import { partition, prop } from 'ramda';
import GenericCreativeContent from 'types/genericCreativeContent';
import GenericCreativeFile from 'types/genericCreativeFile';
import { VerificationDeadlineWithAdditionalStatuses } from 'types/verificationDeadline';
import VerificationDeadlinesSection from './VerificationDeadlinesSection';
import MissingCreativesDeadlinesSection from './MissingCreativesDeadlinesSection';
import PendingVerificationDeadlinesSection from './PendingVerificationDeadlinesSection';

interface VerificationDeadline
  extends VerificationDeadlineWithAdditionalStatuses {
  creativeContents: GenericCreativeContent[];
  creativeFiles: GenericCreativeFile[];
  renderedVerificationReminderInstructions: string;
}

interface Props {
  verificationDeadlines: VerificationDeadline[];
  refetch: Function;
  presenterFactory: Object;
}

const VerificationDeadlinesSections: React.FunctionComponent<Props> = ({
  verificationDeadlines,
  refetch,
  presenterFactory,
}: Props) => {
  const [missingCreativeDeadlines, hasCreativeDeadlines] = partition(
    prop('missingCreatives'),
    verificationDeadlines,
  );
  const [pendingVerificationDeadlines, nonPendingDeadlines] = partition(
    prop('anyPendingVerificationImages'),
    hasCreativeDeadlines,
  );
  const [verifiedDeadlines, unverifiedDeadlines] = partition(
    prop('verified'),
    nonPendingDeadlines,
  );
  const [didNotCompleteDeadlines, toCompleteDeadlines] = partition(
    prop('didNotComplete'),
    unverifiedDeadlines,
  );
  const [overdueDeadlines, upcomingDeadlines] = partition(
    prop('overdue'),
    toCompleteDeadlines,
  );

  return (
    <>
      <MissingCreativesDeadlinesSection
        verificationDeadlines={missingCreativeDeadlines}
      />
      <VerificationDeadlinesSection
        header="Overdue"
        verificationDeadlines={overdueDeadlines}
        refetch={refetch}
        presenterFactory={presenterFactory}
      />
      <VerificationDeadlinesSection
        header="Upcoming"
        verificationDeadlines={upcomingDeadlines}
        refetch={refetch}
        presenterFactory={presenterFactory}
      />
      <VerificationDeadlinesSection
        header="Did Not Complete"
        verificationDeadlines={didNotCompleteDeadlines}
        refetch={refetch}
        presenterFactory={presenterFactory}
      />
      <PendingVerificationDeadlinesSection
        verificationDeadlines={pendingVerificationDeadlines}
      />
      <VerificationDeadlinesSection
        header="Completed"
        verificationDeadlines={verifiedDeadlines}
        refetch={refetch}
        presenterFactory={presenterFactory}
      />
    </>
  );
};

export default VerificationDeadlinesSections;
