import * as React from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import HoverTable from 'components/HoverTable';
import Card from 'components/Card';
import RemoveOrganizationCapabilityModal from 'components/RemoveOrganizationCapability';

const DefaultCapabilityTable = ({ defaultCapabilites }) => {
  const row = (defaultCapability) => {
    const { capabilityDetail } = defaultCapability;

    return (
      <tr
        key={defaultCapability.id}
        data-test={`defaultCapability-${defaultCapability.id}`}
      >
        <td>{`${defaultCapability.description}`}</td>
        {capabilityDetail ? (
          <>
            <td>{`${capabilityDetail.price}`}</td>
            <td>{capabilityDetail.maxForSale}</td>
          </>
        ) : (
          <>
            <td aria-label="capability price" />
            <td aria-label="quantity for sale" />
          </>
        )}
        <td className="d-flex justify-content-end">
          <RemoveOrganizationCapabilityModal
            organizationCapability={defaultCapability}
          />
        </td>
      </tr>
    );
  };

  return (
    <>
      <Card>
        <h3 data-test="default-capability-table-header">
          {defaultCapabilites[0].capability.name}
        </h3>
        <HoverTable>
          <thead>
            <tr className="th-font-family-11">
              <th scope="col" className="align-middle">
                Description
              </th>
              <th scope="col" className="align-middle">
                Price
              </th>
              <th scope="col" className="align-middle">
                Quantity
              </th>
              <th scope="col" className="d-flex justify-content-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{map(row, defaultCapabilites)}</tbody>
        </HoverTable>
      </Card>
    </>
  );
};

DefaultCapabilityTable.propTypes = {
  defaultCapabilites: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.object))
    .isRequired,
};

export default DefaultCapabilityTable;
