import React from 'react';
import PropTypes from 'prop-types';
import SaveButton from './SaveButton';

const SaveFooter = ({ disabled, large, save, saving, saved }) => (
  <div className="mt-2 mb-2 mr-2 text-right">
    <SaveButton
      disabled={disabled}
      large={large}
      save={save}
      saved={saved}
      saving={saving}
    />
  </div>
);

SaveFooter.propTypes = {
  disabled: PropTypes.bool,
  large: PropTypes.bool,
  save: PropTypes.func,
  saved: PropTypes.bool,
  saving: PropTypes.bool,
};

SaveFooter.defaultProps = {
  disabled: false,
  large: undefined,
  save: () => {},
  saved: false,
  saving: false,
};

export default SaveFooter;
