import * as React from 'react';
import { Link } from 'react-router-dom';
import { CampaignStatus } from 'types/campaign';
import { OfferStatus } from 'types/offer';
import { Season } from 'types/season';
import { seasonAndYearLabel } from 'utils/campaign';
import { ORGANIZATION_DASHBOARD_PATH } from 'utils/paths';
import { capitalize } from 'utils/string';
import StatusBadge from './StatusBadge';

interface Sponsor {
  name: string;
}
interface Campaign {
  season: Season;
  sponsor: Sponsor;
  status: CampaignStatus;
  year: string;
}
interface Offer {
  id: number;
  campaign: Campaign;
  status: OfferStatus;
}

interface Props {
  offer: Offer;
}

const OrganizationOffersTableRow: React.FunctionComponent<Props> = ({
  offer,
}: Props) => {
  const { campaign, id: offerId, status } = offer;
  const {
    sponsor: { name: sponsorName },
  } = campaign;

  const seasonAndYear = seasonAndYearLabel(campaign);
  const linkId = `offer-${offerId}-link`;
  const linkTitleDetails = `${sponsorName} ${seasonAndYear}`;

  let linkLabel;
  let routeLabel;
  switch (status) {
    case 'confirmed':
      linkLabel = 'View';
      routeLabel = 'sponsorship';
      break;
    case 'proposed':
      linkLabel = 'Review';
      routeLabel = 'offer';
      break;
    default:
      break;
  }

  return (
    <tr data-test={`offer-${offerId}`}>
      <td className="align-middle">{sponsorName}</td>
      <td className="align-middle">{seasonAndYear}</td>
      <td className="align-middle" aria-label="Status Badge">
        <StatusBadge offer={offer} />
      </td>
      <td className="align-middle">
        {!!linkLabel && (
          <Link
            id={linkId}
            title={`${linkLabel} ${linkTitleDetails} ${capitalize(
              routeLabel,
            )} Details`}
            to={`${ORGANIZATION_DASHBOARD_PATH}/${routeLabel}/${offerId}`}
          >
            {linkLabel}
          </Link>
        )}
      </td>
    </tr>
  );
};

export default OrganizationOffersTableRow;
