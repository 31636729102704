import React from 'react';
import { connect } from 'react-redux';
import { contains, prop } from 'ramda';
import PropTypes from 'prop-types';
import { getSports } from 'modules/sports';
import { joinWithComma } from 'utils/string';
import { getSports as getSportsModule } from 'modules';

import FilterCard from './FilterCard';
import SportFilterForm from './SportFilterForm';

const SportFilterCardUnwrapped = ({ filterId, sportIds, sports }) => (
  <FilterCard
    filterId={filterId}
    title="Target Sports"
    description={joinWithComma(
      sports.filter((sport) => contains(sport.id, sportIds)).map(prop('name')),
    )}
    iconValue="trophy"
    formRenderer={({ save, cancel }) => (
      <SportFilterForm
        sportIds={sportIds}
        sports={sports}
        onCancel={cancel}
        onSave={save}
      />
    )}
  />
);

SportFilterCardUnwrapped.propTypes = {
  filterId: PropTypes.number.isRequired,
  sportIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const mapStateToProps = (state) => ({
  sports: getSports(getSportsModule(state)),
});

const SportFilterCard = connect(mapStateToProps)(SportFilterCardUnwrapped);

SportFilterCard.propTypes = {
  filterId: PropTypes.number.isRequired,
  sportIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default SportFilterCard;
