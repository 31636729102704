import * as React from 'react';
import { map } from 'ramda';
import { GenericCreativeContentWithAllFields } from 'types/genericCreativeContent';
import { GenericCreativeFileWithAllFields } from 'types/genericCreativeFile';
import GenericCreativeContentCard from 'components/GenericCreativeContentCard';
import GenericCreativeFileCard from 'components/GenericCreativeFileCard';
import PreviewGenericCreativeContentModal from 'components/PreviewGenericCreativeContentModal';
import RemoveCreativeContentTemplateModal from './RemoveCreativeContentTemplateModal';
import RemoveCreativeFileTemplateModal from './RemoveCreativeFileTemplateModal';
import UpdateCreativeContentTemplateModal from './UpdateCreativeContentTemplateModal';

interface Props {
  colClassName: string;
  creativeContentTemplates: GenericCreativeContentWithAllFields[];
  creativeFileTemplates: GenericCreativeFileWithAllFields[];
  tableColCount: number;
  defaultVerificationDeadlineId: number;
}

const ExpandedCreativeTemplatesRow: React.FunctionComponent<Props> = ({
  colClassName,
  creativeContentTemplates,
  creativeFileTemplates,
  tableColCount,
  defaultVerificationDeadlineId,
}: Props) => (
  <tr
    data-test={`expanded-creative-templates-row-${defaultVerificationDeadlineId}`}
  >
    <td colSpan={tableColCount} className="pt-0">
      <div className="row">
        {map(
          (creativeFileTemplate) => (
            <div
              className={colClassName}
              key={`creative-file-template-${creativeFileTemplate.id}`}
            >
              <GenericCreativeFileCard
                genericCreativeFile={creativeFileTemplate}
              >
                <RemoveCreativeFileTemplateModal
                  creativeFileTemplate={creativeFileTemplate}
                />
              </GenericCreativeFileCard>
            </div>
          ),
          creativeFileTemplates,
        )}
        {map(
          (creativeContentTemplate) => (
            <div
              className={colClassName}
              key={`creative-content-template-${creativeContentTemplate.id}`}
            >
              <GenericCreativeContentCard
                genericCreativeContent={creativeContentTemplate}
              >
                <UpdateCreativeContentTemplateModal
                  creativeContentTemplate={creativeContentTemplate}
                  defaultVerificationDeadlineId={defaultVerificationDeadlineId}
                />
                <PreviewGenericCreativeContentModal
                  genericCreativeContent={creativeContentTemplate}
                  modalTargetLabel="Preview"
                />
                <RemoveCreativeContentTemplateModal
                  creativeContentTemplate={creativeContentTemplate}
                />
              </GenericCreativeContentCard>
            </div>
          ),
          creativeContentTemplates,
        )}
      </div>
    </td>
  </tr>
);

export default ExpandedCreativeTemplatesRow;
