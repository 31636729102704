import * as React from 'react';
import { getInputId } from 'utils/id';
import { capitalizeEachWord, joinTruthyWithSpace } from 'utils/string';
import { RequiredAsterisk } from 'components/RequiredLabel';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  formGroupClassName?: string;
  helpBlock?: string;
  inputId?: string;
  label: string;
  labelClassName?: string;
  prefix?: string;
  required?: boolean;
  value: string;
}

const TextAreaInput: React.FunctionComponent<Props> = ({
  disabled = false,
  formGroupClassName = '',
  helpBlock = '',
  inputId = '',
  label,
  labelClassName = '',
  onChange,
  prefix = '',
  required = false,
  value,
}: Props) => {
  const id = getInputId([prefix, inputId || label]);

  return (
    <div className={joinTruthyWithSpace(['form-group', formGroupClassName])}>
      <label className={labelClassName} htmlFor={id}>
        {capitalizeEachWord(label)}
        {!!required && <RequiredAsterisk />}
      </label>
      <textarea
        aria-required={required ? 'true' : undefined}
        className="form-control"
        disabled={disabled}
        id={id}
        onChange={onChange}
        required={required}
        value={value}
      />
      {!!helpBlock && (
        <small className="form-text text-muted">{helpBlock}</small>
      )}
    </div>
  );
};

export default TextAreaInput;
