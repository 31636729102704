import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const CAMPAIGN_OFFER_COUNT_REPORT_FRAGMENT = gql`
  fragment CampaignOfferCountsReportFragment on Campaign {
    offerCountsReport {
      prospecting
      qualified
      proposed
      confirmed
      removed
    }
  }
`;
