import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import GenericCreativeContentModal from 'components/GenericCreativeContentModal';
import {
  CREATIVE_CONTENT_TEMPLATE_FRAGMENT,
  DEFAULT_VERIFICATION_DEADLINE_MINIMAL_FRAGMENT,
} from '../getCampaign';

export const CreateCreativeContentTemplateModalUnwrapped = ({
  capabilityName,
  create,
  defaultVerificationDeadlines,
  toggleClassName,
}) => (
  <GenericCreativeContentModal
    capabilityName={capabilityName}
    deadlines={defaultVerificationDeadlines}
    deadlineType="defaultVerificationDeadline"
    onSubmit={create}
    toggleProps={{
      className: toggleClassName,
      children: (
        <>
          <Icon value="plus" />
          Add Creative Content
        </>
      ),
    }}
  />
);

CreateCreativeContentTemplateModalUnwrapped.propTypes = {
  capabilityName: PropTypes.string.isRequired,
  create: PropTypes.func.isRequired,
  defaultVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      deadline: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  toggleClassName: PropTypes.string.isRequired,
};

const MUTATION = gql`
  mutation CreateCreativeContentTemplate(
    $input: CreativeContentTemplateInput!
  ) {
    createCreativeContentTemplate(input: $input) {
      defaultVerificationDeadline {
        ...DefaultVerificationDeadlineMinimalFragment
        creativeContentTemplates {
          ...CreativeContentTemplateFragment
        }
        deadline
      }
    }
  }
  ${DEFAULT_VERIFICATION_DEADLINE_MINIMAL_FRAGMENT}
  ${CREATIVE_CONTENT_TEMPLATE_FRAGMENT}
`;

const CreateCreativeContentTemplateModal = ({
  capabilityName,
  defaultVerificationDeadlines,
  toggleClassName,
}) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CreateCreativeContentTemplateModalUnwrapped
        capabilityName={capabilityName}
        create={({ content, name, selectedDeadlineId }) =>
          mutate({
            variables: {
              input: {
                content,
                name,
                defaultVerificationDeadlineId: Number(selectedDeadlineId),
              },
            },
          })
        }
        toggleClassName={toggleClassName}
        defaultVerificationDeadlines={defaultVerificationDeadlines}
      />
    )}
  </Mutation>
);

CreateCreativeContentTemplateModal.propTypes = {
  capabilityName: PropTypes.string.isRequired,
  defaultVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      deadline: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  toggleClassName: PropTypes.string.isRequired,
};

export default CreateCreativeContentTemplateModal;
