import * as React from 'react';
import Icon from 'components/Icon';

interface Props extends React.HTMLAttributes<HTMLElement> {
  buttonColor?: string;
  expanded: boolean;
  idLabel: string;
  label?: string;
}

const ExpandRowButton: React.FunctionComponent<Props> = ({
  buttonColor,
  expanded,
  idLabel,
  label,
  onClick,
}: Props) => (
  <button
    className={`btn btn-xs btn-${buttonColor}`}
    id={`expand-${idLabel}-button`}
    onClick={onClick}
    type="button"
  >
    {label}
    <Icon
      className="ml-1"
      value={expanded ? 'chevron-down' : 'chevron-right'}
    />
  </button>
);

ExpandRowButton.defaultProps = {
  buttonColor: 'primary',
  label: undefined,
};

export default ExpandRowButton;
