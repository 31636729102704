import * as React from 'react';
import Alert from 'components/Alert';

const AvailableCreativeNotificationAlert: React.FunctionComponent = () => (
  <Alert alertStyle="warning">
    <strong>Warning: </strong>
    Uploading a creative to this verification deadline will result in the league
    being emailed.&nbsp; Since this is the first creative to be added to a
    verification deadline that requires creatives,&nbsp; the league will be
    notified about this update in an email that will go out at the end of the
    day.
  </Alert>
);

export default AvailableCreativeNotificationAlert;
