import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { map } from 'ramda';
import gql from 'graphql-tag';
import moment, { Moment } from 'moment';
import { useSubmit } from 'utils/hooks';
import {
  campaignCapabilityCapabilityName,
  campaignCapabilityCapabilityVerificationDeadlines,
  sortByCampaignCapabilityCapabilityName,
} from 'utils/asset';
import { findById } from 'utils/misc';
import {
  invalidMustacheTagError,
  isInvalidInstructions,
} from 'utils/mustacheTagErrors';
import type { Offer } from 'types/offer';
import Icon from 'components/Icon';
import ModalFormWithMustacheTagHandling from 'components/ModalFormWithMustacheTagHandling';
import DeadlineNameInput, {
  getInitialStandardizedDeadlineName,
} from 'components/formInputs/DeadlineNameInput';
import DeadlineDateInput from 'components/formInputs/DeadlineDateInput';
import { RequiredAsterisk } from 'components/RequiredLabel';
import RequiresCreativesInput from 'components/formInputs/RequiresCreativesInput';
import VerificationReminderInstructionsInput from 'components/formInputs/VerificationReminderInstructionsInput';
import { VERIFICATION_DEADLINE_FRAGMENT } from '../../fragments';

type CreateVerificationDeadlineModalProps = {
  disabled: boolean;
  offer: Offer;
  toggleClassName: string;
};

function CreateVerificationDeadlineModal(
  props: CreateVerificationDeadlineModalProps,
) {
  const { disabled, offer, toggleClassName } = props;
  const { assets, sponsorableProperty } = offer;
  const [mutate] = useMutation(MUTATION);
  const [assetId, setAssetId] = useState('');
  const [deadline, setDeadline] = useState<Moment | null>(null);
  const [name, setName] = useState('');
  const [instructions, setInstructions] = useState('');
  const [requiresCreatives, setRequiresCreatives] = useState(false);

  const requiredDataPresent = !!(assetId && deadline && instructions && name);

  const create = (input: any) => mutate({ variables: { input } });

  async function asyncSubmit() {
    if (isInvalidInstructions(instructions)) {
      throw new Error(invalidMustacheTagError);
    }

    await create({
      assetId: Number(assetId),
      deadline: deadline?.format('YYYY-MM-DD'),
      name,
      requiresCreatives,
      verificationReminderInstructions: instructions,
    });

    setAssetId('');
    setDeadline(null);
    setName('');
    setInstructions('');
    setRequiresCreatives(false);
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const capabilityVerificationDeadlinesByAssetId = (id: string) =>
    campaignCapabilityCapabilityVerificationDeadlines(
      findById(Number(id))(assets),
    );

  function handleAssetSelection(value: string) {
    setAssetId(value);
    setName(
      getInitialStandardizedDeadlineName(
        capabilityVerificationDeadlinesByAssetId(value),
      ),
    );
  }

  const prefix = 'create-verification-deadline';

  return (
    <ModalFormWithMustacheTagHandling
      id={`${prefix}-modal`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      requiredDataPresent={requiredDataPresent}
      submitLabel="Add"
      title="Add Deadline?"
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon value="plus" />
            Add Deadline
          </>
        ),
        disabled,
      }}
    >
      <p>
        {'Are you sure you want to add a deadline for '}
        <strong>{sponsorableProperty.name}</strong>?
      </p>
      <DeadlineDateInput
        deadline={deadline}
        isOutsideRange={(date: Moment) => date.isBefore(moment())}
        isRequired
        onChange={setDeadline}
        label="Deadline"
      />
      <div className="form-group mt-3">
        <label htmlFor="asset-input">
          Asset
          <RequiredAsterisk />
        </label>
        <select
          aria-required="true"
          className="form-control"
          id="asset-input"
          onChange={({ target: { value } }) => handleAssetSelection(value)}
          value={assetId}
          disabled={loading}
        >
          <option value="">-- Please choose one --</option>
          {/* @ts-ignore */}
          {map(
            renderAssetOption,
            sortByCampaignCapabilityCapabilityName(assets),
          )}
        </select>
      </div>
      {!!assetId && (
        <DeadlineNameInput
          capabilityVerificationDeadlines={capabilityVerificationDeadlinesByAssetId(
            assetId,
          )}
          isRequired
          loading={loading}
          name={name}
          onChange={setName}
          prefix={prefix}
        />
      )}
      <VerificationReminderInstructionsInput
        instructions={instructions}
        loading={loading}
        prefix={prefix}
        setInstructions={setInstructions}
      />
      <RequiresCreativesInput
        disabled={loading}
        onChange={(event) =>
          setRequiresCreatives((event.target as HTMLInputElement).checked)
        }
        prefix={prefix}
        requiresCreatives={requiresCreatives}
      />
    </ModalFormWithMustacheTagHandling>
  );
}

export default CreateVerificationDeadlineModal;

const renderAssetOption = (asset: { id: string }) => (
  <option key={asset.id} value={asset.id}>
    {campaignCapabilityCapabilityName(asset)}
  </option>
);

const MUTATION = gql`
  mutation CreateVerificationDeadline(
    $input: CreateVerificationDeadlineInput!
  ) {
    createVerificationDeadline(input: $input) {
      offer {
        id
        anyVerificationDeadlinesToIncludeInEmails
        verificationDeadlines {
          ...VerificationDeadlineFragment
          anyPendingVerificationImages
        }
      }
    }
  }
  ${VERIFICATION_DEADLINE_FRAGMENT}
`;
