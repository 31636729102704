import React from 'react';
import PropTypes from 'prop-types';

const DataConfidenceReason = ({
  addChanges,
  dataConfidenceReason,
  disabled,
}) => (
  <div className="form-group mt-2">
    <label className="font-weight-bold" htmlFor="dataConfidenceReason">
      Reason for change:
      <span className="text-danger">*</span>
    </label>
    <input
      className="form-control"
      id="dataConfidenceReason"
      onChange={(event) =>
        addChanges('update dataConfidenceReason', {
          dataConfidenceReason: event.target.value,
        })
      }
      type="text"
      value={dataConfidenceReason}
      disabled={disabled}
    />
  </div>
);

DataConfidenceReason.propTypes = {
  addChanges: PropTypes.func.isRequired,
  dataConfidenceReason: PropTypes.string,
  disabled: PropTypes.bool,
};

DataConfidenceReason.defaultProps = {
  dataConfidenceReason: '',
  disabled: false,
};

export default DataConfidenceReason;
