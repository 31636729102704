/* eslint-disable jsx-a11y/anchor-is-valid */

import * as React from 'react';
import { gt, length, map } from 'ramda';
import { sortByName } from 'utils/sort';

interface Option {
  id: number;
  name: string;
}

type Options = Option[];

interface Props extends React.HTMLAttributes<HTMLElement> {
  options: Options;
  selected: Option;
  toggleSelected(option: Option): void;
}

const SwitchSelectedDropdown: React.FunctionComponent<Props> = ({
  options,
  selected,
  toggleSelected,
}: Props) => {
  const { name } = selected;
  const sortedOptions: Options = sortByName(options);

  return gt(length(options), 1) ? (
    <>
      <a
        className="nav-link dropdown-toggle"
        role="button"
        id="switchSelectedMenuLink"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {name}
      </a>
      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="switchSelectedMenuLink"
      >
        {map((option) => {
          const { id: optionId } = option;

          return (
            <button
              className="dropdown-item"
              data-test={`switch-selected-${optionId}`}
              key={optionId}
              onClick={() => toggleSelected(option)}
              type="button"
            >
              {option.name}
            </button>
          );
        }, sortedOptions)}
      </div>
    </>
  ) : null;
};

export default SwitchSelectedDropdown;

/* eslint-enable jsx-a11y/anchor-is-valid */
