import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import USER_FRAGMENT from 'fragments/UserFragment';
import BackToLink from 'components/BackToLink';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import Sections, { Section } from 'components/Sections';
import SponsorTab from './sponsor/SponsorTab';
import SponsorUsersTab from './sponsor/SponsorUsersTab';
import { SponsorDashboardMainUnwrapped } from './SponsorDashboardMain';

export const SponsorUnwrapped = ({ refetch, sponsor }) => {
  const { name: sponsorName } = sponsor;

  return (
    <RestrictTo roles={['admin']}>
      <FieldhousePageTitle title={sponsorName}>
        <BackToLink label="Sponsors" path={CAMPAIGN_PLANNER_PATH} />
        <h1 id="pageTitle">{sponsorName}</h1>
        <Sections noContainingCard>
          <Section name="Campaigns">
            <SponsorTab refetch={refetch} sponsor={sponsor} />
          </Section>
          <Section name="Users" path="users">
            <SponsorUsersTab sponsor={sponsor} />
          </Section>
          <Section name="Seasonal Dashboard" path="seasonal-dashboard">
            <SponsorDashboardMainUnwrapped
              selectedSponsor={sponsor}
              shouldRenderEditSeasonalDashboardButton
            />
          </Section>
        </Sections>
      </FieldhousePageTitle>
    </RestrictTo>
  );
};

SponsorUnwrapped.propTypes = {
  refetch: PropTypes.func,
  sponsor: LeagueSidePropTypes.sponsor.isRequired,
};

SponsorUnwrapped.defaultProps = {
  refetch: () => {},
};

const QUERY = gql`
  query Sponsor($id: Int!) {
    sponsor(id: $id) {
      id
      campaigns {
        id
        leagueBudget
        magicToken
        name
        season
        year
        status
      }
      name
      logoThumbnailUrl
      users {
        ...UserFragment
        lastLogin
      }
    }
  }
  ${USER_FRAGMENT}
`;

const Sponsor = ({ match }) => (
  <Query
    fetchPolicy="network-only"
    query={QUERY}
    variables={{ id: Number(match.params.sponsorId) }}
  >
    {({ data, refetch }) => {
      const sponsor = data && data.sponsor;

      return (
        <Page loaded={!!sponsor}>
          {!!sponsor && (
            <SponsorUnwrapped refetch={refetch} sponsor={sponsor} />
          )}
        </Page>
      );
    }}
  </Query>
);

Sponsor.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sponsorId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Sponsor;
