import NumberInput from 'components/formInputs/NumberInput';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';

const MUTATION = gql`
  mutation CreateDedicatedEmail($input: CreateDedicatedEmailInput!) {
    createDedicatedEmail(input: $input) {
      organization {
        id
        name
        organizationCapabilities {
          id
          capabilityDetail {
            id
            maxForSale
            price
            __typename
            capabilityDetailable {
              ... on DedicatedEmail {
                id
                emailListSize
              }
            }
          }
        }
      }
    }
  }
`;

const DedicatedEmailCapabilityForm = ({
  organization,
  capabilityId,
  redirect,
}) => {
  const [mutate] = useMutation(MUTATION, {
    fetchPolicy: 'no-cache',
  });

  const [price, setPrice] = useState(null);
  const [maxForSale, setMaxForSale] = useState(null);
  const [emailListSize, setEmailListSize] = useState(null);

  const onSubmit = async (event) => {
    event.preventDefault();

    await mutate({
      variables: {
        input: {
          organizationId: organization.id,
          capabilityId,
          price,
          maxForSale,
          emailListSize,
        },
      },
    });

    redirect();
  };

  return (
    <form data-target="game-signage-form" onSubmit={onSubmit}>
      <NumberInput
        label="What is the approximate size of your email list?"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) => setEmailListSize(Number(value))}
        customId="email-list-size-input"
        required
        step={1}
        value={emailListSize}
        placeholder="Please enter the size"
      />
      <NumberInput
        label="What is the estimated price to sponsor 1 dedicated email?"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) => setPrice(Number(value))}
        customId="estimated-price-for-dedicated-email-input"
        required
        step={1}
        value={price}
        placeholder="Please enter the estimated price"
      />
      <NumberInput
        label="What is the maximum number of dedicated emails we can sell?"
        labelClassName="font-weight-bold"
        min={0}
        onChange={({ target: { value } }) => setMaxForSale(Number(value))}
        customId="maximum-number-of-dedicated-email-input"
        required
        step={1}
        value={maxForSale}
        placeholder="Please enter the maximum number"
      />
      <button
        type="submit"
        className="btn btn-primary"
        data-test="dedicated-email-capability-form-submit-button"
      >
        Submit
      </button>
    </form>
  );
};

DedicatedEmailCapabilityForm.propTypes = {
  organization: LeagueSidePropTypes.organization.isRequired,
  capabilityId: PropTypes.number.isRequired,
  redirect: PropTypes.string.isRequired,
};

export default DedicatedEmailCapabilityForm;
