import WizardStep from '../wizard-step';

export default function ThankYouStep() {
  return (
    <WizardStep>
      <WizardStep.Content title="Thank you!">
        <p className="sui-mb-2">
          Thank you for completing the sponsorship application! We&apos;ll
          review your application and may follow up for additional details or to
          explore potential sponsorship opportunities.
        </p>
        <p className="sui-mb-2">You can close this tab.</p>
      </WizardStep.Content>
    </WizardStep>
  );
}
