import * as React from 'react';
import type { Campaign, CampaignCapability } from 'types/campaign';
import CreateCreativeContentTemplateModal from './CreateCreativeContentTemplateModal';
import CreateCreativeFileTemplateModal from './CreateCreativeFileTemplateModal';
import CreateDefaultVerificationDeadlineModal from './CreateDefaultVerificationDeadlineModal';
import DefaultVerificationDeadlinesTable from './DefaultVerificationDeadlinesTable';

interface Props {
  campaign: Campaign;
  campaignCapability: CampaignCapability;
}

const DefaultVerificationDeadlines: React.FunctionComponent<Props> = ({
  campaign,
  campaignCapability,
}: Props) => {
  const { id: campaignId, confirmedOffers } = campaign;
  const {
    id: campaignCapabilityId,
    capability,
    defaultVerificationDeadlines,
  } = campaignCapability;
  const { name: capabilityName, capabilityVerificationDeadlines } = capability;

  const firstButtonClassName = 'btn btn-secondary btn-sm';
  const buttonClassName = `${firstButtonClassName} ml-2`;

  return (
    <>
      <h6 className="mt-4">Default Verification Deadlines</h6>
      <CreateDefaultVerificationDeadlineModal
        campaignCapabilityId={campaignCapabilityId}
        capabilityName={capabilityName}
        capabilityVerificationDeadlines={capabilityVerificationDeadlines}
        toggleClassName={firstButtonClassName}
      />
      {defaultVerificationDeadlines.length ? (
        <>
          <CreateCreativeContentTemplateModal
            capabilityName={capabilityName}
            defaultVerificationDeadlines={defaultVerificationDeadlines}
            toggleClassName={buttonClassName}
          />
          <CreateCreativeFileTemplateModal
            capabilityName={capabilityName}
            campaignId={campaignId}
            defaultVerificationDeadlines={defaultVerificationDeadlines}
            toggleClassName={buttonClassName}
          />
          <DefaultVerificationDeadlinesTable
            capabilityName={capabilityName}
            capabilityVerificationDeadlines={capabilityVerificationDeadlines}
            confirmedOffers={confirmedOffers}
            defaultVerificationDeadlines={defaultVerificationDeadlines}
          />
        </>
      ) : (
        <p className="text-center mt-3">
          No deadlines exist for this campaign!
        </p>
      )}
    </>
  );
};

export default DefaultVerificationDeadlines;
