/* eslint-disable jsx-a11y/alt-text */
import { map } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { addCloudinaryAutoFormat } from 'utils/image';

const PhotosGrid = ({ images }) => (
  <div className="d-flex flex-wrap">
    {map(
      (image) => (
        // eslint-disable-next-line jsx-a11y/alt-text
        <a
          href={`${image.fullSizeUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-auto d-block mb-3 col-4"
        >
          <img
            data-test={`featured-photo-${image.id}`}
            src={addCloudinaryAutoFormat(image.thumbnailUrl)}
            className="height-max-content img-fluid"
          />
        </a>
      ),
      images,
    )}
  </div>
);

PhotosGrid.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      fullSizeUrl: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    }).isRequired,
  ).isRequired,
};

export default PhotosGrid;
