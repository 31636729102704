import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRole, logout as logoutAction } from 'modules/authentication';
import { getAuthentication } from 'modules';
import { replace } from 'react-router-redux';
import {
  ORGANIZATION_DASHBOARD_PATH,
  ORGANIZATIONS_PATH,
  SPONSOR_DASHBOARD_PATH,
} from 'utils/paths';

const redirectPathByUserRole = {
  admin: ORGANIZATIONS_PATH,
  organization: ORGANIZATION_DASHBOARD_PATH,
  sponsor: SPONSOR_DASHBOARD_PATH,
  superadmin: ORGANIZATIONS_PATH,
};

function HomeUnwrapped({ logout, redirect, userRole }) {
  const redirectPath = redirectPathByUserRole[userRole];
  if (redirectPath) redirect(redirectPath);
  else logout();

  return null;
}

HomeUnwrapped.propTypes = {
  logout: PropTypes.func.isRequired,
  redirect: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userRole: getRole(getAuthentication(state)),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutAction()),
  redirect: (path) => dispatch(replace(path)),
});

const Home = connect(mapStateToProps, mapDispatchToProps)(HomeUnwrapped);

export default Home;
