import React from 'react';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import RestrictTo from 'components/RestrictTo';
import VerificationsIndex from './verifications/VerificationsIndex';

function Verifications() {
  return (
    <FieldhousePageTitle title="Verifications">
      <RestrictTo roles={['admin']}>
        <VerificationsIndex />
      </RestrictTo>
    </FieldhousePageTitle>
  );
}

export default Verifications;
