import gql from 'graphql-tag';

export const CREATIVE_CONTENT_FRAGMENT = gql`
  fragment CreativeContentFragment on CreativeContent {
    id
    content
    name
    renderedContent
  }
`;

export const VERIFICATION_IMAGE_FRAGMENT = gql`
  fragment VerificationImageFragment on VerificationImage {
    id
    createdAt
    from
    fullSizeUrl
    hidden
    status
    thumbnailUrl
    verificationDeadline {
      id
    }
    featured
  }
`;

export const VERIFICATION_DEADLINE_FRAGMENT = gql`
  fragment VerificationDeadlineFragment on VerificationDeadline {
    id
    asset {
      campaignCapability {
        id
        capability {
          id
          name
          capabilityVerificationDeadlines {
            id
            name
          }
        }
      }
    }
    capabilityName
    creativeContents {
      ...CreativeContentFragment
    }
    creativeFiles {
      id
      fullSizeUrl
      name
      thumbnailUrl
    }
    deadline
    didNotComplete
    missingCreatives
    name
    overdue
    requiresCreatives
    verificationDeadlineResponses {
      id
      addressed
      comment
      cannedResponse
      createdAt
    }
    verificationImages(statuses: [pending, verified]) {
      ...VerificationImageFragment
    }
    verified
    verificationReminderInstructions
  }
  ${CREATIVE_CONTENT_FRAGMENT}
  ${VERIFICATION_IMAGE_FRAGMENT}
`;
