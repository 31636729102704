import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import {
  ORGANIZATIONS_PATH,
  ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH,
} from 'utils/paths';
import { ORGANIZATION_PACKAGE_MINIMAL_FRAGMENT } from 'fragments/OrganizationPackageFragment';
import ORGANIZATION_ADDRESSES_FRAGMENT from 'components/organizationDashboardWrapper/LeagueAddressesFragment';
import OrganizationDashboardWrapper from 'components/OrganizationDashboardWrapper';
import Page from 'components/Page';
import jwtDecode from 'jwt-decode';
import Loader from 'components/Loader';
import SponsorshipLibrary from './packageBuilder/SponsorshipLibrary';
import UpdatePackageCard from './packageBuilder/UpdatePackageCard';

export const PackageBuilderUnwrapped = ({ organizationPackage }) => (
  <>
    <h2>Sponsorship Package Builder</h2>
    <UpdatePackageCard organizationPackage={organizationPackage} />
  </>
);

PackageBuilderUnwrapped.propTypes = {
  organizationPackage: PropTypes.shape({
    name: PropTypes.string.isRequired,
    organizationCapabilityPackages: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        organizationCapability: PropTypes.shape({
          id: PropTypes.number.isRequired,
          description: PropTypes.string,
          name: PropTypes.string.isRequired,
        }).isRequired,
        quantity: PropTypes.number.isRequired,
      }),
    ),
    packageDescription: PropTypes.string,
    price: PropTypes.number.isRequired,
  }).isRequired,
};

const QUERY = gql`
  query OrganizationPackage($id: Int!) {
    organizationPackage(id: $id) {
      ...OrganizationPackageMinimalFragment
      sponsorableProperties {
        id
        name
      }
      organization {
        id
        name
        sponsorableProperties {
          id
          name
        }
        organizationCapabilities {
          id
          archived
          description
          name
          organizationCapabilityPackages {
            id
          }
        }
        ...OrganizationAddressesFragment
      }
      organizationCapabilityPackages {
        id
        quantity
        organizationCapability {
          id
          description
          name
        }
      }
    }
  }
  ${ORGANIZATION_PACKAGE_MINIMAL_FRAGMENT}
  ${ORGANIZATION_ADDRESSES_FRAGMENT}
`;

const PackageBuilder = ({ match }) => (
  <Query
    fetchPolicy="network-only"
    query={QUERY}
    variables={{ id: Number(match.params.organizationPackageId) }}
  >
    {({ data, loading, error }) => {
      if (error) throw error;
      if (loading) return <Loader />;
      const organizationPackage = data && data.organizationPackage;
      const organization =
        organizationPackage && organizationPackage.organization;
      const userRole = () => {
        const token = localStorage.getItem('token');
        const claims = jwtDecode(token);
        return claims.role;
      };
      const backToPath =
        userRole() === 'organization'
          ? ORGANIZATION_PACKAGES_BUILDER_SECTIONS_PATH
          : `${ORGANIZATIONS_PATH}/${organization.id}/packages`;
      return organizationPackage ? (
        <OrganizationDashboardWrapper
          breadcrumb={{ label: 'Packages', path: backToPath }}
          organizations={[organization]}
          sidebar={
            <SponsorshipLibrary organizationPackage={organizationPackage} />
          }
          title="Sponsorship Package Builder"
        >
          {() => (
            <PackageBuilderUnwrapped
              organizationPackage={organizationPackage}
            />
          )}
        </OrganizationDashboardWrapper>
      ) : (
        <Page loaded={false} />
      );
    }}
  </Query>
);

PackageBuilder.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      organizationPackageId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default PackageBuilder;
