import Card from 'components/Card';
import type { Offer } from 'types/offer';
import MarkAsQualifiedModal from 'components/sponsorablePropertyTables/MarkAsQualifiedModal';

type MarkAsQualifiedCardProps = {
  offer: Offer;
  refetchSponsorship: () => void;
};

function MarkAsQualifiedCard(props: MarkAsQualifiedCardProps) {
  const { offer, refetchSponsorship } = props;

  return (
    <Card>
      <h2 className="card-title">Mark as Qualified</h2>
      <p>
        Once the league looks qualified for the sponsorship based on its profile
        and survey responses, mark it as qualifed to distinguish it from other
        prospects and to be able to send an offer to the league.
      </p>
      <p className="text-center">
        <MarkAsQualifiedModal
          id="mark-as-qualified"
          offers={[offer]}
          toggleClassName="btn btn-primary btn-lg"
          onSubmit={refetchSponsorship}
        />
      </p>
    </Card>
  );
}

export default MarkAsQualifiedCard;
