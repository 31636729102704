import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { map, pluck } from 'ramda';

import 'react-select/dist/react-select.css';

const options = map(({ id, name }) => ({ label: name, value: id }));

function MultiSelect({ onChange, models, selectedIds, ...rest }) {
  function handleChange(selectedOptions) {
    const newSelectedIds = pluck('value', selectedOptions);

    onChange(newSelectedIds);
  }

  return (
    <Select
      onChange={useCallback(handleChange)}
      options={options(models)}
      value={selectedIds}
      multi
      {...rest}
    />
  );
}

MultiSelect.propTypes = {
  models: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default MultiSelect;
