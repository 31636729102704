import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactImageLightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { addCloudinaryAutoFormat } from 'utils/image';

export default class Lightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };

    this.close = () => this.setState({ isOpen: false });
    this.open = this.open.bind(this);
    this.movePrevious = () => this.setState({ photoIndex: this.previousIndex });
    this.moveNext = () => this.setState({ photoIndex: this.nextIndex });
  }

  get nextIndex() {
    // eslint-disable-next-line react/destructuring-assignment
    return (this.state.photoIndex + 1) % this.props.images.length;
  }

  get previousIndex() {
    const { images } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    return (this.state.photoIndex + images.length - 1) % images.length;
  }

  open({ photoIndex } = {}) {
    if (photoIndex) this.setState({ isOpen: true, photoIndex });
    else this.setState({ isOpen: true });
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    const { children, images, imageProps, ...rest } = this.props;

    return (
      <>
        {children(this.open)}

        {isOpen && (
          <ReactImageLightbox
            {...rest}
            {...imageProps(photoIndex)}
            mainSrc={addCloudinaryAutoFormat(images[photoIndex])}
            nextSrc={addCloudinaryAutoFormat(images[this.nextIndex])}
            prevSrc={addCloudinaryAutoFormat(images[this.previousIndex])}
            onCloseRequest={this.close}
            onMovePrevRequest={this.movePrevious}
            onMoveNextRequest={this.moveNext}
          />
        )}
      </>
    );
  }
}

Lightbox.propTypes = {
  children: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageProps: PropTypes.func,
};

Lightbox.defaultProps = {
  imageProps: () => {},
};
