import {
  anyPass,
  append,
  contains,
  join,
  map,
  pipe,
  replace,
  test,
} from 'ramda';

export const invalidMustacheTagError = 'invalid mustache tag';

export const hasInvalidMustacheTagErrors = (error) => {
  const invalidMustacheTagErrors = {
    genericTagError: 'invalid mustache tag',
    sportTagError:
      'GraphQL error: Cannot create creative content for sponsorable property with no sport',
    targetLocationTagError:
      'GraphQL error: Cannot create creative content for sponsorable property with no location filter',
    campaignTargetLocationTagError:
      'GraphQL error: Cannot create creative content template due to no targeting locations',
  };
  let isFound = false;

  Object.keys(invalidMustacheTagErrors).forEach((key) => {
    if (invalidMustacheTagErrors[key].includes(error.message)) {
      isFound = true;
    }
  });

  return isFound;
};

const replaceRegex = (tags) => new RegExp(`{{${join('}}|{{', tags)}}}`, 'g');
const isInvalidInput = (tags) =>
  pipe(
    replace(replaceRegex(tags), ''),
    anyPass(
      append(
        test(/{{|}}/),
        map((tag) => contains(tag), tags),
      ),
    ),
  );

export const isInvalidInstructions = isInvalidInput([
  'sponsor_name',
  'verification_email_address',
]);
export const isInvalidContent = isInvalidInput([
  'league_name',
  'league_city',
  'league_state',
  'custom_field',
  'sport_name',
  'target_location',
  'season_name',
]);
