import React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isValidContactEmail } from 'utils/league';
import SPORT_FRAGMENT from 'fragments/SportFragment';
import { isEmpty, map } from 'ramda';
import StringInput from 'components/formInputs/StringInput';
import PlacesAutocomplete from 'components/PlacesAutocomplete';
import MultiSelect from 'components/MultiSelect';
import RequiredLabel, { RequiredAsterisk } from 'components/RequiredLabel';

const SPORTS_QUERY = gql`
  query Sports {
    sports {
      ...SportFragment
    }
  }
  ${SPORT_FRAGMENT}
`;

const validateContactEmail = (contactEmail) =>
  contactEmail === '' || isValidContactEmail(contactEmail);
const renderDoubleAsterisk = <span className="text-danger">**</span>;

const OrganizationCreateFormInputs = ({
  name,
  setName,
  contactEmail,
  setContactEmail,
  contactPhone,
  setContactPhone,
  websiteUrl,
  setWebsiteUrl,
  setLocation,
  locations,
  locationQuery,
  setLocationQuery,
  removeLocation,
  winterSportIds,
  springSportIds,
  summerSportIds,
  fallSportIds,
  setWinterSportIds,
  setSpringSportIds,
  setSummerSportIds,
  setFallSportIds,
  loading,
}) => {
  const { data, loading: sportsLoading } = useQuery(SPORTS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const sports = data && data.sports;

  const groupClassName = 'list-group-item border-0';
  const labelClassName = 'font-weight-bold';

  return !sportsLoading && sports ? (
    <>
      <div
        className="list-group list-group-flush"
        data-test="organization-create-form-inputs"
      >
        <StringInput
          disabled={loading}
          groupClassName={groupClassName}
          label="name"
          labelClassName={labelClassName}
          onChange={({ target: { value } }) => setName(value)}
          placeholder="Hometown Organization"
          required
          type="text"
          value={name}
        />
        <div className={groupClassName}>
          <label className={labelClassName} htmlFor="contact-email">
            Email Address
          </label>
          <input
            className={classNames('form-control', {
              'is-invalid': !validateContactEmail(contactEmail),
            })}
            disabled={loading}
            id="contact-email"
            onChange={({ target: { value } }) => setContactEmail(value)}
            placeholder="hometownorganization@example.com"
            value={contactEmail}
          />
        </div>
        <div className={groupClassName}>
          <label className={labelClassName} htmlFor="contact-phone">
            Phone Number (555-555-5555)
          </label>
          <input
            id="contact-phone"
            className="form-control"
            disabled={loading}
            onChange={({ target: { value } }) => setContactPhone(value)}
            placeholder="555-555-5555"
            value={contactPhone}
            type="tel"
          />
        </div>
        <div className={groupClassName}>
          <label className={labelClassName} htmlFor="website-url">
            Website URL (format: http://example.com)
            <RequiredAsterisk />
          </label>
          <input
            id="website-url"
            className="form-control"
            disabled={loading}
            onChange={({ target: { value } }) => setWebsiteUrl(value)}
            placeholder="http://www.hometownorganization.com"
            type="url"
            value={websiteUrl}
          />
        </div>
        <div className={groupClassName}>
          <label className={labelClassName} htmlFor="location">
            Location
            <RequiredAsterisk />
          </label>
          <PlacesAutocomplete
            inputProps={{ disabled: loading }}
            onChange={(placesLocationQuery) => {
              setLocationQuery(placesLocationQuery);
            }}
            onSelect={(placesLocation) => {
              if (!locations.includes(placesLocation)) {
                setLocation(locations.concat(placesLocation));
              }
              setLocationQuery('');
            }}
            value={locationQuery}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps }) => (
              <>
                <input
                  {...getInputProps({
                    className: 'form-control',
                    placeholder: 'Search...',
                  })}
                />
                <div className="dropdown">
                  <div
                    className={classNames('dropdown-menu', {
                      show: suggestions.length,
                    })}
                  >
                    {suggestions.map((suggestion) => (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: classNames('dropdown-item', {
                            active: suggestion.active,
                          }),
                          key: suggestion.description,
                        })}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </PlacesAutocomplete>
        </div>
        {!isEmpty(locations) &&
          map(
            (location) => (
              <div key={location}>
                <button
                  type="button"
                  data-test={`location-selected-${location}`}
                  className="btn btn-secondary mr-2 my-1"
                  onClick={() => removeLocation(location)}
                >
                  {location}
                  <small className="ml-2">x</small>
                </button>
              </div>
            ),
            locations,
          )}
        <div className={groupClassName}>
          <label className={labelClassName} htmlFor="winter-sports-select">
            Winter Sport(s)
            {renderDoubleAsterisk}
          </label>
          <MultiSelect
            id="winter-sports-select"
            onChange={setWinterSportIds}
            models={sports}
            selectedIds={winterSportIds}
            disabled={loading}
          />
        </div>
        <div className={groupClassName}>
          <label className={labelClassName} htmlFor="spring-sports-select">
            Spring Sport(s)
            {renderDoubleAsterisk}
          </label>
          <MultiSelect
            id="spring-sports-select"
            onChange={setSpringSportIds}
            models={sports}
            selectedIds={springSportIds}
            disabled={loading}
          />
        </div>
        <div className={groupClassName}>
          <label className={labelClassName} htmlFor="summer-sports-select">
            Summer Sport(s)
            {renderDoubleAsterisk}
          </label>
          <MultiSelect
            id="summer-sports-select"
            onChange={setSummerSportIds}
            models={sports}
            selectedIds={summerSportIds}
            disabled={loading}
          />
        </div>
        <div className={groupClassName}>
          <label className={labelClassName} htmlFor="fall-sports-select">
            Fall Sport(s)
            {renderDoubleAsterisk}
          </label>
          <MultiSelect
            id="fall-sports-select"
            onChange={setFallSportIds}
            models={sports}
            selectedIds={fallSportIds}
            disabled={loading}
          />
        </div>
      </div>
      <RequiredLabel />
      <br />
      <span className="text-danger">**At least one season sport required</span>
    </>
  ) : null;
};

OrganizationCreateFormInputs.propTypes = {
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  contactEmail: PropTypes.string.isRequired,
  setContactEmail: PropTypes.func.isRequired,
  contactPhone: PropTypes.string.isRequired,
  setContactPhone: PropTypes.func.isRequired,
  websiteUrl: PropTypes.string.isRequired,
  setWebsiteUrl: PropTypes.func.isRequired,
  setLocation: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
  locationQuery: PropTypes.string.isRequired,
  setLocationQuery: PropTypes.func.isRequired,
  winterSportIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  springSportIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  summerSportIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  fallSportIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  setWinterSportIds: PropTypes.func.isRequired,
  setSpringSportIds: PropTypes.func.isRequired,
  setSummerSportIds: PropTypes.func.isRequired,
  setFallSportIds: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  removeLocation: PropTypes.func.isRequired,
};

export default OrganizationCreateFormInputs;
