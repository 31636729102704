export function getStateFromLocalStorageByKey(LOCAL_STORAGE_KEY) {
  try {
    const value = localStorage.getItem(LOCAL_STORAGE_KEY);
    return JSON.parse(value) || {};
  } catch (e) {
    return {};
  }
}

export const updateLocalStorageByKeyAndState =
  (LOCAL_STORAGE_KEY) => (state) => () => {
    const value = JSON.stringify(state);

    localStorage.setItem(LOCAL_STORAGE_KEY, value);
  };
