import * as React from 'react';
import classNames from 'classnames';
import { getInputId } from 'utils/id';
import { capitalizeEachWord } from 'utils/string';
import { RequiredAsterisk } from 'components/RequiredLabel';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  groupClassName?: string;
  helpBlock?: string;
  inputSize?: string;
  label: string;
  labelClassName?: string;
  placeholder?: string;
  prefix?: string;
  required?: boolean;
  type: 'text' | 'password';
  value: string;
  pattern?: string;
}

const StringInput: React.FunctionComponent<Props> = ({
  disabled,
  groupClassName,
  helpBlock,
  inputSize,
  label,
  labelClassName,
  onChange,
  placeholder,
  prefix,
  required,
  type,
  value,
  pattern,
}: Props) => {
  const id = getInputId([prefix, label]);

  return (
    <div className={groupClassName || 'form-group'}>
      <label className={labelClassName} htmlFor={id}>
        {capitalizeEachWord(label)}
        {!!required && <RequiredAsterisk />}
      </label>
      <input
        aria-required={required ? 'true' : undefined}
        className={classNames('form-control', {
          [`form-control-${inputSize}`]: !!inputSize,
        })}
        disabled={disabled}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        type={type}
        value={value}
        pattern={pattern}
      />
      {!!helpBlock && (
        <small id={`${id}-help-block`} className="form-text text-muted">
          {helpBlock}
        </small>
      )}
    </div>
  );
};

StringInput.defaultProps = {
  disabled: false,
  groupClassName: undefined,
  helpBlock: undefined,
  inputSize: undefined,
  labelClassName: undefined,
  placeholder: undefined,
  prefix: undefined,
  required: undefined,
  pattern: undefined,
};

export default StringInput;
