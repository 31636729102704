import React from 'react';
import { graphql, Query } from 'react-apollo';
import { pipe, propOr } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import LOCATION_FRAGMENT from 'fragments/LocationFragment';
import SPONSORSHIP_INSIGHT_REPORT_FRAGMENT from 'fragments/SponsorshipInsightReportFragment';
import { CAMPAIGN_TARGETING_OFFER_FRAGMENT } from 'fragments/OfferFragment';

const prospectiveSponsorablePropertiesQuery = gql`
  query ProspectiveSponsorableProperties(
    $campaignId: Int!
    $cursor: String = "*"
  ) {
    prospectiveSponsorableProperties(campaignId: $campaignId, cursor: $cursor) {
      lastPage
      sponsorableProperties {
        competitiveness
        id
        lat
        sponsorablePropertyCapabilitiesCount
        sponsorablePropertyPackagesCount
        lon
        maxAge
        minAge
        name
        numberOfPlayers
        dataConfidence
        organization {
          id
          dataConfidence
          sportsManagementSoftwareId
          eventsSurveyResponses {
            submittedAt
            surveyGizmoResponseId
          }
          sponsorshipInsightReport {
            ...SponsorshipInsightReportFragment
          }
          websiteUrl
        }
        sport
      }
      nextCursor
    }
  }
  ${SPONSORSHIP_INSIGHT_REPORT_FRAGMENT}
`;

const wrapWithProspectiveSponsorableProperties = graphql(
  prospectiveSponsorablePropertiesQuery,
  {
    options: ({ match }) => ({
      variables: { campaignId: Number(match.params.campaignId) },
    }),
    props: ({ data, ownProps }) => ({
      loading: data.loading,
      loadMore: () =>
        data.fetchMore({
          query: prospectiveSponsorablePropertiesQuery,
          variables: {
            campaignId: Number(ownProps.match.params.campaignId),
            cursor: data.prospectiveSponsorableProperties.nextCursor,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => ({
            prospectiveSponsorableProperties: {
              ...fetchMoreResult.prospectiveSponsorableProperties,
              sponsorableProperties: [
                ...previousResult.prospectiveSponsorableProperties
                  .sponsorableProperties,
                ...fetchMoreResult.prospectiveSponsorableProperties
                  .sponsorableProperties,
              ],
            },
          }),
        }),
      prospectiveSponsorableProperties:
        data.prospectiveSponsorableProperties || { sponsorableProperties: [] },
      refetchProspectiveSponsorableProperties: data.refetch,
    }),
  },
);

function wrapWithCampaign(WrappedComponent) {
  const WithCampaign = (props) => (
    <Query
      variables={{ id: Number(props.match.params.campaignId) }} // eslint-disable-line react/destructuring-assignment
      query={gql`
        query Campaign($id: Int!) {
          campaign(id: $id) {
            id
            name
            campaignCapabilities {
              id
              capability {
                id
              }
            }
            locationFilters {
              id
              enabled
              locations {
                ...LocationFragment
              }
              name
              radius
              strategy
              strategyCount
            }
            campaignGeometries {
              id
              geoJson
              label
            }
            offers {
              ...CampaignTargetingOfferFragment
            }
            radii {
              label
              lat
              lon
              miles
            }
            sponsor {
              id
              name
            }
          }
          capabilities {
            id
            name
          }
          markets: geometries(geometryType: market) {
            id
            name
          }
        }
        ${LOCATION_FRAGMENT}
        ${CAMPAIGN_TARGETING_OFFER_FRAGMENT}
      `}
    >
      {({ data, refetch }) => (
        <WrappedComponent
          campaign={propOr(
            {
              campaignCapabilities: [],
              locationFilters: [],
              name: '',
              offers: [],
              radii: [],
              sponsor: { name: '' },
            },
            'campaign',
            data,
          )}
          capabilities={propOr([], 'capabilities', data)}
          markets={propOr([], 'markets', data)}
          refetchCampaign={refetch}
          {...props}
        />
      )}
    </Query>
  );

  WithCampaign.propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        campaignId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  WithCampaign.WrappedComponent =
    WrappedComponent.WrappedComponent || WrappedComponent;

  return WithCampaign;
}

function wrapWithAutoFetching(WrappedComponent) {
  class AutoFetching extends React.Component {
    componentDidUpdate(previousProps) {
      const { props } = this;
      const { lastPage, nextCursor } = props.prospectiveSponsorableProperties;

      if (
        !lastPage &&
        nextCursor !== previousProps.prospectiveSponsorableProperties.nextCursor
      )
        props.loadMore();
    }

    render() {
      const { props } = this;

      return (
        <WrappedComponent
          {...this.props}
          prospectiveSponsorableProperties={
            props.prospectiveSponsorableProperties.sponsorableProperties
          }
        />
      );
    }
  }

  AutoFetching.propTypes = {
    loadMore: PropTypes.func.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        campaignId: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    prospectiveSponsorableProperties: PropTypes.shape({
      lastPage: PropTypes.bool,
      sponsorableProperties:
        LeagueSidePropTypes.sponsorableProperties.isRequired,
      nextCursor: PropTypes.string,
    }).isRequired,
    refetchProspectiveSponsorableProperties: PropTypes.func.isRequired,
    refetchCampaign: PropTypes.func.isRequired,
  };

  AutoFetching.WrappedComponent =
    WrappedComponent.WrappedComponent || WrappedComponent;

  return AutoFetching;
}

const wrapWithCampaignAndProspectiveSponsorableProperties = pipe(
  wrapWithAutoFetching,
  wrapWithProspectiveSponsorableProperties,
  wrapWithCampaign,
);

export default wrapWithCampaignAndProspectiveSponsorableProperties;
