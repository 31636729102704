import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import CheckModalTable from 'components/CheckModalTable';
import type { Check } from 'types/offer';

type CancelCheckModalProps = {
  check: Check;
};

function CancelCheckModal(props: CancelCheckModalProps) {
  const { check } = props;
  const [mutate] = useMutation(MUTATION, {
    variables: { input: { checkId: check.id } },
  });
  const { error, loading, handleSubmit } = useSubmit(mutate);

  const renderBody = (
    <>
      <p>Are you sure you want to cancel the following check?</p>
      <CheckModalTable check={check} />
    </>
  );

  return (
    <ModalForm
      id={`cancel-check-modal-${check.id}`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        className: classNames('btn btn-primary', { 'font-italic': loading }),
        children: (
          <>
            {loading ? null : <Icon className="mr-1" value="cross" />}
            {loadingTense(loading, 'Cancel Check')}
          </>
        ),
      }}
      toggleProps={{
        className: 'btn btn-link text-danger p-0',
        children: <Icon value="block" />,
      }}
      title="Cancel Check"
    >
      {renderBody}
    </ModalForm>
  );
}

const MUTATION = gql`
  mutation CancelCheck($input: CancelCheckInput!) {
    cancelCheck(input: $input) {
      offer {
        id
        checks {
          id
        }
      }
    }
  }
`;

export default CancelCheckModal;
