import { isEmpty } from 'ramda';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { getGeometries, getRadii } from 'utils/locationFilter';
import Map from 'components/Map';
import Sidebar from 'components/Sidebar';
import ProspectingCard from 'components/ProspectingCard';
import PropTypes from 'prop-types';

const renderLocation = (location, style, cable) => (
  <div style={style} key={location.id}>
    <ProspectingCard
      id={location.id}
      prospectingReportId={location.prospectingReportId}
      link="prospect-location"
      name={location.name}
      cable={cable}
    />
  </div>
);

const ProspectFilterPerLocation = ({ locationFilter, cable }) => {
  const { prospectiveSponsorableProperties, locations } = locationFilter;

  return (
    <div className="prospect-page__sidebar-and-summary">
      <div className="prospect-page__sidebar">
        <Sidebar>
          <div className="flex-grow-1" data-test="locations">
            {isEmpty(locations) && <p>No locations exist for this filter.</p>}
            {locations.length > 0 &&
              locations.map((location) => renderLocation(location, {}, cable))}
            {/*
              TODO: Check if we still need this AutoSizer and List, if so,
              check if we are using the correct version of react-virtualized */}
            {/* <AutoSizer>
              {({ height, width }) => (
                <List
                  height={height}
                  rowCount={locations.length}
                  rowHeight={150}
                  rowRenderer={({ index, style }) =>
                    renderLocation(locations[index], style, cable)
                  }
                  width={width}
                />
              )}
            </AutoSizer> */}
          </div>
        </Sidebar>
      </div>
      <div className="summary">
        <Map
          campaignGeometries={getGeometries(locationFilter)}
          sponsorableProperties={prospectiveSponsorableProperties || []}
          radii={getRadii(locationFilter)}
        />
      </div>
    </div>
  );
};

ProspectFilterPerLocation.propTypes = {
  locationFilter: LeagueSidePropTypes.locationFilter,
  cable: PropTypes.shape.isRequired,
};

ProspectFilterPerLocation.defaultProps = {
  locationFilter: null,
};

export default ProspectFilterPerLocation;
