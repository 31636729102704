import formatDate from 'utils/formatDate';
import { formatCurrency } from 'utils/formatNumber';
import { isApproved, isPending } from 'utils/check';
import type { Check } from 'types/offer';
import Card from 'components/Card';
import Badge, { BadgeProps } from 'components/Badge';
import CancelCheckModal from './cancel-check-modal';
import VoidCheckModal from './void-check-modal';

const statusBadgeType: {
  [key: string]: string;
} = {
  rejected: 'danger',
  voided: 'secondary',
  approved: 'success',
  pending: 'warning',
};

type CheckCardProps = {
  check: Check;
};

const CheckCard = (props: CheckCardProps) => {
  const { check } = props;
  const { number, offer, status } = check;
  const { mailingAddress } = offer.sponsorableProperty.organization;

  return (
    <Card
      bodyPadding={3}
      border={statusBadgeType[status]}
      data-test={`check-card-${check.id}`}
      footer={
        <div className="row">
          <div data-test="status" className="col">
            <Badge
              badgeType={statusBadgeType[status] as BadgeProps['badgeType']}
              className="text-capitalize"
              title={badgeTitle(check)}
            >
              {status}
            </Badge>
          </div>
          <div className="col-auto text-right">
            {isPending(check) && <CancelCheckModal check={check} />}
            {isApproved(check) && <VoidCheckModal check={check} />}
          </div>
        </div>
      }
      verticalMargins={3}
    >
      <div className="row" style={{ height: '22px' }}>
        {!!number && (
          <div data-test="number" className="col text-right">
            <strong>#{number}</strong>
          </div>
        )}
      </div>
      <div className="row">
        <div data-test="user" className="col text-truncate">
          <strong>User: </strong>
          {check.createdBy.name}
        </div>
        <div data-test="created" className="col-auto">
          <strong>Created: </strong>
          {formatDate(check.createdAt, 'M/D/YY')}
        </div>
      </div>
      <div className="row">
        <div data-test="to" className="col text-truncate">
          <strong>To: </strong>
          {mailingAddress && mailingAddress.name}
        </div>
        <div data-test="amount" className="col-auto">
          <strong>Amount: </strong>
          {formatCurrency(check.amount)}
        </div>
      </div>
      <div className="row">
        <div data-test="memo" className="col text-truncate mt-1">
          <small>
            <strong>Memo: </strong>
            {check.memo}
          </small>
        </div>
      </div>
    </Card>
  );
};

export default CheckCard;

const badgeTitle = (check: Check): string => {
  const { actionReason, reviewedBy, status, voidedBy } = check;

  switch (status) {
    case 'rejected':
      return `Rejected by ${reviewedBy.name}: ${actionReason}`;
    case 'voided':
      return `Voided by ${voidedBy.name}: ${actionReason}`;
    case 'approved':
      return `Approved by ${reviewedBy.name}`;
    default:
      return '';
  }
};
