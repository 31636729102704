import { map } from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { STATES } from 'utils/deliverableAddress';

const renderOption = (option) => (
  <option key={option} value={option}>
    {option}
  </option>
);

const renderOptions = map(renderOption);

const OrganizationLocation = ({ addChanges, city, disabled, state }) => (
  <div className="list-group-item">
    <div className="row">
      <div className="col-md-6 form-group">
        <label className="font-weight-bold" htmlFor="city">
          City
        </label>
        <input
          type="text"
          className="form-control"
          id="city"
          onChange={(event) =>
            addChanges('update city', {
              city: event.target.value ? event.target.value : null,
            })
          }
          placeholder="City"
          value={city || ''}
          disabled={disabled}
        />
      </div>
      <div className="col-md-6 form-group">
        <label className="font-weight-bold" htmlFor="state">
          State
        </label>
        <select
          className="form-control"
          id="state"
          onChange={(event) =>
            addChanges('update state', {
              state: event.target.value ? event.target.value : null,
            })
          }
          value={state || ''}
          disabled={disabled}
        >
          <option value="">-- State --</option>
          {renderOptions(STATES)}
        </select>
      </div>
    </div>
  </div>
);

OrganizationLocation.propTypes = {
  addChanges: PropTypes.func.isRequired,
  city: PropTypes.string,
  disabled: PropTypes.bool,
  state: PropTypes.string,
};

OrganizationLocation.defaultProps = {
  city: null,
  disabled: false,
  state: null,
};

export default OrganizationLocation;
