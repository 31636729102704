import React from 'react';
import { graphql, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import {
  ADDED_OFFERS_QUERY_ARGS,
  REMOVED_OFFERS_QUERY_ARGS,
} from 'utils/queryArgs';
import LOCATION_FRAGMENT from 'fragments/LocationFragment';
import FieldhousePageTitle from 'components/FieldhousePageTitle';
import Page from 'components/Page';
import RestrictTo from 'components/RestrictTo';
import ProspectingReportTotalProgressBar from 'components/ProspectingReportTotalProgressBar';
import AddAllAvailableSponsorablePropertiesModal from 'components/AddAllAvailableSponsorablePropertiesModal';
import AddPreviousPartnersModal from 'components/AddPreviousPartnersModal';
import SponsorAndCampaignLinksHeader from 'components/SponsorAndCampaignLinksHeader';
import ProspectCampaignSidebarAndSummary from './prospectCampaign/ProspectCampaignSidebarAndSummary';

class ProspectCampaignUnwrapped extends React.Component {
  componentDidUpdate(previousProps) {
    const { prospectiveSponsorableProperties, loadMore } = this.props;
    const { lastPage, nextCursor } = prospectiveSponsorableProperties;
    if (
      !lastPage &&
      nextCursor !== previousProps.prospectiveSponsorableProperties.nextCursor
    ) {
      loadMore();
    }
  }

  render() {
    const {
      campaign,
      prospectiveSponsorableProperties,
      refetchCampaign,
      cable,
    } = this.props;

    return (
      <Page
        fullScreen
        loaded={!!campaign && !!prospectiveSponsorableProperties}
        mainClassName="sui-flex"
      >
        {!!campaign && !!prospectiveSponsorableProperties && (
          <RestrictTo roles={['admin']}>
            <FieldhousePageTitle title={campaign.name} />
            <div className="row mx-1 mt-3">
              <div className="col-3">
                <SponsorAndCampaignLinksHeader campaign={campaign} />
                <h1>Campaign Prospecting</h1>
              </div>
              <div className="col-6 p-0 text-center align-self-center">
                <ProspectingReportTotalProgressBar
                  prospectingReportId={campaign.prospectingReportId}
                  cable={cable}
                />
              </div>
              <div className="col-3 align-self-center text-right">
                <AddPreviousPartnersModal
                  input={{ campaignId: campaign.id }}
                  onAdd={refetchCampaign}
                />
                <AddAllAvailableSponsorablePropertiesModal
                  prospectingReportId={campaign.prospectingReportId}
                  input={{ campaignId: campaign.id }}
                  onAdd={refetchCampaign}
                />
              </div>
            </div>
            <ProspectCampaignSidebarAndSummary
              campaign={campaign}
              prospectives={
                prospectiveSponsorableProperties.sponsorableProperties
              }
              cable={cable}
            />
          </RestrictTo>
        )}
      </Page>
    );
  }
}

ProspectCampaignUnwrapped.propTypes = {
  campaign: LeagueSidePropTypes.campaign,
  loadMore: PropTypes.func,
  prospectiveSponsorableProperties: PropTypes.shape({
    sponsorableProperties: LeagueSidePropTypes.sponsorableProperties,
    lastPage: PropTypes.bool,
    nextCursor: PropTypes.string,
  }),
  refetchCampaign: PropTypes.func.isRequired,
  cable: PropTypes.shape.isRequired,
};

ProspectCampaignUnwrapped.defaultProps = {
  campaign: null,
  loadMore: () => {},
  prospectiveSponsorableProperties: {
    lastPage: false,
    nextCursor: '*',
  },
};

const SPONSORABLE_PROPERTIES_QUERY = gql`
  query ProspectiveSponsorableProperties($campaignId: Int!, $cursor: String!) {
    prospectiveSponsorableProperties(campaignId: $campaignId, cursor: $cursor) {
      lastPage
      nextCursor
      sponsorableProperties {
        id
        lat
        lon
        name
      }
    }
  }
`;

export const wrapWithProspectiveSponsorableProperties = graphql(
  SPONSORABLE_PROPERTIES_QUERY,
  {
    options: ({ match }) => ({
      fetchPolicy: 'network-only',
      variables: { campaignId: Number(match.params.campaignId), cursor: '*' },
    }),
    props: ({ data, ownProps }) => ({
      loading: data.loading,
      loadMore: () =>
        data.fetchMore({
          query: SPONSORABLE_PROPERTIES_QUERY,
          variables: {
            campaignId: Number(ownProps.match.params.campaignId),
            cursor: data.prospectiveSponsorableProperties.nextCursor,
          },
          updateQuery(previousResult, { fetchMoreResult }) {
            return {
              prospectiveSponsorableProperties: {
                ...fetchMoreResult.prospectiveSponsorableProperties,
                sponsorableProperties: [
                  ...previousResult.prospectiveSponsorableProperties
                    .sponsorableProperties,
                  ...fetchMoreResult.prospectiveSponsorableProperties
                    .sponsorableProperties,
                ],
              },
            };
          },
        }),
      prospectiveSponsorableProperties:
        data.prospectiveSponsorableProperties || {
          sponsorableProperties: [],
          sponsorshipInsightReports: [],
        },
      refetchProspectiveLeagues: data.refetch,
    }),
  },
);

const ProspectCampaignWithSponsorableProperties =
  wrapWithProspectiveSponsorableProperties(ProspectCampaignUnwrapped);

export const CAMPAIGN_QUERY = gql`
  query ProspectCampaign($id: Int!) {
    campaign(id: $id) {
      id
      name
      addedOffers: offers(${ADDED_OFFERS_QUERY_ARGS}) {
        id
        sponsorableProperty { id }
      }
      removedOffers: offers(${REMOVED_OFFERS_QUERY_ARGS}) {
        id
        sponsorableProperty { id }
      }
      locationFilters(enabled: true) {
        id
        enabled
        locations {
          ...LocationFragment
        }
        prospectingReportId
        name
        radius
        strategy
        strategyCount
      }
      prospectingReportId
      sponsor {
        id
        name
      }
      campaignGeometries {
        id
        geoJson
        label
      }
      radii {
        label
        lat
        lon
        miles
      }
    }
  }
  ${LOCATION_FRAGMENT}
`;

const CAMPAIGN_REFETCH_QUERY = gql`
  query ProspectCampaignRefetch($id: Int!) {
    campaign(id: $id) {
      id
      addedOffers: offers(${ADDED_OFFERS_QUERY_ARGS}) {
        id
        sponsorableProperty { id }
      }
      prospectingReportId
      locationFilters(enabled: true) {
        id
        prospectingReportId
      }
    }
  }
`;

const ProspectCampaign = ({ match, cable }) => {
  const { campaignId } = match.params;
  const id = Number(campaignId);

  const { data } = useQuery(CAMPAIGN_QUERY, { variables: { id } });

  const { refetch } = useQuery(CAMPAIGN_REFETCH_QUERY, {
    variables: { id },
    skip: true,
  });

  return (
    <ProspectCampaignWithSponsorableProperties
      campaign={data && data.campaign}
      match={match}
      refetchCampaign={refetch}
      cable={cable}
    />
  );
};

ProspectCampaign.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string,
    }),
  }).isRequired,
  cable: PropTypes.shape.isRequired,
};

export default ProspectCampaign;
