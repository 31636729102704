import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

export const RemoveOrganizationCapabilityModalUnwrapped = ({
  organizationCapability,
  remove,
}) => {
  const { error, loading, handleSubmit } = useSubmit(remove);

  return (
    <ModalForm
      // eslint-disable-next-line react/prop-types
      id={`remove-organization-capability-${organizationCapability.id}-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Remove'),
        className: 'btn btn-danger',
        disabled: loading,
      }}
      title="Remove Capability?"
      toggleProps={{
        children: <Icon value="trash" />,
        className: 'btn btn-link p-0 text-danger',
        title: 'Remove',
      }}
    >
      Are you sure you want to remove&nbsp;
      <strong>{organizationCapability.name}</strong>
      &nbsp;from this capability?
    </ModalForm>
  );
};

RemoveOrganizationCapabilityModalUnwrapped.propTypes = {
  organizationCapability: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  remove: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation DeleteOrganizationCapability(
    $input: DeleteOrganizationCapabilityInput!
  ) {
    deleteOrganizationCapability(input: $input) {
      organization {
        id
        organizationCapabilities {
          id
        }
      }
    }
  }
`;

const RemoveOrganizationCapabilityModal = ({ organizationCapability }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <RemoveOrganizationCapabilityModalUnwrapped
        organizationCapability={organizationCapability}
        remove={() =>
          mutate({
            variables: {
              // eslint-disable-next-line react/prop-types
              input: { id: Number(organizationCapability.id) },
            },
          })
        }
      />
    )}
  </Mutation>
);

RemoveOrganizationCapabilityModal.propTypes = {
  organizationCapability: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default RemoveOrganizationCapabilityModal;
