/* eslint-disable react/forbid-prop-types */

import qs from 'qs';
import React from 'react';
import PropTypes from 'prop-types';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import NewWindowLink from 'components/NewWindowLink';

const env = runtimeEnv();
const reportUrl = (params) =>
  `${env.REACT_APP_VERIFICATIONS_REPORT_URL}?${qs.stringify(params)}`;

const VerificationsReportLink = ({ children, params }) => (
  <NewWindowLink hideIcon to={reportUrl(params)}>
    {children}
  </NewWindowLink>
);

VerificationsReportLink.propTypes = {
  children: PropTypes.node.isRequired,
  params: PropTypes.object,
};

VerificationsReportLink.defaultProps = {
  params: {},
};

export default VerificationsReportLink;
