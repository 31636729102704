import gql from 'graphql-tag';
import CAMPAIGN_STATISTIC_FRAGMENT from 'fragments/CampaignStatisticFragment';

export const DEFAULT_VERIFICATION_DEADLINE_MINIMAL_FRAGMENT = gql`
  fragment DefaultVerificationDeadlineMinimalFragment on DefaultVerificationDeadline {
    id
    missingCreatives
  }
`;

export const DEFAULT_VERIFICATION_DEADLINE_FRAGMENT = gql`
  fragment DefaultVerificationDeadlineFragment on DefaultVerificationDeadline {
    ...DefaultVerificationDeadlineMinimalFragment
    deadline
    name
    offset
    offsetType
    requiresCreatives
    verificationReminderInstructions
  }
  ${DEFAULT_VERIFICATION_DEADLINE_MINIMAL_FRAGMENT}
`;

export const CREATIVE_CONTENT_TEMPLATE_FRAGMENT = gql`
  fragment CreativeContentTemplateFragment on CreativeContentTemplate {
    id
    content
    name
    renderedContent
  }
`;

export const CREATIVE_FILE_TEMPLATE_FRAGMENT = gql`
  fragment CreativeFileTemplateFragment on CreativeFileTemplate {
    id
    fullSizeUrl
    name
    thumbnailUrl
  }
`;

export default gql`
  query Campaign($id: Int!) {
    campaign(id: $id) {
      id
      offerCountsReport {
        prospecting
        confirmed
        qualified
        proposed
      }
      additionalRequirements
      leagueBudget
      leagueSharePercentage
      leaguesConfirmedGoalDate
      totalSpend
      campaignCapabilities {
        id
        capability {
          id
          capabilityVerificationDeadlines {
            id
            name
          }
          createDeadlineBasedOnQuantity
          name
        }
        defaultVerificationDeadlines {
          ...DefaultVerificationDeadlineFragment
          creativeContentTemplates {
            ...CreativeContentTemplateFragment
          }
          creativeFileTemplates {
            ...CreativeFileTemplateFragment
          }
        }
        minimumQuantity
        quantity
        executionExpectations
      }
      campaignStatistics {
        ...CampaignStatisticFragment
      }
      confirmedOffers: offers(statuses: [confirmed]) {
        id
        value
        league {
          name
        }
        verificationDeadlines {
          capabilityName
          creativeContents {
            id
          }
          creativeFiles {
            id
          }
          didNotComplete
          name
        }
      }
      demographicTargetingSummary
      digitalVerificationProgress {
        total
        overdue
        verified
      }
      geographicTargetingSummary
      goal
      magicToken
      name
      physicalVerificationProgress {
        total
        overdue
        verified
      }
      primarySalesContactId
      primaryStrategistContactId
      primaryBdrContactId
      proposedOffers: offers(statuses: [proposed, rejected_by_league]) {
        id
      }
      prospectingOffers: offers(statuses: [prospecting]) {
        id
      }
      sponsorOverview
      targetNumberOfLeagues
      testimonials {
        id
        attribution
        quote
      }
      sponsor {
        id
        name
      }
      qualifiedOffers: offers(statuses: [qualified]) {
        id
      }
      sponsorDashboardAdditionalContent
      sponsorFacingGoalTitle
      sponsorFacingGoalBody
      sponsorFacingGoalImageUrl
      lookerUrl
      impressionsOverride
      season
      year
      status
      seasonName
      customQuestions {
        id
        question
      }
      sponsorshipApplicationsSent
    }
  }
  ${DEFAULT_VERIFICATION_DEADLINE_FRAGMENT}
  ${CREATIVE_CONTENT_TEMPLATE_FRAGMENT}
  ${CREATIVE_FILE_TEMPLATE_FRAGMENT}
  ${CAMPAIGN_STATISTIC_FRAGMENT}
`;
