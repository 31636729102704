import gql from 'graphql-tag';

const LOCATION_FRAGMENT = gql`
  fragment LocationFragment on Location {
    id
    lat
    lon
    name
    place
  }
`;

export default LOCATION_FRAGMENT;
