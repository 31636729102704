import React, { useContext } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import RefetchDataContext from './RefetchDataContext';

export const DeleteLocationModalUnwrapped = ({
  filterName,
  location,
  remove,
}) => {
  const { id, name, place } = location;
  const refetchData = useContext(RefetchDataContext);
  const { error, loading, handleSubmit } = useSubmit(() =>
    remove().then(refetchData),
  );

  const displayName = (
    <strong>
      {place !== name && `${name}: `}
      {place}
    </strong>
  );

  return (
    <ModalForm
      id={`delete-row-${id}-location-modal`}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{ children: loadingTense(loading, 'Delete') }}
      toggleProps={{
        children: <Icon value="trash" />,
        className: 'btn btn-link text-danger p-0',
      }}
      title={`Delete Location from ${filterName}?`}
    >
      <p>
        Are you sure you want to delete {displayName} from this location filter?
      </p>
    </ModalForm>
  );
};

DeleteLocationModalUnwrapped.propTypes = {
  filterName: PropTypes.string.isRequired,
  location: LeagueSidePropTypes.location.isRequired,
  remove: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      locationFilter {
        id
        locations {
          id
        }
      }
    }
  }
`;

const DeleteLocationModal = ({ filterName, location }) => (
  <Mutation
    mutation={MUTATION}
    variables={{ input: { locationId: location.id } }}
  >
    {(mutate) => (
      <DeleteLocationModalUnwrapped
        filterName={filterName}
        location={location}
        remove={mutate}
      />
    )}
  </Mutation>
);

DeleteLocationModal.propTypes = {
  filterName: PropTypes.string.isRequired,
  location: LeagueSidePropTypes.location.isRequired,
};

export default DeleteLocationModal;
