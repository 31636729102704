import React from 'react';
import PropTypes from 'prop-types';
import MultiSelect from 'components/MultiSelect';

class SportFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSportIds: props.sportIds,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setValue = (selectedSportIds) => this.setState({ selectedSportIds });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { onSave } = this.props;
    const { selectedSportIds } = this.state;
    onSave({
      sportIds: selectedSportIds,
    });
  }

  render() {
    const { sports } = this.props;
    const { selectedSportIds } = this.state;
    const { onCancel } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <MultiSelect
            models={sports}
            onChange={this.setValue}
            selectedIds={selectedSportIds}
          />
        </div>
        <div className="text-right">
          <button type="button" className="btn btn-link" onClick={onCancel}>
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={this.save}
            data-test="submit-sport-filter"
            disabled={!selectedSportIds.length}
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

SportFilterForm.propTypes = {
  sportIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  sports: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default SportFilterForm;
