import React from 'react';
import { map } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import formatDate from 'utils/formatDate';
import Badge from 'components/Badge';
import VerificationsListIcon from 'components/VerificationsListIcon';
import MissingCreativesIndicator from 'components/MissingCreativesIndicator';
import DangerousInnerHtml from 'components/dangerous-inner-html/dangerous-inner-html';
import ConfirmedOfferOverviewSection from './ConfirmedOfferOverviewSection';

const PendingVerificationDeadlinesSection = ({ verificationDeadlines }) =>
  verificationDeadlines.length ? (
    <ConfirmedOfferOverviewSection header="Under Review">
      {map(
        (verificationDeadline) => (
          <div
            className="row mb-5"
            key={verificationDeadline.id}
            data-test={`pending-verification-deadline-row-${verificationDeadline.id}`}
          >
            <div className="col-2 col-md-1 text-nowrap">
              <VerificationsListIcon
                className="mr-2"
                anyPendingVerificationImages
              />
              <h3
                className={classNames(
                  'verification-deadline-details-date text-really-muted',
                )}
              >
                {formatDate(verificationDeadline.deadline, 'M/D/YY')}
              </h3>
            </div>
            <div className="col">
              <div className="d-flex mb-2">
                <div className="d-flex flex-wrap align-items-center">
                  <div
                    className={classNames(
                      'verification-deadline-details-header pr-2 text-really-muted',
                    )}
                  >
                    {verificationDeadline.name}
                  </div>
                  <div className="verification-deadline-details-badge pr-2">
                    <Badge
                      badgeType="warning"
                      title="Thank you for uploading your verifications! We will update the status of this verification deadline after we review!"
                    >
                      Under Review!
                    </Badge>
                  </div>
                </div>
              </div>
              <div className={classNames('mb-1 text-really-muted')}>
                <DangerousInnerHtml
                  html={
                    verificationDeadline.renderedVerificationReminderInstructions
                  }
                />
              </div>
              {verificationDeadline.missingCreatives && (
                <MissingCreativesIndicator />
              )}
            </div>
          </div>
        ),
        verificationDeadlines,
      )}
    </ConfirmedOfferOverviewSection>
  ) : null;

PendingVerificationDeadlinesSection.defaultProps = {
  verificationDeadlines: null,
};

PendingVerificationDeadlinesSection.propTypes = {
  verificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      renderedVerificationReminderInstructions: PropTypes.string.isRequired,
    }),
  ),
};

export default PendingVerificationDeadlinesSection;
