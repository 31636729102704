import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { path, prop } from 'ramda';
import authentication from './authentication';
import caughtErrors from './caughtErrors';
import locationLists from './locationLists';
import sponsorAdd from './sponsor/add';
import campaignAdd from './campaign/add';
import campaignSearchFilters from './campaign/searchFilters';
import sports from './sports';
import invitation from './invitation';

const campaign = combineReducers({
  add: campaignAdd,
  searchFilters: campaignSearchFilters,
});

const sponsor = combineReducers({ add: sponsorAdd });

export default combineReducers({
  authentication,
  campaign,
  caughtErrors,
  invitation,
  routing: routerReducer,
  locationLists,
  sponsor,
  sports,
});

export const getAddCampaign = path(['campaign', 'add']);
export const getAddSponsor = path(['sponsor', 'add']);
export const getAuthentication = prop('authentication');
export const getCampaignSearchFilters = path(['campaign', 'searchFilters']);
export const getCaughtErrors = prop('caughtErrors');
export const getInvitation = prop('invitation');
export const getLocationLists = prop('locationLists');
export const getSports = prop('sports');
export const getUsers = prop('users');
