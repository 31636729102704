import React from 'react';
import Sections, { Section } from 'components/Sections';
import VerificationsMap from './VerificationsMap';
import ResultsTab from './ResultsTab';
import ImpressionsTab from './ImpressionsTab';
import TestimonialSection from './TestimonialSection';
import Creatives from './Creatives';
import SeasonalGoalTab from './SeasonalGoalTab';
import FeaturedPhoto from './FeaturedPhoto';

const SeasonalDashboardSections = () => (
  <Sections noContainingCard isSeasonalDashboardSections colWidth="8">
    <Section name="Goals">
      <SeasonalGoalTab />
    </Section>
    <Section name="Featured Photos" path="featured-photos">
      <FeaturedPhoto />
    </Section>
    <Section name="Verification Map" path="verification-map">
      <VerificationsMap />
    </Section>
    <Section name="Impressions" path="impressions">
      <ImpressionsTab />
    </Section>
    <Section name="Results" path="results">
      <ResultsTab />
    </Section>
    <Section name="Testimonials" path="testimonials">
      <TestimonialSection />
    </Section>
    <Section name="Creative" path="creative">
      <Creatives />
    </Section>
  </Sections>
);

SeasonalDashboardSections.propTypes = {};

export default SeasonalDashboardSections;
