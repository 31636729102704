import React from 'react';
import { graphql, Query } from 'react-apollo';
import { compose, curry, map, pipe } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import {
  getRecentOrganizationIds,
  getRecentOrganizationsStateFromLocalStorage,
} from 'utils/localStorage/recentOrganizations';
import { rejectFalsy } from 'utils/array';
import { findById } from 'utils/misc';
import SPONSORSHIP_INSIGHT_REPORT_FRAGMENT from 'fragments/SponsorshipInsightReportFragment';
import OrganizationsTable from './OrganizationsTable';

const noResultsMessage = (
  <p className="text-center font-weight-bold">
    No organizations found with your search criteria.
  </p>
);
const searchingMessage = (
  <p className="text-center font-italic">Searching organizations...</p>
);

export const OrganizationsResultsUnwrapped = ({
  localRecentOrganizations,
  organizations,
  recentOrganizations,
  searched,
  searching,
}) => {
  const searchComplete = searched && !searching;
  const organizationsLength = organizations.length;

  return (
    <>
      {searching && searchingMessage}
      {searchComplete &&
        (organizationsLength ? (
          <OrganizationsTable organizations={organizations} />
        ) : (
          noResultsMessage
        ))}
      {(!searched || (searchComplete && !organizationsLength)) && (
        <>
          {!!localRecentOrganizations.length && (
            <>
              <h3 className="mt-4 mb-1">Recently Viewed Organizations:</h3>
              <OrganizationsTable organizations={localRecentOrganizations} />
            </>
          )}
          {!!recentOrganizations.length && (
            <>
              <h3 className="mt-4 mb-1">Recently Updated Organizations:</h3>
              <OrganizationsTable organizations={recentOrganizations} />
            </>
          )}
        </>
      )}
    </>
  );
};

const organizationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  playLocation: PropTypes.shape({
    address: PropTypes.string.isRequired,
  }),
  sponsorshipInsightReport: PropTypes.shape({
    averageConfirmedValue: PropTypes.number.isRequired,
    confirmedCount: PropTypes.number.isRequired,
    onTimeVerificationPercentage: PropTypes.number,
  }),
  websiteUrl: PropTypes.string,
});

OrganizationsResultsUnwrapped.propTypes = {
  localRecentOrganizations: PropTypes.arrayOf(organizationPropType.isRequired),
  organizations: PropTypes.arrayOf(organizationPropType.isRequired),
  recentOrganizations: PropTypes.arrayOf(organizationPropType.isRequired),
  searched: PropTypes.bool,
  searching: PropTypes.bool,
};

OrganizationsResultsUnwrapped.defaultProps = {
  localRecentOrganizations: [],
  organizations: [],
  recentOrganizations: [],
  searched: false,
  searching: false,
};

const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on Organization {
    id
    name
    engagementStatus
    primaryPlayLocation {
      address
    }
    sponsorshipInsightReport {
      ...SponsorshipInsightReportFragment
    }
    websiteUrl
  }
  ${SPONSORSHIP_INSIGHT_REPORT_FRAGMENT}
`;

const LOCAL_RECENT_ORGANIZATIONS_QUERY = gql`
  query LocalRecentOrganizations($organizationIds: [Int!]!) {
    organizations(organizationIds: $organizationIds) {
      ...OrganizationFragment
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

const sortOrganizationsByIds = (ids, organizations) =>
  map((id) => findById(id)(organizations), ids);
const curriedSortOrganizationsByIds = curry(sortOrganizationsByIds);

const OrganizationsResultsWrappedWithLocal = (props) => {
  const state = getRecentOrganizationsStateFromLocalStorage();
  const organizationIds = getRecentOrganizationIds(state);
  const sortRecentOrganizations = pipe(
    curriedSortOrganizationsByIds(organizationIds),
    rejectFalsy,
  );

  return (
    <Query
      fetchPolicy="network-only"
      query={LOCAL_RECENT_ORGANIZATIONS_QUERY}
      variables={{ organizationIds }}
    >
      {({ data }) => {
        const localRecentOrganizations = data && data.organizations;
        const sortedOrganizations =
          localRecentOrganizations &&
          sortRecentOrganizations(localRecentOrganizations);

        return (
          <OrganizationsResultsUnwrapped
            localRecentOrganizations={sortedOrganizations}
            {...props}
          />
        );
      }}
    </Query>
  );
};

OrganizationsResultsWrappedWithLocal.propTypes = {
  organizations: PropTypes.arrayOf(organizationPropType.isRequired),
  recentOrganizations: PropTypes.arrayOf(organizationPropType.isRequired),
  searched: PropTypes.bool,
  searching: PropTypes.bool,
};

OrganizationsResultsWrappedWithLocal.defaultProps = {
  organizations: [],
  recentOrganizations: [],
  searched: false,
  searching: false,
};

const queryResults = graphql(
  gql`
    query SearchedOrganizations($query: String!) {
      searchedOrganizations(query: $query) {
        ...OrganizationFragment
      }
    }
    ${ORGANIZATION_FRAGMENT}
  `,
  {
    props: ({ data }) => ({
      searching: data.loading,
      organizations: data.searchedOrganizations,
    }),
    options: ({ query }) => ({
      fetchPolicy: 'network-only',
      variables: {
        query,
      },
    }),
    skip: (ownProps) => !ownProps.query,
  },
);

const getRecent = graphql(
  gql`
    query RecentOrganizations {
      recentOrganizations {
        ...OrganizationFragment
      }
    }
    ${ORGANIZATION_FRAGMENT}
  `,
  {
    props: ({ data }) => ({
      recentOrganizations: data.recentOrganizations,
    }),
    options: () => ({
      fetchPolicy: 'network-only',
    }),
  },
);

const OrganizationsResults = compose(
  queryResults,
  getRecent,
)(OrganizationsResultsWrappedWithLocal);

export default OrganizationsResults;
