import { createReducer } from 'redux-create-reducer';
import { F, T } from 'ramda';
import { LOCATION_CHANGE, push } from 'react-router-redux';
import { combineReducers } from 'redux';
import LeaguesideAPI from '../../utils/leagueside-api';
import { CAMPAIGN_PLANNER_PATH } from '../../utils/paths';

export const SAVE = 'campaign/add/save';
export const SAVE_SUCCEEDED = 'campaign/add/saveSucceeded';
export const SAVE_FAILED = 'campaign/add/saveFailed';

export const saveSucceeded = (savedCampaign) => (dispatch) => {
  dispatch({ type: SAVE_SUCCEEDED, payload: savedCampaign });
  setTimeout(
    () =>
      dispatch(
        push(
          `${CAMPAIGN_PLANNER_PATH}/${savedCampaign.sponsorId}/campaigns/${savedCampaign.id}`,
        ),
      ),
    500,
  );
};

export const save = (campaignProperties) => (dispatch) => {
  dispatch({ type: SAVE, payload: campaignProperties });

  const dispatchSaveSucceeded = (savedCampaign) =>
    dispatch(saveSucceeded(savedCampaign));
  const dispatchSaveFailed = () => dispatch({ type: SAVE_FAILED });

  return LeaguesideAPI.createCampaign(campaignProperties).then(
    dispatchSaveSucceeded,
    dispatchSaveFailed,
  );
};

export const saving = createReducer(false, {
  [SAVE]: T,
  [LOCATION_CHANGE]: F,
  [SAVE_SUCCEEDED]: F,
  [SAVE_FAILED]: F,
});

export const saved = createReducer(false, {
  [SAVE_SUCCEEDED]: T,
  [LOCATION_CHANGE]: F,
});

const add = combineReducers({
  saving,
  saved,
});

export default add;
