import { either, isNil, map, pipe, prop, reject } from 'ramda';

const nilLatOrLon = either(pipe(prop('lat'), isNil), pipe(prop('lon'), isNil));
export const getLocationsWithPoints = (locations) =>
  reject(nilLatOrLon, locations);
export const getRadii = (locations, radius) =>
  map(
    (location) => ({
      label: location.place,
      lon: location.lon,
      lat: location.lat,
      miles: radius,
    }),
    getLocationsWithPoints(locations),
  );

const nilGeoJson = pipe(prop('geoJson'), isNil);
export const getGeometries = (locations) =>
  map(
    (location) => ({
      id: location.id,
      geoJson: location.geoJson,
      label: location.place,
    }),
    reject(nilGeoJson, locations),
  );
