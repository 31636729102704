import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import RefetchDataContext from './RefetchDataContext';

const MUTATION = gql`
  mutation CreateLocationFilter($input: CreateLocationFilterInput!) {
    createLocationFilter(input: $input) {
      locationFilter {
        id
      }
    }
  }
`;

function CreateLocationFilterModalUnwrapped({ match }) {
  const { campaignId } = match.params;
  const [name, setName] = useState('Target Locations');
  const handleNameChange = (event) => setName(event.target.value);
  const refetchData = useContext(RefetchDataContext);

  return (
    <Mutation
      mutation={MUTATION}
      variables={{ input: { campaignId: Number(campaignId), name } }}
    >
      {(create) => (
        <ModalForm
          id={`create-location-filter-${campaignId}`}
          title="New Target Locations"
          onSubmit={async () => {
            await create();
            refetchData();
          }}
          toggleProps={{
            children: (
              <>
                <Icon value="hair-cross" className="text-muted mr-2" />
                Locations
              </>
            ),
            className: 'dropdown-item',
          }}
        >
          <label htmlFor="name">
            What should we call this new group of target locations?
          </label>
          <input
            className="form-control"
            name="name"
            onChange={handleNameChange}
            type="text"
            value={name}
          />
        </ModalForm>
      )}
    </Mutation>
  );
}

CreateLocationFilterModalUnwrapped.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      campaignId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const CreateLocationFilterModal = withRouter(
  CreateLocationFilterModalUnwrapped,
);

export default CreateLocationFilterModal;
