import * as React from 'react';

type AlertStyle =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'info'
  | 'light'
  | 'dark';

type AlertProps = React.HTMLAttributes<HTMLElement> &
  React.PropsWithChildren<{
    alertStyle?: AlertStyle;
    className?: string;
  }>;

function Alert(props: AlertProps) {
  const { alertStyle, children, className, ...rest } = props;
  return (
    <p className={`alert alert-${alertStyle} ${className}`} {...rest}>
      {children}
    </p>
  );
}

export default Alert;
