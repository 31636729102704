import React from 'react';
import { complement, map, merge, partition, path, pathEq } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import formatDate from 'utils/formatDate';
import { useSubmit } from 'utils/hooks';
import { pluckId } from 'utils/misc';
import { sortByOrganizationName } from 'utils/offer';
import ModalForm from 'components/ModalForm';
import ModalEmailBodyPreview from './ModalEmailBodyPreview';

const cannotReceiveEmails = complement(
  pathEq(['sponsorableProperty', 'organization', 'canReceiveEmails'], true),
);

const renderWarningOffer = ({ id, sponsorableProperty }) => (
  <li key={id}>
    {`${sponsorableProperty.organization.name} (${sponsorableProperty.name})`}
  </li>
);

function SponsorshipEmailModal({
  children,
  fetchEmail,
  id,
  offers,
  sendEmails,
  lastSentAtPath,
  submitProps,
  title,
  toggleProps,
  trackingEvents,
}) {
  const [offersThatCannotReceiveEmails, offersThatCanReceiveEmails] = partition(
    cannotReceiveEmails,
    offers,
  );
  const [offerToPreview] = offersThatCanReceiveEmails;

  const onSubmit = async () => {
    if (typeof trackingEvents === 'function') {
      trackingEvents(offersThatCanReceiveEmails);
    }
    await sendEmails(pluckId(offersThatCanReceiveEmails));
  };
  const { error, loading, handleSubmit } = useSubmit(onSubmit);

  const lastSentAt = path(lastSentAtPath);
  const renderOffer = (offer) => (
    <li key={offer.id} className="sui-mb-1">
      {`${offer.sponsorableProperty.organization.name} (${offer.sponsorableProperty.name})`}
      <ul className="sui-mt-0.5">
        {lastSentAt(offer) && (
          <li className="text-muted sui-mb-1" data-test="previously-sent">
            {'Previously sent on '}
            <strong>{formatDate(lastSentAt(offer))}</strong>
          </li>
        )}
      </ul>
    </li>
  );

  const renderSendBody = (
    <>
      {!!offersThatCannotReceiveEmails.length && (
        <div className="alert alert-warning" data-test="missing-contacts-alert">
          <p>
            We are missing contact emails for the following organizations so
            they will NOT be sent emails:
          </p>
          <ul className="sui-mb-3">
            {map(
              renderWarningOffer,
              sortByOrganizationName(offersThatCannotReceiveEmails),
            )}
          </ul>
        </div>
      )}
      {!!offersThatCanReceiveEmails.length && (
        <>
          <p className="sui-mb-2">
            Are you sure you want to send emails to the following organizations?
          </p>
          <ul className="sui-pl-4 sui-mb-3">
            {map(
              renderOffer,
              sortByOrganizationName(offersThatCanReceiveEmails),
            )}
          </ul>
          <p className="sui-mb-1">{`Preview of email to ${
            offerToPreview &&
            offerToPreview.sponsorableProperty.organization.name
          }:`}</p>
          <ModalEmailBodyPreview
            fetchEmail={() => fetchEmail(offerToPreview && offerToPreview.id)}
            title="Email Preview"
          />
        </>
      )}
    </>
  );

  return (
    <ModalForm
      id={id}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={merge(
        {
          children: loading ? 'Sending...' : 'Send Email',
          disabled: !offersThatCanReceiveEmails.length || loading,
        },
        submitProps,
      )}
      toggleProps={toggleProps}
      size="large"
      title={title}
    >
      {children}
      {!!offers.length && renderSendBody}
    </ModalForm>
  );
}

SponsorshipEmailModal.propTypes = {
  children: PropTypes.node,
  fetchEmail: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  lastSentAtPath: PropTypes.arrayOf(PropTypes.string).isRequired,
  offers: PropTypes.arrayOf(LeagueSidePropTypes.offer).isRequired,
  sendEmails: PropTypes.func.isRequired,
  submitProps: ModalForm.propTypes.submitProps,
  title: PropTypes.string.isRequired,
  toggleProps: ModalForm.propTypes.toggleProps.isRequired,
  trackingEvents: PropTypes.func,
};

SponsorshipEmailModal.defaultProps = {
  children: null,
  submitProps: undefined,
  trackingEvents: null,
};

export default SponsorshipEmailModal;
