import * as React from 'react';

interface Props extends React.HTMLAttributes<HTMLElement> {
  header: string;
}

const ConfirmedOfferOverviewSection: React.FunctionComponent<Props> = ({
  children,
  header,
}: Props) => (
  <section className="mb-5">
    <h3 className="m-0">{header}</h3>
    <hr className="my-2" />
    {children}
  </section>
);

export default ConfirmedOfferOverviewSection;
