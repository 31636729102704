import React from 'react';
import PropTypes from 'prop-types';

function renderBar(color, maximum, value, rest) {
  const percentage = !maximum || value === null ? 0 : (value / maximum) * 100;

  return (
    <div
      className={`progress-bar bg-${color}`}
      role="progressbar"
      aria-valuenow={Math.round(percentage)}
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ width: `${percentage}%` }}
      {...rest}
    />
  );
}
const renderChild = (child) => React.cloneElement(child, { isChild: true });
const renderChildren = (children) => React.Children.map(children, renderChild);

function ProgressBar({ children, isChild, color, maximum, value, ...rest }) {
  if (isChild) return renderBar(color, maximum, value, rest);

  return (
    <div className="progress">
      {children
        ? renderChildren(children)
        : renderBar(color, maximum, value, rest)}
    </div>
  );
}

ProgressBar.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'warning']),
  isChild: PropTypes.bool,
  maximum: PropTypes.number,
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  children: null,
  color: 'primary',
  isChild: false,
  maximum: 0,
  value: 0,
};

export default ProgressBar;
