import * as React from 'react';
import { Link } from 'react-router-dom';
import { seasonAndYearLabel } from 'utils/campaign';
import { formatToDollar } from 'utils/formatNumber';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import { CampaignStatus } from 'types/campaign';
import { Season } from 'types/season';
import DuplicateCampaignModal from './DuplicateCampaignModal';

interface Campaign {
  id: number;
  leagueBudget: number;
  name: string;
  season: Season;
  status: CampaignStatus;
  year: string;
}

interface Props {
  campaign: Campaign;
  sponsorId: number;
}

const CampaignsTableRow: React.FunctionComponent<Props> = ({
  campaign,
  sponsorId,
}: Props) => {
  const { id: campaignId, leagueBudget, name, status } = campaign;

  return (
    <tr data-test={`campaign-table-row-${campaignId}`}>
      <td>
        <Link
          to={`${CAMPAIGN_PLANNER_PATH}/${sponsorId}/campaigns/${campaignId}`}
        >
          {name}
        </Link>
      </td>
      <td>{seasonAndYearLabel(campaign)}</td>
      <td className="text-capitalize">{status}</td>
      <td>{leagueBudget ? formatToDollar(leagueBudget) : '--'}</td>
      <td className="text-right">
        <DuplicateCampaignModal originalName={name} campaignId={campaignId} />
      </td>
    </tr>
  );
};

export default CampaignsTableRow;
