import * as React from 'react';
import { GenericCreativeFileWithAllFields } from 'types/genericCreativeFile';
import ImageCard from 'components/ImageCard';

interface Props extends React.HTMLAttributes<HTMLElement> {
  genericCreativeFile: GenericCreativeFileWithAllFields;
}

const GenericCreativeFileCard: React.FunctionComponent<Props> = ({
  children,
  genericCreativeFile,
}: Props) => {
  const {
    id: creativeFileId,
    fullSizeUrl,
    name,
    thumbnailUrl,
  } = genericCreativeFile;

  return (
    <ImageCard
      cardId={`generic-creative-file-card-${creativeFileId}`}
      fullSizeUrl={fullSizeUrl}
      header={<small>{name}</small>}
      imgAlt={name}
      thumbnailUrl={thumbnailUrl}
    >
      {children}
    </ImageCard>
  );
};

export default GenericCreativeFileCard;
