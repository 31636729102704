import React, { useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeaguesideApi from 'utils/leagueside-api';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import { getId } from 'utils/id';
import showModal from 'utils/showModal';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import ImageInput from 'components/formInputs/ImageInput';
import NameInput from 'components/formInputs/NameInput';
import DeadlineSelectInput from 'components/formInputs/DeadlineSelectInput';
import ReplaceCreativesInput from 'components/formInputs/ReplaceCreativesInput';
import {
  CREATIVE_FILE_TEMPLATE_FRAGMENT,
  DEFAULT_VERIFICATION_DEADLINE_MINIMAL_FRAGMENT,
} from '../getCampaign';

export const CreateCreativeFileTemplateModalUnwrapped = ({
  capabilityName,
  defaultVerificationDeadlines,
  onUploadComplete,
  toggleClassName,
}) => {
  const [replaceCreatives, setReplaceCreatives] = useState(false);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [deadlineId, setDeadlineId] = useState('');

  const requiredDataPresent = name && image && deadlineId;

  async function asyncSubmit() {
    await LeaguesideApi.createCreativeFileTemplate(deadlineId, image, name);

    setName('');
    setImage(null);
    setDeadlineId('');
    setReplaceCreatives(false);

    onUploadComplete();

    if (replaceCreatives) showModal(`#${deadlineId}-replace-creatives-modal`);
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const submitLabel = 'Create';
  const id = getId([submitLabel, capabilityName, 'creative-file-template']);

  return (
    <ModalForm
      id={`${id}-modal`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, submitLabel),
        disabled: !requiredDataPresent || loading,
      }}
      title={`${submitLabel} Creative File?`}
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon value="plus" />
            Add Creative File
          </>
        ),
      }}
    >
      <p>
        To upload creative file, please specify which deadline the file should
        be associated with.
      </p>
      <DeadlineSelectInput
        data-test="deadline-select-input"
        deadlineId={deadlineId}
        deadlines={defaultVerificationDeadlines}
        isRequired
        loading={loading}
        onChange={({ target: { value } }) => setDeadlineId(value)}
        prefix={id}
      />
      <NameInput
        data-test="name-input"
        isRequired
        loading={loading}
        name={name}
        onChange={({ target: { value } }) => setName(value)}
        prefix={id}
      />
      <ImageInput
        data-test="image-input"
        image={image}
        isRequired
        loading={loading}
        onChange={({ target: { files } }) => setImage(files[0])}
        prefix={id}
      />
      <ReplaceCreativesInput
        disabled={loading}
        prefix={id}
        replaceCreatives={replaceCreatives}
        setReplaceCreatives={setReplaceCreatives}
      />
    </ModalForm>
  );
};

CreateCreativeFileTemplateModalUnwrapped.propTypes = {
  capabilityName: PropTypes.string.isRequired,
  defaultVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      deadline: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  onUploadComplete: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string.isRequired,
};

const REFETCH_QUERY = gql`
  query Campaign($id: Int!) {
    campaign(id: $id) {
      id
      campaignCapabilities {
        id
        defaultVerificationDeadlines {
          ...DefaultVerificationDeadlineMinimalFragment
          creativeFileTemplates {
            ...CreativeFileTemplateFragment
          }
          deadline
        }
      }
    }
  }
  ${DEFAULT_VERIFICATION_DEADLINE_MINIMAL_FRAGMENT}
  ${CREATIVE_FILE_TEMPLATE_FRAGMENT}
`;

const CreateCreativeFileTemplateModal = ({
  campaignId,
  capabilityName,
  defaultVerificationDeadlines,
  toggleClassName,
}) => (
  <Query
    fetchPolicy="network-only"
    query={REFETCH_QUERY}
    variables={{ id: campaignId }}
  >
    {({ refetch }) => (
      <CreateCreativeFileTemplateModalUnwrapped
        capabilityName={capabilityName}
        defaultVerificationDeadlines={defaultVerificationDeadlines}
        onUploadComplete={refetch}
        toggleClassName={toggleClassName}
      />
    )}
  </Query>
);

CreateCreativeFileTemplateModal.propTypes = {
  campaignId: PropTypes.number.isRequired,
  capabilityName: PropTypes.string.isRequired,
  defaultVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      deadline: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  toggleClassName: PropTypes.string.isRequired,
};

export default CreateCreativeFileTemplateModal;
