import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useWizardContext } from '../provider';
import { StepIdsEnum, StepTypeEnum } from '../types';
import WizardNavigationLegend from './wizard-navigation-legend';

export default function WizardNavigation() {
  const {
    wizardMap,
    token,
    activeSectionIndex,
    activeStepIndex,
    applicationProgressData: { applicationProgress },
  } = useWizardContext();

  const doneStepsPerId = useMemo(() => {
    return {
      [StepIdsEnum.INTRODUCTION]: true,
      ...applicationProgress,
    };
  }, [applicationProgress]);

  const doneSectionsPerId = useMemo(() => {
    if (!wizardMap) return null;
    const keys = Object.keys(wizardMap ?? {}) as StepTypeEnum[];
    const doneSections: Partial<Record<StepTypeEnum, boolean>> = keys.reduce(
      (acc, sectionKey) => ({
        ...acc,
        [sectionKey]: wizardMap[sectionKey].steps.every(
          (step) => doneStepsPerId[step.id],
        ),
      }),
      {},
    );
    return doneSections;
  }, [wizardMap, doneStepsPerId]);

  const wizardMapKeysList = Object.keys(wizardMap ?? {}) as StepTypeEnum[];

  return (
    <aside
      className="sui-flex-1 sui-border-r sui-border-solid sui-border-gray-80 sui-hidden d:sui-block"
      style={{ maxWidth: 240 }}
    >
      {wizardMap && (
        <nav className="sui-px-3 sui-pt-4 sui-pb-8">
          <ul className="sui-list-none sui-mb-0">
            {wizardMapKeysList.map(
              (key, sectionIndex) =>
                wizardMap[key].steps.length > 0 && (
                  <li
                    key={key}
                    className="sui-mb-2"
                    data-testid={`wizard-navigation-section-${key}`}
                  >
                    <WizardNavigationLegend
                      active={activeSectionIndex === sectionIndex}
                      done={Boolean(
                        doneSectionsPerId && doneSectionsPerId[key],
                      )}
                    >
                      {renderLinkOrText({
                        label: wizardMap[key].title,
                        to: `/qualify/${token}?step=${sectionIndex}-0`,
                        // testId: `wizard-navigation-section-${key}`,
                        evalCondition: () =>
                          Boolean(
                            (doneSectionsPerId && doneSectionsPerId[key]) ||
                              sectionIndex === 0 ||
                              (sectionIndex > 0 &&
                                doneSectionsPerId &&
                                doneSectionsPerId[
                                  wizardMapKeysList[sectionIndex - 1]
                                ]),
                          ),
                      })}
                    </WizardNavigationLegend>
                    {activeSectionIndex === sectionIndex && (
                      <ul className="sui-list-none sui-border-l sui-ml-1 sui-pl-1 sui-border-solid sui-border-gray-70 sui-mt-2">
                        {wizardMap[key].steps.map((step, stepIndex) => (
                          <li
                            key={step.name}
                            className="sui-mb-2"
                            data-testid={`wizard-navigation-section-link-${key}-${step.id}`}
                          >
                            <WizardNavigationLegend
                              listStyle="small"
                              active={activeStepIndex === stepIndex}
                              done={doneStepsPerId[step.id]}
                            >
                              {renderLinkOrText({
                                label: step.name,
                                to: `/qualify/${token}?step=${sectionIndex}-${stepIndex}`,
                                // testId: `wizard-navigation-section-link-${key}-${step.id}`,
                                evalCondition: () =>
                                  Boolean(
                                    doneStepsPerId[step.id] ||
                                      doneStepsPerId[
                                        wizardMap[key].steps[stepIndex - 1]?.id
                                      ],
                                  ),
                              })}
                            </WizardNavigationLegend>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ),
            )}
          </ul>
        </nav>
      )}
    </aside>
  );
}

function renderLinkOrText({
  label,
  to,
  evalCondition,
}: {
  label: string;
  to: string;
  evalCondition: () => boolean;
}) {
  if (evalCondition()) {
    return (
      <Link to={to} className="sui-no-underline" style={{ color: 'inherit' }}>
        {label}
      </Link>
    );
  }
  return label;
}
