import React from 'react';
import { filter, map } from 'ramda';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { pluckId } from 'utils/misc';
import { missingNeededShippingAddress } from 'utils/organization';
import OrganizationAddressModal, {
  getModalId,
} from 'components/OrganizationAddressModal';

function needsAddress(organization) {
  const { confirmedOffers, mailingAddress } = organization;

  return (
    !mailingAddress ||
    missingNeededShippingAddress(organization, confirmedOffers)
  );
}

const modalSelector = (organizationId) => `#${getModalId(organizationId)}`;
const filterNeedsAddress = filter(needsAddress);
const renderModal = (organization) => (
  <OrganizationAddressModal key={organization.id} organization={organization} />
);
const renderModals = map(renderModal);

class OrganizationsAddressModalQueuer extends React.Component {
  async componentDidMount() {
    const { $ } = this.props;
    const showOrganizationModal = (organizationId) =>
      $(modalSelector(organizationId)).modal('show');

    const promptAddressInput = (organizationId) =>
      new Promise((resolve) => {
        showOrganizationModal(organizationId);
        $(modalSelector(organizationId)).one('hidden.bs.modal', resolve);
      });

    const organizationIds = pluckId(this.organizationsToUpdate);

    for (let i = 0; i < organizationIds.length; i += 1) {
      await promptAddressInput(organizationIds[i]); // eslint-disable-line no-await-in-loop
    }
  }

  get organizationsToUpdate() {
    const { organizations } = this.props;
    return filterNeedsAddress(organizations);
  }

  render() {
    const { organizations } = this.props;
    return renderModals(organizations);
  }
}

OrganizationsAddressModalQueuer.propTypes = {
  organizations: PropTypes.arrayOf(LeagueSidePropTypes.organization).isRequired,
  $: PropTypes.func,
};

OrganizationsAddressModalQueuer.defaultProps = {
  $: global.$,
};

export default OrganizationsAddressModalQueuer;
