import * as React from 'react';
import CheckboxInput from './CheckboxInput';

interface Props extends React.HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  prefix?: string;
  requiresCreatives: boolean;
}

const RequiresCreativesInput: React.FunctionComponent<Props> = ({
  disabled,
  onChange,
  prefix,
  requiresCreatives,
}: Props) => (
  <CheckboxInput
    checked={requiresCreatives}
    disabled={disabled}
    label="Requires Creatives"
    onChange={onChange}
    prefix={prefix}
  />
);

RequiresCreativesInput.defaultProps = {
  disabled: false,
  prefix: undefined,
};

export default RequiresCreativesInput;
