import * as React from 'react';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import OfferCountsReport from 'types/offerCountsReport';
import Badge from 'components/Badge';
import NewWindowLink from 'components/NewWindowLink';
import RestrictTo from 'components/RestrictTo';
import AssetConflict from 'types/assetConflict';
import ConflictingAssetBadge from './ConflictingAssetBadge';

const title = (status: string) => `Number of Other Active ${status} Offers`;

interface Props {
  offerCountsReport: OfferCountsReport;
  sponsorablePropertyId: number;
  fundraiserConflict: AssetConflict;
  surveyConflict: AssetConflict;
}

const ActiveOffersBadges: React.FunctionComponent<Props> = ({
  offerCountsReport,
  sponsorablePropertyId,
  fundraiserConflict = { conflict: false },
  surveyConflict = { conflict: false },
}: Props) => {
  const { confirmed, proposed, prospecting, qualified } = offerCountsReport;

  return (
    <RestrictTo roles={['admin']}>
      <span className="text-nowrap">
        <NewWindowLink
          hideIcon
          to={`${ORGANIZATIONS_PATH}/${sponsorablePropertyId}/campaigns`}
        >
          <Badge
            badgeType={prospecting ? 'prospecting' : 'zero'}
            title={title('Prospecting')}
            size="sm"
          >
            {prospecting}
          </Badge>
          <Badge
            badgeType={qualified ? 'qualified' : 'zero'}
            title={title('Qualified')}
            size="sm"
          >
            {qualified}
          </Badge>
          <Badge
            badgeType={proposed ? 'proposed' : 'zero'}
            title={title('Proposed')}
            size="sm"
          >
            {proposed}
          </Badge>
          <Badge
            badgeType={confirmed ? 'confirmed' : 'zero'}
            title={title('Confirmed')}
            size="sm"
          >
            {confirmed}
          </Badge>
        </NewWindowLink>
        <ConflictingAssetBadge
          conflict={fundraiserConflict}
          assetName="Fundraising"
        />
        <ConflictingAssetBadge conflict={surveyConflict} assetName="Surveys" />
      </span>
    </RestrictTo>
  );
};

export default ActiveOffersBadges;
