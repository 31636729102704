import { useMemo } from 'react';
import { useWizardContext } from '../provider';
import { StepIdsEnum, StepTypeEnum } from '../types';
import s from './styles.module.css';

export default function WizardProgressBar() {
  const {
    wizardMap,
    activeSectionIndex,
    activeStepIndex,
    applicationProgressData,
  } = useWizardContext();

  const activeStepsIdsList = useMemo(() => {
    if (!wizardMap) return [];
    const wizardMapKeys = Object.keys(wizardMap ?? {}) as StepTypeEnum[];
    return wizardMapKeys.reduce(
      (acc, sectionKey) => [
        ...acc,
        ...wizardMap[sectionKey].steps.map((step) => step.id),
      ],
      [] as StepIdsEnum[],
    );
  }, [wizardMap]);

  const currentStepIndex = useMemo(() => {
    if (!wizardMap) return 0;
    const wizardMapKeys = Object.keys(wizardMap ?? {}) as StepTypeEnum[];
    const section = wizardMap[wizardMapKeys[activeSectionIndex ?? 0]];
    if (!section || !section?.steps[activeStepIndex ?? 0]) return null;
    const idToFind = section?.steps[activeStepIndex ?? 0].id;
    const activeIndex = activeStepsIdsList.findIndex((id) => id === idToFind);
    return activeIndex + 1;
  }, [activeStepsIdsList, wizardMap]);

  const progressValue = applicationProgressData.completionPercentage;

  return (
    <div
      className="sui-px-4 sui-pt-2 d:sui-hidden sui-flex sui-items-center"
      style={{ gap: '2rem' }}
    >
      <progress className={s.progressBar} value={progressValue ?? 0} max={100}>
        {progressValue}%
      </progress>
      <p className="sui-text-desktop-3 sui-text-gray-40">
        {currentStepIndex}/{activeStepsIdsList.length}
      </p>
    </div>
  );
}
