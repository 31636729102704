import { useScript } from 'utils/hooks';

const HubSpotChat = () => {
  const url = '//js.hs-scripts.com/2575409.js';
  const id = 'hs-script-loader';

  useScript(url, id);

  return null;
};

export default HubSpotChat;
