import { all, map, pipe, pluck, prop, split, trim } from 'ramda';
// Import polyfills for Internet Explorer
import 'string.prototype.codepointat/auto';
import 'unorm';
import { validate } from 'isemail';
import { joinWithComma, defaultToEmptyString } from './string';

export const leagueLocation = ({ city, state }) =>
  city && state ? `${city}, ${state}` : city || state;

export const listSportsNames = pipe(
  prop('sports'),
  pluck('name'),
  joinWithComma,
);

export const hasValidGeoData = ({ playLocation }) =>
  !!playLocation && !!playLocation.lat && !!playLocation.lon;

export const isValidContactEmail = pipe(
  defaultToEmptyString,
  split(','),
  map(trim),
  all(validate),
);
