import s from './styles.module.css';

type DangerousInnerHtmlProps = {
  html: string;
};

export default function DangerousInnerHtml(props: DangerousInnerHtmlProps) {
  const { html } = props;
  return (
    <div
      className={s.container}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}
