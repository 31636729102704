import React from 'react';
import { isEmpty, map } from 'ramda';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { findSponsorablePropertyId } from 'utils/offer';
import Map from 'components/Map';
import ProspectingCard from 'components/ProspectingCard';
import PropTypes from 'prop-types';

const ProspectCampaignSidebarAndSummary = ({
  campaign,
  prospectives,
  cable,
}) => {
  const { locationFilters } = campaign;
  const { addedOffers, removedOffers } = campaign;
  const addedSponsorablePropertyIds = new Set(
    addedOffers.map(findSponsorablePropertyId),
  );
  const removedSponsorablePropertyIds = new Set(
    removedOffers.map(findSponsorablePropertyId),
  );

  return (
    <div className="prospect-page__sidebar-and-summary">
      <div className="prospect-page__sidebar">
        {isEmpty(locationFilters) && (
          <p>No location filters are enabled for this campaign.</p>
        )}
        {map(
          (locationFilter) => (
            <ProspectingCard
              id={locationFilter.id}
              prospectingReportId={locationFilter.prospectingReportId}
              link="prospect-filter"
              name={locationFilter.name}
              cable={cable}
            />
          ),
          locationFilters,
        )}
      </div>
      <div className="summary">
        <Map
          addedSponsorablePropertyIds={addedSponsorablePropertyIds}
          campaignGeometries={campaign.campaignGeometries}
          removedSponsorablePropertyIds={removedSponsorablePropertyIds}
          sponsorableProperties={prospectives}
          radii={campaign.radii}
        />
      </div>
    </div>
  );
};

ProspectCampaignSidebarAndSummary.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
  prospectives: LeagueSidePropTypes.leagues.isRequired,
  cable: PropTypes.shape.isRequired,
};

export default ProspectCampaignSidebarAndSummary;
