import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { loadingTense, pluralizedTense } from 'utils/string';
import ModalForm from './ModalForm';

const MUTATION = gql`
  mutation AddAllAvailableSponsorablePropertiesToCampaign(
    $input: AddAllAvailableSponsorablePropertiesToCampaignInput!
  ) {
    addAllAvailableSponsorablePropertiesToCampaign(input: $input)
  }
`;

const AddAllAvailableSponsorablePropertiesModal = ({
  availableCount,
  input,
  onAdd,
}) => {
  const [mutate] = useMutation(MUTATION);
  const addAll = () => mutate({ variables: { input } }).then(onAdd);

  const { loading, handleSubmit } = useSubmit(addAll);
  return availableCount ? (
    <ModalForm
      id="add-all-available-sponsorable-properties-modal"
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{ children: loadingTense(loading, 'Add') }}
      toggleProps={{
        className: 'btn btn-link',
        children: `Add ${
          availableCount !== 1 && 'All '
        }${availableCount} Available`,
      }}
      title="Are you sure?"
    >
      <p>
        {`Are you sure you want to add ${
          availableCount !== 1 && 'all '
        }${availableCount} available sponsorable ${pluralizedTense(
          availableCount,
          'property',
        )} to this campaign?`}
      </p>
    </ModalForm>
  ) : null;
};

AddAllAvailableSponsorablePropertiesModal.propTypes = {
  availableCount: PropTypes.number.isRequired,
  input: PropTypes.shape({
    campaignId: PropTypes.number,
    searchFilterId: PropTypes.number,
  }).isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default AddAllAvailableSponsorablePropertiesModal;
