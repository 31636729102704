import axios from 'axios';
import { map, pick, prop } from 'ramda';
import runtimeEnv from '@mars/heroku-js-runtime-env';
import { received } from 'modules/caughtErrors';
import { sanitizeName } from './string';

const env = runtimeEnv();

axios.defaults.baseURL = env.REACT_APP_SERVICE_BASE_URL;
axios.defaults.headers = { 'Content-Type': 'application/json' };

function withAuthHeader(options = {}) {
  const token = localStorage.getItem('token');
  return {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  };
}

function handleAuthenticationRejection(rejection) {
  if (rejection.response.status === 404) {
    return { message: 'Unknown username or password.' };
  }
  // TODO: Log

  return { message: 'An unknown error has occurred. Please contact support.' };
}

function handleAcceptanceRejection(rejection) {
  if (rejection.response.data.password) {
    return {
      message:
        'Your password must have at least one uppercase letter, one lowercase letter, and one digit.',
    };
  }

  if (rejection.response.data.password_confirmation) {
    return { message: 'Your passwords must match.' };
  }

  return { message: 'An unknown error has occurred. Please contact support.' };
}

function handleInternalServerError(error) {
  if (error && error.response && error.response.status >= 500) {
    window.store.dispatch(received({ showError: true }));
  } else {
    throw error;
  }
}

const getData = prop('data');
const get = (path) => axios.get(path, withAuthHeader()).then(getData);
const post = (path, data) =>
  axios.post(path, data, withAuthHeader()).then(getData);
const patch = (path, data) =>
  axios.patch(path, data, withAuthHeader()).then(getData);

const deserializeCampaign = (json) => ({
  endsAt: json.ends_at,
  id: json.id,
  name: json.name,
  sponsorId: json.sponsor_id,
  startsAt: json.starts_at,
});

const deserializeLocationList = (json) => ({
  id: json.id,
  locations: json.location_list_items,
});

const deserializeSearchFilter = (json) => ({
  id: json.id,
  enabled: json.enabled,
  invalidZips: json.invalid_zips,
  parameters: json.parameters,
  type: json.filter_type,
});

const deserializeSponsor = pick(['id', 'name']);
const deserializeSport = pick(['id', 'name']);
const deserializeUser = pick(['id', 'name', 'email', 'status']);

const serializeCampaign = (campaignObject) => ({
  ends_at: campaignObject.endsAt,
  name: campaignObject.name,
  sponsor_id: campaignObject.sponsorId,
  season: campaignObject.season,
  year: campaignObject.year,
  starts_at: campaignObject.startsAt,
});

const downloadDocx = (path) =>
  axios
    .get(path, withAuthHeader({ responseType: 'arraybuffer' }))
    .catch(handleInternalServerError)
    .then(({ data, request }) => {
      const a = document.createElement('a');
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      const url = window.URL.createObjectURL(blob);
      const [, filename] = request
        .getResponseHeader('Content-Disposition')
        .split('"');

      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });

async function uploadCsvToUpdateLeague(file) {
  const path = '/update-leagues-with-csv';
  const formData = new FormData();
  formData.append('file', file);

  const response = await axios
    .post(
      path,
      formData,
      withAuthHeader({
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    )
    .catch((err) => {
      handleInternalServerError(err);
    });

  return response.data;
}

export default {
  acceptInvitation(
    token,
    password,
    passwordConfirmation,
    name,
    title,
    cellPhone,
  ) {
    return axios
      .post(`/invitations/${token}/accept`, {
        password,
        password_confirmation: passwordConfirmation,
        name,
        title,
        cell_phone: cellPhone,
      })
      .then(prop('data'), handleAcceptanceRejection);
  },
  accountRecovery(email) {
    return post('/account_recovery', { email });
  },
  authenticate(email, password) {
    return axios
      .post('/user_token', { auth: { email, password } })
      .then(getData, handleAuthenticationRejection);
  },
  changePassword(recoveryToken, password, passwordConfirmation) {
    return post('/reset_password', {
      reset_password: {
        recovery_token: recoveryToken,
        password,
        password_confirmation: passwordConfirmation,
      },
    });
  },
  createCampaign(campaignProperties) {
    return post(
      `/sponsors/${campaignProperties.sponsorId}/campaigns`,
      serializeCampaign(campaignProperties),
    )
      .catch(handleInternalServerError)
      .then(deserializeCampaign);
  },
  createCreativeFile(verificationDeadlineId, image, name) {
    const formData = new FormData();
    formData.append('verificationDeadlineId', verificationDeadlineId);
    formData.append('image', image);
    formData.append('name', sanitizeName(name));

    return axios
      .post(
        'creative_files',
        formData,
        withAuthHeader({ headers: { 'Content-Type': 'multipart/form-data' } }),
      )
      .catch(handleInternalServerError);
  },
  createCreativeFileTemplate(defaultVerificationDeadlineId, image, name) {
    const formData = new FormData();
    formData.append(
      'defaultVerificationDeadlineId',
      defaultVerificationDeadlineId,
    );
    formData.append('image', image);
    formData.append('name', sanitizeName(name));

    return axios
      .post(
        'creative_file_templates',
        formData,
        withAuthHeader({ headers: { 'Content-Type': 'multipart/form-data' } }),
      )
      .catch(handleInternalServerError);
  },
  createLocationList(sponsorId, file) {
    const formData = new FormData();
    formData.append('locations_file', file);
    return axios
      .post(
        `sponsors/${sponsorId}/location_lists`,
        formData,
        withAuthHeader({
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      )
      .catch(handleInternalServerError)
      .then((response) => deserializeLocationList(response.data.locationList));
  },
  createSearchFilter(campaignId, filter) {
    const path = `/campaigns/${campaignId}/search_filters`;
    const data = {
      filter_type: filter.type,
      parameters: filter.parameters,
    };
    return post(path, data)
      .catch(handleInternalServerError)
      .then(deserializeSearchFilter);
  },
  addCsvLocationsToSearchFilter(filterId, file) {
    const path = `/search_filters/${filterId}/add_csv_locations`;
    const formData = new FormData();
    formData.append('file', file);
    return axios
      .post(
        path,
        formData,
        withAuthHeader({
          headers: { 'Content-Type': 'multipart/form-data' },
        }),
      )
      .catch(handleInternalServerError);
  },
  uploadCsvToUpdateLeague,
  createSponsor(sponsorProperties) {
    return post('/sponsors', sponsorProperties)
      .catch(handleInternalServerError)
      .then(deserializeSponsor);
  },
  createSponsorFacingGoalImage(campaignId, image) {
    const formData = new FormData();
    formData.append('campaign_id', campaignId);
    formData.append('image', image);

    return axios
      .post(
        'sponsor_facing_goal_images',
        formData,
        withAuthHeader({ headers: { 'Content-Type': 'multipart/form-data' } }),
      )
      .catch(handleInternalServerError);
  },
  createSponsorLogo(sponsorId, image) {
    const formData = new FormData();
    formData.append('sponsor_id', sponsorId);
    formData.append('image', image);

    return axios
      .post(
        'sponsor_logos',
        formData,
        withAuthHeader({ headers: { 'Content-Type': 'multipart/form-data' } }),
      )
      .catch(handleInternalServerError);
  },
  createVerificationImageWithDeadline(
    verificationDeadlineId,
    image,
    hidden,
    from,
    status,
  ) {
    const formData = new FormData();
    formData.append('verificationDeadlineId', verificationDeadlineId);
    formData.append('image', image);
    formData.append('hidden', hidden);
    formData.append('from', from);
    formData.append('status', status);

    return axios
      .post(
        'verification_images',
        formData,
        withAuthHeader({ headers: { 'Content-Type': 'multipart/form-data' } }),
      )
      .catch((error) => error);
  },
  deleteSearchFilter(filterId) {
    return axios
      .delete(`/search_filters/${filterId}`, withAuthHeader())
      .catch(handleInternalServerError);
  },
  downloadLeagueList(campaignId) {
    return downloadDocx(`/league_lists/${campaignId}.docx`);
  },
  downloadOfferSponsorablePropertyList(campaignId, statuses) {
    return downloadDocx(
      `/sponsorable_property_lists/${campaignId}.docx?statuses[]=${statuses.join(
        '&statuses[]=',
      )}`,
    );
  },
  generateLeagues(data) {
    return post('/league_generator', data).catch(handleInternalServerError);
  },
  getInvitation(token) {
    return get(`/invitations/${token}`).then(deserializeUser);
  },
  getSearchFilters(campaignId) {
    const path = `/campaigns/${campaignId}/search_filters`;

    return get(path)
      .catch(handleInternalServerError)
      .then(map(deserializeSearchFilter));
  },
  getSports() {
    return get('/sports').then(map(deserializeSport));
  },
  getUsers() {
    return get('/users')
      .catch(handleInternalServerError)
      .then(map(deserializeUser));
  },
  organizationSelfSignup(contactEmail, name) {
    return post('/organization_self_signups', {
      self_signup: { contact_email: contactEmail, name },
    }).catch(handleInternalServerError);
  },
  patchSearchFilter(filterId, props) {
    const path = `/search_filters/${filterId}`;

    return patch(path, props)
      .catch(handleInternalServerError)
      .then(deserializeSearchFilter);
  },
  validateRecoveryToken(recoveryToken) {
    return get(`/reset_password/${recoveryToken}`);
  },
};
