import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { equals } from 'ramda';
import gql from 'graphql-tag';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import type { VerificationDeadline, VerificationImage } from 'types/offer';
import DeadlineSelectInput from 'components/formInputs/DeadlineSelectInput';
import ModalForm from 'components/ModalForm';
import SelectInput from 'components/formInputs/SelectInput';
import VerificationImageCard from 'components/VerificationImageCard';
import VisibilityInput from 'components/formInputs/VisibilityInput';
import showModal from 'utils/showModal';
import CheckboxInput from 'components/formInputs/CheckboxInput';
import SendRequestAdditionalVerificationEmailModal from './send-request-additional-verification-email-modal';
import { VERIFICATION_IMAGE_FRAGMENT } from '../fragments';

const equalsPending = equals('pending');
const equalsVerified = equals('verified');
const equalsRejected = equals('rejected');

type ReviewVerificationImageModalProps = {
  initialVerificationDeadlineId?: number;
  initialFeaturedStatus: boolean;
  pendingCard?: boolean;
  togglePropsChildren: string;
  verificationDeadlines: VerificationDeadline[];
  verificationImage: VerificationImage;
};

function ReviewVerificationImageModal(
  props: ReviewVerificationImageModalProps,
) {
  const {
    initialVerificationDeadlineId,
    initialFeaturedStatus,
    pendingCard,
    togglePropsChildren = 'Review',
    verificationDeadlines,
    verificationImage,
  } = props;
  const [mutate] = useMutation(MUTATION);
  const { status: initialStatus } = verificationImage;
  const [status, setStatus] = useState(
    equalsPending(initialStatus) ? '' : initialStatus,
  );
  const [visibility, setVisibility] = useState<'true' | 'false'>(
    verificationImage.hidden ? 'true' : 'false',
  );
  const [verificationDeadlineId, setVerificationDeadlineId] = useState(
    initialVerificationDeadlineId,
  );
  const [featured, setFeatured] = useState(initialFeaturedStatus);

  const isVerified = equalsVerified(status);

  const save = ({
    hidden,
    status: statusToSet,
    verificationDeadlineId: verificationDeadlineIdToSet,
    featured: featuredToSet,
  }: any) =>
    mutate({
      variables: {
        input: {
          id: Number(verificationImage.id),
          hidden,
          status: statusToSet,
          verificationDeadlineId: Number(verificationDeadlineIdToSet) || null,
          featured: featuredToSet,
        },
      },
    });

  async function onSubmit() {
    await save({
      hidden: visibility === 'true',
      status,
      verificationDeadlineId,
      featured,
    });
    if (status === 'rejected')
      showModal(`#${SendRequestAdditionalVerificationEmailModal.ID}`);
  }

  const { error, loading, handleSubmit } = useSubmit(onSubmit);

  const handleStatusChange = (value: string) => {
    if (!equalsVerified(value)) {
      setVisibility('false');
    }

    if (equalsRejected(value)) {
      setFeatured(false);
    }

    return setStatus(value);
  };

  return (
    <ModalForm
      id={`review-${pendingCard ? 'pending-' : ''}verification-modal-${
        verificationImage.id
      }`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Save'),
        disabled: !status || (isVerified && !verificationDeadlineId) || loading,
      }}
      toggleProps={{
        className: 'btn btn-link p-0 mx-auto',
        children: togglePropsChildren,
      }}
      title="Review Verification"
    >
      <div className="text-center mb-3">
        <VerificationImageCard
          displayHeader
          verificationImage={verificationImage}
        />
      </div>
      <SelectInput
        data-test="status-input"
        disabled={loading}
        label="Status"
        labelClassName="font-weight-bold"
        noDefault
        onChange={(ev) =>
          handleStatusChange((ev.target as HTMLSelectElement).value)
        }
        required
        value={status}
      >
        <option value="" disabled>
          -- Pending --
        </option>
        <option value="verified">Accepted</option>
        <option value="rejected">Rejected</option>
      </SelectInput>
      <DeadlineSelectInput
        data-test="verified-deadline-input"
        deadlineId={String(verificationDeadlineId)}
        deadlines={verificationDeadlines}
        isRequired={isVerified}
        loading={loading}
        onChange={(ev) =>
          setVerificationDeadlineId(
            Number((ev.target as HTMLSelectElement).value),
          )
        }
      />
      {isVerified && (
        <VisibilityInput
          data-test="visibility-input"
          disabled={loading}
          onChange={(ev) =>
            setVisibility(
              (ev.target as HTMLSelectElement).value as 'true' | 'false',
            )
          }
          required
          visibility={visibility}
        />
      )}
      <CheckboxInput
        checked={featured}
        label="Featured Photo"
        disabled={loading || equalsRejected(status)}
        onChange={(ev) => setFeatured((ev.target as HTMLInputElement).checked)}
        prefix={`update-featured-image-${verificationImage.id}`}
      />
    </ModalForm>
  );
}

export default ReviewVerificationImageModal;

const MUTATION = gql`
  mutation ReviewVerificationImage($input: ReviewVerificationImageInput!) {
    reviewVerificationImage(input: $input) {
      offer {
        id
        anyVerificationDeadlinesToIncludeInEmails
        pendingImages: verificationImages(status: pending) {
          id
          hidden
          status
          verificationDeadline {
            id
          }
        }
        verificationDeadlines {
          id
          anyPendingVerificationImages
          didNotComplete
          overdue
          verificationImages(statuses: [pending, verified]) {
            ...VerificationImageFragment
          }
          verified
        }
      }
    }
  }
  ${VERIFICATION_IMAGE_FRAGMENT}
`;
