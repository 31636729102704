import React from 'react';
import PropTypes from 'prop-types';
import { joinTruthyWithSpace } from 'utils/string';
import CircledIcon from 'components/CircledIcon';

const iconFontSize = 10;
const iconSize = 16;

const ICON_PROPS_BY_STATUS = {
  verified: {
    textColor: 'white',
    backgroundColor: 'primary',
    fontSize: iconFontSize,
    title: 'Verified',
    value: 'check',
  },
  overdue: {
    textColor: 'white',
    backgroundColor: 'warning',
    fontSize: iconFontSize,
    title: 'Overdue',
    value: 'warning',
  },
  pending: {
    textColor: 'secondary',
    backgroundColor: 'secondary',
    fontSize: iconFontSize,
    title: 'Pending',
    value: 'check',
  },
  didNotComplete: {
    textColor: 'white',
    backgroundColor: 'danger',
    fontSize: iconFontSize,
    title: 'Did Not Complete',
    value: 'cross',
  },
  anyPendingVerificationImages: {
    fontSize: iconSize,
    textColor: 'warning',
    title: 'Verifications Under Review',
    value: 'images',
  },
};

function VerificationsListIcon({
  anyPendingVerificationImages,
  className,
  didNotComplete,
  overdue,
  verified,
}) {
  const { textColor, ...iconProps } = (() => {
    if (anyPendingVerificationImages)
      return ICON_PROPS_BY_STATUS.anyPendingVerificationImages;
    if (verified) return ICON_PROPS_BY_STATUS.verified;
    if (didNotComplete) return ICON_PROPS_BY_STATUS.didNotComplete;
    if (overdue) return ICON_PROPS_BY_STATUS.overdue;

    return ICON_PROPS_BY_STATUS.pending;
  })();

  return (
    <CircledIcon
      className={joinTruthyWithSpace([`text-${textColor}`, className])}
      size={iconSize}
      {...iconProps}
    />
  );
}

VerificationsListIcon.propTypes = {
  anyPendingVerificationImages: PropTypes.bool,
  className: PropTypes.string,
  didNotComplete: PropTypes.bool,
  overdue: PropTypes.bool,
  verified: PropTypes.bool,
};

VerificationsListIcon.defaultProps = {
  anyPendingVerificationImages: false,
  className: '',
  didNotComplete: false,
  overdue: false,
  verified: false,
};

export default VerificationsListIcon;
