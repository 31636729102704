import axios from 'axios';
import { identity } from 'ramda';
import { logout } from 'modules/authentication';
import store from 'store';

export default function logoutIfRequestUnauthorized(error) {
  if (error.response && error.response.status === 401) {
    store.dispatch(logout());
  }

  return Promise.reject(error);
}

axios.interceptors.response.use(identity, logoutIfRequestUnauthorized);
