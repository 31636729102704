import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import type { CreativeContent } from 'types/offer';
import RemoveGenericCreativeModal from 'components/RemoveGenericCreativeModal';

type RemoveCreativeContentModalProps = {
  creativeContent: CreativeContent;
  toggleClassName?: string;
};

function RemoveCreativeContentModal(props: RemoveCreativeContentModalProps) {
  const { creativeContent, toggleClassName = 'btn btn-link text-danger p-0' } =
    props;

  return (
    <Mutation mutation={MUTATION}>
      {(mutate: any) => (
        <RemoveGenericCreativeModal
          creativeType="content"
          id={creativeContent.id}
          name={creativeContent.name}
          remove={() =>
            mutate({
              variables: {
                input: { id: Number(creativeContent.id) },
              },
            })
          }
          toggleClassName={toggleClassName}
        />
      )}
    </Mutation>
  );
}

export default RemoveCreativeContentModal;

const MUTATION = gql`
  mutation DeleteCreativeContent($input: CreativeContentInput!) {
    deleteCreativeContent(input: $input) {
      verificationDeadline {
        id
        creativeContents {
          id
        }
        missingCreatives
      }
    }
  }
`;
