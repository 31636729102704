import React from 'react';
import { map } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import formatDate from 'utils/formatDate';
import { sortByProp } from 'utils/sort';
import EventsSurveyResponseLink from './EventsSurveyResponseLink';

const sortBySubmittedAt = sortByProp('submittedAt');

const EventsSurveyResponsesLinkDropdown = (props) => {
  const { eventsSurveyResponses, small } = props;
  const surveyResponsesLength = eventsSurveyResponses.length;

  switch (surveyResponsesLength) {
    case 0:
      return null;
    case 1:
      return (
        <div
          data-test={`events-survey-response-link-${eventsSurveyResponses[0].surveyGizmoResponseId}`}
        >
          <EventsSurveyResponseLink
            eventsSurveyResponse={eventsSurveyResponses[0]}
            label="Events Survey"
            {...props}
          />
        </div>
      );
    default:
      return (
        <div className="dropdown">
          <button
            className={classNames('btn btn-link dropdown-toggle p-0 border-0', {
              'btn-sm': small,
            })}
            type="button"
            data-toggle="dropdown"
            data-test="events-survey-response-dropdown"
          >
            {`${surveyResponsesLength} Events Surveys`}
          </button>
          <div className="dropdown-menu px-0 py-1" style={{ minWidth: '0px' }}>
            {map(
              (eventsSurveyResponse) => (
                <div
                  className={`px-2 py-${small ? 0 : 1}`}
                  key={eventsSurveyResponse.surveyGizmoResponseId}
                  data-test={`events-survey-response-link-${eventsSurveyResponse.surveyGizmoResponseId}`}
                >
                  <EventsSurveyResponseLink
                    eventsSurveyResponse={eventsSurveyResponse}
                    label={formatDate(eventsSurveyResponse.submittedAt)}
                    {...props}
                  />
                </div>
              ),
              sortBySubmittedAt(eventsSurveyResponses),
            )}
          </div>
        </div>
      );
  }
};

EventsSurveyResponsesLinkDropdown.propTypes = {
  eventsSurveyResponses: LeagueSidePropTypes.eventsSurveyResponses,
  small: PropTypes.bool,
};

EventsSurveyResponsesLinkDropdown.defaultProps = {
  eventsSurveyResponses: [],
  small: false,
};

export default EventsSurveyResponsesLinkDropdown;
