import React, { useState } from 'react';
import { complement, isEmpty, isNil } from 'ramda';
import PropTypes from 'prop-types';
import { isNotEmpty } from 'utils/misc';
import utilHideModal from 'utils/hideModal';
import {
  isValidContactEmail,
  leagueLocation as getOrganizationLocation,
} from 'utils/league';
import Modal, { ModalBody, ModalFooter } from 'components/Modal';
import AddButton from 'components/AddButton';
import OrganizationCreateFormInputs from './OrganizationCreateFormInputs';
import OrganizationSelector from './OrganizationSelector';

const URL_PROTOCOL_PLACEHOLDER = 'http://';
const doesNotAppearToBeEmails = complement(isValidContactEmail);
const filterPlaceholder = (url) =>
  url === URL_PROTOCOL_PLACEHOLDER ? null : url;

const organizationContent = (organization) => (
  <>
    {organization.name}
    {organization.offersExist && (
      <span className="badge ml-1 badge-primary">Offers Exist</span>
    )}
    <div className="text-truncate">
      <small className="text-muted">
        {getOrganizationLocation(organization)}
      </small>
    </div>
  </>
);

const CreateOrSelectOrganizationModal = ({
  add,
  campaign: { id: campaignId, name: campaignName },
  create,
  redirect,
  toggleProps,
}) => {
  const [organizationId, setOrganizationId] = useState(null);
  const [organizationName, setOrganizationName] = useState('');
  const [organizationContactEmail, setOrganizationContactEmail] = useState('');
  const [organizationContactPhone, setOrganizationContactPhone] = useState('');
  const [organizationWebsiteUrl, setOrganizationWebsiteUrl] =
    useState('http://');
  const [locations, setLocations] = useState([]);
  const [organizationLocationQuery, setOrganizationLocationQuery] =
    useState('');
  const [winterSportIds, setWinterSportIds] = useState([]);
  const [springSportIds, setSpringSportIds] = useState([]);
  const [summerSportIds, setSummerSportIds] = useState([]);
  const [fallSportIds, setFallSportIds] = useState([]);
  const [showOrganizationCreateForm, setShowOrganizationCreateForm] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const modalId = 'create-or-select-organization-modal';

  const handleRemovePlayLocation = (address) => {
    setLocations(locations.filter((location) => location !== address));
  };

  const resetFormState = () => {
    setOrganizationId(null);
    setOrganizationContactEmail('');
    setOrganizationContactPhone('');
    setOrganizationName('');
    setLocations([]);
    setOrganizationLocationQuery('');
    setOrganizationWebsiteUrl('http://');
    setWinterSportIds([]);
    setSpringSportIds([]);
    setSummerSportIds([]);
    setFallSportIds([]);
    setShowOrganizationCreateForm(false);
  };

  const hideModal = () => {
    utilHideModal(`#${modalId}`);
    resetFormState();
  };
  const toggleShowCreateOrganizationForm = () =>
    setShowOrganizationCreateForm(!showOrganizationCreateForm);

  const atleastOneSeasonHasASport =
    winterSportIds.length +
      fallSportIds.length +
      summerSportIds.length +
      springSportIds.length >
    0;

  const hasDisabledAddButton = () => {
    if (!showOrganizationCreateForm) return !organizationId;

    if (
      isNotEmpty(organizationContactEmail) &&
      doesNotAppearToBeEmails(organizationContactEmail)
    )
      return true;

    return (
      !organizationName || isEmpty(locations) || !atleastOneSeasonHasASport
    );
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    if (showOrganizationCreateForm) {
      const response = await create({
        contactEmail: organizationContactEmail,
        contactPhone: organizationContactPhone,
        name: organizationName,
        locations,
        winterSportIds,
        springSportIds,
        summerSportIds,
        fallSportIds,
        websiteUrl: filterPlaceholder(organizationWebsiteUrl),
      });

      if (isNil(campaignId)) {
        redirect({
          organizationId: response.data.createOrganization.organization.id,
        });
      }
    } else {
      add({ organizationId });
    }

    setLoading(false);
    hideModal();
  }

  const title = campaignName
    ? `Add Organization to ${campaignName}`
    : 'Add Organization';
  // eslint-disable-next-line consistent-return
  const setIdOrNavigateToOrganizationPage = ({
    organizationId: selectedOrganization,
  }) => {
    if (selectedOrganization && isNil(campaignId)) {
      redirect({ organizationId: selectedOrganization });
    }

    setOrganizationId(selectedOrganization);
  };

  return (
    <>
      <button
        className="btn btn-link dropdown-item"
        data-target={`#${modalId}`}
        data-toggle="modal"
        type="button"
        {...toggleProps}
      />
      <Modal id={modalId} title={title} onClose={hideModal}>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            {showOrganizationCreateForm ? (
              <OrganizationCreateFormInputs
                name={organizationName}
                setName={setOrganizationName}
                contactEmail={organizationContactEmail}
                setContactEmail={setOrganizationContactEmail}
                contactPhone={organizationContactPhone}
                setContactPhone={setOrganizationContactPhone}
                websiteUrl={organizationWebsiteUrl}
                setWebsiteUrl={setOrganizationWebsiteUrl}
                setLocation={setLocations}
                locations={locations}
                locationQuery={organizationLocationQuery}
                setLocationQuery={setOrganizationLocationQuery}
                removeLocation={handleRemovePlayLocation}
                winterSportIds={winterSportIds}
                springSportIds={springSportIds}
                summerSportIds={summerSportIds}
                fallSportIds={fallSportIds}
                setWinterSportIds={setWinterSportIds}
                setSpringSportIds={setSpringSportIds}
                setSummerSportIds={setSummerSportIds}
                setFallSportIds={setFallSportIds}
                loading={loading}
              />
            ) : (
              <OrganizationSelector
                organizationContent={organizationContent}
                campaignId={campaignId}
                loading={loading}
                onSelect={setIdOrNavigateToOrganizationPage}
                toggleShowCreateOrganizationForm={
                  toggleShowCreateOrganizationForm
                }
                query={organizationName}
                onChange={setOrganizationName}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              data-test={`${modalId}-cancel`}
              data-dismiss="modal"
              type="button"
              onClick={() => resetFormState()}
            >
              Cancel
            </button>
            <AddButton
              dataTest={`${modalId}-submit`}
              disabled={hasDisabledAddButton()}
              adding={loading}
              subject="Organization"
            />
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

CreateOrSelectOrganizationModal.propTypes = {
  add: PropTypes.func.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  create: PropTypes.func.isRequired,
  redirect: PropTypes.func,
  toggleProps: PropTypes.shape({
    className: PropTypes.string,
    children: PropTypes.node,
  }),
};

CreateOrSelectOrganizationModal.defaultProps = {
  campaign: {},
  redirect: () => {},
  toggleProps: {},
};

export default CreateOrSelectOrganizationModal;
