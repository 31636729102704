import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { CAMPAIGN_PLANNER_PATH } from 'utils/paths';
import RichTextInput from 'components/RichTextInput';
import WithSaveFooter from 'components/WithSaveFooter';
import VisibleToSponsorIndicator from 'components/VisibleToSponsorIndicator';
import Page from 'components/Page';
import Loader from 'components/Loader';
import BackToLink from 'components/BackToLink';

const FETCH_QUERY = gql`
  query SeasonalGoal($sponsorId: Int!, $season: String, $year: Int) {
    sponsor(id: $sponsorId) {
      id
      name
      seasonalGoal(season: $season, year: $year) {
        id
        sponsorFacingGoalBody
      }
    }
  }
`;

const ADD_MUTATION = gql`
  mutation CreateSeasonalGoal($input: CreateSeasonalGoalInput!) {
    createSeasonalGoal(input: $input) {
      seasonalGoal {
        id
        sponsorFacingGoalBody
        sponsor {
          id
        }
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateSeasonalGoal($input: UpdateSeasonalGoalInput!) {
    updateSeasonalGoal(input: $input) {
      seasonalGoal {
        id
        sponsorFacingGoalBody
        sponsor {
          id
        }
      }
    }
  }
`;

const AddSeasonalGoal = ({ match }) => {
  const { sponsorId, season, year } = match.params;
  const { data, loading, refetch } = useQuery(FETCH_QUERY, {
    variables: { sponsorId: Number(sponsorId), season, year: Number(year) },
  });

  const [addMutate] = useMutation(ADD_MUTATION);
  const [updateMutate] = useMutation(UPDATE_MUTATION);

  const sponsor = data && data.sponsor;
  const goal = data && data.sponsor.seasonalGoal;
  let goalBody = goal ? goal.sponsorFacingGoalBody : '';

  const onSave = async (input) => {
    if (goal) {
      await updateMutate({
        variables: { input: { ...input, id: goal.id } },
      });
    } else {
      await addMutate({
        variables: {
          input: {
            ...input,
            sponsorId: Number(sponsorId),
            season,
            year: Number(year),
          },
        },
      });
    }
    const { data: refetchData } = await refetch();
    goalBody = refetchData.sponsor.seasonalGoal.sponsorFacingGoalBody;
  };

  if (loading) return <Loader />;

  return (
    <Page loaded={!!sponsor}>
      {!!sponsor && (
        <>
          <BackToLink
            label={sponsor.name}
            path={`${CAMPAIGN_PLANNER_PATH}/${sponsor.id}`}
          />
          <h1>{sponsor.name}</h1>
          <p>
            <strong>Campaign Goals</strong>
          </p>
          <WithSaveFooter
            handleMutation={onSave} // eslint-disable-line react/destructuring-assignment
            savedValue={goalBody} // eslint-disable-line react/destructuring-assignment
          >
            {({ addChanges }) => (
              <form
                className="list-group list-group-flush"
                id="create-update-seasonal-goal"
              >
                <div className="form-group">
                  <VisibleToSponsorIndicator>
                    <RichTextInput
                      data-test="rich-text-editor"
                      id="sponsor-facing-goal-body"
                      onChange={(sponsorFacingGoalBody) =>
                        addChanges({ sponsorFacingGoalBody })
                      }
                      initialValue={goalBody}
                    />
                  </VisibleToSponsorIndicator>
                  <small
                    id="sponsor-facing-goal-body-help-block"
                    className="form-text text-muted"
                  >
                    {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                    {
                      "The campaign goal field is used for ensuring that the sponsor and TeamSnap have a common understanding of what they're trying to accomplish with their campaign. Use this space to outline their key metrics, the actions we are taking towards them, and our desired outcomes so that throughout the campaign they can view their results in relation to those goals."
                    }
                  </small>
                </div>
              </form>
            )}
          </WithSaveFooter>
        </>
      )}
    </Page>
  );
};

AddSeasonalGoal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sponsorId: PropTypes.string.isRequired,
      season: PropTypes.string.isRequired,
      year: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AddSeasonalGoal;
