import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import type { CreativeContent } from 'types/offer';
import Icon from 'components/Icon';
import GenericCreativeContentModal from 'components/GenericCreativeContentModal';
import { CREATIVE_CONTENT_FRAGMENT } from '../../fragments';

type UpdateCreativeContentModalProps = {
  creativeContent: CreativeContent;
  toggleClassName?: string;
};

const UpdateCreativeContentModal = (props: UpdateCreativeContentModalProps) => {
  const { creativeContent, toggleClassName = '' } = props;
  const [mutate] = useMutation(MUTATION);
  const update = ({ content, name }: any) =>
    mutate({
      variables: {
        input: { content, name, id: Number(creativeContent.id) },
      },
    });
  return (
    <GenericCreativeContentModal
      deadlineType="verificationDeadline"
      genericCreativeContent={creativeContent}
      onSubmit={update}
      toggleProps={{
        className: toggleClassName,
        children: <Icon value="pencil" />,
        title: 'Update',
      }}
    />
  );
};

export default UpdateCreativeContentModal;

const MUTATION = gql`
  mutation UpdateCreativeContent($input: CreativeContentInput!) {
    updateCreativeContent(input: $input) {
      creativeContent {
        ...CreativeContentFragment
      }
    }
  }
  ${CREATIVE_CONTENT_FRAGMENT}
`;
