/* eslint-disable react/no-unused-state */

import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import Icon from 'components/Icon';
import CampaignStatisticModal from './CampaignStatisticModal';

const CampaignStatisticUpdateModalUnwrapped = ({
  campaignStatistic,
  onSubmit,
}) => (
  <CampaignStatisticModal
    campaignStatistic={campaignStatistic}
    onSubmit={onSubmit}
    label="update"
    toggleProps={{
      className: 'btn btn-link text-primary',
      children: <Icon value="pencil" />,
    }}
  />
);

CampaignStatisticUpdateModalUnwrapped.propTypes = {
  campaignStatistic: LeagueSidePropTypes.campaignStatistic.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const MUTATION = gql`
  mutation UpdateCampaignStatistic($input: CampaignStatisticInput!) {
    updateCampaignStatistic(input: $input) {
      description
      iconName
      id
      name
      number
    }
  }
`;

const CampaignStatisticUpdateModal = ({ campaignStatistic }) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CampaignStatisticUpdateModalUnwrapped
        campaignStatistic={campaignStatistic}
        onSubmit={(input) =>
          mutate({
            variables: {
              input: {
                id: campaignStatistic.id,
                ...input,
              },
            },
          })
        }
      />
    )}
  </Mutation>
);

CampaignStatisticUpdateModal.propTypes = {
  campaignStatistic: LeagueSidePropTypes.campaignStatistic.isRequired,
};

export { CampaignStatisticUpdateModalUnwrapped };
export default CampaignStatisticUpdateModal;
