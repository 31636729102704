import {
  addIndex,
  flatten,
  isEmpty,
  map,
  partition,
  path,
  pipe,
  pluck,
  props,
} from 'ramda';

export const MILES_PER_DEGREE = 69;
export const METERS_PER_MILE = 1609.34;
export const US_BOUNDS = [
  [24.396308, -124.848974],
  [49.384358, -66.885444],
];

const isEven = (n) => n % 2 === 0;
const partitionIndexed = addIndex(partition);
const flattenGeoJsonCoords = pipe(
  map(path(['geoJson', 'coordinates'])),
  flatten,
  partitionIndexed((_val, idx) => isEven(idx)),
);

function getPointsBoundingRadius({ lat, lon, miles }) {
  const degrees = miles / MILES_PER_DEGREE;

  return [
    { lat: lat + degrees, lon: lon + degrees },
    { lat: lat + degrees, lon: lon - degrees },
    { lat: lat - degrees, lon: lon + degrees },
    { lat: lat - degrees, lon: lon - degrees },
  ];
}

function getPointsBoundingRadii(radii) {
  return flatten(map(getPointsBoundingRadius, radii));
}

export function getMapBounds(points, radii = [], geometries = []) {
  if (isEmpty(points) && isEmpty(geometries) && isEmpty(radii))
    return US_BOUNDS;

  const pointsLats = pluck(0, points);
  const pointsLons = pluck(1, points);

  const radiiPoints = getPointsBoundingRadii(radii);
  const radiiLats = pluck('lat', radiiPoints);
  const radiiLons = pluck('lon', radiiPoints);

  const [geometryLons, geometryLats] = flattenGeoJsonCoords(geometries);

  const lats = flatten([pointsLats, radiiLats, geometryLats]);
  const lons = flatten([pointsLons, radiiLons, geometryLons]);

  return [
    [Math.min(...lats), Math.min(...lons)],
    [Math.max(...lats), Math.max(...lons)],
  ];
}

export const getPoint = props(['lat', 'lon']);
export const getPoints = map(getPoint);
