import { complement, isEmpty } from 'ramda';
import { createReducer } from 'redux-create-reducer';

const RECEIVED = 'caughtErrors/received';

export const received = (payload) => ({ type: RECEIVED, payload });

const caughtErrors = createReducer([], {
  [RECEIVED]: (state, { payload }) => state.concat(payload),
});

export default caughtErrors;

export const hasErrors = complement(isEmpty);
