import * as React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { isEmpty, map } from 'ramda';
import gql from 'graphql-tag';
import { capitalizeEachWord } from 'utils/string';
import OrganizationPackageType, {
  PackageType,
} from 'types/organizationPackage';
import LeaguePackageCard from 'components/LeaguePackageCard';
import Icon from 'components/Icon';
import SaveButton from 'components/SaveButton';
import ErrorAlert from 'components/ErrorAlert';
import { ORGANIZATION_CAPABILITY_FRAGMENT } from 'fragments/OrganizationCapabilityFragment';
import ORGANIZATION_PACKAGE_FRAGMENT_V2 from 'fragments/OrganizationPackageFragmentV2';
import AddOrganizationPackageModal from 'components/AddOrganizationPackageModal';

const REMOVE_ORGANIZATION_PACKAGE_MUTATION = gql`
  mutation RemoveOrganizationPackage($input: RemoveOrganizationPackageInput!) {
    removeOrganizationPackage(input: $input) {
      organization {
        id
        organizationCapabilities {
          id
          organizationCapabilityPackages {
            id
          }
        }
        organizationPackages {
          id
        }
      }
    }
  }
`;

interface OrganizationPackage extends OrganizationPackageType {
  packageType: PackageType;
  packageDescription?: string;
}

interface Organization {
  id: number;
  websiteSponsorshipsUrl?: string;
  organizationPackages: OrganizationPackage[];
}

interface PackagesTabUnwrappedProps {
  organization: Organization;
  save: Function;
}

export const PackagesTabUnwrapped: React.FunctionComponent<
  PackagesTabUnwrappedProps
> = ({ organization, save }: PackagesTabUnwrappedProps) => {
  const { organizationPackages, websiteSponsorshipsUrl } = organization;

  const [sponsorshipUrl, setSponsorshipUrl] = React.useState(
    websiteSponsorshipsUrl,
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const renderOrganizationPackageCard = (
    organizationPackage: OrganizationPackage,
  ) => (
    <div key={organizationPackage.id} className="col-6">
      <LeaguePackageCard
        additionalHeaderLabel={
          <div className="mt-2">
            {`(${capitalizeEachWord(organizationPackage.packageType)})`}
          </div>
        }
        organizationPackage={organizationPackage}
        mutation={REMOVE_ORGANIZATION_PACKAGE_MUTATION}
      />
    </div>
  );

  const saveUrl = async () => {
    setError(false);
    setLoading(true);
    try {
      await save(sponsorshipUrl);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="list-group list-group-flush">
      <div className="list-group-item">
        <label className="font-weight-bold" htmlFor="websiteSponsorshipsUrl">
          Sponsorship Page URL
        </label>
        {error && <ErrorAlert />}

        <div className="input-group mb-2">
          <input
            className="form-control"
            id="websiteSponsorshipsUrl"
            onChange={({ target: { value } }) => setSponsorshipUrl(value)}
            placeholder="www.example_sponsorship_url.com"
            value={sponsorshipUrl}
            type="text"
          />
          <div className="input-group-append">
            <a
              className="btn btn-primary"
              href={sponsorshipUrl || ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon value="export" />
            </a>
          </div>
        </div>
        <SaveButton
          large={false}
          save={saveUrl}
          saved={websiteSponsorshipsUrl === sponsorshipUrl}
          saving={loading}
        />
      </div>
      <div className="list-group-item">
        <div className="row align-items-center mb-2">
          <div className="col font-weight-bold">Organization Packages</div>
          <div className="col-auto text-right">
            <AddOrganizationPackageModal internal league={organization} />
          </div>
        </div>
        {isEmpty(organizationPackages) ? (
          <p className="text-center m-4">
            This organization has no packages added yet!
          </p>
        ) : (
          <div className="row">
            {map(renderOrganizationPackageCard, organizationPackages)}
          </div>
        )}
      </div>
    </div>
  );
};

interface PackagesTabProps {
  organizationId: number;
}

const ORGANIZATION_QUERY = gql`
  query Organization($id: Int!) {
    organization(id: $id) {
      id
      organizationCapabilities {
        ...OrganizationCapabilityFragment
      }
      sponsorableProperties {
        id
        name
      }
      organizationPackages {
        ...OrganizationPackageFragment
        packageType
      }
      websiteSponsorshipsUrl
    }
  }
  ${ORGANIZATION_CAPABILITY_FRAGMENT}
  ${ORGANIZATION_PACKAGE_FRAGMENT_V2}
`;

const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        websiteSponsorshipsUrl
      }
    }
  }
`;

const PackagesTab: React.FunctionComponent<PackagesTabProps> = ({
  organizationId,
}: PackagesTabProps) => {
  const { data, loading } = useQuery(ORGANIZATION_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id: organizationId },
  });
  const [mutate] = useMutation(UPDATE_ORGANIZATION_MUTATION);

  const organization = data && data.organization;

  return !loading && organization ? (
    <PackagesTabUnwrapped
      organization={organization}
      save={(websiteSponsorshipsUrl: string) =>
        mutate({
          variables: { input: { organizationId, websiteSponsorshipsUrl } },
        })
      }
    />
  ) : null;
};

export default PackagesTab;
