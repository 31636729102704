import React from 'react';
import { useQuery } from 'react-apollo';
import { prepend } from 'ramda';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import OrganizationAutocomplete from 'pages/organizations/OrganizationAutocomplete';

const ORGANIZATIONS_RESULTS_PER_PAGE = 10;

const GET_ORGANIZATIONS = gql`
  query Organizations($query: String!, $perPage: Int, $campaignId: Int) {
    searchedOrganizations(query: $query, perPage: $perPage) {
      id
      city
      name
      state
      websiteUrl
      offersExist(campaignId: $campaignId)
    }
  }
`;

const OrganizationSelector = ({
  organizationContent,
  campaignId,
  onSelect,
  onChange,
  toggleShowCreateOrganizationForm,
  query,
  loading,
}) => {
  const { data } = useQuery(GET_ORGANIZATIONS, {
    skip: !query,
    variables: { query, perPage: ORGANIZATIONS_RESULTS_PER_PAGE, campaignId },
  });

  const organizations = data && data.searchedOrganizations;

  const fakeOrganization = {
    id: 0,
    offers: [],
    name: `Create Organization "${query}"`,
  };
  const items = prepend(fakeOrganization, organizations || []);
  const resetSelection = () => onSelect({ organizationId: null });

  function handleChange({ target: { value } }) {
    resetSelection();
    onChange(value);
  }

  function handleSelect(value, { id }) {
    if (id === 0) {
      toggleShowCreateOrganizationForm();
    } else {
      onSelect({ organizationId: Number(id) });
      onChange(value);
    }
  }

  return (
    <OrganizationAutocomplete
      items={items}
      localQuery={query} /* eslint-disable react/jsx-no-bind */
      onChange={handleChange}
      onSelect={handleSelect} /* eslint-disable react/jsx-no-bind */
      loading={loading}
      placeholder="Search organizations by name, email, or website url"
      renderOrganizationItem={organizationContent}
    />
  );
};

OrganizationSelector.propTypes = {
  organizationContent: PropTypes.func.isRequired,
  campaignId: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  toggleShowCreateOrganizationForm: PropTypes.func.isRequired,
};

export default OrganizationSelector;
