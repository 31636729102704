import * as React from 'react';
import Icon from 'components/Icon';

const MissingCreativesIndicator: React.FunctionComponent = () => (
  <span className="text-danger" data-test="missing-creatives-indicator">
    <Icon className="mr-1" value="new" />
    Waiting for creative from sponsor
  </span>
);

export default MissingCreativesIndicator;
