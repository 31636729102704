import { Icon } from '@teamsnap/snap-ui';

export default function WizardExit({ name }: { name: string }) {
  return (
    <>
      <div className="sui-flex sui-justify-center sui-items-center sui-p-3 sui-border-b sui-border-solid sui-border-gray-80 sui-max-w-w sui-mx-auto ">
        <p
          className="mb-0 sui-text-desktop-6 sui-font-bold"
          data-testid="application-exit-header"
        >
          {name} sponsorship application
        </p>
      </div>
      <div
        className="sui-flex sui-justify-center sui-mx-auto"
        style={{ maxWidth: 800 }}
      >
        <header className="sui-mt-8">
          <div className="sui-flex sui-items-center">
            <Icon name="check_circle" size="xl" />
            <div className="sui-ml-2">
              <h1 className="sui-text-desktop-8 sui-mb-1 sui-font-bold">
                Your progress has been saved
              </h1>
              <p className="sui-text-gray-40">
                Return to complete your application at any time using the link
                in your email
              </p>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}
