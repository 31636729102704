import { useState } from 'react';
import { useMutation } from 'react-apollo';
import type { Offer } from 'types/offer';
import gql from 'graphql-tag';
import { useSubmit } from 'utils/hooks';
import { ACTIVE_OFFERS_QUERY_ARGS } from 'utils/queryArgs';
import Alert from 'components/Alert';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';

type MarkAsConfirmedModalProps = {
  id: string;
  offer: Offer;
  refetchSponsorship: () => void;
  toggleClassName: string;
};

function MarkAsConfirmedModal(props: MarkAsConfirmedModalProps) {
  const { id, offer, refetchSponsorship, toggleClassName } = props;
  const {
    sponsorableProperty: { name },
    seasonStart,
    seasonEnd,
  } = offer;
  const [mutate] = useMutation(MUTATION);
  const [changeReason, setChangeReason] = useState('');
  const { error, loading, handleSubmit } = useSubmit(() =>
    markAsConfirmed(changeReason),
  );

  const missingSeasonDates = !seasonStart || !seasonEnd;

  const markAsConfirmed = (changeReasonToSet: string) =>
    mutate({
      variables: {
        input: {
          offerId: offer.id,
          changeReason: changeReasonToSet,
        },
      },
    }).then(refetchSponsorship);

  return (
    <ModalForm
      id={id}
      error={error}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: 'Mark as Confirmed',
        disabled: !changeReason || missingSeasonDates || loading,
      }}
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon className="mr-1" value="check" />
            Mark as Confirmed
          </>
        ),
      }}
      title="Mark as Confirmed?"
    >
      {missingSeasonDates ? (
        <Alert alertStyle="danger" className="my-0">
          This offer cannot be confirmed because it is missing season start
          and/or end dates.&nbsp; Please enter these dates on the offer card and
          try again.
        </Alert>
      ) : (
        <>
          <p>
            {'Are you sure you want to mark '}
            <strong>{name}</strong>
            {' as confirmed for this campaign?'}
          </p>
          <div className="form-group my-3">
            <label htmlFor="change-reason">
              Please provide a reason for manually marking the league as
              confirmed:
            </label>
            <textarea
              className="form-control mt-1"
              id="change-reason"
              onChange={(event) => setChangeReason(event.target.value)}
              value={changeReason}
              disabled={loading}
            />
          </div>
        </>
      )}
    </ModalForm>
  );
}

const MUTATION = gql`
  mutation MarkAsConfirmed($input: MarkAsConfirmedInput!) {
    markAsConfirmed(input: $input) {
      offer {
        id
        league {
          id
          activeOffers: offers(${ACTIVE_OFFERS_QUERY_ARGS}) {
            id
            status
          }
        }
      }
    }
  }
`;

export default MarkAsConfirmedModal;
