import DELIVERABLE_ADDRESS_FRAGMENT from 'fragments/DeliverableAddressFragment';
import SPONSORSHIP_EVENT_FRAGMENT from 'fragments/SponsorshipEventFragment';
import SPONSORSHIP_INSIGHT_REPORT_FRAGMENT from 'fragments/SponsorshipInsightReportFragment';
import gql from 'graphql-tag';
import { ACTIVE_OFFERS_QUERY_ARGS } from 'utils/queryArgs';
import {
  VERIFICATION_DEADLINE_FRAGMENT,
  VERIFICATION_IMAGE_FRAGMENT,
} from './fragments';

export const CHECK_FRAGMENT = gql`
  fragment CheckFragment on Check {
    id
    actionReason
    amount
    createdAt
    createdBy {
      id
      name
    }
    memo
    number
    offer {
      id
      campaign {
        id
        name
        sponsor {
          id
          name
        }
      }
      sponsorableProperty {
        id
        organization {
          id
          mailingAddress {
            ...DeliverableAddressFragment
          }
        }
        name
      }
    }
    reviewedBy {
      id
      name
    }
    status
    voidedBy {
      id
      name
    }
  }
  ${DELIVERABLE_ADDRESS_FRAGMENT}
`;

export const SPONSORSHIP_QUERY = gql`
  query Sponsorship($id: Int!) {
    offer(id: $id) {
      id
      additionalRequirements
      anyVerificationDeadlinesToIncludeInEmails
      assets {
        id
        anyVerifiedDeadlines
        campaignCapability {
          id
          capability {
            id
            name
            capabilityVerificationDeadlines {
              id
              name
            }
          }
          defaultVerificationDeadlines {
            id
            deadline
          }
          executionExpectations
        }
        quantity
        reach
      }
      campaign {
        id
        campaignCapabilities {
          id
          capability {
            id
            name
            createDeadlineBasedOnQuantity
            }
            defaultVerificationDeadlines {
            id
          }
          minimumQuantity
        }
        name
        offers {
          id
          sponsorableProperty {
            id
            name
            playLocation { id lat lon }
          }
          status
        }
        radii {
          label
          lat
          lon
          miles
        }
        season
        sponsor {
          id
          name
          campaigns(statuses: [active proposed]) {
            id
            name
            year
            season
            sponsor {
              id
              name
            }
          }
        }
        year
      }
      checks {
        ...CheckFragment
      }
      communicatedToLeague
      customField
      sponsorableProperty { 
        id
        name
        organization {
          id
          contactEmail
          contactPhone
          canReceiveEmails
          confirmedOffers: offers(statuses: [confirmed]) {
            id
            campaign {
              id
              name
              sponsor {
                id
                name
              }
            }
            updatedAt
            value
          }
          emailListSize
          socialMediaFollowingSize
          name
          mailingAddress {
            ...DeliverableAddressFragment
          }
          websiteUrl
          hubspotCompanyId
          sponsorshipInsightReport {
          ...SponsorshipInsightReportFragment
          }
        }
        competitiveness { id name }
        sponsorablePropertyCapabilities {
          id 
          name
        }

        name
        numberOfPlayers
        confirmedOffers: offers(statuses: [confirmed]) {
          id
          campaign {
            id
            name
            sponsor {
              id
              name
            }
          }
          updatedAt
          value
        }
        activeOffers: offers(${ACTIVE_OFFERS_QUERY_ARGS}) {
          id
          status
        }
        playLocation {
          id
          lat
          lon
          address
        }
        sport { id name }
        season
        female
        male
        maxAge
        minAge
        startDate
        endDate
        numberOfPlayers
      }
      leagueRejectionReason
      magicToken
      offerSentAt
      pendingImages: verificationImages(status: pending) {
        ...VerificationImageFragment
      }
      removalReason
      seasonStart
      seasonEnd
      sponsorshipEvents {
        ...SponsorshipEventFragment
      }
      status
      sponsorshipApplicationSentAt
      sponsorshipApplicationMagicToken
      value
      verificationDeadlines {
        ...VerificationDeadlineFragment
        anyPendingVerificationImages
      }
      offerContracts {
        contractUrl
        createdAt
        versionNumber
      }
    }
  }
  ${CHECK_FRAGMENT}
  ${SPONSORSHIP_INSIGHT_REPORT_FRAGMENT}
  ${VERIFICATION_IMAGE_FRAGMENT}
  ${SPONSORSHIP_EVENT_FRAGMENT}
  ${VERIFICATION_DEADLINE_FRAGMENT}
`;

export const CREATE_CHECK_MUTATION = gql`
  mutation CreateCheck($input: CreateCheckInput!) {
    createCheck(input: $input) {
      id
      checks {
        ...CheckFragment
      }
    }
  }
  ${CHECK_FRAGMENT}
`;
