import * as React from 'react';
import { DataConfidence } from 'types/sponsorableProperty';
import { OfferStatus } from 'types/offer';
import RecentCommentRow from './RecentCommentRow';
import SponsorablePropertyCol from './SponsorablePropertyCol';

interface Organization {
  id: number;
  dataConfidence: DataConfidence;
  websiteUrl?: string;
}

interface SponsorableProperty {
  id: number;
  organization: Organization;
  name: string;
  dataConfidence: DataConfidence;
}
interface RecentComment {
  createdAt: Date;
  description: string;
}
interface Offer {
  id: number;
  sponsorableProperty: SponsorableProperty;
  recentComment: RecentComment;
  removalReason: string;
  status: OfferStatus;
}

interface Props {
  colLength: number;
  offer: Offer;
}

const RemovedTableRow: React.FunctionComponent<Props> = ({
  colLength,
  offer,
}: Props) => {
  const {
    id: offerId,
    sponsorableProperty,
    recentComment,
    removalReason,
    status,
  } = offer;

  return (
    <>
      <tr data-test={`offer-${offerId}-row`} className="p-2">
        <td aria-label="Empty cell for formatting" />
        <SponsorablePropertyCol
          sponsorableProperty={sponsorableProperty}
          offerId={offerId}
        />
        <td className="align-middle">
          <small>{removalReason}</small>
        </td>
        <td className="align-middle text-capitalize" data-test="status">
          {status}
        </td>
      </tr>
      {!!recentComment && (
        <RecentCommentRow colLength={colLength} offer={offer} />
      )}
    </>
  );
};

export default RemovedTableRow;
