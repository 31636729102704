import * as React from 'react';
import { rejectById } from 'utils/misc';
import { partitionOffersByStatus } from 'utils/offer';
import { ORGANIZATIONS_PATH } from 'utils/paths';
import Badge from 'components/Badge';
import NewWindowLink from 'components/NewWindowLink';
import RestrictTo from 'components/RestrictTo';
import type { SponsorableProperty } from 'types/offer';

const title = (status: string) => `Number of Other Active ${status} Offers`;

interface Props {
  sponsorableProperty: SponsorableProperty;
  offerIdToExclude?: number;
}

const ActiveOffersBadges: React.FunctionComponent<Props> = ({
  sponsorableProperty,
  offerIdToExclude,
}: Props) => {
  const { activeOffers, organization } = sponsorableProperty;
  const { id: organizationId } = organization;
  const {
    qualifiedOffers,
    proposedOrRejectedByLeagueOffers,
    confirmedOffers,
    prospectingOffers,
  } = partitionOffersByStatus(rejectById(offerIdToExclude)(activeOffers));

  const prospectingLength = prospectingOffers.length;
  const qualifiedLength = qualifiedOffers.length;
  const proposedOrRejectedByLeagueLength =
    proposedOrRejectedByLeagueOffers.length;
  const confirmedLength = confirmedOffers.length;

  return (
    <RestrictTo roles={['admin']}>
      <NewWindowLink
        hideIcon
        to={`${ORGANIZATIONS_PATH}/${organizationId}/campaigns`}
      >
        <span className="text-nowrap sui-flex" style={{ gap: '0.5rem' }}>
          <Badge
            badgeType={prospectingLength ? 'prospecting' : 'zero'}
            title={title('Prospecting')}
          >
            {prospectingLength}
          </Badge>
          <Badge
            badgeType={qualifiedLength ? 'qualified' : 'zero'}
            title={title('Qualified')}
          >
            {qualifiedLength}
          </Badge>
          <Badge
            badgeType={proposedOrRejectedByLeagueLength ? 'proposed' : 'zero'}
            title={title('Proposed')}
          >
            {proposedOrRejectedByLeagueLength}
          </Badge>
          <Badge
            badgeType={confirmedLength ? 'confirmed' : 'zero'}
            title={title('Confirmed')}
          >
            {confirmedLength}
          </Badge>
        </span>
      </NewWindowLink>
    </RestrictTo>
  );
};

ActiveOffersBadges.defaultProps = {
  offerIdToExclude: undefined,
};

export default ActiveOffersBadges;
