import {
  __,
  any,
  compose,
  concat,
  converge,
  defaultTo,
  dropLast,
  endsWith,
  equals,
  head,
  join,
  last,
  map,
  pipe,
  replace,
  split,
  tail,
  toUpper,
} from 'ramda';
import qs from 'qs';
import nlp from 'compromise';
import { rejectFalsy } from './array';

export const joinWithComma = join(', ');
export const joinWithSpace = join(' ');
export const joinTruthyWithSpace = (values) =>
  joinWithSpace(rejectFalsy(values));

export const replaceUnderscoresWithSpaces = replace(/_/g, ' ');
export const replaceDashesWithSpaces = replace(/-/g, ' ');
export const replaceSpacesWithDashes = replace(/ /g, '-');

const wordParts = [compose(toUpper, head), tail];
export const capitalize = converge(concat(), wordParts);
export const capitalizeEachWord = pipe(
  split('_'),
  map(capitalize),
  joinWithSpace,
);

export const snakeToCamel = (string) =>
  string.replace(/(_\w)/g, (m) => m[1].toUpperCase());
export const camelToKebab = (string) =>
  replace(/([a-z])([A-Z])/g, '$1-$2', string).toLowerCase();
export const camelize = (string) =>
  string
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase(),
    )
    .replace(/\s+/g, '');

const dropLastOne = dropLast(1);

export const pluralizedTense = (quantity, word) => {
  if (quantity === 1) {
    return word;
  }
  if (endsWith('y', word)) {
    return concat(dropLastOne(word), 'ies');
  }

  return `${word}s`;
};

// Start to use gerundWithEllipsis method (below) instead!
export const loadingTense = (loading, submitString) => {
  if (!loading) return submitString;

  const wordList1 = ['Cancel', 'Submit'];
  const wordList2 = [
    'Accept',
    'Add',
    'Download',
    'Reject',
    'Send',
    'Upload',
    'Void',
  ];

  const firstWord = pipe(split(' '), head);
  const word = firstWord(submitString);
  const isWord = equals(word);
  const addEnding = concat(__, 'ing...');

  if (endsWith('e', word)) {
    return addEnding(dropLastOne(word));
  }
  if (any(isWord, wordList1)) {
    return addEnding(concat(word, last(word)));
  }
  if (any(isWord, wordList2)) {
    return addEnding(word);
  }

  return 'Loading...';
};

export const parseQueryString = pipe(tail, qs.parse);

export const defaultToEmptyString = defaultTo('');

export const getExtension = pipe(split('.'), last);

export const pastTense = (string) => {
  const wordList = ['Submit'];

  const firstWord = pipe(split(' '), head);
  const word = firstWord(string);
  const isWord = equals(word);
  const addEnding = concat(__, 'ed');

  if (any(isWord, wordList)) {
    return addEnding(concat(word, last(word)));
  }

  const doc = nlp(string);
  doc.verbs().toPastTense();
  const verb = doc.text();
  return verb;
};

export const gerund = (string) => {
  const doc = nlp(string);
  doc.verbs().toGerund();

  const verb = doc.text();
  return verb;
};

export const gerundWithEllipsis = (string) => `${gerund(string)}...`;

export const conjugateString = (string, loading, past = false) => {
  if (loading) return gerundWithEllipsis(string);

  if (past) return pastTense(string);

  return string;
};

export const sanitizeName = (name) => {
  if (!name) return '';
  return name
    .replace(/[?&#\\%<>+]/g, '_')
    .replace(/^[\\/\s]+|[\\/\s]+$/g, '')
    .replace(/\s+/g, '_');
};
