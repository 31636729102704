import * as React from 'react';
import { map } from 'ramda';
import PropTypes from 'prop-types';
import HoverTable from 'components/HoverTable';
import Card from 'components/Card';
import RemoveOrganizationCapabilityModal from 'components/RemoveOrganizationCapability';

const SocialMediaTable = ({ socialMediaCapabilities }) => {
  const row = (socialMediaCapability) => {
    const { capabilityDetail } = socialMediaCapability;

    return (
      <>
        {capabilityDetail ? (
          <tr
            key={capabilityDetail.id}
            data-test={`socialMediaPostsCapability-${capabilityDetail.id}`}
          >
            {capabilityDetail.capabilityDetailable ? (
              <>
                <td aria-label="Social Media Channel Name">{`${capabilityDetail.capabilityDetailable.socialMediaChannel.name}`}</td>
                <td aria-label="Social Media Following Size">{`${capabilityDetail.capabilityDetailable.socialMediaFollowingSize}`}</td>
              </>
            ) : (
              <>
                <td aria-label="Social Media Channel Name" />
                <td aria-label="Social Media Following Size" />
              </>
            )}
            <td>{`${capabilityDetail.price}`}</td>
            <td>{capabilityDetail.maxForSale}</td>
            <td className="d-flex justify-content-end">
              <RemoveOrganizationCapabilityModal
                organizationCapability={socialMediaCapability}
              />
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  return (
    <>
      <Card>
        <h3 data-test="social-media-posts-table-header">Social Media Posts</h3>
        <HoverTable>
          <thead>
            <tr className="th-font-family-11">
              <th scope="col">Channel</th>
              <th scope="col">Social Media Following Size</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col" className="d-flex justify-content-end">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>{map(row, socialMediaCapabilities)}</tbody>
        </HoverTable>
      </Card>
    </>
  );
};

SocialMediaTable.propTypes = {
  socialMediaCapabilities: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default SocialMediaTable;
