import * as React from 'react';
import { contains } from 'ramda';
import SponsorshipInsightReport from 'components/SponsorshipInsightReport';
import { DataConfidence } from 'types/sponsorableProperty';
import OfferCountsReport from 'types/offerCountsReport';
import SponsorshipInsightReportType from 'types/sponsorshipInsightReport';
import ActiveOffersBadges from 'components/ActiveOffersBadges';
import { sponsorablePropertyLocation } from 'utils/sponsorableProperty';
import AssetConflict from 'types/assetConflict';
import RecentCommentRow from './RecentCommentRow';
import SurveyCol from './SurveyCol';
import HubspotLink from './HubspotLink';
import SponsorablePropertyCol from './SponsorablePropertyCol';
import PackagesIndicator from './PackagesIndicator';
import OrganizationCol from './OrganizationCol';

interface Organization {
  id: number;
  sponsorshipInsightReport: SponsorshipInsightReportType;
  dataConfidence: DataConfidence;
  hubspotCompanyId: string;
  city: string;
  state: string;
  name: string;
}
interface SponsorableProperty {
  id: number;
  organization: Organization;
  name: string;
  sponsorablePropertyPackagesCount: number;
  sponsorshipInsightReport: SponsorshipInsightReportType;
  dataConfidence: DataConfidence;
}

interface RecentComment {
  createdAt: Date;
  description: string;
}

interface Campaign {
  id: number;
  season: string;
  year: number;
}
interface Offer {
  id: number;
  sponsorableProperty: SponsorableProperty;
  offerCountsReport: OfferCountsReport;
  recentComment: RecentComment;
  surveyOpenedAt?: Date;
  sponsorshipApplicationSentAt?: Date;
  fundraiserConflict: AssetConflict;
  surveyConflict: AssetConflict;
  campaign: Campaign;
}

interface Props {
  colLength: number;
  handleToggle: () => {};
  offer: Offer;
  selectedOfferIds: number[];
}

const ProspectsTableRow: React.FunctionComponent<Props> = ({
  handleToggle,
  colLength,
  offer,
  selectedOfferIds,
}: Props) => {
  const {
    id: offerId,
    offerCountsReport,
    sponsorableProperty,
    recentComment,
  } = offer;
  const { organization } = sponsorableProperty;
  const { hubspotCompanyId, sponsorshipInsightReport } = organization;

  return (
    <>
      <tr data-test={`offer-${offerId}-row`} className="p-2">
        <td className="align-middle">
          <input
            checked={contains(offerId, selectedOfferIds)}
            data-test={`prospecting-toggle-${offerId}`}
            onChange={() => handleToggle()}
            type="checkbox"
          />
        </td>
        <OrganizationCol organization={organization} />
        <SponsorablePropertyCol
          sponsorableProperty={sponsorableProperty}
          offerId={offerId}
        />
        <td className="align-middle pl-1">
          <SponsorshipInsightReport
            sponsorshipInsightReport={sponsorshipInsightReport}
          />
          <div className="sui-mt-1">
            <ActiveOffersBadges
              offerCountsReport={offerCountsReport}
              sponsorablePropertyId={organization.id}
              fundraiserConflict={offer.fundraiserConflict}
              surveyConflict={offer.surveyConflict}
            />
            <PackagesIndicator sponsorableProperty={sponsorableProperty} />
          </div>
        </td>
        <td
          className="align-middle d-none d-lg-table-cell"
          data-test="location"
        >
          <small>{sponsorablePropertyLocation(organization)}</small>
        </td>
        <SurveyCol offer={offer} />
        <td className="align-middle text-center" data-test="hubspot">
          {!!hubspotCompanyId && (
            <HubspotLink hubspotCompanyId={+hubspotCompanyId} />
          )}
        </td>
      </tr>
      {!!recentComment && (
        <RecentCommentRow colLength={colLength} offer={offer} />
      )}
    </>
  );
};

export default ProspectsTableRow;
