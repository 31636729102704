import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import NumberInput from 'components/formInputs/NumberInput';
import SelectInput from 'components/formInputs/SelectInput';
import TextAreaInput from 'components/formInputs/TextAreaInput';

export const AddOrganizationCapabilityToPackageModalUnwrapped = ({
  add,
  added,
  organizationCapability,
}) => {
  const {
    id: organizationCapabilityId,
    description,
    name,
  } = organizationCapability;

  const [quantity, setQuantity] = useState('');

  async function asyncSubmit() {
    await add({ quantity: Number(quantity) });

    setQuantity('');
  }

  const { error, loading, handleSubmit } = useSubmit(asyncSubmit);

  const prefix = `add-organization-capability-${organizationCapabilityId}-to-package`;

  return (
    <ModalForm
      id={`${prefix}-modal`}
      error={error}
      hasRequiredFields
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Add'),
        disabled: added || !quantity || loading,
      }}
      title="Add Opportunity To Package"
      toggleProps={{
        className: 'btn btn-primary btn-sm mt-2',
        children: added ? 'Already Added' : 'Add',
        disabled: added,
      }}
    >
      <SelectInput
        disabled
        label="Opportunity Type"
        noDefault
        prefix={prefix}
        value={name}
      >
        <option value={name}>{name}</option>
      </SelectInput>
      {!!description && (
        <TextAreaInput
          disabled
          label="Description"
          prefix={prefix}
          value={description}
        />
      )}
      <NumberInput
        disabled={loading}
        label="Quantity"
        onChange={({ target: { value } }) => setQuantity(value)}
        min={1}
        prefix={prefix}
        required
        value={quantity}
      />
    </ModalForm>
  );
};

AddOrganizationCapabilityToPackageModalUnwrapped.propTypes = {
  add: PropTypes.func.isRequired,
  added: PropTypes.bool.isRequired,
  organizationCapability: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

const MUTATION = gql`
  mutation CreateOrganizationCapabilityPackage(
    $input: CreateOrganizationCapabilityPackageInput!
  ) {
    createOrganizationCapabilityPackage(input: $input) {
      organizationPackage {
        id
        organization {
          id
          organizationCapabilities {
            id
            organizationCapabilityPackages {
              id
            }
          }
        }
        organizationCapabilityPackages {
          id
          quantity
          organizationCapability {
            id
            description
            name
          }
        }
      }
    }
  }
`;

const AddOrganizationCapabilityToPackageModal = ({
  added,
  organizationCapability,
  organizationPackageId,
}) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <AddOrganizationCapabilityToPackageModalUnwrapped
        add={({ quantity }) =>
          mutate({
            variables: {
              input: {
                organizationCapabilityId: organizationCapability.id,
                organizationPackageId,
                quantity,
              },
            },
          })
        }
        added={added}
        organizationCapability={organizationCapability}
      />
    )}
  </Mutation>
);

AddOrganizationCapabilityToPackageModal.propTypes = {
  added: PropTypes.bool.isRequired,
  organizationCapability: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  organizationPackageId: PropTypes.number.isRequired,
};

export default AddOrganizationCapabilityToPackageModal;
