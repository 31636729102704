import gql from 'graphql-tag';

const TESTIMONIAL_FRAGMENT = gql`
  fragment TestimonialFragment on Testimonial {
    id
    attribution
    quote
  }
`;

export default TESTIMONIAL_FRAGMENT;
