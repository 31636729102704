import React from 'react';
import PropTypes from 'prop-types';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import styled from 'styled-components';
import CheckboxButton from 'components/CheckboxButton';
import Paragraph from './Paragraph';

const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
`;

const PlayersGenders = ({ addChanges, league }) => (
  <div className="container">
    <Paragraph bold>Which genders play in your league?</Paragraph>
    <div className="mt-3">
      <Paragraph className="mb-4">Select all that apply.</Paragraph>
      <Inputs>
        <CheckboxButton
          checked={league.male}
          id="male"
          onChange={(event) => addChanges({ male: event.target.checked })}
        >
          <h3 className="m-0">Boys</h3>
        </CheckboxButton>
        <CheckboxButton
          checked={league.female}
          id="female"
          onChange={(event) => addChanges({ female: event.target.checked })}
        >
          <h3 className="m-0">Girls</h3>
        </CheckboxButton>
      </Inputs>
    </div>
  </div>
);

PlayersGenders.propTypes = {
  addChanges: PropTypes.func.isRequired,
  league: LeagueSidePropTypes.league.isRequired,
};

export default PlayersGenders;
