import { dropWhile, join, startsWith } from 'ramda';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const { REACT_APP_HUBSPOT_PORTAL_ID } = runtimeEnv();

export const surveyGizmoUrl = (surveyGizmoResponseId) =>
  `https://data.surveygizmo.com/sharedexplore/response/id/5297439/vid/14668/cid/392193/rid/${surveyGizmoResponseId}?key=10763bad7a2d135d147bc960554410fd&shared=true`;
export const hubspotUrl = (hubspotCompanyId) =>
  `https://app.hubspot.com/contacts/${REACT_APP_HUBSPOT_PORTAL_ID}/company/${hubspotCompanyId}/`;
export const salesforceUrl = (salesforceId) =>
  `https://teamsnap.lightning.force.com/lightning/r/Account/${salesforceId}/view`;
const startsWithHttp = startsWith('http');
const dropHttp = (href) =>
  join(
    '',
    dropWhile((char) => char !== '/', href),
  );
export const getHref = (to) => (startsWithHttp(to) ? dropHttp(to) : to);
