import React, { useContext, useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import 'react-virtualized/styles.css';
import Table, { Column } from 'react-virtualized/dist/commonjs/Table';
import { isEmpty, merge } from 'ramda';
import gql from 'graphql-tag';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { pluralizedTense, replaceUnderscoresWithSpaces } from 'utils/string';
import { getLocationsWithPoints } from 'utils/location';
import Card from 'components/Card';
import Icon from 'components/Icon';
import RefetchDataContext from './RefetchDataContext';
import CreateLocationsByCsvModal from './CreateLocationsByCsvModal';
import CreateLocationByAddressModal from './CreateLocationByAddressModal';
import CreateLocationsByZipsModal from './CreateLocationsByZipsModal';
import UpdateLocationModal from './UpdateLocationModal';
import DeleteLocationModal from './DeleteLocationModal';
import CreateLocationsByMarketsModal from './CreateLocationsByMarketsModal';
import DeleteSearchFilterModal from './DeleteSearchFilterModal';

const BUTTON_COLUMN_WIDTH = 23;
const UPDATE_MUTATION = gql`
  mutation UpdateLocationFilter($input: UpdateLocationFilterInput!) {
    updateLocationFilter(input: $input) {
      locationFilter {
        id
        enabled
        name
        radius
        strategy
        strategyCount
      }
    }
  }
`;

function LocationFilterCard({ locationFilter, markets }) {
  const { locations, name: filterName } = locationFilter;
  const hasNoPoints = isEmpty(getLocationsWithPoints(locations));
  const locationCount = locations.length;
  const [formState, setFormState] = useState(null);
  const setFormField = (fieldName) => (event) =>
    setFormState(merge(formState, { [fieldName]: event.target.value }));
  const stopEditing = () => setFormState(null);
  const editing = !!formState;
  const refetchData = useContext(RefetchDataContext);

  function edit() {
    setFormState({
      name: filterName,
      radius: String(locationFilter.radius),
      strategy: locationFilter.strategy,
      strategyCount: String(locationFilter.strategyCount),
    });
  }

  return (
    <ApolloConsumer>
      {(client) => {
        async function update(input) {
          await client.mutate({
            mutation: UPDATE_MUTATION,
            variables: {
              input: {
                id: locationFilter.id,
                ...input,
              },
            },
          });

          return refetchData();
        }

        function handleEnabledChange(event) {
          const enabled = event.target.checked;

          update({ enabled });
        }

        function renderEditView() {
          const { name, radius, strategy, strategyCount } = formState;

          async function handleFormSubmit(event) {
            event.preventDefault();

            await update({
              name,
              radius: Number(radius),
              strategy,
              strategyCount: Number(strategyCount),
            });

            stopEditing();
          }

          return (
            <>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    className="form-control mb-2"
                    name="name"
                    onChange={setFormField('name')}
                    required
                    type="text"
                    value={name}
                  />
                </div>
                {!hasNoPoints && (
                  <div className="form-group">
                    <label htmlFor="radius">
                      Radius around zips and addresses
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        max="100"
                        min="1"
                        name="radius"
                        onChange={setFormField('radius')}
                        required
                        type="number"
                        value={radius}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">miles</span>
                      </div>
                    </div>
                    <input
                      className="form-control"
                      max="100"
                      min="1"
                      onChange={setFormField('radius')}
                      type="range"
                      value={radius}
                    />
                  </div>
                )}
                <div className="form-group">
                  <label htmlFor="strategy-count">
                    How many sponsorable properties should we sponsor in these
                    locations?
                  </label>
                  <div className="input-group">
                    <input
                      className="form-control col-2"
                      min="0"
                      name="strategy-count"
                      onChange={setFormField('strategyCount')}
                      type="number"
                      value={strategyCount}
                    />
                    <select
                      className="custom-select"
                      name="strategy"
                      onChange={setFormField('strategy')}
                      value={strategy}
                    >
                      <option value="per_location">
                        {`sponsorable properties per location (${
                          Number(strategyCount) * locationCount
                        } total across ${locationCount} ${pluralizedTense(
                          locationCount,
                          'location',
                        )})`}
                      </option>
                      <option value="total">
                        {`best sponsorable properties available within ${locationCount} ${pluralizedTense(
                          locationCount,
                          'location',
                        )}`}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    className="btn btn-link"
                    data-test="cancel"
                    onClick={stopEditing}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary"
                    data-test="save"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </form>
              <hr />
              <strong>
                {`${locationCount} ${pluralizedTense(
                  locationCount,
                  'Location',
                )}`}
                <div className="dropdown d-inline-block">
                  <button
                    className="btn btn-link dropdown-toggle"
                    data-test="add-locations"
                    type="button"
                    data-toggle="dropdown"
                  >
                    Add
                  </button>
                  <div className="dropdown-menu">
                    <CreateLocationByAddressModal
                      locationFilterId={locationFilter.id}
                    />
                    <CreateLocationsByCsvModal
                      locationFilterId={locationFilter.id}
                    />
                    <CreateLocationsByMarketsModal
                      locationFilterId={locationFilter.id}
                      markets={markets}
                    />
                    <CreateLocationsByZipsModal
                      locationFilterId={locationFilter.id}
                    />
                  </div>
                </div>
              </strong>
              <div style={{ height: '200px' }}>
                <AutoSizer>
                  {({ width }) => (
                    <Table
                      height={200}
                      rowCount={locationCount}
                      rowHeight={30}
                      rowGetter={({ index }) => ({
                        location: locations[index],
                        index,
                      })}
                      width={width}
                    >
                      <Column
                        className="location-name"
                        dataKey="name"
                        label="Name"
                        cellRenderer={({ rowData: { location } }) =>
                          location.name
                        }
                        width={width - 2 * BUTTON_COLUMN_WIDTH}
                      />
                      <Column
                        data-test="location-edit-modal-link"
                        dataKey="id"
                        label="Edit"
                        cellRenderer={({ rowData: { location } }) => (
                          <UpdateLocationModal
                            location={location}
                            key={location.id}
                          />
                        )}
                        width={BUTTON_COLUMN_WIDTH}
                      />
                      <Column
                        dataKey="id"
                        label="Delete"
                        cellRenderer={({ rowData: { location } }) => (
                          <DeleteLocationModal
                            filterName={filterName}
                            location={location}
                            key={location.id}
                          />
                        )}
                        width={BUTTON_COLUMN_WIDTH}
                      />
                    </Table>
                  )}
                </AutoSizer>
              </div>
            </>
          );
        }

        function renderSummary() {
          return (
            <>
              <div className="float-right">
                <button
                  className="btn btn-link"
                  data-test="edit"
                  onClick={edit}
                  type="button"
                >
                  Edit
                </button>
                <DeleteSearchFilterModal
                  locationFilter={locationFilter}
                  refetchData={refetchData}
                />
              </div>
              {`Targeting ${
                locationFilter.strategyCount
              } sponsorable ${pluralizedTense(
                locationFilter.strategyCount,
                'property',
              )} `}
              {`${replaceUnderscoresWithSpaces(
                locationFilter.strategy,
              )} across `}
              {`${locationCount} ${pluralizedTense(locationCount, 'location')}`}
            </>
          );
        }

        return (
          <Card
            data-test={`location-filter-${locationFilter.id}`}
            verticalMargins={3}
          >
            <strong data-test="name" className="d-block">
              <input
                checked={locationFilter.enabled}
                className="float-right"
                data-test="enabled-input"
                onChange={handleEnabledChange}
                type="checkbox"
              />
              <Icon value="hair-cross" className="text-muted mr-2" />
              {filterName}
            </strong>
            {editing ? renderEditView() : renderSummary()}
          </Card>
        );
      }}
    </ApolloConsumer>
  );
}

LocationFilterCard.propTypes = {
  locationFilter: LeagueSidePropTypes.locationFilter.isRequired,
  markets: LeagueSidePropTypes.geometries.isRequired,
};

export default LocationFilterCard;
