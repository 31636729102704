import React from 'react';
import { prop } from 'ramda';
import classNames from 'classnames';
import LeagueSidePropTypes from 'utils/LeagueSidePropTypes';
import { formatNumber } from 'utils/formatNumber';
import { capitalize } from 'utils/string';
import Card from 'components/Card';
import ProgressBar from 'components/ProgressBar';
import VerificationsReportLink from 'components/VerificationsReportLink';

function VerificationsCard({ campaign }) {
  const { physicalVerificationProgress, digitalVerificationProgress } =
    campaign;

  const renderVerificationProgress = (verificationType) => {
    const verificationProgress = prop(
      `${verificationType}VerificationProgress`,
      campaign,
    );

    const { overdue, total, verified } = verificationProgress;
    return (
      <>
        <strong>
          {`${formatNumber(verified)} of ${formatNumber(total)} ${capitalize(
            verificationType,
          )} Deadlines Verified`}
        </strong>
        <ProgressBar>
          <ProgressBar
            data-test={`verified-${verificationType}-deadlines-progress-bar`}
            maximum={total}
            value={verified}
          />
          <ProgressBar
            color="warning"
            data-test={`overdue-${verificationType}-deadlines-progress-bar`}
            maximum={total}
            value={overdue}
          />
        </ProgressBar>
      </>
    );
  };

  return (
    <Card id="verifications-card">
      <h3 className="card-title">
        <VerificationsReportLink params={{ campaign_id: campaign.id }}>
          Verifications
        </VerificationsReportLink>
      </h3>
      {physicalVerificationProgress.total ||
      digitalVerificationProgress.total ? (
        <>
          {!physicalVerificationProgress.total || (
            <div>{renderVerificationProgress('physical')}</div>
          )}
          {!digitalVerificationProgress.total || (
            <div
              className={classNames({
                'mt-4': physicalVerificationProgress.total,
              })}
            >
              {renderVerificationProgress('digital')}
            </div>
          )}
        </>
      ) : (
        'No verification deadlines exist for this campaign'
      )}
    </Card>
  );
}

VerificationsCard.propTypes = {
  campaign: LeagueSidePropTypes.campaign.isRequired,
};

export default VerificationsCard;
