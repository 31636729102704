import React, { useCallback, useState } from 'react';
import { contains, cond, always, T } from 'ramda';
import PropTypes from 'prop-types';
import hideModal from 'utils/hideModal';
import { loadingTense } from 'utils/string';
import ContactUsLink from 'components/ContactUsLink';
import ErrorAlert from 'components/ErrorAlert';
import Icon from 'components/Icon';
import ModalForm from 'components/ModalForm';

const errorMessageCond = cond([
  [
    contains('Invalid user role/status'),
    always(
      'That user could not be sent an invite. If you believe this is an error',
    ),
  ],
  [
    contains('User already added'),
    always(
      'That email is already associated with a user for your organization. If you believe this is an error',
    ),
  ],
  [
    T,
    always(
      'We had trouble inviting the user. Please check your internet connection and try again. If the problem persists',
    ),
  ],
]);

const AddExternalUserModal = ({ addUser, children, internal }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const modalId = 'add-external-user-modal';
  const title = internal ? 'Add A User' : 'Invite User';

  async function confirmAddUser(event) {
    event.preventDefault();
    setError(null);
    const timeoutID = setTimeout(() => setLoading(true), 200);
    try {
      await addUser(email);
      clearTimeout(timeoutID);
      hideModal(`#${modalId}`);
      setEmail('');
    } catch (e) {
      clearTimeout(timeoutID);
      setError(e.message);
    }
    setLoading(false);
  }

  const errorMessage = !!error && (
    <ErrorAlert data-test="add-user-error-message" className="mt-3 mb-0">
      {`Oops! ${errorMessageCond(error)}, please `}
      {internal ? 'contact us' : <ContactUsLink />}.
    </ErrorAlert>
  );

  return (
    <ModalForm
      id={modalId}
      error={error}
      errorMessage={errorMessage}
      loading={loading}
      handleSubmit={useCallback(confirmAddUser)}
      submitProps={{
        children: loadingTense(loading, 'Add User'),
        disabled: loading || !email.length,
      }}
      title={title}
      toggleProps={{
        className: `btn btn-${internal ? 'secondary' : 'primary'}`,
        children: (
          <>
            <Icon className="mr-2" value={internal ? 'plus' : 'add-user'} />
            {title}
          </>
        ),
      }}
    >
      {children}
      <label htmlFor="email-input">Enter An Email Address</label>
      <input
        className="form-control"
        data-test="add-user-email"
        id="email-input"
        onChange={({ target: { value } }) => setEmail(value)}
        required
        type="email"
        value={email}
        disabled={loading}
      />
    </ModalForm>
  );
};

AddExternalUserModal.propTypes = {
  addUser: PropTypes.func.isRequired,
  children: PropTypes.node,
  internal: PropTypes.bool,
};

AddExternalUserModal.defaultProps = {
  children: null,
  internal: false,
};

export default AddExternalUserModal;
