import React from 'react';
import PropTypes from 'prop-types';
import { formatPercentageValue } from 'utils/formatNumber';
import { anyPass, keys, head, pipe, startsWith } from 'ramda';
import FilterCard from './FilterCard';
import EthnicityFilterForm from './EthnicityFilterForm';

const firstKey = pipe(keys, head);
const startsWithAOrI = anyPass([startsWith('a'), startsWith('i')]);
const description = (ethnicity, ratio) => (
  <>
    With a{startsWithAOrI(ethnicity) && 'n'}{' '}
    <strong className="text-capitalize">{ethnicity} </strong>
    population of at least <strong>{formatPercentageValue(ratio)}%</strong>
  </>
);

const EthnicityFilterCard = ({ filterId, parameters }) => {
  const ethnicity = firstKey(parameters) || 'asian';
  const ratio = parameters[ethnicity] || '0.5';

  return (
    <FilterCard
      filterId={filterId}
      title="Target Ethnicity"
      description={description(ethnicity, ratio)}
      iconValue="pie-chart"
      formRenderer={({ save, cancel }) => (
        <EthnicityFilterForm
          ethnicity={ethnicity}
          onCancel={cancel}
          onSave={save}
          ratio={ratio}
        />
      )}
    />
  );
};

EthnicityFilterCard.propTypes = {
  filterId: PropTypes.number.isRequired,
  parameters: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default EthnicityFilterCard;
