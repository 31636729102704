import * as React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

const EngagementStatusProps = {
  active: {
    className: 'text-primary',
    title:
      'Active: A user from this organization has either logged in, filled out prequal, or accepted an offer in the last 6 month.',
  },
  inactive: {
    className: 'text-muted',
    title:
      'Inactive: The organization has not engaged with the platform in 6+ months.',
  },
};

const EngagementStatusIcon = ({ organization }) => {
  const { engagementStatus } = organization;
  const { className, title } = EngagementStatusProps[engagementStatus];

  return (
    <Icon
      className={`${className} mr-1`}
      data-test={engagementStatus}
      title={title}
      value="controller-record"
    />
  );
};

EngagementStatusIcon.propTypes = {
  organization: PropTypes.shape({
    engagementStatus: PropTypes.string,
  }).isRequired,
};

export default EngagementStatusIcon;
