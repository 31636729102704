import ContactUsLink from 'components/ContactUsLink';
import React from 'react';
import Paragraph from './Paragraph';

const Welcome = () => (
  <div className="container" id="welcome">
    <Paragraph bold>Welcome to TeamSnap Sponsorships!</Paragraph>
    <Paragraph>
      {
        'Our goal is to help your league sell more sponsorships. Please fill out this short questionnaire about your organization with as much detail as possible. Get started by clicking the '
      }
      <strong>Next</strong>
      {' button below.'}
    </Paragraph>
    <p>
      {"If at any point you need help, please don't hesitate to "}
      <ContactUsLink />!
    </p>
  </div>
);

export default Welcome;
