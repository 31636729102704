import { FormEvent, useRef, useState } from 'react';
import type { Offer } from 'types/offer';
import LeaguesideApi from 'utils/leagueside-api';
import { loadingTense } from 'utils/string';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import DeadlineSelectInput from 'components/formInputs/DeadlineSelectInput';
import VisibilityInput from 'components/formInputs/VisibilityInput';
import ImageInput from 'components/formInputs/ImageInput';

const INITIAL_STATE: {
  verificationDeadlineId: string;
  image: File | null;
  loading: boolean;
  visibility: string;
} = {
  verificationDeadlineId: '',
  image: null,
  loading: false,
  visibility: 'false',
};

type UploadVerificationImageModalProps = {
  onUploadComplete: () => void;
  toggleClassName?: string;
  verificationDeadlines: Offer['verificationDeadlines'];
};

function UploadVerificationImageModal(
  props: UploadVerificationImageModalProps,
) {
  const {
    onUploadComplete,
    toggleClassName = 'btn btn-secondary',
    verificationDeadlines,
  } = props;
  const [state, setState] = useState(INITIAL_STATE);
  const preview = useRef<HTMLImageElement | null>(null);

  const handleVerificationDeadlineChange = (event: FormEvent<HTMLElement>) => {
    const target = event.target as HTMLSelectElement;
    setState({ ...state, verificationDeadlineId: target.value });
  };

  const handleVisibilityChange = (event: FormEvent<HTMLElement>) => {
    const target = event.target as HTMLSelectElement;
    setState({ ...state, visibility: target.value });
  };

  const handleImageChange = (event: FormEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const image: File = target.files?.[0] as File;
    if (image) {
      setState({ ...state, image });
      const previewElement = preview.current;
      if (previewElement) {
        previewElement.src = URL.createObjectURL(image);
      }
    }
  };

  const handleSubmit = async (event: Event) => {
    event.preventDefault();

    const { verificationDeadlineId, image, visibility } = state;

    setState({ ...state, loading: true });

    // Assuming LeaguesideApi.createVerificationImageWithDeadline is an async function
    await LeaguesideApi.createVerificationImageWithDeadline(
      verificationDeadlineId,
      image,
      visibility === 'true',
      'Employee',
      'verified',
    );

    setState(INITIAL_STATE);
    onUploadComplete();
  };

  const { verificationDeadlineId, image, loading, visibility } = state;

  const id = 'upload-verification-image';

  return (
    <ModalForm
      id={`${id}-modal`}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        children: loadingTense(loading, 'Upload'),
        disabled: !verificationDeadlineId || !image || loading,
      }}
      title="Upload Verification Image"
      toggleProps={{
        className: toggleClassName,
        children: (
          <>
            <Icon className="mr-1" value="image" />
            Upload Verification
          </>
        ),
      }}
    >
      <p>
        To upload a verification image,&nbsp; please specify which deadline the
        image verifies and select the image file from your computer.
      </p>
      <ImageInput
        image={image ?? undefined}
        isRequired
        loading={loading}
        onChange={handleImageChange}
        prefix={id}
      />
      <DeadlineSelectInput
        deadlineId={verificationDeadlineId}
        deadlines={verificationDeadlines}
        onChange={handleVerificationDeadlineChange}
        prefix={id}
      />
      <VisibilityInput
        disabled={loading}
        onChange={handleVisibilityChange}
        visibility={visibility as 'true' | 'false'}
      />
      <div className="form-group">
        {image && (
          <label className="font-weight-bold" htmlFor="upload-preview">
            Image Preview
          </label>
        )}
        <img
          alt="preview"
          id="upload-preview"
          className="form-control img-thumbnail"
          ref={preview}
          style={{ display: image ? '' : 'none' }}
        />
      </div>
    </ModalForm>
  );
}

export default UploadVerificationImageModal;
