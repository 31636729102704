import React, { useState } from 'react';
import { startsWith } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSubmit } from 'utils/hooks';
import ForgotPasswordModal from 'components/ForgotPasswordModal';
import ModalForm from 'components/ModalForm';
import Icon from 'components/Icon';
import ContactUsLink from 'components/ContactUsLink';
import ErrorAlert from 'components/ErrorAlert';

const isPasswordIncorrect = startsWith('GraphQL error: Password');

const errorMessage = (error, email) =>
  isPasswordIncorrect(error) ? (
    <ErrorAlert className="mt-3">
      Oops! That password isn&#39;t right. Please try again.&nbsp;
      <ForgotPasswordModal initialEmail={email} />
    </ErrorAlert>
  ) : (
    <ErrorAlert className="mt-3">
      Oops! We had trouble accepting the offer.&nbsp; Please check your internet
      connection and try again. If the problem persists, please&nbsp;
      <ContactUsLink />.
    </ErrorAlert>
  );

const createAccountMessage = (
  <>
    <p>
      If you click the button below,&nbsp; we will create a new TeamSnap account
      for you at the following email address.
    </p>
    <p>
      After that, you will receive a verification email to finish creating your
      account.&nbsp; Once you create your account, the offer will be accepted!
    </p>
  </>
);

const signWithAccountMessage = (
  <p>To accept this offer, you need to sign it with a TeamSnap account.</p>
);

const AcceptOfferModal = ({
  acceptOffer,
  email,
  hasAccount,
  loggedIn,
  onAccept,
  toggleProps,
}) => {
  const [password, setPassword] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const input = hasAccount ? { email, password } : { email };
  const accept = () => acceptOffer(input).then(onAccept);
  const { error, loading, handleSubmit } = useSubmit(accept);

  const submitButtonText = hasAccount ? 'Accept Offer' : 'Create Account';
  const loadingButtonText = hasAccount ? 'Accepting...' : 'Creating...';

  const disabledEmailInput = (
    <div className="form-group">
      <label className="font-weight-bold" htmlFor="email">
        Email Address
      </label>
      <input
        className="form-control"
        disabled
        id="email"
        type="email"
        value={email}
      />
    </div>
  );

  const agreedToTermsInput = (
    <div className="form-check">
      <input
        checked={agreedToTerms}
        className="form-check-input"
        id="agreed-to-terms"
        onChange={(event) => setAgreedToTerms(event.target.checked)}
        type="checkbox"
        disabled={loading}
      />
      <label className="form-check-label" htmlFor="agreed-to-terms">
        <strong>I accept.</strong>
        &nbsp;By checking this box, you are agreeing to our&nbsp;
        <a
          href="https://www.teamsnap.com/privacy-policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
        &nbsp;and&nbsp;
        <a
          href="https://www.teamsnap.com/terms"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of Use
        </a>
        {/* eslint-disable-next-line max-len */}, accepting this sponsorship on
        behalf of your organization, committing to completing the listed
        requirements, and agreeing to receive calls and texts related to this
        sponsorship.
      </label>
    </div>
  );

  const passwordInput = (
    <div className="form-group">
      <div className="row">
        <div className="col">
          <label className="font-weight-bold" htmlFor="password">
            Password
          </label>
        </div>
        <div className="col text-right">
          <ForgotPasswordModal initialEmail={email} />
        </div>
      </div>
      <input
        className="form-control"
        disabled={loading}
        id="password"
        onChange={(event) => setPassword(event.target.value)}
        required
        type="password"
        value={password}
      />
    </div>
  );

  const acceptOfferMessage = (
    <p>
      {loggedIn ? (
        <>
          If you select&nbsp;
          <strong>I accept</strong>
        </>
      ) : (
        <>If you enter your password</>
      )}
      &nbsp;below and click&nbsp;
      <strong>Accept Offer</strong>, the offer will be accepted!
    </p>
  );

  return (
    <ModalForm
      id="accept-offer"
      error={error}
      errorMessage={error && errorMessage(error.message, email)}
      loading={loading}
      onSubmit={handleSubmit}
      submitProps={{
        className: classNames('btn btn-primary', { 'font-italic': loading }),
        children: (
          <>
            {loading ? null : <Icon className="mr-1" value="check" />}
            {loading ? loadingButtonText : submitButtonText}
          </>
        ),
        disabled:
          !agreedToTerms || (hasAccount && !loggedIn && !password) || loading,
      }}
      toggleProps={{
        className: 'btn btn-primary ml-2 my-1',
        children: (
          <>
            <Icon className="mr-1" value="check" />
            Accept
          </>
        ),
        ...toggleProps,
      }}
      title="Accept Offer?"
    >
      {!loggedIn && signWithAccountMessage}
      {hasAccount ? acceptOfferMessage : createAccountMessage}
      {!loggedIn && (
        <>
          {disabledEmailInput}
          {hasAccount && passwordInput}
        </>
      )}
      {agreedToTermsInput}
    </ModalForm>
  );
};

AcceptOfferModal.propTypes = {
  acceptOffer: PropTypes.func.isRequired,
  email: PropTypes.string,
  hasAccount: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  toggleProps: PropTypes.shape({
    disabled: PropTypes.bool,
  }).isRequired,
};

AcceptOfferModal.defaultProps = {
  email: null,
};

export default AcceptOfferModal;
