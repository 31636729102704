import * as React from 'react';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLElement> {
  border?: string;
  cardId: string;
  footer?: React.ReactNode;
  header?: JSX.Element;
}

const PreviewCard: React.FunctionComponent<Props> = ({
  border,
  cardId,
  children,
  footer,
  header,
}: Props) => (
  <div
    className={classNames('card h-100', {
      [`border-${border}`]: !!border,
      'border-thick': !!border,
    })}
    data-test={`preview-${cardId}`}
  >
    {!!header && <div className="card-header py-2 px-3">{header}</div>}
    <div className="card-body p-2 d-flex align-items-center justify-content-center">
      {children}
    </div>
    {!!footer && (
      <div className="card-footer py-2 d-flex align-items-center justify-content-between">
        {footer}
      </div>
    )}
  </div>
);

PreviewCard.defaultProps = {
  border: undefined,
  footer: undefined,
  header: undefined,
};

export default PreviewCard;
