import { useEffect } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useWizardContext } from 'pages/qualify/provider';
import { useFieldArray } from 'react-hook-form';
import { LabelButton } from '@teamsnap/snap-ui';
import { Inputs } from 'pages/qualify/types';
import moment from 'moment';
import {
  COMPETITIVENESSES_LIST_QUERY,
  SPORTS_LIST_QUERY,
} from 'pages/qualify/queries';
import { GENDERS_LIST, parseAnyListToOptionsList } from 'pages/qualify/utils';
import WizardStep from '../wizard-step';
import WizardInput from '../wizard-input';

export default function ProgramsStep() {
  const {
    tokenData,
    control,
    values,
    setValue,
    token,
    formState: { isValid },
  } = useWizardContext();
  const { data: competitivenessQueryData } = useQuery<{
    competitivenesses: { id: string; name: string }[];
  }>(COMPETITIVENESSES_LIST_QUERY, {
    context: { headers: { Authorization: `Bearer ${token}` } },
  });
  const { data: sportsQueryData } = useQuery<{
    sports: { id: string; name: string }[];
  }>(SPORTS_LIST_QUERY, {
    context: { headers: { Authorization: `Bearer ${token}` } },
  });
  const [performMutation] = useMutation(MUTATION);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sponsorableProperties',
  });

  const modifyInputsBeforeMutation = (
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    sponsorableProperties: data.sponsorableProperties?.map((program) => ({
      ...program,
      competitivenessId: Number(program.competitivenessId),
      sportId: Number(program.sportId),
      numberOfPlayers: Number(program.numberOfPlayers),
      minAge: Number(program.minAge),
      maxAge: Number(program.maxAge),
    })),
  });

  const modifyValuesAfterMutation = (
    response: any,
    data: Partial<Inputs>,
  ): Partial<Inputs> => ({
    ...data,
    sponsorableProperties: data?.sponsorableProperties?.map(
      (program, index) => ({
        ...program,
        id: response?.data?.upsertSponsorableProperties
          ?.sponsorableProperties?.[index]?.id,
      }),
    ),
  });

  useEffect(() => {
    if (values.sponsorableProperties?.length) {
      setValue('sponsorableProperties', values.sponsorableProperties);
    } else {
      setValue('sponsorableProperties', [
        DEFAULT_PROGRAM({ season: tokenData.season }),
      ]);
    }
  }, [values]);

  return (
    <WizardStep
      performMutation={performMutation}
      modifyInputsBeforeMutation={modifyInputsBeforeMutation}
      modifyValuesAfterMutation={modifyValuesAfterMutation}
      mutationStaticIds={{
        organizationId: tokenData.id,
        sponsorshipApplicationId: values.sponsorshipApplicationId,
        offerId: tokenData.offer_id,
      }}
    >
      <WizardStep.Content
        title={`Enter details for the programs in your ${tokenData.season} ${tokenData.year} season`}
      >
        <h2 className="sui-text-mobile-5 d:sui-text-desktop-6 sui-font-bold sui-mb-3">
          Program Information
        </h2>

        {fields.map((field, index) => (
          <section
            key={field.id}
            className="sui-grid sui-p-2 sui-bg-neutral-background-weak sui-mb-2"
            style={{ gap: 16 }}
          >
            <header className="sui-flex sui-justify-between sui-items-center sui-h-6">
              <p className="sui-text-mobile-4 t:sui-text-desktop-5">
                Program {index + 1}
              </p>
              {fields.length > 1 && (
                <LabelButton
                  icon="delete"
                  iconPosition="left"
                  labelText="Remove"
                  sentiment="negative"
                  variantType="tertiary"
                  onClick={() => remove(index)}
                />
              )}
            </header>

            <div
              className="sui-flex sui-flex-col t:sui-grid"
              style={{
                gridTemplateColumns: '1fr 180px',
                gap: 16,
              }}
            >
              <WizardInput
                type="text"
                name={`sponsorableProperties.${index}.name`}
                label="Program Name"
                rules={{ required: 'Program Name is required' }}
              />
              <WizardInput
                type="select"
                name={`sponsorableProperties.${index}.competitivenessId`}
                label="Type"
                rules={{ required: 'Type is required' }}
                options={parseAnyListToOptionsList(
                  competitivenessQueryData?.competitivenesses ?? [],
                  { withEmptyOption: false },
                )}
                placeholder="Select a type"
              />
            </div>
            <div
              className="sui-flex sui-flex-col t:sui-grid"
              style={{
                gridTemplateColumns: '1fr 1fr',
                gap: 16,
              }}
            >
              <WizardInput
                type="select"
                name={`sponsorableProperties.${index}.sportId`}
                label="Sport"
                rules={{ required: 'Sport(s) is required' }}
                options={parseAnyListToOptionsList(
                  sportsQueryData?.sports ?? [],
                  { withEmptyOption: false },
                )}
                placeholder="Select a sport"
              />
              <WizardInput
                type="number"
                name={`sponsorableProperties.${index}.numberOfPlayers`}
                label="Anticipated number of participants"
                rules={{
                  required: 'Anticipated number of participants is required',
                }}
              />
            </div>
            <div
              className="sui-flex sui-flex-col t:sui-grid"
              style={{
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: 16,
              }}
            >
              <WizardInput
                type="select"
                name={`sponsorableProperties.${index}.gender`}
                label="Gender"
                rules={{ required: 'Gender is required' }}
                options={[...GENDERS_LIST]}
                placeholder="Select a gender"
              />
              <WizardInput
                type="number"
                name={`sponsorableProperties.${index}.minAge`}
                label="Lower age limit"
                max={99}
                triggerAnotherInput={`sponsorableProperties.${index}.maxAge`}
                rules={{
                  required: 'Lower age limit is required',
                  validate: (value, formValues) => {
                    const { maxAge } = formValues.sponsorableProperties[index];
                    if (Number(value) > Number(maxAge)) {
                      return 'Youngest must be less than Oldest';
                    }
                    return true;
                  },
                }}
              />
              <WizardInput
                type="number"
                name={`sponsorableProperties.${index}.maxAge`}
                label="Upper age limit"
                max={99}
                triggerAnotherInput={`sponsorableProperties.${index}.minAge`}
                rules={{
                  required: 'Upper age limit is required',
                  validate: (value, formValues) => {
                    const { minAge } = formValues.sponsorableProperties[index];
                    if (Number(value) < Number(minAge)) {
                      return 'Oldest must be greater than Youngest';
                    }
                    return true;
                  },
                }}
              />
            </div>
            <div
              className="sui-flex sui-flex-col t:sui-grid"
              style={{
                gridTemplateColumns: '1fr 1fr',
                gap: 16,
              }}
            >
              <WizardInput
                type="date"
                name={`sponsorableProperties.${index}.startDate`}
                label="Season start date"
                triggerAnotherInput={`sponsorableProperties.${index}.endDate`}
                rules={{
                  required: 'Season start date is required',
                  validate: {
                    todayOrAfterToday: (value) => {
                      const pickedDate = moment(value);
                      const today = moment().startOf('day');
                      if (pickedDate.isBefore(today)) {
                        return 'Start Date must be today or later';
                      }
                      return true;
                    },
                    beforeLastDate: (value, formValues) => {
                      const { endDate } =
                        formValues.sponsorableProperties[index];
                      if (new Date(value) > new Date(endDate)) {
                        return 'Start Date must be before End Date';
                      }
                      return true;
                    },
                  },
                }}
              />
              <WizardInput
                type="date"
                name={`sponsorableProperties.${index}.endDate`}
                label="Season end date"
                triggerAnotherInput={`sponsorableProperties.${index}.startDate`}
                rules={{
                  required: 'Season end date is required',
                  validate: {
                    todayOrAfterToday: (value) => {
                      const pickedDate = moment(value);
                      const today = moment().startOf('day');
                      if (pickedDate.isBefore(today)) {
                        return 'End Date must be today or later';
                      }
                      return true;
                    },
                    afterFirstDate: (value, formValues) => {
                      const { startDate } =
                        formValues.sponsorableProperties[index];
                      if (new Date(value) < new Date(startDate)) {
                        return 'End Date must be after Start Date';
                      }
                      return true;
                    },
                  },
                }}
              />
            </div>
          </section>
        ))}

        <LabelButton
          icon="add"
          iconPosition="left"
          labelText="Add another program"
          variantType="tertiary"
          disabled={!isValid}
          data-testid="add-program"
          onClick={() => append(DEFAULT_PROGRAM({ season: tokenData.season }))}
        />
      </WizardStep.Content>
    </WizardStep>
  );
}

const MUTATION = gql`
  mutation upsertSponsorableProperties(
    $input: UpsertSponsorablePropertiesInput!
  ) {
    upsertSponsorableProperties(input: $input) {
      id
      sponsorableProperties {
        id
      }
    }
  }
`;

const DEFAULT_PROGRAM = (defaultValues: Partial<Inputs>) => ({
  id: 0,
  name: '',
  competitivenessId: null,
  sportId: null,
  gender: null,
  minAge: null,
  maxAge: null,
  startDate: '',
  endDate: '',
  numberOfPlayers: null,
  season: '',
  ...defaultValues,
});
