import React from 'react';
import { Mutation } from 'react-apollo';
import { merge } from 'ramda';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import Icon from 'components/Icon';
import { getInitialStandardizedDeadlineName } from 'components/formInputs/DeadlineNameInput';
import DefaultVerificationDeadlineModal from './DefaultVerificationDeadlineModal';
import {
  CREATIVE_CONTENT_TEMPLATE_FRAGMENT,
  CREATIVE_FILE_TEMPLATE_FRAGMENT,
  DEFAULT_VERIFICATION_DEADLINE_FRAGMENT,
} from '../getCampaign';

const CreateDefaultVerificationDeadlineModalUnwrapped = ({
  campaignCapabilityId,
  capabilityName,
  capabilityVerificationDeadlines,
  create,
  toggleClassName,
}) => (
  <DefaultVerificationDeadlineModal
    capabilityName={capabilityName}
    capabilityVerificationDeadlines={capabilityVerificationDeadlines}
    initialName={getInitialStandardizedDeadlineName(
      capabilityVerificationDeadlines,
    )}
    modalId={`create-verification-deadline-${campaignCapabilityId}-modal`}
    onSubmit={create}
    toggleProps={{
      className: toggleClassName,
      children: (
        <>
          <Icon value="plus" />
          Add Deadline
        </>
      ),
    }}
  />
);

CreateDefaultVerificationDeadlineModalUnwrapped.propTypes = {
  campaignCapabilityId: PropTypes.number.isRequired,
  capabilityName: PropTypes.string.isRequired,
  capabilityVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  create: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string.isRequired,
};

const MUTATION = gql`
  mutation CreateDefaultVerificationDeadline(
    $input: CreateDefaultVerificationDeadlineInput!
  ) {
    createDefaultVerificationDeadline(input: $input) {
      campaignCapability {
        id
        defaultVerificationDeadlines {
          ...DefaultVerificationDeadlineFragment
          creativeContentTemplates {
            ...CreativeContentTemplateFragment
          }
          creativeFileTemplates {
            ...CreativeFileTemplateFragment
          }
        }
      }
    }
  }
  ${DEFAULT_VERIFICATION_DEADLINE_FRAGMENT}
  ${CREATIVE_CONTENT_TEMPLATE_FRAGMENT}
  ${CREATIVE_FILE_TEMPLATE_FRAGMENT}
`;

const CreateDefaultVerificationDeadlineModal = ({
  campaignCapabilityId,
  capabilityName,
  capabilityVerificationDeadlines,
  toggleClassName,
}) => (
  <Mutation mutation={MUTATION}>
    {(mutate) => (
      <CreateDefaultVerificationDeadlineModalUnwrapped
        campaignCapabilityId={campaignCapabilityId}
        capabilityName={capabilityName}
        capabilityVerificationDeadlines={capabilityVerificationDeadlines}
        create={(input) =>
          mutate({
            variables: {
              input: merge({ campaignCapabilityId }, input),
            },
          })
        }
        toggleClassName={toggleClassName}
      />
    )}
  </Mutation>
);

CreateDefaultVerificationDeadlineModal.propTypes = {
  campaignCapabilityId: PropTypes.number.isRequired,
  capabilityName: PropTypes.string.isRequired,
  capabilityVerificationDeadlines: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  toggleClassName: PropTypes.string.isRequired,
};

export default CreateDefaultVerificationDeadlineModal;
